import React, { useEffect, useState } from "react";
import api from "../config/api";
import { FormInput } from "./custom/components";

interface Product {
    id: number;
    productName: string;
    productCode: string;
    sac: string;
    gst: string;
    productDetails: string;
    pwoGst: string;
    pwGst: string;
    wholesaleRate: string;
    retailRate: string;
    mrp: string;
    stock: string;
    department: string;
    category: string;
    brand: string;
    vendor: string;
    updatedByOn: string;
    hsnCode: string;
}

interface Dropdown {
    label: string;
    value: string;
}

interface ProductSearchProps {
    handleProductName: (

        product: Product | null,
        id: number,
        field: string,
        value: string
    ) => void;
    pi: { id: number; productName: Dropdown | null }; // Updated to include Dropdown type
    resetSearch: boolean;
    setResetSearch: React.Dispatch<React.SetStateAction<boolean>>;
    editProductName: Dropdown | null; // Updated to include Dropdown type
    handleKeyDown?: (
        e: React.KeyboardEvent | React.MouseEvent,
        nextField: string,
        rowId: string,
        moveToNextRow?: boolean
    ) => void;
    refs?: React.MutableRefObject<{
        [key: string]: any;
    }>;
}

const ProductSearch: React.FC<ProductSearchProps> = ({
    handleProductName,
    pi,
    resetSearch,
    setResetSearch,
    editProductName,
    handleKeyDown,
    refs,
}) => {
    const [searchTerm, setSearchTerm] = useState<string>(
        editProductName?.label || ""
    ); // Initialize with editProductName label
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
    const [showTable, setShowTable] = useState<boolean>(false);

    // Update searchTerm when editProductName changes
    useEffect(() => {
        if (editProductName) {
            setSearchTerm(editProductName.label);
        } else {
            setSearchTerm("");
        }
    }, [editProductName]);

    // Reset search when resetSearch prop changes
    useEffect(() => {
        if (resetSearch) {
            setSearchTerm("");
            setFilteredProducts([]);
            setShowTable(false);
            setResetSearch(false);
        }
    }, [resetSearch]);

    // Fetch products with debouncing
    const fetchProducts = async (value: string) => {
        if (value.trim()) {
            try {
                const response = await api().get(
                    `/product/productSearch/?search=${searchTerm}`
                );
                setFilteredProducts(response.data.data);
                setShowTable(response.data.data.length > 0);
            } catch (error) {
                console.error("Error fetching products:", error);
                setFilteredProducts([]);
                setShowTable(false);
            }
        } else {
            setFilteredProducts([]);
            setShowTable(false);
        }
    };

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        fetchProducts(value);
    };

    // Handle product selection
    const handleSelectProduct = (
        e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
        product: Product
    ) => {
        const productLabel = `${product.productName}-${product.productCode}-${product.sac}-${product.gst}`;
        setSearchTerm(productLabel);
        setShowTable(false);
        handleKeyDown!(e, "qty", pi.id.toString());
        // Pass the selected product to the parent component
        handleProductName(product, pi.id, "qty", pi.id.toString());
    };

    return (
        <div className="relative " style={{ width: "250px" }}>
            {/* Input Field */}
            <FormInput
                name={`productName-${pi.id}`}
                type="text"
                value={searchTerm} // Controlled by searchTerm state
                onChange={handleInputChange}
                onFocus={() => setShowTable(true)}
                className="border rounded w-full p-2"
                placeholder="Search product..."
                style={{ height: "7px" }} // Adjusted height5
                ref={(el) => (refs!.current[`productName-${pi.id}`] = el)}
                onKeyDown={(e) => handleKeyDown!(e, "qty", pi.id.toString())}
            />

            {/* Table Dropdown */}
            {showTable && filteredProducts?.length > 0 && (
                <div style={{ position: "absolute", zIndex: "1" }}>
                    <table
                        className="w-full border-collapse text-xs table2"
                        style={{ width: "130%" }}
                    >
                        <thead>
                            <tr className="bg-gray-200 text-[10px]">
                                <th className="border border-gray-300 px-1 py-1 w-24">
                                    Product Name
                                </th>
                                <th className="border border-gray-300 px-1 py-1 w-16">Code</th>
                                <th className="border border-gray-300 px-1 py-1 w-20">
                                    SAC/HSN Code
                                </th>
                                <th className="border border-gray-300 px-1 py-1 w-12">GST</th>
                                <th className="border border-gray-300 px-1 py-1 w-16">
                                    Price w/o GST
                                </th>
                                <th className="border border-gray-300 px-1 py-1 w-16">
                                    Price w/ GST
                                </th>
                                <th className="border border-gray-300 px-1 py-1 w-20">
                                    Wholesale Rate
                                </th>
                                <th className="border border-gray-300 px-1 py-1 w-20">
                                    Retail Rate
                                </th>
                                <th className="border border-gray-300 px-1 py-1 w-12">MRP</th>
                                <th className="border border-gray-300 px-1 py-1 w-12">Stock</th>
                                <th className="border border-gray-300 px-1 py-1 w-16">
                                    Department
                                </th>
                                <th className="border border-gray-300 px-1 py-1 w-16">
                                    Category
                                </th>
                                <th className="border border-gray-300 px-1 py-1 w-16">Brand</th>
                                <th className="border border-gray-300 px-1 py-1 w-24">
                                    Updated On
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white text-[10px]">
                            {filteredProducts?.map((product) => (
                                <tr
                                    key={product.id}
                                    className="hover:bg-gray-100 cursor-pointer"
                                    onClick={(e) => handleSelectProduct(e, product)}
                                >
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.productName}
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.productCode}
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.sac}
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.gst}%
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.pwoGst}
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.pwGst}
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.wholesaleRate}
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.retailRate}
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.mrp}
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.stock}
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.department}
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.category}
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.brand}
                                    </td>
                                    <td className="border border-gray-300 px-1 py-1">
                                        {product.updatedByOn}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default ProductSearch;
