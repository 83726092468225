import React, { forwardRef } from 'react';
import { StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';

// Define props for the CreatableDropdown component
interface CreatableDropdownProps {
    id?: string;
    label: string;
    labelClassName?: string;
    required?: boolean;
    height?: string;
    value: string;
    isClearable?: boolean;
    onChange?: (value: any) => void;
    onCreateOption?: (inputValue: string) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;  // Added onKeyDown prop
    options?: { label: string; value: string }[];
    className?: string;
    customStyles?: StylesConfig<any, false>;
    errorText?: string;
}

const CreatableDropdown = forwardRef<HTMLDivElement, CreatableDropdownProps>(({
    id,
    label,
    labelClassName = '',
    required = false,
    isClearable = true,
    value,
    height,
    onChange,
    onCreateOption,
    onKeyDown,
    options = [],
    className = '',
    customStyles = {},
    errorText,
}, ref) => {
    const defaultStyles: StylesConfig<any, false> = {
        control: (base) => ({
            ...base,
            border: '1px solid rgba(2, 2, 2, 0.12)',
            borderRadius: '4px',
            boxShadow: 'none',
            height: height,
        }),
    };

    return (
        <div className="form-group" ref={ref} style={{ height: height }}>
            <label htmlFor={id} className={labelClassName}>
                {label} {!required && <span className="text-danger">*</span>}
            </label>
            <CreatableSelect
                id={id}
                isClearable={isClearable}
                value={options.find((option) => option.value === value) || null}
                onChange={onChange}
                onCreateOption={onCreateOption}
                options={options}
                onKeyDown={onKeyDown}
                className={className}
                styles={{ ...defaultStyles, ...customStyles }}
            />
            {errorText && <small className="text-danger mb-5">{errorText}</small>}
        </div>
    );
});

export default CreatableDropdown;
