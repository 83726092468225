import React, { useEffect, useState } from 'react';

interface ClockProps {
    onTimeChange?: (time: string) => void; // Callback to notify parent of time changes
}

const Clock: React.FC<ClockProps> = ({ onTimeChange }) => {
    const [time, setTime] = useState<string>('');

    useEffect(() => {
        const printTime = () => {
            const date = new Date();
            const h = date.getHours();
            const m = date.getMinutes();
            const s = date.getSeconds();

            const ampm = h >= 12 ? "PM" : "AM";
            const formattedHours = (h % 12 || 12).toString().padStart(2, "0");
            const formattedMinutes = m.toString().padStart(2, "0");
            const formattedSeconds = s.toString().padStart(2, "0");

            const newTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

            // Only update state if the time has changed
            setTime((prevTime) => {
                if (prevTime !== newTime) {
                    onTimeChange?.(newTime); // Notify parent of time change
                    return newTime;
                }
                return prevTime;
            });
        };

        printTime(); // Call immediately to set the initial time
        const intervalId = setInterval(printTime, 1000);

        return () => clearInterval(intervalId);
    }, [onTimeChange]);

    return (
        <span className="fs-4 badge bg-primary ms-1">{time}</span>
    );
};

export default Clock;