import { ResetPasswordActionList } from "../actions/changePasswordAction";
import { Action } from "../actions/types";

interface ResetPasswordState {
    userId: string;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    success: boolean;
    error: string | null;
}

const initialState: ResetPasswordState = {
    userId: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    success: false,
    error: null,
};

const resetPasswordReducer = (
    state: ResetPasswordState = initialState,
    action: Action
): ResetPasswordState => {
    switch (action.type) {
        case ResetPasswordActionList.CHANGE_PASSWORD:
            if (action.data) {
                return {
                    ...state,
                    userId: action.data.userId,
                    oldPassword: action.data.oldPassword,
                    newPassword: action.data.newPassword,
                    confirmPassword: action.data.confirmPassword,
                    success: true,
                    error: null,
                };
            }
            return { ...state, success: false, error: "Password reset failed." };

        default:
            return state;
    }
};

export default resetPasswordReducer;


