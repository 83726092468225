/* eslint-disable no-throw-literal */
import { Dispatch } from "redux";
import { authLogin, editPassword } from "../../services/user.service";
import { PasswordType } from "./usersActions";

export interface LoginDataType {
    userId: string;
    password: string;
}

export interface RoleType {
    id?: string;
    role: string;
    pageTitle: string;
    pageUrl: string;
    add: string;
    view: string;
    edit: string;
    delete: string;
    createdBy?: string | null;
    createdAt?: string;
    updatedAt?: string;
}

export interface AuthUserType {
    id?: number;
    "auth-token"?: string;
    userId: string;
    role: string;
    password?: string;
    name: string;
    branchId?: number;
    email: string;
    mobileNumber?: string;
    status?: string;
    roles?: RoleType[];
    createdAt?: string;
    updatedAt?: string;
}

export interface ResetPasswordDataType {
    userId?: string;
    oldPassword?: string;
    newPassword?: string;
    confirmPassword?: string;
}

export enum UserActionList {
    LOGIN_USER = "LOGIN_USER",
    LOGOUT_USER = "LOGOUT_USER",
    RESET_PASSWORD = "RESET_PASSWORD"
}

export interface LoginAction {
    type: UserActionList.LOGIN_USER;
    data: AuthUserType;
}

export interface LogoutAction {
    type: UserActionList.LOGOUT_USER;
    data: null;
}
export interface ResetPasswordAction {
    type: UserActionList.RESET_PASSWORD;
    data: null;
}


export type AuthUserActions = LoginAction | LogoutAction | ResetPasswordAction;

export const loginUser = (data: LoginDataType) => {
    return async (dispatch: Dispatch) => {
        const response = await authLogin(data);
        dispatch<AuthUserActions>({
            type: UserActionList.LOGIN_USER,
            data: response.data.data,
        });
    };
};

export const logoutUser = () => {
    return (dispatch: Dispatch) => {
        dispatch<LogoutAction>({
            type: UserActionList.LOGOUT_USER,
            data: null,
        });
    };
};

export const resetPasswordAction = (data: PasswordType, id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await editPassword(data, id);
            dispatch<ResetPasswordAction>({
                type: UserActionList.RESET_PASSWORD,
                data: response.data,
            });
        } catch (error) {
            console.error("Error resetting password:", error);
            throw error; // Optional: Handle error as needed
        }
    };
};