import React, { ChangeEventHandler, MouseEventHandler, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import CreatableDropdown from "../components/CreatableDropdown"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput, FormInput2 } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import api from "../config/api"
import { AuthUserType, RoleType } from "../redux/actions/authUserActions"
import { BrandType, addBrandAction, fetchBrandAction } from "../redux/actions/brandAction"
import { CategoryType, addCategoryAction, fetchCategoryAction } from "../redux/actions/categoryAction"
import { DepartmentType, addDepartmentAction, fetchDepartmentAction } from "../redux/actions/departmentAction"
import { DropDownType, addDropDownAction, fetchDropDownAction } from "../redux/actions/dropdownAction"
import { ProductType, addProductAction, deleteProductAction, editProductAction, fetchProductAction } from "../redux/actions/productAction"
import { AppDispatch, StoreState } from "../redux/store";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import {
  ClientRegistrationType,
  fetchClientRegistrationAction,
} from "../redux/actions/clientRegistration";


import { Badge } from "react-bootstrap"


interface TableBody {
    sno: number
    productName: string
    productCode: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    rolePermissions: RoleType | undefined
}

type HandleEdit = (value: ProductType) => void
type HandleDelete = (value: ProductType) => void

const ProductMaster = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()


 const clients = useSelector<StoreState, ClientRegistrationType[]>(state => state.clientRegistration)

        useEffect(() => {
              dispatch(fetchClientRegistrationAction())
          }, [])


    const productList = useSelector<StoreState, ProductType[]>(state => state.product)
    const brandList = useSelector<StoreState, BrandType[]>(state => state.brand)
    const departmentList = useSelector<StoreState, DepartmentType[]>(state => state.department)
    const cateList = useSelector<StoreState, CategoryType[]>(state => state.category)
    const unitLists = useSelector<StoreState, DropDownType[]>(state => state.dropdown)
    const [recentProductId, setRecentProductId] = useState('')
    const AuthUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const url = window.location.pathname
    const rolePermissions = AuthUser?.roles?.find(r => r.pageUrl === url)

    useEffect(() => {
        dispatch(fetchProductAction())
        dispatch(fetchBrandAction())
        dispatch(fetchDepartmentAction())
        dispatch(fetchCategoryAction())
        dispatch(fetchDropDownAction())
    }, [dispatch])

    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<ProductType | null>(null)

    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [id, setId] = useState<number>()

    const [loginid, setLoginid] = useState<string>('')
    const [loginidError, setLoginidError] = useState<string>('')
    const [productName, setProductName] = useState<string | undefined>('')
    const [productNameError, setProductNameError] = useState<string>('')
    const [productCode, setProductCode] = useState<string>('')
    const [productCodeError, setProductCodeError] = useState<string>('')
    const [brandId, setBrandId] = useState<string | undefined>('')
    const [brandIdError, setBrandIdError] = useState<string>('')
    const [departmentId, setDepartmentId] = useState<string>('')
    const [departmentIdError, setDepartmentIdError] = useState<string>('')
    const [categoryId, setCategoryId] = useState<string>()
    const [categoryIdError, setCategoryIdError] = useState<string>('')
    const [unit, setUnit] = useState<string>('')
    const [unitErrorInModel, setUnitErrorInModel] = useState<string>('')
    const [unitError, setUnitError] = useState<string>('')
    const [uom, setUom] = useState<string>('1')
    const [uomError, setUomError] = useState<string>('')
    const [sac, setSac] = useState<string>('')
    const [sacError, setSacError] = useState<string>('')
    const [gst, setGst] = useState<string>('')
    const [gstError, setGstError] = useState<string>('')
    const [rackNumber, setRackNumber] = useState<string>('')
    const [rackNumberError, setRackNumberError] = useState<string>('')
    const [reOrder, setReOrder] = useState<string>('1')
    const [reOrderError, setReOrderError] = useState<string>('')
    const [taxable, setTaxable] = useState<string>('')
    const [taxableError, setTaxableError] = useState<string>('')
    const [modelNumber, setModelNumber] = useState<string>('')
    const [modelNumberError, setModelNumberError] = useState<string>('')
    const [modelName, setModelName] = useState<string>('')
    const [modelNameError, setModelNameError] = useState<string>('')
    const [reMarks, setReMarks] = useState<string>('')
    const [reMarksError, setReMarksError] = useState<string>('')
    const [openModal, setOpenModal] = useState(false)
    const [brandName, setBrandName] = useState<string>('')
    const [showModal, setShowModal] = useState(false)
    const [departmentName, setDepartmentName] = useState<string>('')
    const [viewModal, setViewModal] = useState(false)
    const [categoryName, setCategoryName] = useState<string>('')
    const [unitModal, setUnitModal] = useState(false)
    const [unitName, setUnitName] = useState<string>('')
    const [imageUpload, setImageUpload] = useState<any | null>({ filename: '' })
    const [imageUrl, setImageUrl] = useState<any>('')
    const [imageFlag, setImageFlag] = useState<boolean>(false)
    const [oldProductCode, setOldProductCode] = useState<string>('0')
    const [oldProductCodeError, setOldProductCodeError] = useState<string>('')
    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()
    const searchTerm = searchQuery.get('search') || ''
    const mainContentRef = React.useRef<HTMLDivElement>(null)
    const [showData, setShowData] = useState<boolean>(false);
    const [sacOptions, setSacOptions] = useState<string[]>([]);

    useEffect(() => {
        if (productList.length > 0) {
            setSacOptions(Array.from(
                new Set([
                    ...productList
                        .filter((p) => p.sac !== undefined)
                        .map((p) => String(p.sac)),
                    sac
                ])
            ));
        }
    }, [productList, sac]);

    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery({ search: e.target.value })
    }

    const filterProductHandler = (product: any, searchTerm: any) => {
        return searchTerm !== '' ? (new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(product.productName) || new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(product.productCode)) : true || []
    }

    const fetchRecentProductId = async () => {
        api().get(`/product/recent/`).then((res) => {
            const result = (res.data.data !== null ? ((parseInt(categoryId!) * 10000) + parseInt(res.data.data)) : 100000) + 1
            if (formType === FormTypeList.ADD) setProductCode(result.toString())
            console.log(res.data.data);
            console.log(categoryId)
        });
    }

    useEffect(() => {
        fetchRecentProductId();
    }, [categoryId, formType, editData])

    //For focus
    const [focusedField, setFocusedField] = useState<string | null>(null);

    const productNameRef = useRef<HTMLInputElement>(null);
    const oldProductCodeRef = useRef<HTMLInputElement>(null);
    const productCodeRef = useRef<HTMLInputElement>(null);
    const departmentRef = useRef<HTMLDivElement>(null);
    const categoryRef = useRef<HTMLDivElement>(null);
    const brandRef = useRef<HTMLDivElement>(null);
    const unitRef = useRef<HTMLDivElement>(null);
    const hsnRef = useRef<HTMLInputElement>(null);
    const gstRef = useRef<HTMLInputElement>(null);
    const reOrderRef = useRef<HTMLInputElement>(null);
    const modelNumberRef = useRef<HTMLInputElement>(null);
    const modelNameRef = useRef<HTMLInputElement>(null);
    const reMarksRef = useRef<HTMLInputElement>(null);
    const rackNumberRef = useRef<HTMLInputElement>(null);
    const taxRef = useRef<HTMLInputElement>(null);
    const uomRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (productNameRef.current) {
            productNameRef.current.focus();
            setFocusedField("productName");
        }
    }, []);

    const handleKeyDown = <T extends Element>(
        e: React.KeyboardEvent<T>,
        nextRef?: React.RefObject<HTMLElement>,
        nextField?: string,
        prevRef?: React.RefObject<HTMLElement>,
        prevField?: string
    ) => {
        const moveFocus = (ref?: React.RefObject<HTMLElement>, field?: string) => {
            if (!ref?.current) return;

            e.preventDefault();

            const activeElement = document.activeElement as HTMLElement;
            if (activeElement && 'blur' in activeElement) {
                activeElement.blur();
            }

            const input = ref.current.querySelector('input, select, textarea') || ref.current;
            if (input instanceof HTMLElement) {
                input.focus();
                setFocusedField(field || '');
            }
        };

        if (e.key === 'ArrowDown') moveFocus(nextRef, nextField);
        if (e.key === 'ArrowUp') moveFocus(prevRef, prevField);
    };


    React.useEffect(() => {
        if (searchTerm !== '') {
            searchRef.current?.focus()
        }
    }, [])


    //   pdf download functionality

    
    
    

  const downloadPDF = () => {
    const logoUrl = clients[0]?.shopPhoto;
    const doc = new jsPDF("landscape");
    const marginX = 10;
    const boxWidth = doc.internal.pageSize.width - 2 * marginX;
    const boxHeight = 25;
    let startY = 15;

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");

    // Add header with logo and shop details
    doc.rect(marginX, startY - 5, boxWidth, boxHeight);

    if (logoUrl) {
      doc.addImage(logoUrl, "JPEG", marginX + 5, startY - 2, 25, 20);
    }

    const textX = marginX + 35;

    doc.text(clients[0]?.shopFullName || "", textX, startY);
    doc.setFont("helvetica", "normal");
    doc.text(
      `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${
        clients[0]?.district || ""
      } `,
      textX,
      startY + 5
    );
    doc.text(
      `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${
        clients[0]?.contactNo1 || ""
      }`,
      textX,
      startY + 10
    );
    doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 15);

    const currentDate = new Date().toLocaleDateString("en-GB"); 
    const pageWidth = doc.internal.pageSize.width;
    doc.text(`Date: ${currentDate}`, pageWidth - marginX - 40, startY + 15);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    const heading = "Product Master List";
    const headingWidth = doc.getTextWidth(heading);
    doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 8);

    const groupedProducts = productList.reduce((acc, product) => {
      const categoryName =
        cateList.find((category) => category.id === product.categoryId)?.name ||
        "Unknown Category";
      if (!acc[categoryName]) {
        acc[categoryName] = [];
      }
      acc[categoryName].push(product);
      return acc;
    }, {} as Record<string, ProductType[]>);

    const sortedCategories = Object.keys(groupedProducts).sort();

    let currentY = startY + boxHeight + 10;

    sortedCategories.forEach((category) => {
      const products = groupedProducts[category];

      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.text(`Category: ${category}`, marginX, currentY);
      currentY += 5;

      const tableBody = products.map((item, index) => [
        (index + 1).toString(),
        item.productCode || "",
        brandList.find((brand) => brand.id === item.brandId)?.name ||
          "Unknown Brand",
        item.productName || "",
        item.sac || "",
        item.gst || "",
      ]);

      autoTable(doc, {
        startY: currentY,
        head: [
          [
            "SI.No",
            "Product Code",
            "Brand Name",
            "Product Name",
            "H.S.N",
            "GST",
          ],
        ],
        body: tableBody,
        theme: "grid",
        styles: {
          fontSize: 8,
          textColor: 0,
          lineColor: 0,
          lineWidth: 0.1,
        },
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontStyle: "bold",
          lineColor: 0,
          lineWidth: 0.1,
        },
        margin: { top: 25, left: 10, right: 10 },
        columnStyles: {
          0: { halign: "center" },
          1: { halign: "left" },
          2: { halign: "left" },
          3: { halign: "left" },
          4: { halign: "left" },
          5: { halign: "left" },
        },
      
      });

      
      currentY = (doc as any).lastAutoTable.finalY + 10;
    });

    const pageCount = doc.getNumberOfPages();
    console.log(pageCount);

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      let pageHeight = doc.internal.pageSize.height;
      let footerY = pageHeight - 15;

      doc.setFontSize(10);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, footerY);
    }
    // console.log(pageCount);

    doc.save("ProductMaster_CategoryWise.pdf");
  };
   



    const handleBrand = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            setBrandId(selectedOption.value);
        } else {
            setBrandId("");
        }
        setBrandIdError('');
    };

    const handleDepartment = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            setDepartmentId(selectedOption.value);
        } else {
            setDepartmentId("");
        }
        setDepartmentIdError('');
    };

    const handleCategory = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            setCategoryId(selectedOption.value);
        } else {
            setCategoryId("");
        }
        setCategoryIdError('');
    };

    const handleProductName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setProductName(e.target.value)
        setProductNameError('')
    }
    const handleOldProductCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOldProductCode(e.target.value)
        setOldProductCodeError('')
    }
    const handleProductCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setProductCode(e.target.value)
        setProductCodeError('')
    }
    const handleUnit = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            setUnit(selectedOption.value);
        } else {
            setUnit("");
        }
        setUnitError('');
    };
    const handleUom: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUom(e.target.value)
        setUomError('')
    }

    const handleSac = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            setSac(selectedOption.value); // Set the selected value
        } else {
            setSac(""); // Reset if no option is selected
        }
        setSacError(''); // Clear any error message
    };

    const handleGst: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGst(e.target.value)
        setGstError('')
    }
    const handleRackNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRackNumber(e.target.value)
        setRackNumberError('')
    }
    const handleReOrder: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setReOrder(value);
        setReOrderError(value < '1' ? 'Reorder level should be greater than or equal to 1' : '');
    }
    const handleBrandName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBrandName(e.target.value)
    }
    const handleDepartmentName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDepartmentName(e.target.value)
    }
    const handleCategoryName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCategoryName(e.target.value)
    }
    const handleUnitName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUnitName(e.target.value)
    }

    const handleTaxable: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTaxable(e.target.value)
    }
    const handleModelNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setModelNumber(e.target.value)
    }
    const handleModelName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setModelName(e.target.value)
    }
    const handleReMarks: ChangeEventHandler<HTMLInputElement> = (e) => {
        setReMarks(e.target.value)
    }

    const handleImageUpload: ChangeEventHandler<HTMLInputElement | HTMLFormElement> = (e) => {
        setImageUpload(e.target.files[0])
        setImageFlag(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)

        setEditData(value)

    }

    const handleDelete: HandleDelete = (value) => {
        setDeleteId(value.id)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const handleModalClose = () => {
        setOpenModal(false)
        setShowModal(false)
        setViewModal(false)
        setUnitModal(false)
    }

    const resetStates = () => {
        setLoginid('')
        setLoginidError('')
        setBrandId('')
        setBrandIdError('')
        setDepartmentId('')
        setDepartmentIdError('')
        setCategoryId('')
        setCategoryIdError('')
        setProductName('')
        setProductNameError('')
        setOldProductCode('')
        setOldProductCodeError('')
        setUnit('')
        setUnitError('')
        setUom('1')
        setUomError('')
        setSac('')
        setSacError('')
        setGst('')
        setGstError('')
        setRackNumber('')
        setRackNumberError('')
        setReOrder('1')
        setReOrderError('')
        setTaxable('')
        setTaxableError('')
        setModelName('')
        setModelNameError('')
        setModelNumber('')
        setModelNumberError('')
        setReMarks('')
        setReMarksError('')
        setBrandName('')
        setDepartmentName('')
        setCategoryName('')
        setImageUpload({ filename: "" })
        setEditData(null)
        setFormType(FormTypeList.ADD)
    }
    const viewTable = () => {
        setShowData((data) => (!data))
    }
    const handleSave = () => {
        let error = false

        const editData: ProductType = {
            id: id,
            productName: productName,
            productCode: productCode,
            brandId: brandId ? parseInt(brandId) : '',
            departmentId: departmentId ? parseInt(departmentId) : '',
            categoryId: categoryId ? parseInt(categoryId) : '',
            unit: unit,
            uom: parseInt(uom),
            sac: parseInt(sac),
            gst: gst,
            rackNumber: rackNumber,
            reOrder: parseInt(reOrder),
            taxable: taxable,
            modelNumber: parseInt(modelNumber),
            modelName: modelName,
            reMark: reMarks,
            imageUrl: imageUrl

        }

        if (productName === '') {
            setProductNameError('Product name required')
            error = true
        } else if ((productName || '').length > 50) {
            setProductNameError('Product name should be within 50 characters')
            error = true
        }

        if ((oldProductCode || '').length > 15) {
            setOldProductCodeError('Old product code should be within 15 characters')
            error = true
        }

        if (productCode === '') {
            setProductCodeError('Product code required')
            error = true
        }

        if (departmentId === '') {
            setDepartmentIdError('Department required')
            error = true
        }

        if (categoryId === '') {
            setCategoryIdError('Category required')
            error = true
        }

        if (brandId === '') {
            setBrandIdError('Brand required')
            error = true
        }

        if (unit === '') {
            setUnitError('Unit required')
            error = true
        }

        if (gst === '') {
            setGstError('GST required')
            error = true
        }

        if (reOrder === '') {
            setReOrderError('Re-Order required')
            error = true
        }

        if (taxable === '') {
            setTaxableError('Taxable required')
            error = true
        }

        if (sac?.toString() === '') {
            setSacError('HSN/SAC required')
            error = true
        } else if ((sac?.toString().trim() || '').length > 8) {
            setSacError('HSN/SAC should be within 8 Digits')
            error = true
        }

        if (!/^\d+$/.test(uom) || parseInt(uom) === 0) {
            setUomError('Value should be greater than zero and should be a number');
            error = true;
        }

        if (!error) {
            const data = new FormData()
            data.append('productName', productName ? productName : '')
            data.append('oldProductCode', oldProductCode ? oldProductCode : '')
            data.append('productCode', productCode ? productCode : '')
            data.append('brandId', brandId ? brandId : '')
            data.append('departmentId', departmentId ? departmentId : '')
            data.append('categoryId', categoryId ? categoryId : '')
            data.append('unit', unit ? unit : '')
            data.append('uom', uom ? uom : '')
            data.append('sac', sac ? sac : '')
            data.append('gst', gst ? gst : '')
            data.append('rackNumber', rackNumber ? rackNumber : '')
            data.append('reOrder', reOrder ? reOrder : '')
            data.append('taxable', taxable ? taxable : '')
            data.append('modelNumber', modelNumber ? modelNumber : '')
            data.append('modelName', modelName ? modelName : '')
            data.append('reMark', reMarks ? reMarks : '')
            data.append('imageUrl', imageUpload)
            if (imageFlag) {
                data.append('oldImageUrl', editData?.imageUrl ? imageUrl : '')
            }

            if (formType === FormTypeList.ADD) {

                dispatch(addProductAction(data)).then(text => {
                    toast('Added', ToastTypes.SUCCESS)
                    resetStates()
                    fetchRecentProductId();
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(editProductAction(data, editData.id)).then(text => {
                    toast('Updated', ToastTypes.SUCCESS)
                    resetStates()
                    setEditData(null)
                    setFormType(FormTypeList.ADD)
                    fetchRecentProductId();
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {

        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {

            setId(editData.id)
            // setProductNo(editData.productNo?.toString() || '')
            setCategoryId(editData.categoryId?.toString() || '')
            setDepartmentId(editData.departmentId?.toString() || '')
            setBrandId(editData.brandId?.toString() || '')
            setProductName(editData.productName || '')
            setOldProductCode(editData.oldProductCode ? editData.oldProductCode.toString() : '')
            setProductCode(editData.productCode ? editData.productCode.toString() : '')
            setUnit(editData.unit || '')
            setUom(editData.uom?.toString() || '')
            setSac(editData.sac?.toString() || '')
            setGst(String(editData.gst))
            setRackNumber(editData.rackNumber || '')
            setReOrder(editData.reOrder?.toString() || '')
            setTaxable(editData.taxable || '')
            setModelNumber(editData.modelNumber?.toString() || '')
            setModelName(editData.modelName || '')
            setReMarks(editData.reMark || '')

        } else {
            resetStates()
        }
    }, [formType, editData])

    //add brand
    const onManufacture = (newValue: any) => {
        const data = new FormData()
        data.append('name', newValue)
        setBrandName(newValue)

        dispatch(addBrandAction(data)).then((response) => {
            const newBrandId = response.id;
            setBrandId(newBrandId?.toString());

            toast(response.message, ToastTypes.SUCCESS);

        }).catch(text => {
            toast(text, ToastTypes.ERROR)
        })

        setOpenModal(false)
    }

    //add department
    const onDepartment = (newValue: any) => {
        const data = new FormData();
        data.append('name', newValue);
        setDepartmentName(newValue);

        dispatch(addDepartmentAction(data))
            .then((response) => {
                const newDepartmentId = response.id;
                setDepartmentId(newDepartmentId?.toString());

                toast(response.message, ToastTypes.SUCCESS);
            })
            .catch((error) => {
                toast(error, ToastTypes.ERROR);
            });

        setShowModal(false);  // Close the modal after the department is added
    };


    //add category
    const onCategory = (newValue: any) => {
        const data = new FormData()
        data.append('name', newValue)
        data.append('departmentId', departmentId ? departmentId.toString() : '')
        setCategoryName(newValue)

        dispatch(addCategoryAction(data)).then((response) => {
            const newCategoryId = response.id;
            setCategoryId(newCategoryId?.toString());

            toast(response.message, ToastTypes.SUCCESS);
        }).catch(text => {
            toast(text, ToastTypes.ERROR)
        })

        setViewModal(false)
    }
    const onUnit = (newValue: any) => {

        if (newValue.length > 3) {
            setUnitErrorInModel('Unit should be 3 characters or below');
            return;
        }

        const addUnitData: DropDownType = {
            id: id,
            category: "unit",
            title: newValue
        }

        setUnit(newValue)

        dispatch(addDropDownAction(addUnitData)).then(text => {
            toast('Added Unit', ToastTypes.SUCCESS)
            setUnitName(newValue)
            setUnit(newValue)
            // setEditData(null)
        }).catch(text => {
            toast(text, ToastTypes.ERROR)
        })

        setUnitModal(false)
    }
    const onDelete = () => {
        if (deleteId) {
            dispatch(deleteProductAction(deleteId)).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
                resetStates()
            }).catch(text => {

            })
        }
    }

    const filterCategoriesByDepartment = (categories: CategoryType[], selectedDepartmentId: string): CategoryType[] => {
        return categories.filter((category) => category.departmentId === selectedDepartmentId);
    };

    return (
      <>
        <Modal
          headerText={"Delete Product Master"}
          visible={showDeleteForm}
          onClose={handleDeleteFormClose}
          centered
          size="lg"
        >
          <DeleteConfirmationForm
            onDelete={onDelete}
            onClose={handleDeleteFormClose}
          />
        </Modal>
        <Modal
          headerText={"Add Brand"}
          visible={openModal}
          onClose={handleModalClose}
          centered
          size="sm"
        >
          <FormInput
            name="brandName"
            value={brandName}
            onChange={handleBrandName}
            className="mb-3"
          />
          <button
            className="btn btn-sm fw-bold btn-danger px-2"
            onClick={() => resetStates()}
          >
            Add New
          </button>
          <button
            type="button"
            className="btn btn-sm fw-bold btn-primary m-2"
            onClick={onManufacture}
          >
            save
          </button>
        </Modal>
        <Modal
          headerText={"Add Department"}
          visible={showModal}
          onClose={handleModalClose}
          centered
          size="sm"
        >
          <FormInput
            name="departmentName"
            value={departmentName}
            onChange={handleDepartmentName}
            className="mb-3"
          />
          <button
            className="btn btn-sm fw-bold btn-danger px-2"
            onClick={() => resetStates()}
          >
            Add New
          </button>
          <button
            type="button"
            className="btn btn-sm fw-bold btn-primary m-2"
            onClick={onDepartment}
          >
            save
          </button>
        </Modal>
        <Modal
          headerText={"Add Category"}
          visible={viewModal}
          onClose={handleModalClose}
          centered
          size="sm"
        >
          <FormInput
            name="categoryName"
            value={categoryName}
            onChange={handleCategoryName}
            className="mb-3"
          />
          <button
            className="btn btn-sm fw-bold btn-danger px-2"
            onClick={() => resetStates()}
          >
            Add New
          </button>
          <button
            type="button"
            className="btn btn-sm fw-bold btn-primary m-2"
            onClick={onCategory}
          >
            save
          </button>
        </Modal>
        <Modal
          headerText={"Add Unit"}
          visible={unitModal}
          onClose={handleModalClose}
          centered
          size="sm"
        >
          <FormInput
            name="unit"
            value={unitName}
            onChange={handleUnitName}
            errorText={unitErrorInModel}
            className="mb-3"
          />
          <button
            className="btn btn-sm fw-bold btn-danger px-2"
            onClick={() => resetStates()}
          >
            Add New
          </button>
          <button
            type="button"
            className="btn btn-sm fw-bold btn-primary m-2"
            onClick={onUnit}
          >
            save
          </button>
        </Modal>
        <UserLayout>
          <h4>Product Master</h4>
          <div className="row">
            <div className={showData ? "col-lg-8" : "col-lg-12"}>
              <div className="card shadow-lg p-1">
                <div className="text-end my-2">
                  <button
                    className="btn btn-sm fw-bold btn-success"
                    style={{ float: "right" }}
                    onClick={() => viewTable()}
                  >
                    {showData ? "Hide" : "View"}
                  </button>
                  <button
                    className="btn btn-sm fw-bold btn-secondary me-1"
                    style={{ float: "right" }}
                    onClick={() => resetStates()}
                  >
                    Add New
                  </button>
                </div>
                <div className="card-body p-2">
                  <div className="row ">
                    {/* <div
                      className="col-sm-6 col-md-6 col-lg-4 hstack"
                      style={{
                        height: "40px",
                        lineHeight: "normal",
                        padding: "0px 12px",
                      }}
                    >
                      <div className="col-4">
                        <label htmlFor="">Old Product Code</label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="oldProductCode"
                          label=""
                          height="35px"
                          ref={oldProductCodeRef}
                          value={oldProductCode || "0"}
                          onChange={handleOldProductCode}
                          errorText={oldProductCodeError}
                          className={
                            focusedField === "oldProductCode"
                              ? "border border-2 border-primary"
                              : ""
                          }
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              productCodeRef,
                              "productCode",
                              undefined,
                              undefined
                            )
                          }
                          onClick={() => setFocusedField("oldProductCode")}
                        />
                      </div>
                    </div> */}

                    <div
                      className="col-sm-6 col-md-6 col-lg-8 hstack"
                      style={{
                        height: "40px",
                        lineHeight: "normal",
                        padding: "0px 12px",
                      }}
                    >
                      <div className="col-2">
                        <label htmlFor="">
                          Product Name<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div
                        style={
                          showData ? { width: "558px" } : { width: "852px" }
                        }
                      >
                        <FormInput2
                          name="productName"
                          label=""
                          labelClassName="required"
                          height="35px"
                          ref={productNameRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              productCodeRef,
                              "productCode",
                              undefined,
                              undefined
                            )
                          }
                          className={
                            focusedField === "productName"
                              ? "border border-2 border-primary"
                              : ""
                          }
                          required={true}
                          value={productName}
                          onChange={handleProductName}
                          errorText={productNameError}
                          onClick={() => setFocusedField("productName")}
                        />
                      </div>
                    </div>
                    <div
                      className="col-sm-4 col-md-5 col-lg-4 hstack"
                      style={{
                        height: "40px",
                        lineHeight: "normal",
                        padding: "0px 12px",
                      }}
                    >
                      <div className="col-4">
                        <label htmlFor="">Model Number</label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="modelNumber"
                          label=""
                          labelClassName="required"
                          height="35px"
                          value={modelNumber}
                          onChange={handleModelNumber}
                          ref={modelNumberRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              modelNameRef,
                              "modelName",
                              uomRef,
                              "uom"
                            )
                          }
                          errorText={modelNumberError}
                          className={
                            focusedField === "modelNumber"
                              ? "border border-2 border-primary"
                              : ""
                          }
                          onClick={() => setFocusedField("modelNumber")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div
                      className="col-sm-6 col-md-6 col-lg-4 hstack"
                      style={{
                        height: "70px",
                        lineHeight: "normal",
                        padding: "0px 12px",
                      }}
                    >
                      <div className="col-4">
                        <label htmlFor="">
                          Product Code<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="productCode"
                          label=""
                          labelClassName="required"
                          required={true}
                          height="35px"
                          value={productCode}
                          ref={productCodeRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              departmentRef,
                              "department",
                              oldProductCodeRef,
                              "oldProductCode"
                            )
                          }
                          errorText={productCodeError}
                          type="number"
                          readOnly={formType !== FormTypeList.ADD}
                          containerClass="w-100 read-only-field"
                          className={
                            focusedField === "productCode"
                              ? "border border-2 border-primary"
                              : ""
                          }
                          onClick={() => setFocusedField("productCode")}
                          onChange={handleProductCode}
                        />
                      </div>
                    </div>
                    <div
                      className="col-sm-4 col-md-3 col-lg-4 hstack"
                      style={{
                        height: "60px",
                        lineHeight: "normal",
                        padding: "0px 12px",
                      }}
                    >
                      <div className="col-4">
                        <label htmlFor="">
                          H.S.N/SAC<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div
                        className="col-7"
                        onClick={() => setFocusedField("sac")}
                      >
                        <CreatableDropdown
                          id="sac"
                          label=""
                          labelClassName="required"
                          required={true}
                          value={sac}
                          onChange={handleSac}
                          ref={hsnRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              gstRef,
                              "gst",
                              categoryRef,
                              "category"
                            )
                          }
                          className={
                            focusedField === "hsn"
                              ? "border border-2 border-primary"
                              : ""
                          }
                          options={sacOptions.map((sac) => ({
                            label: sac,
                            value: sac,
                          }))}
                          onCreateOption={(newValue) => {
                            const newSac = newValue.trim();
                            if (newSac && !sacOptions.includes(newSac)) {
                              console.log("New SAC Created:", newSac);
                              setSac(newSac);
                              setSacOptions((prev) => [...prev, newSac]);
                              setSacError("");
                            } else {
                              setSacError("Please provide a unique SAC");
                            }
                          }}
                          errorText={sacError}
                        />
                      </div>
                    </div>
                    <div
                      className="col-sm-4 col-md-5 col-lg-4 hstack"
                      style={{
                        height: "70px",
                        lineHeight: "normal",
                        padding: "0px 12px",
                      }}
                    >
                      <div className="col-4">
                        <label htmlFor="">Model Name</label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="modelName"
                          label=""
                          labelClassName="required"
                          height="35px"
                          value={modelName}
                          onChange={handleModelName}
                          ref={modelNameRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              reOrderRef,
                              "reOrder",
                              modelNumberRef,
                              "modelNumber"
                            )
                          }
                          errorText={modelNumberError}
                          className={
                            focusedField === "modelName"
                              ? "border border-2 border-primary"
                              : ""
                          }
                          onClick={() => setFocusedField("modelName")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div
                      className="col-sm-6 col-md-4 col-lg-4 hstack
                    "
                      style={{
                        height: "40px",
                        lineHeight: "normal",
                        padding: "0px 12px",
                      }}
                    >
                      <div className="col-4">
                        <label htmlFor="">
                          Department<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div
                        className="col-7"
                        onClick={() => setFocusedField("department")}
                      >
                        <CreatableDropdown
                          id="departmentId"
                          label=""
                          labelClassName="required"
                          required={true}
                          value={departmentId}
                          onChange={handleDepartment}
                          onCreateOption={onDepartment}
                          ref={departmentRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              brandRef,
                              "brand",
                              productCodeRef,
                              "productCode"
                            )
                          }
                          className={
                            focusedField === "department"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                          options={departmentList.map((dl: DepartmentType) => ({
                            label: dl.name || "Unknown Department",
                            value: dl.id?.toString() || "",
                          }))}
                          errorText={departmentIdError}
                        />
                      </div>
                    </div>
                    <div
                      className="col-sm-4 col-md-3 col-lg-4 hstack"
                      style={{
                        height: "70px",
                        lineHeight: "normal",
                        padding: "0px 12px",
                      }}
                    >
                      <div className="col-4">
                        <label htmlFor="">
                          GST %<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="gst"
                          label=""
                          labelClassName="required"
                          required={true}
                          height="35px"
                          value={gst}
                          onChange={handleGst}
                          onClick={() => setFocusedField("gst")}
                          ref={gstRef}
                          onKeyDown={(e) =>
                            handleKeyDown(e, unitRef, "unit", hsnRef, "hsn")
                          }
                          className={
                            focusedField === "gst"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                          errorText={gstError}
                          type="select"
                        >
                          <option value="">Select</option>
                          {[0, 5, 12, 18, 28].map((gst) => (
                            <option key={gst} value={gst}>
                              {gst}
                            </option>
                          ))}
                        </FormInput2>
                      </div>
                    </div>

                    <div className="col-sm-4 col-md-5 col-lg-4 hstack">
                      <div className="col-4">
                        <label htmlFor="">
                          Re-Order Level<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="reOrder"
                          label=""
                          labelClassName="required"
                          height="35px"
                          required={true}
                          value={reOrder}
                          ref={reOrderRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              rackNumberRef,
                              "rackNumber",
                              modelNameRef,
                              "modelName"
                            )
                          }
                          className={
                            focusedField === "reOrder"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                          onChange={handleReOrder}
                          errorText={reOrderError}
                          type="number"
                          onClick={() => setFocusedField("reOrder")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1 ">
                    <div
                      className="col-sm-6 col-md-12 col-lg-4 hstack"
                      style={{
                        height: "20px",
                        lineHeight: "normal",
                        padding: "0px 12px",
                      }}
                    >
                      <div className="col-4">
                        <label htmlFor="">
                          Brand<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div
                        className="col-7"
                        onClick={() => setFocusedField("brand")}
                      >
                        <CreatableDropdown
                          id="brand"
                          label=""
                          labelClassName="required"
                          required={true}
                          value={brandId || ""}
                          onChange={handleBrand}
                          onCreateOption={onManufacture}
                          ref={brandRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              categoryRef,
                              "category",
                              departmentRef,
                              "department"
                            )
                          }
                          className={
                            focusedField === "brand"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                          options={brandList.map((bd: BrandType) => ({
                            label: bd.name || "Unknown Brand",
                            value: bd.id?.toString() || "",
                          }))}
                          errorText={brandIdError}
                        />
                      </div>
                    </div>

                    <div
                      className="col-sm-4 col-md-3 col-lg-4 hstack"
                      style={{
                        height: "20px",
                        lineHeight: "normal",
                        padding: "0px 12px",
                      }}
                    >
                      <div className="col-4">
                        <label htmlFor="">
                          UOM<span className="text-danger"> *</span>
                        </label>
                      </div>

                      <div
                        className="col-7"
                        onClick={() => setFocusedField("unit")}
                      >
                        <CreatableDropdown
                          id="uom"
                          label=""
                          labelClassName="required"
                          required={true}
                          value={unit}
                          onChange={handleUnit}
                          onCreateOption={onUnit}
                          ref={unitRef}
                          onKeyDown={(e) =>
                            handleKeyDown(e, uomRef, "uom", gstRef, "gst")
                          }
                          className={
                            focusedField === "unit"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                          options={unitLists
                            .filter(
                              (unit: DropDownType) => unit.category === "unit"
                            )
                            .map((unit) => ({
                              label: unit.title,
                              value: unit.title,
                            }))}
                          errorText={unitError}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4 col-md-5 col-lg-4 hstack">
                      <div className="col-4">
                        <label htmlFor="">Rack Number</label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="rackNumber"
                          label=""
                          labelClassName="required"
                          height="35px"
                          value={rackNumber}
                          onChange={handleRackNumber}
                          onClick={() => setFocusedField("rackNumber")}
                          ref={rackNumberRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              taxRef,
                              "tax",
                              reOrderRef,
                              "reOrder"
                            )
                          }
                          errorText={rackNumberError}
                          className={
                            focusedField === "rackNumber"
                              ? "border border-2 border-primary"
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div
                      className="col-sm-6 col-md-8 col-lg-4 hstack"
                      style={{
                        height: "40px",
                        lineHeight: "normal",
                        padding: "0px 12px",
                      }}
                    >
                      <div className="col-4">
                        <label htmlFor="">
                          Category<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div
                        className="col-7"
                        onClick={() => setFocusedField("category")}
                      >
                        <CreatableDropdown
                          id="category"
                          label=""
                          labelClassName="required"
                          required={true}
                          value={categoryId || ""}
                          onChange={handleCategory}
                          onCreateOption={onCategory}
                          ref={categoryRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              hsnRef,
                              "hsn",
                              brandRef,
                              "brand"
                            )
                          }
                          className={
                            focusedField === "category"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                          options={cateList
                            .filter(
                              (cl: CategoryType) =>
                                cl.departmentId?.toString() ===
                                (departmentId !== ""
                                  ? departmentId?.toString()
                                  : cl.departmentId?.toString())
                            )
                            .map((cl: CategoryType) => ({
                              label: cl.name || "Unknown Department",
                              value: cl.id?.toString() || "",
                            }))}
                        />
                      </div>
                    </div>

                    <div
                      className="col-sm-4 col-md-3 col-lg-4 hstack"
                      style={{
                        height: "60px",
                        lineHeight: "normal",
                        padding: "0px 12px",
                      }}
                    >
                      <div className="col-4">
                        <label htmlFor="">Unit</label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="unit"
                          label=""
                          labelClassName="required"
                          height="35px"
                          value={uom}
                          onChange={handleUom}
                          errorText={uomError}
                          type="number"
                          ref={uomRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              modelNumberRef,
                              "modelNumber",
                              unitRef,
                              "unit"
                            )
                          }
                          className={
                            focusedField === "uom"
                              ? "border border-2 border-primary"
                              : ""
                          }
                          onClick={() => setFocusedField("uom")}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4 col-md-5 col-lg-4 hstack">
                      <div className="col-4">
                        <label htmlFor="">
                          Tax Allowed<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="taxable"
                          label=""
                          labelClassName="required"
                          height="35px"
                          required={true}
                          value={taxable}
                          ref={taxRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              reMarksRef,
                              "reMarks",
                              rackNumberRef,
                              "rackNumber"
                            )
                          }
                          className={
                            focusedField === "tax"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                          onChange={handleTaxable}
                          errorText={taxableError}
                          type="select"
                          onClick={() => setFocusedField("tax")}
                        >
                          <option value="">Select</option>
                          {["yes", "no"].map((tax) => (
                            <option key={tax} value={tax}>
                              {tax}
                            </option>
                          ))}
                        </FormInput2>
                      </div>
                    </div>
                    {/* another input */}
                  </div>
                  <div className="row mt-3 mb-1">
                    <div className="col-sm-4 col-md-5 col-lg-8 hstack">
                      <div className="col-2">
                        <label htmlFor="">Re-Marks</label>
                      </div>
                      <div
                        style={
                          showData ? { width: "555px" } : { width: "855px" }
                        }
                      >
                        <FormInput
                          name="reMarks"
                          label=""
                          labelClassName="required"
                          value={reMarks}
                          onChange={handleReMarks}
                          ref={reMarksRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              undefined,
                              undefined,
                              taxRef,
                              "tax"
                            )
                          }
                          errorText={reMarksError}
                          type="textarea"
                          className={
                            focusedField === "reMarks"
                              ? "border border-2 border-primary"
                              : ""
                          }
                          onClick={() => setFocusedField("reMarks")}
                        />
                      </div>
                    </div>
                  </div>

                  {(formType === FormTypeList.ADD ||
                    formType === FormTypeList.EDIT) && (
                    <div className="d-flex justify-content-end hstack gap-1 mt-2">
                      {rolePermissions?.add && (
                        <button
                          className="btn btn-sm fw-bold btn-success m-2"
                          onClick={handleSave}
                        >
                          {editData ? "Update" : "Save"}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {rolePermissions?.view && showData ? (
              <div className="col-lg-4">
                <div
                  className="card shadow-lg p-1"
                  style={{ height: "510px", overflow: "auto" }}
                >
                  <div className="d-flex justify-content-end align-items-center gap-3 p-2">
                    <h2>
                      <Badge bg="success">
                        Count:
                        <Badge pill bg="danger">
                          {productList.length}
                        </Badge>
                      </Badge>
                    </h2>

                    <button
                      className="btn btn-primary d-flex align-items-center justify-content-center"
                      style={{ height: "40px" }}
                      onClick={downloadPDF}
                    >
                      <i className="fe-download me-1"></i> Download PDF
                    </button>
                  </div>
                  <div className="mb-2">
                    <FormInput2
                      onChange={searchTermChangeHandler}
                      name=""
                      placeholder="Search..."
                      value={searchTerm}
                      onClick={() => {
                        setFocusedField("search");

                        mainContentRef?.current?.scrollTo({
                          behavior: "smooth",
                          top: 270,
                        });
                      }}
                      onBlur={()=>setFocusedField(null)}
                      ref={searchRef}
                      className={
                        focusedField === "search"
                          ? "border border-2 border-primary rounded"
                          : ""
                      }
                    />
                  </div>
                  <div className="table-wrapper">
                    {/* <div style={{ height: "43vh", overflowY: "auto" }}> */}
                    <table className="table my-2">
                      <thead>
                        <tr>
                          <th className="text-truncate align-middle">S.No</th>
                          <th className="text-truncate align-middle">
                            Product Name
                          </th>
                          <th className="text-truncate align-middle">
                            Product Code
                          </th>
                          {(rolePermissions?.edit ||
                            rolePermissions?.delete) && (
                            <th className="text-truncate align-middle">
                              Action
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {productList
                          .filter((product) =>
                            filterProductHandler(product, searchTerm)
                          )
                          .map((product, i) => {
                            return (
                              <ProductBody
                                sno={i + 1}
                                productName={product.productName || ""}
                                productCode={product.productCode || ""}
                                onEdit={() => handleEdit(product)}
                                onDelete={() => handleDelete(product)}
                                rolePermissions={rolePermissions}
                              />
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* </div> */}
          </div>
        </UserLayout>
      </>
    );
}

const ProductBody = ({ sno, productName, productCode, onEdit, onDelete, rolePermissions }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{productName}</td>
        <td className='text-capitalize align-middle'>{productCode}</td>

        {(rolePermissions?.edit || rolePermissions?.delete) && <td className="align-middle">
            <div className='hstack justify-content-center gap-1'>
                {rolePermissions?.edit && <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>}
                {rolePermissions?.delete && <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>}
            </div>
        </td>}
    </tr>
}
export default ProductMaster;