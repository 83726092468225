import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { DepartmentType, addDepartmentAction, deleteDepartmentAction, editDepartmentAction, fetchDepartmentAction } from "../redux/actions/departmentAction"
import { AppDispatch, StoreState } from "../redux/store"
import { AuthUserType, RoleType } from "../redux/actions/authUserActions"

interface TableBody {
    sno: number,
    departmentName: string | undefined,
    // viewImage: string | undefined,
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    rolesAccessObj:RoleType|undefined

}

type HandleEdit = (value: DepartmentType) => void
type HandleDelete = (value: DepartmentType) => void

const Department = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const DepartmentList = useSelector<StoreState, DepartmentType[]>(state => state.department)

    useEffect(() => {
        dispatch(fetchDepartmentAction())
    }, [dispatch])


    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<DepartmentType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [id, setId] = useState<number>()

    const [departmentName, setDepartmentName] = useState<string>('')
    const [departmentNameError, setDepartmentNameError] = useState<string>('')

    const [imageUpload, setImageUpload] = useState<any | null>({ filename: '' })
    const [imageUrl, setImageUrl] = useState<any>('')
    const [imageFlag, setImageFlag] = useState<boolean>(false)

    const Authuser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const url = window.location.pathname
    const rolesAccessObj = Authuser?.roles?.find(f => f.pageUrl === url)

    const filteredDept = DepartmentList.filter((data)=>
        data.name?.toLocaleLowerCase().includes(departmentName.toLocaleLowerCase())
    )

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDepartmentName(e.target.value)
        setDepartmentNameError('')
    }
    const handleImageUpload: ChangeEventHandler<HTMLInputElement | HTMLFormElement> = (e) => {
        setImageUpload(e.target.files[0])
        setImageFlag(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)

    }

    const handleDelete: HandleDelete = (value) => {
        setDeleteId(value.id)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const resetStates = () => {

        setDepartmentName('')
        setEditData(null)

    }

    const handleSave = () => {
        let error = false
        // const addData: DepartmentType = {
        //     category:"department",
        //     title:departmentName

        // }

        const edata: DepartmentType = {
            id: id,
            name: departmentName,
            imageUrl: imageUrl
        }
        if (departmentName.trim() === '') {
            setDepartmentNameError('Department required')
            error = true
        } else if ((departmentName.trim() || '').length > 30) {
            setDepartmentNameError('Department should be within 30 characters')
            error = true
        }




        if (!error) {
            const data = new FormData()
            data.append('name', departmentName)
            data.append('imageUrl', imageUpload)
            if (imageFlag) {
                data.append('oldImageUrl', editData?.imageUrl ? imageUrl : '')
            }
            if (formType === FormTypeList.ADD) {
                dispatch(addDepartmentAction(data)).then(text => {
                    toast('Added', ToastTypes.SUCCESS)
                    resetStates()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== null) {
                dispatch(editDepartmentAction(data, editData.id)).then(text => {
                    toast('Updated', ToastTypes.SUCCESS)
                    resetStates()
                    setEditData(null)
                    setFormType(FormTypeList.ADD)
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }


    useEffect(() => {
        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {
            setId(editData.id)
            setDepartmentName(editData.name || '')
        } else {
            resetStates()
        }
    }, [formType, editData])


    const onDelete = () => {
        if (deleteId) {
            dispatch(deleteDepartmentAction(deleteId)).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
                resetStates()
            }).catch(text => {

            })
        }
    }
    return <>
        <Modal
            headerText={'Delete Department'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <DeleteConfirmationForm
                onDelete={onDelete}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <UserLayout>
            <h4>Department Master</h4>
            <div className="container">
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="hstack justify-content-center gap-3">
                            <label htmlFor="" className="mb-2">Department Name</label>
                            <FormInput
                                name='departmentName'
                                // label='Department'
                                labelClassName="required"
                                value={departmentName}
                                onChange={handleName}
                                errorText={departmentNameError}
                                containerClass="mb-2"
                            />
                            {/* <FormInput
                                name='imageUpload'
                                value={imageUpload.filename}
                                onChange={handleImageUpload}
                                type={"file"}
                                className="mb-2"
                            /> */}
                            {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) &&
                                <>
                                    < button className="btn btn-sm fw-bold btn-secondary mb-2" onClick={() => resetStates()}>Add New</button>
                                    {(editData ? rolesAccessObj?.edit : rolesAccessObj?.add) && 
                                    <button className="btn btn-sm fw-bold btn-success mb-2" onClick={handleSave}>{editData ? "Update" : "Save"}</button>}
                                </>
                            }

                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
                {rolesAccessObj?.view && <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <div className="card mb-0 p-1">
                            <div className='table-wrapper'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className='text-truncate align-middle'>S.No</th>
                                            <th className='text-truncate align-middle'>Name</th>
                                            {/* <th className='text-truncate align-middle'>Image Url</th> */}
                                            {(rolesAccessObj?.edit||rolesAccessObj?.delete)&& 
                                            <th className='text-truncate align-middle'>Actions</th>}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredDept.map((dp, i) => {
                                            return <DepartBody
                                                sno={i + 1}
                                                departmentName={dp.name}
                                                // viewImage={dp.imageUrl}
                                                key={dp.id}
                                                onEdit={() => handleEdit(dp)}
                                                onDelete={() => handleDelete(dp)}
                                                rolesAccessObj={rolesAccessObj}
                                            />
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>}
            </div>
        </UserLayout>
    </>
}

const DepartBody = ({ sno, departmentName, onEdit, onDelete,rolesAccessObj }: TableBody) => {

    const [image, setImage] = React.useState<any>('')

    // React.useEffect(() => {
    //     const config = store.getState().authUser?.['auth-token']!
    //     fetch(apiUrl + 'image/department' + viewImage, {
    //         headers: {
    //             'auth-token': store.getState().authUser?.['auth-token']!
    //         }
    //     }).then(async (res) => {
    //         const blob = await res.blob()
    //         const fileURL = window.URL.createObjectURL(blob);
    //         setImage(fileURL)
    //     });
    // }, [viewImage]);
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{departmentName}</td>
        {/* <td className='text-capitalize align-middle'><img src={image} style={{ margin: "3px" }} className="img-thumbnail w-100px" width={100} height={100} /></td> */}
        <td className="align-middle">
            {(rolesAccessObj?.edit || rolesAccessObj?.delete) && <div className='hstack justify-content-center gap-1'>
                {rolesAccessObj?.edit && <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>}
                {rolesAccessObj?.delete && <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>}
            </div>}
        </td>
    </tr>
}
export default Department

