import { ChangeEventHandler, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import api from "../config/api";
import { AccountHolderType } from "../redux/actions/accountHolder";
import { StoreState } from "../redux/store";
import FormInput from "./custom/components/FormInput";
import Option from "./Option";
import FormInput2 from "./custom/components/FormInput2";
import { useSearchParams } from "react-router-dom";
import React from "react";

interface PurchaseReturnFormBySupplierProps {
    onSave: (result: { data: any; invoiceReturnNo: string; invoiceReturnDate: string }) => void;
}

const PURCHASE_TYPE_DROPS = [
    {
        text: 'PURCHASE RETURN TAX INVOICE',
        value: 'PURCHASE RETURN TAX INVOICE'
    },
    // {
    //     text: 'PURCHASE ESTIMATE',
    //     value: 'PURCHASE ESTIMATE'
    // },
    // {
    //     text: 'OPENING STOCK',
    //     value: 'OPENING STOCK'
    // },
]

export interface Dropdown {
    label: string;
    value: string;
    grandTotal?: number;
}

interface Product {
    id: string;
    product_name: string;
    item_quantity: string;
    free_item_quantity: string;
    total_item_quantity: string;
    quantity: string;
    free_quantity: string;
    total_quantity: string;
}

const PurchaseReturnFormBySupplier: React.FC<PurchaseReturnFormBySupplierProps> = ({ onSave }) => {
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(
        (state) => state.account
    );
    const [supplier, setSupplier] = useState<Dropdown | null>(null);
    const [invoice, setInvoice] = useState<Dropdown | null>(null);
    const [invoiceList, setInvoiceList] = useState<Dropdown[]>([]);
    const [grandTotal, setGrandTotal] = useState<number | null>(null);
    const [invoiceReturnNo, setInvoiceReturnNo] = useState<string>('');
    const [invoiceReturnDate, setInvoiceReturnDate] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [purchaseItems, setPurchaseItems] = useState<Product[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [purchaseReturnType, setPurchaseReturnType] = useState<string>(PURCHASE_TYPE_DROPS[0].value)
    const [purchaseReturnTypeError, setPurchaseReturnTypeError] = useState<string>('')
    const supplierList = AccountLists.filter(a => a.groupName === 'Supplier' || a.groupName === 'supplier')
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchQuery, setSearchQuery] = useSearchParams();
    const searchTerm = searchQuery.get('search') || ''

    const searchRef = React.useRef<HTMLInputElement>(null);
     const mainContentRef = React.useRef<HTMLDivElement>(null)

    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
            setSearchQuery({ search: e.target.value })
    }

     const filterAccountHandler = (purchaseItem: any, searchTerm: any) => {
            return searchTerm !== '' ? (new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(purchaseItem.product_name)) : true || []
        }
    
        React.useEffect(() => {
            if (searchTerm !== '') {
                searchRef.current?.focus()
            }
        }, [])

    const handlePurchaseType: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPurchaseReturnType(e.target.value)
        setPurchaseReturnTypeError('')
    }

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            minHeight: "25px",
            height: "25px",
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            height: "25px",
            padding: "0 5px",
        }),
        input: (provided: any) => ({
            ...provided,
            margin: "0px",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            height: "30px",
        }),
    };

    useEffect(() => {
        const fetchInvoiceList = async () => {
            if (!supplier) {
                setInvoiceList([]);
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const response = await api().get(
                    `/purchase/?supplierId=${supplier.value}&invoiceNo=null&invoiceDate=null&purchaseItemsId=null`
                );

                const purchaseData = response.data.data;

                setInvoiceList(
                    purchaseData.map((item: any) => ({
                        label: `${item.invoice_number.toString() || ""} - ${item.invoice_date || ""}`,
                        value: item.id,
                    }))
                );
            } catch (err) {
                console.error("Error fetching invoice list:", err);
                setError("Failed to fetch invoice list. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchInvoiceList();
    }, [supplier]);

    useEffect(() => {
        const fetchGrandTotal = async () => {
            if (!supplier || !invoice) {
                setGrandTotal(null);
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const selectedProductsParam = selectedProducts.join(",");
                const response = await api().get(
                    `/purchase/?supplierId=${supplier.value}&invoiceNo=${invoice.label.split(" - ")[0]}&invoiceDate='${invoice.label.split(" - ")[1]}'&purchaseItemsId=null`
                );

                const invoiceData = response.data.data;
                const productData = invoiceData.flatMap((item: any) => item.PurchaseItems);

                if (invoiceData.length > 0) {
                    setGrandTotal(invoiceData[0]?.grand_total || null);
                } else {
                    setGrandTotal(null);
                }

                setPurchaseItems(
                    productData.map((item: any) => ({
                        id: item.id,
                        product_name: item.product_name,
                        item_quantity: item.quantity,
                        free_item_quantity: item.free_quantity,
                        total_item_quantity: item.total_quantity,
                        quantity: item.stock_quantity,
                        free_quantity: item.stock_free_quantity,
                        total_quantity: item.stock_total_quantity
                    }))
                );
            } catch (err) {
                console.error("Error fetching grand total:", err);
                setError("Failed to fetch grand total. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchGrandTotal();
    }, [supplier, invoice]);

    const handleCheckboxChange = (productId: string) => {
        setSelectedProducts((prevSelected) => {
            if (prevSelected.includes(productId)) {
                return prevSelected.filter((id) => id !== productId);
            }
            return [...prevSelected, productId];
        });
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedProducts([]);
        } else {
            setSelectedProducts(purchaseItems.map((item) => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSave = async () => {
        if (!supplier || !invoice) {
            return;
        }

        const errors = [];
        if (!invoiceReturnNo) {
            errors.push("Invoice Return No is required");
        }
        if (!invoiceReturnDate) {
            errors.push("Invoice Return Date is required");
        }

        if (errors.length > 0) {
            setError(errors.join(". "));
            return;
        }

        const selectedProductsParam = selectedProducts.join(",");

        const url = `/purchase/?supplierId=${supplier.value}&invoiceNo=${invoice.label.split(" - ")[0]}&invoiceDate='${invoice.label.split(" - ")[1]}'&purchaseItemsId=${selectedProductsParam}`;
        try {
            setLoading(true);
            setError(null);

            const response = await api().get(url);
            const data = response.data.data[0];

            console.log("Response from the server:", data);

            setSelectedProducts([]);
            setSelectAll(false);

            if (onSave) {
                onSave({ data, invoiceReturnNo, invoiceReturnDate });
            }
        } catch (err) {
            console.error("Error saving data:", err);
            setError("Failed to save data. Please try again.");
        } finally {
            setLoading(false);
        }
    };



    return (
        <div className="container overflow-hidden">
            <div className="row">
                <div className="col-3" style={{ backgroundColor: "#B1D7E9", padding: "20px" }}>
                    <div className='mb-3'>
                        <FormInput
                            name='Purchase Return Type'
                            label='Purchase Return Type'
                            labelClassName="required"
                            value={purchaseReturnType}
                            onChange={handlePurchaseType}
                            placeholder='Purchase Return Type'
                            errorText={purchaseReturnTypeError}
                            containerClass="mb-2"
                            // disabled={showViewForm}
                            type="select"
                        >
                            <option value="">Select</option>
                            {PURCHASE_TYPE_DROPS.map((a, i) => {
                                return <Option value={a.value.toString()} key={i}>{a.text}</Option>
                            })}
                        </FormInput>
                    </div>
                    <div className="mb-3">
                        <label>Supplier</label>
                        <Select
                            options={supplierList.map((f) => ({
                                label: f?.accountName || "",
                                value: f?.accountNumber?.toString() || "",
                            }))}
                            isSearchable={true}
                            isClearable={true}
                            onChange={(v) => setSupplier(v)}
                            value={supplier}
                            styles={customStyles}
                        />
                    </div>

                    <div className="mb-3">
                        <label>Search Invoice</label>
                        <Select
                            options={invoiceList}
                            isSearchable={true}
                            isClearable={true}
                            onChange={(v) => setInvoice(v)}
                            value={invoice}
                            styles={customStyles}
                            isDisabled={loading}
                        />
                    </div>

                    <div className="mb-3">
                        <label>Grand Total</label>
                        <input
                            type="text"
                            className="form-control"
                            value={grandTotal !== null ? grandTotal : ""}
                            readOnly
                        />
                    </div>

                    <div className="mb-3">
                        <label>Invoice Return No <span className="text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            value={invoiceReturnNo}
                            onChange={(e) => setInvoiceReturnNo(e.target.value)}
                        />
                    </div>

                    <div className="mb-3">
                        <label>Invoice Return Date <span className="text-danger">*</span></label>
                        <input
                            type="date"
                            className="form-control"
                            value={invoiceReturnDate}
                            onChange={(e) => setInvoiceReturnDate(e.target.value)}
                        />
                    </div>

                    {loading && <p>Loading...</p>}
                    {error && <p className="text-danger">{error}</p>}
                </div>

                <div className="col-9 bg-white" style={{ padding: "20px", height: "50%"  }}>
                    {/* Displaying product names and checkboxes */}
                    <div className="d-flex justify-content-between mb-3">
                        <div>
                            <input
                                className="me-1"
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                            />
                            <label>Select All</label>
                        </div>

                        <div>
                            <button
                                className="btn btn-primary me-2"
                                onClick={handleSave}
                                disabled={selectedProducts.length === 0}
                            >
                                Save
                            </button>
                            <button
                                className="btn btn-secondary"
                                onClick={() => {
                                    setSelectedProducts([]);
                                    setSelectAll(false);
                                }}
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                    <div>
                        <FormInput2
                            onChange={searchTermChangeHandler}
                            name=""
                            placeholder='Search...'
                            height='24px'
                            value={searchTerm}
                            onClick={() => {
                                mainContentRef?.current?.scrollTo({
                                    behavior: 'smooth',
                                    top: 270
                                })
                            }}
                            ref={searchRef}
                            className="container my-2"
                        />

                    </div>
                    <div className='table-wrapper' style={{height: "365px"}}>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Product Name</th>
                                    <th>Item Quantity</th>
                                    <th>Free Item Quantity</th>
                                    <th>Total Item Quantity</th>
                                    <th>Quantity</th>
                                    <th>Free</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchaseItems?.filter((item) => filterAccountHandler(item, searchTerm))?.length > 0 ? (
                                    purchaseItems?.filter((item) => filterAccountHandler(item, searchTerm))?.map((item, index) => (
                                        <tr
                                            key={item.id}
                                            onClick={() => handleCheckboxChange(item.id)}
                                            style={{ cursor: 'pointer', backgroundColor: selectedProducts.includes(item.id) ? '#e9f5ff' : 'transparent' }}
                                        >
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedProducts.includes(item.id)}
                                                    onChange={() => handleCheckboxChange(item.id)}
                                                    onClick={() => handleCheckboxChange(item.id)}
                                                />
                                            </td>
                                            <td>{item.product_name}</td>
                                            <td>{item.item_quantity}</td>
                                            <td>{item.free_item_quantity}</td>
                                            <td>{item.total_item_quantity}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.free_quantity}</td>
                                            <td>{item.total_quantity}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={8} className="text-center">No records found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseReturnFormBySupplier;
