import { nanoid } from "@reduxjs/toolkit"
import { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from "react"
import { Modal as BootstrapModal, Button, Form } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import Modal from "../components/Modal"
import useOverlayLoader from "../components/OverlayLoader/useOverlayLoader"
import ProductSearch from "../components/ProductSearch"
import PurchaseForm, { Dropdown, PURCHASE_TYPE_DROPS } from "../components/PurchaseForm"
import SuccessModal from "../components/SuccessModal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { convertAmtToWords, getDate, getOnePlusYear, rupeeFormat, today } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolder"
import { AuthUserType } from "../redux/actions/authUserActions"
import { BranchType } from "../redux/actions/branchAction"
import { ProductType } from "../redux/actions/productAction"
import { PurchaseItems, PurchasePaymentModeTypes, PurchasePaymentModesDropDown, PurchaseType, addOpeningStockAction, addPurchaseAction, fetchPurchaseAction, updateOpeningStockAction, updatePurchaseAction } from "../redux/actions/purchaseActions"
import { UsersType } from "../redux/actions/usersActions"
import { AppDispatch, StoreState } from "../redux/store"
import { getPurchaseById } from "../services/purchase.service"
import { getTransactionCurrentBalanceByAcNo } from "../services/transaction.service"
import '../styles/styles.css'
import GroupProducts, { cartesianAttributes } from "./GroupProducts"
import Purchase from "./Purchase"

interface PurchaseItem {
    id: number;
    productName: Dropdown | null;
    productCode: string;
    hsn: string;
    gst: string;
    batchNo: string;
    expiryDt: Date | null;
    qty: string;
    free: string;
    prate: string;
    totDisAmt: string;
    pwog: string;
    pwg: string;
    fcost: string;
    lcost: string;
    marginPercent: string;
    marginAmt: string;
    salesRate: string;
    sRate1: string;
    sRate2: string;
    sRate3: string;
    mrp: string;
    sdRs: string;
    pdPercent: string;
    pdRs: string;
    cdPercent: string;
    cdRs: string;
    taxable: string;
    igst: string;
    cgst: string;
    sgst: string;
    netAmt: string;
    unitProfit: string;
    totalProfit: string;
    profitPercent: string;
}

interface Product {
    id: number;
    productName: string;
    productCode: string;
    sac: string;
    gst: string;
}

const PURCHASE_PAYMENT_MODES = [
    {
        text: "CASH",
        value: "Cash",
    },
    {
        text: "CREDIT",
        value: "Credit",
    },
    {
        text: "BANK",
        value: "Bank",
    },
];

const getDefaultPurchaseItems = () => {
    const purchaseItems: PurchaseItem[] = [];

    // const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // const d = new Date();
    // const month = months[d.getMonth()];
    // const nextYear = d.getFullYear() + 1;

    // const expDate = month + ' ' + nextYear

    for (let i = 0; i < 100; i++) {
        purchaseItems.push({
            id: i + 1,
            productName: null,
            productCode: "",
            hsn: "",
            gst: "",
            batchNo: "",
            expiryDt: null,
            qty: "",
            free: "",
            prate: "",
            totDisAmt: "0",
            pwog: "",
            pwg: "",
            fcost: "",
            lcost: "",
            marginPercent: "",
            marginAmt: "",
            salesRate: "",
            sRate1: "",
            sRate2: "",
            sRate3: "",
            mrp: "",
            sdRs: "0",
            pdPercent: "0",
            pdRs: "0",
            cdPercent: "0",
            cdRs: "0",
            taxable: "0",
            igst: "",
            cgst: "",
            sgst: "",
            netAmt: "0",
            unitProfit: "0",
            totalProfit: "0",
            profitPercent: "0",
        });
    }
    return purchaseItems;
};

export interface PurchaseItemEditAttributes {
    id: number;
    purchase_id: number;
    entry_number: number;
    product_id: number;
    batch_number: number;
    expiry_date: string;
    quantity: number;
    free_quantity: number;
    totalQuantity: number;
    purchase_rate: number;
    price_wogst: number;
    price_gst: number;
    freight_cost: number;
    landing_cost: number;
    margin_percent: number;
    margin_amt: number;
    sales_rate: number;
    w_rate: number;
    r_rate: number;
    l_rate: number;
    mrp: number;
    batch: number;
    uRate: number;
    s_discount: number;
    p_discount_percent: number;
    p_discount_amount: number;
    c_discount_percent: number;
    c_discount_amount: number;
    taxable_amount: number;
    igst_amount: number;
    cgst_amount: number;
    sgst_amount: number;
    total_amount: number;
    unit_cost: number;
    profit_percent: number;
    profit_amount: number;
    productCode?: number;
}

interface PurchaseEditAttributes {
    id: number;
    finance_year: string;
    branch_id: number;
    invoice_type: string;
    invoice_title: string;
    invoice_date: string;
    goods_received_date: string;
    invoice_day: number;
    invoice_week_number: number;
    invoice_week_day: number;
    invoice_month: number;
    invoice_year: number;
    invoice_number: number;
    invoice_amount: number;
    purchase_entry_number: number;
    system_no: string;
    rep_id: number;
    supplier_id: number;
    bill_amount: string;
    dis_percent: string;
    dis_amount: string;
    freight_cost?: string;
    add: string;
    less: string;
    grand_total: string;
    amt_in_words: string;
    particulars: string;
    amt_paid: string;
    payment_mode: string;
    delivery_mode: string;
    delivery_details: string;
    remarks?: string;
    net_taxable: string;
    net_igst: string;
    net_sgst: string;
    net_cgst: string;
    net_profit: string;
    net_profit_percent: string;
    A_value: number;
    A_taxable: number;
    B_value: number;
    B_taxable: number;
    C_value: number;
    C_taxable: number;
    D_value: number;
    D_taxable: number;
    E_value: number;
    E_taxable: number;
    F_value: number;
    F_taxable: number;
    G_value: number;
    G_taxable: number;
    order_date: string;
    order_no: string;
    date_of_supply: string;
    place_of_supply: string;
    transport_mode: string;
    transport_name: string;
    vehicle_no: string;
    no_of_bundles: number;
    no_of_qty: number;
    net_weight: number;
    eway_bill_no: string;
    acknow_no: string;
    acknow_date: string;
    irn_no: string;
    irn_date: string;
    destination: string;
    dispatch_document_no: string;
    buyer_order_date: string;
    buyer_order_no: string;
    createdAt?: string;
    created_by: number;
    remaining_record_exist?: number;
    PurchaseItems: PurchaseItemEditAttributes[];
    Transactions?: any[];
    PurchaseAttributes?: EditPurchaseAttributeType[];
}
export interface EditPurchaseAttributeType {
    id: number;
    attribute1: string;
    attribute2: string;
    attribute3: number;
    attribute4: number;
    attribute5: number;
    batch_number: number;
    brand_id: number;
    category_id: number;
    cgst_amount: number;
    createdAt: string;
    expiry_date: string;
    free_quantity: number;
    purchase_rate: number;
    igst_amount: number;
    l_rate: number;
    mrp: number;
    price_gst: number;
    price_wogst: number;
    freight_cost: number;
    landing_cost: number;
    margin_percent: number;
    margin_amt: number;
    sales_rate: number;
    product_code: string;
    item_code: string;
    product_group_code: string;
    purchase_id: number;
    quantity: number;
    r_rate: number;
    sgst_amount: number;
    taxable_amount: number;
    total_amount: number;
    total_quantity: number;
    unit_profit: number;
    w_rate: number;
    s_discount: number;
    p_discount_amount: number;
    p_discount_percent: number;
    c_discount_amount: number;
    c_discount_percent: number;
    profit_amount: number;
    profit_percent: number;
}
export interface PurchaseAttribute {
    productCode: string;
    attribute: cartesianAttributes[];
}
interface Props {
    From: string;
}

const PurchaseMaster = ({ From }: Props) => {
    const toast = useToast();
    const [setShowOverlayLoader, setHideOverlayLoader] = useOverlayLoader();
    const dispatch = useDispatch<AppDispatch>();
    const qtyRefs = useRef<any>([]);

    const ProductList = useSelector<StoreState, ProductType[]>(
        (state) => state.product
    );
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(
        (state) => state.account
    );
    const userList = useSelector<StoreState, UsersType[]>((state) => state.user);
    const branchList = useSelector<StoreState, BranchType[]>(
        (state) => state.branch
    );

    const [cartesianTableData, setCartesianTableData] = useState<
        PurchaseAttribute[]
    >([]);

    const [financialYear, setFinancialYear] = useState<string>("");
    const [financialYearError, setFinancialYearError] = useState<string>("");
    const [invoiceType, setInvoiceType] = useState<string>("");
    const [invoiceTypeError, setInvoiceTypeError] = useState<string>("");
    const [invoiceTitle, setInvoiceTitle] = useState<string>("");
    const [invoiceTitleError, setInvoiceTitleError] = useState<string>("");
    const [gudsRecdDate, setGudsRecdDate] = useState<string>("");
    const [invoiceDate, setInvoiceDate] = useState<string>("");
    const [invoiceDateError, setInvoiceDateError] = useState<string>("");
    const [invoiceNo, setInvoiceNo] = useState<string>("");
    const [invoiceNoError, setInvoiceNoError] = useState<string>("");

    const [invoiceAmount, setInvoiceAmount] = useState<string>("");
    const [purchaseEntryNo, setPurchaseEntryNo] = useState<string>("");
    const [supplier, setSupplier] = useState<string>("");

    const [supName, setSupName] = useState<string>("");
    const [supNameError, setSupNameError] = useState<string>("");
    const [supAddress, setSupAddress] = useState<string>("");
    const [supAddressError, setSupAddressError] = useState<string>("");
    const [supContact, setSupContact] = useState<string>("");
    const [supContactError, setSupContactError] = useState<string>("");
    const [gst, setGST] = useState<string>("");
    const [gstError, setGSTError] = useState<string>("");
    const [supAadhar, setSupAadhar] = useState<string>("");
    const [supAadharError, setSupAadharError] = useState<string>("");

    const [refBy, setRefBy] = useState<string>("");
    const [refByError, setRefByError] = useState<string>("");
    const [supPlace, setSupPlace] = useState<string>("");
    const [supPlaceError, setSupPlaceError] = useState<string>("");
    const [branch, setBranch] = useState<string>("");
    const [branchError, setBranchError] = useState<string>("");
    const [billedBy, setBilledBy] = useState<string>("");
    const [billedByError, setBilledByError] = useState<string>("");
    const [sysNo, setSysNo] = useState<string>("");
    const [sysNoError, setSysNoError] = useState<string>("");

    const [productName, setProductName] = useState<string>("");
    const [productNameError, setProductNameError] = useState<string>("");

    const [purchaseItems, setPurchaseItems] = useState<PurchaseItem[]>(
        getDefaultPurchaseItems()
    );
    const authUser = useSelector<StoreState, AuthUserType | null>(
        (state) => state.authUser
    );

    const [showPurchaseForm, setShowPurchaseForm] = useState<boolean>(false);
    const [showPurchaseView, setShowPurchaseView] = useState<boolean>(false);
    const [showAttributeModal, setShowAttributeModal] = useState<boolean>(false);

    const [billAmount, setBillAmount] = useState<string>("");
    const [billAmountError, setBillAmountError] = useState<string>("");
    const [disPercent, setDisPercent] = useState<string>("");
    const [disPercentError, setDisPercentError] = useState<string>("");
    const [disAmt, setDisAmt] = useState<string>("0");
    const [disAmtError, setDisAmtError] = useState<string>("");
    const [totAmt, setTotAmt] = useState<string>("");
    const [totAmtError, setTotAmtError] = useState<string>("");
    const [roundOff, setRoundOff] = useState<string>("0");
    const [roundOffError, setRoundOffError] = useState<string>("");
    const [billNetAmt, setBillNetAmt] = useState<string>("");
    const [billNetAmtError, setBillNetAmtError] = useState<string>("");
    const [totFreightCost, setTotFreightCost] = useState<string>("");
    const [totFreightCostError, setTotFreightCostError] = useState<string>("");
    const [add, setAdd] = useState<string>("0");
    const [addError, setAddError] = useState<string>("");
    const [less, setLess] = useState<string>("0");
    const [lessError, setLessError] = useState<string>("");
    const [gtotal, setGTotal] = useState<string>("");
    const [gtotalError, setGTotalError] = useState<string>("");
    const [amtInWords, setAmtInWords] = useState<string>("");
    const [amtInWordsError, setAmtInWordsError] = useState<string>("");
    const [paymentMode, setPaymentMode] = useState<PurchasePaymentModeTypes>(
        PurchasePaymentModesDropDown.CASH
    );
    const [paymentModeError, setPaymentModeError] = useState<string>("");
    const [paymentNo, setPaymentNo] = useState<string>("");
    const [paymentNoError, setPaymentNoError] = useState<string>("");
    const [amtPaid, setAmtPaid] = useState<string>("0");
    const [amtPaidError, setAmtPaidError] = useState<string>("");
    const [currentBal, setCurrentBal] = useState<string>("0");
    const [currentBalError, setCurrentBalError] = useState<string>("");
    const [previousBal, setPreviousBal] = useState<string>("0");
    const [previousBalError, setPreviousBalError] = useState<string>("");
    const [overAllBal, setOverAllBal] = useState<string>("0");
    const [overAllBalError, setOverAllBalError] = useState<string>("");
    const [bankName, setBankName] = useState<string>("");
    const [bankNumber, setBankNumber] = useState<string>("");
    const [tenderedBal, setTenderedBal] = useState<string>("");
    const [tenderedBalError, setTenderedBalError] = useState<string>("");
    const [deliveryMode, setDeliveryMode] = useState<string>("");
    const [deliveryModeError, setDeliveryModeError] = useState<string>("");
    const [deliveryDetails, setDeliveryDetails] = useState<string>("");
    const [deliveryDetailsError, setDeliveryDetailsError] = useState<string>("");

    const [finalAmount, setFinalAmount] = useState<string>("0");
    const [totalProfit, setTotalProfit] = useState<string>("0");

    //variables to check attribute modal totals
    const [pcode, setPcode] = useState<string>("");
    const [pgstPercent, setPgstPercent] = useState<number>(0);
    const [pprate, setPPRate] = useState<number>(0);
    const [pSdrs, setPSdrs] = useState<number>(0);
    const [pPdPercent, setPPdPercent] = useState<number>(0);
    const [pPdrs, setPPdrs] = useState<number>(0);
    const [pCdPercent, setPCdPercent] = useState<number>(0);
    const [pCdrs, setPCdrs] = useState<number>(0);
    const [pFcost, setPFcost] = useState<number>(0);
    const [pLcost, setPLcost] = useState<number>(0);
    const [pMarginPercent, setPMarginPercent] = useState<number>(0);
    const [pMarginAmt, setPMarginAmt] = useState<number>(0);
    const [pSalesRate, setPSalesRate] = useState<number>(0);
    const [ppwg, setPPwg] = useState<number>(0);
    const [ppwog, setPPwog] = useState<number>(0);
    const [pwPrice, setPWPrice] = useState<number>(0);
    const [prPrice, setPRPrice] = useState<number>(0);
    const [puPrice, setPUPrice] = useState<number>(0);
    const [pmrp, setPMrp] = useState<number>(0);
    const [pIgst, setPIgst] = useState<number>(0);
    const [pCgst, setPCgst] = useState<number>(0);
    const [pSgst, setPSgst] = useState<number>(0);
    const [pTax, setPTax] = useState<number>(0);
    const [pAmt, setPAmt] = useState<number>(0);
    const [pUprofit, setPUprofit] = useState<number>(0);
    const [pTprofit, setPTprofit] = useState<number>(0);
    const [pProfitPercent, setPProfitPercent] = useState<number>(0);
    const [pQty, setPQty] = useState<number>(0);
    const [pFree, setPFree] = useState<number>(0);
    const [pNetAmt, setPNetAmt] = useState<number>(0);
    const [pTotalProfit, setPTotalProfit] = useState<number>(0);
    const [pTotalTaxable, setPTotalTaxable] = useState<number>(0);

    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
    const [id, setId] = useState<number>();

    //New fields state variable
    const [orderDate, setOrderDate] = useState<string>("");
    const [orderNo, setOrderNo] = useState<string>("");
    const [dateOfSupply, setDateOfSupply] = useState<string>("");
    const [placeOfSupply, setPlaceOfSupply] = useState<string>("");
    const [transportMode, setTransportMode] = useState<string>("");
    const [transportName, setTransportName] = useState<string>("");
    const [vehicleNo, setVehicleNo] = useState<string>("");
    const [noOfBundles, setNoOfBundles] = useState<string>("");
    const [noOfQty, setNoOfQty] = useState<string>("");
    const [netWeight, setNetWeight] = useState<string>("");
    const [ewayBillNo, setEwayBillNo] = useState<string>("");
    const [ackNo, setAckNo] = useState<string>("");
    const [ackDate, setAckDate] = useState<string>("");
    const [irnNo, setIrnNo] = useState<string>("");
    const [irnDate, setIrnDate] = useState<string>("");
    const [remarks, setRemarks] = useState<string>("");
    const [buyerOrderDate, setBuyerOrderDate] = useState("");
    const [buyerOrderNo, setBuyerOrderNo] = useState("");
    const [destination, setDestination] = useState("");
    const [dispatchDocumentNo, setDispatchDocumentNo] = useState("");

    //save model
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState<string>("");
    const [modalInvoiceNumber, setModalInvoiceNumber] = useState<string>("");
    const [modalPurchaseEntryNo, setModalPurchaseEntryNo] = useState<string>("");

    //update stock
    const [isBalanceChecked, setIsBalanceChecked] = useState(false);

    //focus
    const [focusedField, setFocusedField] = useState<string | null>(null);

    //model for final submit notification
    const [showFinalSubmitModal, setShowFinalSubmitModal] = useState(false);

    //for total netTaxable, netIgst, netCgst, netSgst, netProfit
    const totalData = purchaseItems.filter((f) => {
        return (
            f.productName?.label !== "" &&
            f.productName?.label !== undefined &&
            f.productName?.value !== "" &&
            f.productName?.value !== undefined
        );
    });

    const [netTaxable, setNetTaxable] = useState(0);
    const [netIgst, setNetIgst] = useState(0);
    const [netCgst, setNetCgst] = useState(0);
    const [netSgst, setNetSgst] = useState(0);
    const [netProfit, setNetProfit] = useState(0);

    //reset in ProductSearch
    const [resetSearch, setResetSearch] = useState<boolean>(false);

    useEffect(() => {
        if (totalData.length > 0) {
            setNetTaxable(
                totalData.reduce(
                    (prev, curr) => prev + parseFloat(curr.taxable || "0"),
                    0
                )
            );
            setNetIgst(
                totalData.reduce((prev, curr) => prev + parseFloat(curr.igst || "0"), 0)
            );
            setNetCgst(
                totalData.reduce((prev, curr) => prev + parseFloat(curr.cgst || "0"), 0)
            );
            setNetSgst(
                totalData.reduce((prev, curr) => prev + parseFloat(curr.sgst || "0"), 0)
            );
            setNetProfit(
                totalData.reduce(
                    (prev, curr) => prev + parseFloat(curr.totalProfit || "0"),
                    0
                )
            );
        } else {
            setNetTaxable(0);
            setNetIgst(0);
            setNetCgst(0);
            setNetSgst(0);
            setNetProfit(0);
        }
    }, [totalData]);

    // Refs for each field
    const disPercentRef = useRef<HTMLInputElement>(null);
    const disAmtRef = useRef<HTMLInputElement>(null);
    const totFreightCostRef = useRef<HTMLInputElement>(null);
    const addRef = useRef<HTMLInputElement>(null);
    const lessRef = useRef<HTMLInputElement>(null);
    const narrationRef = useRef<HTMLInputElement>(null);
    const dModeRef = useRef<HTMLInputElement>(null);
    const dDetailsRef = useRef<HTMLInputElement>(null);

    const cashRef = useRef<any>(null);
    const card1Ref = useRef<any>(null);
    const card2Ref = useRef<any>(null);
    const online1Ref = useRef<any>(null);
    const online2Ref = useRef<any>(null);

    const handleKeyDownForRight = <T extends Element>(
        e: React.KeyboardEvent<T>,
        nextRef: React.RefObject<
            | HTMLDivElement
            | HTMLInputElement
            | HTMLSelectElement
            | HTMLTextAreaElement
        >,
        fieldName: string
    ) => {
        if (e.key === "Enter" && nextRef.current) {
            // console.log('nextRef.current', nextRef.current);

            e.preventDefault();
            const input = nextRef.current.querySelector(
                "input, select, .react-select__input"
            );
            if (input) {
                (input as HTMLElement).focus();
            }
            setFocusedField(fieldName);
        }
    };

    const refs = useRef<{
        [key: string]: any;
    }>({});

    const handleKeyDown = (
        e: React.KeyboardEvent | React.MouseEvent,
        nextField: string,
        rowId: string,
        moveToNextRow: boolean = false
    ) => {
        const isKeyboardEvent = e.type === "keydown";
        const isMouseEvent = e.type === "click";

        // console.log("Is Keyboard Event:", isKeyboardEvent);
        // console.log("Is Mouse Event:", isMouseEvent);

        if (isKeyboardEvent && (e as React.KeyboardEvent).key === "Enter") {
            e.preventDefault();

            const currentRow = parseInt(rowId, 10);
            const targetRowId = moveToNextRow ? currentRow + 1 : currentRow;
            const nextRef = refs.current[`${nextField}-${targetRowId}`];
            console.log("Target row", targetRowId);
            console.log("nextRef", nextRef);
            console.log("focusedField", focusedField);

            if (nextRef) {
                if (nextRef.querySelector) {
                    const input = nextRef.querySelector(
                        "input, select, .react-select__input"
                    );
                    console.log("1");
                    if (input) {
                        console.log("12");
                        (input as HTMLElement).focus();
                    }
                } else if (nextRef.focus) {
                    console.log("2");
                    nextRef.focus();
                } else if (nextRef.select?.focus) {
                    console.log("3");
                    nextRef.select.focus();
                }

                setFocusedField(`${nextField}-${targetRowId}`);
            }
        } else if (isMouseEvent) {
            e.preventDefault();

            const currentRow = parseInt(rowId, 10);
            const targetRowId = moveToNextRow ? currentRow + 1 : currentRow;
            const nextRef = refs.current[`${nextField}-${targetRowId}`];

            if (nextRef) {
                if (nextRef.querySelector) {
                    const input = nextRef.querySelector(
                        "input, select, .react-select__input"
                    );
                    if (input) {
                        (input as HTMLElement).focus();
                    }
                } else if (nextRef.focus) {
                    nextRef.focus();
                } else if (nextRef.select?.focus) {
                    nextRef.select.focus();
                }

                setFocusedField(`${nextField}-${targetRowId}`);
            }
        }
    };

    useEffect(() => {
        const firstItemId = purchaseItems[0]?.id;

        if (firstItemId && refs.current[`productName-${firstItemId}`]) {
            const currentRef = refs.current[`productName-${firstItemId}`];

            if (currentRef && currentRef.focus) {
                // currentRef.focus();
                setFocusedField(`productName-${firstItemId}`);
            }
        }
    }, [purchaseItems]);

    const [show, setShow] = useState(false);

    // State variables for form inputs
    // const [grandTotal, setGrandTotal] = useState('');
    //const [previousBalance, setPreviousBalance] = useState('');
    const [totalBalance, setTotalBalance] = useState<number>(0);
    const [cash, setCash] = useState("0");
    const [card1, setCard1] = useState("0");
    const [card2, setCard2] = useState("0");
    const [onlinePayment1, setOnlinePayment1] = useState("0");
    const [onlinePayment2, setOnlinePayment2] = useState("0");
    const [overallBalance, setOverallBalance] = useState<number>(0);
    const [paymentMethod, setPaymentMethod] = useState<string>("");

    const handleClose = () => {
        let paidAmt =
            parseFloat(cash) +
            parseFloat(card1) +
            parseFloat(card2) +
            parseFloat(onlinePayment1) +
            parseFloat(onlinePayment2);
        if (paidAmt <= totalBalance) {
            setShow(false);
        } else {
            setErrorMessage("Your payment exceeds total balance");
            toast("Your payment exceeds total balance", ToastTypes.ERROR);
        }
    };
    const handleShow = () => {
        const data = purchaseItems?.filter((f) => {
            return (
                f.productName?.label !== "" &&
                f.productName?.label !== undefined &&
                f.productName?.value !== "" &&
                f.productName?.value !== undefined
            );
        });
        if (data.length === 0) {
            toast("Kindly fill the data first ", ToastTypes.ERROR);
        } else if (
            data.length > 0 &&
            !isBalanceChecked &&
            parseFloat(invoiceAmount).toFixed(2) !==
            parseFloat(finalAmount).toFixed(2)
        ) {
            toast(
                "Mismatch in Invoice and bill Amt, Kindly Check ",
                ToastTypes.ERROR
            );
        } else if (totFreightCost === "") {
            toast(`Total Freight cost required`, ToastTypes.ERROR);
        } else {
            setShow(true);
        }
    };

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const totalPayment =
            parseFloat(cash) +
            parseFloat(card1) +
            parseFloat(card2) +
            parseFloat(onlinePayment1) +
            parseFloat(onlinePayment2);
        //commented as it is handled in handleclose
        // if (totalPayment > parseFloat(billAmount)) {
        //     setErrorMessage("Total payment exceeds Bill Amount")
        //     setDisableSave(true);
        // } else {
        //     setErrorMessage("")
        //     setDisableSave(false);
        // }
    }, [cash, card1, card2, onlinePayment1, onlinePayment2, gtotal]);

    const handlePaymentMethodChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPaymentMethod(e.target.value);
    };

    useEffect(() => {
        const newTotalBalance =
            parseInt(previousBal) < 0
                ? parseInt(gtotal) + Math.abs(parseInt(previousBal))
                : parseInt(gtotal);
        setTotalBalance(newTotalBalance);
    }, [gtotal, previousBal]);

    useEffect(() => {
        if (parseInt(cash) < 0) {
            setCash("0");
            toast("Minus value not allowed", ToastTypes.ERROR);
        }
        if (parseInt(card1) < 0) {
            setCard1("0");
            toast("Minus value not allowed", ToastTypes.ERROR);
        }
        if (parseInt(card2) < 0) {
            setCard2("0");
            toast("Minus value not allowed", ToastTypes.ERROR);
        }
        if (parseInt(onlinePayment1) < 0) {
            setOnlinePayment1("0");
            toast("Minus value not allowed", ToastTypes.ERROR);
        }
        if (parseInt(onlinePayment2) < 0) {
            setOnlinePayment2("0");
            toast("Minus value not allowed", ToastTypes.ERROR);
        }
    }, [cash, card1, card2, onlinePayment1, onlinePayment2]);

    useEffect(() => {
        if (paymentMethod === "credit") {
            const amtPaids = 0;
            setAmtPaid(amtPaids.toString());
        } else {
            const amtPaids =
                parseInt(cash) +
                parseInt(card1) +
                parseInt(card2) +
                parseInt(onlinePayment1) +
                parseInt(onlinePayment2);
            setAmtPaid(amtPaids.toString());
        }
    }, [cash, card1, card2, onlinePayment1, onlinePayment2, paymentMethod]);

    useEffect(() => {
        const newOverAllBalance =
            totalBalance -
            ((parseInt(cash) || 0) +
                (parseInt(card1) || 0) +
                (parseInt(card2) || 0) +
                (parseInt(onlinePayment1) || 0) +
                (parseInt(onlinePayment2) || 0));
        setOverallBalance(newOverAllBalance);
    }, [totalBalance, cash, card1, card2, onlinePayment1, onlinePayment2]);

    // useEffect(() => {
    //     const amtPaids = parseInt(cash) + parseInt(card1) + parseInt(card2) + parseInt(onlinePayment1) + parseInt(onlinePayment2);
    //     setAmtPaid(amtPaids.toString());
    // }, [cash, card1, card2, onlinePayment1, onlinePayment2]);

    useEffect(() => {
        dispatch(fetchPurchaseAction());
    }, []);

    const resetStates = () => {
        setFormType(FormTypeList.ADD);
        setPurchaseItems(getDefaultPurchaseItems());
        //top
        setGudsRecdDate("");
        setInvoiceType("");
        setInvoiceTitle("");
        setInvoiceDate("");
        setInvoiceNo("");
        setInvoiceAmount("");
        setPurchaseEntryNo("");
        setSupplier("");
        setSupName("");
        setSupAddress("");
        setSupContact("");
        setGST("");
        setSupAadhar("");
        setRefBy("");
        setSupPlace("");
        setBranch("");
        setBilledBy("");
        setSysNo("");
        //
        setBillAmount("");
        setBillAmountError("");
        setDisPercent("");
        setDisPercentError("");
        setDisAmt("");
        setDisAmtError("");
        setTotAmt("");
        setTotAmtError("");
        setRoundOff("");
        setRoundOffError("");
        setBillNetAmt("");
        setBillNetAmtError("");
        setTotFreightCost("");
        setTotFreightCostError("");
        setAdd("");
        setAddError("");
        setLess("");
        setLessError("");
        setGTotal("");
        setGTotalError("");
        setAmtInWords("");
        setAmtInWordsError("");
        setPaymentMode(PurchasePaymentModesDropDown.CASH);
        setPaymentModeError("");
        setPaymentNo("");
        setPaymentNoError("");
        setAmtPaid("");
        setAmtPaidError("");
        setCurrentBal("0");
        setCurrentBalError("");
        setPreviousBal("0");
        setPreviousBalError("");
        setOverAllBal("0");
        setOverAllBalError("");
        setBankName("");
        setBankNumber("");
        setDeliveryMode("");
        setDeliveryModeError("");
        setDeliveryDetails("");
        setDeliveryDetailsError("");
        setFinalAmount("0");
        setCash("0");
        setCard1("0");
        setCard2("0");
        setOnlinePayment1("0");
        setOnlinePayment2("0");
        setPaymentMethod("");
        setOrderDate("");
        setOrderNo("");
        setDateOfSupply("");
        setPlaceOfSupply("");
        setTransportMode("");
        setTransportName("");
        setVehicleNo("");
        setNoOfBundles("");
        setNoOfQty("");
        setNetWeight("");
        setEwayBillNo("");
        setAckNo("");
        setAckDate("");
        setIrnNo("");
        setIrnDate("");
        setRemarks("");
        setIsBalanceChecked(false);

        // reset the product search
        setResetSearch(true);
    };

    // const handleProductName = (
    //     val: Dropdown | null,
    //     e: React.MouseEventHandler | number,
    //     nextField: string,
    //     id: string,
    //     moveToNextRow: boolean = false) => {
    //     const currentRow = parseInt(id, 10);
    //     const targetRowId = moveToNextRow ? currentRow + 1 : currentRow;

    //     const nextRef = refs.current[`${nextField}-${targetRowId}`];

    //     const prodObj = ProductList.find(pd => pd.id?.toString() === val?.value?.toString()
    //     )

    //     let updated = [...purchaseItems]

    //     let ind = updated.findIndex(pi => pi.id.toString() === id)

    //     if (ind > -1) {
    //         updated[ind].productName = val
    //         updated[ind].productCode = prodObj?.productCode ? prodObj?.productCode.toString() : ''
    //         updated[ind].hsn = prodObj?.sac ? prodObj?.sac.toString() : ''
    //         updated[ind].gst = prodObj?.gst ? prodObj?.gst : ''
    //         if (supPlace.toLocaleLowerCase().includes('tamilnadu')) {
    //             let gstPercent = parseFloat(prodObj?.gst ? prodObj?.gst : '0') / 2

    //             updated[ind].cgst = (parseFloat(updated[ind].taxable) * gstPercent / 100).toString()
    //             updated[ind].sgst = (parseFloat(updated[ind].taxable) * gstPercent / 100).toString()
    //             updated[ind].igst = '0'
    //         } else {
    //             let gstPercent = parseFloat(prodObj?.gst ? prodObj?.gst : '0')
    //             updated[ind].cgst = '0'
    //             updated[ind].sgst = '0'
    //             updated[ind].igst = (parseFloat(updated[ind].taxable) * gstPercent / 100).toString()
    //         }
    //         //for pwg
    //         let pwg = (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0) + ((updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0) * (prodObj?.gst ? parseFloat(prodObj?.gst) : 0) / 100)
    //         updated[ind].pwg = pwg.toString()

    //         //for exp date
    //         updated[ind].expiryDt = new Date(getOnePlusYear())
    //         updated[ind].batchNo = '1'
    //         setPurchaseItems(updated)

    //     }
    //     if (val === null) {
    //         updated[ind].expiryDt = null
    //         updated[ind].batchNo = ''
    //     }
    //     setPurchaseItems(updated)
    //     setProductNameError('')

    //     const index = purchaseItems.findIndex(pi => pi.id.toString() === id);
    //     // if (index !== -1 && qtyRefs.current[index]) {
    //     //     qtyRefs.current[index].focus()
    //     // }
    //     if (nextRef.querySelector) {
    //         // For wrapper elements (like react-select or similar)
    //         const input = nextRef.querySelector('input, select, .react-select__input');
    //         if (input) {
    //             (input as HTMLElement).focus();
    //         }
    //     } else if (nextRef.focus) {
    //         // For native inputs
    //         nextRef.focus();
    //     } else if (nextRef.select?.focus) {
    //         // For react-select's `select` method
    //         nextRef.select.focus();
    //     }
    // }

    const handleProductName = (
        val: Product | null,
        id: number,
        field: string,
        value: string
    ) => {
        const updated = [...purchaseItems];
        const ind = updated.findIndex((pi) => pi.id.toString() === id.toString());

        if (ind > -1 && val) {
            updated[ind].productName = {
                label: val.productName,
                value: val.id.toString(),
            };
            updated[ind].productCode = val.productCode;
            updated[ind].hsn = val.sac;
            updated[ind].gst = val.gst;

            if (supPlace.toLowerCase().includes("tamilnadu")) {
                let gstPercent = parseFloat(val.gst) / 2;
                updated[ind].cgst = (
                    (parseFloat(updated[ind].taxable) * gstPercent) /
                    100
                ).toString();
                updated[ind].sgst = (
                    (parseFloat(updated[ind].taxable) * gstPercent) /
                    100
                ).toString();
                updated[ind].igst = "0";
            } else {
                let gstPercent = parseFloat(val.gst);
                updated[ind].cgst = "0";
                updated[ind].sgst = "0";
                updated[ind].igst = (
                    (parseFloat(updated[ind].taxable) * gstPercent) /
                    100
                ).toString();
            }

            updated[ind].pwg = (
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0) +
                ((updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0) *
                    parseFloat(val.gst)) /
                100
            ).toString();

            updated[ind].expiryDt = new Date(getOnePlusYear());
            updated[ind].batchNo = "1";
        }

        if (!val) {
            updated[ind].expiryDt = null;
            updated[ind].batchNo = "";
        }

        setPurchaseItems(updated);
        setProductNameError("");
    };

    const handleProductCode = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].productCode = value;
        }
        setPurchaseItems(updated);
    };

    const handleHsn = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].hsn = value;
        }
        setPurchaseItems(updated);
    };
    const handleGst = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].gst = value;
        }
        setPurchaseItems(updated);
    };
    const handleBatchNo = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].batchNo = value;
        }
        setPurchaseItems(updated);
    };
    const handleExpiryDate = (value: Date, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].expiryDt = value;
        }
        setPurchaseItems(updated);
    };
    const handleQty = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].qty = value;

            //OLD
            //for taxable
            // updated[ind].taxable = (((value ? parseFloat(value) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) - (updated[ind].sdRs ? parseFloat(updated[ind].sdRs) : 0) - (updated[ind].pdRs ? parseFloat(updated[ind].pdRs) : 0) - (updated[ind].cdRs ? parseFloat(updated[ind].cdRs) : 0)).toString()
            //for igst
            // let tax = (((value ? parseFloat(value) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) - (updated[ind].sdRs ? parseFloat(updated[ind].sdRs) : 0) - (updated[ind].pdRs ? parseFloat(updated[ind].pdRs) : 0) - (updated[ind].cdRs ? parseFloat(updated[ind].cdRs) : 0))

            //NEW
            updated[ind].taxable = (
                (value ? parseFloat(value) : 0) *
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)
            ).toString();

            //for igst
            let tax =
                (value ? parseFloat(value) : 0) *
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0);

            if (supPlace.toLocaleLowerCase().includes("tamilnadu")) {
                let gstPercent =
                    parseFloat(updated[ind]?.gst ? updated[ind]?.gst : "0") / 2;

                updated[ind].cgst = ((tax * gstPercent) / 100).toString();
                updated[ind].sgst = ((tax * gstPercent) / 100).toString();
                updated[ind].igst = "0";

                //for netAMt
                updated[ind].netAmt = (
                    tax +
                    (tax * gstPercent) / 100 +
                    (tax * gstPercent) / 100
                ).toString();
            } else {
                let gstPercent = parseFloat(
                    updated[ind]?.gst ? updated[ind]?.gst : "0"
                );
                updated[ind].igst = ((tax * gstPercent) / 100).toString();
                updated[ind].cgst = "0";
                updated[ind].sgst = "0";
                //for netAMt
                updated[ind].netAmt = (tax + (tax * gstPercent) / 100).toString();
            }

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit =
                ((value ? parseFloat(value) : 0) +
                    (updated[ind].free ? parseFloat(updated[ind].free) : 0)) *
                (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0) -
                ((value ? parseFloat(value) : 0) +
                    (updated[ind].free ? parseFloat(updated[ind].free) : 0)) *
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0);
            updated[ind].totalProfit = tprofit.toString();
        }

        setPurchaseItems(updated);
        // const index = updated.findIndex(pi => pi.id.toString() === id);
        // // console.log(index);

        // if ((index !== -1 && nextCell.current[index])) {
        //     nextCell.current[index].focus()
        // }
    };

    const handleFree = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].free = value;

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit =
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) +
                    (value ? parseFloat(value) : 0)) *
                (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0) -
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) +
                    (value ? parseFloat(value) : 0)) *
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0);
            updated[ind].totalProfit = tprofit.toString();
        }
        setPurchaseItems(updated);
    };

    const handlePurcahseRate = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].prate = value;
            let pwog = (
                parseFloat(updated[ind].prate) - parseFloat(updated[ind].totDisAmt)
            ).toString();
            handlePWOG(pwog, id);
        }
        setPurchaseItems(updated);
    };
    const handlePWOG = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].pwog = value;

            //for taxable
            updated[ind].taxable = (
                (updated[ind].qty ? parseFloat(updated[ind].qty) : 0) *
                (value ? parseFloat(value) : 0)
            ).toString();
            //for igst
            let tax =
                (updated[ind].qty ? parseFloat(updated[ind].qty) : 0) *
                (value ? parseFloat(value) : 0);

            if (supPlace.toLocaleLowerCase().includes("tamilnadu")) {
                let gstPercent =
                    parseFloat(updated[ind]?.gst ? updated[ind]?.gst : "0") / 2;

                updated[ind].cgst = ((tax * gstPercent) / 100).toString();
                updated[ind].sgst = ((tax * gstPercent) / 100).toString();
                updated[ind].igst = "0";

                //for netAMt
                updated[ind].netAmt = (
                    tax +
                    (tax * gstPercent) / 100 +
                    (tax * gstPercent) / 100
                ).toString();
            } else {
                let gstPercent = parseFloat(
                    updated[ind]?.gst ? updated[ind]?.gst : "0"
                );
                updated[ind].igst = ((tax * gstPercent) / 100).toString();
                updated[ind].cgst = "0";
                updated[ind].sgst = "0";
                //for netAMt
                updated[ind].netAmt = (tax + (tax * gstPercent) / 100).toString();
            }
            //for pwg
            let pwg =
                (value ? parseFloat(value) : 0) +
                ((value ? parseFloat(value) : 0) *
                    (updated[ind].gst ? parseFloat(updated[ind].gst) : 0)) /
                100;
            updated[ind].pwg = pwg.toString();

            //for unitprofit
            let uprofit =
                (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0) -
                (value ? parseFloat(value) : 0);
            updated[ind].unitProfit = uprofit.toString();

            //for profit %
            let profitPercent =
                ((uprofit ? uprofit : 0) / (value ? parseFloat(value) : 0)) * 100;
            updated[ind].profitPercent = profitPercent.toFixed(2);

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit =
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) +
                    (updated[ind].free ? parseFloat(updated[ind].free) : 0)) *
                (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0) -
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) +
                    (updated[ind].free ? parseFloat(updated[ind].free) : 0)) *
                (value ? parseFloat(value) : 0);
            updated[ind].totalProfit = tprofit.toString();
        }
        setPurchaseItems(updated);
    };
    const handlePWG = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].pwg = value;
        }
        setPurchaseItems(updated);
    };
    const handleFreightCost = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].fcost = value;
            updated[ind].lcost = (
                parseFloat(updated[ind].pwg) + parseFloat(value)
            ).toString();
            updated[ind].marginAmt = (
                (parseFloat(updated[ind].lcost) *
                    parseFloat(updated[ind].marginPercent)) /
                100
            ).toFixed(2);
            updated[ind].salesRate = (
                parseFloat(updated[ind].lcost) + parseFloat(updated[ind].marginAmt)
            ).toString();
        }
        setPurchaseItems(updated);
    };
    const handleLandingCost = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].lcost = value;
        }
        setPurchaseItems(updated);
    };
    const handleMarginPercent = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].marginPercent = value;
            updated[ind].marginAmt = (
                (parseFloat(updated[ind].lcost) * parseFloat(value)) /
                100
            ).toFixed(2);
            updated[ind].salesRate = (
                parseFloat(updated[ind].lcost) + parseFloat(updated[ind].marginAmt)
            ).toFixed(4);
            updated[ind].sRate1 = (parseFloat(updated[ind].salesRate) + 1).toFixed(4);
            updated[ind].sRate2 = (parseFloat(updated[ind].salesRate) + 2).toFixed(4);
            updated[ind].sRate3 = (parseFloat(updated[ind].salesRate) + 3).toFixed(4);

            //for unitprofit
            let uprofit =
                (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0) -
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0);
            updated[ind].unitProfit = uprofit.toString();

            //for profit %
            let profitPercent =
                ((uprofit ? uprofit : 0) /
                    (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) *
                100;
            updated[ind].profitPercent = profitPercent.toFixed(2);

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit =
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) +
                    (updated[ind].free ? parseFloat(updated[ind].free) : 0)) *
                (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0) -
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) +
                    (updated[ind].free ? parseFloat(updated[ind].free) : 0)) *
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0);
            updated[ind].totalProfit = tprofit.toFixed(2).toString();
        }
        setPurchaseItems(updated);
    };
    const handleMarginAmt = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].marginAmt = value;
            //to get what % of no is another no = val/lcost*100
            updated[ind].marginPercent = (
                (parseFloat(value) / parseFloat(updated[ind].lcost)) *
                100
            ).toFixed(2);
            updated[ind].salesRate = (
                parseFloat(updated[ind].lcost) + parseFloat(value)
            ).toFixed(4);
            updated[ind].sRate1 = (parseFloat(updated[ind].salesRate) + 1).toFixed(4);
            updated[ind].sRate2 = (parseFloat(updated[ind].salesRate) + 2).toFixed(4);
            updated[ind].sRate3 = (parseFloat(updated[ind].salesRate) + 3).toFixed(4);

            //for unitprofit
            let uprofit =
                (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0) -
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0);
            updated[ind].unitProfit = uprofit.toString();

            //for profit %
            let profitPercent =
                ((uprofit ? uprofit : 0) /
                    (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) *
                100;
            updated[ind].profitPercent = profitPercent.toFixed(2);

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit =
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) +
                    (updated[ind].free ? parseFloat(updated[ind].free) : 0)) *
                (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0) -
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) +
                    (updated[ind].free ? parseFloat(updated[ind].free) : 0)) *
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0);
            updated[ind].totalProfit = tprofit.toFixed(2).toString();
        }
        setPurchaseItems(updated);
    };
    // const handleSalesRate = (value: string, id: string) => {
    //     let updated = [...purchaseItems]
    //     let ind = updated.findIndex(pi => pi.id.toString() === id)
    //     if (ind > -1) {
    //         updated[ind].salesRate = value
    //         updated[ind].marginAmt = (parseFloat(value) - parseFloat(updated[ind].lcost)).toFixed(2); // Calculate marginAmt
    //         updated[ind].marginPercent = (
    //             (parseFloat(updated[ind].marginAmt) / parseFloat(updated[ind].lcost)) * 100
    //         ).toFixed(2); // Calculate marginPercent
    //     }
    //     setPurchaseItems(updated)
    // }

    const handleSalesRate = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);

        if (ind > -1) {
            let salesRate = parseFloat(value);
            let lcost = parseFloat(updated[ind].lcost) || 0;

            // Ensure salesRate is a valid number
            if (isNaN(salesRate)) {
                salesRate = 0;
            }

            // Update the sales rate and related fields
            updated[ind].salesRate = value;
            updated[ind].sRate1 = (parseFloat(value) + 1).toFixed(4);
            updated[ind].sRate2 = (parseFloat(value) + 2).toFixed(4);
            updated[ind].sRate3 = (parseFloat(value) + 3).toFixed(4);
            updated[ind].marginAmt = (salesRate - lcost).toFixed(2);
            updated[ind].marginPercent = (
                (parseFloat(updated[ind].marginAmt) / lcost) *
                100
            ).toFixed(2);

            //for unitprofit
            let uprofit =
                (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0) -
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0);
            updated[ind].unitProfit = uprofit.toString();

            //for profit %
            let profitPercent =
                ((uprofit ? uprofit : 0) /
                    (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) *
                100;
            updated[ind].profitPercent = profitPercent.toFixed(2);

            //tot profit=(q+f*s1)-(q+f*pwog)
            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit =
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) +
                    (updated[ind].free ? parseFloat(updated[ind].free) : 0)) *
                (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0) -
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) +
                    (updated[ind].free ? parseFloat(updated[ind].free) : 0)) *
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0);
            updated[ind].totalProfit = tprofit.toFixed(2).toString();
        }

        setPurchaseItems(updated);
    };

    const handleSRate1 = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].sRate1 = value;
            updated[ind].sRate2 = (parseFloat(value) + 1).toFixed(4);
            updated[ind].sRate3 = (parseFloat(value) + 2).toFixed(4);

            //for unitprofit
            let uprofit =
                (value ? parseFloat(value) : 0) -
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0);
            updated[ind].unitProfit = uprofit.toString();

            //for profit %
            let profitPercent =
                ((uprofit ? uprofit : 0) /
                    (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) *
                100;
            updated[ind].profitPercent = profitPercent.toFixed(2);

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit =
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) +
                    (updated[ind].free ? parseFloat(updated[ind].free) : 0)) *
                (value ? parseFloat(value) : 0) -
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) +
                    (updated[ind].free ? parseFloat(updated[ind].free) : 0)) *
                (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0);
            updated[ind].totalProfit = tprofit.toString();
        }

        setPurchaseItems(updated);
    };
    const handleSRate2 = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].sRate2 = value;
            updated[ind].sRate3 = (parseFloat(value) + 1).toFixed(4);
        }
        setPurchaseItems(updated);
    };
    const handleSRate3 = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].sRate3 = value;
        }
        setPurchaseItems(updated);
    };
    const handleMRP = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].mrp = value;
        }
        setPurchaseItems(updated);
    };
    const handleSDRs = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].sdRs = value;
            //NEW for total dis amt
            updated[ind].totDisAmt = (
                parseFloat(value) +
                parseFloat(updated[ind].pdRs) +
                parseFloat(updated[ind].cdRs)
            ).toString();
            let pwog = (
                parseFloat(updated[ind].prate) - parseFloat(updated[ind].totDisAmt)
            ).toString();
            handlePWOG(pwog, id);
            // //for taxable
            // updated[ind].taxable = ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)).toString()

            // let tax = ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0))

            // if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
            //     let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0') / 2

            //     updated[ind].cgst = (tax * gstPercent / 100).toString()
            //     updated[ind].sgst = (tax * gstPercent / 100).toString()
            //     updated[ind].igst = '0'
            //     //for netAMt
            //     updated[ind].netAmt = (tax
            //         + (tax * gstPercent / 100)
            //         + (tax * gstPercent / 100)).toString()
            // } else {
            //     let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0')
            //     updated[ind].igst = (tax * gstPercent / 100).toString()
            //     updated[ind].cgst = '0'
            //     updated[ind].sgst = '0'
            //     //for netAMt
            //     updated[ind].netAmt = (tax + (tax * gstPercent / 100)).toString()
            // }
        }
        setPurchaseItems(updated);
    };
    const handlePDPercent = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].pdPercent = value;
            if (updated[ind].prate !== "") {
                //NEW
                // updated[ind].pdRs = (parseFloat(updated[ind].pwg) * parseFloat(value) / 100).toString()
                updated[ind].pdRs = (
                    (parseFloat(updated[ind].prate) * parseFloat(value)) /
                    100
                ).toString();
                let pdrs = (
                    (parseFloat(updated[ind].prate) * parseFloat(value)) /
                    100
                ).toString();

                //NEW for total dis amt
                updated[ind].totDisAmt = (
                    parseFloat(pdrs) +
                    parseFloat(updated[ind].sdRs) +
                    parseFloat(updated[ind].cdRs)
                ).toString();
                let pwog = (
                    parseFloat(updated[ind].prate) - parseFloat(updated[ind].totDisAmt)
                ).toString();
                handlePWOG(pwog, id);

                // //for taxable
                // updated[ind].taxable = ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)).toString()
                // //for igst
                // let tax = ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0))

                // if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                //     let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0') / 2

                //     updated[ind].cgst = (tax * gstPercent / 100).toString()
                //     updated[ind].sgst = (tax * gstPercent / 100).toString()
                //     updated[ind].igst = '0'

                //     //for netAMt
                //     updated[ind].netAmt = (tax
                //         + (tax * gstPercent / 100)
                //         + (tax * gstPercent / 100)).toString()
                // } else {
                //     let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0')
                //     updated[ind].igst = (tax * gstPercent / 100).toString()
                //     updated[ind].cgst = '0'
                //     updated[ind].sgst = '0'
                //     //for netAMt
                //     updated[ind].netAmt = (tax + (tax * gstPercent / 100)).toString()
                // }
            }
        }
        setPurchaseItems(updated);
    };
    const handlePDRs = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].pdRs = value;
        }
        setPurchaseItems(updated);
    };
    const handleCDPercent = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].cdPercent = value;
            if (updated[ind].prate !== "") {
                //NEW
                // updated[ind].cdRs = (parseFloat(updated[ind].pwg) * parseFloat(value) / 100).toString()
                updated[ind].cdRs = (
                    (parseFloat(updated[ind].prate) * parseFloat(value)) /
                    100
                ).toString();

                let cdrs = (
                    (parseFloat(updated[ind].prate) * parseFloat(value)) /
                    100
                ).toString();
                //NEW for total dis amt
                updated[ind].totDisAmt = (
                    parseFloat(updated[ind].pdRs) +
                    parseFloat(updated[ind].sdRs) +
                    parseFloat(cdrs)
                ).toString();
                let pwog = (
                    parseFloat(updated[ind].prate) - parseFloat(updated[ind].totDisAmt)
                ).toString();
                handlePWOG(pwog, id);

                // //for taxable
                // updated[ind].taxable = ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)).toString()

                // //for igst
                // let tax = ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0))

                // if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                //     let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0') / 2

                //     updated[ind].cgst = (tax * gstPercent / 100).toString()
                //     updated[ind].sgst = (tax * gstPercent / 100).toString()
                //     updated[ind].igst = '0'

                //     //for netAMt
                //     updated[ind].netAmt = (tax
                //         + (tax * gstPercent / 100)
                //         + (tax * gstPercent / 100)).toString()
                // } else {
                //     let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0')
                //     updated[ind].igst = (tax * gstPercent / 100).toString()
                //     updated[ind].cgst = '0'
                //     updated[ind].sgst = '0'
                //     //for netAMt
                //     updated[ind].netAmt = (tax + (tax * gstPercent / 100)).toString()
                // }
            }
        }
        setPurchaseItems(updated);
    };
    const handleCDRs = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].cdRs = value;
        }
        setPurchaseItems(updated);
    };
    const handleTaxable = (value: string, id: string) => {
        console.log("Hello World");
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].taxable = value;
        }
        setPurchaseItems(updated);
    };
    const handleIGST = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].igst = value;
        }
        setPurchaseItems(updated);
    };
    const handleCGST = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].cgst = value;
        }
        setPurchaseItems(updated);
    };
    const handleSGST = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].sgst = value;
        }
        setPurchaseItems(updated);
    };
    const handleNetAmt = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].netAmt = value;
        }
        setPurchaseItems(updated);
    };
    const handleUnitProfit = (value: string, id: string) => {
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].unitProfit = value;
        }
        setPurchaseItems(updated);
    };
    const handleTotalProfit = (value: string, id: string) => {
        console.log("TotalProfit");
        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].totalProfit = value;
        }
        setPurchaseItems(updated);
    };

    const handleAttributeClick = (id: string) => {
        let error = false;

        let updated = [...purchaseItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            setPcode(updated[ind].productCode);
            setPgstPercent(parseInt(updated[ind].gst));
            setPQty(parseInt(updated[ind].qty));
            setPFree(parseInt(updated[ind].free));
            setPNetAmt(parseFloat(updated[ind].netAmt));
            setPTotalTaxable(parseFloat(updated[ind].taxable));
            setPTotalProfit(parseFloat(updated[ind].totalProfit));
            setPPwg(parseFloat(updated[ind].pwg));
            setPPwog(parseFloat(updated[ind].pwog));
            setPWPrice(parseFloat(updated[ind].sRate1));
            setPRPrice(parseFloat(updated[ind].sRate2));
            setPUPrice(parseFloat(updated[ind].sRate3));
            //new
            setPPRate(parseFloat(updated[ind].prate));
            setPSdrs(parseFloat(updated[ind].sdRs));
            setPPdPercent(parseFloat(updated[ind].pdPercent));
            setPPdrs(parseFloat(updated[ind].pdRs));
            setPCdPercent(parseFloat(updated[ind].cdPercent));
            setPCdrs(parseFloat(updated[ind].cdRs));
            setPFcost(parseFloat(updated[ind].fcost));
            setPLcost(parseFloat(updated[ind].lcost));
            setPMarginPercent(parseFloat(updated[ind].marginPercent));
            setPMarginAmt(parseFloat(updated[ind].marginAmt));
            setPSalesRate(parseFloat(updated[ind].salesRate));
            //
            setPMrp(parseFloat(updated[ind].mrp));
            setPIgst(parseFloat(updated[ind].igst));
            setPCgst(parseFloat(updated[ind].cgst));
            setPSgst(parseFloat(updated[ind].sgst));
            setPTax(parseFloat(updated[ind].taxable));
            setPAmt(parseFloat(updated[ind].netAmt));
            setPUprofit(parseFloat(updated[ind].unitProfit));
            setPTprofit(parseFloat(updated[ind].totalProfit));
            setPProfitPercent(parseFloat(updated[ind].profitPercent));
        }
        if (
            updated[ind].productCode !== "" &&
            updated[ind].gst !== "" &&
            updated[ind].qty !== "" &&
            updated[ind].free !== "" &&
            updated[ind].taxable !== "" &&
            updated[ind].netAmt !== ""
        ) {
            if (supPlace === "") {
                toast("Please select supply place", ToastTypes.ERROR);
                error = true;
            }
            if (updated[ind].productCode === "") {
                toast("Please select product code", ToastTypes.ERROR);
                error = true;
            }
            if (updated[ind].gst === "") {
                toast("Please select gst", ToastTypes.ERROR);
                error = true;
            }
            if (updated[ind].qty === "") {
                toast("Please fill product qty", ToastTypes.ERROR);
                error = true;
            }
            if (updated[ind].free === "") {
                toast("Please fill product free qty", ToastTypes.ERROR);
                error = true;
            }
            if (updated[ind].taxable === "") {
                toast("Please fill product taxable", ToastTypes.ERROR);
                error = true;
            }
            if (updated[ind].netAmt === "") {
                toast("Please fill product netAmt", ToastTypes.ERROR);
                error = true;
            }

            if (error === false) {
                setShowAttributeModal(true);
            }
        } else toast("Please Fill product details", ToastTypes.ERROR);
        // setPurchaseItems(updated)
    };

    //Bill side
    const handlePaymentMode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentMode(e.target.value as PurchasePaymentModeTypes);
        setPaymentModeError("");
    };

    const handleBank: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBankNumber(e.target.value);
        if (e.target.value) {
            let obj = AccountLists.find(
                (a) => a.accountNumber?.toString() === e.target.value.toString()
            );
            setBankName(obj?.accountName ? obj?.accountName : "");
        }
    };

    // const handleDisPercent: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setDisPercent(e.target.value)
    //     setDisPercentError('')
    // }
    const handleDisPercent: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        if (parseInt(value) < 0) {
            setDisPercent("0");
            setDisPercentError("Minus value not allowed");
        } else {
            setDisPercent(value);
            setDisPercentError("");
        }
    };

    // const handleDisAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setDisAmt(e.target.value)
    //     setDisAmtError('')
    // }
    const handleDisAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        if (parseFloat(value) < 0) {
            setDisAmt("0");
            setDisAmtError("Minus value not allowed");
            return;
        }
        setDisAmt(value);
        setDisAmtError("");
    };

    const handleTotalAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotAmt(e.target.value);
        setTotAmtError("");
    };
    const handleBillAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBillAmount(e.target.value);
        setBillAmountError("");
    };
    const handleRoundOff: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRoundOff(e.target.value);
        setRoundOffError("");
    };
    const handleBillNetAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBillNetAmt(e.target.value);
        setBillNetAmtError("");
    };
    const handleTotFrieghtCost: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotFreightCost(e.target.value);
        setTotFreightCostError("");
    };
    // const handleAdd: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setAdd(e.target.value)
    //     setAddError('')
    //     setFinalAmount(prev => e.target.value ? (Math.round(parseFloat(prev) + parseFloat(e.target.value))).toString() : prev)
    // }
    const handleAdd: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        if (parseFloat(value) < 0) {
            setAdd("0");
            setAddError("Minus value not allowed");
            return;
        }
        setAdd(value);
        setAddError("");
        setFinalAmount((prev) =>
            value ? (parseFloat(prev) + parseFloat(value)).toString() : prev
        );
    };

    // const handleLess: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setLess(e.target.value)
    //     setLessError('')
    //     setFinalAmount(prev => e.target.value ? (Math.round(parseFloat(prev) - parseFloat(e.target.value))).toString() : prev)
    // }

    const handleLess: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        if (parseFloat(value) < 0) {
            setLess("0");
            setLessError("Minus value not allowed");
            return;
        }
        setLess(value);
        setLessError("");
        setFinalAmount((prev) =>
            value ? (parseFloat(prev) - parseFloat(value)).toString() : prev
        );
    };

    const handleGrandTotal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGTotal(e.target.value);
        setGTotalError("");
    };
    const handleAmtInWords: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAmtInWords(e.target.value);
        setAmtInWordsError("");
    };
    const handlePaymentNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentNo(e.target.value);
        setPaymentNoError("");
    };

    // const handleAmtPaid: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setAmtPaid(cash+card1+card2+onlinePayment1+onlinePayment2)
    //     setAmtPaidError('')
    // }
    const handleCurrentBal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCurrentBal(e.target.value);
        setCurrentBalError("");
    };
    const handlePreviousBal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPreviousBal(e.target.value);
        setPreviousBalError("");
    };
    const handleOverAllBal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOverAllBal(e.target.value);
        setOverAllBalError("");
    };
    const handleDeliveryMode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDeliveryMode(e.target.value);
        setDeliveryModeError("");
    };
    const handleDeliveryDetails: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDeliveryDetails(e.target.value);
        setDeliveryDetailsError("");
    };
    //
    const handleInvoiceTypeClick = () => {
        setShowPurchaseForm(true);
    };
    const handleView = () => {
        setShowPurchaseView(true);
    };
    const handleFormClose = () => {
        setShowPurchaseForm(false);
        setShowPurchaseView(false);
        setShowAttributeModal(false);
        setIsModalOpen(false);
    };

    //update stock

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsBalanceChecked(!isBalanceChecked);
    };
    // const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    //     if (e.key === 'Enter') {
    //         e.preventDefault();
    //         const index = purchaseItems.findIndex(pi => pi.id === id);

    //         const nextIndex = index + 1;
    //         if (nextRowRef.current[nextIndex]) {
    //             nextRowRef.current[nextIndex].focus()
    //         }
    //         if (index !== -1 && nextRowRef.current[index]) {
    //             nextRowRef.current[index].focus()
    //         }
    //     }
    // }
    const handleShowConfirmationModel = () => {
        if (
            parseFloat(invoiceAmount).toFixed(2) ===
            parseFloat(finalAmount).toFixed(2)
        ) {
            setShowFinalSubmitModal(true);
        }
    };

    const handleSave = () => {
        let error = false;

        const data = purchaseItems.filter((f) => {
            // console.log(f.productName?.label);
            // console.log(f.productName?.value);
            return (
                f.productName?.label !== "" &&
                f.productName?.label !== undefined &&
                f.productName?.value !== "" &&
                f.productName?.value !== undefined
            );
        });
        const pItemTotFreightCost = data.reduce((prev, curr) => {
            return prev + parseFloat(curr.fcost);
        }, 0);
        // if (
        //     !isBalanceChecked &&
        //     pItemTotFreightCost !== parseFloat(totFreightCost)
        // ) {
        //     toast('Mismatch in Total Freight cost and Purchase Items Freight Cost', ToastTypes.ERROR);
        //     error = true;
        // }

        if (data.length > 0) {
            data.forEach((d, i) => {
                if (d.productName?.value === "") {
                    toast(`In Row ${d.id}, Product required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.productCode === "") {
                    toast(`In Row ${d.id},Product Code required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.hsn === "") {
                    toast(`In Row ${d.id},HSN required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.gst === "") {
                    toast(`In Row ${d.id},GST required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.batchNo === "") {
                    toast(`In Row ${d.id},Batch required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.expiryDt?.toDateString() === "") {
                    toast(`In Row ${d.id},Exp date required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.qty === "") {
                    toast(`In Row ${d.id},Quantity required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.free === "") {
                    toast(`In Row ${d.id},Free required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.pwg === "") {
                    toast(`In Row ${d.id},PWG required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.prate === "") {
                    toast(`In Row ${d.id},prate required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.fcost === "") {
                    toast(`In Row ${d.id},fcost required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.lcost === "") {
                    toast(`In Row ${d.id},lcost required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.salesRate === "") {
                    toast(`In Row ${d.id},salesRate required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.pwog === "") {
                    toast(`In Row ${d.id},PWOG required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.sRate1 === "") {
                    toast(`In Row ${d.id},Wholesale Price required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.sRate2 === "") {
                    toast(`In Row ${d.id},Retail Price required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.sRate3 === "") {
                    toast(`In Row ${d.id},Unit Price required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.mrp === "") {
                    toast(`In Row ${d.id},MRP required`, ToastTypes.ERROR);
                    error = true;
                }

                if (parseFloat(d.pwg) < parseFloat(d.pwog)) {
                    toast(
                        `In Row ${d.id},PWG must be greater than PWOG`,
                        ToastTypes.ERROR
                    );
                    error = true;
                } else if (parseFloat(d.sRate1) < parseFloat(d.pwg)) {
                    toast(
                        `In Row ${d.id},Rate 1 must be greater than PWG`,
                        ToastTypes.ERROR
                    );
                    error = true;
                } else if (parseFloat(d.sRate2) < parseFloat(d.sRate1)) {
                    toast(
                        `In Row ${d.id},Rate 2 must be greater than Rate 1`,
                        ToastTypes.ERROR
                    );
                    error = true;
                } else if (parseFloat(d.sRate3) < parseFloat(d.sRate2)) {
                    toast(
                        `In Row ${d.id},Rate 3 must be greater than Rate 2`,
                        ToastTypes.ERROR
                    );
                    error = true;
                } else if (parseFloat(d.mrp) < parseFloat(d.sRate3)) {
                    toast(
                        `In Row ${d.id},MRP must be greater than Rate 3`,
                        ToastTypes.ERROR
                    );
                    error = true;
                }

                if (d.taxable === "") {
                    toast(`In Row ${d.id},Tax required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.netAmt === "") {
                    toast(`In Row ${d.id},NetAmt required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.unitProfit === "") {
                    toast(`In Row ${d.id},Unit Profit required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.totalProfit === "") {
                    toast(`In Row ${d.id},Total Profit required`, ToastTypes.ERROR);
                    error = true;
                }
            });
        } else {
            toast("Data Required", ToastTypes.ERROR);
            error = true;
        }
        ///top
        if (financialYear === "") {
            toast(`Financial Year required`, ToastTypes.ERROR);
            error = true;
        }
        if (invoiceType === "") {
            toast(`Invoice type required`, ToastTypes.ERROR);
            error = true;
        }
        if (invoiceTitle === "") {
            toast(`Invoice title required`, ToastTypes.ERROR);
            error = true;
        }
        if (From === "Purchase" && invoiceNo === "") {
            toast(`Invoice No required`, ToastTypes.ERROR);
            error = true;
        }
        if (invoiceDate === "") {
            toast(`Invoice date required`, ToastTypes.ERROR);
            error = true;
        }
        if (purchaseEntryNo === "") {
            toast(`Purchase Entry No required`, ToastTypes.ERROR);
            error = true;
        }
        if (supplier === "") {
            toast(`Supplier required`, ToastTypes.ERROR);
            error = true;
        }
        // if (branch === '') {
        //     toast(`Branch required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (supPlace === '') {
        //     toast(`Branch required`, ToastTypes.ERROR)
        //     error = true
        // }

        ///right
        if (billAmount === "") {
            toast(`Bill Amt required`, ToastTypes.ERROR);
            error = true;
        }
        if (billNetAmt === "") {
            toast(`Net Amt required`, ToastTypes.ERROR);
            error = true;
        }
        if (totAmt === "") {
            toast(`Total Amt required`, ToastTypes.ERROR);
            error = true;
        }
        if (totFreightCost === "") {
            toast(`Total Freight cost required`, ToastTypes.ERROR);
            error = true;
        }
        if (gtotal === "") {
            toast(`Grand total required`, ToastTypes.ERROR);
            error = true;
        }
        if (
            [
                PurchasePaymentModesDropDown.BANK,
                PurchasePaymentModesDropDown.CASH,
                PurchasePaymentModesDropDown.CREDIT,
            ].indexOf(paymentMode as PurchasePaymentModeTypes) === -1
        ) {
            toast("Payment Mode must be BANK/CASH/CREDIT");
            error = true;
        }

        if (paymentMethod === "credit") {
            if (parseFloat(amtPaid) > 0) {
                toast(`Amt Paid value invalid`, ToastTypes.ERROR);
            }
        }

        if (paymentMethod === "cashAndBank") {
            if (parseFloat(amtPaid) <= 0) {
                toast(`Amt Paid value invalid`, ToastTypes.ERROR);
            }
        }

        // if (paymentMode === PurchasePaymentModesDropDown.CASH || paymentMode === PurchasePaymentModesDropDown.BANK) {
        //     if (parseFloat(amtPaid) <= 0) {
        //         toast(`Amt Paid value invalid`, ToastTypes.ERROR)
        //         error = true
        //     }
        // }
        if (amtInWords === "") {
            toast(`Amt In Words required`, ToastTypes.ERROR);
            error = true;
        }
        if (currentBal === "") {
            toast(`Current balance required`, ToastTypes.ERROR);
            error = true;
        }
        if (previousBal === "") {
            toast(`Previous balance required`, ToastTypes.ERROR);
            error = true;
        }
        if (overAllBal === "") {
            toast(`OverAll balance required`, ToastTypes.ERROR);
            error = true;
        }
        if (deliveryDetails === "") {
            toast(`Delivery details required`, ToastTypes.ERROR);
            error = true;
        }
        if (deliveryMode === "") {
            toast(`Delivery mode required`, ToastTypes.ERROR);
            error = true;
        }
        if (From === "Purchase" && invoiceAmount === "") {
            toast(`Invoice Amount required`, ToastTypes.ERROR);
            error = true;
        }
        if (finalAmount === "") {
            toast(`Grand total required`, ToastTypes.ERROR);
            error = true;
        }
        //

        //new model fields check
        if (orderNo !== "" && orderNo.length > 15) {
            toast("Order no should be within 15 character", ToastTypes.ERROR);
            error = true;
        }
        if (placeOfSupply !== "" && placeOfSupply.length > 30) {
            toast("Place of supply should be within 30 character", ToastTypes.ERROR);
            error = true;
        }
        if (transportMode !== "" && transportMode.length > 30) {
            toast("Transport mode should be within 30 character", ToastTypes.ERROR);
            error = true;
        }
        if (transportName !== "" && transportName.length > 30) {
            toast("Transport name should be within 30 character", ToastTypes.ERROR);
            error = true;
        }
        if (vehicleNo !== "" && vehicleNo.length > 30) {
            toast("vehicle no should be within 30 character", ToastTypes.ERROR);
            error = true;
        }
        if (noOfBundles !== "" && noOfBundles.length > 11) {
            toast("No of Bundles should be within 11 character", ToastTypes.ERROR);
            error = true;
        }
        if (noOfQty !== "" && noOfQty.length > 11) {
            toast("No of qty should be within 11 character", ToastTypes.ERROR);
            error = true;
        }
        if (ewayBillNo !== "" && ewayBillNo.length > 25) {
            toast("Eway bill no should be within 25 character", ToastTypes.ERROR);
            error = true;
        }
        if (ackNo !== "" && ackNo.length > 25) {
            toast(
                "Acknowledgement no should be within 25 character",
                ToastTypes.ERROR
            );
            error = true;
        }
        if (irnNo !== "" && irnNo.length > 25) {
            toast("IRN no should be within 15 character", ToastTypes.ERROR);
            error = true;
        }
        if (remarks !== "" && remarks.length > 250) {
            toast("Remarks must be within 250 characters", ToastTypes.ERROR);
            error = true;
        }

        if (
            From === "Purchase" &&
            !isBalanceChecked &&
            data.length > 0 &&
            parseFloat(invoiceAmount).toFixed(2) !==
            parseFloat(finalAmount).toFixed(2)
            // invoiceAmount !== finalAmount
        ) {
            toast(
                "Mismatch in Invoice and Bill Amount, Kindly Check",
                ToastTypes.ERROR
            );
            error = true;
        }

        const transactions = [];

        if (parseFloat(cash) > 0) {
            transactions.push({
                paymentMode: "Cash",
                particulars: "Cash",
                amtPaid: parseInt(cash),
            });
        }
        if (parseFloat(card1) > 0) {
            transactions.push({
                paymentMode: "Bank",
                particulars: "Card1",
                amtPaid: parseInt(card1),
            });
        }
        if (parseFloat(card2) > 0) {
            transactions.push({
                paymentMode: "Bank",
                particulars: "Card2",
                amtPaid: parseInt(card2),
            });
        }
        if (parseFloat(onlinePayment1) > 0) {
            transactions.push({
                paymentMode: "Bank",
                particulars: "Online Payment 1",
                amtPaid: parseInt(onlinePayment1),
            });
        }
        if (parseFloat(onlinePayment2) > 0) {
            transactions.push({
                paymentMode: "Bank",
                particulars: "Online Payment 2",
                amtPaid: parseInt(onlinePayment2),
            });
        }
        if (paymentMethod === "credit") {
            transactions.push({
                paymentMode: "Credit",
                particulars: "Credit",
                amtPaid: 0,
            });
        }

        if (transactions.length === 0) {
            toast("Fill the tender details", ToastTypes.ERROR);
            error = true;
        }

        if (error === false) {
            const netTaxable = data.reduce((previous, current) => {
                return previous + parseFloat(current.taxable);
            }, 0);
            const netIgst = data.reduce((previous, current) => {
                return previous + parseFloat(current.igst);
            }, 0);
            const netCgst = data.reduce((previous, current) => {
                return previous + parseFloat(current.cgst);
            }, 0);
            const netSgst = data.reduce((previous, current) => {
                return previous + parseFloat(current.sgst);
            }, 0);
            const netPercent = data.reduce((previous, current) => {
                return previous + parseFloat(current.profitPercent);
            }, 0);
            //A
            const AData = data.filter((a) => a.gst.toString() === "0");
            const AValue = AData.reduce((previous, current) => {
                return (
                    previous +
                    parseFloat(current.igst) +
                    parseFloat(current.cgst) +
                    parseFloat(current.sgst)
                );
            }, 0);
            const ATaxable = AData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable);
            }, 0);
            //B
            const BData = data.filter((a) => a.gst.toString() === "5");
            const BValue = BData.reduce((previous, current) => {
                return (
                    previous +
                    parseFloat(current.igst) +
                    parseFloat(current.cgst) +
                    parseFloat(current.sgst)
                );
            }, 0);
            const BTaxable = BData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable);
            }, 0);
            //C
            const CData = data.filter((a) => a.gst.toString() === "12");
            const CValue = CData.reduce((previous, current) => {
                return (
                    previous +
                    parseFloat(current.igst) +
                    parseFloat(current.cgst) +
                    parseFloat(current.sgst)
                );
            }, 0);
            const CTaxable = CData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable);
            }, 0);
            //D
            const DData = data.filter((a) => a.gst.toString() === "18");
            const DValue = DData.reduce((previous, current) => {
                return (
                    previous +
                    parseFloat(current.igst) +
                    parseFloat(current.cgst) +
                    parseFloat(current.sgst)
                );
            }, 0);
            const DTaxable = DData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable);
            }, 0);
            //E
            const EData = data.filter((a) => a.gst.toString() === "28");
            const EValue = EData.reduce((previous, current) => {
                return (
                    previous +
                    parseFloat(current.igst) +
                    parseFloat(current.cgst) +
                    parseFloat(current.sgst)
                );
            }, 0);
            const ETaxable = EData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable);
            }, 0);

            let idate = new Date(invoiceDate);
            var days = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
            ];
            var dayName = days[idate.getDay()];

            // console.log('new' + cash);
            // let credit =  0
            // if (parseInt(previousBal) == overallBalance){
            //       credit = 0;
            // }
            // else if (parseInt(previousBal) < overallBalance){
            //         let creditIncrement = overallBalance - parseInt(previousBal);
            //         credit = creditIncrement;
            // }
            // else if (parseInt(previousBal) > overallBalance){
            //     let creditDecrement = overallBalance - parseInt(previousBal);
            //     credit = -(creditDecrement);
            // }
            // if (credit > 0) {
            //     transactions.push({ paymentMode: 'Credit', particulars: 'credit', amtPaid: credit });
            // }

            let addData: PurchaseType = {
                financeYear: financialYear,
                branchId: authUser?.branchId ? authUser.branchId : 0,
                invoiceType: invoiceType,
                invoiceTitle: invoiceTitle,
                invoiceDate: invoiceDate,
                goodsReceivedDate: gudsRecdDate,
                invoiceDay: dayName,
                invoiceWeekNumber: 1,
                invoiceWeekDay: idate.getDay(),
                invoiceMonth: idate.getMonth() + 1,
                invoiceYear: idate.getFullYear(),
                invoiceNumber: From === "Purchase" ? invoiceNo : "",
                invoiceAmount: parseFloat(invoiceAmount),
                purchaseEntryNumber: parseInt(purchaseEntryNo),
                repId: parseInt(refBy),
                supplierId: parseInt(supplier),
                //new
                systemNo: sysNo,
                paymentMode: paymentMode,
                supplierName: supName,
                bankName: bankName,
                bankNumber: parseInt(bankNumber),
                billAmount: billAmount,
                disPercent: disPercent,
                disAmount: disAmt,
                freightCost: totFreightCost,
                add: add,
                less: less,
                grandTotal: gtotal,
                amtInWords: amtInWords,
                particulars: paymentNo,
                amtPaid: amtPaid,
                deliveryMode: deliveryMode,
                deliveryDetails: deliveryDetails,
                netTaxable: netTaxable.toString(),
                netIgst: netIgst.toString(),
                netSgst: netSgst.toString(),
                netCgst: netCgst.toString(),
                netProfit: totalProfit,
                netProfitPercent: netPercent.toString(),
                AValue: AValue.toString(),
                ATaxable: ATaxable.toString(),
                BValue: BValue.toString(),
                BTaxable: BTaxable.toString(),
                CValue: CValue.toString(),
                CTaxable: CTaxable.toString(),
                DValue: DValue.toString(),
                DTaxable: DTaxable.toString(),
                EValue: EValue.toString(),
                ETaxable: ETaxable.toString(),
                //
                orderDate: orderDate,
                orderNo: orderNo,
                dateOfSupply: dateOfSupply,
                placeOfSupply: placeOfSupply,
                transportMode: transportMode,
                transportName: transportName,
                vehicleNo: vehicleNo,
                noOfBundles: noOfBundles ? parseInt(noOfBundles) : 0,
                noOfQty: noOfQty ? parseInt(noOfQty) : 0,
                netWeight: netWeight ? parseFloat(netWeight) : 0,
                ewayBillNo: ewayBillNo,
                acknowNo: ackNo,
                acknowDate: ackDate,
                irnNo: irnNo,
                irnDate: irnDate,
                buyerOrderDate: buyerOrderDate,
                buyerOrderNo: buyerOrderNo,
                destination: destination,
                dispatchDocumentNo: dispatchDocumentNo,
                remarks: From === "Purchase" ? remarks : "OpeningStock",
                transactions: transactions,
                stockItems: cartesianTableData,
                remainingRecordExist: isBalanceChecked ? 1 : 0,
                purchaseItems: data.map((dd) => {
                    const obj: PurchaseItems = {
                        entryNumber: parseInt(purchaseEntryNo),
                        productId: dd.productName?.value
                            ? parseInt(dd.productName?.value)
                            : 0,
                        productCode: dd.productCode,
                        batchNumber: parseInt(dd.batchNo),
                        expiryDate: getDate(dd.expiryDt),
                        quantity: dd.qty,
                        freeQuantity: dd.free,
                        totalQuantity: (
                            parseFloat(dd.qty) + (dd.free ? parseFloat(dd.free) : 0)
                        ).toString(),
                        purchaseRate: parseFloat(dd.prate),
                        priceWogst: parseFloat(dd.pwog),
                        priceGst: parseFloat(dd.pwg),
                        freightCost: parseFloat(dd.fcost),
                        landingCost: parseFloat(dd.lcost),
                        marginPercent: parseFloat(dd.marginPercent),
                        marginAmt: parseFloat(dd.marginAmt),
                        salesRate: parseFloat(dd.salesRate),
                        wRate: parseFloat(dd.sRate1),
                        rRate: parseFloat(dd.sRate2),
                        lRate: parseFloat(dd.sRate3),
                        mrp: parseFloat(dd.mrp),
                        sDiscount: parseFloat(dd.sdRs),
                        pDiscountpercent: parseFloat(dd.pdPercent),
                        pDiscountamount: parseFloat(dd.pdRs),
                        cDiscountpercent: parseFloat(dd.cdPercent),
                        cDiscountamount: parseFloat(dd.cdRs),
                        taxableAmount: parseFloat(dd.taxable),
                        igstAmount: parseFloat(dd.igst),
                        cgstAmount: parseFloat(dd.cgst),
                        sgstAmount: parseFloat(dd.sgst),
                        totalAmount: parseFloat(dd.netAmt),
                        unitCost: parseFloat(dd.unitProfit),
                        profitPercent: parseFloat(dd.profitPercent),
                        profitAmount: parseFloat(dd.totalProfit),
                    };
                    return obj;
                }),
            };
            if (From === "Purchase") {
                if (formType === FormTypeList.ADD) {
                    setShowOverlayLoader();
                    dispatch(addPurchaseAction(addData))
                        .then((response) => {
                            const { message, invoiceNumber, purchaseEntryNumber } = response;
                            setModalInvoiceNumber(invoiceNumber);
                            setModalPurchaseEntryNo(purchaseEntryNumber);
                            setModalMessage(message);
                            setIsModalOpen(true);
                            toast(message, ToastTypes.SUCCESS);
                            resetStates();
                            dispatch(fetchPurchaseAction());
                        })
                        .catch((error) => {
                            toast(error, ToastTypes.ERROR);
                        })
                        .finally(() => {
                            setHideOverlayLoader();
                        });
                } else if (formType === FormTypeList.EDIT && id) {
                    setShowOverlayLoader();
                    dispatch(updatePurchaseAction(addData, id))
                        .then((response) => {
                            const { message, invoiceNumber, purchaseEntryNumber } = response;
                            setModalInvoiceNumber(invoiceNumber);
                            setModalPurchaseEntryNo(purchaseEntryNumber);
                            setModalMessage(message);
                            setIsModalOpen(true);
                            toast(message, ToastTypes.SUCCESS);
                            resetStates();
                            dispatch(fetchPurchaseAction());
                        })
                        .catch((text) => {
                            toast(text, ToastTypes.ERROR);
                        })
                        .finally(() => {
                            setHideOverlayLoader();
                        });
                }
            } else if (From === "OpeningStock") {
                if (formType === FormTypeList.ADD) {
                    setShowOverlayLoader();
                    dispatch(addOpeningStockAction(addData))
                        .then((text) => {
                            toast(text, ToastTypes.SUCCESS);
                            resetStates();
                            dispatch(fetchPurchaseAction());
                        })
                        .catch((text) => {
                            toast(text, ToastTypes.ERROR);
                        })
                        .finally(() => {
                            setHideOverlayLoader();
                        });
                } else if (formType === FormTypeList.EDIT && id) {
                    setShowOverlayLoader();
                    dispatch(updateOpeningStockAction(addData, id))
                        .then((text) => {
                            toast(text, ToastTypes.SUCCESS);
                            resetStates();
                            dispatch(fetchPurchaseAction());
                        })
                        .catch((text) => {
                            toast(text, ToastTypes.ERROR);
                        })
                        .finally(() => {
                            setHideOverlayLoader();
                        });
                }
            }
        }
    };

    useEffect(() => {
        const data = purchaseItems.filter((f) => {
            return (
                f.productName?.label !== "" &&
                f.productName?.label !== undefined &&
                f.productName?.value !== "" &&
                f.productName?.value !== undefined
            );
        });
        const bill = data
            .reduce((previous, current) => {
                return previous + parseFloat(current.netAmt);
            }, 0)
            .toFixed(2);
        setBillAmount(bill.toString());
        setDisAmt("0");
        setDisPercent("0");
    }, [purchaseItems]);

    useEffect(() => {
        const data = purchaseItems.filter((f) => {
            return (
                f.productName?.label !== "" &&
                f.productName?.label !== undefined &&
                f.productName?.value !== "" &&
                f.productName?.value !== undefined
            );
        });
        const tProfit = data
            .reduce((previous, current) => {
                return previous + parseFloat(current.totalProfit);
            }, 0)
            .toFixed(2);
        setTotalProfit(tProfit.toString());
    }, [purchaseItems]);

    // useEffect(() => {
    //     setDisAmt('0')
    //     setDisPercent('0')
    // }, [billAmount])
    useEffect(() => {
        let fAmt = parseFloat(gtotal);
        setFinalAmount(fAmt.toString());
    }, [gtotal]);

    useEffect(() => {
        let billnetAmt = Math.round(totAmt ? parseFloat(totAmt) : 0);
        setBillNetAmt(billnetAmt.toString());
    }, [totAmt]);

    // useEffect(() => {
    //     if (parseFloat(previousBal) < 0) {
    //         let cbal = -((previousBal ? Math.abs(parseFloat(previousBal)) : 0) - parseFloat(amtPaid))
    //         setCurrentBal(cbal.toString())
    //     } else {
    //         let cbal = (previousBal ? parseFloat(previousBal) : 0) - parseFloat(amtPaid)
    //         setCurrentBal(cbal.toString())
    //     }
    // }, [amtPaid, previousBal])

    useEffect(() => {
        if (amtPaid === "0") {
            setCurrentBal(gtotal);
        } else {
            let cbal = parseFloat(gtotal) - parseFloat(amtPaid);
            setCurrentBal(cbal.toString());
        }
    }, [amtPaid, gtotal]);

    useEffect(() => {
        let obal =
            (currentBal ? parseFloat(currentBal) : 0) +
            (previousBal ? parseFloat(previousBal) : 0);
        setOverAllBal(obal.toString());
    }, [currentBal, previousBal]);

    useEffect(() => {
        let gtot =
            (billNetAmt ? parseFloat(billNetAmt) : 0) +
            (add ? parseFloat(add) : 0) -
            (less ? parseFloat(less) : 0);
        setGTotal(Math.round(gtot).toString());
    }, [add, less, billNetAmt]);

    useEffect(() => {
        let tamt =
            (billAmount ? parseFloat(billAmount) : 0) -
            (disAmt ? parseFloat(disAmt) : 0);
        setTotAmt(tamt.toString());
    }, [disAmt, billAmount]);

    useEffect(() => {
        if (totAmt) {
            let Total = Math.round(parseFloat(totAmt)) - parseFloat(totAmt);
            setRoundOff(Total.toFixed(4));
        }
    }, [totAmt]);

    useEffect(() => {
        if (disPercent !== "") {
            let damt =
                ((billAmount ? parseFloat(billAmount) : 0) *
                    (disPercent ? parseFloat(disPercent) : 0)) /
                100;
            setDisAmt(damt.toString());
        }
    }, [billAmount, disPercent]);

    useEffect(() => {
        const d = new Date();
        const todayDate = d.getDate(); //27
        const currentMonth = d.getMonth() + 1; //6
        const currentYear = d.getFullYear(); //2022

        if (currentMonth >= 4 && todayDate >= 1) {
            let year = currentYear + "-" + (currentYear + 1);
            setFinancialYear(year);
        } else if (currentMonth < 4) {
            let year = currentYear - 1 + "-" + currentYear;
            setFinancialYear(year);
        }
    }, []);

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            // background: '#fff',
            // borderColor: '#9e9e9e',
            minHeight: "23px",
            height: "23px",
            boxShadow: state.isFocused ? null : null,
            width: "150px",
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: "25px",
            padding: "0 5px",
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: "0px",
        }),
        dropdownIndicator: (prevStyle: any, state: any) => ({
            // ...prevStyle,
            display: "none",
        }),
        indicatorSeparator: (state: any) => ({
            display: "none",
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: "28px",
        }),
        menu: (css: any) => ({
            ...css,
            // 'z-index': 600,
            width: "250px",
        }),
    };

    useEffect(() => {
        setAmtInWords(convertAmtToWords(parseFloat(gtotal), "India"));
    }, [gtotal]);

    useEffect(() => {
        if (From === "Purchase") setShowPurchaseForm(true);
    }, []);

    useEffect(() => {
        if (supplier) {
            (async () => {
                const response = await getTransactionCurrentBalanceByAcNo(
                    supplier,
                    formType === FormTypeList.EDIT ? id?.toString() : undefined
                );
                const data: { currentBalance: number; accountName: string } =
                    response.data.data;

                setPreviousBal(data?.currentBalance?.toString());
            })();
        }
    }, [supplier, formType, id]);

    useEffect(() => {
        if (formType === FormTypeList.EDIT && id) {
            (async () => {
                // const response = dispatch(fetchPurchaseByIdAction(id))
                // const data = response
                try {
                    setShowOverlayLoader();
                    // console.log('hi2');
                    const response = await getPurchaseById(id);
                    const data: PurchaseEditAttributes = response.data.data;
                    // console.log(data);
                    // console.log(data.order_date);

                    const sobj = AccountLists.find(
                        (a) => a.accountNumber?.toString() === data.supplier_id?.toString()
                    );
                    let sname = sobj?.accountName;
                    let sadd = sobj?.address1! + sobj?.address2;
                    let scont = sobj?.contactNumber1;
                    let saadhar = sobj?.aadharNumber;
                    let sgst = sobj?.gstNumber;
                    let splace = sobj?.supplierPlace;

                    const userObj = userList.find(
                        (a) => a.id?.toString() === data.created_by?.toString()
                    );
                    let uname = userObj?.name ? userObj?.name : "";
                    //****top****
                    setInvoiceType(data.invoice_type);
                    setInvoiceTitle(data.invoice_title);
                    setInvoiceDate(data.invoice_date);
                    setGudsRecdDate(data.goods_received_date);
                    setInvoiceNo(data.invoice_number.toString());
                    setInvoiceAmount(data.invoice_amount.toString());
                    setPurchaseEntryNo(data.purchase_entry_number.toString());
                    setSupplier(data.supplier_id.toString());
                    setSupName(sname ? sname : "");
                    setSupAddress(sadd);
                    setSupContact(scont ? scont.toString() : "");
                    setGST(sgst ? sgst : "");
                    setSupAadhar(saadhar ? saadhar.toString() : "");
                    setRefBy(data.rep_id.toString());
                    setSupPlace(splace ? splace : "");
                    setBranch(data.branch_id.toString());
                    setBilledBy(uname);
                    setSysNo(data.system_no);
                    setOrderDate(data.order_date);
                    setOrderNo(data.order_no);
                    setDateOfSupply(data.date_of_supply);
                    setPlaceOfSupply(data.place_of_supply);
                    setTransportMode(data.transport_mode);
                    setTransportName(data.transport_name);
                    setVehicleNo(data.vehicle_no);
                    setNoOfBundles(data.no_of_bundles.toString());
                    setNoOfQty(data.no_of_qty.toString());
                    setNetWeight(data.net_weight.toString());
                    setEwayBillNo(data.eway_bill_no);
                    setAckNo(data.acknow_no);
                    setAckDate(data.acknow_date);
                    setIrnNo(data.irn_no);
                    setIrnDate(data.irn_date);
                    setRemarks(data.remarks !== undefined ? data.remarks : "");
                    //****Right****
                    setBillAmount(data.bill_amount);
                    setDisPercent(data.dis_percent.toString());
                    setDisAmt(data.dis_amount.toString());
                    setTotFreightCost(
                        data.freight_cost ? data.freight_cost?.toString() : "0"
                    );
                    // setTotAmt(data.)
                    // setRoundOff(data.)
                    // setBillNetAmt(data.)
                    setAdd(data.add);
                    setLess(data.less);
                    setGTotal(data.grand_total);
                    setAmtInWords(data.amt_in_words);
                    if (data.payment_mode === PurchasePaymentModesDropDown.CASH) {
                        setPaymentMode(PurchasePaymentModesDropDown.CASH);
                    } else if (data.payment_mode === PurchasePaymentModesDropDown.BANK) {
                        setPaymentMode(PurchasePaymentModesDropDown.BANK);
                        setBankName(
                            data?.Transactions ? data?.Transactions[3]?.account_name : ""
                        );
                        setBankNumber(
                            data?.Transactions
                                ? data?.Transactions[3]?.account_number.toString()
                                : ""
                        );
                    } else if (
                        data.payment_mode === PurchasePaymentModesDropDown.CREDIT
                    ) {
                        setPaymentMode(PurchasePaymentModesDropDown.CREDIT);
                    }
                    setPaymentNo(data.particulars);
                    setAmtPaid(data.amt_paid);
                    // setCurrentBal('')
                    // setPreviousBal('-3800')
                    // setOverAllBal('')
                    setDeliveryMode(data.delivery_mode);
                    setDeliveryDetails(data.delivery_details);
                    setFinalAmount(data.grand_total);

                    setOrderDate(data.order_date);
                    setOrderNo(data.order_no);
                    setDateOfSupply(data.date_of_supply);
                    setPlaceOfSupply(data.place_of_supply);
                    setTransportMode(data.transport_mode);
                    setTransportName(data.transport_name);
                    setVehicleNo(data.vehicle_no);
                    setNoOfBundles(data.no_of_bundles.toString());
                    setNoOfQty(data.no_of_qty.toString());
                    setNetWeight(data.net_weight.toString());
                    setEwayBillNo(data.eway_bill_no);
                    setAckNo(data.acknow_no);
                    setAckDate(data.acknow_date);
                    setIrnNo(data.irn_no);
                    setIrnDate(data.irn_date);
                    setDestination(data.destination);
                    setDispatchDocumentNo(data.dispatch_document_no);
                    setBuyerOrderDate(data.buyer_order_date);
                    setBuyerOrderNo(data.buyer_order_no);

                    setIsBalanceChecked(data.remaining_record_exist === 1 ? true : false);

                    setRemarks(data.remarks !== undefined ? data.remarks : "");

                    //Tendor Edit
                    const transactions = data?.Transactions || [];

                    // Initialize variables
                    let cashAmount = "0";
                    let card1Amount = "0";
                    let card2Amount = "0";
                    let onlinePayment1Amount = "0";
                    let onlinePayment2Amount = "0";
                    let paymentMethodByPaymentMode = "";

                    // Iterate through the Transactions array, starting from the third record and skipping every other record
                    for (let i = 2; i < transactions.length; i += 2) {
                        const transaction = transactions[i];
                        switch (transaction.particulars) {
                            case "Cash":
                                cashAmount = transaction.debit_amount ?? "0";
                                break;
                            case "Card1":
                                card1Amount = transaction.debit_amount ?? "0";
                                break;
                            case "Card2":
                                card2Amount = transaction.debit_amount ?? "0";
                                break;
                            case "Online Payment 1":
                                onlinePayment1Amount = transaction.debit_amount ?? "0";
                                break;
                            case "Online Payment 2":
                                onlinePayment2Amount = transaction.debit_amount ?? "0";
                                break;
                            default:
                                break;
                        }
                    }

                    for (let i = 1; i < transactions.length; i += 1) {
                        const transaction = transactions[i];

                        switch (transaction.payment_mode) {
                            case "Credit":
                                paymentMethodByPaymentMode = "credit";
                                break;
                            case "Cash":
                            case "Bank":
                                paymentMethodByPaymentMode = "cashAndBank";
                                break;
                            default:
                                break;
                        }
                    }

                    // Set the state
                    setCash(cashAmount);
                    setCard1(card1Amount);
                    setCard2(card2Amount);
                    setOnlinePayment1(onlinePayment1Amount);
                    setOnlinePayment2(onlinePayment2Amount);
                    setPaymentMethod(paymentMethodByPaymentMode);

                    // *****attribute table*****
                    const attributeArray = data.PurchaseAttributes;
                    let cdata: PurchaseAttribute[] = [];

                    data.PurchaseItems.forEach((pItem, pindex) => {
                        const prodObj = ProductList.find(
                            (pd) => pd.id?.toString() === pItem.product_id.toString()
                        );
                        let darray = attributeArray?.filter(
                            (f) =>
                                f.product_group_code?.toString() ===
                                prodObj?.productCode?.toString()
                        );
                        cdata.push({
                            productCode: prodObj?.productCode
                                ? prodObj?.productCode?.toString()
                                : "",
                            attribute:
                                darray && darray?.length > 0
                                    ? darray?.map((d) => {
                                        let arr = [
                                            d.attribute1,
                                            d.attribute2,
                                            d.attribute3,
                                            d.attribute4,
                                            d.attribute5,
                                        ];
                                        let name = "";
                                        arr.forEach((a) => {
                                            if (a !== "") {
                                                name = name + a;
                                            }
                                        });
                                        return {
                                            id: nanoid(),
                                            item: name,
                                            itemCode: d.item_code,
                                            productCode: d.product_code,
                                            productGroupCode: d.product_group_code,
                                            qty: d.quantity?.toString(),
                                            free: d.free_quantity?.toString(),
                                            purchaseRate: d.purchase_rate?.toString(),
                                            pwog: d.price_wogst?.toString(),
                                            pwg: d.price_gst?.toString(),
                                            freightCost: d.freight_cost?.toString(),
                                            landingCost: d.landing_cost?.toString(),
                                            marginPercent: d.margin_percent?.toString(),
                                            marginAmt: d.margin_amt?.toString(),
                                            salesRate: d.sales_rate?.toString(),
                                            sRate1: d.w_rate?.toString(),
                                            sRate2: d.r_rate?.toString(),
                                            sRate3: d.l_rate?.toString(),
                                            mrp: d.mrp?.toString(),
                                            sdRs: d.s_discount?.toString(),
                                            pdPercent: d.p_discount_percent?.toString(),
                                            pdRs: d.p_discount_amount?.toString(),
                                            cdPercent: d.c_discount_percent?.toString(),
                                            cdRs: d.c_discount_amount?.toString(),
                                            totDisAmt: (
                                                d.s_discount +
                                                d.p_discount_amount +
                                                d.c_discount_amount
                                            )?.toString(),
                                            taxable: d.taxable_amount?.toString(),
                                            igst: d.igst_amount?.toString(),
                                            cgst: d.cgst_amount?.toString(),
                                            sgst: d.sgst_amount?.toString(),
                                            netAmt: d.total_amount?.toString(),
                                            unitProfit: d.unit_profit?.toString(),
                                            totalProfit: d.profit_amount?.toString(),
                                            profitPercent: d.profit_percent?.toString(),
                                        };
                                    })
                                    : [],
                        });
                    });
                    setCartesianTableData(cdata);
                    //
                    //****table****
                    const purchaseItemEditData = getDefaultPurchaseItems();
                    data.PurchaseItems.forEach((pItem, pindex) => {
                        const prodObj = ProductList.find(
                            (pd) => pd.id?.toString() === pItem.product_id.toString()
                        );

                        purchaseItemEditData[pindex] = {
                            id: pindex + 1,
                            productName: {
                                label: prodObj?.productName ? prodObj?.productName : "",
                                value: pItem.product_id.toString()
                                    ? pItem.product_id.toString()
                                    : "",
                            },
                            productCode: prodObj?.productCode
                                ? prodObj?.productCode.toString()
                                : "",
                            hsn: prodObj?.sac ? prodObj?.sac.toString() : "",
                            gst: prodObj?.gst ? prodObj?.gst : "",
                            batchNo: pItem.batch_number.toString(),
                            expiryDt: new Date(pItem.expiry_date),
                            qty: pItem.quantity.toString(),
                            free: pItem.free_quantity.toString(),
                            prate: pItem.purchase_rate.toString(),
                            sdRs: pItem.s_discount.toString(),
                            pdPercent: pItem.p_discount_percent.toString(),
                            pdRs: pItem.p_discount_amount.toString(),
                            cdPercent: pItem.c_discount_percent.toString(),
                            cdRs: pItem.c_discount_amount.toString(),
                            totDisAmt: (
                                pItem.s_discount +
                                pItem.p_discount_amount +
                                pItem.c_discount_amount
                            ).toString(),
                            pwog: pItem.price_wogst.toString(),
                            pwg: pItem.price_gst.toString(),
                            fcost: pItem.freight_cost.toString(),
                            lcost: pItem.landing_cost.toString(),
                            marginPercent: pItem.margin_percent.toString(),
                            marginAmt: pItem.margin_amt.toString(),
                            salesRate: pItem.sales_rate.toString(),
                            sRate1: pItem.w_rate.toString(),
                            sRate2: pItem.r_rate.toString(),
                            sRate3: pItem.l_rate.toString(),
                            mrp: pItem.mrp.toString(),

                            taxable: pItem.taxable_amount.toString(),
                            igst: pItem.igst_amount.toString(),
                            cgst: pItem.cgst_amount.toString(),
                            sgst: pItem.sgst_amount.toString(),
                            netAmt: pItem.total_amount.toString(),
                            unitProfit: pItem.unit_cost.toString(),
                            totalProfit: pItem.profit_amount.toString(),
                            profitPercent: pItem.profit_percent.toString(),
                        };
                    });
                    setPurchaseItems(purchaseItemEditData);
                } catch (errro) {
                } finally {
                    setHideOverlayLoader();
                }
            })();
        }
    }, [formType, id]);

    useEffect(() => {
        if (From === "OpeningStock") {
            const refBy = AccountLists.find(
                (al) => al.groupName?.toLowerCase() === "representer"
            );
            const sobj = AccountLists.find((a) =>
                a.accountName?.toLowerCase()?.includes("office")
            );
            let sname = sobj?.accountName;
            let sadd = sobj?.address1! + sobj?.address2;
            let scont = sobj?.contactNumber1;
            let saadhar = sobj?.aadharNumber;
            let sgst = sobj?.gstNumber;
            let splace = sobj?.supplierPlace;

            let bobj = branchList.find(
                (b) => b.id?.toString() === authUser?.branchId?.toString()
            );
            let bname = bobj?.name;

            setPurchaseEntryNo(
                (Math.floor(Math.random() * 90000) + 10000).toString()
            );
            setInvoiceType(PURCHASE_TYPE_DROPS[2].value);
            setInvoiceTitle("WHOLESALE");
            setInvoiceDate(today());
            setGudsRecdDate(today());
            setSupplier(sobj?.accountNumber ? sobj?.accountNumber?.toString() : "");
            setSupName(sname ? sname : "");
            setSupAddress(sadd ? sadd : "");
            setSupContact(scont ? scont?.toString() : "");
            setSupAadhar(saadhar ? saadhar?.toString() : "");
            setSupPlace(splace ? splace : "");
            setGST(sgst ? sgst : "");
            setBilledBy(authUser?.name ? authUser?.name : "");
            setSysNo("SYS 1");
            setRefBy(
                refBy && refBy?.accountNumber ? refBy?.accountNumber?.toString() : ""
            );
            setBranch(bname ? bname : "");
            setInvoiceNo("");
            setInvoiceAmount("");
        }
    }, [From, AccountLists, branchList, authUser]);

    return (
        <>
            <Modal
                headerText={"Invoice Details   " + financialYear}
                onClose={handleFormClose}
                centered
                size="xl"
                visible={showPurchaseForm}
                bgColor="#B1D7E9" //1587BE
            >
                <PurchaseForm
                    setOrderDate={setOrderDate}
                    setOrderNo={setOrderNo}
                    setBuyerOrderDate={setBuyerOrderDate}
                    setBuyerOrderNo={setBuyerOrderNo}
                    setDestination={setDestination}
                    setDispatchDocumentNo={setDispatchDocumentNo}
                    setDateOfSupply={setDateOfSupply}
                    setPlaceOfSupply={setPlaceOfSupply}
                    setTransportMode={setTransportMode}
                    setTransportName={setTransportName}
                    setVehicleNo={setVehicleNo}
                    setNoOfBundles={setNoOfBundles}
                    setNoOfQty={setNoOfQty}
                    setNetWeight={setNetWeight}
                    setEwayBillNo={setEwayBillNo}
                    setAckNo={setAckNo}
                    setAckDate={setAckDate}
                    setIrnNo={setIrnNo}
                    setIrnDate={setIrnDate}
                    setRemarks={setRemarks}
                    setInvoiceType={setInvoiceType}
                    setInvoiceTitle={setInvoiceTitle}
                    setInvDate={setInvoiceDate}
                    setInvNo={setInvoiceNo}
                    setSupName={setSupName}
                    setSupAddress={setSupAddress}
                    setSupContact={setSupContact}
                    setGST={setGST}
                    setSupAadhar={setSupAadhar}
                    setBilledBy={setBilledBy}
                    setSysNo={setSysNo}
                    setSupPlace={setSupPlace}
                    setRefBy={setRefBy}
                    setBranch={setBranch}
                    setInvoiceAmount={setInvoiceAmount}
                    setPurchaseEntryNo={setPurchaseEntryNo}
                    setSupplier={setSupplier}
                    setGudsRecdDate={setGudsRecdDate}
                    onSave={handleFormClose}
                    formType={formType}
                    gudsRecdDate={gudsRecdDate}
                    invoiceDate={invoiceDate}
                    invoiceNo={invoiceNo}
                    invoiceAmount={invoiceAmount}
                    purchaseEntryNo={purchaseEntryNo}
                    supplier={supplier}
                    invoiceType={invoiceType}
                    refBy={refBy}
                    orderDate={orderDate}
                    orderNo={orderNo}
                    buyerOrderDate={buyerOrderDate}
                    buyerOrderNo={buyerOrderNo}
                    destination={destination}
                    dispatchDocumentNo={dispatchDocumentNo}
                    dateOfSupply={dateOfSupply}
                    placeOfSupply={placeOfSupply}
                    transportMode={transportMode}
                    transportName={transportName}
                    vehicleNo={vehicleNo}
                    noOfBundles={noOfBundles}
                    noOfQty={noOfQty}
                    netWeight={netWeight}
                    ewayBillNo={ewayBillNo}
                    acknowNo={ackNo}
                    acknowDate={ackDate}
                    irnNo={irnNo}
                    irnDate={irnDate}
                    remarks={remarks}
                    id={id}
                />
            </Modal>
            <Modal
                headerText={From === "Purchase" ? "Purchase" : "Opening Stock"}
                onClose={handleFormClose}
                centered
                size="xl"
                visible={showPurchaseView}
            >
                <Purchase
                    setFormType={setFormType}
                    setId={setId}
                    onclose={handleFormClose}
                    From={From}
                />
            </Modal>
            <Modal
                headerText="Attribute"
                onClose={handleFormClose}
                centered
                size="xl"
                visible={showAttributeModal}
            >
                <GroupProducts
                    pPrate={pprate}
                    pSdrs={pSdrs}
                    pPdPercent={pPdPercent}
                    pPdrs={pPdrs}
                    pCdPercent={pCdPercent}
                    pCdrs={pCdrs}
                    pFcost={pFcost}
                    pLcost={pLcost}
                    pMarginAmt={pMarginAmt}
                    pMarginPercent={pMarginPercent}
                    pSalesRate={pSalesRate}
                    pcode={pcode}
                    pPwg={ppwg}
                    pPwog={ppwog}
                    pWprice={pwPrice}
                    pRprice={prPrice}
                    pUprice={puPrice}
                    pMrp={pmrp}
                    pIgst={pIgst}
                    pCgst={pCgst}
                    pSgst={pSgst}
                    pTax={pTax}
                    pAmt={pAmt}
                    pUprofit={pUprofit}
                    pTprofit={pTprofit}
                    pProfitPercent={pProfitPercent}
                    cartesianTableData={cartesianTableData}
                    setCartesianTableData={setCartesianTableData}
                    supPlace={supPlace}
                    GSTPercent={pgstPercent}
                    pFree={pFree}
                    pNetAmt={pNetAmt}
                    pQty={pQty}
                    pTotalTaxable={pTotalTaxable}
                    pTotalProfit={pTotalProfit}
                    onSave={handleFormClose}
                />
            </Modal>
            <UserLayout>
                <h4>{From == "OpeningStock" ? "Opening Stock" : "Purchase Master"}</h4>
                <div className="row">
                    <div className="col-lg-9">
                        {/* <div className="col-lg-12"> */}
                        <div className="card shadow-lg p-1 pb-0 mb-1">
                            <div className="card-body p-1">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Financial Year </label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="year"
                                                    label=""
                                                    labelClassName="required"
                                                    value={financialYear}
                                                    // onChange={handleProductName}
                                                    errorText={financialYearError}
                                                    containerClass="mb-1 text-end"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Invoice Type </label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="invoiceType"
                                                    label=""
                                                    labelClassName="required"
                                                    value={invoiceType}
                                                    // onChange={handleInvoiceType}
                                                    onClick={handleInvoiceTypeClick}
                                                    errorText={invoiceTypeError}
                                                    containerClass="mb-1 text-end"
                                                    width={50}
                                                    readOnly={true}
                                                    className="input-border-animation"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Invoice Title </label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="invoiceTitle"
                                                    label=""
                                                    labelClassName="required"
                                                    value={invoiceTitle}
                                                    // onChange={handleInvoiceTitle}
                                                    errorText={invoiceTitleError}
                                                    containerClass="mb-1 text-end"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Invoice Date </label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="invoiceDate"
                                                    label=""
                                                    labelClassName="required"
                                                    value={invoiceDate}
                                                    // onChange={handleInvoiceDate}
                                                    errorText={invoiceDateError}
                                                    containerClass="mb-1 text-end"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Invoice No </label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="invoiceNo"
                                                    label=""
                                                    labelClassName="required"
                                                    value={invoiceNo}
                                                    // onChange={handleInvoiceNo}
                                                    errorText={invoiceNoError}
                                                    containerClass="mb-1 text-end"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Supplier</label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="supName"
                                                    label=""
                                                    labelClassName="required"
                                                    value={supName}
                                                    // onChange={handleSupName}
                                                    errorText={supNameError}
                                                    containerClass="mb-1 text-end"
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Address</label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="supAddress"
                                                    label=""
                                                    labelClassName="required"
                                                    value={supAddress}
                                                    // onChange={handleSupAddress}
                                                    errorText={supAddressError}
                                                    containerClass="mb-1"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Contact</label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="supContact"
                                                    label=""
                                                    labelClassName="required"
                                                    value={supContact}
                                                    // onChange={handlesupContact}
                                                    errorText={supContactError}
                                                    containerClass="mb-1"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">GST</label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="gst"
                                                    label=""
                                                    labelClassName="required"
                                                    value={gst}
                                                    // onChange={handlegst}
                                                    errorText={gstError}
                                                    containerClass="mb-1"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Aadhar/Other</label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="supAadhar"
                                                    label=""
                                                    labelClassName="required"
                                                    value={supAadhar}
                                                    // onChange={handlesupAadhar}
                                                    errorText={supAadharError}
                                                    containerClass="mb-1"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Referred By</label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="refBy"
                                                    label=""
                                                    labelClassName="required"
                                                    value={refBy}
                                                    // onChange={handlerefBy}
                                                    errorText={refByError}
                                                    containerClass="p-0 mb-1"
                                                    width={50}
                                                    readOnly={true}
                                                    disabled={true}
                                                    type="select"
                                                >
                                                    <option value=""></option>
                                                    {AccountLists.filter(
                                                        (al) =>
                                                            al.groupName === "representer" ||
                                                            al.groupName === "Representer"
                                                    ).map((al, i) => {
                                                        return (
                                                            <option value={al.accountNumber} key={i}>
                                                                {al.accountName}
                                                            </option>
                                                        );
                                                    })}
                                                </FormInput>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Supply Place</label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="SupPlace"
                                                    label=""
                                                    labelClassName="required"
                                                    value={supPlace}
                                                    // onChange={handleSupPlace}
                                                    errorText={supPlaceError}
                                                    containerClass="p-0 mb-1"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Branch</label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="branch"
                                                    label=""
                                                    labelClassName="required"
                                                    value={branch}
                                                    // onChange={handlebranch}
                                                    errorText={branchError}
                                                    containerClass="p-0 mb-1"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">Billed By</label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="billedBy"
                                                    label=""
                                                    labelClassName="required"
                                                    value={billedBy}
                                                    // onChange={handlebilledBy}
                                                    errorText={billedByError}
                                                    containerClass="p-0 mb-1"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-5">
                                                <label htmlFor="">System No</label>
                                            </div>
                                            <div className="col-7">
                                                <FormInput
                                                    name="systemNo"
                                                    label=""
                                                    labelClassName="required"
                                                    value={sysNo}
                                                    // onChange={handlesystemNo}
                                                    errorText={sysNoError}
                                                    containerClass="p-0 mb-1"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                        <div className="row pt-0 mt-0">
                            <div className="col-lg-12">
                                <div className="card shadow-lg p-0 mt-0">
                                    <div className="card-body pt-1 px-2">
                                        <div className="table-wrapper">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-truncate align-middle">S.No</th>
                                                        <th className="text-truncate align-middle">
                                                            Product Name
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Product Code
                                                        </th>
                                                        <th className="text-truncate align-middle">HSN</th>
                                                        <th className="text-truncate align-middle">GST%</th>
                                                        <th className="text-truncate align-middle">
                                                            Batch
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Exp.Dt
                                                        </th>
                                                        <th className="text-truncate align-middle">QTY</th>
                                                        <th className="text-truncate align-middle">Free</th>
                                                        <th className="text-truncate align-middle">
                                                            P.Rate
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            SD Rs
                                                        </th>
                                                        <th className="text-truncate align-middle">PD%</th>
                                                        <th className="text-truncate align-middle">
                                                            PD Rs
                                                        </th>
                                                        <th className="text-truncate align-middle">CD%</th>
                                                        <th className="text-truncate align-middle">
                                                            CD Rs
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Total Dis
                                                        </th>
                                                        <th className="text-truncate align-middle">PWOG</th>
                                                        <th className="text-truncate align-middle">PWG</th>
                                                        <th className="text-truncate align-middle">
                                                            Fr.cost
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Landing Cost
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Margin %
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Margin Amt
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Sales Rate
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Wholesale
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Retail
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            U.Rate
                                                        </th>
                                                        <th className="text-truncate align-middle">MRP</th>
                                                        <th className="text-truncate align-middle">
                                                            Taxable
                                                        </th>
                                                        <th className="text-truncate align-middle">IGST</th>
                                                        <th className="text-truncate align-middle">CGST</th>
                                                        <th className="text-truncate align-middle">SGST</th>
                                                        <th className="text-truncate align-middle">
                                                            Net Amt
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Unit Profit
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Total Profit
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Profit %
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {purchaseItems.map((pi, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                {/* <td className="p-0">
                                                                <Select
                                                                    options={ProductList?.map(p => {
                                                                        return {
                                                                            label: (p?.productName + '-' + p?.productCode + '-' + p.sac + '-' + p.gst) || '',
                                                                            value: p.id?.toString() || ''
                                                                        }
                                                                    })}
                                                                    placeholder={false}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    onChange={(val) => handleProductName(val, pi.id, 'qty', pi.id.toString())}
                                                                    value={pi.productName}
                                                                    styles={customStyles}
                                                                    ref={(el) =>
                                                                        (refs.current[`productName-${pi.id}`] = el)
                                                                    }
                                                                    onKeyDown={(e) => handleKeyDown(e, 'qty', pi.id.toString())}
                                                                    // ref={el => qtyRefs.current[i] = el}
                                                                    className={focusedField === 'productName' ? "border border-2 border-primary rounded" : ""}
                                                                />
                                                            </td> */}
                                                                <td className="p-0 d-flex">
                                                                    <button
                                                                        className="btn btn-sm py-0 px-1"
                                                                        onClick={() => {
                                                                            const updatedItems = purchaseItems.filter(
                                                                                (_, index) => index !== i
                                                                            );
                                                                            setPurchaseItems(updatedItems);
                                                                        }}
                                                                    >
                                                                        X
                                                                    </button>

                                                                    <ProductSearch
                                                                        key={pi.id}
                                                                        editProductName={
                                                                            FormTypeList.EDIT ? pi.productName : null
                                                                        }
                                                                        handleProductName={handleProductName}
                                                                        pi={pi}
                                                                        resetSearch={resetSearch}
                                                                        setResetSearch={setResetSearch} // Pass the setResetSearch function
                                                                        handleKeyDown={handleKeyDown}
                                                                        // handleKeyDown={(e, nextField, rowId) =>
                                                                        //   handleKeyDown(e, nextField, rowId, true)
                                                                        // }
                                                                        refs={refs}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="productCode"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.productCode}
                                                                        onChange={(e) =>
                                                                            handleProductCode(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={productNameError}
                                                                        readOnly={true}
                                                                        containerClass="w-100 read-only-field"
                                                                    />
                                                                </td>
                                                                <td className="p-0 ">
                                                                    <FormInput
                                                                        name="hsn"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.hsn}
                                                                        onChange={(e) =>
                                                                            handleHsn(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={productNameError}
                                                                        containerClass="read-only-field"
                                                                        readOnly={true}
                                                                        style={{
                                                                            width: "105px",
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="gst"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.gst}
                                                                        onChange={(e) =>
                                                                            handleGst(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        readOnly={true}
                                                                        containerClass="read-only-field"
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="batchNo"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.batchNo}
                                                                        onChange={(e) =>
                                                                            handleBatchNo(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                    />
                                                                </td>
                                                                <td className="p-0 w-90">
                                                                    <DatePicker
                                                                        id="DatePicker"
                                                                        // type="string"
                                                                        className="fw-bold form-control"
                                                                        selected={pi.expiryDt}
                                                                        onChange={(date: any) =>
                                                                            handleExpiryDate(date, pi.id.toString())
                                                                        }
                                                                        showMonthYearPicker
                                                                        dateFormat="MM/yyyy"
                                                                        yearItemNumber={8}
                                                                    //     required
                                                                    />
                                                                    {/* <FormInput
                                                                name='expiryDate'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.expiryDt}
                                                                onChange={(e) => handleExpiryDate(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                // width={500}
                                                                // type="date"
                                                                containerClass=""
                                                            /> */}
                                                                </td>

                                                                <td key={pi.id} className="p-0">
                                                                    <FormInput
                                                                        name={`qty-${pi.id}`}
                                                                        label=""
                                                                        value={pi.qty}
                                                                        onChange={(e) =>
                                                                            handleQty(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`qty-${pi.id}`] = el)
                                                                        }
                                                                        // ref={el => qtyRefs.current[i] = el}
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(e, "free", pi.id.toString())
                                                                        }
                                                                        className={
                                                                            focusedField === `qty-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="free"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.free}
                                                                        onChange={(e) =>
                                                                            handleFree(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`free-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(
                                                                                e,
                                                                                "pRate",
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        className={
                                                                            focusedField === `free-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="Purchase rate"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.prate}
                                                                        onChange={(e) =>
                                                                            handlePurcahseRate(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`pRate-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(e, "sdRs", pi.id.toString())
                                                                        }
                                                                        className={
                                                                            focusedField === `pRate-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="SDRs"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.sdRs}
                                                                        onChange={(e) =>
                                                                            handleSDRs(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`sdRs-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(e, "pd", pi.id.toString())
                                                                        }
                                                                        className={
                                                                            focusedField === `sdRs-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="pdPercent"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.pdPercent}
                                                                        onChange={(e) =>
                                                                            handlePDPercent(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`pd-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(e, "cd", pi.id.toString())
                                                                        }
                                                                        className={
                                                                            focusedField === `pd-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="pdRs"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.pdRs}
                                                                        onChange={(e) =>
                                                                            handlePDRs(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass="read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="cdPercent"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.cdPercent}
                                                                        onChange={(e) =>
                                                                            handleCDPercent(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`cd-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(
                                                                                e,
                                                                                "frCost",
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        className={
                                                                            focusedField === `cd-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="cdRs"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.cdRs}
                                                                        onChange={(e) =>
                                                                            handleCDRs(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=" read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="Total discount"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.totDisAmt}
                                                                        onChange={(e) => { }}
                                                                        errorText={gstError}
                                                                        containerClass=" read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="pwog"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.pwog}
                                                                        onChange={(e) =>
                                                                            handlePWOG(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=" read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="pwg"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.pwg}
                                                                        onChange={(e) =>
                                                                            handlePWG(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass="read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="freight cost"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.fcost}
                                                                        onChange={(e) =>
                                                                            handleFreightCost(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`frCost-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(
                                                                                e,
                                                                                "margin",
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        className={
                                                                            focusedField === `frCost-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="landing cost"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.lcost}
                                                                        onChange={(e) =>
                                                                            handleLandingCost(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass="read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="margin percent"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.marginPercent}
                                                                        onChange={(e) =>
                                                                            handleMarginPercent(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`margin-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(
                                                                                e,
                                                                                "marginAmt",
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        className={
                                                                            focusedField === `margin-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="margin amount"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.marginAmt}
                                                                        onChange={(e) =>
                                                                            handleMarginAmt(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`marginAmt-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(
                                                                                e,
                                                                                "salesRate",
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        className={
                                                                            focusedField === `marginAmt-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="sales rate"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.salesRate}
                                                                        onChange={(e) =>
                                                                            handleSalesRate(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`salesRate-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(
                                                                                e,
                                                                                "wholesale",
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        className={
                                                                            focusedField === `salesRate-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>

                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="sRate1"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.sRate1}
                                                                        onChange={(e) =>
                                                                            handleSRate1(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`wholesale-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(
                                                                                e,
                                                                                "retail",
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        className={
                                                                            focusedField === `wholesale-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="sRate2"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.sRate2}
                                                                        onChange={(e) =>
                                                                            handleSRate2(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`retail-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(
                                                                                e,
                                                                                "uRate",
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        className={
                                                                            focusedField === `retail-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="sRate3"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.sRate3}
                                                                        onChange={(e) =>
                                                                            handleSRate3(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`uRate-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(e, "mrp", pi.id.toString())
                                                                        }
                                                                        className={
                                                                            focusedField === `uRate-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="MRP"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.mrp}
                                                                        onChange={(e) =>
                                                                            handleMRP(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`mrp-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(
                                                                                e,
                                                                                "productName",
                                                                                pi.id.toString(),
                                                                                true
                                                                            )
                                                                        }
                                                                        className={
                                                                            focusedField === `mrp-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>

                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="taxable"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={parseFloat(pi.taxable).toFixed(2)}
                                                                        onChange={(e) =>
                                                                            handleTaxable(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=" read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="igst"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.igst}
                                                                        onChange={(e) =>
                                                                            handleIGST(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass="w-100 read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="cgst"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.cgst}
                                                                        onChange={(e) =>
                                                                            handleCGST(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass="w-100 read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="sgst"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.sgst}
                                                                        onChange={(e) =>
                                                                            handleSGST(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass="w-100 read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="netAmt"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={parseFloat(pi.netAmt).toFixed(2)}
                                                                        onChange={(e) =>
                                                                            handleNetAmt(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass="w-100 read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="unitProfit"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={parseFloat(pi.unitProfit).toFixed(2)}
                                                                        onChange={(e) =>
                                                                            handleUnitProfit(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass="read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="totalProfit"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={parseFloat(pi.totalProfit).toFixed(
                                                                            2
                                                                        )}
                                                                        onChange={(e) =>
                                                                            handleTotalProfit(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass="read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="profitPercent"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={parseFloat(pi.profitPercent)}
                                                                        // onChange={(e) => handleProfitPercent(e.target.value, pi.id.toString())}
                                                                        errorText={gstError}
                                                                        containerClass="read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <button
                                                                        className="btn btn-sm fw-bold btn-primary py-0"
                                                                        onClick={() =>
                                                                            handleAttributeClick(pi.id.toString())
                                                                        }
                                                                    >
                                                                        +
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between hstack gap-1 mt-2">
                                            <h4>Your Tentative Profit: {rupeeFormat(totalProfit)}</h4>
                                            <div>
                                                <h4>
                                                    <strong style={{ color: "#333" }}>
                                                        Total Taxable:
                                                    </strong>
                                                    <span style={{ color: "#007bff" }}>
                                                        {" "}
                                                        {netTaxable.toFixed(4)}
                                                    </span>{" "}
                                                    |<strong style={{ color: "#333" }}> IGST:</strong>
                                                    <span style={{ color: "#28a745" }}>
                                                        {" "}
                                                        {netIgst.toFixed(4)}
                                                    </span>{" "}
                                                    |<strong style={{ color: "#333" }}> CGST:</strong>
                                                    <span style={{ color: "#dc3545" }}>
                                                        {" "}
                                                        {netCgst.toFixed(4)}
                                                    </span>{" "}
                                                    |<strong style={{ color: "#333" }}> SGST:</strong>
                                                    <span style={{ color: "#ffc107" }}>
                                                        {" "}
                                                        {netSgst.toFixed(4)}
                                                    </span>
                                                </h4>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end hstack gap-1 mt-2">
                                            {/* <button className="btn btn-secondary" onClick={() => resetStates()}>Clear</button> */}
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="balanceCheckbox"
                                                    checked={isBalanceChecked}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="balanceCheckbox"
                                                >
                                                    {isBalanceChecked
                                                        ? "Remaining record exists"
                                                        : "No remaining record"}
                                                </label>
                                            </div>
                                            <button className="btn btn-primary" onClick={handleView}>
                                                View
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    if (
                                                        parseFloat(invoiceAmount)?.toFixed(2) ===
                                                        parseFloat(finalAmount)?.toFixed(2)
                                                    ) {
                                                        handleShowConfirmationModel();
                                                    } else {
                                                        handleSave();
                                                    }
                                                }}
                                            >
                                                {formType === FormTypeList.EDIT ? "Update" : "Save"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mb-1">
                            <span className="fs-4 badge bg-danger ms-4">
                                Invoice Amt: {invoiceAmount}
                            </span>
                        </div>
                        <div className="card shadow-lg ">
                            <div className="card-body">
                                <div className="row">
                                    <h4 className="text-center">Grand Total</h4>
                                    <h2
                                        className="text-center text-white "
                                        style={{ backgroundColor: "#032566" }}
                                    >
                                        {rupeeFormat(finalAmount)}
                                    </h2>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <label>Bill Amount</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="BillAmt"
                                                        label=""
                                                        labelClassName="required"
                                                        value={billAmount}
                                                        onChange={handleBillAmt}
                                                        errorText={billAmountError}
                                                        containerClass="w-100 read-only-field"
                                                        type="number"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Discount %</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="DisPercent"
                                                        label=""
                                                        labelClassName="required"
                                                        value={disPercent}
                                                        onChange={handleDisPercent}
                                                        errorText={disPercentError}
                                                        containerClass="w-100"
                                                        type="number"
                                                        ref={disPercentRef}
                                                        onKeyDown={(e) =>
                                                            handleKeyDownForRight(e, disAmtRef, "disAmt")
                                                        }
                                                        className={
                                                            focusedField === `disPercent`
                                                                ? "border border-2 border-primary rounded"
                                                                : ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Discount Amt</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="DisAmt"
                                                        label=""
                                                        labelClassName="required"
                                                        value={parseFloat(disAmt).toFixed()}
                                                        onChange={handleDisAmt}
                                                        errorText={disAmtError}
                                                        containerClass="w-100"
                                                        type="number"
                                                        ref={disAmtRef}
                                                        onKeyDown={(e) =>
                                                            handleKeyDownForRight(
                                                                e,
                                                                totFreightCostRef,
                                                                "totFreightCost"
                                                            )
                                                        }
                                                        className={
                                                            focusedField === `disAmt`
                                                                ? "border border-2 border-primary rounded"
                                                                : ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Total Amt</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="TotalAMt"
                                                        label=""
                                                        labelClassName="required"
                                                        value={totAmt}
                                                        onChange={handleTotalAmt}
                                                        errorText={totAmtError}
                                                        containerClass="w-100 read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Round Off</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="RoundOff"
                                                        label=""
                                                        labelClassName="required"
                                                        value={roundOff}
                                                        onChange={handleRoundOff}
                                                        errorText={roundOffError}
                                                        containerClass="read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Net Amt</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="netAmt"
                                                        label=""
                                                        labelClassName="required"
                                                        value={billNetAmt}
                                                        onChange={handleBillNetAmt}
                                                        errorText={billNetAmtError}
                                                        containerClass="read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Freight Cost</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="Total Freight Cost"
                                                        label=""
                                                        labelClassName="required"
                                                        value={totFreightCost}
                                                        onChange={handleTotFrieghtCost}
                                                        errorText={totFreightCostError}
                                                        containerClass=""
                                                        type="number"
                                                        ref={totFreightCostRef}
                                                        onKeyDown={(e) =>
                                                            handleKeyDownForRight(e, addRef, "add")
                                                        }
                                                        className={
                                                            focusedField === `totFreightCost`
                                                                ? "border border-2 border-primary rounded"
                                                                : ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Add</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="Add"
                                                        label=""
                                                        labelClassName="required"
                                                        value={add}
                                                        onChange={handleAdd}
                                                        errorText={addError}
                                                        containerClass="w-100"
                                                        type="number"
                                                        ref={addRef}
                                                        onKeyDown={(e) =>
                                                            handleKeyDownForRight(e, lessRef, "less")
                                                        }
                                                        className={
                                                            focusedField === `add`
                                                                ? "border border-2 border-primary rounded"
                                                                : ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Less</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="Less"
                                                        label=""
                                                        labelClassName="required"
                                                        value={less}
                                                        onChange={handleLess}
                                                        errorText={lessError}
                                                        containerClass="w-100"
                                                        type="number"
                                                        ref={lessRef}
                                                        onKeyDown={(e) =>
                                                            handleKeyDownForRight(
                                                                e,
                                                                narrationRef,
                                                                "narration"
                                                            )
                                                        }
                                                        className={
                                                            focusedField === `less`
                                                                ? "border border-2 border-primary rounded"
                                                                : ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Grand Total</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="grandTotal"
                                                        label=""
                                                        labelClassName="required"
                                                        value={gtotal}
                                                        onChange={handleGrandTotal}
                                                        errorText={gtotalError}
                                                        containerClass="w-100 read-only-field"
                                                        readOnly={true}
                                                        type="number"
                                                    />
                                                </td>
                                            </tr>
                                            {From === "Purchase" && (
                                                <tr>
                                                    <td></td>
                                                    <td style={{}}>
                                                        <Button
                                                            onClick={handleShow}
                                                            className="mt-1 mb-1 p-1"
                                                            style={{
                                                                fontWeight: "bold",
                                                                width: "100%",
                                                                color: "white",
                                                                borderRadius: "5px",
                                                            }}
                                                        >
                                                            Tender
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>
                                                    <label>Amt In Words</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="AmtInWords"
                                                        label=""
                                                        labelClassName="required"
                                                        value={amtInWords}
                                                        onChange={handleAmtInWords}
                                                        errorText={amtInWordsError}
                                                        containerClass="w-100"
                                                    // type="number"
                                                    />
                                                </td>
                                            </tr>
                                            {/* <tr>
                                            <td><label>Payment Mode</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='paymentMode'
                                                    label=''
                                                    labelClassName="required"
                                                    value={paymentMode}
                                                    onChange={handlePaymentMode}
                                                    errorText={paymentModeError}
                                                    containerClass="w-100"
                                                    type="select"
                                                >
                                                    <option value="">Select</option>
                                                    {PURCHASE_PAYMENT_MODES.map((a, i) => {
                                                        return <Option value={a.value.toString()} key={i}>{a.text}</Option>
                                                    })}
                                                </FormInput>
                                            </td>
                                        </tr> */}
                                            {paymentMode === PurchasePaymentModesDropDown.BANK && (
                                                <tr>
                                                    <td>
                                                        <label>Bank</label>
                                                    </td>
                                                    <td className="p-0">
                                                        <FormInput
                                                            name="bankNumber"
                                                            label=""
                                                            labelClassName="required"
                                                            value={bankNumber}
                                                            onChange={handleBank}
                                                            containerClass="w-100"
                                                            type="select"
                                                        >
                                                            <option value="">Select</option>
                                                            {AccountLists.filter(
                                                                (al) =>
                                                                    al.groupName === "main" &&
                                                                    al.accountName?.toLowerCase().includes("bank")
                                                            ).map((al) => {
                                                                return (
                                                                    <option value={al.accountNumber}>
                                                                        {al.accountName}
                                                                    </option>
                                                                );
                                                            })}
                                                        </FormInput>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>
                                                    <label>Narration</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="paymentNo"
                                                        label=""
                                                        labelClassName="required"
                                                        value={paymentNo}
                                                        onChange={handlePaymentNo}
                                                        errorText={paymentNoError}
                                                        containerClass="w-100"
                                                        ref={narrationRef}
                                                        onKeyDown={(e) =>
                                                            handleKeyDownForRight(e, dModeRef, "dMode")
                                                        }
                                                        className={
                                                            focusedField === `narration`
                                                                ? "border border-2 border-primary rounded"
                                                                : ""
                                                        }
                                                    // type="number"
                                                    />
                                                </td>
                                            </tr>
                                            {(paymentMode === PurchasePaymentModesDropDown.BANK ||
                                                paymentMode === PurchasePaymentModesDropDown.CASH) && (
                                                    <tr>
                                                        <td>
                                                            <label>Amt Paid</label>
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name="AmtPaid"
                                                                label=""
                                                                labelClassName="required"
                                                                value={amtPaid}
                                                                // onChange={handleAmtPaid}
                                                                errorText={amtPaidError}
                                                                containerClass="w-100 read-only-field"
                                                                type="number"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                )}
                                            <tr>
                                                <td>
                                                    <label>Current Bal</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="currentBal"
                                                        label=""
                                                        labelClassName="required"
                                                        value={currentBal}
                                                        onChange={handleCurrentBal}
                                                        errorText={currentBalError}
                                                        containerClass="w-100 read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Previous Bal</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="previousBal"
                                                        label=""
                                                        labelClassName="required"
                                                        value={previousBal}
                                                        onChange={handlePreviousBal}
                                                        errorText={previousBalError}
                                                        containerClass="w-100 read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>OverAll Bal</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="overAllBal"
                                                        label=""
                                                        labelClassName="required"
                                                        value={overAllBal}
                                                        onChange={handleOverAllBal}
                                                        errorText={overAllBalError}
                                                        containerClass="w-100 read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            {/* <tr>
                                            <td><label>Tendered Amt</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='tenderedAmt'
                                                    label=''
                                                    labelClassName="required"
                                                    value={tenderedAmt}
                                                    onChange={handleTenderedAmt}
                                                    errorText={tenderedAmtError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Tendered Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='tenderedBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={tenderedBal}
                                                    onChange={handleTenderedBal}
                                                    errorText={tenderedBalError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr> */}
                                            <tr>
                                                <td>
                                                    <label>Delivery Mode</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="deliveryMode"
                                                        label=""
                                                        labelClassName="required"
                                                        value={deliveryMode}
                                                        onChange={handleDeliveryMode}
                                                        errorText={deliveryModeError}
                                                        containerClass="w-100"
                                                        ref={dModeRef}
                                                        onKeyDown={(e) =>
                                                            handleKeyDownForRight(e, dDetailsRef, "dDetails")
                                                        }
                                                        className={
                                                            focusedField === `dMode`
                                                                ? "border border-2 border-primary rounded"
                                                                : ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Delivery Details</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="deliverydetails"
                                                        label=""
                                                        labelClassName="required"
                                                        value={deliveryDetails}
                                                        onChange={handleDeliveryDetails}
                                                        errorText={deliveryDetailsError}
                                                        containerClass="w-100"
                                                        ref={dDetailsRef}
                                                        onKeyDown={(e) =>
                                                            handleKeyDownForRight(e, dDetailsRef, "dDetails")
                                                        }
                                                        className={
                                                            focusedField === `dDetails`
                                                                ? "border border-2 border-primary rounded"
                                                                : ""
                                                        }
                                                    // type="number"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </UserLayout>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header
                    closeButton
                    style={{ backgroundColor: "lightblue" }}
                >
                    <BootstrapModal.Title
                        className="text-dark"
                        style={{ fontWeight: "bold", fontSize: "24px" }}
                    >
                        Tender
                    </BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body style={{ backgroundColor: "lightblue" }}>
                    <Form className="row">
                        <Form.Check
                            type="radio"
                            label="Cash and Bank"
                            value="cashAndBank"
                            checked={paymentMethod === "cashAndBank"}
                            onChange={handlePaymentMethodChange}
                            style={{ fontWeight: "bold", fontSize: "18px" }}
                            className="col-4 m-2"
                        />
                        <Form.Check
                            type="radio"
                            label="Credit"
                            value="credit"
                            checked={paymentMethod === "credit"}
                            onChange={handlePaymentMethodChange}
                            style={{ fontWeight: "bold", fontSize: "18px" }}
                            className="col-4 m-2"
                        />
                    </Form>
                    {paymentMethod === "cashAndBank" && (
                        <>
                            <table className="striped bordered hover">
                                <tbody>
                                    <tr>
                                        <td
                                            style={{
                                                fontWeight: "bold",
                                                color: "red",
                                                fontSize: "18px",
                                            }}
                                            className="p-2"
                                        >
                                            Grand Total
                                        </td>
                                        <td className="p-2">
                                            {" "}
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter Grand Total"
                                                value={gtotal}
                                                readOnly={true}
                                                style={{
                                                    height: "4vh",
                                                    width: "100%",
                                                    fontSize: "18px",
                                                    color: "black",
                                                    backgroundColor: "lightgrey",
                                                    borderColor: "black",
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                fontWeight: "bold",
                                                color: "red",
                                                fontSize: "18px",
                                            }}
                                            className="p-2"
                                        >
                                            Previous Balance
                                        </td>
                                        <td className="p-2">
                                            {" "}
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter Previous Balance"
                                                value={previousBal}
                                                readOnly={true}
                                                style={{
                                                    height: "4vh",
                                                    fontSize: "18px",
                                                    color: "black",
                                                    backgroundColor: "lightgrey",
                                                    borderColor: "black",
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                fontWeight: "bold",
                                                color: "red",
                                                fontSize: "18px",
                                            }}
                                            className="p-2"
                                        >
                                            Total Balance
                                        </td>
                                        <td className="p-2">
                                            {" "}
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter Total Balance"
                                                value={totalBalance}
                                                readOnly={true}
                                                style={{
                                                    height: "4vh",
                                                    fontSize: "18px",
                                                    color: "black",
                                                    backgroundColor: "lightgrey",
                                                    borderColor: "black",
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            className="p-2"
                                            style={{ fontWeight: "bold", fontSize: "18px" }}
                                        >
                                            Cash
                                        </td>
                                        <td className="p-2">
                                            <FormInput
                                                name="cash"
                                                type="number"
                                                placeholder="Enter amount"
                                                value={cash}
                                                onChange={(e) => setCash(e.target.value)}
                                                ref={cashRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDownForRight(e, card1Ref, "card1")
                                                }
                                                className={
                                                    focusedField === "cash"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                style={{
                                                    height: "4vh",
                                                    fontSize: "18px",
                                                    color: "black",
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            className="p-2"
                                            style={{ fontWeight: "bold", fontSize: "18px" }}
                                        >
                                            Card 1
                                        </td>
                                        <td className="p-2">
                                            <FormInput
                                                name="card1"
                                                type="number"
                                                placeholder="Enter amount"
                                                value={card1}
                                                onChange={(e) => setCard1(e.target.value)}
                                                ref={card1Ref}
                                                onKeyDown={(e) =>
                                                    handleKeyDownForRight(e, card2Ref, "card2")
                                                }
                                                className={
                                                    focusedField === "card1"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                style={{
                                                    height: "4vh",
                                                    fontSize: "18px",
                                                    color: "black",
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            className="p-2"
                                            style={{ fontWeight: "bold", fontSize: "18px" }}
                                        >
                                            Card 2
                                        </td>
                                        <td className="p-2">
                                            <FormInput
                                                name="card2"
                                                type="number"
                                                placeholder="Enter amount"
                                                value={card2}
                                                onChange={(e) => setCard2(e.target.value)}
                                                ref={card2Ref}
                                                onKeyDown={(e) =>
                                                    handleKeyDownForRight(e, online1Ref, "onlinePay1")
                                                }
                                                className={
                                                    focusedField === "card2"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                style={{
                                                    height: "4vh",
                                                    fontSize: "18px",
                                                    color: "black",
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            className="p-2"
                                            style={{ fontWeight: "bold", fontSize: "18px" }}
                                        >
                                            Online Payment 1
                                        </td>
                                        <td className="p-2">
                                            <FormInput
                                                name="onlinePay1"
                                                type="number"
                                                placeholder="Enter amount"
                                                value={onlinePayment1}
                                                onChange={(e) => setOnlinePayment1(e.target.value)}
                                                ref={online1Ref}
                                                onKeyDown={(e) =>
                                                    handleKeyDownForRight(e, online2Ref, "onlinePay2")
                                                }
                                                className={
                                                    focusedField === "onlinePay1"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                style={{
                                                    height: "4vh",
                                                    fontSize: "18px",
                                                    color: "black",
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            className="p-2"
                                            style={{ fontWeight: "bold", fontSize: "18px" }}
                                        >
                                            Online Payment 2
                                        </td>
                                        <td className="p-2">
                                            <FormInput
                                                name="onlinePay2"
                                                type="number"
                                                placeholder="Enter amount"
                                                value={onlinePayment2}
                                                onChange={(e) => setOnlinePayment2(e.target.value)}
                                                ref={online2Ref}
                                                onKeyDown={(e) =>
                                                    handleKeyDownForRight(e, cashRef, "online2")
                                                }
                                                className={
                                                    focusedField === "onlinePay2"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                style={{
                                                    height: "4vh",
                                                    fontSize: "18px",
                                                    color: "black",
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            className="p-2"
                                            style={{ fontWeight: "bold", fontSize: "18px" }}
                                        >
                                            Total Paid Amt
                                        </td>
                                        <td>
                                            <span
                                                style={{
                                                    fontWeight: "bold",
                                                    color: "blue",
                                                    fontSize: "18px",
                                                }}
                                                className="p-2"
                                            >
                                                {parseFloat(cash) +
                                                    parseFloat(card1) +
                                                    parseFloat(card2) +
                                                    parseFloat(onlinePayment1) +
                                                    parseFloat(onlinePayment2)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                fontWeight: "bold",
                                                color: "red",
                                                fontSize: "18px",
                                            }}
                                            className="p-2"
                                        >
                                            Overall Balance
                                        </td>
                                        <td className="p-2">
                                            {" "}
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter Overall Balance"
                                                value={overallBalance}
                                                readOnly={true}
                                                style={{
                                                    height: "4vh",
                                                    fontSize: "18px",
                                                    color: "black",
                                                    backgroundColor: "lightgrey",
                                                    borderColor: "black",
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <label
                                className="w-100 ms-5"
                                style={{ fontSize: "20px", color: "red" }}
                            >
                                {errorMessage}
                            </label>
                        </>
                    )}
                </BootstrapModal.Body>

                <BootstrapModal.Footer style={{ backgroundColor: "lightblue" }}>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </BootstrapModal.Footer>
            </BootstrapModal>

            {/* <BootstrapModal>
            <BootstrapModal.Header closeButton style={{ backgroundColor: 'lightblue' }}>
                <BootstrapModal.Title className="text-dark" style={{ fontWeight: "bold", fontSize: "24px" }} >Tender</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body style={{ backgroundColor: 'lightblue' }}>
                <table className="striped bordered hover">
                    <tbody>
                        <tr>
                            <td style={{ fontWeight: "bold", color: "red", fontSize: "18px" }} className="pt-2 p-3">Grand Total</td>
                            <td className="pt-2 p-3"> <Form.Control
                                type="number"
                                placeholder="Enter Grand Total"
                                value={gtotal}
                                readOnly={true}
                                style={{ height: "4vh", width: "100%", fontSize: "18px", color: "black", backgroundColor: "lightgrey", borderColor: "black" }}
                            /></td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold", color: "red", fontSize: "18px" }} className="pt-2 p-3">Previous Balance</td>
                            <td className="pt-2 p-3"> <Form.Control
                                type="number"
                                placeholder="Enter Previous Balance"
                                value={previousBal}
                                readOnly={true}
                                style={{ height: "4vh", fontSize: "18px", color: "black", backgroundColor: "lightgrey", borderColor: "black" }}
                            /></td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold", color: "red", fontSize: "18px" }} className="pt-2 p-3">Total Balance</td>
                            <td className="pt-2 p-3"> <Form.Control
                                type="number"
                                placeholder="Enter Total Balance"
                                value={totalBalance}
                                readOnly={true}
                                style={{ height: "4vh", fontSize: "18px", color: "black", backgroundColor: "lightgrey", borderColor: "black" }}
                            /></td>
                        </tr>
                        <tr>
                            <td className="pt-2 p-3" style={{ fontWeight: "bold", fontSize: "18px" }}>Cash</td>
                            <td className="pt-2 p-3">
                                <Form.Control
                                    type="number"
                                    placeholder="Enter amount"
                                    value={cash}
                                    onChange={(e) => setCash(e.target.value)}
                                    style={{ height: "4vh", fontSize: "18px", color: "black" }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="pt-2 p-3" style={{ fontWeight: "bold", fontSize: "18px" }}>Card 1</td>
                            <td className="pt-2 p-3">
                                <Form.Control
                                    type="number"
                                    placeholder="Enter amount"
                                    value={card1}
                                    onChange={(e) => setCard1(e.target.value)}
                                    style={{ height: "4vh", fontSize: "18px", color: "black" }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="pt-2 p-3" style={{ fontWeight: "bold", fontSize: "18px" }}>Card 2</td>
                            <td className="pt-2 p-3">
                                <Form.Control
                                    type="number"
                                    placeholder="Enter amount"
                                    value={card2}
                                    onChange={(e) => setCard2(e.target.value)}
                                    style={{ height: "4vh", fontSize: "18px", color: "black" }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="pt-2 p-3" style={{ fontWeight: "bold", fontSize: "18px" }}>Online Payment 1</td>
                            <td className="pt-2 p-3">
                                <Form.Control
                                    type="number"
                                    placeholder="Enter amount"
                                    value={onlinePayment1}
                                    onChange={(e) => setOnlinePayment1(e.target.value)}
                                    style={{ height: "4vh", fontSize: "18px", color: "black" }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="pt-2 p-3" style={{ fontWeight: "bold", fontSize: "18px" }}>Online Payment 2</td>
                            <td className="pt-2 p-3">
                                <Form.Control
                                    type="number"
                                    placeholder="Enter amount"
                                    value={onlinePayment2}
                                    onChange={(e) => setOnlinePayment2(e.target.value)}
                                    style={{ height: "4vh", fontSize: "18px", color: "black" }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold", color: "red", fontSize: "18px" }} className="pt-2 p-3">Overall Balance</td>
                            <td className="pt-2 p-3"> <Form.Control
                                type="number"
                                placeholder="Enter Overall Balance"
                                value={overallBalance}
                                readOnly={true}
                                style={{ height: "4vh", fontSize: "18px", color: "black", backgroundColor: "lightgrey", borderColor: "black" }}
                            /></td>
                        </tr>
                    </tbody>
                </table>
            </BootstrapModal.Body>

            <BootstrapModal.Footer style={{ backgroundColor: 'lightblue' }}>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button>
            </BootstrapModal.Footer>
        </BootstrapModal > */}
            <Modal
                headerText={"Success Message"}
                onClose={handleFormClose}
                centered
                visible={isModalOpen}
                bgColor="#B1D7E9"
            >
                <SuccessModal
                    message={modalMessage}
                    invoiceNumber={modalInvoiceNumber}
                    purchaseEntryNumber={modalPurchaseEntryNo}
                    onClose={handleFormClose}
                />
            </Modal>
            <Modal
                headerText="Confirm Final Submission"
                onClose={() => setShowFinalSubmitModal(false)}
                centered
                visible={showFinalSubmitModal}
                bgColor="#FFFFFF"
            >
                <div>
                    <p className="text-dark">
                        Are you sure you want to submit? You will not be able to edit this
                        later.
                    </p>
                    <button
                        className="btn btn-primary m-2"
                        onClick={() => {
                            setIsBalanceChecked(false);
                            setShowFinalSubmitModal(false);
                            handleSave();
                        }}
                    >
                        Okay
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowFinalSubmitModal(false)}
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default PurchaseMaster;
