import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface SuccessModalProps {
  onClose: () => void;
  message: string;
  invoiceNumber: number | string;
  purchaseEntryNumber: number | string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ message, onClose, invoiceNumber, purchaseEntryNumber }) => {
  return (
    <>
      <div className="bg-white p-2 rounded">
        <p className='text-center text-success' style={{ fontWeight: "bold", fontSize: "20px" }}>
          {message}
        </p>
        <div className="mt-6 d-flex justify-content-center">
          <div className='m-0 p-0' style={{width: "90%"}}>
          <div className="d-flex items-center border-b pb-2">
            <span className="text-xl" style={{ fontWeight: "500", fontSize: "18px", width: "50%" }}>
              Invoice Number
            </span>
            <span className="text-xl" style={{ fontSize: "18px", textAlign: "left", width: "50%", color: "red" }}>
              : {invoiceNumber}
            </span>
          </div>
          <div className="d-flex items-center border-b pb-2">
            <span className="text-xl" style={{ fontWeight: "500", fontSize: "18px", width: "50%" }}>
              Purchase Entry Number
            </span>
            <span className="text-xl" style={{ fontSize: "18px", textAlign: "left", width: "50%", color: "red" }}>
              : {purchaseEntryNumber}
            </span>
          </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <Button onClick={onClose}>
            Ok
          </Button>
        </div>
      </div>
    </>
  );
};

export default SuccessModal;
