import { useEffect, useState } from "react";
import { Card, Table, Button } from "react-bootstrap";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import ReportFilter from "./ReportFilter";
import api from "../../config/api";
import { getDate, rupeeFormat, LocalFormatDate } from "../../config/functions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../redux/store";
import {
  ClientRegistrationType,
  fetchClientRegistrationAction,
} from "../../redux/actions/clientRegistration";
import { apiUrl } from "../../config";
import bgMaterial from "../../components/custom/assets/images/bg-material.png";
import SalesReportFilter from "./SalesReportFilter";
import SalesReturnReportFilter from "./SalesReturnReportFilter";

interface SaleReturnBillers {
  financialYear: string;
  // repName: string;
  invoiceDate: string;
  invoiceDay: string;
  invoiceNo: string;
  customerName: string;
  grandTotal: number;
  // taxable: number;
  // igst: number;
  // sgst: number;
  // cgst: number;
  // total: number;
  // addAmount: number;
  // lessAmount: number;
  // netTotal: number;
}

interface SalesReturnBillersWiseReport {
  financialYear: string;
  billerName: string;
  saleData: SaleReturnBillers[];
}

const SalesReturnBillersWise = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [billerName, setBillerName] = useState<string>("");
  const clients = useSelector<StoreState, ClientRegistrationType[]>(
    (state) => state.clientRegistration
  );

  const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

  const [tableData, setTableData] = useState<SalesReturnBillersWiseReport[]>([]);

  useEffect(() => {
    dispatch(fetchClientRegistrationAction());
  }, []);

  useEffect(() => {
    if (triggerSearch) {
      const response = api().get(
        `reports/salesReturnBillersWise/?fromDate=${getDate(fromDate)}&toDate=${getDate(toDate)}&billerName=${billerName}`
      );

      response
        .then((res) => {
          if (res.status === 200 && res.data.data) {
            console.log("API Response:", res.data);
            setTableData(res.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching sales return data:", error);
        });

      setTriggerSearch(false);
    }
  }, [triggerSearch, fromDate, toDate, billerName]);

  const [filters, setFilters] = useState<any>({});

  const handleSearch = (newFilters: any) => {
    setFilters(newFilters);
    setFromDate(newFilters.fromDate);
    setToDate(newFilters.toDate);
    setBillerName(newFilters.billerName);

    setTriggerSearch(true);
  };

  const downloadCSV = () => {
    const header = [
      "SI.No",
      "Date",
      "Day",
      "Invoice No",
      "Customer Name",
      "Grand Total",
    ];

    let csvContent = header.join(",") + "\n";

    tableData.forEach((item, index) => {
      csvContent += `\nFinancial Year: ${item.financialYear}\n`;
      csvContent += `\nBiller Name: ${item.billerName}\n`;
      // csvContent += header.join(",") + "\n";

      item.saleData.forEach((sales, subIndex) => {
        csvContent +=
          [
            subIndex + 1,
            sales.invoiceDate,
            sales.invoiceDay,
            sales.invoiceNo,
            sales.customerName,
            sales.grandTotal,
            // sales.gst,
            // sales.netAmount
          ].join(",") + "\n";
      });
      const totalGrandTotal = item.saleData.reduce(
        (sum, sales) => +sum + +sales.grandTotal,
        0
    );
    csvContent += `,,,,Total Grand Total,${totalGrandTotal}\n\n`;
    });

    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "sales_return_billers_wise_report.csv";
    link.click();
  };
  const downloadPDF = () => {
    const logoUrl = clients[0]?.shopPhoto;
    const doc = new jsPDF("landscape");

    const marginX = 10;
    const boxWidth = doc.internal.pageSize.width - 2 * marginX;
    const boxHeight = 25;
    let startY = 15;

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");

    doc.rect(marginX, startY - 5, boxWidth, boxHeight);

    if (logoUrl) {
      doc.addImage(logoUrl, "JPEG", marginX + 5, startY - 2, 25, 20);
    }

    const paddingBetweenHeaderAndTable = 5;
    const textX = marginX + 35;

    doc.text(clients[0]?.shopShortName || "", textX, startY);
    doc.setFont("helvetica", "normal");
    doc.text(
      `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${
        clients[0]?.district || ""
      }`,
      textX,
      startY + 5
    );
    doc.text(
      `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${
        clients[0]?.contactNo1 || ""
      }`,
      textX,
      startY + 10
    );
    doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 15);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    const heading = "sales return billers wise report";
    const headingWidth = doc.getTextWidth(heading);
    const pageWidth = doc.internal.pageSize.width;
    doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 8);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const financialYearText = `Financial Year: ${
      tableData[0]?.financialYear || ""
    }`;
    const headingStartX = marginX + boxWidth - headingWidth - 10;
    doc.text(financialYearText, headingStartX, startY + 13);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    const formattedFromDate =
      "From : " + (LocalFormatDate(getDate(fromDate)) || "");
    const formattedToDate = "To : " + (LocalFormatDate(getDate(toDate)) || "");
    const tableStartY = 12 + boxHeight + paddingBetweenHeaderAndTable;
    doc.text(formattedFromDate, marginX, tableStartY);
    doc.text(formattedToDate, marginX + 50, tableStartY);

    let bodyData: any = [];
    let serialNumber = 1;

    tableData.forEach((item) => {
      bodyData.push([
        {
          content: `Biller Name: ${item.billerName}`,
          colSpan: 14,
          styles: { fontStyle: "bold", halign: "center" },
        },
      ]);

      bodyData.push(
        ...item.saleData?.map((sales) => [
          serialNumber++,
          sales.invoiceDate,
          sales.invoiceDay,
          sales.invoiceNo,
          sales.customerName,
          rupeeFormat(sales.grandTotal),
        ])
      );
      const totalGrandTotal = item.saleData.reduce(
        (sum, sales) => +sum + +sales.grandTotal,
        0
      );
      bodyData.push([
        {
          content: "Total Grand Total",
          colSpan: 5,
          styles: { fontStyle: "bold", halign: "right" }
        },
        rupeeFormat(totalGrandTotal)
      ]);

    });

    autoTable(doc, {
      startY: startY + 32,
      head: [
        ["SI.No", "Date", "Day", "Invoice No", "Customer Name", "Grand Total"],
      ],
      body: bodyData,
      theme: "grid",
      styles: { fontSize: 8, textColor: 0, lineColor: 0, lineWidth: 0.1 },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: 0,
        fontStyle: "bold",
        lineColor: 0,
        lineWidth: 0.1,
      },
      columnStyles: {
        0: { halign: "center" }, // SI.No (Center aligned)
        1: { halign: "center" }, // Date (Center aligned)
        2: { halign: "center" }, // Invoice Number (Center aligned)
        3: { halign: "center" }, // Supplier Name (Left aligned)
        4: { halign: "center" }, // Net Total (Right aligned)
        5: { halign: "right" }, // Net Total (Right aligned)
      },
      margin: { top: 25, left: 10, right: 10 },
    });

    // Footer Section (Page Number)
    const pageCount = doc.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      let pageHeight = doc.internal.pageSize.height;
      let footerY = pageHeight - 15;

      doc.setFontSize(10);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, footerY);
    }

    doc.save("sales_return_billers_wise_report.pdf");
  };

  return (
    <>
      <SalesReturnReportFilter onSearch={handleSearch} from="Return Billers Wise" />
      {tableData.length > 0 ? (
        <Card className="mt-2 p-2 shadow-lg border-0 rounded-3">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5 className="fw-bold">
              Sales return billers wise details
            </h5>
            <div>
              <Button
                className="me-2 btn-sm"
                variant="success"
                onClick={downloadCSV}
              >
                Download CSV
              </Button>
              <Button
                className="me-2 btn-sm"
                variant="success"
                onClick={downloadPDF}
              >
                Download PDF
              </Button>
            </div>
          </div>
          {tableData?.map((item, index) => {
            const totalGrandTotal = item.saleData.reduce(
              (sum, sales) => +sum + +sales.grandTotal,
              0
          );
            return(
            
            <div key={index}>
              <h6 className="mt-3 fw-bold fs-4">Biller Name: 
                <span className="mt-3 fw-bold fs-4 text-primary">{" "+(item.billerName)}</span>
              </h6>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>SI.No</th>
                    <th>Financial Year</th>
                    <th>Invoice Date</th>
                    <th>Invoice Day</th>
                    <th>Invoice No</th>
                    <th>Customer Name</th>
                    <th>Grand Total</th>
                  </tr>
                </thead>
                <tbody>
                  {item.saleData?.map((sales, subIndex) => (
                    <tr key={`${index}-${subIndex}`}>
                      <td>{subIndex + 1}</td>
                      <td>{item.financialYear}</td>
                      <td>{sales.invoiceDate}</td>
                      <td>{sales.invoiceDay}</td>
                      <td>{sales.invoiceNo}</td>
                      <td>{sales.customerName}</td>
                      <td className="text-end">{rupeeFormat(sales.grandTotal)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={6} className="text-end fw-bold">
                      Total Grand Total:
                    </td>
                    <td className="fw-bold text-end">{rupeeFormat(totalGrandTotal)}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )})}
        </Card>
      ) : (
        <h4 className="text-center fw-bold text-secondary">
          No such data found !
        </h4>
      )}
    </>
  );
};

export default SalesReturnBillersWise;
