/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import { camelize } from "../../config/functions"
import { PurchaseAttribute } from "../../pages/PurchaseMaster"
import { addOpeningStock, addPurchase, deletePurchase, editOpeningStock, editPurchase, getPurchase, getPurchaseByDate, getPurchaseById } from "../../services/purchase.service"


export enum PurchasePaymentModesDropDown {
    CASH = "Cash",
    BANK = "Bank",
    CREDIT = "Credit",
}
export type PurchasePaymentModeTypes = PurchasePaymentModesDropDown.CASH | PurchasePaymentModesDropDown.BANK | PurchasePaymentModesDropDown.CREDIT

export enum PurchaseActionList {
    ADD_PURCHASE = 'ADD_PURCHASE',
    FETCH_PURCHASE = 'FETCH_PURCHASE',
    UPDATE_PURCHASE = 'UPDATE_PURCHASE',
    DELETE_PURCHASE = 'DELETE_PURCHASE',
}

export interface PurchaseItems {
    entryNumber: number
    productId: number
    productCode: string
    batchNumber: number
    expiryDate: string
    quantity: string
    freeQuantity: string
    totalQuantity: string
    purchaseRate: number
    priceWogst: number
    priceGst: number
    freightCost: number
    landingCost: number
    marginPercent: number
    marginAmt: number
    salesRate: number
    wRate: number
    rRate: number
    lRate: number
    mrp: number
    sDiscount: number
    pDiscountpercent: number
    pDiscountamount: number
    cDiscountpercent: number
    cDiscountamount: number
    taxableAmount: number
    igstAmount: number
    cgstAmount: number
    sgstAmount: number
    totalAmount: number
    unitCost: number
    profitPercent: number
    profitAmount: number
}

export interface PurchaseType {
    // id?: number
    // invoiceDate: string
    // invoiceNo: string
    // purchaseEntryNo: string
    // supplierId: number
    // finalAmount: string
    id?: number
    financeYear: string
    branchId: number
    invoiceType: string
    invoiceTitle: string
    invoiceHour?: string
    invoiceDate: string
    goodsReceivedDate: string
    invoiceDay?: string
    invoiceWeekNumber?: number
    invoiceWeekDay?: number
    invoiceMonth?: number
    invoiceYear?: number
    invoiceNumber: string
    invoiceAmount?: number
    purchaseEntryNumber: number
    systemNo: string
    repId: number
    // repName: string
    supplierId: number
    purchaseItems: PurchaseItems[]
    stockItems: PurchaseAttribute[]
    /////new
    bankName: string
    bankNumber: number
    supplierName: string
    paymentMode: PurchasePaymentModeTypes
    billAmount?: string
    disPercent?: string
    disAmount?: string
    freightCost?: string
    add?: string
    less?: string
    grandTotal?: string
    amtInWords?: string
    particulars?: string
    amtPaid?: string
    deliveryMode?: string
    deliveryDetails?: string
    remarks?: string
    netTaxable?: string
    netIgst?: string
    netSgst?: string
    netCgst?: string
    netProfit?: string
    netProfitPercent?: string
    AValue?: string
    ATaxable?: string
    BValue?: string
    BTaxable?: string
    CValue?: string
    CTaxable?: string
    DValue?: string
    DTaxable?: string
    EValue?: string
    ETaxable?: string
    FValue?: string
    FTaxable?: string
    GValue?: string
    GTaxable?: string
    orderDate?: string
    orderNo?: string
    dateOfSupply?: string
    placeOfSupply?: string
    transportMode?: string
    transportName?: string
    vehicleNo?: string
    noOfBundles?: number
    noOfQty?: number
    netWeight?: number
    ewayBillNo?: string
    acknowNo?: string
    acknowDate?: string
    irnNo?: string
    irnDate?: string
    buyerOrderNo?: string
    buyerOrderDate?: string
    destination?: string
    dispatchDocumentNo?: string
    remainingRecordExist?: number
    transactions: ({
        paymentMode: string;
        particulars: string;
        amtPaid: number;
    })[]

}

export interface AddPurchaseAction {
    type: PurchaseActionList.ADD_PURCHASE
    data: PurchaseType
}

export interface FetchPurchaseAction {
    type: PurchaseActionList.FETCH_PURCHASE
    data: Array<PurchaseType>
}

export interface UpdatePurchaseAction {
    type: PurchaseActionList.UPDATE_PURCHASE
    data: PurchaseType
    id: number
}

export interface DeletePurchaseAction {
    type: PurchaseActionList.DELETE_PURCHASE
    data: number
}

export type PurchaseActions = AddPurchaseAction | FetchPurchaseAction | UpdatePurchaseAction | DeletePurchaseAction

export const addPurchaseAction = (data: PurchaseType) => {
    // return async (dispatch: Dispatch, getState: GetState) => {

    return async (dispatch: Dispatch) => {
        return addPurchase(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddPurchaseAction>({
                    type: PurchaseActionList.ADD_PURCHASE,
                    data: data
                })
                return Promise.resolve({
                    message: response.data.message || 'Purchase Updated',
                    invoiceNumber: response.data.data.invoiceNumber,
                    purchaseEntryNumber: response.data.data.purchaseEntryNumber,
                });

            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })

    }
    // dispatch<AddPurchaseAction>({
    //     type: PurchaseActionList.ADD_PURCHASE,
    //     data: {
    //         ...data,
    //         id: parseInt(nanoid(5))
    //     }
    // })

    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // // return Promise.resolve("Purchase Added")
    // return api().post<APIResponse<PurchaseType>>('Purchase/', data, config).then(response => {
    //     if (response.status === 200 && response.data.data) {

    //         dispatch<AddPurchaseAction>({
    //             type: PurchaseActionList.ADD_PURCHASE,
    //             data: response.data.data
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Purchase Added')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to add'
    //         : 'Unable to add')
    // })
    // }
}

export const addOpeningStockAction = (data: PurchaseType) => {

    return async (dispatch: Dispatch) => {
        return addOpeningStock(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddPurchaseAction>({
                    type: PurchaseActionList.ADD_PURCHASE,
                    data: data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Opening Stock Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })

    }
}

export const updatePurchaseAction = (data: PurchaseType, id: number) => {
    // return async (dispatch: Dispatch, getState: GetState) => {

    return async (dispatch: Dispatch) => {
        return editPurchase(data, id).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
                dispatch<UpdatePurchaseAction>({
                    type: PurchaseActionList.UPDATE_PURCHASE,
                    data: data,
                    id: id
                })
                // return Promise.resolve(response.data.message
                //     ? response.data.message
                //     : 'Purchase Updated')

                return Promise.resolve({
                    message: response.data.message || 'Purchase Updated',
                    invoiceNumber: response.data.data.invoiceNumber,
                    purchaseEntryNumber: response.data.data.purchaseEntryNumber,
                });

            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })

    }

    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // // return Promise.resolve("Purchase Updated")
    // return api().put<APIResponse<PurchaseType>>(`Purchase/?id=${id}`, data, config).then(response => {
    //     if (response.status === 200 && response.data.data) {
    //         dispatch<UpdatePurchaseAction>({
    //             type: PurchaseActionList.UPDATE_PURCHASE,
    //             data: response.data.data
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Purchase Updated')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to update'
    //         : 'Unable to update')
    // })
}

export const updateOpeningStockAction = (data: PurchaseType, id: number) => {

    return async (dispatch: Dispatch) => {
        return editOpeningStock(data, id).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
                dispatch<UpdatePurchaseAction>({
                    type: PurchaseActionList.UPDATE_PURCHASE,
                    data: data,
                    id: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Opening Stock Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })

    }
}

export const fetchPurchaseByIdAction = (id: number) => {
    return async (dispatch: Dispatch) => {
        const response = await getPurchaseById(id)
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        return data
        // dispatch<FetchPurchaseAction>({
        //     type: PurchaseActionList.FETCH_PURCHASE,
        //     data: data
        // })
    }
}

export const fetchPurchaseByDateAction = (startDate: string, endDate: string) => {
    return async (dispatch: Dispatch) => {
        const response = await getPurchaseByDate(startDate, endDate)
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchPurchaseAction>({
            type: PurchaseActionList.FETCH_PURCHASE,
            data: data
        })
    }
}




export const fetchPurchaseAction = () => {
    return async (dispatch: Dispatch) => {
        const response = await getPurchase()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchPurchaseAction>({
            type: PurchaseActionList.FETCH_PURCHASE,
            data: data
        })
    }


    // return async (dispatch: Dispatch, getState: GetState) => {

    // dispatch<FetchPurchaseAction>({
    //     type: PurchaseActionList.FETCH_PURCHASE,
    //     data: PurchaseSampleData
    // })
    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // return api().get<APIResponse<PurchaseType[]>>('Purchase/', config).then(response => {
    //     if (response.status === 200 && response.data.data) {
    //         dispatch<FetchPurchaseAction>({
    //             type: PurchaseActionList.FETCH_PURCHASE,
    //             data: response.data.data
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Fetch Successfull')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to fetch'
    //         : 'Unable to fetch')
    // })
    // }
}

export const deletePurchaseAction = (id: number) => {
    return async (dispatch: Dispatch) => {
        return deletePurchase(id).then(response => {
            if (response.status === 200) {
                dispatch<DeletePurchaseAction>({
                    type: PurchaseActionList.DELETE_PURCHASE,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Purchase Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
    // return async (dispatch: Dispatch, getState: GetState) => {

    // dispatch<DeletePurchaseAction>({
    //     type: PurchaseActionList.DELETE_PURCHASE,
    //     data: id
    // })

    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // // return Promise.resolve("Purchase Deleted")
    // return api().delete<APIResponse<PurchaseType>>(`Purchase/?id=${id}`, config).then(response => {
    //     if (response.status === 200) {
    //         dispatch<DeletePurchaseAction>({
    //             type: PurchaseActionList.DELETE_PURCHASE,
    //             data: id
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Purchase Deleted')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to delete'
    //         : 'Unable to delete')
    // })
    // }
}

