import { ClientRegistrationActionList, ClientRegistrationType } from "../actions/clientRegistration";
import { Action } from "../actions/types";


const initialValue: Array<ClientRegistrationType> = [];

export const clientRegistrationReducer = (state: ClientRegistrationType[] = initialValue, action: Action) => {
    switch (action.type) {
        case ClientRegistrationActionList.ADD_CLIENT_REG:
            return [...state, action.data];

        case ClientRegistrationActionList.EDIT_CLIENT_REG: {
            const updated = [...state].slice();
            const index = updated.findIndex(u => u.id === action.id);
            if (index > -1) {
                updated[index] = {
                    ...updated[index],
                    ...action.data,
                    shopLogo: action.data.shopLogo || updated[index].shopLogo,
                    bannerImage: action.data.bannerImage || updated[index].bannerImage
                };
            }
            return updated;
        }

        case ClientRegistrationActionList.FETCH_CLIENT_REG:
            return action.data;

        case ClientRegistrationActionList.DELETE_CLIENT_REG:
            return state.filter(client => client.id?.toString() !== action?.data?.toString());

        default:
            return state;
    }
};