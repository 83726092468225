import { useEffect, useState } from "react";
import { Card, Table, Button } from "react-bootstrap";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import ReportFilter from "./ReportFilter";
import api from '../../config/api';
import { getDate, rupeeFormat, LocalFormatDate } from "../../config/functions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../redux/store";
import { ClientRegistrationType, fetchClientRegistrationAction } from "../../redux/actions/clientRegistration";
import { apiUrl } from "../../config";
import bgMaterial from "../../components/custom/assets/images/bg-material.png";

interface InvoiceData {
    financialYear: string;
    supplierGSTIN: string;
    supplierName: string;
    invoiceNo: string;
    date: string;
    rate: number;
    taxable: number;
    igst: number;
    cgst: number;
    sgst: number;
    netTotal: number;
}

const PurchaseReturnGstRateWise = () => {
    const dispatch = useDispatch<AppDispatch>()

    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [supplierId, setsupplierId] = useState<string>("");
    const clients = useSelector<StoreState, ClientRegistrationType[]>(state => state.clientRegistration)

    const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

    const [tableData, setTableData] = useState<InvoiceData[]>([]);

    useEffect(() => {
        dispatch(fetchClientRegistrationAction())
    }, [])

    useEffect(() => {
        if (triggerSearch) {
            const response = api().get(
                `reports/purchaseReturnGstRateWise/?fromDate=${getDate(fromDate)}&toDate=${getDate(toDate)}&supplierId=${supplierId}`
            );

            response.then((res) => {
                if (res.status === 200 && res.data.data) {
                    console.log("API Response:", res.data);
                    setTableData(res.data.data);
                }
            }).catch(error => {
                console.error("Error fetching purchase data:", error);
            });

            setTriggerSearch(false);
        }
    }, [triggerSearch, fromDate, toDate, supplierId]);

    const [filters, setFilters] = useState<any>({});

    const handleSearch = (newFilters: any) => {
        setFilters(newFilters);
        setFromDate(newFilters.fromDate);
        setToDate(newFilters.toDate);
        setsupplierId(newFilters.supplierId);

        setTriggerSearch(true);
    };
    const downloadCSV = () => {
        const header = [
            "SI.No", "Finanacial Year", "Supplier GSTIN", "Supplier Name", "Invoice Number", "Date", "Rate",
            "Taxable", "IGST", "CGST", "SGST", "Net Total"
        ].map(col => `="${col}"`);

        const rows = tableData?.map((item, index) => [
            `="${index + 1}"`, // Ensures SI.No is treated as text
            `="${item.financialYear}"`,
            `="${item.supplierGSTIN}"`,
            `="${item.supplierName}"`,
            `="${item.invoiceNo}"`,
            `="${item.date}"`,
            `="${item.rate}"`,
            `="${item.taxable}"`,
            `="${item.igst}"`,
            `="${item.cgst}"`,
            `="${item.sgst}"`,
            `="${item.netTotal}"`
        ]);

        const csvContent = [
            header.join(","),
            ...rows.map(row => row.join(","))
        ].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "purchase_return_gst_rate_wise_report.csv";
        link.click();
    };


    const downloadPDF = () => {
        const logoUrl = clients[0]?.shopPhoto;

        const doc = new jsPDF("landscape");
        const marginX = 10;
        const boxWidth = doc.internal.pageSize.width - 2 * marginX;
        const boxHeight = 25;
        let startY = 15;

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");

        doc.rect(marginX, startY - 5, boxWidth, boxHeight);

        if (logoUrl) {
            doc.addImage(logoUrl, "JPEG", marginX + 5, startY - 2, 25, 20);
        }

        const textX = marginX + 35;

        doc.text(clients[0]?.shopShortName || "", textX, startY);
        doc.setFont("helvetica", "normal");
        doc.text(
            `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${clients[0]?.district || ""} `,
            textX,
            startY + 5
        );
        doc.text(
            `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${clients[0]?.contactNo1 || ""}`,
            textX,
            startY + 10
        );
        doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 15);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        const heading = "Purchase return GST rate wise report";
        const headingWidth = doc.getTextWidth(heading);
        const pageWidth = doc.internal.pageSize.width;
        doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 8);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const financialYearText = `Financial Year: ${tableData[0]?.financialYear || ""}`;
        const headingStartX = marginX + boxWidth - headingWidth - 10;
        doc.text(financialYearText, headingStartX, startY + 13);

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");

        const fromDate = "From : " + (LocalFormatDate(getDate(filters?.fromDate)) || "");
        const toDate = "To : " + (LocalFormatDate(getDate(filters?.toDate)) || "");

        const tableStartY = startY + boxHeight + 10;

        doc.text(fromDate, marginX, tableStartY - 8);
        doc.text(toDate, marginX + 50, tableStartY - 8);

        if (!tableData || tableData.length === 0) {
            doc.text("No data available", marginX, tableStartY);
            doc.save("purchase_return_gst_rate_wise_report.pdf");
            return;
        }

        let tableBody = tableData?.map((item, index) => [
            (index + 1).toString(),
            item.supplierGSTIN || "",
            item.supplierName || "",
            item.invoiceNo || "",
            LocalFormatDate(item.date) || "",
            item.rate || "0",
            rupeeFormat(item.taxable) || "0",
            rupeeFormat(item.igst) || "0",
            rupeeFormat(item.cgst) || "0",
            rupeeFormat(item.sgst) || "0",
            rupeeFormat(item.netTotal) || "0",
        ]);

        autoTable(doc, {
            startY: 47,
            head: [
                [
                    "SI.No", "Supplier GSTIN", "Supplier Name", "Invoice Number", "Date",
                    "Rate", "Taxable", "IGST", "CGST", "SGST", "Net Total"
                ],
            ],
            body: tableBody,
            theme: "grid",
            styles: { fontSize: 8, textColor: 0, lineColor: 0, lineWidth: 0.1 },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: 0,
                fontStyle: "bold",
                lineColor: 0,
                lineWidth: 0.1,
            },
            margin: { top: 25, left: 10, right: 10 },
            columnStyles: {
                0: { halign: "center" },
                1: { halign: "left" },
                2: { halign: "left" },
                3: { halign: "left" },
                4: { halign: "center" },
                5: { halign: "right" },
                6: { halign: "right" },
                7: { halign: "right" },
                8: { halign: "right" },
                9: { halign: "right" },
                10: { halign: "right" },
            },

        });

        doc.save("Purchase_return_gst_rate_wise_report.pdf");
    };


    return (
        <>
            <ReportFilter onSearch={handleSearch} from="Purchase Return GST Rate Wise" />
            {tableData.length > 0 ?
                <Card className="mt-2 p-2 shadow-lg border-0 rounded-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5 className="fw-bold">Purchase return GST rate wise details</h5>
                        <div>
                            <Button className="me-2 btn-sm" variant="success" onClick={downloadCSV}>
                                Download CSV
                            </Button>
                            <Button className="me-2 btn-sm" variant="success" onClick={downloadPDF}>
                                Download PDF
                            </Button>
                        </div>
                    </div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>SI.No</th>
                                <th>Financial Year</th>
                                <th>Supplier GSTIN</th>
                                <th>Supplier Name</th>
                                <th>Invoice Number</th>
                                <th>Date</th>
                                <th>Rate</th>
                                <th>Taxable</th>
                                <th>IGST</th>
                                <th>CGST</th>
                                <th>SGST</th>
                                <th>Net Total</th>
                            </tr>
                        </thead>

                        <tbody>
                            {tableData?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.financialYear}</td>
                                    <td>{item.supplierGSTIN}</td>
                                    <td>{item.supplierName}</td>
                                    <td>{item.invoiceNo}</td>
                                    <td>{LocalFormatDate(item.date)}</td>
                                    <td>{item.rate}</td>
                                    <td>{rupeeFormat(item.taxable)}</td>
                                    <td>{rupeeFormat(item.igst)}</td>
                                    <td>{rupeeFormat(item.cgst)}</td>
                                    <td>{rupeeFormat(item.sgst)}</td>
                                    <td>{rupeeFormat(item.netTotal)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </Card> : <h4 className="text-center fw-bold text-secondary">
                    No such data found !
                </h4>
            }
        </>
    );
};

export default PurchaseReturnGstRateWise;
