import { ChangeEventHandler, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormInput, VerticalForm } from '../components/custom/components';
import AuthLayout from '../components/custom/pages/AuthLayout';
import { ToastTypes, useToast } from '../components/toast/ToastProvider';
import { errorHandler } from '../config/api';
import { AppDispatch, StoreState } from '../redux/store';
import '../styles/styles.css';
import { AuthUserType } from '../redux/actions/authUserActions';
import { changePasswordAction, ResetPasswordDataType } from '../redux/actions/changePasswordAction';
import { changePasswordType } from '../redux/actions/usersActions';

const ResetPassword = () => {
    const toast = useToast();
    const dispatch = useDispatch<AppDispatch>();
    const authUser = useSelector<StoreState, AuthUserType | null>((state) => state.authUser);
    console.log(authUser);

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [mounted, setMounted] = useState<boolean>(false);

    const [username, setUsername] = useState<string>(authUser?.userId || '');
    const [oldPassword, setOldPassword] = useState<string>(authUser?.password || '');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [oldPasswordError, setOldPasswordError] = useState<string>('');
    const [newPasswordError, setNewPasswordError] = useState<string>('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');


    const handleOldPassword: ChangeEventHandler<HTMLInputElement> = (event) => {
        setOldPasswordError('');
        setOldPassword(event.target.value)
    }

    const handleNewPassword: ChangeEventHandler<HTMLInputElement> = (event) => {
        setNewPasswordError('');
        setNewPassword(event.target.value);
    };


    const handleConfirmPassword: ChangeEventHandler<HTMLInputElement> = (event) => {
        setConfirmPasswordError('');
        setConfirmPassword(event.target.value);
    };

    // const hasRequiredCharacters = (input: string): boolean => {
    //     const hasAlpha = /[A-Za-z]/.test(input);
    //     const hasNum = /\d/.test(input);
    //     const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(input);
    //     return hasAlpha && hasNum && hasSpecialChar;
    // };

    const resetPasswordInsights = (data: ResetPasswordDataType) => {
        let error = false;
        if (!data.newPassword || !data.confirmPassword || !data.oldPassword) {
            toast('All fields are required', ToastTypes.ERROR);
            error = true;
            if (!data.oldPassword) {
                setOldPasswordError('Old Password is required');
                error = true;
            }
            if (!data.newPassword) {
                setNewPasswordError('New Password is required');
                error = true;
            }
            if (!data.confirmPassword) {
                setConfirmPasswordError('Confirm Password is required');
                error = true;
            }
            if (data.newPassword !== data.oldPassword) {
                setNewPasswordError('New Password should not be the same as the old password');
                error = true;
            }
            // if (data.oldPassword !== authUser?.) {
            //     setOldPasswordError('old password is incorrect');
            //     error = true;
            // }
            return error;
        }
    };

    const handleResetPassword = async (event: React.FormEvent) => {
        event.preventDefault();

        const data: ResetPasswordDataType = {
            userId: username,
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
        };

        const hasError = resetPasswordInsights(data);

        if (!hasError) {
            setLoading(true);
            const passwordData: changePasswordType = {
                id: Number(authUser?.id),
                oldPassword: oldPassword,
                newPassword: newPassword,
            };

            dispatch(changePasswordAction(passwordData, authUser?.id)).then(text => {
                toast('Password changed Successfully', ToastTypes.SUCCESS)
                navigate('/logout')
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }


    console.log(authUser);

    useEffect(() => {
        setMounted(true);
        return () => setMounted(false);
    }, []);

    return (
        <AuthLayout helpText={'Enter your details to reset your password.'}>
            <VerticalForm onSubmit={handleResetPassword}>
                <FormInput
                    label={'Username'}
                    type="text"
                    name="username"
                    value={username}
                    placeholder="Enter your username"
                    disabled
                    containerClass="mb-3"
                    labelColor="white"
                    className="custom-input"
                />
                <FormInput
                    label={'Old Password'}
                    type="password"
                    name="oldPassword"
                    value={oldPassword}
                    placeholder="Enter your old password"
                    onChange={handleOldPassword}
                    errorText={oldPasswordError}
                    containerClass="mb-3"
                    labelColor="white"
                    className="custom-input"
                />
                <FormInput
                    label={'New Password'}
                    type="password"
                    name="newPassword"
                    value={newPassword}
                    placeholder="Enter your new password"
                    onChange={handleNewPassword}
                    errorText={newPasswordError}
                    containerClass="mb-3"
                    labelColor="white"
                    className="custom-input"
                />
                <FormInput
                    label={'Confirm Password'}
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    placeholder="Confirm your new password"
                    onChange={handleConfirmPassword}
                    errorText={confirmPasswordError}
                    containerClass="mb-3"
                    labelColor="white"
                    className="custom-input"
                />
                <div className="text-center d-grid">
                    {loading ? (
                        <Button variant="primary" type="button" disabled className="btn-lg p-2"
                            onClick={handleResetPassword}>
                            Reset Password
                        </Button>
                    ) : (
                        <Button variant="primary" type="submit" className="btn-lg p-2">
                            Reset Password
                        </Button>
                    )}
                </div>
            </VerticalForm>
        </AuthLayout>
    );
};

export default ResetPassword;
