import { MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { AuthUserType } from "../redux/actions/authUserActions"
import { ShopType, addShopAction, deleteShopAction, editShopAction } from "../redux/actions/shopAction"
import { AppDispatch, StoreState } from "../redux/store"

interface TableBody {
    sno: number,
    shopName: string | undefined,
    // viewImage: string | undefined,
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    // rolesAccessObj:RoleType|undefined

}

type HandleEdit = (value: ShopType) => void
type HandleDelete = (value: ShopType) => void

const Shop = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const ShopList = useSelector<StoreState, ShopType[]>(state => state.shop)

    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<ShopType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [id, setId] = useState<number>()

    const [shopName, setShopName] = useState<string>('')
    const [shopNameError, setShopNameError] = useState<string>('')

    // const [imageUpload, setImageUpload] = useState<any | null>({ filename: '' })
    // const [imageUrl, setImageUrl] = useState<any>('')
    // const [imageFlag, setImageFlag] = useState<boolean>(false)

    const Authuser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const url = window.location.pathname
    // const rolesAccessObj = Authuser?.roles?.find(f => f.pageUrl === url)

    const filteredShop = ShopList.filter((data) =>
        data.name?.toLocaleLowerCase().includes(shopName.toLocaleLowerCase())
    )

    const handleName = (e: any) => {
        const value = e.target.value;
        if (/[^a-zA-Z0-9 ]/g.test(value)) {
            setShopNameError('Special characters are not allowed!');
        } else {
            setShopName(value);
            setShopNameError('');
        }
    };
    // const handleImageUpload: ChangeEventHandler<HTMLInputElement | HTMLFormElement> = (e) => {
    //     setImageUpload(e.target.files[0])
    //     setImageFlag(true)
    // }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)

    }

    const handleDelete: HandleDelete = (value) => {
        setDeleteId(value.id)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const resetStates = () => {

        setShopName('')
        setEditData(null)

    }

    const handleSave = () => {
        let error = false
        // const addData: ShopType = {
        //     category:"shop",
        //     title:shopName

        // }

        const edata: ShopType = {
            id: id,
            name: shopName,
            // imageUrl: imageUrl
        }
        if (shopName.trim() === '') {
            setShopNameError('Shop required')
            error = true
        } else if ((shopName.trim() || '').length > 50) {
            setShopNameError('Shop should be within 50 characters')
            error = true
        }




        if (!error) {
            const data = new FormData()
            data.append('name', shopName)
            // data.append('imageUrl', imageUpload)
            // if (imageFlag) {
            //     data.append('oldImageUrl', editData?.imageUrl ? imageUrl : '')
            // }
            if (formType === FormTypeList.ADD) {
                dispatch(addShopAction(data)).then(text => {
                    toast('Shop Added', ToastTypes.SUCCESS)
                    resetStates()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== null) {
                dispatch(editShopAction(data, editData.id)).then(text => {
                    toast('Updated', ToastTypes.SUCCESS)
                    resetStates()
                    setEditData(null)
                    setFormType(FormTypeList.ADD)
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }


    useEffect(() => {
        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {
            setId(editData.id)
            setShopName(editData.name || '')
        } else {
            resetStates()
        }
    }, [formType, editData])


    const onDelete = () => {
        if (deleteId) {
            dispatch(deleteShopAction(deleteId)).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
                resetStates()
            }).catch(text => {

            })
        }
    }
    return (<>
        <Modal
            headerText={'Delete Shop'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <DeleteConfirmationForm
                onDelete={onDelete}
                onClose={handleDeleteFormClose}
            />
        </Modal>

        <UserLayout>
            <div className="container">
                <h4 className="text-center text-primary fw-bold mb-4 fs-2">Shop Master</h4>
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                        <div className="card shadow-lg p-4">
                            <div className="row">
                                {/* <div className="col-1"></div> */}
                                <div className="col-2">
                                    <label htmlFor="shopName" className="form-label fw-bold text-secondary">Shop Name:</label>
                                </div>
                                <div className="col-1"></div>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        id="shopName"
                                        className={`form-control ${shopNameError ? 'is-invalid' : ''} w-100`}
                                        value={shopName}
                                        onChange={(e) => handleName(e)}
                                    />
                                    {shopNameError && <div className="invalid-feedback">{shopNameError}</div>}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-7"></div>
                                <button className="btn btn-sm btn-secondary me-1 fw-bold d-inline-block col-2" onClick={resetStates}>Add New</button>
                                <button className="btn btn-sm btn-success fw-bold col-2 d-inline-block" onClick={handleSave}>{editData ? 'Update' : 'Save'}</button>
                                {/* <div className="col-1"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-8 offset-md-2">
                        <div className="card shadow-lg p-2">
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredShop.map((dp, i) => (
                                            <ShopBody
                                                key={dp.id}
                                                sno={i + 1}
                                                shopName={dp.name}
                                                onEdit={() => handleEdit(dp)}
                                                onDelete={() => handleDelete(dp)}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    </>

    );
}

const ShopBody = ({ sno, shopName, onEdit, onDelete }: TableBody) => {
    return (
        <tr>
            <td className="align-middle text-center fw-bold text-secondary">{sno}</td>
            <td className="align-middle text-capitalize text-primary fw-bold">{shopName}</td>
            <td className="align-middle">
                <div className="d-flex justify-content-center gap-2">
                    <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>
                    <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>
                </div>
            </td>
        </tr>
    );
};

export default Shop;
