import { useEffect, useState } from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../redux/store";
import { ClientRegistrationType, fetchClientRegistrationAction } from "../../redux/actions/clientRegistration";
import { AccountHolderType } from "../../redux/actions/accountHolder";
import { UsersType } from "../../redux/actions/usersActions";

interface props {
    onSearch: (filters: any) => void
    from?: string
}

const SalesReportFilter = ({ onSearch, from }: props) => {
    const today = new Date();
    const dispatch = useDispatch<AppDispatch>()
    const [weekNumbers, setWeekNumbers] = useState<number[]>([]);
    const clients = useSelector<StoreState, ClientRegistrationType[]>(state => state.clientRegistration)
    const accountHolders = useSelector<StoreState, AccountHolderType[]>(state => state.account)
    const users = useSelector<StoreState, UsersType[]>(state => state.user)

    const getWeeksInMonth = (date: Date): number => {
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const diffInTime = endDate.getTime() - startDate.getTime();
        const totalDays = diffInTime / (1000 * 3600 * 24);
        const totalWeeks = Math.ceil(totalDays / 7);
        return totalWeeks;
    };

    const [filters, setFilters] = useState({
        year: today,
        month: today,
        weekDays: today,
        weekNumber: 0,
        fromDate: today,
        toDate: today,
        branchId: null,
        repId: null,
        billerName:null,
        invoiceTitle: "RETAIL",
        invoiceType: null,
    });

    const handleYearChange = (date: Date | null) => {
        if (date) {
            const newYear = date.getFullYear();
            const firstDayOfYear = new Date(newYear, 0, 1);
            const firstMonth = new Date(newYear, 0, 1);
            const firstWeekDay = new Date(newYear, 0, 1);
            const weekNumber = getWeekNumber(firstDayOfYear);

            setFilters((prev) => ({
                ...prev,
                year: date,
                month: firstMonth,
                weekDays: firstWeekDay,
                weekNumber: weekNumber,
                fromDate: firstDayOfYear,
                toDate: new Date(firstDayOfYear.getTime() + 7 * 24 * 60 * 60 * 1000), // Next 7 days
            }));
        }
    };

    const handleSearchClick = () => {
        onSearch(filters);
        
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleReset = () => {
        setFilters({
            year: today,
            month: today,
            weekDays: today,
            weekNumber: 0,
            fromDate: today,
            toDate: today,
            branchId: null,
            invoiceTitle: "RETAIL",
            invoiceType: null,
            repId: null,
            billerName:null
        });
    };


    const handleDateChange = (date: Date | null, name: string) => {
        if (date) {
            setFilters((prev) => {
                let updatedFilters = { ...prev, [name]: date };

                if (name === "fromDate") {
                    const newYear = date.getFullYear();
                    const newMonth = date.getMonth();

                    updatedFilters.weekDays = date;
                    updatedFilters.year = new Date(newYear, 0, 1);
                    updatedFilters.month = new Date(newYear, newMonth, 1);

                    updatedFilters.toDate = new Date(date);
                    updatedFilters.toDate.setDate(date.getDate() + 7);
                }

                return updatedFilters;
            });
        }
    };

    const getWeekNumber = (date: Date): number => {
        const firstJan = new Date(date.getFullYear(), 0, 1);
        const days = Math.floor((date.getTime() - firstJan.getTime()) / (24 * 60 * 60 * 1000));
        return Math.ceil((days + firstJan.getDay() + 1) / 7);
    };
    useEffect(() => {
        if (filters.month) {
            const weekCount = getWeeksInMonth(filters.month);
            setWeekNumbers(Array.from({ length: weekCount }, (_, i) => i + 1));
        }
    }, [filters.month]);

    useEffect(() => {
        dispatch(fetchClientRegistrationAction())
    }, [])

    return (
        <Card className="p-2 shadow-lg border-0 rounded-3">
            <Form>
                <Row className="mb-3">
                    <Col md={2}>
                        <Form.Group>
                            <Form.Label className="fw-semibold">Year</Form.Label>
                            <DatePicker
                                selected={filters.year}
                                onChange={(date) => handleYearChange(date)}
                                showYearPicker
                                dateFormat="yyyy"
                                className="form-control"
                            />
                        </Form.Group>
                    </Col>

                    <Col md={2}>
                        <Form.Group>
                            <Form.Label className="fw-semibold">Month</Form.Label>
                            <DatePicker
                                selected={filters.month}
                                onChange={(date) => handleDateChange(date, "month")}
                                showMonthYearPicker
                                dateFormat="MMMM"
                                className="form-control"
                            />
                        </Form.Group>
                    </Col>

                    <Col md={2}>
                        <Form.Group>
                            <Form.Label className="fw-semibold">Week Number</Form.Label>
                            <Form.Select
                                name="weekNumber"
                                value={filters.weekNumber}
                                onChange={handleChange}
                            >
                                {weekNumbers.map((week) => (
                                    <option key={week} value={week}>
                                        Week {week}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col md={2}>
                        <Form.Group>
                            <Form.Label className="fw-semibold">Day</Form.Label>
                            <DatePicker
                                selected={filters.weekDays}
                                onChange={(date) => handleDateChange(date, "weekDays")}
                                dateFormat="EEEE"
                                className="form-control"
                            />
                        </Form.Group>
                    </Col>

                    <Col md={2}>
                        <Form.Group>
                            <Form.Label className="fw-semibold">From Date</Form.Label>
                            <DatePicker
                                selected={filters.fromDate}
                                onChange={(date) => handleDateChange(date, "fromDate")}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group>
                            <Form.Label className="fw-semibold">To Date</Form.Label>
                            <DatePicker
                                selected={filters.toDate}
                                onChange={(date) => handleDateChange(date, "toDate")}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                            />
                        </Form.Group>
                    </Col>
                    
                </Row>
                
                <Row className="mb-3">
                    {from === "Sales Invoice Wise" && <>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label className="fw-semibold">Branch</Form.Label>
                                <Form.Select name="branch" value={filters.branchId ?? ''} onChange={handleChange}>
                                    <option value="All">All</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={client.id}>
                                            {client.place}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col md={2}>
                            <Form.Group>
                                <Form.Label className="fw-semibold">Invoice Title</Form.Label>
                                <Form.Select name="invoiceTitle" value={filters.invoiceTitle ?? ''} onChange={handleChange}>
                                    <option value="RETAIL">RETAIL</option>
                                    <option value="WHOLESALE">WHOLESALE</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col md={2}>
                            <Form.Group>
                                <Form.Label className="fw-semibold">Invoice Type</Form.Label>
                                <Form.Select name="invoiceType" value={filters.invoiceType ?? ''} onChange={handleChange}>
                                    <option value="All">All</option>
                                    {["Tax Invoice", "Estimate", "Quotation", "Tax Return", "SALES TAX INVOICE"].map((title) => (
                                        <option key={title} value={title}>
                                            {title}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </>}
                    {from === "Representer Wise" && (
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label className="fw-semibold">Representer</Form.Label>
                                <Form.Select name="repId" value={filters.repId?? ''} onChange={handleChange}>
                                    <option value="null">Select</option>
                                    {accountHolders
                                        .filter((account) => account.groupName === "Representer") // Filtering by groupName
                                        .map((account) => (
                                            <option key={account.accountNumber} value={account.accountNumber}>
                                                {account.accountName}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    )}
                    {from === "Billers Wise" && (
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label className="fw-semibold">Billed by</Form.Label>
                                <Form.Select name="billerName" value={filters.billerName?? ''} onChange={handleChange}>
                                    <option value="null">Select</option>
                                    {users
                                        .map((user) => (
                                            <option key={user.userId} value={user.userId}>
                                                {user.name}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    )}
                    <div className="d-flex justify-content-end mb-1">
                        <Button className="btn-sm fw-bold" variant="secondary" onClick={handleReset}>Reset</Button>
                        <Button className="btn-sm fw-bold ms-2" variant="success" onClick={handleSearchClick}>Search</Button>
                </div>
                </Row>  
            </Form>
        </Card>
    );
};

export default SalesReportFilter;
