import { nanoid } from "@reduxjs/toolkit";
import React, { FC, useEffect, useState } from "react";
import { FormInput, MultiSelect } from "../components/custom/components";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import { getAttributes } from "../services/attribute.service";
import { getAttributesItems } from "../services/attributeItems.service";
import { PurchaseReturnAttribute } from "./PurchaseReturnMaster";

interface Props {
    pcode: string
    GSTPercent: number
    pPrate?: number
    pSdrs?: number
    pPdPercent?: number
    pPdrs?: number
    pCdPercent?: number
    pCdrs?: number
    pPwg?: number
    pPwog?: number
    pFcost?: number
    pLcost?: number
    pMarginPercent?: number
    pMarginAmt?: number
    pSalesRate?: number
    pWprice?: number
    pRprice?: number
    pUprice?: number
    pMrp?: number
    pQty: number
    pFree: number
    pIgst?: number
    pCgst?: number
    pSgst?: number
    pTax?: number
    pAmt?: number
    pUprofit?: number
    pTprofit?: number
    pProfitPercent?: number
    pNetAmt: number
    pTotalProfit: number
    pTotalTaxable: number
    supPlace: string
    cartesianTableData: PurchaseReturnAttribute[]
    setCartesianTableData: React.Dispatch<React.SetStateAction<PurchaseReturnAttribute[]>>
    onSave: () => void
}

interface selectedAttributeItemsType {
    id: number
    attributeId: number
    name: string
    code: string
}

export interface AttributeDropdown {
    id: number
    attributeId: number
    name: string
    code: string
    label: string
    value: number
}

export interface cartesianAttributes {
    id: string
    item: string
    itemCode: string
    productCode: string
    productGroupCode: string
    // Qty: string
    ///
    qty: string
    free: string
    purchaseRate: string
    sdRs: string
    pdPercent: string
    pdRs: string
    cdPercent: string
    cdRs: string
    totDisAmt: string
    pwog: string
    pwg: string
    sRate1: string
    sRate2: string
    sRate3: string
    mrp: string
    taxable: string
    igst: string
    cgst: string
    sgst: string
    netAmt: string
    unitProfit: string
    totalProfit: string
    profitPercent: string
}

export interface AttributeType {
    id: number
    name: string
    createdBy?: string
    createTimestamp?: string
}

export interface AttributeItemsType {
    id: number
    attributeId: number
    name: string
    code: string
    createdBy?: string
    createTimestamp?: string
}

const GroupProductsReturn: FC<Props> = ({ cartesianTableData, pcode, GSTPercent, supPlace, setCartesianTableData, onSave, pFree, pNetAmt, pQty, pTotalProfit, pTotalTaxable, pPwg, pPwog, pWprice, pRprice, pUprice, pMrp, pIgst, pCgst, pSgst, pAmt, pTax, pTprofit, pUprofit, pProfitPercent, pFcost, pLcost, pMarginAmt, pMarginPercent, pPrate, pSalesRate, pSdrs, pCdPercent, pCdrs, pPdPercent, pPdrs }: Props) => {
    const toast = useToast()

    const [productCode, setProductCode] = useState<string>(pcode);
    const [attributeDD, setAttributeDD] = useState<AttributeDropdown[][]>([]);
    const [attributes, setAttributes] = useState<AttributeType[]>([]);
    const [attributeItems, setAttributeItems] = useState<AttributeItemsType[]>([]);
    const [selectedAttributeItems, setSelectedAttributeItems] = useState<selectedAttributeItemsType[][]>([]);

    // const handlePrice = (value: string, id: string) => {
    //     let updated = [...cartesianTableData]
    //     let ind1 = updated?.findIndex(c => c.productCode === productCode)
    //     let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
    //     if (attIndex > -1) {
    //         updated[ind1].attribute[attIndex].Price = value
    //     }
    //     setCartesianTableData(updated)
    // }

    // const handleQty = (value: string, id: string) => {
    //     let updated = [...cartesianTableData]
    //     let ind1 = updated?.findIndex(c => c.productCode === productCode)
    //     let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
    //     if (attIndex > -1) {
    //         updated[ind1].attribute[attIndex].Qty = value
    //     }
    //     setCartesianTableData(updated)
    // }

    const handleQty = (value: string, id: string) => {

        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].qty = value

            // //OLD for taxable
            // updated[ind1].attribute[attIndex].taxable = (((value ? parseFloat(value) : 0) * (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0)) - (updated[ind1].attribute[attIndex].sdRs ? parseFloat(updated[ind1].attribute[attIndex].sdRs) : 0) - (updated[ind1].attribute[attIndex].pdRs ? parseFloat(updated[ind1].attribute[attIndex].pdRs) : 0) - (updated[ind1].attribute[attIndex].cdRs ? parseFloat(updated[ind1].attribute[attIndex].cdRs) : 0)).toString()
            //NEW for taxable
            updated[ind1].attribute[attIndex].taxable = ((value ? parseFloat(value) : 0) * (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0)).toString()

            //for igst,cgst,sgst
            let tax = ((value ? parseFloat(value) : 0) * (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0))

            if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                let gstPercent = (GSTPercent ? GSTPercent : 0) / 2

                updated[ind1].attribute[attIndex].cgst = (tax * gstPercent / 100).toString()
                updated[ind1].attribute[attIndex].sgst = (tax * gstPercent / 100).toString()
                updated[ind1].attribute[attIndex].igst = '0'

                //for netAMt
                updated[ind1].attribute[attIndex].netAmt = (tax
                    + (tax * gstPercent / 100)
                    + (tax * gstPercent / 100)).toString()
            } else {
                let gstPercent = (GSTPercent ? GSTPercent : 0)
                updated[ind1].attribute[attIndex].igst = (tax * gstPercent / 100).toString()
                updated[ind1].attribute[attIndex].cgst = '0'
                updated[ind1].attribute[attIndex].sgst = '0'
                //for netAMt
                updated[ind1].attribute[attIndex].netAmt = (tax + (tax * gstPercent / 100)).toString()
            }

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit = (((value ? parseFloat(value) : 0) + (updated[ind1].attribute[attIndex].free ? parseFloat(updated[ind1].attribute[attIndex].free) : 0)) * (updated[ind1].attribute[attIndex].sRate1 ? parseFloat(updated[ind1].attribute[attIndex].sRate1) : 0)) - (((value ? parseFloat(value) : 0) + (updated[ind1].attribute[attIndex].free ? parseFloat(updated[ind1].attribute[attIndex].free) : 0)) * (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0))
            updated[ind1].attribute[attIndex].totalProfit = tprofit.toString()
        }
        setCartesianTableData(updated)

    }
    const handleFree = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].free = value

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit = (((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) + (value ? parseFloat(value) : 0)) * (updated[ind1].attribute[attIndex].sRate1 ? parseFloat(updated[ind1].attribute[attIndex].sRate1) : 0)) - (((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) + (value ? parseFloat(value) : 0)) * (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0))
            updated[ind1].attribute[attIndex].totalProfit = tprofit.toString()
        }
        setCartesianTableData(updated)
    }
    const handlePurcahseRate = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].purchaseRate = value
            let pwog = (parseFloat(updated[ind1].attribute[attIndex].purchaseRate) - parseFloat(updated[ind1].attribute[attIndex].totDisAmt)).toString()
            handlePWOG(pwog, id)
        }
        setCartesianTableData(updated)
    }
    const handlePWOG = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].pwog = value

            //for taxable
            updated[ind1].attribute[attIndex].taxable = ((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) * (value ? parseFloat(value) : 0)).toString()
            //for igst
            let tax = ((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) * (value ? parseFloat(value) : 0))

            if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                let gstPercent = (GSTPercent ? GSTPercent : 0) / 2

                updated[ind1].attribute[attIndex].cgst = (tax * gstPercent / 100).toString()
                updated[ind1].attribute[attIndex].sgst = (tax * gstPercent / 100).toString()
                updated[ind1].attribute[attIndex].igst = '0'

                //for netAMt
                updated[ind1].attribute[attIndex].netAmt = (tax
                    + (tax * gstPercent / 100)
                    + (tax * gstPercent / 100)).toString()
            } else {
                let gstPercent = (GSTPercent ? GSTPercent : 0)
                updated[ind1].attribute[attIndex].igst = (tax * gstPercent / 100).toString()
                updated[ind1].attribute[attIndex].cgst = '0'
                updated[ind1].attribute[attIndex].sgst = '0'
                //for netAMt
                updated[ind1].attribute[attIndex].netAmt = (tax + (tax * gstPercent / 100)).toString()
            }
            //for pwg
            let pwg = (value ? parseFloat(value) : 0) + ((value ? parseFloat(value) : 0) * (GSTPercent ? GSTPercent : 0) / 100)
            updated[ind1].attribute[attIndex].pwg = pwg.toString()

            //for unitprofit
            let uprofit = (updated[ind1].attribute[attIndex].sRate1 ? parseFloat(updated[ind1].attribute[attIndex].sRate1) : 0) - (value ? parseFloat(value) : 0)
            updated[ind1].attribute[attIndex].unitProfit = uprofit.toString()

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit = (((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) + (updated[ind1].attribute[attIndex].free ? parseFloat(updated[ind1].attribute[attIndex].free) : 0)) * (updated[ind1].attribute[attIndex].sRate1 ? parseFloat(updated[ind1].attribute[attIndex].sRate1) : 0)) - (((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) + (updated[ind1].attribute[attIndex].free ? parseFloat(updated[ind1].attribute[attIndex].free) : 0)) * (value ? parseFloat(value) : 0))
            updated[ind1].attribute[attIndex].totalProfit = tprofit.toString()

            //for profit %
            let profitPercent = ((uprofit ? uprofit : 0) / (value ? parseFloat(value) : 0)) * 100
            updated[ind1].attribute[attIndex].profitPercent = profitPercent.toFixed(2)

        }
        setCartesianTableData(updated)
    }
    const handlePWG = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].pwg = value
        }
        setCartesianTableData(updated)
    }
    const handleSRate1 = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].sRate1 = value

            //for unitprofit
            let uprofit = (value ? parseFloat(value) : 0) - (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0)
            updated[ind1].attribute[attIndex].unitProfit = uprofit.toString()

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit = (((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) + (updated[ind1].attribute[attIndex].free ? parseFloat(updated[ind1].attribute[attIndex].free) : 0)) * (value ? parseFloat(value) : 0)) - (((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) + (updated[ind1].attribute[attIndex].free ? parseFloat(updated[ind1].attribute[attIndex].free) : 0)) * (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0))
            updated[ind1].attribute[attIndex].totalProfit = tprofit.toString()

            //for profit %
            let profitPercent = ((uprofit ? uprofit : 0) / (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0)) * 100
            updated[ind1].attribute[attIndex].profitPercent = profitPercent.toFixed(2)

        }

        setCartesianTableData(updated)
    }
    const handleSRate2 = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].sRate2 = value
        }
        setCartesianTableData(updated)
    }
    const handleSRate3 = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].sRate3 = value
        }
        setCartesianTableData(updated)
    }
    const handleMRP = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].mrp = value
        }
        setCartesianTableData(updated)
    }
    const handleSDRs = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].sdRs = value
            //NEW for total dis amt
            updated[ind1].attribute[attIndex].totDisAmt = (parseFloat(value) + parseFloat(updated[ind1].attribute[attIndex].pdRs) + parseFloat(updated[ind1].attribute[attIndex].cdRs)).toString()

            let pwog = (parseFloat(updated[ind1].attribute[attIndex].purchaseRate) - parseFloat(updated[ind1].attribute[attIndex].totDisAmt)).toString()
            handlePWOG(pwog, id)
            // //for taxable
            // updated[ind1].attribute[attIndex].taxable = (((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) * (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0)) - (value ? parseFloat(value) : 0) - (updated[ind1].attribute[attIndex].pdRs ? parseFloat(updated[ind1].attribute[attIndex].pdRs) : 0) - (updated[ind1].attribute[attIndex].cdRs ? parseFloat(updated[ind1].attribute[attIndex].cdRs) : 0)).toString()

            // let tax = (((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) * (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0)) - (value ? parseFloat(value) : 0) - (updated[ind1].attribute[attIndex].pdRs ? parseFloat(updated[ind1].attribute[attIndex].pdRs) : 0) - (updated[ind1].attribute[attIndex].cdRs ? parseFloat(updated[ind1].attribute[attIndex].cdRs) : 0))

            // if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
            //     let gstPercent = (GSTPercent ? GSTPercent : 0) / 2

            //     updated[ind1].attribute[attIndex].cgst = (tax * gstPercent / 100).toString()
            //     updated[ind1].attribute[attIndex].sgst = (tax * gstPercent / 100).toString()
            //     updated[ind1].attribute[attIndex].igst = '0'
            //     //for netAMt
            //     updated[ind1].attribute[attIndex].netAmt = (tax
            //         + (tax * gstPercent / 100)
            //         + (tax * gstPercent / 100)).toString()
            // } else {
            //     let gstPercent = (GSTPercent ? GSTPercent : 0)
            //     updated[ind1].attribute[attIndex].igst = (tax * gstPercent / 100).toString()
            //     updated[ind1].attribute[attIndex].cgst = '0'
            //     updated[ind1].attribute[attIndex].sgst = '0'
            //     //for netAMt
            //     updated[ind1].attribute[attIndex].netAmt = (tax + (tax * gstPercent / 100)).toString()
            // }
        }
        setCartesianTableData(updated)
    }
    const handlePDPercent = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].pdPercent = value

            if (updated[ind1].attribute[attIndex].purchaseRate !== '') {
                updated[ind1].attribute[attIndex].pdRs = (parseFloat(updated[ind1].attribute[attIndex].purchaseRate) * parseFloat(value) / 100).toString()

                let pdrs = (parseFloat(updated[ind1].attribute[attIndex].purchaseRate) * parseFloat(value) / 100).toString()
                //NEW for total dis amt
                updated[ind1].attribute[attIndex].totDisAmt = (parseFloat(pdrs) + parseFloat(updated[ind1].attribute[attIndex].sdRs) + parseFloat(updated[ind1].attribute[attIndex].cdRs)).toString()

                let pwog = (parseFloat(updated[ind1].attribute[attIndex].purchaseRate) - parseFloat(updated[ind1].attribute[attIndex].totDisAmt)).toString()
                handlePWOG(pwog, id)
                //for taxable
                // updated[ind1].attribute[attIndex].taxable = (((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) * (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0)) - (updated[ind1].attribute[attIndex].sdRs ? parseFloat(updated[ind1].attribute[attIndex].sdRs) : 0) - (pdrs ? parseFloat(pdrs) : 0) - (updated[ind1].attribute[attIndex].cdRs ? parseFloat(updated[ind1].attribute[attIndex].cdRs) : 0)).toString()
                // //for igst
                // let tax = (((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) * (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0)) - (updated[ind1].attribute[attIndex].sdRs ? parseFloat(updated[ind1].attribute[attIndex].sdRs) : 0) - (pdrs ? parseFloat(pdrs) : 0) - (updated[ind1].attribute[attIndex].cdRs ? parseFloat(updated[ind1].attribute[attIndex].cdRs) : 0))

                // if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                //     let gstPercent = (GSTPercent ? GSTPercent : 0) / 2

                //     updated[ind1].attribute[attIndex].cgst = (tax * gstPercent / 100).toString()
                //     updated[ind1].attribute[attIndex].sgst = (tax * gstPercent / 100).toString()
                //     updated[ind1].attribute[attIndex].igst = '0'

                //     //for netAMt
                //     updated[ind1].attribute[attIndex].netAmt = (tax
                //         + (tax * gstPercent / 100)
                //         + (tax * gstPercent / 100)).toString()
                // } else {
                //     let gstPercent = (GSTPercent ? GSTPercent : 0)
                //     updated[ind1].attribute[attIndex].igst = (tax * gstPercent / 100).toString()
                //     updated[ind1].attribute[attIndex].cgst = '0'
                //     updated[ind1].attribute[attIndex].sgst = '0'
                //     //for netAMt
                //     updated[ind1].attribute[attIndex].netAmt = (tax + (tax * gstPercent / 100)).toString()
                // }
            }
        }
        setCartesianTableData(updated)
    }
    const handlePDRs = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].pdRs = value
        }
        setCartesianTableData(updated)
    }
    const handleCDPercent = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].cdPercent = value
            if (updated[ind1].attribute[attIndex].purchaseRate !== '') {
                updated[ind1].attribute[attIndex].cdRs = (parseFloat(updated[ind1].attribute[attIndex].purchaseRate) * parseFloat(value) / 100).toString()

                let cdrs = (parseFloat(updated[ind1].attribute[attIndex].purchaseRate) * parseFloat(value) / 100).toString()
                //NEW for total dis amt
                updated[ind1].attribute[attIndex].totDisAmt = (parseFloat(cdrs) + parseFloat(updated[ind1].attribute[attIndex].sdRs) + parseFloat(updated[ind1].attribute[attIndex].pdRs)).toString()

                let pwog = (parseFloat(updated[ind1].attribute[attIndex].purchaseRate) - parseFloat(updated[ind1].attribute[attIndex].totDisAmt)).toString()
                handlePWOG(pwog, id)
                // //for taxable
                // updated[ind1].attribute[attIndex].taxable = (((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) * (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0)) - (updated[ind1].attribute[attIndex].sdRs ? parseFloat(updated[ind1].attribute[attIndex].sdRs) : 0) - (updated[ind1].attribute[attIndex].pdRs ? parseFloat(updated[ind1].attribute[attIndex].pdRs) : 0) - (cdrs ? parseFloat(cdrs) : 0)).toString()

                // //for igst
                // let tax = (((updated[ind1].attribute[attIndex].qty ? parseFloat(updated[ind1].attribute[attIndex].qty) : 0) * (updated[ind1].attribute[attIndex].pwog ? parseFloat(updated[ind1].attribute[attIndex].pwog) : 0)) - (updated[ind1].attribute[attIndex].sdRs ? parseFloat(updated[ind1].attribute[attIndex].sdRs) : 0) - (updated[ind1].attribute[attIndex].pdRs ? parseFloat(updated[ind1].attribute[attIndex].pdRs) : 0) - (cdrs ? parseFloat(cdrs) : 0))

                // if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                //     let gstPercent = (GSTPercent ? GSTPercent : 0) / 2

                //     updated[ind1].attribute[attIndex].cgst = (tax * gstPercent / 100).toString()
                //     updated[ind1].attribute[attIndex].sgst = (tax * gstPercent / 100).toString()
                //     updated[ind1].attribute[attIndex].igst = '0'

                //     //for netAMt
                //     updated[ind1].attribute[attIndex].netAmt = (tax
                //         + (tax * gstPercent / 100)
                //         + (tax * gstPercent / 100)).toString()
                // } else {
                //     let gstPercent = (GSTPercent ? GSTPercent : 0)
                //     updated[ind1].attribute[attIndex].igst = (tax * gstPercent / 100).toString()
                //     updated[ind1].attribute[attIndex].cgst = '0'
                //     updated[ind1].attribute[attIndex].sgst = '0'
                //     //for netAMt
                //     updated[ind1].attribute[attIndex].netAmt = (tax + (tax * gstPercent / 100)).toString()
                // }

            }
        }
        setCartesianTableData(updated)
    }
    const handleCDRs = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].cdRs = value
        }
        setCartesianTableData(updated)
    }
    const handleTaxable = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].taxable = value
        }
        setCartesianTableData(updated)
    }
    const handleIGST = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].igst = value
        }
        setCartesianTableData(updated)
    }
    const handleCGST = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].cgst = value
        }
        setCartesianTableData(updated)
    }
    const handleSGST = (value: string, id: string) => {
        let updated = [...cartesianTableData]
        let ind1 = updated?.findIndex(c => c.productCode === productCode)
        let attIndex = updated[ind1]?.attribute.findIndex(pi => pi.id.toString() === id)
        if (attIndex > -1) {
            updated[ind1].attribute[attIndex].sgst = value
        }
        setCartesianTableData(updated)
    }

    const selectedAttributeItemss = [
        [
            { id: 1, attributeId: 1, name: 'Red', code: 'RED' },
            { id: 2, attributeId: 1, name: 'Blue', code: 'BLU' },
            { id: 3, attributeId: 1, name: 'Yellow', code: 'YEL' },
        ],
        [
            { id: 4, attributeId: 2, name: 'Size 2', code: 'S02' },
            { id: 5, attributeId: 2, name: 'Size 12', code: 'S12' },
            { id: 6, attributeId: 2, name: 'Size 1', code: 'S01' }
        ],
        [
            { id: 7, attributeId: 3, name: 'Silk', code: 'SIL' },
            { id: 8, attributeId: 3, name: 'Polyster', code: 'POL' }
        ],
    ]

    const cartesian = (...selectedAttributeItems: any) => {
        if (selectedAttributeItems && selectedAttributeItems.length > 1) {
            console.log('1');
            return selectedAttributeItems?.reduce((a: any[], b: any[]) => a.flatMap((d: any) => b.map((e: any) => [d, e].flat())))
        } else if (selectedAttributeItems && selectedAttributeItems.length === 1) {
            // return selectedAttributeItems?.reduce((a: any[], b: any[]) => b.reduce((d: any, e: any) => [e]))
            console.log('2');
            return selectedAttributeItems[0]?.map((e: any) => [e])
        } else {
            console.log('3');
            toast('Select Attribute', ToastTypes.ERROR)
        }
    };

    // const getInitialTableData = () => {
    //     if (cartesianAttributes) {
    //         for (let i = 0; i < cartesianAttributes.length; i++) {
    //             // const element = array[i];

    //         }
    //     } else {
    //         return []
    //     }
    // }

    // const attributes = [
    //     { id: 1, name: 'Color' },
    //     { id: 2, name: 'Size' },
    //     { id: 3, name: 'Material' }
    // ]
    // const attributeItems = [
    //     { id: 1, attributeId: 1, name: 'Red', code: 'RED' },
    //     { id: 2, attributeId: 1, name: 'Blue', code: 'BLU' },
    //     { id: 3, attributeId: 1, name: 'Black', code: 'BLA' },
    //     { id: 4, attributeId: 2, name: 'Size 1', code: 'S01' },
    //     { id: 5, attributeId: 2, name: 'Size 2', code: 'S02' },
    //     { id: 6, attributeId: 2, name: 'Size 12', code: 'S12' },
    //     { id: 7, attributeId: 3, name: 'Cotton', code: 'COT' },
    //     { id: 8, attributeId: 3, name: 'Silk', code: 'SIL' },
    //     { id: 9, attributeId: 3, name: 'Polyster', code: 'POL' },
    // ]

    console.log(attributeDD);
    console.log(cartesianTableData);

    const handleClear = () => {
        let updated = [...cartesianTableData]
        let newArray = updated?.filter(c => c.productCode !== productCode)
        setCartesianTableData(newArray)
        onSave()
    }

    const handleSave = () => {
        let error = false
        let updated = [...cartesianTableData]
        let att = updated?.find(c => c.productCode === productCode)?.attribute

        const currentProductNetTaxable = att?.reduce((previous, current) => {
            return previous + parseFloat(current.taxable)
        }, 0)

        const currentProductNetAmt = att?.reduce((previous, current) => {
            return previous + parseFloat(current.netAmt)
        }, 0)
        const currentProductQty = att?.reduce((previous, current) => {
            return previous + parseFloat(current.qty)
        }, 0)
        const currentProductFreeQty = att?.reduce((previous, current) => {
            return previous + parseFloat(current.free)
        }, 0)

        if (currentProductNetTaxable !== pTotalTaxable) {
            toast('Mismatch in Tax Amt, Kindly Check ', ToastTypes.ERROR)
            error = true
        }
        if (currentProductNetAmt !== pNetAmt) {
            toast('Mismatch in Net Amt, Kindly Check ', ToastTypes.ERROR)
            error = true
        }
        if (currentProductQty !== pQty) {
            toast('Mismatch in Net Qty, Kindly Check ', ToastTypes.ERROR)
            error = true
        }
        if (currentProductFreeQty !== pFree) {
            toast('Mismatch in Net Free Qty, Kindly Check ', ToastTypes.ERROR)
            error = true
        }

        if (error === false) {
            onSave()
        }
    }

    const submitHandler = () => {
        const cartesianAttributes = cartesian(...attributeDD);
        // console.log(cartesianAttributes);
        if (cartesianAttributes) {

            const arrayObj: cartesianAttributes[] = cartesianAttributes?.map((arr: any) => {
                let igst = '0'
                let cgst = '0'
                let sgst = '0'
                let netAmt = '0'

                //for taxable
                let taxable = ((1 * (pPwog ? pPwog : 0)) - 0 - 0 - 0).toString()
                //for igst
                let tax = ((1 * (pPwog ? pPwog : 0)) - 0 - 0 - 0)

                if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                    let gstPercent = (GSTPercent ? GSTPercent : 0) / 2;

                    cgst = (tax * gstPercent / 100).toString()
                    sgst = (tax * gstPercent / 100).toString()
                    igst = '0'

                    //for netAMt
                    netAmt = (tax
                        + (tax * gstPercent / 100)
                        + (tax * gstPercent / 100)).toString()
                } else {
                    let gstPercent = (GSTPercent ? GSTPercent : 0);

                    igst = (tax * gstPercent / 100).toString()
                    cgst = '0'
                    sgst = '0'
                    //for netAMt
                    netAmt = (tax + (tax * gstPercent / 100)).toString()
                }
                //for pwg
                // let pwg = (value ? parseFloat(value) : 0) + ((value ? parseFloat(value) : 0) * (GSTPercent ? GSTPercent : 0) / 100)
                // updated[ind1].attribute[attIndex].pwg = pwg.toString()

                //for unitprofit
                let uprofit = (pWprice ? (pWprice) : 0) - (pPwog ? (pPwog) : 0)
                // unitProfit = uprofit.toString()

                //tot profit=(q+f*s1)-(q+f*pwog)
                let tprofit = ((1 + 0) * (pWprice ? (pWprice) : 0)) - ((1 + 0) * (pPwog ? (pPwog) : 0))
                // totalProfit = tprofit.toString()

                //for profit %
                let profitPercent = ((uprofit ? uprofit : 0) / (pPwog ? (pPwog) : 0)) * 100
                // updated[ind1].attribute[attIndex].profitPercent = profitPercent.toFixed(2)


                return {
                    id: nanoid(),
                    item: arr?.map((i: any) => i.name + " ").join(','),
                    itemCode: arr?.map((i: any) => i.code + " ").join(''),
                    productCode: productCode + '-' + arr?.map((i: any) => i.code).join(''),
                    productGroupCode: productCode,
                    // Price: '0',
                    // Qty: '0',
                    //
                    qty: '1',
                    free: '0',
                    purchaseRate: pPrate ? pPrate : '0',
                    pwog: pPwog ? pPwog : '0',
                    pwg: pPwg ? pPwg : '0',
                    freightCost: pFcost ? pFcost : '0',
                    landingCost: pLcost ? pLcost : '0',
                    marginPercent: pMarginPercent ? pMarginPercent : '0',
                    marginAmt: pMarginAmt ? pMarginAmt : '0',
                    salesRate: pSalesRate ? pSalesRate : '0',
                    sRate1: pWprice ? pWprice : '0',
                    sRate2: pRprice ? pRprice : '0',
                    sRate3: pUprice ? pUprice : '0',
                    mrp: pMrp ? pMrp : '0',
                    sdRs: pSdrs ? pSdrs : '0',
                    pdPercent: pPdPercent ? pPdPercent : '0',
                    pdRs: pPdrs ? pPdrs : '0',
                    cdPercent: pCdPercent ? pCdPercent : '0',
                    cdRs: pCdrs ? pCdrs : '0',
                    totDisAmt: "0",
                    taxable: taxable ? taxable : '0',
                    igst: igst ? igst : '0',
                    cgst: cgst ? cgst : '0',
                    sgst: sgst ? sgst : '0',
                    netAmt: netAmt ? netAmt : '0',
                    unitProfit: uprofit ? uprofit : '0',
                    totalProfit: tprofit ? tprofit : '0',
                    profitPercent: profitPercent ? profitPercent : '0',
                }
            })
            console.log(arrayObj);
            setCartesianTableData(prev => {
                if (cartesianTableData.find(t => t.productCode === productCode)) {
                    let updated = [...cartesianTableData]
                    let ind1 = updated?.findIndex(c => c.productCode === productCode)
                    if (ind1 > -1) {
                        updated[ind1].attribute = arrayObj
                    }
                    return updated
                } else {
                    return [...prev, { productCode: productCode, attribute: arrayObj }]
                }
            })
        }
    }

    useEffect(() => {
        (async () => {
            const response = await getAttributes()
            const data: AttributeType[] = response.data.data
            console.log(data);
            setAttributes(data)
        })()
    }, [])

    useEffect(() => {
        (async () => {
            const response = await getAttributesItems()
            const data: AttributeItemsType[] = response.data.data
            console.log(data);
            setAttributeItems(data)
        })()
    }, [])

    console.log("Hello World " + parseFloat(pNetAmt.toFixed(2)))
    return (
        <>
            <div className="row mb-2">
                <div className="col-lg-3 hstack gap-1">
                    <label>Product Code:</label>
                    {/* style={{ marginLeft: "50px" }} */}
                    <input className="w-50" type="text" value={productCode} onChange={({ target }) => setProductCode(target.value)} readOnly={true} />
                </div>
                <div className="col-lg-2 col-md-2 mb-2">
                    <span className=" fs-6 badge bg-warning text-dark">Total QTY: {pQty}</span>
                </div>
                <div className="col-lg-2 col-md-2 mb-2">
                    <span className=" fs-6 badge bg-warning text-dark">Total Free: {pFree}</span>
                </div>
                <div className="col-lg-2 col-md-2 hstack gap-1 mb-2">
                    <span className=" fs-6 badge bg-warning text-dark">NET TAX: {pTotalTaxable}</span>
                </div>
                <div className="col-lg-2 col-md-2 hstack gap-1 mb-2">
                    <span className=" fs-6 badge bg-warning text-dark">NET AMT: {pNetAmt}</span>
                </div>
            </div>

            <div className="col-12 hstack gap-1 ms-1">
                {attributes.map((attribute, ai) => {
                    const handleAtrributeDropDown = (value: any) => {
                        console.log(value);
                        if (value?.length !== 0) {
                            if (!attributeDD.some(item => {
                                return item?.some(obj => {
                                    return obj?.attributeId === attribute.id
                                })
                            })) {
                                setAttributeDD(prev => [...prev, value])
                            } else {
                                setAttributeDD(attributeDD.map(item => {
                                    return attribute.id === item[0]?.attributeId ? value : item
                                }))
                            }
                        } else {
                            setAttributeDD(attributeDD.filter(item => attribute.id !== item[0]?.attributeId))
                        }
                    }
                    return (

                        <React.Fragment key={attribute.id}>
                            <div className=" col-lg-3 hstack gap-1">
                                <label className="text-capitalize">{attribute.name}:</label>
                                {/* <div className='col-sm-6 col-lg-3 mb-1'> */}
                                <MultiSelect
                                    options={attributeItems?.filter((item) => item.attributeId === attribute.id)?.map((it, i) => {
                                        return {
                                            id: it.id,
                                            attributeId: attribute.id,
                                            value: it.id ? it.id : 0,
                                            label: (it.name) || '',
                                            name: (it.name) || '',
                                            code: it.code ? it.code.toString() : '',
                                        }
                                    })}
                                    label='' selectedOptions={attributeDD.filter(f => f[0]?.attributeId === attribute.id)[0]} setSelectedOptions={handleAtrributeDropDown} placeHolder='Select' />
                                {/* <Select
                                    options={attributeItems?.filter((item) => item.attributeId === attribute.id)?.map((it, i) => {
                                        return {
                                            id: ai,
                                            label: (it.name) || '',
                                            value: it.id?.toString() || '',
                                        }
                                    })}
                                    placeholder={false}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(val) => handleAttribute(val, ai.toString())}
                                    value={attributeDD}
                                    styles={customStyles}
                                /> */}
                            </div>
                            {/* ///////// */}
                            {/* <label style={{ marginLeft: "50px" }}>{attribute.name}:</label>
                        <select>
                            {attributeItems.filter((item) => item.attributeId === attribute.id).map((item: any, index) => {
                                return (
                                    <option value={item.value} key={index}>
                                        {item.name}
                                    </option>
                                )
                            })
                            }
                        </select> */}
                        </React.Fragment>
                    )
                })
                }
            </div>
            <div className="text-end">
                <button className="btn btn-sm fw-bold btn-secondary" style={{ marginLeft: "50px" }} onClick={submitHandler}>Populate</button>
            </div>
            <hr />
            {attributes?.map(
                (attribute) => {
                    return (
                        <>
                            <label style={{ marginLeft: "50px" }}>{'Selected ' + attribute.name + "s: " + (attributeDD.flat(1).filter((item) => item.attributeId === attribute.id).map((item) => item?.label))}</label>
                        </>
                    )
                })
            }

            {/* <br /><br /> */}
            <div className="card shadow-lg p-1">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className="table" >
                            {/* border={1} style={{ marginLeft: "50px" }} */}
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>Item</th>
                                    <th className='text-truncate align-middle'>Item Code</th>
                                    <th className='text-truncate align-middle'>Product Code</th>
                                    <th className='text-truncate align-middle'>QTY</th>
                                    <th className='text-truncate align-middle'>Free</th>
                                    <th className='text-truncate align-middle'>P.Rate</th>
                                    <th className='text-truncate align-middle'>SD Rs</th>
                                    <th className='text-truncate align-middle'>PD%</th>
                                    <th className='text-truncate align-middle'>PD Rs</th>
                                    <th className='text-truncate align-middle'>CD%</th>
                                    <th className='text-truncate align-middle'>CD Rs</th>
                                    <th className='text-truncate align-middle'>Tot.Dis.Amt</th>
                                    <th className='text-truncate align-middle'>PWOG</th>
                                    <th className='text-truncate align-middle'>PWG</th>
                                    <th className='text-truncate align-middle'>Wholesale</th>
                                    <th className='text-truncate align-middle'>Retail</th>
                                    <th className='text-truncate align-middle'>U.Rate</th>
                                    <th className='text-truncate align-middle'>MRP</th>

                                    <th className='text-truncate align-middle'>Taxable</th>
                                    <th className='text-truncate align-middle'>IGST</th>
                                    <th className='text-truncate align-middle'>CGST</th>
                                    <th className='text-truncate align-middle'>SGST</th>
                                    <th className='text-truncate align-middle'>Net Amt</th>
                                    <th className='text-truncate align-middle'>Unit Profit</th>
                                    <th className='text-truncate align-middle'>Total Profit</th>
                                    <th className='text-truncate align-middle'>Profit %</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {cartesianAttributes?.map((item: any) => {
                                    return (
                                        <tr>
                                            <td><label>{item.map((i: any) => i.name + " ")} </label></td>
                                            <td><label>{item.map((i: any) => i.code + " ")} </label></td>
                                            <td><label>{productCode + item.map((i: any) => i.id).join('')}</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='Price'
                                                    label=''
                                                    labelClassName="required"
                                                    // value={ }
                                                    // onChange={(e) => handleSDRs(e.target.value, pi.id.toString())}
                                                    containerClass=""
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='Quantity'
                                                    label=''
                                                    labelClassName="required"
                                                    // value={ }
                                                    // onChange={(e) => handleSDRs(e.target.value, pi.id.toString())}
                                                    containerClass=""
                                                    type="number"
                                                />
                                            </td>
                                            {/* <td><input type="text" />{ }</td> */}
                                {/* </tr>
                                    )
                                })
                                } */}
                                {cartesianTableData?.find(c => c.productCode === productCode)?.attribute.map((item) => {
                                    return (
                                        <tr>
                                            <td className="text-truncate"><label>{item.item} </label></td>
                                            <td className="text-truncate"><label>{item.itemCode} </label></td>
                                            <td className="text-truncate"><label>{item.productCode}</label></td>
                                            {/* <td><input type="text" />{ }</td> */}
                                            <td className="p-0">
                                                <FormInput
                                                    name='qty'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.qty}
                                                    onChange={(e) => handleQty(e.target.value, item.id.toString())}
                                                    containerClass=""
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='free'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.free}
                                                    onChange={(e) => handleFree(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='purchase rate'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.purchaseRate}
                                                    onChange={(e) => handlePurcahseRate(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='SDRs'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.sdRs}
                                                    onChange={(e) => handleSDRs(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='pdPercent'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.pdPercent}
                                                    onChange={(e) => handlePDPercent(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='pdRs'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.pdRs}
                                                    onChange={(e) => handlePDRs(e.target.value, item.id.toString())}
                                                    containerClass=" read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='cdPercent'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.cdPercent}
                                                    onChange={(e) => handleCDPercent(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='cdRs'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.cdRs}
                                                    onChange={(e) => handleCDRs(e.target.value, item.id.toString())}
                                                    containerClass=" read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='total dis amt'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.totDisAmt}
                                                    onChange={(e) => handleCDRs(e.target.value, item.id.toString())}
                                                    containerClass=" read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='pwog'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.pwog}
                                                    onChange={(e) => handlePWOG(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='pwg'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.pwg}
                                                    onChange={(e) => handlePWG(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='sRate1'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.sRate1}
                                                    onChange={(e) => handleSRate1(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='sRate2'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.sRate2}
                                                    onChange={(e) => handleSRate2(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='sRate3'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.sRate3}
                                                    onChange={(e) => handleSRate3(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='MRP'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.mrp}
                                                    onChange={(e) => handleMRP(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                    type="number"
                                                />
                                            </td>

                                            <td className="p-0">
                                                <FormInput
                                                    name='taxable'
                                                    label=''
                                                    labelClassName="required"
                                                    value={parseFloat(item.taxable).toFixed(2)}
                                                    onChange={(e) => handleTaxable(e.target.value, item.id.toString())}
                                                    containerClass=" read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='igst'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.igst}
                                                    onChange={(e) => handleIGST(e.target.value, item.id.toString())}
                                                    containerClass="w-100 read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='cgst'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.cgst}
                                                    onChange={(e) => handleCGST(e.target.value, item.id.toString())}
                                                    containerClass="w-100 read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='sgst'
                                                    label=''
                                                    labelClassName="required"
                                                    value={item.sgst}
                                                    onChange={(e) => handleSGST(e.target.value, item.id.toString())}
                                                    containerClass="w-100 read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='netAmt'
                                                    label=''
                                                    labelClassName="required"
                                                    value={parseFloat(item.netAmt).toFixed(2)}
                                                    // onChange={(e) => handleNetAmt(e.target.value, item.id.toString())}
                                                    containerClass="w-100 read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='unitProfit'
                                                    label=''
                                                    labelClassName="required"
                                                    value={parseFloat(item.unitProfit).toFixed(2)}
                                                    // onChange={(e) => handleUnitProfit(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='totalProfit'
                                                    label=''
                                                    labelClassName="required"
                                                    value={parseFloat(item.totalProfit).toFixed(2)}
                                                    // onChange={(e) => handleTotalProfit(e.target.value, item.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='profitPercent'
                                                    label=''
                                                    labelClassName="required"
                                                    value={parseFloat(item.profitPercent)}
                                                    // onChange={(e) => handleProfitPercent(e.target.value, pi.id.toString())}
                                                    containerClass="read-only-field"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-end hstack gap-1 mt-1">
                        <button className="btn btn-danger" onClick={handleClear}>Delete</button>
                        <button className="btn btn-primary" onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupProductsReturn;