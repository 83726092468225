import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { BranchType, addBranchAction, deleteBranchAction, editBranchAction, fetchBranchAction } from "../redux/actions/branchAction"
import { AppDispatch, StoreState } from "../redux/store"

interface TableBody {
    sno: number
    name: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: BranchType) => void
type HandleDelete = (value: BranchType) => void



const BranchMaster = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const branchList = useSelector<StoreState, BranchType[]>(state => state.branch)


    useEffect(() => {
        dispatch(fetchBranchAction())
    }, [dispatch])

    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)

    const [loginid, setLoginid] = useState<string>('')
    const [loginidError, setLoginidError] = useState<string>('')
    const [editData, setEditData] = useState<BranchType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [id, setId] = useState<number>()
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')


    const handleLoginid: ChangeEventHandler<HTMLInputElement> = (e) => {
        setLoginid(e.target.value)
        setLoginidError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }



    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)

    }

    const handleDelete: HandleDelete = (value) => {
        // setFormType(FormTypeList.DELETE)
        setDeleteId(value.id)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const resetStates = () => {
        setLoginid('')
        setLoginidError('')
        setName('')
        setNameError('')
        setEditData(null)
    }

    const handleSave = () => {
        let error = false
        const addData: BranchType = {
            id: id,
            name: name,
        }

        const editData: BranchType = {
            id: id,
            name: name,
        }

        if (addData.name === '') {
            setNameError('Branch name required')
            error = true
        } else if ((addData.name || "").length > 30) {
            setNameError('Branch name should be within 30 characters')
            error = true
        }



        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addBranchAction(addData)).then(text => {
                    toast('Added', ToastTypes.SUCCESS)
                    resetStates()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== null) {
                dispatch(editBranchAction(editData, editData.id)).then(text => {
                    toast('Updated', ToastTypes.SUCCESS)
                    resetStates()
                    setEditData(null)
                    setFormType(FormTypeList.ADD)
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {
            setId(editData.id)
            setName(editData.name || "")

        } else {
            resetStates()
        }
    }, [formType, editData])


    const onDelete = () => {
        if (deleteId) {
            dispatch(deleteBranchAction(deleteId)).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
            }).catch(text => {

            })
        }
    }
    return <>
        <Modal
            headerText={'Delete User'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <DeleteConfirmationForm
                onDelete={onDelete}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <UserLayout>
            <h4>Branch Master</h4>
            <div className="container">
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        {/* <div className="card"> */}
                        <div className='hstack justify-content-center gap-3'>
                            <label htmlFor="" className="mb-2">Name</label>
                            <FormInput
                                name='name'
                                labelClassName="required"
                                value={name}
                                onChange={handleName}
                                errorText={nameError}
                                containerClass="mb-2"
                            />
                            {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) &&
                                <>
                                    < button className="btn btn-sm fw-bold btn-secondary mb-2" onClick={() => resetStates()}>Add New</button>
                                    <button className="btn btn-sm fw-bold btn-success mb-2" onClick={handleSave}>{editData ? "Update" : "Save"}</button>
                                </>
                            }

                        </div>
                        {/* </div> */}
                    </div>
                    <div className="col-3"></div>
                </div>
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <div className="card mb-0 p-1">
                            {/* <div className="card-body"> */}
                            <div className='table-wrapper'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className='text-truncate align-middle'>S.No</th>
                                            <th className='text-truncate align-middle'>Name</th>
                                            <th className='text-truncate align-middle'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {branchList.map((tr, i) => {
                                            return <UserBody
                                                sno={i + 1}
                                                name={tr.name || ""}
                                                onEdit={() => handleEdit(tr)}
                                                onDelete={() => handleDelete(tr)}

                                            />
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
            </div>
        </UserLayout >
    </>
}

const UserBody = ({ sno, name, onEdit, onDelete }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{name}</td>
        <td className="align-middle">
            <div className='hstack justify-content-center gap-1'>
                <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>
                <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>
            </div>
        </td>
    </tr>
}
export default BranchMaster