import api from "../config/api"

export const addShop = async (data: FormData) => {
    return await api().post('/shop/', data)
}

export const editShop = async (data: FormData, id: number | undefined) => {
    // return await api().put(`/shop/${id}`, data)
    return await api().post(`/shop/?id=${id}`, data)
}

export const getShop = async () => {
    return await api().get('/shop/')
}

export const deleteShop = async (id: number | undefined) => {
    return await api().delete(`/shop/?id=${id}`)
    // return await api().delete(`/shop/${id}`)
}