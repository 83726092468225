import { nanoid } from "@reduxjs/toolkit";
import { MouseEventHandler, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from 'react-select';
import Modal from "../components/Modal";
import UserLayout from "../components/custom/layouts/UserLayout";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import api from "../config/api";
import { LocalFormatDate } from "../config/functions";
import { AccountHolderType } from "../redux/actions/accountHolder";
import { ProductType } from "../redux/actions/productAction";
import { PurchaseType } from "../redux/actions/purchaseActions";
import { StoreState } from "../redux/store";
import GroupProducts from "./GroupProducts";
import { EditPurchaseAttributeType, PurchaseAttribute, PurchaseItemEditAttributes } from "./PurchaseMaster";

interface Dropdown {
    label: string
    value: string
}

interface TableBody {
    sno: number
    id: number | undefined
    invoiceDate: string
    invoiceNo: string
    purchaseEntryNo: string
    supplier: string
    finalAmount: string
    onGetPurchaseItemsClick: MouseEventHandler<HTMLButtonElement>
}

interface PurchaseItem {
    id: number,
    purchase_id: number
    entry_number: number
    product_id: number
    batch_number: number
    expiry_date: string
    quantity: number
    free_quantity: number
    totalQuantity: number
    purchase_rate: number
    price_wogst: number
    price_gst: number
    freight_cost: number
    landing_cost: number
    margin_percent: number
    margin_amt: number
    sales_rate: number
    w_rate: number
    r_rate: number
    l_rate: number
    mrp: number
    batch: number,
    uRate: number,
    s_discount: number
    p_discount_percent: number
    p_discount_amount: number
    c_discount_percent: number
    c_discount_amount: number
    taxable_amount: number
    igst_amount: number
    cgst_amount: number
    sgst_amount: number
    total_amount: number
    unit_cost: number
    profit_percent: number
    profit_amount: number
    productCode?: number
}

interface EditData {
    supplierId: number,
    PurchaseItems: PurchaseItemEditAttributes[]
    PurchaseAttributes?: EditPurchaseAttributeType[]
}

const PurchaseUpdateAttribute = () => {
    const toast = useToast()
    const ProductList = useSelector<StoreState, ProductType[]>(state => state.product);
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(state => state.account);
    const [product, setProduct] = useState<Dropdown | null>(null);
    const [filteredPurchaseList, setFilteredPurchaseList] = useState<PurchaseType[]>([]);
    const [showPurchaseItems, setShowPurchaseItems] = useState(false);
    const [editData, setEditData] = useState<EditData | null>(null);
    const [filteredPurchaseItemList, setFilteredPurchaseItemList] = useState<PurchaseItem[]>([]);
    const [cartesianTableData, setCartesianTableData] = useState<PurchaseAttribute[]>([]);
    const [showAttributeModal, setShowAttributeModal] = useState<boolean>(false)
    const [supPlace, setSupplyPlace] = useState<string>('')
    const [purchaseId, setPurchaseId] = useState<number>(0)

    //variables to check attribute modal totals
    const [pcode, setPcode] = useState<string>('')
    const [pgstPercent, setPgstPercent] = useState<number>(0)
    const [pprate, setPPRate] = useState<number>(0)
    const [pFcost, setPFcost] = useState<number>(0)
    const [pLcost, setPLcost] = useState<number>(0)
    const [pMarginPercent, setPMarginPercent] = useState<number>(0)
    const [pMarginAmt, setPMarginAmt] = useState<number>(0)
    const [pSalesRate, setPSalesRate] = useState<number>(0)
    const [ppwg, setPPwg] = useState<number>(0)
    const [ppwog, setPPwog] = useState<number>(0)
    const [pwPrice, setPWPrice] = useState<number>(0)
    const [prPrice, setPRPrice] = useState<number>(0)
    const [puPrice, setPUPrice] = useState<number>(0)
    const [pmrp, setPMrp] = useState<number>(0)
    const [pIgst, setPIgst] = useState<number>(0)
    const [pCgst, setPCgst] = useState<number>(0)
    const [pSgst, setPSgst] = useState<number>(0)
    const [pTax, setPTax] = useState<number>(0)
    const [pAmt, setPAmt] = useState<number>(0)
    const [pUprofit, setPUprofit] = useState<number>(0)
    const [pTprofit, setPTprofit] = useState<number>(0)
    const [pProfitPercent, setPProfitPercent] = useState<number>(0)
    const [pQty, setPQty] = useState<number>(0)
    const [pFree, setPFree] = useState<number>(0)
    const [pNetAmt, setPNetAmt] = useState<number>(0)
    const [pTotalProfit, setPTotalProfit] = useState<number>(0)
    const [pTotalTaxable, setPTotalTaxable] = useState<number>(0)

    const resetStates = () => {
        setProduct(null)
        setFilteredPurchaseList([])
        setShowPurchaseItems(false)
        setEditData(null)
        setFilteredPurchaseItemList([])
        setCartesianTableData([])
        setShowAttributeModal(false)
        setSupplyPlace('')
    }

    const handleAssignEditAttributes = (data: EditData) => {
        // *****attribute table*****
        const attributeArray = data.PurchaseAttributes
        let cdata: PurchaseAttribute[] = []

        data.PurchaseItems.forEach((pItem, pindex) => {
            const prodObj = ProductList.find(pd => pd.id?.toString() === pItem.product_id.toString())
            let darray = attributeArray?.filter(f => f.product_group_code?.toString() === prodObj?.productCode?.toString())
            cdata.push({
                productCode: prodObj?.productCode ? prodObj?.productCode?.toString() : '',
                attribute: darray && darray?.length > 0 ? darray?.map(d => {

                    let arr = [d.attribute1, d.attribute2, d.attribute3, d.attribute4, d.attribute5]
                    let name = '';
                    arr.forEach(a => {
                        if (a !== '') {
                            name = name + a
                        }
                    })
                    return {
                        id: nanoid(),
                        item: name,
                        itemCode: d.item_code,
                        productCode: d.product_code,
                        productGroupCode: d.product_group_code,
                        qty: d.quantity?.toString(),
                        free: d.free_quantity?.toString(),
                        purchaseRate: d.purchase_rate?.toString(),
                        pwog: d.price_wogst?.toString(),
                        pwg: d.price_gst?.toString(),
                        freightCost: d.freight_cost?.toString(),
                        landingCost: d.landing_cost?.toString(),
                        marginPercent: d.margin_percent?.toString(),
                        marginAmt: d.margin_amt?.toString(),
                        salesRate: d.sales_rate?.toString(),
                        sRate1: d.w_rate?.toString(),
                        sRate2: d.r_rate?.toString(),
                        sRate3: d.l_rate?.toString(),
                        mrp: d.mrp?.toString(),
                        sdRs: d.s_discount?.toString(),
                        pdPercent: d.p_discount_percent?.toString(),
                        pdRs: d.p_discount_amount?.toString(),
                        cdPercent: d.c_discount_percent?.toString(),
                        cdRs: d.c_discount_amount?.toString(),
                        totDisAmt: (d.s_discount + d.p_discount_amount + d.c_discount_amount)?.toString(),
                        taxable: d.taxable_amount?.toString(),
                        igst: d.igst_amount?.toString(),
                        cgst: d.cgst_amount?.toString(),
                        sgst: d.sgst_amount?.toString(),
                        netAmt: d.total_amount?.toString(),
                        unitProfit: d.unit_profit?.toString(),
                        totalProfit: d.profit_amount?.toString(),
                        profitPercent: d.profit_percent?.toString(),
                    }
                }) : []
            })
        })
        setCartesianTableData(cdata)
        //
    }

    const handleAttributeClick = (id: string) => {
        let error = false

        if (filteredPurchaseItemList) {
            let updated = [...filteredPurchaseItemList]
            let ind = updated.findIndex(pi => pi.id.toString() === id)
            const prodObj = ProductList.find(pd => pd.id?.toString() === updated[ind].product_id.toString())


            if (ind > -1) {
                setPcode(prodObj ? prodObj.productCode! : '')
                setPgstPercent(prodObj ? parseInt(prodObj.gst!) : 0)
                setPQty((updated[ind].quantity))
                setPFree((updated[ind].free_quantity))
                setPNetAmt((updated[ind].total_amount))
                setPTotalTaxable((updated[ind].taxable_amount))
                setPTotalProfit((updated[ind].total_amount))
                setPPwg((updated[ind].price_gst))
                setPPwog((updated[ind].price_wogst))
                setPWPrice((updated[ind].w_rate))
                setPRPrice((updated[ind].l_rate))
                setPUPrice((updated[ind].uRate))
                //new
                setPPRate((updated[ind].purchase_rate))
                setPFcost((updated[ind].freight_cost))
                setPLcost((updated[ind].landing_cost))
                setPMarginPercent((updated[ind].margin_percent))
                setPMarginAmt((updated[ind].margin_amt))
                setPSalesRate((updated[ind].sales_rate))
                //
                setPMrp((updated[ind].mrp))
                setPIgst((updated[ind].igst_amount))
                setPCgst((updated[ind].cgst_amount))
                setPSgst((updated[ind].sgst_amount))
                setPTax((updated[ind].taxable_amount))
                setPAmt((updated[ind].total_amount))
                setPUprofit((updated[ind].unit_cost))
                setPTprofit((updated[ind].profit_amount))
                setPProfitPercent((updated[ind].profit_percent))

            }
            if (prodObj && prodObj.productCode !== '' &&
                prodObj.gst !== '' &&
                updated[ind].quantity.toString() !== '' &&
                updated[ind].free_quantity.toString() !== '' &&
                updated[ind].taxable_amount?.toString() !== '' &&
                updated[ind].total_amount?.toString() !== '') {


                if (supPlace === '') {
                    toast('Please select supply place', ToastTypes.ERROR)
                    error = true
                }
                if (prodObj?.productCode === '') {
                    toast('Please select product code', ToastTypes.ERROR)
                    error = true
                }
                if (prodObj?.gst === '') {
                    toast('Please select gst', ToastTypes.ERROR)
                    error = true
                }
                if (updated[ind].quantity.toString() === '') {
                    toast('Please fill product qty', ToastTypes.ERROR)
                    error = true
                }
                if (updated[ind].free_quantity.toString() === '') {
                    toast('Please fill product free qty', ToastTypes.ERROR)
                    error = true
                }
                if (updated[ind].taxable_amount.toString() === '') {
                    toast('Please fill product taxable', ToastTypes.ERROR)
                    error = true
                }
                if (updated[ind].total_amount.toString() === '') {
                    toast('Please fill product netAmt', ToastTypes.ERROR)
                    error = true
                }


                if (error === false) {
                    setShowAttributeModal(true)
                }
            } else toast('Please Fill product details', ToastTypes.ERROR)
            // setPurchaseItems(updated)}
        }
    }

    const getPurchaseItemUsingPurchaseId = (id: number | undefined) => {
        if (id) {
            api().get<{ data: EditData }>(`/purchase/?purchaseId=${id}`)
                .then(response => {
                    if (response.status === 200 && response.data.data) {
                        const result = response.data.data
                        setEditData(result)
                        console.log("Purchase items:", result.PurchaseItems);
                        setFilteredPurchaseItemList(result.PurchaseItems);
                        setShowPurchaseItems(true);
                        handleAssignEditAttributes(result)
                        const sobj = AccountLists.find(a => a.accountNumber?.toString() === result?.supplierId?.toString())
                        console.log(sobj);
                        let supPlace = sobj?.supplierPlace
                        setSupplyPlace(supPlace ? supPlace : '')
                        setPurchaseId(id)
                    }
                })
                .catch(error => {
                    console.error("Error fetching purchase items:", error);
                });
        }
    };

    const handleSave = () => {
        if (purchaseId) {
            const data = {
                stockItems: cartesianTableData,
                purchaseItems: editData?.PurchaseItems
            }
            api().put(`/purchaseAttributes/updateAttributesWithStock/?purchaseId=${purchaseId}`, data)
                .then(response => {
                    if (response.status === 200 && response.data.data) {
                        const result = response.data.data
                        toast(response.data.message, ToastTypes.SUCCESS)
                        resetStates()
                    }
                })
                .catch(error => {
                    console.error("Error fetching purchase items:", error);
                    toast(error.response.data.error, ToastTypes.ERROR)
                });
        }
    }

    useEffect(() => {
        if (product) {
            api().get(`/purchase/?productId=${product.value}`)
                .then(response => {
                    if (response.status === 200 && response.data.data) {
                        console.log("API Response:", response.data);
                        setFilteredPurchaseList(response.data.data);
                    }
                })
                .catch(error => {
                    console.error("Error fetching purchase data:", error);
                });
        }
    }, [product]);

    const handleFormClose = () => {
        setShowAttributeModal(false)
    }
    console.log("Filtered Purchase List:", filteredPurchaseList);
    console.log(supPlace);

    return (<>
        <Modal
            headerText="Attribute"
            onClose={handleFormClose}
            centered
            size="xl"
            visible={showAttributeModal}
        >
            <GroupProducts
                pPrate={pprate}
                pFcost={pFcost}
                pLcost={pLcost}
                pMarginAmt={pMarginAmt}
                pMarginPercent={pMarginPercent}
                pSalesRate={pSalesRate}
                pcode={pcode}
                pPwg={ppwg}
                pPwog={ppwog}
                pWprice={pwPrice}
                pRprice={prPrice}
                pUprice={puPrice}
                pMrp={pmrp}
                pIgst={pIgst}
                pCgst={pCgst}
                pSgst={pSgst}
                pTax={pTax}
                pAmt={pAmt}
                pUprofit={pUprofit}
                pTprofit={pTprofit}
                pProfitPercent={pProfitPercent}
                cartesianTableData={cartesianTableData}
                setCartesianTableData={setCartesianTableData}
                supPlace={supPlace}
                GSTPercent={pgstPercent}
                pFree={pFree}
                pNetAmt={pNetAmt}
                pQty={pQty}
                pTotalTaxable={pTotalTaxable}
                pTotalProfit={pTotalProfit}
                onSave={handleFormClose}
            />
        </Modal>
        <UserLayout>
            <h4>Purchase Attribute</h4>
            <div className="row">
                <div className="container w-2 mt-2 mb-2">
                    <div style={{ marginBottom: "10px", padding: "10px", border: "1px solid #ccc", backgroundColor: "#f9f9f9", borderRadius: "5px" }}>
                        <label className="fs-4">Product Code:</label>
                        <Select
                            options={ProductList?.map(p => ({
                                label: (p?.productCode) || '',
                                value: p.id?.toString() || ''
                            }))}
                            placeholder="Select a product"
                            isSearchable={true}
                            isClearable={true}
                            onChange={(val) => setProduct(val)}
                            value={product}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card shadow-lg p-2">
                        <div className='table-wrapper' style={{ zIndex: "0" }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        <th className='text-truncate align-middle'>Purchase Entry No</th>
                                        <th className='text-truncate align-middle'>Invoice Date</th>
                                        <th className='text-truncate align-middle'>Invoice No</th>
                                        <th className='text-truncate align-middle'>Supplier</th>
                                        <th className='text-truncate align-middle'>Grand Total</th>
                                        <th className='text-truncate align-middle'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredPurchaseList.map((tr, i) => {
                                        let sobj = AccountLists.find(s => s?.accountNumber === tr?.supplierId)
                                        let sname = sobj?.accountName ? sobj?.accountName : ''
                                        return (
                                            <UserBody
                                                sno={i + 1}
                                                invoiceNo={tr.invoiceNumber?.toString()}
                                                invoiceDate={tr.invoiceDate?.toString()}
                                                purchaseEntryNo={tr.purchaseEntryNumber?.toString()}
                                                finalAmount={tr.grandTotal ? tr.grandTotal?.toString() : '0'}
                                                supplier={sname}
                                                id={tr.id}
                                                onGetPurchaseItemsClick={() => getPurchaseItemUsingPurchaseId(tr.id)}
                                                key={i}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {showPurchaseItems && <div className="row">
                <div className="col-lg-12">
                    <div className="card shadow-lg p-2">
                        <div className='table-wrapper' style={{ zIndex: "0" }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        <th className='text-truncate align-middle'>Product Name</th>
                                        <th className='text-truncate align-middle'>Product Code</th>
                                        <th className='text-truncate align-middle'>Batch</th>
                                        <th className='text-truncate align-middle'>Quantity</th>
                                        <th className='text-truncate align-middle'>Purchase Rate</th>
                                        <th className='text-truncate align-middle'>PWOG</th>
                                        <th className='text-truncate align-middle'>PWG</th>
                                        <th className='text-truncate align-middle'>Sales Rate</th>
                                        <th className='text-truncate align-middle'>Wholesale Rate</th>
                                        <th className='text-truncate align-middle'>Retail Rate</th>
                                        <th className='text-truncate align-middle'>Unit Rate</th>
                                        <th className='text-truncate align-middle'>MRP</th>
                                        <th className='text-truncate align-middle'>Taxable</th>
                                        <th className='text-truncate align-middle'>Net Amt</th>
                                        <th className='text-truncate align-middle'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredPurchaseItemList.map((pi, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>
                                                {ProductList?.find(p => p.id === pi.product_id)?.productName || ''}
                                            </td>
                                            <td>
                                                {ProductList?.find(p => p.id === pi.product_id)?.productCode || ''}
                                            </td>
                                            <td>{pi.batch_number}</td>
                                            <td>{pi.quantity}</td>
                                            <td>{pi.purchase_rate}</td>
                                            <td>{pi.price_wogst}</td>
                                            <td>{pi.price_gst}</td>
                                            <td>{pi.sales_rate}</td>
                                            <td>{pi.w_rate}</td>
                                            <td>{pi.r_rate}</td>
                                            <td>{pi.l_rate}</td>
                                            <td>{pi.mrp}</td>
                                            <td>{pi.taxable_amount}</td>
                                            <td>{pi.total_amount}</td>
                                            <td className="p-1"><button className="btn btn-sm btn-danger" onClick={() => handleAttributeClick(pi.id?.toString())}>Edit Attribute</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-end hstack gap-1 mt-2">
                            {/* <button className="btn btn-secondary" onClick={() => resetStates()}>Clear</button> */}
                            <button className="btn btn-primary" onClick={resetStates}>Clear</button>
                            <button className="btn btn-primary" onClick={handleSave}>Update</button>
                        </div>
                    </div>
                </div>
            </div>}
        </UserLayout>
    </>
    );
}

const UserBody = ({ sno, invoiceDate, finalAmount, invoiceNo, purchaseEntryNo, supplier, id, onGetPurchaseItemsClick }: TableBody) => {

    return (
        <>
            <tr key={sno}>
                <td className='align-middle'>{sno}</td>
                <td className='text-capitalize align-middle'>{purchaseEntryNo}</td>
                <td className='text-capitalize align-middle'>{LocalFormatDate(invoiceDate)}</td>
                <td className='text-capitalize align-middle'>{invoiceNo}</td>
                <td className='text-capitalize align-middle'>{supplier}</td>
                <td className='text-capitalize align-middle'>{finalAmount}</td>
                <td className='text-capitalize align-middle text-center p-1'>
                    <button className='btn btn-primary btn-sm' onClick={onGetPurchaseItemsClick}>Get Purchase Item</button>
                </td>
            </tr>
        </>
    );
}

export default PurchaseUpdateAttribute;
