import { useState } from "react";
import { Card, Container, Nav } from "react-bootstrap";
import UserLayout from "../../components/custom/layouts/UserLayout";
import SalesReturnBillersWise from "../../components/report/SalesReturnBillersWise";
import SalesReturnDateWise from "../../components/report/SalesReturnDateWise";
import SalesReturnGstRateWise from "../../components/report/SalesReturnGstRateWise";
import SalesReturnInvoiceWise from "../../components/report/SalesReturnInvoiceWise";
import SalesReturnProductDateWise from "../../components/report/SalesReturnProductDateWise";
import SalesReturnRepresenterBillWise from "../../components/report/SalesReturnRepresenterWise";
import "../../styles/styles.css";

const SalesReturnReports = () => {
    const [activeTab, setActiveTab] = useState("invoice");

    return (
        <UserLayout>
            <h3 className="mb-2 font-bold">Sales Return Report</h3>

            <Container fluid className="p-4 shadow-lg bg-white rounded">

                <Nav variant="pills" className="custom-tabs mb-2 justify-content-start" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey || "invoice")}>

                    <Nav.Item>
                        <Nav.Link eventKey="invoice" className="custom-tab">Sales Return Invoice Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="date" className="custom-tab">Sales Return Date Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="gst" className="custom-tab">Sales Return GST Rate Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="product" className="custom-tab">Sales Return Product Date Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="customer" className="custom-tab">Sales Return Billed by Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="representer" className="custom-tab">Sales Return Representer Wise</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                        <Nav.Link eventKey="summary" className="custom-tab">Sales Summary </Nav.Link>
                    </Nav.Item> */}
                </Nav>

                {/* Tab Content */}
                <Card className="tab-content-card">
                    {activeTab === "invoice" && <TabContent title="Sales Return Invoice Wise" />}
                    {activeTab === "date" && <TabContent title="Sales Return Date Wise" />}
                    {activeTab === "gst" && <TabContent title="Sales GST Rate Wise" />}
                    {activeTab === "product" && <TabContent title="Sales Return Product Date Wise" />}
                    {activeTab === "customer" && <TabContent title="Sales Return Billed by Wise" />}
                    {activeTab === "representer" && <TabContent title="Sales Return Representer Wise" />}
                    {/* {activeTab === "summary" && <TabContent title="Sales Summary" />} */}
                </Card>
            </Container>
        </UserLayout>
    );
};

// Tab Content Component
interface TabContentProps {
    title: string;
}

// Tab Content Component with explicit type
const TabContent: React.FC<TabContentProps> = ({ title }) => (
    <>
        {title === "Sales Return Invoice Wise" && <div>
            <SalesReturnInvoiceWise />
        </div>}
        {title === "Sales Return Date Wise" && <div>
            <SalesReturnDateWise />
        </div>}
        {title === "Sales GST Rate Wise" && <div>
            <SalesReturnGstRateWise />
        </div>}
        {title === "Sales Return Product Date Wise" && <div>
            <SalesReturnProductDateWise />
        </div>}
        {title === "Sales Return Billed by Wise" && <div>
            <SalesReturnBillersWise />
        </div>}
        {title === "Sales Return Representer Wise" && <div>
            <SalesReturnRepresenterBillWise />
        </div>}
        {title === "Sales Summary" && <div>
            {/* <SalesSummary /> */}
        </div>}
    </>
);


export default SalesReturnReports;
