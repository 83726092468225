import { useEffect, useState } from "react";
import { Card, Table, Button } from "react-bootstrap";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import ReportFilter from "./ReportFilter";
import api from '../../config/api';
import { getDate, LocalFormatDate, rupeeFormat } from "../../config/functions";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { ClientRegistrationType } from "../../redux/actions/clientRegistration";
import SalesReportFilter from "./SalesReportFilter";
import SalesReturnReportFilter from "./SalesReturnReportFilter";

interface SalesData {
    financialYear: string;
    invoiceNo: number;
    productName: string;
    productCode: string;
    qty: number;
    salesRate: number;
    discount: number;
    gst: number;
    netAmount: number;
}

interface SalesProductDateWiseReport {
    financialYear:string;
    saleDate: string;
    saleDay: string;
    saleData: SalesData[];
}

const SalesReturnProductDateWise = () => {
    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [triggerSearch, setTriggerSearch] = useState<boolean>(false);
    const [tableData, setTableData] = useState<SalesProductDateWiseReport[]>([]);
    const clients = useSelector<StoreState, ClientRegistrationType[]>(state => state.clientRegistration)
    
    useEffect(() => {
        if (triggerSearch) {
            const response = api().get(
                `reports/salesReturnProductDateWise/?fromDate=${getDate(fromDate)}&toDate=${getDate(toDate)}`
            );

            response.then((res) => {
                if (res.status === 200 && res.data.data) {
                    console.log("API Response:", res.data);
                    setTableData(res.data.data);
                }
            }).catch(error => {
                console.error("Error fetching sales data:", error);
            });

            setTriggerSearch(false);
        }
    }, [triggerSearch, fromDate, toDate]);
const [filters, setFilters] = useState<any>({});
    const handleSearch = (newFilters: any) => {
        setFromDate(newFilters.fromDate);
        setToDate(newFilters.toDate);
        setTriggerSearch(true);
    };
   
    const downloadCSV = () => {
        const header = [
            "SI.No","Product Name",
                    "Product Code", "Qty", "s.rate","Disc","GST","Total"
        ];

        let csvContent = header.join(",") + "\n";

        tableData.forEach((item, index) => {
            csvContent += `\nFinancial Year: ${item.financialYear}\n`;
            csvContent += `\nSales Date: ${item.saleDate}\n`;
            csvContent += `\nSales Day: ${item.saleDay}\n`;
            // csvContent += header.join(",") + "\n";

            item.saleData.forEach((sales, subIndex) => {
                csvContent += [
                    subIndex + 1,
                    sales.productName,
                    sales.productCode,
                    sales.qty,
                    sales.salesRate,
                    sales.discount,
                    sales.gst,
                    sales.netAmount
                ].join(",") + "\n";
            });

        });

        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "sales_return_product_date_report.csv";
        link.click();
    };

    const downloadPDF = () => {
        const logoUrl = clients[0]?.shopPhoto;
        const doc = new jsPDF("landscape");

        const marginX = 10;
        const boxWidth = doc.internal.pageSize.width - 2 * marginX;
        const boxHeight = 25;
        let startY = 15;
        
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        
        doc.rect(marginX, startY - 5, boxWidth, boxHeight);

        if (logoUrl) {
            doc.addImage(logoUrl, "JPEG", marginX + 5, startY - 2, 25, 20);
        }

        const paddingBetweenHeaderAndTable = 5;
        const textX = marginX + 35;

        doc.text(clients[0]?.shopShortName || "", textX, startY);
        doc.setFont("helvetica", "normal");
        doc.text(
            `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${clients[0]?.district || ""}`,
            textX,
            startY + 5
        );
        doc.text(
            `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${clients[0]?.contactNo1 || ""}`,
            textX,
            startY + 10
        );
        doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 15);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        const heading = "sales return product date wise report";
        const headingWidth = doc.getTextWidth(heading);
        const pageWidth = doc.internal.pageSize.width;
        doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 8);
            
         doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const financialYearText = `Financial Year: ${tableData[0]?.financialYear || ""}`;
        const headingStartX = marginX + boxWidth - headingWidth - 10;
        doc.text(financialYearText, headingStartX, startY + 13);
                
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        const formattedFromDate = "From : " + (LocalFormatDate(getDate(fromDate)) || "");
        const formattedToDate = "To : " + (LocalFormatDate(getDate(toDate)) || "");
        const tableStartY = 12 + boxHeight + paddingBetweenHeaderAndTable;
        doc.text(formattedFromDate, marginX, tableStartY);
        doc.text(formattedToDate, marginX + 50, tableStartY);
        
        
        let bodyData:any = []; 
        let serialNumber = 1;
    
        tableData.forEach((item) => {
            bodyData.push([
                {
                    content: `Sales Date: ${LocalFormatDate(item.saleDate)} Saley Day:${item.saleDay}`,
                    colSpan: 14,
                    styles: { fontStyle: "bold", halign: "center" }
                }
            ]);
    
            bodyData.push(
                ...item.saleData?.map((sales) => [
                    serialNumber++, 
                    sales.productName,
                    sales.productCode,
                    sales.qty,
                    rupeeFormat(sales.salesRate),
                    rupeeFormat(sales.discount),
                    rupeeFormat(sales.gst),
                    rupeeFormat(sales.netAmount),
                ])
            );

        });
    
        autoTable(doc, {
            startY: startY + 32,
            head: [
                [
                    "SI.No","Product Name",
                    "Product Code", "Qty", "s.rate","Disc","GST","Total"
                ]
            ],
            body: bodyData,
            theme: "grid",
            styles: { fontSize: 8, textColor: 0, lineColor: 0, lineWidth: 0.1 },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: 0,
                fontStyle: "bold",
                lineColor: 0,
                lineWidth: 0.1,
            },
            columnStyles: {
                0: { halign: "center" }, // SI.No (Center aligned)
                1: { halign: "center" }, // Date (Center aligned)
                2: { halign: "center" }, // Invoice Number (Center aligned)
                3: { halign: "center" },   // Supplier Name (Left aligned)
                4: { halign: "right" },// Net Total (Right aligned)
            },
            margin: { top: 25, left: 10, right: 10 }
        });
        
        // Footer Section (Page Number)
        const pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            let pageHeight = doc.internal.pageSize.height;
            let footerY = pageHeight - 15;

            doc.setFontSize(10);
            doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, footerY);
        }

        doc.save("sales_return_product_date_wise_report.pdf");
    };
    
    return (
        <>
            <SalesReturnReportFilter onSearch={handleSearch} />
            {tableData.length > 0 ? <Card className="mt-2 p-2 shadow-lg border-0 rounded-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5 className="fw-bold">Sales return product date wise details</h5>
                    <div>
                        <Button className="me-2 btn-sm" variant="success" onClick={downloadCSV}>
                            Download CSV
                        </Button>
                        <Button className="me-2 btn-sm" variant="success" onClick={downloadPDF}>
                            Download PDF
                        </Button>
                    </div>
                </div>
                {tableData?.map((item, index) => (
                    <div key={index}>
                        <h6 className="mt-3">Sales Date: {LocalFormatDate(item.saleDate)} <span className="ms-1">
                            Sales Day:{item.saleDay}</span></h6>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>SI.No</th>
                                    <th>Financial Year</th>
                                    <th>Invoice No</th>
                                    <th>Product Name</th>
                                    <th>Product Code</th>
                                    <th>Quantity</th>
                                    <th>Sales rate</th>
                                    <th>Discount </th>
                                    <th>GST </th>
                                    <th>Total </th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.saleData?.map((sales, subIndex) => (

                                     <tr key={`${index}-${subIndex}`}>
                                        <td>{subIndex + 1}</td>
                                        <td>{item.financialYear}</td>
                                        <td>{sales.invoiceNo}</td>
                                        <td>{sales.productName}</td>
                                        <td>{sales.productCode}</td>
                                        <td>{sales.qty}</td>
                                        <td>{sales.salesRate}</td>
                                        <td>{sales.discount}</td>
                                        <td>{sales.gst}</td>
                                        <td>{sales.netAmount}</td>

                                    </tr>
                                ))}
                            </tbody>
                           
                        </Table>
                    </div>
                ))}
            </Card> : <h4 className="text-center fw-bold text-secondary">
                No such data found !
            </h4>
            }
        </>
    );
};

export default SalesReturnProductDateWise;