import { ShopActionList, ShopType } from "../actions/shopAction";
import { Action } from "../actions/types";

const initialValue: Array<ShopType> = []

export const shopReducer = (state: ShopType[] = initialValue, action: Action) => {

    switch (action.type) {
        case ShopActionList.ADD_SHOP:
            return [...state, action.data];
        case ShopActionList.EDIT_SHOP: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => {
                return u.id === action.id
            })

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case ShopActionList.FETCH_SHOP:
            return action.data

        case ShopActionList.DELETE_SHOP: {
            return state.filter(i => i.id !== action.data)

        }


        default:
            return state;
    }
}