import { useEffect, useState } from "react";
import { Card, Table, Button } from "react-bootstrap";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import ReportFilter from "./ReportFilter";
import api from '../../config/api';
import { getDate, LocalFormatDate, rupeeFormat } from "../../config/functions";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { ClientRegistrationType } from "../../redux/actions/clientRegistration";
import SalesReportFilter from "./SalesReportFilter";
import SalesReturnReportFilter from "./SalesReturnReportFilter";

interface SalesDateWiseData {
    financialYear: string;
    salesDate: string;
    day: string;
    billAmount: number;
    taxable: number;
    gst: number;
    discount: number;
    addAmount: number;
    lessAmount: number;
    total: number;
    paid: number;
    balance: number;
   
}

const SalesReturnDateWise = () => {
    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");

    const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

    const [tableData, setTableData] = useState<SalesDateWiseData[]>([]);

    const clients = useSelector<StoreState, ClientRegistrationType[]>(state => state.clientRegistration)

    useEffect(() => {
        if (triggerSearch) {
            const response = api().get(
                `reports/salesReturnDateWise/?fromDate=${getDate(fromDate)}&toDate=${getDate(toDate)}`
            );

            response.then((res) => {
                if (res.status === 200 && res.data.data) {
                    console.log("API Response:", res.data);
                    setTableData(res.data.data);
                }
            }).catch(error => {
                console.error("Error fetching sales Return data:", error);
            });

            setTriggerSearch(false);
        }
    }, [triggerSearch, fromDate, toDate]);

    const handleSearch = (newFilters: any) => {
        setFromDate(newFilters.fromDate);
        setToDate(newFilters.toDate);

        setTriggerSearch(true);
    };

    const downloadCSV = () => {
        const header = ["SI.No", "Finanacial Year", "Sales date", "Day", "Bill Amount","Taxable", "GST", "Disc", "Add Amount", "Less Amount", "Total", "Paid","Balance"];

        const colTotal = tableData.reduce(
            (acc, item) => {
                acc.billAmount += item.billAmount;
                acc.taxable += item.taxable;
                acc.gst += item.gst;
                acc.discount += item.discount;
               
                acc.addAmount += item.addAmount;
                acc.lessAmount += item.lessAmount;
                 acc.total += item.total;
                acc.paid += item.paid;
                acc.balance += item.balance;
                return acc;
            },
            {
                billAmount:0,
                taxable: 0,
                gst: 0,
                discount:0,
                addAmount: 0,
                lessAmount: 0,
                total: 0,
                paid: 0,
                balance: 0
            }
        );

        const rows = tableData?.map((item, index) => [
            index + 1,
            item.financialYear,
            LocalFormatDate(item.salesDate),
            item.day,
            item.billAmount.toFixed(2),
            item.taxable.toFixed(2),
            item.gst.toFixed(2),
            item.discount.toFixed(2),
            item.addAmount.toFixed(2),
            item.lessAmount.toFixed(2),
            item.total.toFixed(2),
            item.paid.toFixed(2),
            item.balance.toFixed(2),
        ]);

        const grandTotalRow = [
            "", "", "", "Total",
            colTotal?.billAmount?.toFixed(2).toString(),
            colTotal?.taxable?.toFixed(2).toString(),
            colTotal?.gst?.toFixed(2).toString(),
            colTotal?.discount?.toFixed(2).toString(),
            colTotal?.addAmount?.toFixed(2).toString(),
            colTotal?.lessAmount?.toFixed(2).toString(),
            colTotal?.total?.toFixed(2).toString(),
            colTotal?.paid?.toFixed(2).toString(),
            colTotal?.balance?.toFixed(2).toString()
        ];

        const csvContent = [
            header.join(","),
            ...rows.map(row => row.join(",")),
            grandTotalRow.join(",")
        ].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "sales_return_date_wise_report.csv";
        link.click();
    };


    const downloadPDF = () => {
        const logoUrl = clients[0]?.shopPhoto;

        const doc = new jsPDF("landscape");
        const marginX = 10;
        const boxWidth = doc.internal.pageSize.width - 2 * marginX;
        const boxHeight = 25;
        let startY = 15;

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");

        doc.rect(marginX, startY - 5, boxWidth, boxHeight);

        if (logoUrl) {
            doc.addImage(logoUrl, "JPEG", marginX + 5, startY - 2, 25, 20);
        }

        const paddingBetweenHeaderAndTable = 5;
        const textX = marginX + 35;

        doc.text(clients[0]?.shopShortName || "", textX, startY);
        doc.setFont("helvetica", "normal");
        doc.text(
            `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${clients[0]?.district || ""}`,
            textX,
            startY + 5
        );
        doc.text(
            `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${clients[0]?.contactNo1 || ""}`,
            textX,
            startY + 10
        );
        doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 15);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        const heading = "Sales return date wise report";
        const headingWidth = doc.getTextWidth(heading);
        const pageWidth = doc.internal.pageSize.width;
        doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 8);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const financialYearText = `Financial Year: ${tableData[0]?.financialYear || ""}`;
        const headingStartX = marginX + boxWidth - headingWidth - 10;
        doc.text(financialYearText, headingStartX, startY + 13);
        
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        const formattedFromDate = "From : " + (LocalFormatDate(getDate(fromDate)) || "");
        const formattedToDate = "To : " + (LocalFormatDate(getDate(toDate)) || "");

        const tableStartY = 12 + boxHeight + paddingBetweenHeaderAndTable;
        doc.text(formattedFromDate, marginX, tableStartY);
        doc.text(formattedToDate, marginX + 50, tableStartY);

        const colTotal = tableData.reduce(
            (acc, item) => {
                acc.billAmount += item.billAmount;
                acc.taxable += item.taxable;
                acc.gst += item.gst;
                acc.discount += item.discount;
                acc.addAmount += item.addAmount;
                acc.lessAmount += item.lessAmount;
                acc.total += item.total;
                acc.paid += item.paid;
                acc.balance += item.balance;
                return acc;
            },
            {
                billAmount:0,
                taxable: 0,
                gst: 0,
                discount: 0,
                addAmount: 0,
                lessAmount: 0,
                total: 0,
                paid: 0,
                balance: 0
            }
        );

        autoTable(doc, {
            startY:  startY + 32,
            head: [
                ["SI.No", "Sales date", "Sales day","Bill Amount" ,"Taxable", "GST","Discount",  "Add Amount", "Less Amount", " Total", "Paid","Balance"]
            ],
            body: [
                ...tableData?.map((item, index) => [
                    index + 1,
                    LocalFormatDate(item.salesDate),
                    item.day,
                    rupeeFormat(item.billAmount.toFixed(2)),
                    rupeeFormat(item.taxable.toFixed(2)),
                    rupeeFormat(item.gst.toFixed(2)),
                    rupeeFormat(item.discount.toFixed(2)),
                    
                    rupeeFormat(item.addAmount.toFixed(2)),
                    rupeeFormat(item.lessAmount.toFixed(2)),
                    rupeeFormat(item.total.toFixed(2)),
                    rupeeFormat(item.paid.toFixed(2)),
                    rupeeFormat(item.balance.toFixed(2)),
                ]),
                [
                    { content: "Total", colSpan: 3, styles: { fontStyle: "bold", halign: "right" } },
                    {content: rupeeFormat(colTotal?.billAmount?.toFixed(2)), styles: { fontStyle: "bold"}},
                    {content: rupeeFormat(colTotal?.taxable?.toFixed(2)), styles: { fontStyle: "bold"}},
                    {content: rupeeFormat(colTotal?.gst?.toFixed(2)), styles: { fontStyle: "bold"}},
                    {content: rupeeFormat(colTotal?.discount?.toFixed(2)), styles: { fontStyle: "bold"}},
                    {content: rupeeFormat(colTotal?.addAmount?.toFixed(2)), styles: { fontStyle: "bold"}},
                    { content: rupeeFormat(colTotal?.lessAmount?.toFixed(2)), styles: { fontStyle: "bold" } },
                     {content: rupeeFormat(colTotal?.total?.toFixed(2)), styles: { fontStyle: "bold"}},
                    {content: rupeeFormat(colTotal?.paid?.toFixed(2)), styles: { fontStyle: "bold" }},
                    {content: rupeeFormat(colTotal?.balance?.toFixed(2)), styles: { fontStyle: "bold" }}
                ]
            ],
            theme: 'grid',
            styles: { fontSize: 8, textColor: 0, lineColor: 0, lineWidth: 0.1 },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: 0,
                fontStyle: "bold",
                lineColor: 0,
                lineWidth: 0.1,
            },
            margin: { top: 25, left: 10, right: 10 },
            columnStyles: {
                0: { halign: "center" },
                1: { halign: "center" },
                2: { halign: "center" },
                3: { halign: "right" },  
                4: { halign: "right" },  
                5: { halign: "right" }, 
                6: { halign: "right" },  
                7: { halign: "right" },  
                8: { halign: "right" }, 
                9: { halign: "right" },  
                10: { halign: "right" }, 
                11: { halign: "right" }, 
            },
        });

         // Footer Section (Page Number)
         const pageCount = doc.getNumberOfPages();
         for (let i = 1; i <= pageCount; i++) {
             doc.setPage(i);
             let pageHeight = doc.internal.pageSize.height;
             let footerY = pageHeight - 15;
 
             doc.setFontSize(10);
             doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, footerY);
         }

        doc.save("sales_return_date_wise_report.pdf");
    };


    return (
        <>
            <SalesReturnReportFilter onSearch={handleSearch} />
            {tableData.length > 0 ? <Card className="mt-2 p-2 shadow-lg border-0 rounded-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5 className="fw-bold">Sales return date wise report</h5>
                    <div>
                        <Button className="me-2 btn-sm" variant="success" onClick={downloadCSV}>
                            Download CSV
                        </Button>
                        <Button className="me-2 btn-sm" variant="success" onClick={downloadPDF}>
                            Download PDF
                        </Button>
                    </div>
                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>SI.No</th>
                            <th>Financial Year</th>
                            <th>Date</th>
                            <th>Day</th>
                            <th>Bill Amount</th>
                            <th>Taxable</th>
                            <th>GST</th>
                            <th>Disc</th>
                            <th>Add</th>
                            <th>Less</th>
                            <th>Total</th>
                            <th>Paid</th>
                            <th>Balance </th>
                        </tr>
                    </thead>

                    <tbody>
                        {tableData?.length > 0 ? (
                            tableData?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.financialYear}</td>
                                    <td>{item.salesDate}</td>
                                    <td>{item.day}</td>
                                    <td>{item.billAmount}</td>
                                    <td>{item.taxable}</td>
                                    <td>{item.gst}</td>
                                    <td>{item.discount}</td>
                                    <td>{item.addAmount}</td>
                                    <td>{item.lessAmount}</td>
                                    <td>{item.total}</td>
                                    <td>{item.paid}</td>
                                    <td>{item.balance}</td>
                                    
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={13} className="text-center fw-bold">
                                    No such data found !!!
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card> : <h4 className="text-center fw-bold text-secondary">
                No such data found !
            </h4>
            }
        </>
    );
};

export default SalesReturnDateWise;
