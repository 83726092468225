import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../components/custom/components/PageTitle'
import LineChartForCard from '../components/custom/components/charts/apex/LineChartForCards'
import UserLayout from '../components/custom/layouts/UserLayout'
import { getDate } from '../config/functions'
import { PurchaseReturnPaymentModesDropDown, PurchaseReturnType, fetchPurchaseReturnAction } from '../redux/actions/purchaseReturnAction'
import { AppDispatch, StoreState } from '../redux/store'
import PurchaseReturnHourlyReport from './PurchaseReturnHourlyReport'
import PurchaseReturnMonthlyReport from './PurchaseReturnMonthlyReport'
import PurchaseReturnWeeklyReport from './PurchaseReturnWeeklyReport'
import PurchaseReturnYearlyReport from './PurchaseReturnYearlyReport'

const PurchaseReturnDashboard = () => {
    const dispatch = useDispatch<AppDispatch>()
    const purchaseReturnList = useSelector<StoreState, PurchaseReturnType[]>(state => state.purchaseReturn)
    let d = new Date();
    const currDate = d;
    // const [cashData, setCashData] = useState<number[]>([])
    // const [cardData, setCardData] = useState<number[]>([])

    const cashData = useMemo<number[]>(() => {
        let todayData = purchaseReturnList?.filter(f => f.invoiceReturnDate === getDate(currDate)).filter(pm => pm.paymentMode === PurchaseReturnPaymentModesDropDown.CASH)
        if (todayData.length > 0) {
            let cashArr = todayData?.map(cash => cash?.grandTotal ? parseFloat(cash?.grandTotal) : 0)
            // setCashData(cashArr)
            return cashArr
        } else return []

    }, [purchaseReturnList, currDate])

    const cardData = useMemo<number[]>(() => {
        let todayData = purchaseReturnList?.filter(f => f.invoiceReturnDate === getDate(currDate)).filter(pm => pm.paymentMode === PurchaseReturnPaymentModesDropDown.BANK)
        if (todayData.length > 0) {
            let cardArr = todayData?.map(card => card?.grandTotal ? parseFloat(card?.grandTotal) : 0)
            // setCardData(cardArr)
            return cardArr
        } else return []

    }, [purchaseReturnList, currDate])

    const todaysCashPurchaseReturn = useMemo<number>(() => {
        let todayData = purchaseReturnList?.filter(f => f.invoiceReturnDate === getDate(currDate)).filter(pm => pm.paymentMode === PurchaseReturnPaymentModesDropDown.CASH)

        return todayData.reduce((prev, curr) => {
            return prev + (curr?.grandTotal ? parseFloat(curr?.grandTotal) : 0)
        }, 0)

    }, [purchaseReturnList, currDate])

    const todaysCardPurchaseReturn = useMemo<number>(() => {
        let todayData = purchaseReturnList?.filter(f => f.invoiceReturnDate === getDate(currDate)).filter(pm => pm.paymentMode === PurchaseReturnPaymentModesDropDown.BANK)

        return todayData.reduce((prev, curr) => {
            return prev + (curr?.grandTotal ? parseFloat(curr?.grandTotal) : 0)
        }, 0)

    }, [purchaseReturnList, currDate])

    const todaysOverallPurchase = useMemo<number[]>(() => {
        let todayData = purchaseReturnList?.filter(f => f.invoiceReturnDate === getDate(currDate))
        let OverallArr = todayData?.map(card => card?.grandTotal ? parseFloat(card?.grandTotal) : 0)
        return OverallArr
    }, [purchaseReturnList, currDate])

    useEffect(() => {
        dispatch(fetchPurchaseReturnAction())
    }, [])

    return <UserLayout>

        <PageTitle
            title='Purchase Return Analysis'
        />
        <div className='row mx-3'>
            <div className='col-sm-12 col-md-4 col-lg-4'>
                <div className='card' style={{ width: '360px' }}>
                    <div className='card-body p-1'>
                        <h4 className='fs-3 fw-bold ms-3 my-0'>Cash Purchase Return</h4>
                        <h4 className='fs-4 fw-bold ms-3 my-0'>₹ {todaysCashPurchaseReturn}</h4>
                        <LineChartForCard
                            datasets={
                                [{
                                    name: 'Cash',
                                    data: cashData,
                                    // data: [5, 18, 8, 12, 9, 13, 11, 18, 15],
                                }]
                            }
                            colors={['#008FFB']}
                            labels={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                            title=''
                            height={60}
                            xyLabelShow={false}
                        />
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-4 col-lg-4 '>
                <div className='card' style={{ width: '360px' }}>
                    <div className='card-body p-1'>
                        <h4 className='fs-3 fw-bold ms-3 my-0'>Bank Purchase Return</h4>
                        <h4 className='fs-4 fw-bold ms-3 my-0'>₹ {todaysCardPurchaseReturn}</h4>
                        <LineChartForCard
                            datasets={
                                [{
                                    name: 'Card',
                                    data: cardData,
                                    // data: [6, 20, 8, 12, 9, 18, 11, 18, 15],
                                }]
                            }
                            colors={['#00E396']}
                            labels={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                            title=''
                            height={60}
                            xyLabelShow={false}
                        />
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-4 col-lg-4'>
                <div className='card' style={{ width: '360px' }}>
                    <div className='card-body p-1'>
                        <h4 className='fs-3 fw-bold ms-3 my-0'>Overall Purchase</h4>
                        <h4 className='fs-4 fw-bold ms-3 my-0'>₹ {todaysCashPurchaseReturn + todaysCardPurchaseReturn || 0}</h4>
                        <LineChartForCard
                            datasets={
                                [{
                                    name: 'Overall',
                                    data: todaysOverallPurchase,
                                }]
                            }
                            colors={['#FEB019']}
                            labels={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                            title=''
                            height={60}
                            xyLabelShow={false}
                        />
                    </div>
                </div>
            </div>
        </div>
        <PurchaseReturnYearlyReport />
        <PurchaseReturnMonthlyReport />
        <PurchaseReturnWeeklyReport />
        <PurchaseReturnHourlyReport />

    </UserLayout>

}

export default PurchaseReturnDashboard
