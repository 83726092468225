import { Dispatch } from "redux"
import { camelize } from "../../config/functions"
import { addUser, deleteUser, editPassword, editUser, getUser } from "../../services/user.service"
import { log } from "console"

export enum ActiveDropDown {
    Active = "active",
    InActive = "inactive",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive




export interface UsersType {
    id?: number
    userId?: string
    name?: string
    cellNo?:number
    password?: string
    // confirmPassword: string
    status?: ActiveTypes | undefined
    emailId?: string
    branchId?: string
    role?: string
    createdBy?: number | null,
    createdAt?: string,
    updatedAt?: string
    updatedBy?: number | null,
}

export enum UsersActionsList {
    ADD_USERS = 'ADD_USERS',
    EDIT_USERS = 'EDIT_USERS',
    FETCH_USERS = 'FETCH_USERS',
    DELETE_USERS = 'DELETE_USERS',
    EDIT_PASSWORD = 'EDIT_PASSWORD'
}

export interface DeleteUsers {
    id: number | undefined
}

export interface PasswordType {
    id?: number
    resetPassword: string
}

export interface changePasswordType {
    id?: number
    oldPassword: string
    newPassword: string
}

export interface AddUsersAction {
    type: UsersActionsList.ADD_USERS
    data: UsersType
}

export interface EditUsersAction {
    type: UsersActionsList.EDIT_USERS
    data: UsersType
    id: number | undefined
}

export interface FetchUsersAction {
    type: UsersActionsList.FETCH_USERS
    data: UsersType[]

}

export interface DeleteUsersAction {
    type: UsersActionsList.DELETE_USERS
    data: number | undefined
}

export interface EditPasswordAction {
    type: UsersActionsList.EDIT_PASSWORD;
    data: PasswordType;
    id: number | undefined
}

export type UsersActions = AddUsersAction | EditUsersAction | FetchUsersAction | DeleteUsersAction | EditPasswordAction

export const addUsersAction = (data: UsersType) => {
    return async (dispatch: Dispatch) => {
        return addUser(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddUsersAction>({
                    type: UsersActionsList.ADD_USERS,
                    data: addData
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'User Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const editUsersAction = (data: UsersType, id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return editUser(data, id).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
                dispatch<EditUsersAction>({
                    type: UsersActionsList.EDIT_USERS,
                    data: editData,
                    id: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'User Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })

    }
}

export const fetchUsersAction = () => {
    return async (dispatch: Dispatch) => {
        const response = await getUser()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchUsersAction>({
            type: UsersActionsList.FETCH_USERS,
            data: data
        })
    }

}


export const deleteUsersAction = (id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return deleteUser(id).then(response => {
            if (response.status === 200) {
                dispatch<DeleteUsersAction>({
                    type: UsersActionsList.DELETE_USERS,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'User Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

export const updatePassword = (data: PasswordType, id: number | undefined) => {
    return async (dispatch: Dispatch) => {

        return editPassword(data, id).then(response => {
            if (response.status === 200 && response.data) {
                dispatch<EditPasswordAction>({
                    type: UsersActionsList.EDIT_PASSWORD,
                    data: data,
                    id: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Password Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })

    }
}