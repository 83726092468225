import React, { FC } from "react";
import BarCode from 'react-barcode';
import { useReactToPrint } from "react-to-print";

interface BarCodeProps {
    count: number
    name: string
    price: number
    mrp: number
    productCode: string
    onClose?: () => void
}
const GenerateBarCode: FC<BarCodeProps> = (props) => {

    const count = (props.count + (props.count % 2)) / 2

    let printRef: any = React.useRef(null)
    const barCodeLists: BarCodeProps[] = [];

    for (let i = 0; i < count; i++) {
        barCodeLists.push({ name: props.name, price: props.price, productCode: props.productCode, count: 0, mrp: props.mrp })

    }

    const printHandler = useReactToPrint({
        content: () => printRef

    })
    return <>
        <div style={{ display: 'block' }}>
            <div ref={(el) => (printRef = el)}>
                {barCodeLists.map((barCodeList: BarCodeProps) => (
                    <div className="row print">
                        <div className="col-6">
                            <div style={{ textAlign: "center", fontSize: 8 }}>
                                <div style={{ textAlign: "center", fontSize: 12, margin: "0px 10px 0px 10px" }}><b><div>THIRUPATHI AUTO PARTS</div></b></div>
                                <BarCode value={props.productCode} width={1} height={20} fontSize={14} marginBottom={3} margin={0} />
                                <div style={{ textAlign: "center", fontSize: 12, margin: "0px 10px 0px 10px" }}><div>{props.name}</div></div>
                                <div style={{ textAlign: "center", fontSize: 12, margin: "0px 10px 0px 10px" }}><div><b><del className="me-1">{props.mrp.toFixed(2)}</del></b><b>{props.price.toFixed(2)}</b></div></div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div style={{ textAlign: "center", fontSize: 8 }}>
                                <div style={{ textAlign: "center", fontSize: 12, margin: "0px 10px 0px 10px" }}><b><div>THIRUPATHI AUTO PARTS</div></b></div>
                                <BarCode value={props.productCode} width={1} height={20} fontSize={14} marginBottom={3} margin={0} />
                                <div style={{ textAlign: "center", fontSize: 12, margin: "0px 10px 0px 10px" }}><div>{props.name}</div></div>
                                <div style={{ textAlign: "center", fontSize: 12, margin: "0px 10px 0px 10px" }}><div><b><del className="me-1">{props.mrp.toFixed(2)}</del></b><b>{props.price.toFixed(2)}</b></div></div>
                            </div>
                        </div>

                    </div>

                ))}
            </div>
        </div>
        <div className="text-center">
            <button className="btn btn-success" onClick={printHandler}>Print</button>
        </div>
    </>
}

export default GenerateBarCode;