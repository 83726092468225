import "../../styles/styles.css";
import { useState } from "react";
import { Container, Card, Nav } from "react-bootstrap";
import UserLayout from "../../components/custom/layouts/UserLayout";
import ReportFilter from "../../components/report/ReportFilter";

const GstReport = () => {
    const [activeTab, setActiveTab] = useState("invoice");

    return (
        <UserLayout>
            <h3 className="mb-2 font-bold">GST Report</h3>

            <Container fluid className="p-4 shadow-lg bg-white rounded">

                <Nav variant="pills" className="custom-tabs mb-2 justify-content-start" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey || "invoice")}>

                    <Nav.Item>
                        <Nav.Link eventKey="invoice" className="custom-tab">GST Invoice Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="date" className="custom-tab">GST Date Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="gst" className="custom-tab">GST GST Rate Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="product" className="custom-tab">GST Product Date Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="customer" className="custom-tab">GST Customer Bill Wise</Nav.Link>
                    </Nav.Item>
                </Nav>

                {/* Tab Content */}
                <Card className="tab-content-card">
                    {activeTab === "invoice" && <TabContent title="GST Invoice Wise" />}
                    {activeTab === "date" && <TabContent title="GST Date Wise" />}
                    {activeTab === "gst" && <TabContent title="GST GST Rate Wise" />}
                    {activeTab === "product" && <TabContent title="GST Product Date Wise" />}
                    {activeTab === "customer" && <TabContent title="GST Customer Bill Wise" />}
                </Card>
            </Container>
        </UserLayout>
    );
};

// Tab Content Component
interface TabContentProps {
    title: string;
}

// Tab Content Component with explicit type
const TabContent: React.FC<TabContentProps> = ({ title }) => (
    <>
        {title === "GST Invoice Wise" && <div>
            {/* <ReportFilter /> */}
        </div>}
        {title === "GST Date Wise" && <div>
            {/* <ReportFilter /> */}
        </div>}
        {title === "GST GST Rate Wise" && <div>
            {/* <ReportFilter /> */}
        </div>}
        {title === "GST Product Date Wise" && <div>
            {/* <ReportFilter /> */}
        </div>}
        {title === "GST Customer Bill Wise" && <div>
            {/* <ReportFilter /> */}
        </div>}
    </>
);

export default GstReport;
