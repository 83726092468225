import { Dispatch } from "redux";
import { changePassword } from "../../services/user.service"
import { changePasswordType } from "./usersActions";
import { useNavigate } from "react-router-dom";

export interface ResetPasswordDataType {
    userId: string;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export enum ResetPasswordActionList {
    CHANGE_PASSWORD = "CHANGE_PASSWORD"
}

export interface ResetPasswordAction {
    type: ResetPasswordActionList.CHANGE_PASSWORD;
    data: ResetPasswordDataType | null;
}

export const changePasswordAction = (data: changePasswordType, id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        // try {
        // const response = await changePassword(data, id);
        // dispatch<ResetPasswordAction>({
        //     type: ResetPasswordActionList.CHANGE_PASSWORD,
        //     data: response.data,
        // });
        return changePassword(data, id).then(response => {
            if (response.status === 200) {
                dispatch<ResetPasswordAction>({
                    type: ResetPasswordActionList.CHANGE_PASSWORD,
                    data: response.data,
                });
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Passwordddd Changed Successfully');

            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })

        // } catch (error) {
        //     console.error("Error resetting password:", error);
        // }
    };
};
