import { useEffect, useState } from "react";
import { Card, Table, Button } from "react-bootstrap";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import ReportFilter from "./ReportFilter";
import api from "../../config/api";
import { getDate, LocalFormatDate, rupeeFormat } from "../../config/functions";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { ClientRegistrationType } from "../../redux/actions/clientRegistration";
import SalesReportFilter from "./SalesReportFilter";

interface SalesDateWiseData {
  financialYear: string;
  invoiceNumber: number;
  invoiceDate: string;
  day: string;
  grandTotal: number;
  tenderAmount: number;
  tenderReturn: number;
  tenderBalance: number;
  split: string;
  remarks: string;
  createdBY: string;
}

const SalesTenderWise = () => {
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");

  const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

  const [tableData, setTableData] = useState<SalesDateWiseData[]>([]);

  const clients = useSelector<StoreState, ClientRegistrationType[]>(
    (state) => state.clientRegistration
  );

  useEffect(() => {
    if (triggerSearch) {
      const response = api().get(
        `reports/salesTenderWise/?fromDate=${getDate(fromDate)}&toDate=${getDate(
          toDate
        )}`
      );

      response
        .then((res) => {
          if (res.status === 200 && res.data.data) {
            console.log("API Response:", res.data);
            setTableData(res.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching salesTender data:", error);
        });

      setTriggerSearch(false);
    }
  }, [triggerSearch, fromDate, toDate]);

  const handleSearch = (newFilters: any) => {
    setFromDate(newFilters.fromDate);
    setToDate(newFilters.toDate);

    setTriggerSearch(true);
  };

  const downloadCSV = () => {
    const header = [
      "SI.No",
      "Finanacial Year",
      "Invoice Number",
      "Date",
      "Day",
      "Grand Total ",
      "Tender Amount",
      "Tender Return",
      "Tender Balance",
      "Split",
      "Remarks",
      "CreatedBy",    
    ];

    const rows = tableData?.map((item, index) => [
      index + 1,
      item.financialYear,
      item.invoiceNumber,
      LocalFormatDate(item.invoiceDate),
      item.day,
      item.grandTotal.toFixed(2),
      item.tenderAmount.toFixed(2),
      item.tenderReturn.toFixed(2),
      item.tenderBalance.toFixed(2),
      item.split,
      item.remarks,
      item.createdBY,
     
    ]);

   
    const csvContent = [
      header.join(","),
      ...rows.map((row) => row.join(",")),
    //   grandTotalRow.join(","),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "sales_tender_wise_report.csv";
    link.click();
  };

  const downloadPDF = () => {
    const logoUrl = clients[0]?.shopPhoto;

    const doc = new jsPDF("landscape");
    const marginX = 10;
    const boxWidth = doc.internal.pageSize.width - 2 * marginX;
    const boxHeight = 25;
    let startY = 15;

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");

    doc.rect(marginX, startY - 5, boxWidth, boxHeight);

    if (logoUrl) {
      doc.addImage(logoUrl, "JPEG", marginX + 5, startY - 2, 25, 20);
    }

    const paddingBetweenHeaderAndTable = 5;
    const textX = marginX + 35;

    doc.text(clients[0]?.shopShortName || "", textX, startY);
    doc.setFont("helvetica", "normal");
    doc.text(
      `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${
        clients[0]?.district || ""
      }`,
      textX,
      startY + 5
    );
    doc.text(
      `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${
        clients[0]?.contactNo1 || ""
      }`,
      textX,
      startY + 10
    );
    doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 15);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    const heading = "Sales tender wise report";
    const headingWidth = doc.getTextWidth(heading);
    const pageWidth = doc.internal.pageSize.width;
    doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 8);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const financialYearText = `Financial Year: ${
      tableData[0]?.financialYear || ""
    }`;
    const headingStartX = marginX + boxWidth - headingWidth - 10;
    doc.text(financialYearText, headingStartX, startY + 13);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    const formattedFromDate =
      "From : " + (LocalFormatDate(getDate(fromDate)) || "");
    const formattedToDate = "To : " + (LocalFormatDate(getDate(toDate)) || "");

    const tableStartY = 12 + boxHeight + paddingBetweenHeaderAndTable;
    doc.text(formattedFromDate, marginX, tableStartY);
    doc.text(formattedToDate, marginX + 50, tableStartY);

    // const colTotal = tableData.reduce(
    //   (acc, item) => {
    //     acc.billAmount += item.billAmount;
    //     acc.taxable += item.taxable;
    //     acc.gst += item.gst;
    //     acc.discount += item.discount;
    //     acc.addAmount += item.addAmount;
    //     acc.lessAmount += item.lessAmount;
    //     acc.total += item.total;
    //     acc.paid += item.paid;
    //     acc.balance += item.balance;
    //     return acc;
    //   },
    //   {
    //     billAmount: 0,
    //     taxable: 0,
    //     gst: 0,
    //     discount: 0,
    //     addAmount: 0,
    //     lessAmount: 0,
    //     total: 0,
    //     paid: 0,
    //     balance: 0,
    //   }
    // );

    autoTable(doc, {
      startY: startY + 32,
      head: [
        [
          "SI.No",
          "Finanacial Year",
          "Invoice Number",
          "Date",
          "Day",
          "Grand Total ",
          "Tender Amount",
          "Tender Return",
          "Tender Balance",
          "Split",
          "Remarks",
          "CreatedBy",
        ],
      ],
      body: [
        ...tableData?.map((item, index) => [
          index + 1,
          item.financialYear,
          item.invoiceNumber,
          LocalFormatDate(item.invoiceDate),
          item.day,
          rupeeFormat(item.grandTotal.toFixed(2)),
          rupeeFormat(item.tenderAmount.toFixed(2)),
          rupeeFormat(item.tenderReturn.toFixed(2)),
          rupeeFormat(item.tenderBalance.toFixed(2)),
          item.split,
          item.remarks,
          item.createdBY,
        ]),
        // [
        //   {
        //     content: "Total",
        //     colSpan: 3,
        //     styles: { fontStyle: "bold", halign: "right" },
        //   },
        //   {
        //     content: rupeeFormat(colTotal?.billAmount?.toFixed(2)),
        //     styles: { fontStyle: "bold" },
        //   },
        //   {
        //     content: rupeeFormat(colTotal?.taxable?.toFixed(2)),
        //     styles: { fontStyle: "bold" },
        //   },
        //   {
        //     content: rupeeFormat(colTotal?.gst?.toFixed(2)),
        //     styles: { fontStyle: "bold" },
        //   },
        //   {
        //     content: rupeeFormat(colTotal?.discount?.toFixed(2)),
        //     styles: { fontStyle: "bold" },
        //   },
        //   {
        //     content: rupeeFormat(colTotal?.addAmount?.toFixed(2)),
        //     styles: { fontStyle: "bold" },
        //   },
        //   {
        //     content: rupeeFormat(colTotal?.lessAmount?.toFixed(2)),
        //     styles: { fontStyle: "bold" },
        //   },
        //   {
        //     content: rupeeFormat(colTotal?.total?.toFixed(2)),
        //     styles: { fontStyle: "bold" },
        //   },
        //   {
        //     content: rupeeFormat(colTotal?.paid?.toFixed(2)),
        //     styles: { fontStyle: "bold" },
        //   },
        //   {
        //     content: rupeeFormat(colTotal?.balance?.toFixed(2)),
        //     styles: { fontStyle: "bold" },
        //   },
        // ],
      ],
      theme: "grid",
      styles: { fontSize: 8, textColor: 0, lineColor: 0, lineWidth: 0.1 },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: 0,
        fontStyle: "bold",
        lineColor: 0,
        lineWidth: 0.1,
      },
      margin: { top: 25, left: 10, right: 10 },
      columnStyles: {
        0: { halign: "center" },
        1: { halign: "center" },
        2: { halign: "center" },
        3: { halign: "center" },
        4: { halign: "center" },
        5: { halign: "right" },
        6: { halign: "right" },
        7: { halign: "right" },
        8: { halign: "right" },
        9: { halign: "center" },
        10: { halign: "center" },
        11: { halign: "center" },
      },
    });

    // Footer Section (Page Number)
    const pageCount = doc.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      let pageHeight = doc.internal.pageSize.height;
      let footerY = pageHeight - 15;

      doc.setFontSize(10);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, footerY);
    }

    doc.save("sales_tender_wise_report.pdf");
  };

  return (
    <>
      <SalesReportFilter onSearch={handleSearch} />
      {tableData.length > 0 ? (
        <Card className="mt-2 p-2 shadow-lg border-0 rounded-3">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5 className="fw-bold">Sales tender wise report</h5>
            <div>
              <Button
                className="me-2 btn-sm"
                variant="success"
                onClick={downloadCSV}
              >
                Download CSV
              </Button>
              <Button
                className="me-2 btn-sm"
                variant="success"
                onClick={downloadPDF}
              >
                Download PDF
              </Button>
            </div>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>SI.No</th>
                <th>Financial Year</th>
                <th>Invoice Number</th>
                <th>Date</th>
                <th>Day</th>
                <th>Grand Total</th>
                <th>Tender Amount</th>
                <th>Tender Return</th>
                <th>Tender Balance</th>
                <th>Split</th>
                <th>Remarks</th>
                <th>CreatedBy</th>
               
              </tr>
            </thead>

            <tbody>
              {tableData.length > 0 ? (
                tableData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.financialYear}</td>
                    <td>{item.invoiceNumber}</td>
                    <td>{item.invoiceDate}</td>
                    <td>{item.day}</td>
                    <td>{item.grandTotal}</td>
                    <td>{item.tenderAmount}</td>
                    <td>{item.tenderReturn}</td>
                    <td>{item.tenderBalance}</td>
                    <td>{item.split}</td>
                    <td>{item.remarks}</td>
                    <td>{item.createdBY}</td>
                    
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={13} className="text-center fw-bold">
                    No such data found !!!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      ) : (
        <h4 className="text-center fw-bold text-secondary">
          No such data found !
        </h4>
      )}
    </>
  );
};

export default SalesTenderWise;
