import { ChangeEventHandler, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
// import 'react-select/dist/react-select.css'
import Select from 'react-select'
// import 'react-virtualized-select/styles.css'
// import 'react-virtualized/styles.css'
import { FormType, FormTypeList } from "../config"
import { today } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolder"
import { AuthUserType } from "../redux/actions/authUserActions"
import { BranchType } from "../redux/actions/branchAction"
import { PurchaseType } from "../redux/actions/purchaseActions"
import { StoreState } from "../redux/store"
import Option from "./Option"
import { FormInput } from "./custom/components"
import { ToastTypes, useToast } from "./toast/ToastProvider"

const ACTIVE_DROPS = [
    {
        text: 'Active',
        value: true
    },
    {
        text: 'InActive',
        value: false
    },
]

export const PURCHASE_TYPE_DROPS = [
    {
        text: 'PURCHASE TAX INVOICE',
        value: 'PURCHASE TAX INVOICE'
    },
    {
        text: 'PURCHASE ESTIMATE',
        value: 'PURCHASE ESTIMATE'
    },
    {
        text: 'OPENING STOCK',
        value: 'OPENING STOCK'
    },
]

interface PurchaseFormProps {
    buyerOrderNo: string
    buyerOrderDate: string
    dispatchDocumentNo: string
    destination: string
    setInvoiceType: React.Dispatch<React.SetStateAction<string>>
    setInvoiceTitle: React.Dispatch<React.SetStateAction<string>>
    setInvDate: React.Dispatch<React.SetStateAction<string>>
    setInvNo: React.Dispatch<React.SetStateAction<string>>
    setSupName: React.Dispatch<React.SetStateAction<string>>
    setSupAddress: React.Dispatch<React.SetStateAction<string>>
    setSupContact: React.Dispatch<React.SetStateAction<string>>
    setGST: React.Dispatch<React.SetStateAction<string>>
    setSupAadhar: React.Dispatch<React.SetStateAction<string>>
    setBilledBy: React.Dispatch<React.SetStateAction<string>>
    setSysNo: React.Dispatch<React.SetStateAction<string>>
    setSupPlace: React.Dispatch<React.SetStateAction<string>>
    setRefBy: React.Dispatch<React.SetStateAction<string>>
    setBranch: React.Dispatch<React.SetStateAction<string>>
    setInvoiceAmount: React.Dispatch<React.SetStateAction<string>>
    setPurchaseEntryNo: React.Dispatch<React.SetStateAction<string>>
    setSupplier: React.Dispatch<React.SetStateAction<string>>
    setGudsRecdDate: React.Dispatch<React.SetStateAction<string>>
    setOrderDate: React.Dispatch<React.SetStateAction<string>>
    setOrderNo: React.Dispatch<React.SetStateAction<string>>
    setDateOfSupply: React.Dispatch<React.SetStateAction<string>>
    setPlaceOfSupply: React.Dispatch<React.SetStateAction<string>>
    setTransportMode: React.Dispatch<React.SetStateAction<string>>
    setTransportName: React.Dispatch<React.SetStateAction<string>>
    setVehicleNo: React.Dispatch<React.SetStateAction<string>>
    setNoOfBundles: React.Dispatch<React.SetStateAction<string>>
    setNoOfQty: React.Dispatch<React.SetStateAction<string>>
    setNetWeight: React.Dispatch<React.SetStateAction<string>>
    setEwayBillNo: React.Dispatch<React.SetStateAction<string>>
    setAckNo: React.Dispatch<React.SetStateAction<string>>
    setAckDate: React.Dispatch<React.SetStateAction<string>>
    setIrnNo: React.Dispatch<React.SetStateAction<string>>
    setIrnDate: React.Dispatch<React.SetStateAction<string>>
    setDestination: React.Dispatch<React.SetStateAction<string>>
    setDispatchDocumentNo: React.Dispatch<React.SetStateAction<string>>
    setBuyerOrderDate: React.Dispatch<React.SetStateAction<string>>
    setBuyerOrderNo: React.Dispatch<React.SetStateAction<string>>
    setRemarks: React.Dispatch<React.SetStateAction<string>>
    formType: FormType
    onSave: () => void
    gudsRecdDate: string
    invoiceDate: string
    invoiceNo: string
    invoiceAmount: string
    purchaseEntryNo: string
    supplier: string
    invoiceType: string
    refBy: string
    orderDate?: string
    orderNo?: string
    dateOfSupply?: string
    placeOfSupply?: string
    transportMode?: string
    transportName?: string
    vehicleNo?: string
    noOfBundles?: string
    noOfQty?: string
    netWeight?: string
    ewayBillNo?: string
    acknowNo?: string
    acknowDate?: string
    irnNo?: string
    irnDate?: string
    remarks?: string
    id?: number
    // showViewForm: boolean
}


export interface Dropdown {
    label: string
    value: string
    name?: string
}

const PurchaseForm = (props: PurchaseFormProps) => {
    const toast = useToast()
    // const supplierList = useSelector<StoreState, SupplierType[]>(state => state.supplier)
    const branchList = useSelector<StoreState, BranchType[]>(state => state.branch)
    const authUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(state => state.account)
    // console.log(authUser);
    const purchaseList = useSelector<StoreState, PurchaseType[]>(state => state.purchase)

    const [purchaseType, setPurchaseType] = useState<string>(PURCHASE_TYPE_DROPS[0].value)
    const [purchaseTypeError, setPurchaseTypeError] = useState<string>('')
    const [purchaseEntryNo, setPurchaseEntryNo] = useState<string>((Math.floor(Math.random() * 90000) + 10000).toString())
    const [purchaseEntryNoError, setPurchaseEntryNoError] = useState<string>('')
    const [goodsRcvdDate, setGoodsRcvdDate] = useState<string>(today())
    const [goodsRcvdDateError, setGoodsRcvdDateError] = useState<string>('')
    const [invoiceDate, setInvoiceDate] = useState<string>(today())
    const [invoiceDateError, setInvoiceDateError] = useState<string>('')
    const [invoiceNo, setInvoiceNo] = useState<string>('')
    const [invoiceNoError, setInvoiceNoError] = useState<string>('')
    const [invoiceAmt, setInvoiceAmt] = useState<string>('')
    const [invoiceAmtError, setInvoiceAmtError] = useState<string>('')
    const [supplier, setSupplier] = useState<Dropdown | null>(null)
    const [supplierError, setSupplierError] = useState<string>('')
    const [refBy, setRefBy] = useState<string>('')
    const [refByError, setRefByError] = useState<string>('')

    // const [supplierSearchTerm, setSupplierSearchTerm] = useState<string>('')

    const supplierList = AccountLists.filter(a => a.groupName === 'Supplier' || a.groupName === 'supplier')

    // const filteredSuppliers = useMemo<SupplierType[]>(() => {

    //     if (supplier?.value !== '') {
    //         // let obj = supplierList.find(sl => sl.id?.toString() === supplier.toString())

    //         return supplierList.filter(s => {
    //             return s?.name?.includes(supplierSearchTerm)
    //         })
    //     } else {
    //         return supplierList
    //     }
    // }, [supplier])

    //New fields state variable
    const [orderDate, setOrderDate] = useState<string>('')
    const [orderNo, setOrderNo] = useState<string>('')
    const [dateOfSupply, setDateOfSupply] = useState<string>('')
    const [placeOfSupply, setPlaceOfSupply] = useState<string>('')
    const [transportMode, setTransportMode] = useState<string>('')
    const [transportName, setTransportName] = useState<string>('')
    const [vehicleNo, setVehicleNo] = useState<string>('')
    const [noOfBundles, setNoOfBundles] = useState<string>('')
    const [noOfQty, setNoOfQty] = useState<string>('')
    const [netWeight, setNetWeight] = useState<string>('')
    const [ewayBillNo, setEwayBillNo] = useState<string>('')
    const [ackNo, setAckNo] = useState<string>('')
    const [ackDate, setAckDate] = useState<string>('')
    const [irnNo, setIrnNo] = useState<string>('')
    const [irnDate, setIrnDate] = useState<string>('')
    const [remarks, setRemarks] = useState<string>('')
    const [orderDateError, setOrderDateError] = useState<string>('')
    const [orderNoError, setOrderNoError] = useState<string>('')
    const [dateOfSupplyError, setDateOfSupplyError] = useState<string>('')
    const [placeOfSupplyError, setPlaceOfSupplyError] = useState<string>('')
    const [transportModeError, setTransportModeError] = useState<string>('')
    const [transportNameError, setTransportNameError] = useState<string>('')
    const [vehicleNoError, setVehicleNoError] = useState<string>('')
    const [noOfBundlesError, setNoOfBundlesError] = useState<string>('')
    const [noOfQtyError, setNoOfQtyError] = useState<string>('')
    const [netWeightError, setNetWeightError] = useState<string>('')
    const [ewayBillNoError, setEwayBillNoError] = useState<string>('')
    const [ackNoError, setAckNoError] = useState<string>('')
    const [ackDateError, setAckDateError] = useState<string>('')
    const [irnNoError, setIrnNoError] = useState<string>('')
    const [irnDateError, setIrnDateError] = useState<string>('')
    const [remarksError, setRemarksError] = useState<string>('')
    const [buyerOrderDate, setBuyerOrderDate] = useState('');
    const [buyerOrderDateError, setBuyerOrderDateError] = useState('');
    const [buyerOrderNo, setBuyerOrderNo] = useState('');
    const [buyerOrderNoError, setBuyerOrderNoError] = useState('');
    const [destination, setDestination] = useState('');
    const [destinationError, setDestinationError] = useState('');
    const [dispatchDocumentNo, setDispatchDocumentNo] = useState('');
    const [dispatchDocumentNoError, setDispatchDocumentNoError] = useState('');
    //focus
    const [focusedField, setFocusedField] = useState<string | null>(null);

    // Refs for each field
    const purchaseTypeRef = useRef<HTMLInputElement>(null);
    const refByRef = useRef<HTMLInputElement>(null);
    const invoiceDateRef = useRef<HTMLInputElement>(null);
    const goodsRcvdDateRef = useRef<HTMLInputElement>(null);
    const supplierRef = useRef(null);
    const invoiceNoRef = useRef<HTMLInputElement>(null);
    const invoiceAmtRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (purchaseTypeRef.current) {
            purchaseTypeRef.current.focus();
            setFocusedField("purchaseType");
        }
    }, []);

    const handleKeyDown = <T extends Element>(
        e: React.KeyboardEvent<T>,
        nextRef: React.RefObject<HTMLDivElement | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        fieldName: string
    ) => {
        if (e.key === 'Enter' && nextRef.current) {
            e.preventDefault();

            // Ensure nextRef.current is a DOM element before calling querySelector
            if (nextRef.current instanceof HTMLElement) {
                const input = nextRef.current.querySelector('input, select, .react-select__input');
                if (input) {
                    (input as HTMLElement).focus(); // Focus the found element
                }
            }

            setFocusedField(fieldName); // Update the focused field
        }
    };


    const resetStates = () => {
        setPurchaseType('')
        setPurchaseTypeError('')
        setPurchaseEntryNo('')
        setPurchaseEntryNoError('')
        setGoodsRcvdDate(today())
        setGoodsRcvdDateError('')
        setInvoiceDate(today())
        setInvoiceDateError('')
        setSupplier(null)
        setSupplierError('')
        setInvoiceNo('')
        setInvoiceNoError('')
        setOrderDate('')
        setOrderDateError('')
        setOrderNo('')
        setOrderNoError('')
        setDateOfSupply('')
        setDateOfSupplyError('')
        setPlaceOfSupply('')
        setPlaceOfSupplyError('')
        setTransportMode('')
        setTransportModeError('')
        setTransportName('')
        setTransportNameError('')
        setVehicleNo('')
        setVehicleNoError('')
        setNoOfBundles('')
        setNoOfBundlesError('')
        setNoOfQty('')
        setNoOfQtyError('')
        setNetWeight('')
        setNetWeightError('')
        setEwayBillNo('')
        setEwayBillNoError('')
        setAckNo('')
        setAckNoError('')
        setAckDate('')
        setAckDateError('')
        setIrnNo('')
        setIrnNoError('')
        setIrnDate('')
        setIrnDateError('')
        setRemarks('')
        setRemarksError('')
        setBuyerOrderDate('')
        setBuyerOrderDateError('')
        setBuyerOrderNo('')
        setBuyerOrderNoError('')
        setDestination('')
        setDestinationError('')
        setDispatchDocumentNo('')
        setDispatchDocumentNoError('')
    }
    const handlePurchaseType: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPurchaseType(e.target.value)
        setPurchaseTypeError('')
    }

    const handleInvoiceDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInvoiceDate(e.target.value)
        setInvoiceDateError('')
    }

    const handleGoodsRcvdDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGoodsRcvdDate(e.target.value)
        setGoodsRcvdDateError('')
    }

    const handleInvoiceNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInvoiceNo(e.target.value)
        setInvoiceNoError('')
    }
    const handleInvoiceAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInvoiceAmt(e.target.value)
        setInvoiceAmtError('')
    }
    const handleRefBy: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRefBy(e.target.value)
        setRefByError('')
    }

    // const handleSupplier = (value: string) => {
    //     setSupplier(value)
    //     setSupplierError('')
    // }

    const handlePurchaseEntryNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPurchaseEntryNo(e.target.value)
        setPurchaseEntryNoError('')
    }

    const handleOrderDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOrderDate(e.target.value)
        setOrderDateError('')
    }

    const handleOrderNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOrderNo(e.target.value)
        setOrderNoError('')
    }

    const handleDateOfSupply: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDateOfSupply(e.target.value)
        setDateOfSupplyError('')
    }

    const handlePlaceOfSupply: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPlaceOfSupply(e.target.value)
        setPlaceOfSupplyError('')
    }

    const handleTransportMode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTransportMode(e.target.value)
        setTransportModeError('')
    }

    const handleTransportName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTransportName(e.target.value)
        setTransportNameError('')
    }

    const handleVehicleNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVehicleNo(e.target.value);
        setVehicleNoError('');
    }

    const handleNoOfBundles: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNoOfBundles(e.target.value);
        setNoOfBundlesError('');
    }

    const handleNoOfQty: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNoOfQty(e.target.value);
        setNoOfQtyError('');
    }

    const handleNetWeight: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNetWeight(e.target.value);
        setNetWeightError('');
    }

    const handleEwayBillNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEwayBillNo(e.target.value);
        setEwayBillNoError('');
    }

    const handleAckNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAckNo(e.target.value);
        setAckNoError('');
    }

    const handleAckDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAckDate(e.target.value);
        setAckDateError('');
    }

    const handleIrnNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setIrnNo(e.target.value);
        setIrnNoError('');
    }

    const handleIrnDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setIrnDate(e.target.value);
        setIrnDateError('');
    }

    const handleRemarks: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRemarks(e.target.value);
        setRemarksError('');
    }

    const handleBuyerOrderDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBuyerOrderDate(e.target.value);
        setBuyerOrderDateError('');
    };

    const handleBuyerOrderNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBuyerOrderNo(e.target.value);
        setBuyerOrderNoError('');
    };

    const handleDestination: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDestination(e.target.value);
        setDestinationError('');
    };

    const handleDispatchDocumentNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDispatchDocumentNo(e.target.value);
        setDispatchDocumentNoError('');
    };

    const handleSave = () => {
        let error = false
        const data = {
            purchaseType: purchaseType,
            purchaseEntryNo: purchaseEntryNo,
            invoiceDate: invoiceDate,
            goodsRcvdDate: goodsRcvdDate,
            invoiceNo: invoiceNo,
            invoiceAmt: invoiceAmt,
            supplier: supplier
        }

        if (data.purchaseType === '') {
            setPurchaseTypeError('Purchase Type required')
            error = true
        }
        // else if ((data.purchaseType).length > 15) {
        //     setPurchaseTypeError('PurchaseType should be within 30 characters')
        //     error = true
        // }

        if (data.supplier === null || data.supplier?.value === "") {
            setSupplierError('Supplier required')
            toast('Supplier required', ToastTypes.ERROR)
            error = true
        }

        if (data.invoiceNo === '') {
            setInvoiceNoError('Invoice No required')
            error = true
        }

        if (data.invoiceAmt === '') {
            setInvoiceAmtError('Invoice Amount required')
            error = true
        } else if (parseFloat(data.invoiceAmt) <= 0) {
            // setInvoiceAmtError('Enter Valid Invoice Amount')
            toast('Enter Valid Invoice Amount', ToastTypes.ERROR)
            error = true
        }
        // else if ((data.invoiceNo) > 15) {
        //     setInvoiceNoError('Invoice No should be within 15 digits')
        //     error = true
        // }

        if (data.invoiceDate === '') {
            setInvoiceDateError('Invoice date required')
            error = true
        } else if ((data.invoiceDate).length > 10) {
            setInvoiceDateError('Invoice date should be within 10 digits')
            error = true
        }

        if (data.goodsRcvdDate === '') {
            setGoodsRcvdDateError('Goods Received Date required')
            error = true
        } else if ((data.goodsRcvdDate)?.length > 10) {
            setGoodsRcvdDateError('Goods Received Date should be within 10 digits')
            error = true
        }

        if (data.purchaseEntryNo === '') {
            setPurchaseEntryNoError('Purchase Entry No required')
            error = true
        } else if ((data.purchaseEntryNo).length > 10) {
            setPurchaseEntryNoError('Purchase Entry No should be within 10 digits')
            error = true
        }

        //new model fields check
        if (orderNo !== '' && orderNo.length > 15) {
            setOrderNoError('Order no should be within 15 character')
            error = true
        }
        if (placeOfSupply !== '' && placeOfSupply.length > 30) {
            setPlaceOfSupplyError('Place of supply should be within 30 character')
            error = true
        }
        if (transportMode !== '' && transportMode.length > 30) {
            setTransportModeError('Transport mode should be within 30 character')
            error = true
        }
        if (transportName !== '' && transportName.length > 30) {
            setTransportNameError('Transport name should be within 30 character')
            error = true
        }
        if (vehicleNo !== '' && vehicleNo.length > 30) {
            setVehicleNoError('vehicle no should be within 30 character')
            error = true
        }
        if (noOfBundles !== '' && noOfBundles.length > 11) {
            setNoOfBundles('No of Bundles should be within 11 character')
            error = true
        }
        if (noOfQty !== '' && noOfQty.length > 11) {
            setNoOfQty('No of qty should be within 11 character')
            error = true
        }
        if (ewayBillNo !== '' && ewayBillNo.length > 25) {
            setEwayBillNoError('Eway bill no should be within 25 character')
            error = true
        }
        if (ackNo !== '' && ackNo.length > 25) {
            setAckNoError('Acknowledgement no should be within 25 character')
            error = true
        }
        if (irnNo !== '' && irnNo.length > 25) {
            setIrnNoError('IRN no should be within 25 character')
            error = true
        }
        if (remarks !== '' && remarks.length > 250) {
            setRemarksError('Remarks must be within 250 characters')
            error = true
        }

        if (!error) {
            const invoiceYear = (new Date(invoiceDate)).getFullYear();
            const uniqueCheck = `${invoiceYear}-${invoiceNo}-${supplier?.value}`;


            const isDuplicateCombination = purchaseList.some(purchase => {
                const purchaseCombination = `${purchase.invoiceYear}-${purchase.invoiceNumber}-${purchase.supplierId}`;
                // console.log(purchaseCombination === uniqueCheck)
                if (props.formType === FormTypeList.EDIT && props.id) {
                    return purchaseCombination === uniqueCheck && props.id !== purchase.id;
                } else {
                    return purchaseCombination === uniqueCheck;
                }
            });
            if (isDuplicateCombination) {
                toast('Duplicate combination of year, invoice no and supplier detected', ToastTypes.ERROR);
                return;
            }
        }

        //new model fields check
        if (orderNo !== '' && orderNo.length > 15) {
            setOrderNoError('Order no should be within 15 character')
            error = true
        }
        if (placeOfSupply !== '' && placeOfSupply.length > 30) {
            setPlaceOfSupplyError('Place of supply should be within 30 character')
            error = true
        }
        if (transportMode !== '' && transportMode.length > 30) {
            setTransportModeError('Transport mode should be within 30 character')
            error = true
        }
        if (transportName !== '' && transportName.length > 30) {
            setTransportNameError('Transport name should be within 30 character')
            error = true
        }
        if (vehicleNo !== '' && vehicleNo.length > 30) {
            setVehicleNoError('vehicle no should be within 30 character')
            error = true
        }
        if (noOfBundles !== '' && noOfBundles.length > 11) {
            setNoOfBundles('No of Bundles should be within 11 character')
            error = true
        }
        if (noOfQty !== '' && noOfQty.length > 11) {
            setNoOfQty('No of qty should be within 11 character')
            error = true
        }
        if (ewayBillNo !== '' && ewayBillNo.length > 11) {
            setEwayBillNoError('Eway bill no should be within 15 character')
            error = true
        }
        if (ackNo !== '' && ackNo.length > 11) {
            setAckNoError('Acknowledgement no should be within 15 character')
            error = true
        }
        if (irnNo !== '' && irnNo.length > 11) {
            setIrnNoError('IRN no should be within 15 character')
            error = true
        }
        if (remarks !== '' && remarks.length > 250) {
            setRemarksError('Remarks must be within 250 characters')
            error = true
        }

        if (buyerOrderNo !== '' && buyerOrderNo.length > 25) {
            setBuyerOrderNoError('Buyer order no should be within 25 characters');
            error = true;
        }

        if (destination !== '' && destination.length > 25) {
            setDestinationError('Destination should be within 25 characters');
            error = true;
        }

        if (dispatchDocumentNo !== '' && dispatchDocumentNo.length > 25) {
            setDispatchDocumentNoError('Dispatch document no should be within 25 characters');
            error = true;
        }

        if (!error) {

            const sobj = AccountLists.find(a => a.accountNumber?.toString() === supplier?.value)
            let sname = sobj?.accountName
            let sadd = sobj?.address1! + sobj?.address2
            let scont = sobj?.contactNumber1
            let saadhar = sobj?.aadharNumber
            let sgst = sobj?.gstNumber
            let splace = sobj?.supplierPlace

            let bobj = branchList.find(b => b.id?.toString() === authUser?.branchId?.toString())
            // console.log(bobj);
            let bname = bobj?.name

            props.setInvDate(invoiceDate)
            props.setGudsRecdDate(goodsRcvdDate)
            props.setInvNo(invoiceNo)
            props.setInvoiceTitle('WHOLESALE')
            props.setInvoiceType(purchaseType)

            props.setSupName(sname ? sname : '')
            props.setSupContact(scont ? scont.toString() : '')
            props.setSupAddress(sadd ? sadd : '')
            props.setSupAadhar(saadhar ? saadhar.toString() : '')
            props.setGST(sgst ? sgst : '')

            props.setBilledBy(authUser?.name ? authUser?.name : '')
            props.setSysNo('SYS 1')
            props.setSupPlace(splace ? splace : '')
            props.setRefBy(refBy)
            props.setBranch(bname ? bname : '')
            props.setInvoiceAmount(invoiceAmt)
            props.setPurchaseEntryNo(purchaseEntryNo)
            props.setSupplier(supplier?.value ? supplier?.value : '')
            //
            props.setOrderDate(orderDate)
            props.setOrderNo(orderNo)
            props.setDateOfSupply(dateOfSupply)
            props.setPlaceOfSupply(placeOfSupply)
            props.setTransportMode(transportMode)
            props.setTransportName(transportName)
            props.setVehicleNo(vehicleNo)
            props.setNoOfBundles(noOfBundles ? noOfBundles : '')
            props.setNoOfQty(noOfQty ? noOfQty : '')
            props.setNetWeight(netWeight ? netWeight : '')
            props.setEwayBillNo(ewayBillNo)
            props.setAckNo(ackNo)
            props.setAckDate(ackDate)
            props.setIrnNo(irnNo)
            props.setIrnDate(irnDate)
            props.setRemarks(remarks)
            props.setDestination(destination);
            props.setDispatchDocumentNo(dispatchDocumentNo);
            props.setBuyerOrderDate(buyerOrderDate);
            props.setBuyerOrderNo(buyerOrderNo);

            props.onSave()
        }
        // if (!error) {
        //     if (formType === FormTypeList.ADD) {
        //         dispatch(addBrand(data)).then(text => {
        //             toast(text, ToastTypes.SUCCESS)
        //             onSave()
        //         }).catch(text => {
        //             toast(text, ToastTypes.ERROR)
        //         })
        //     } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
        //         dispatch(editBrand(data, editData.id)).then(text => {
        //             toast(text, ToastTypes.SUCCESS)
        //             onSave()
        //         }).catch(text => {
        //             toast(text, ToastTypes.ERROR)
        //         })
        //     }
        // }
    }

    useEffect(() => {
        if ((props.formType === FormTypeList.EDIT) && props !== undefined) {
            let sobj = supplierList.find(s => s.accountNumber?.toString() === props.supplier?.toString())
            setPurchaseType(props.invoiceType)
            setPurchaseEntryNo(props.purchaseEntryNo)
            setGoodsRcvdDate(props.gudsRecdDate)
            setInvoiceDate(props.invoiceDate)
            setSupplier({
                label: sobj?.accountName ? sobj?.accountName : '',
                value: sobj?.accountNumber ? sobj?.accountNumber.toString() : ''
            })
            setInvoiceNo(props.invoiceNo)
            setRefBy(props.refBy)
            setInvoiceAmt(props.invoiceAmount)
            setOrderDate(props.orderDate || '');
            setOrderNo(props.orderNo || '');
            setDateOfSupply(props.dateOfSupply || '');
            setPlaceOfSupply(props.placeOfSupply || '');
            setTransportMode(props.transportMode || '');
            setTransportName(props.transportName || '');
            setVehicleNo(props.vehicleNo || '');
            setNoOfBundles(props.noOfBundles || '');
            setNoOfQty(props.noOfQty || '');
            setNetWeight(props.netWeight || '');
            setEwayBillNo(props.ewayBillNo || '');
            setAckNo(props.acknowNo || '');
            setAckDate(props.acknowDate || '');
            setIrnNo(props.irnNo || '');
            setIrnDate(props.irnDate || '');
            setRemarks(props.remarks || '');
            setDestination(props.destination || '');
            setDispatchDocumentNo(props.dispatchDocumentNo || '');
            setBuyerOrderDate(props.buyerOrderDate || '');
            setBuyerOrderNo(props.buyerOrderNo || '');

        } else if (props.formType === FormTypeList.ADD && props !== undefined && props.invoiceType !== '') {
            let sobj = supplierList.find(s => s.accountNumber?.toString() === props.supplier?.toString())
            console.log(props)
            setPurchaseType(props.invoiceType)
            setPurchaseEntryNo(props.purchaseEntryNo)
            setGoodsRcvdDate(props.gudsRecdDate)
            setInvoiceDate(props.invoiceDate)
            setSupplier({
                label: sobj?.accountName ? sobj?.accountName : '',
                value: sobj?.accountNumber ? sobj?.accountNumber.toString() : ''
            })
            setInvoiceNo(props.invoiceNo)
            setRefBy(props.refBy)
            setInvoiceAmt(props.invoiceAmount)
            setOrderDate(props.orderDate || '');
            setOrderNo(props.orderNo || '');
            setDateOfSupply(props.dateOfSupply || '');
            setPlaceOfSupply(props.placeOfSupply || '');
            setTransportMode(props.transportMode || '');
            setTransportName(props.transportName || '');
            setVehicleNo(props.vehicleNo || '');
            setNoOfBundles(props.noOfBundles || '');
            setNoOfQty(props.noOfQty || '');
            setNetWeight(props.netWeight || '');
            setEwayBillNo(props.ewayBillNo || '');
            setAckNo(props.acknowNo || '');
            setAckDate(props.acknowDate || '');
            setIrnNo(props.irnNo || '');
            setIrnDate(props.irnDate || '');
            setRemarks(props.remarks || '');
            setDestination(props.destination || '');
            setDispatchDocumentNo(props.dispatchDocumentNo || '');
            setBuyerOrderDate(props.buyerOrderDate || '');
            setBuyerOrderNo(props.buyerOrderNo || '');
        }

    }, [props.formType])

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            // background: '#fff',
            // borderColor: '#9e9e9e',
            minHeight: '25px',
            height: '25px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '25px',
            padding: '0 5px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    return <div className='container' style={{ backgroundColor: '#B1D7E9' }}>
        <div className="row">
            <div className="col rounded" style={{ backgroundColor: '#1586BE' }}>
                <FormInput
                    name='Purchase Type'
                    label='Purchase Type'
                    labelClassName="required text-white"
                    value={purchaseType}
                    onChange={handlePurchaseType}
                    placeholder='Purchase Type'
                    errorText={purchaseTypeError}
                    containerClass="mb-2"
                    ref={purchaseTypeRef}
                    onKeyDown={(e) => handleKeyDown(e, refByRef, 'refBy')}
                    className={focusedField === 'purchaseType' ? "border border-4 border-primary rounded" : ""}
                    // disabled={showViewForm}
                    type="select"
                >
                    <option value="">Select</option>
                    {PURCHASE_TYPE_DROPS.map((a, i) => {
                        return <Option value={a.value.toString()} key={i}>{a.text}</Option>
                    })}
                </FormInput>

                <FormInput
                    name='Referred By'
                    label='Referred By'
                    labelClassName="required text-white"
                    value={refBy}
                    onChange={handleRefBy}
                    placeholder='Referred By'
                    errorText={refByError}
                    containerClass="mb-2"
                    ref={refByRef}
                    onKeyDown={(e) => handleKeyDown(e, invoiceDateRef, 'invoiceDate')}
                    className={focusedField === 'refBy' ? "border border-4 border-primary rounded" : ""}
                    // disabled={showViewForm}
                    type="select"
                >
                    <option value="">Select</option>
                    {AccountLists.filter((al) => al.groupName === 'representer' || al.groupName === 'Representer').map((al, i) => {
                        return <option value={al.accountNumber} key={i}>{al.accountName}</option>
                    })
                    }
                </FormInput>

                <FormInput
                    name='Invoice Date'
                    label='Invoice Date'
                    labelClassName="required text-white"
                    value={invoiceDate}
                    onChange={handleInvoiceDate}
                    placeholder='Invoice Date'
                    errorText={invoiceDateError}
                    ref={invoiceDateRef}
                    onKeyDown={(e) => handleKeyDown(e, goodsRcvdDateRef, 'goodsRcvdDate')}
                    className={focusedField === 'invoiceDate' ? "border border-4 border-primary rounded" : ""}
                    containerClass="mb-2"
                    type="date"
                // disabled={showViewForm}
                />

                <FormInput
                    name='Goods Received Date'
                    label='Goods Received Date'
                    labelClassName="required text-white"
                    value={goodsRcvdDate}
                    onChange={handleGoodsRcvdDate}
                    placeholder='Goods Received Date'
                    errorText={goodsRcvdDateError}
                    containerClass="mb-2"
                    ref={goodsRcvdDateRef}
                    onKeyDown={(e) => handleKeyDown(e, supplierRef, 'supplier')}
                    className={focusedField === 'goodsRcvdDate' ? "border border-4 border-primary rounded" : ""}
                    type="date"
                // disabled={showViewForm}
                />


                <label className="text-white" >Supplier</label>
                <Select
                    options={supplierList.map(f => {
                        return {
                            label: f?.accountName ? f?.accountName : '',
                            value: f?.accountNumber?.toString() || ''
                        }
                    })}
                    isSearchable={true}
                    isClearable={true}
                    onChange={v => setSupplier(v)}
                    value={supplier}
                    styles={customStyles}
                    ref={supplierRef}
                    onKeyDown={(e) => handleKeyDown(e, invoiceNoRef, 'invoiceNo')}
                    className={focusedField === 'supplier' ? "border border-4 border-primary rounded" : ""}
                />

                <FormInput
                    name='Purchase Entry No'
                    label='Purchase Entry No (AUTO NO)'
                    labelClassName="required text-white"
                    value={purchaseEntryNo}
                    onChange={handlePurchaseEntryNo}
                    placeholder='Purchase Entry No'
                    errorText={purchaseEntryNoError}
                    containerClass="mt-2 mb-2"
                    style={{ backgroundColor: '#DBF3DF' }}
                    disabled
                // disabled={showViewForm}
                />

                <FormInput
                    name='Invoice No'
                    label='Invoice No'
                    labelClassName="required text-white"
                    value={invoiceNo}
                    onChange={handleInvoiceNo}
                    placeholder='Invoice No'
                    errorText={invoiceNoError}
                    containerClass="mb-2"
                    ref={invoiceNoRef}
                    onKeyDown={(e) => handleKeyDown(e, invoiceAmtRef, 'invoiceAmt')}
                    className={focusedField === 'invoiceNo' ? "border border-4 border-primary rounded" : ""}
                // disabled={showViewForm}
                />

                <FormInput
                    name='Invoice Grand Total'
                    label='Invoice Grand Total'
                    labelClassName="required text-white"
                    value={invoiceAmt}
                    onChange={handleInvoiceAmt}
                    placeholder='Enter bill amount'
                    errorText={invoiceAmtError}
                    containerClass="mb-2"
                    type="number"
                    ref={invoiceAmtRef}
                    onKeyDown={(e) => handleKeyDown(e, invoiceAmtRef, 'invoiceAmt')}
                    className={focusedField === 'invoiceAmt' ? "border border-4 border-primary rounded" : ""}
                // disabled={showViewForm}
                />

                {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Supplier'
                    label='Supplier'
                    labelClassName="required"
                    value={supplierSearchTerm}
                    onChange={handleSupplier}
                    placeholder='Supplier'
                    errorText={supplierError}
                    containerClass="mb-2"
                    // disabled={showViewForm}
                    type="select"
                >
                    <option value="">Select</option>
                    {filteredSuppliers.map((a, i) => {
                        return <Option value={a.id} key={i}>{a.name}</Option>
                    })}
                </FormInput>
            </div> */}

                {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <div className="form-control border-0">
                    <label className="pb-2">Brand Logo</label>
                    <div className="border rounded border-2 border-secondray logo-lg pt-0 pb-1 m-0 w-75">
                        <img src={LogoDark} alt="" height="22" />
                    </div>
                    <div className="hstack gap-1 mt-1">
                        <button className="btn btn-secondary">Browser</button>
                        <button className="btn btn-secondary">Clear</button>
                    </div>
                </div>
            </div> */}
            </div>

            <div className="col">
                <FormInput
                    label="Order Date/Reference Date"
                    title="Order Date/Reference Date"
                    type="date"
                    name="orderDate"
                    value={orderDate}
                    containerClass="mb-2"
                    onChange={handleOrderDate}
                    errorText={orderDateError}
                />
                <FormInput
                    label="Order No/Reference No"
                    title="Order No/Reference No"
                    type="text"
                    name="orderNo"
                    value={orderNo}
                    containerClass="mb-2"
                    onChange={handleOrderNo}
                    errorText={orderNoError}
                />
                <FormInput
                    label="Buyer Order Date"
                    title="Buyer Order Date"
                    type="date"
                    name="buyerOrderDate"
                    value={buyerOrderDate}
                    containerClass="mb-2"
                    onChange={handleBuyerOrderDate}
                    errorText={buyerOrderDateError}
                />
                <FormInput
                    label="Buyer Order No"
                    title="Buyer Order No"
                    type="text"
                    name="buyerOrderNo"
                    value={buyerOrderNo}
                    containerClass="mb-2"
                    onChange={handleBuyerOrderNo}
                    errorText={buyerOrderNoError}
                />
                <FormInput
                    label="Date of Supply/Delivery Date"
                    title="Date of Supply/Delivery Date"
                    type="date"
                    name="dateOfSupply"
                    value={dateOfSupply}
                    containerClass="mb-2"
                    onChange={handleDateOfSupply}
                    errorText={dateOfSupplyError}
                />
                <FormInput
                    label="Place of Supply"
                    title="Place of Supply"
                    type="text"
                    name="placeOfSupply"
                    value={placeOfSupply}
                    containerClass="mb-2"
                    onChange={handlePlaceOfSupply}
                    errorText={placeOfSupplyError}
                />
                <FormInput
                    label="Transport Mode/Dispatch Mode"
                    title="Transport Mode/Dispatch Mode"
                    type="text"
                    name="transportMode"
                    value={transportMode}
                    containerClass="mb-2"
                    onChange={handleTransportMode}
                    errorText={transportModeError}
                />
                <FormInput
                    label="Transport Name/Dispatch Name"
                    title="Transport Name/Dispatch Name"
                    type="text"
                    name="transportName"
                    value={transportName}
                    containerClass="mb-2"
                    onChange={handleTransportName}
                    errorText={transportNameError}
                />
                <FormInput
                    label="Vehicle No"
                    title="Vehicle No"
                    type="text"
                    name="vehicleNo"
                    value={vehicleNo}
                    containerClass="mb-2"
                    onChange={handleVehicleNo}
                    errorText={vehicleNoError}
                />
                <FormInput
                    label="No of Bundles"
                    title="No of Bundles"
                    type="number"
                    name="noOfBundles"
                    value={noOfBundles}
                    containerClass="mb-2"
                    onChange={handleNoOfBundles}
                    errorText={noOfBundlesError}
                />
            </div>
            <div className="col">
                <FormInput
                    label="No of Qty"
                    title="No of Qty"
                    type="number"
                    name="noOfQty"
                    value={noOfQty}
                    containerClass="mb-2"
                    onChange={handleNoOfQty}
                    errorText={noOfQtyError}
                />
                <FormInput
                    label="Net Weight"
                    title="Net Weight"
                    type="number"
                    name="netWeight"
                    value={netWeight}
                    containerClass="mb-2"
                    onChange={handleNetWeight}
                    errorText={netWeightError}
                />
                <FormInput
                    label="Destination"
                    title="Destination"
                    type="text"
                    name="destination"
                    value={destination}
                    containerClass="mb-2"
                    onChange={handleDestination}
                    errorText={destinationError}
                />
                <FormInput
                    label="Dispatch Document No"
                    title="Dispatch Document No"
                    type="text"
                    name="dispatchDocumentNo"
                    value={dispatchDocumentNo}
                    containerClass="mb-2"
                    onChange={handleDispatchDocumentNo}
                    errorText={dispatchDocumentNoError}
                />
                <FormInput
                    label="Eway Bill No"
                    title="Eway Bill No"
                    type="text"
                    name="ewayBillNo"
                    value={ewayBillNo}
                    containerClass="mb-2"
                    onChange={handleEwayBillNo}
                    errorText={ewayBillNoError}
                />
                <FormInput
                    label="Acknowledgement No"
                    title="Acknowledgement No"
                    type="text"
                    name="ackNo"
                    value={ackNo}
                    containerClass="mb-2"
                    onChange={handleAckNo}
                    errorText={ackNoError}
                />
                <FormInput
                    label="Acknowledgement Date"
                    title="Acknowledgement Date"
                    type="date"
                    name="ackDate"
                    value={ackDate}
                    containerClass="mb-2"
                    onChange={handleAckDate}
                    errorText={ackDateError}
                />
                <FormInput
                    label="IRN No"
                    title="IRN No"
                    type="text"
                    name="irnNo"
                    value={irnNo}
                    containerClass="mb-2"
                    onChange={handleIrnNo}
                    errorText={irnNoError}
                />
                <FormInput
                    label="IRN Date"
                    title="IRN Date"
                    type="date"
                    name="irnDate"
                    value={irnDate}
                    containerClass="mb-2"
                    onChange={handleIrnDate}
                    errorText={irnDateError}
                />
                <FormInput
                    label="Remarks/Terms of delivery"
                    title="Remarks/Terms of delivery"
                    type="text"
                    name="remarks"
                    value={remarks}
                    containerClass="mb-2"
                    onChange={handleRemarks}
                    errorText={remarksError}
                />
            </div>

        </div >

        <div className="d-flex justify-content-end hstack gap-1 my-2">
            <button className="btn btn-sm fw-bold btn-secondary fw-bold" onClick={() => resetStates()}>Add New</button>
            <button className="btn btn-sm fw-bold btn-secondary fw-bold" onClick={handleSave}>Save</button>
        </div>
    </div >
}
export default PurchaseForm