import { useEffect, useState } from "react";
import { Card, Table, Button } from "react-bootstrap";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import ReportFilter from "./ReportFilter";
import api from '../../config/api';
import { getDate, rupeeFormat, LocalFormatDate } from "../../config/functions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../redux/store";
import { ClientRegistrationType, fetchClientRegistrationAction } from "../../redux/actions/clientRegistration";
import { apiUrl } from "../../config";
import bgMaterial from "../../components/custom/assets/images/bg-material.png";
import SalesReturnReportFilter from "./SalesReturnReportFilter";

interface SaleRep {
    financialYear: string;
    repName: string;
    invoiceDate: string;
    invoiceDay: string;
    invoiceNo: string;
    customerName: string;
    grandTotal: number;
    // taxable: number;
    // igst: number;
    // sgst: number;
    // cgst: number;
    // total: number;
    // addAmount: number;
    // lessAmount: number;
    // netTotal: number;
}

interface SalesRepWiseReport {
    financialYear: string;
    repName: string;
    saleData: SaleRep[];
}

const SalesReturnRepresenterBillWise = () => {
    const dispatch = useDispatch<AppDispatch>()

    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [repId, setRepId] = useState<string>("");
    const clients = useSelector<StoreState, ClientRegistrationType[]>(state => state.clientRegistration)

    const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

    const [tableData, setTableData] = useState<SalesRepWiseReport[]>([]);
    const [repName, setrepName] = useState('')

    useEffect(() => {
        dispatch(fetchClientRegistrationAction())
    }, [])

    useEffect(() => {
        if (triggerSearch) {

            const response = api().get(
                `reports/salesReturnRepresenterWise/?fromDate=${getDate(fromDate)}&toDate=${getDate(toDate)}&repId=${repId}`
            );

            response.then((res) => {
                if (res.status === 200 && res.data.data) {
                    console.log("API Response:", res.data);
                    setTableData(res.data.data);
                }
            }).catch(error => {
                console.error("Error fetching sales data:", error);
            });

            setTriggerSearch(false);
        }
    }, [triggerSearch, fromDate, toDate, repId]);

    const [filters, setFilters] = useState<any>({});

    const handleSearch = (newFilters: any) => {
        setFilters(newFilters);
        setFromDate(newFilters.fromDate);
        setToDate(newFilters.toDate);
        setRepId(newFilters.repId);

        setTriggerSearch(true);
    };

    const downloadCSV = () => {
        const header = [
            "SI.No", "Financial Year", "Rep Name", "Invoice Date", "Invoice Day",
            "Invoice No", "Customer Name", "Grand Total"
        ];
        let csvContent = header.join(",") + "\n";

        tableData.forEach((item) => {

            csvContent += `Financial Year: ${item.financialYear}\n`;
            csvContent += `Rep Name: ${item.repName}\n`;


            item.saleData.forEach((sales, subIndex) => {
                csvContent += [
                    subIndex + 1,
                    item.financialYear,
                    item.repName,
                    sales.invoiceDate,
                    sales.invoiceDay,
                    sales.invoiceNo,
                    sales.customerName,
                    sales.grandTotal
                ].join(",") + "\n";
            });


            const totalGrandTotal = item.saleData.reduce(
                (sum, sales) => +sum + +sales.grandTotal,
                0
            );
            csvContent += `,,,,,,Total Grand Total,${totalGrandTotal}\n\n`;
        });

        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "sales_return_representer_report.csv";
        link.click();
    };

    const downloadPDF = () => {
        const logoUrl = clients[0]?.shopPhoto;
        const doc = new jsPDF("landscape");
        const marginX = 10;
        const boxWidth = doc.internal.pageSize.width - 2 * marginX;
        const boxHeight = 25;
        let startY = 15;

        // Header Section
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.rect(marginX, startY - 5, boxWidth, boxHeight);
        if (logoUrl) {
            doc.addImage(logoUrl, "JPEG", marginX + 5, startY - 2, 25, 20);
        }
        const paddingBetweenHeaderAndTable = 5;
        const textX = marginX + 35;
        doc.text(clients[0]?.shopShortName || "", textX, startY);
        doc.setFont("helvetica", "normal");
        doc.text(
            `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${clients[0]?.district || ""}`,
            textX,
            startY + 5
        );
        doc.text(
            `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${clients[0]?.contactNo1 || ""}`,
            textX,
            startY + 10
        );
        doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 15);

        // Report Title
        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        const heading = "Sales Return Representer Wise Report";
        const headingWidth = doc.getTextWidth(heading);
        const pageWidth = doc.internal.pageSize.width;
        doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 8);

        // Financial Year and Date Range
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const financialYearText = `Financial Year: ${tableData[0]?.financialYear || ""}`;
        const headingStartX = marginX + boxWidth - headingWidth - 10;
        doc.text(financialYearText, headingStartX, startY + 13);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        const formattedFromDate = "From : " + (LocalFormatDate(getDate(fromDate)) || "");
        const formattedToDate = "To : " + (LocalFormatDate(getDate(toDate)) || "");
        const tableStartY = 12 + boxHeight + paddingBetweenHeaderAndTable;
        doc.text(formattedFromDate, marginX, tableStartY);
        doc.text(formattedToDate, marginX + 50, tableStartY);

        // Table Data
        let bodyData: any = [];
        let serialNumber = 1;

        tableData.forEach((item) => {
            // Add Representer Name as a header row
            bodyData.push([
                {
                    content: `Rep Name: ${(item.repName)}`,
                    colSpan: 8,
                    styles: { fontStyle: "bold", halign: "center" }
                }
            ]);

            // Add sales data rows
            item.saleData.forEach((sales) => {
                bodyData.push([
                    serialNumber++,
                    item.repName,
                    sales.invoiceDate,
                    sales.invoiceDay,
                    sales.invoiceNo,
                    sales.customerName,
                    rupeeFormat(sales.grandTotal),
                ]);
            });

            const totalGrandTotal = item.saleData.reduce(
                (sum, sales) => +sum + +sales.grandTotal,
                0
            );
            bodyData.push([
                {
                    content: "Total Grand Total",
                    colSpan: 6,
                    styles: { fontStyle: "bold", halign: "right" }
                },
                rupeeFormat(totalGrandTotal)
            ]);
        });

        // Generate Table
        autoTable(doc, {
            startY: startY + 32,
            head: [
                [
                    "SI.No", "Rep Name", "Invoice Date", "Invoice Day",
                    "Invoice No", "Customer Name", "Grand Total"
                ]
            ],
            body: bodyData,
            theme: "grid",
            styles: { fontSize: 8, textColor: 0, lineColor: 0, lineWidth: 0.1 },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: 0,
                fontStyle: "bold",
                lineColor: 0,
                lineWidth: 0.1,
            },
            columnStyles: {
                0: { halign: "left" }, // SI.No
                1: { halign: "left" }, // Financial Year
                2: { halign: "left" }, // Rep Name
                3: { halign: "left" }, // Invoice Date
                4: { halign: "left" }, // Invoice Day
                5: { halign: "left" }, // Invoice No
                6: { halign: "right" }, // Grand Total
            },
            margin: { top: 25, left: 10, right: 10 }
        });

        // Footer Section (Page Number)
        const pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            let pageHeight = doc.internal.pageSize.height;
            let footerY = pageHeight - 15;
            doc.setFontSize(10);
            doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, footerY);
        }

        // Save PDF
        doc.save("sales_return_representer_wise_report.pdf");
    };
    return (
        <>
            <SalesReturnReportFilter onSearch={handleSearch} from="Return Representer Wise" />
            {tableData.length > 0 ?
                <Card className="mt-2 p-2 shadow-lg border-0 rounded-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5 className="fw-bold">Sales Return Representer wise details</h5>
                        <div>
                            <Button className="me-2 btn-sm" variant="success" onClick={downloadCSV}>
                                Download CSV
                            </Button>
                            <Button className="me-2 btn-sm" variant="success" onClick={downloadPDF}>
                                Download PDF
                            </Button>
                        </div>
                    </div>
                    {tableData?.map((item, index) => {
                        const totalGrandTotal = item.saleData.reduce(
                            (sum, sales) => +sum + +sales.grandTotal,
                            0
                        );

                        return (
                            <div key={index}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className="text-start">SI.No</th>
                                            <th className="text-start">Financial Year</th>
                                            <th className="text-start">Rep Name</th>
                                            <th className="text-start">Invoice Date</th>
                                            <th className="text-start">Invoice Day</th>
                                            <th className="text-start">Invoice No</th>
                                            <th className="text-start">Customer Name</th>
                                            <th>Grand Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item?.saleData?.map((sales, subIndex) => (
                                            <tr key={`${index}-${subIndex}`}>
                                                <td>{subIndex + 1}</td>
                                                <td>{item.financialYear}</td>
                                                <td>{item.repName}</td>
                                                <td>{sales.invoiceDate}</td>
                                                <td>{sales.invoiceDay}</td>
                                                <td>{sales.invoiceNo}</td>
                                                <td>{sales.customerName}</td>
                                                <td className="text-end">{rupeeFormat(sales.grandTotal)}</td>
                                            </tr>
                                        ))}
                                        {/* Add a new row to display the total grandTotal */}
                                        <tr>
                                            <td colSpan={7} className="text-end fw-bold">
                                                Total Grand Total:
                                            </td>
                                            <td className="fw-bold text-end">{rupeeFormat(totalGrandTotal)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        );
                    })}
                </Card> : <h4 className="text-center fw-bold text-secondary">
                    No such data found !
                </h4>
            }
        </>
    );
};

export default SalesReturnRepresenterBillWise;
