import { Dispatch } from "redux"
import { addRole, deleteRole, editRole, getRole } from "../../services/role.service"

export enum ActiveDropDown {
    Active = "active",
    InActive = "inactive",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface RoleProperties {
    pageUrl: string,
    pageTitle: string,
    add: boolean,
    view: boolean,
    edit: boolean,
    delete: boolean
}

export interface RolesType {
    id?: number
    role: string
    roleProperties: RoleProperties[],
    createdBy?: number | null,
    createdAt?: string,
    updatedAt?: string
    updatedBy?: number | null,
}

export enum RolesActionsList {
    ADD_ROLES = 'ADD_ROLES',
    EDIT_ROLES = 'EDIT_ROLES',
    FETCH_ROLES = 'FETCH_ROLES',
    DELETE_ROLES = 'DELETE_ROLES'
}

export interface DeleteRole {
    id: number | undefined
}

export interface AddRolesAction {
    type: RolesActionsList.ADD_ROLES
    data: RolesType
}

export interface EditRolesAction {
    type: RolesActionsList.EDIT_ROLES
    data: RolesType
    id: string | undefined
}

export interface FetchRolesAction {
    type: RolesActionsList.FETCH_ROLES
    data: RolesType[]
}

export interface DeleteRolesAction {
    type: RolesActionsList.DELETE_ROLES
    data: string | undefined
}

export type RolesActions = AddRolesAction | EditRolesAction | FetchRolesAction | DeleteRolesAction

export const addRolesAction = (data: RolesType) => {
    return async (dispatch: Dispatch) => {
        return addRole(data).then(response => {
            if (response.status === 200 && response.data.data) {

                dispatch<AddRolesAction>({
                    type: RolesActionsList.ADD_ROLES,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'User Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const editRolesAction = (data: RolesType, role: string | undefined) => {
    return async (dispatch: Dispatch) => {
        return editRole(data, role)
            .then(response => {
                // console.log("Edit Roles Response:", response);
                // console.log("Status Code:", response.status);

                if (response.status === 200 && response.data.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
                    const firstResponse = response.data.data[0];
                    // console.log("First Response:", firstResponse);

                    dispatch<EditRolesAction>({
                        type: RolesActionsList.EDIT_ROLES,
                        data: firstResponse,
                        id: role
                    });

                    return Promise.resolve(response.data.message
                        ? response.data.message
                        : 'Roles Updated');
                } else {
                    // console.error("Invalid or unexpected response:", response);
                    throw { response };
                }
            })
            .catch(error => {
                console.error("Edit Roles Error:", error);
                return Promise.reject(error.response
                    ? error.response.data.message
                        ? error.response.data.message
                        : 'Unable to update'
                    : 'Unable to update');
            });
    };
}

export const fetchRolesAction = () => {
    return async (dispatch: Dispatch) => {
        const response = await getRole()
        const data = response.data.data
        dispatch<FetchRolesAction>({
            type: RolesActionsList.FETCH_ROLES,
            data: data
        })
    }
}

export const deleteRolesAction = (role: string | undefined) => {
    return async (dispatch: Dispatch) => {

        return deleteRole(role).then(response => {
            console.log(response.data.message);
            if (response.status === 200) {
                dispatch<DeleteRolesAction>({
                    type: RolesActionsList.DELETE_ROLES,
                    data: response.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Roles Deleted Successfully')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}