import { isAfter, isToday, subDays } from 'date-fns'
import { MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmPurchaseReturn from "../components/DeleteConfirmationPurchaseReturn"
import Modal from "../components/Modal"
import { useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { LocalFormatDate } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolder"
import { PurchaseReturnType, fetchPurchaseReturnAction } from "../redux/actions/purchaseReturnAction"
import { AppDispatch, StoreState } from "../redux/store"
import { AuthUserType } from '../redux/actions/authUserActions'
import DatePicker from 'react-datepicker'
import { FormInput } from "../components/custom/components"

interface TableBody {
    sno: number
    invoiceDate: string
    invoiceNo: string
    purchaseReturnEntryNo: string
    supplier: string
    finalAmount: string
    rolePermissions?: any
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: PurchaseReturnType) => void
type HandleDelete = (value: PurchaseReturnType) => void

interface Props {
    setFormType: React.Dispatch<React.SetStateAction<FormType>>
    setId: React.Dispatch<React.SetStateAction<number | undefined>>
    onclose: () => void
}

const PurchaseReturn = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const purchaseList = useSelector<StoreState, PurchaseReturnType[]>(state => state.purchaseReturn)
    const AuthUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser);
    // const supplierList = useSelector<StoreState, SupplierType[]>(state => state.supplier)
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(state => state.account)

    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    // const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    // const [id, setId] = useState<number>()

    const [editData, setEditData] = useState<PurchaseReturnType>()

    const [searchDate, setSearchDate] = useState<Date | null>(new Date());
    const [searchSupplier, setSearchSupplier] = useState<string>('');
    const [searchInvoiceNo, setSearchInvoiceNo] = useState<string>('');
    const url = window.location.pathname
    const rolePermissions = AuthUser?.roles?.find(r => r.pageUrl === url)

    useEffect(() => {
        if (purchaseList.length > 0) {
            const lastInvoiceDate = new Date(purchaseList[purchaseList.length - 1].invoiceReturnDate);
            setSearchDate(lastInvoiceDate);
        } else {
            setSearchDate(new Date());
        }
    }, [purchaseList]);

    const handleEdit: HandleEdit = (value) => {
        props.setFormType(FormTypeList.EDIT)
        props.setId(value.id)
        setEditData(value)
        console.log('edit');
        props.onclose()
    }

    const handleDelete: HandleDelete = (value) => {
        props.setFormType(FormTypeList.DELETE)
        setEditData(value)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    useEffect(() => {
        dispatch(fetchPurchaseReturnAction())
    }, [])

    const handleDateFilter = (date: Date | null) => {
        setSearchDate(date);
    };

    const handleSupplierFilter = (value: string) => {
        setSearchSupplier(value);
    };

    const handleInvoiceNoFilter = (value: string) => {
        setSearchInvoiceNo(value);
    };

    const isWithinLast7Days = (date: Date) => {
        const today = new Date();
        const sevenDaysAgo = subDays(today, 7);
        return isAfter(date, sevenDaysAgo) || isToday(date);
    };

    const filteredPurchaseList = purchaseList.filter((purchase) => {
        const purchaseDate = new Date(purchase.invoiceReturnDate);
        const formattedDate = LocalFormatDate(purchase.invoiceReturnDate?.toString());

        const isDateMatch = searchDate ? formattedDate === LocalFormatDate(searchDate.toISOString()) : isWithinLast7Days(purchaseDate);

        const normalizedSearchSupplier = searchSupplier.toLowerCase();
        const normalizedSupplierName = (AccountLists.find(s => s?.accountNumber === purchase?.supplierId)?.accountName || '').toLowerCase();

        return (
            isDateMatch &&
            purchase.invoiceReturnNumber?.toString().includes(searchInvoiceNo) &&
            normalizedSupplierName.includes(normalizedSearchSupplier)
        );
    });

    return <>
        <Modal
            headerText={'Delete PurchaseReturn'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='sm'
        >
            <DeleteConfirmPurchaseReturn
                editData={editData}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        {/* Filter Inputs */}
        <div className="row">
            <div className="col-lg-12">
                <div className="hstack gap-4 mb-3">
                    <div>
                        <label htmlFor="searchDate">Return Date :</label>
                        {/* <DatePicker
                                    id="searchDate"
                                    selected={searchDate}
                                    onChange={(date) => handleDateFilter(date)}
                                    // dateFormat="yyyy-MM-dd"
                                    dateFormat="dd-MM-yyyy"
                                /> */}
                        <DatePicker
                            id="searchDate"
                            selected={searchDate}
                            onChange={(date) => handleDateFilter(date)}
                            dateFormat="dd-MM-yyyy"
                        />
                    </div>
                    <FormInput
                        name='searchInvoiceNo'
                        label='Invoice Return No :'
                        value={searchInvoiceNo}
                        onChange={(e) => handleInvoiceNoFilter(e.target.value)}
                    />
                    <FormInput
                        name='searchSupplier'
                        label='Supplier :'
                        value={searchSupplier}
                        onChange={(e) => handleSupplierFilter(e.target.value)}
                    />
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="card shadow-lg">
                    {/* <div className="card-body"> */}
                    <div className='table-wrapper'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Purchase Return Entry No</th>
                                    <th className='text-truncate align-middle'>Invoice Return Date</th>
                                    <th className='text-truncate align-middle'>Invoice Return No</th>
                                    <th className='text-truncate align-middle'>Supplier</th>
                                    <th className='text-truncate align-middle'>Grand Total</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                               {filteredPurchaseList.map((tr, i) => {
                                        let sobj = AccountLists.find(s => s?.accountNumber === tr?.supplierId)
                                        let sname = sobj?.accountName ? sobj?.accountName : ''
                                        // console.log(tr.purchaseReturnEntryNumber);
                                        return <UserBody
                                            sno={i + 1}
                                            invoiceNo={tr.invoiceReturnNumber?.toString()}
                                            invoiceDate={tr.invoiceReturnDate?.toString()}
                                            purchaseReturnEntryNo={tr.purchaseReturnEntryNumber?.toString()}
                                            finalAmount={tr.grandTotal ? tr.grandTotal?.toString() : '0'}
                                            supplier={sname}
                                            onEdit={() => handleEdit(tr)}
                                            onDelete={() => handleDelete(tr)}
                                            rolePermissions={rolePermissions}
                                            key={i}

                                        />
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>
    </>
}

const UserBody = ({ sno, invoiceDate, finalAmount, invoiceNo, purchaseReturnEntryNo, supplier, onEdit, onDelete, rolePermissions }: TableBody) => {
    const AuthUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser);
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{purchaseReturnEntryNo}</td>
        <td className='text-capitalize align-middle'>{LocalFormatDate(invoiceDate)}</td>
        <td className='text-capitalize align-middle'>{invoiceNo}</td>
        <td className='text-capitalize align-middle'>{supplier}</td>
        <td className='text-capitalize align-middle'>{finalAmount}</td>
        <td className="align-middle">
            <div className='hstack gap-1'>
                {(rolePermissions?.edit || AuthUser?.role === 'SUPER') && <button className='btn btn-sm fw-bold btn-info p-1' onClick={onEdit}><i className="fe-edit noti-icon"></i></button>}
                {(rolePermissions?.delete || AuthUser?.role === 'SUPER') && <button className='btn btn-sm fw-bold btn-danger p-1' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></button>}
            </div>
        </td>
    </tr>
}
export default PurchaseReturn