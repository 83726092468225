import "../../styles/styles.css";
import { useState } from "react";
import { Container, Card, Nav } from "react-bootstrap";
import UserLayout from "../../components/custom/layouts/UserLayout";
import PurchaseInvoiceWise from "../../components/report/PurchaseInvoiceWise";
import PurchaseSupplierBillWise from "../../components/report/PurchaseSupplierBillWise";
import PurchaseDateWise from "../../components/report/PurchaseDateWise";
import PurchaseProductDateWise from "../../components/report/PurchaseProductDateWise";
import PurchaseSummary from "../../components/report/PurchaseSummary";
import PurchaseGstRateWise from "../../components/report/PurchaseGstRateWise";

const PurchaseReport = () => {
    const [activeTab, setActiveTab] = useState("invoice");

    return (
        <UserLayout>
            <h3 className="mb-2 font-bold">Purchase Report</h3>

            <Container fluid className="p-3 shadow-lg bg-white rounded">

                <Nav variant="pills" className="custom-tabs mb-2 justify-content-start" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey || "invoice")}>

                    <Nav.Item>
                        <Nav.Link eventKey="invoice" className="custom-tab">Purchase Invoice Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="date" className="custom-tab">Purchase Date Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="gst" className="custom-tab">Purchase GST Rate Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="product" className="custom-tab">Purchase Product Date Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="customer" className="custom-tab">Supplier Bill Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="summary" className="custom-tab">Purchase Summary</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Card className="tab-content-card">
                    {activeTab === "invoice" && <TabContent title="Purchase Invoice Wise" />}
                    {activeTab === "date" && <TabContent title="Purchase Date Wise" />}
                    {activeTab === "gst" && <TabContent title="Purchase GST Rate Wise" />}
                    {activeTab === "product" && <TabContent title="Purchase Product Date Wise" />}
                    {activeTab === "customer" && <TabContent title="Supplier Bill Wise" />}
                    {activeTab === "summary" && <TabContent title="Purchase Summary" />}
                </Card>
            </Container>
        </UserLayout>
    );
};

interface TabContentProps {
    title: string;
}

const TabContent: React.FC<TabContentProps> = ({ title }) => (
    <>
        {title === "Purchase Invoice Wise" && <div>
            <PurchaseInvoiceWise />
        </div>}
        {title === "Purchase Date Wise" && <div>
            <PurchaseDateWise />
        </div>}
        {title === "Purchase GST Rate Wise" && <div>
            <PurchaseGstRateWise/>
        </div>}
        {title === "Purchase Product Date Wise" && <div>
            <PurchaseProductDateWise/>
        </div>}
        {title === "Supplier Bill Wise" && <div>
            <PurchaseSupplierBillWise />
        </div>}
        {title === "Purchase Summary" && <div>
            <PurchaseSummary/>
        </div>}
    </>
);

export default PurchaseReport;
