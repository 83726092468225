import { useState } from "react";
import { Card, Container, Nav } from "react-bootstrap";
import UserLayout from "../../components/custom/layouts/UserLayout";
import PurchaseReturnDateWise from "../../components/report/PurchaseReturnDateWise";
import PurchaseReturnGstRateWise from "../../components/report/PurchaseReturnGstRateWise";
import PurchaseReturnInvoiceWise from "../../components/report/PurchaseReturnInvoiceWise";
import PurchaseReturnProductDateWise from "../../components/report/PurchaseReturnProductDateWise";
import PurchaseReturnSummary from "../../components/report/PurchaseReturnSummary";
import PurchaseReturnSupplierBillWise from "../../components/report/PurchaseReturnSupplierBillWise";
import "../../styles/styles.css";


const PurchaseReturnReports = () => {
    const [activeTab, setActiveTab] = useState("invoice");

    return (
        <UserLayout>
            <h3 className="mb-2 font-bold">Purchase Return Report</h3>

            <Container fluid className="p-3 shadow-lg bg-white rounded">

                <Nav variant="pills" className="custom-tabs mb-2 justify-content-start" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey || "invoice")}>

                    <Nav.Item>
                        <Nav.Link eventKey="invoice" className="custom-tab">Purchase Return Invoice Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="date" className="custom-tab">Purchase Return Date Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="gst" className="custom-tab">Purchase Return GST Rate Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="product" className="custom-tab">Purchase Return Product Date Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="customer" className="custom-tab">Supplier Return Bill Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="summary" className="custom-tab">Purchase Return Summary</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Card className="tab-content-card">
                    {activeTab === "invoice" && <TabContent title="Purchase Return Invoice Wise" />}
                    {activeTab === "date" && <TabContent title="Purchase Return Date Wise" />}
                    {activeTab === "gst" && <TabContent title="Purchase Return GST Rate Wise" />}
                    {activeTab === "product" && <TabContent title="Purchase Return Product Date Wise" />}
                    {activeTab === "customer" && <TabContent title="Supplier Return Bill Wise" />}
                    {activeTab === "summary" && <TabContent title="Purchase Return Summary" />}
                </Card>
            </Container>
        </UserLayout>
    );
};

interface TabContentProps {
    title: string;
}

const TabContent: React.FC<TabContentProps> = ({ title }) => (
    <>
        {title === "Purchase Return Invoice Wise" && <div>
            <PurchaseReturnInvoiceWise />
        </div>}
        {title === "Purchase Return Date Wise" && <div>
            <PurchaseReturnDateWise />
        </div>}
        {title === "Purchase Return GST Rate Wise" && <div>
            <PurchaseReturnGstRateWise />
        </div>}
        {title === "Purchase Return Product Date Wise" && <div>
            <PurchaseReturnProductDateWise />
        </div>}
        {title === "Supplier Return Bill Wise" && <div>
            <PurchaseReturnSupplierBillWise />
        </div>}
        {title === "Purchase Return Summary" && <div>
            <PurchaseReturnSummary />
        </div>}
    </>
);

export default PurchaseReturnReports;
