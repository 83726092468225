import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import api from "../config/api";
import { AccountHolderType } from "../redux/actions/accountHolder";
import { StoreState } from "../redux/store";

interface SalesReturnFormByCustomerProps {
    onSave: (result: { data: any }) => void;
    customerId: number;
}

export interface Dropdown {
    label: string;
    value: string;
    grandTotal?: number;
}

interface Product {
    id: string;
    product_name: string;
    quantity: string;
    free_quantity: string;
    total_quantity: string;
}

const SalesReturnFormByCustomer: React.FC<SalesReturnFormByCustomerProps> = ({ onSave, customerId }) => {
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(
        (state) => state.account
    );
    // const [customer, setCustomer] = useState<Dropdown | null>(null);
    const [invoice, setInvoice] = useState<Dropdown | null>(null);
    const [invoiceList, setInvoiceList] = useState<Dropdown[]>([]);
    const [grandTotal, setGrandTotal] = useState<number | null>(null);
    const [invoiceReturnNo, setInvoiceReturnNo] = useState<string>('');
    const [invoiceReturnDate, setInvoiceReturnDate] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [salesItems, setSalesItems] = useState<Product[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);

    const [selectedItems, setSelectedItems] = useState([]);


    // console.log(customerId);


    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            minHeight: "25px",
            height: "25px",
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            height: "25px",
            padding: "0 5px",
        }),
        input: (provided: any) => ({
            ...provided,
            margin: "0px",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            height: "30px",
        }),
    };

    useEffect(() => {
        const fetchInvoiceList = async () => {
            if (!customerId) {
                setInvoiceList([]);
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const response = await api().get(
                    `/sales/dataByCustomer/?customerId=${customerId}&invoiceNo=null&invoiceDate=null&salesItemsId=null`
                );

                const salesData = response.data.data;

                setInvoiceList(
                    salesData?.map((item: any) => ({
                        label: `${item.invoice_number || ""} - ${item.invoice_date || ""}`,
                        value: item.id,
                    }))
                );
            } catch (err) {
                console.error("Error fetching invoice list:", err);
                setError("Failed to fetch invoice list. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchInvoiceList();
    }, [customerId]);

    useEffect(() => {
        const fetchGrandTotal = async () => {
            if (!customerId || !invoice) {
                setGrandTotal(null);
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const selectedProductsParam = selectedProducts.join(",");
                const response = await api().get(
                    `/sales/dataByCustomer/?customerId=${customerId}&invoiceNo='${invoice.label.split(" - ")[0]}'&invoiceDate='${invoice.label.split(" - ")[1]}'&salesItemsId=null`
                );

                const invoiceData = response.data.data;
                const productData = invoiceData?.flatMap((item: any) => item.salesItems);

                if (invoiceData?.length > 0) {
                    setGrandTotal(invoiceData[0]?.grand_total || null);
                } else {
                    setGrandTotal(null);
                }

                setSalesItems(
                    productData?.map((item: any) => ({
                        id: item.id,
                        product_name: item.product_name,
                        quantity: item.quantity,
                        free_quantity: item.free_quantity,
                        total_quantity: item.total_quantity
                    }))
                );
            } catch (err) {
                console.error("Error fetching grand total:", err);
                setError("Failed to fetch grand total. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchGrandTotal();
    }, [customerId, invoice]);

    const handleCheckboxChange = (productId: string) => {
        setSelectedProducts((prevSelected) => {
            if (prevSelected.includes(productId)) {
                return prevSelected.filter((id) => id !== productId);
            }
            return [...prevSelected, productId];
        });
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedProducts([]);
        } else {
            setSelectedProducts(salesItems.map((item) => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSave = async () => {
        if (!customerId || !invoice) {
            return;
        }

        const selectedProductsParam = selectedProducts.join(",");

        const url = `/sales/dataByCustomer/?customerId=${customerId}&invoiceNo='${invoice.label.split(" - ")[0]}'&invoiceDate='${invoice.label.split(" - ")[1]}'&salesItemsId=${selectedProductsParam}`;
        try {
            setLoading(true);
            setError(null);

            const response = await api().get(url);
            const data = response.data.data[0];

            console.log("Response from the server:", data);

            setSelectedProducts([]);
            setSelectAll(false);

            if (onSave) {
                onSave({ data });
            }
        } catch (err) {
            console.error("Error saving data:", err);
            setError("Failed to save data. Please try again.");
        } finally {
            setLoading(false);
        }
    };



    return (
        <div className="container">
            <div className="row">
                <div className="col-6" style={{ backgroundColor: "#B1D7E9", padding: "20px" }}>

                    <div className="mb-3">
                        <label>Search Invoice</label>
                        <Select
                            options={invoiceList}
                            isSearchable={true}
                            isClearable={true}
                            onChange={(v) => setInvoice(v)}
                            value={invoice}
                            styles={customStyles}
                            isDisabled={loading}
                        />
                    </div>

                    <div className="mb-3">
                        <label>Grand Total</label>
                        <input
                            type="text"
                            className="form-control"
                            value={grandTotal !== null ? grandTotal : ""}
                            readOnly
                        />
                    </div>

                    {loading && <p>Loading...</p>}
                    {error && <p className="text-danger">{error}</p>}
                </div>

                <div className="col-6 bg-white" style={{ padding: "20px" }}>
                    {/* Displaying product names and checkboxes */}
                    <div className="mb-3">
                        <input
                            className="me-1"
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                        />
                        <label>Select All</label>
                    </div>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>Free</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {salesItems.map((item, index) => (
                                <tr
                                    key={item.id}
                                    onClick={() => handleCheckboxChange(item.id)}
                                    style={{ cursor: 'pointer', backgroundColor: selectedProducts.includes(item.id) ? '#e9f5ff' : 'transparent' }}
                                >
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedProducts.includes(item.id)}
                                            onChange={() => handleCheckboxChange(item.id)}
                                        />
                                    </td>
                                    <td>{item.product_name}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.free_quantity}</td>
                                    <td>{item.total_quantity}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                    <div className="mt-3">
                        <button
                            className="btn btn-primary me-2"
                            onClick={handleSave}
                            disabled={selectedProducts.length === 0}
                        >
                            Save
                        </button>
                        <button
                            className="btn btn-secondary"
                            onClick={() => {
                                setSelectedProducts([]);
                                setSelectAll(false);
                            }}
                        >
                            Clear
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalesReturnFormByCustomer;
