import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { CategoryType, addCategoryAction, deleteCategoryAction, editCategoryAction, fetchCategoryAction } from "../redux/actions/categoryAction"
import { DepartmentType, fetchDepartmentAction } from "../redux/actions/departmentAction"
import { AppDispatch, StoreState } from "../redux/store"
import { AuthUserType, RoleType } from "../redux/actions/authUserActions"

interface TableBody {
    sno: number,
    categoryName: string | undefined,
    // viewImage: string | undefined,
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    rolesAccessObj:RoleType|undefined
}

type HandleEdit = (value: CategoryType) => void
type HandleDelete = (value: CategoryType) => void

const Category = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const CategoryList = useSelector<StoreState, CategoryType[]>(state => state.category)
    const DepartmentList = useSelector<StoreState, DepartmentType[]>(state => state.department)
    const Authuser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const url = window.location.pathname
    const rolesAccessObj = Authuser?.roles?.find(f => f.pageUrl === url)

    useEffect(() => {
        dispatch(fetchCategoryAction())
        dispatch(fetchDepartmentAction())
    }, [dispatch])

    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<CategoryType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [id, setId] = useState<number>()


    const [categoryName, setCategoryName] = useState<string>('')
    const [categoryNameError, setCategoryNameError] = useState<string>('')

    const [departmentId, setDepartmentId] = useState<string>('')
    const [departmentIdError, setDepartmentIdError] = useState<string>('')


    const [imageUpload, setImageUpload] = useState<any | null>({ filename: '' })
    const [imageUrl, setImageUrl] = useState<any>('')
    const [imageFlag, setImageFlag] = useState<boolean>(false)
    // const [imageUploadError, setImageUploadError] = useState<string>('')

    const filteredCategory = CategoryList.filter((data)=>
        data.name?.toLocaleLowerCase().includes(categoryName.toLocaleLowerCase())
    )

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCategoryName(e.target.value)
        setCategoryNameError('')
    }

    const handleDepartment: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDepartmentId(e.target.value)
        setDepartmentIdError('')
    }


    const handleImageUpload: ChangeEventHandler<HTMLInputElement | HTMLFormElement> = (e) => {
        setImageUpload(e.target.files[0])
        setImageFlag(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
    }

    const handleDelete: HandleDelete = (value) => {
        setDeleteId(value.id)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const resetStates = () => {
        setCategoryName('')
        setDepartmentId('')
        setImageUpload({ filename: "" })
        setEditData(null)
    }

    const handleSave = () => {
        let error = false
        // const addData: CategoryType = {
        //     name: categoryName,

        // }

        const edata: CategoryType = {
            id: id,
            departmentId: departmentId,
            name: categoryName,
            imageUrl: imageUrl
        }

        if (categoryName.trim() === '') {
            setCategoryNameError('Category required')
            error = true
        } else if ((categoryName.trim() || '').length > 30) {
            setCategoryNameError('Category should be within 30 characters')
            error = true
        }


        if (!error) {
            const data = new FormData()
            data.append('name', categoryName)
            data.append('departmentId', departmentId)
            data.append('imageUrl', imageUpload)
            if (imageFlag) {
                data.append('oldImageUrl', editData?.imageUrl ? imageUrl : '')
            }
            if (formType === FormTypeList.ADD) {
                dispatch(addCategoryAction(data)).then(text => {
                    toast('Added', ToastTypes.SUCCESS)
                    resetStates()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== null) {
                dispatch(editCategoryAction(data, editData.id)).then(text => {
                    toast('Updated', ToastTypes.SUCCESS)
                    resetStates()
                    setEditData(null)
                    setFormType(FormTypeList.ADD)
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }


    useEffect(() => {
        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {
            setId(editData.id)
            setCategoryName(editData.name || '')
            setDepartmentId(editData.departmentId || '')
            // fetch(apiUrl + '/category/image/' + editData.imageUrl, {headers:{
            //     'auth-token': store.getState().authUser?.['auth-token']!
            // }}).then(async (res) => {
            //     const blob = await res.blob()
            //     const fileURL = window.URL.createObjectURL(blob);
            //     setImageUpload(fileURL)
            // });
            // setImageUpload({filename:editData.imageUrl})
        } else {
            resetStates()
        }
    }, [formType, editData])


    const onDelete = () => {
        console.log(deleteId);
        if (deleteId) {
            dispatch(deleteCategoryAction(deleteId)).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
                resetStates()
            }).catch(text => {

            })
        }
    }

    return <>
        <Modal
            headerText={'Delete Manufacture'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <DeleteConfirmationForm
                onDelete={onDelete}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <UserLayout>
            <h4>Category Master</h4>
            <div className="container">
                <div className="row">
                    <div className="hstack justify-content-center gap-3">

                        <div className="col-1"></div>
                        <div className="col-sm-2 col-md-2 col-lg-2">
                            <FormInput
                                name="departmentId"
                                labelClassName="required"
                                value={departmentId}
                                onChange={handleDepartment}
                                errorText={departmentIdError}
                                containerClass="mb-2"
                                // placeholder="Department"
                                type="select"
                            >
                                <option value="">Department</option>
                                {
                                    DepartmentList.map((dl) => {
                                        return <option value={dl.id}>{dl.name}</option>
                                    })
                                }
                            </FormInput>
                        </div>

                        <div className="col-2">
                            {/* <label htmlFor="" className="mb-2">Name</label> */}
                            <FormInput
                                name='categoryName'
                                placeholder='Category Name'
                                // label='Category'
                                labelClassName="required"

                                required={true}
                                value={categoryName}
                                onChange={handleName}
                                errorText={categoryNameError}
                                containerClass="mb-2"
                            />
                        </div>
                        {/* <div className="col-4"> */}
                        {/* <FormInput
                            name='imageUpload'
                            value={imageUpload.filename}
                            onChange={handleImageUpload}
                            type={"file"}
                            className="mb-2"
                        /> */}
                        {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) &&
                            <>
                                < button className="btn btn-sm fw-bold btn-secondary mb-2" onClick={() => resetStates()}>Add New</button>
                                {(editData ? rolesAccessObj?.edit : rolesAccessObj?.add) && <button className="btn btn-sm fw-bold btn-success mb-2" onClick={handleSave}>{editData ? "Update" : "Save"}</button>}
                            </>
                        }

                        {/* </div> */}
                        {/* </div> */}
                        <div className="col-2"></div>
                    </div>
                </div>

                {rolesAccessObj?.view && <div className="row">
                    <div className="col-3"></div>
                    <div className="col=sm-6 col-md-6 col-lg-6">
                        <div className="card mb-0 p-1">
                            {/* <div className="card-body"> */}
                            <div className='table-wrapper'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className='text-truncate align-middle'>S.No</th>
                                            <th className='text-truncate align-middle'>Name</th>
                                            {/* <th className='text-truncate align-middle'>Image</th> */}
                                           {(rolesAccessObj?.edit||rolesAccessObj?.delete)&& <th className='text-truncate align-middle'>Actions</th>}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredCategory.map((cl, i) => {
                                            return <CatBody
                                                sno={i + 1}
                                                categoryName={cl.name}
                                                // viewImage={cl.imageUrl}
                                                key={cl.id}
                                                onEdit={() => handleEdit(cl)}
                                                onDelete={() => handleDelete(cl)}
                                                rolesAccessObj={rolesAccessObj}
                                            />
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>}
            </div>
        </UserLayout >
    </>
}

const CatBody = ({ sno, categoryName, onEdit, onDelete,rolesAccessObj }: TableBody) => {
    const [image, setImage] = React.useState<any>('')

    // React.useEffect(() => {
    //     const config = store.getState().authUser?.['auth-token']!
    //     fetch(apiUrl + 'images/category' + viewImage, {
    //         headers: {
    //             'auth-token': store.getState().authUser?.['auth-token']!
    //         }
    //     }).then(async (res) => {
    //         const blob = await res.blob()
    //         const fileURL = window.URL.createObjectURL(blob);
    //         setImage(fileURL)
    //     });
    // }, [viewImage]);

    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{categoryName}</td>
        {/* <td className='text-capitalize align-middle'><img src={image} style={{ margin: "3px" }} className="img-thumbnail w-100px" width={100} height={100} /></td> */}
        <td className="align-middle">
            {(rolesAccessObj?.edit||rolesAccessObj?.delete)&&<div className='hstack justify-content-center gap-1'>
                {rolesAccessObj?.edit&&<span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>}
                {rolesAccessObj?.delete&&<span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>}
            </div>}
        </td>
    </tr>
}
export default Category

function getAxiosRequestConfig(arg0: any) {
    throw new Error("Function not implemented.")
}

