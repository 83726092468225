import { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import LogoDark from '../assets/images/app-icon.png';
import LogoLight from '../assets/images/app-icon2.png';

interface AccountLayoutProps {
    helpText?: string;
    bottomLinks?: any;
    isCombineForm?: boolean;
    children?: any;
}

const AuthLayout = ({ helpText, bottomLinks, children, isCombineForm }: AccountLayoutProps) => {
    useEffect(() => {
        if (document.body) document.body.classList.add('authentication-bg', 'authentication-bg-pattern');

        return () => {
            if (document.body) document.body.classList.remove('authentication-bg', 'authentication-bg-pattern');
        };
    }, []);

    return (
        <>
            <div className="account-pages mt-5 mb-5">
                <Container>
                    <Row className="d-flex flex-row m-0 p-0 align-middle justify-content-center">
                        {/* <Col md={4} lg={6} style={{textAlign:"right"}} className='p-0'>
                            <img src={shopLogo} className='img-fluid' style={{height:"470px"}}/>
                            <h3 className="text-light">Grocery POS</h3>
                        </Col> */}
                        <Col md={12} lg={12} xl={isCombineForm ? 9 : 4} className='p-0'>
                            <Card style={{ backgroundColor: "#0A2729" }}>
                                <Card.Body className="p-4">
                                    <div className="text-center w-75 m-auto">
                                        <div className="auth-logo">
                                            <Link to="/" className="logo logo-dark text-center">
                                                <span className="logo-lg">
                                                    <h3 className="text-warning">THIRUPATHI AUTO PARTS</h3>
                                                    <img src={LogoDark} alt="" height="60" />
                                                    <span style={{ fontWeight: "bold", fontSize: "1.8em", color: "white" }}>point</span>
                                                    <span style={{ fontWeight: "bold", fontSize: "1.8em", color: "#F9B915" }}> of </span>
                                                    <span style={{ fontWeight: "bold", fontSize: "1.8em", color: "white" }}>sale</span>

                                                </span>
                                            </Link>

                                            <Link to="/" className="logo logo-light text-center">
                                                <span className="logo-lg">
                                                    <img src={LogoLight} alt="" height="60" />
                                                </span>
                                            </Link>
                                        </div>
                                        <p className="text-white mb-4 mt-3">{helpText}</p>
                                    </div>
                                    {children}
                                </Card.Body>
                            </Card>

                            {/* bottom links */}
                            {bottomLinks}
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* <footer className="footer footer-alt bg-light">
                {new Date().getFullYear()} &copy; {' '}
                
                Powered by <a href="http://www.mindzcube.com/" target={'_blank'}>
                    <span className="logo-sm ms-1">
                        <img src={mindzcubeLogo} alt="" height="30" />
                    </span>
                </a>
            </footer> */}
        </>
    );
};

export default AuthLayout;
