import { ChangeEventHandler, MouseEventHandler, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import Option from "../components/Option"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { onlyUnique, validateEmail } from "../config/functions"
import { BranchType, fetchBranchAction } from "../redux/actions/branchAction"
import { RolesType, fetchRolesAction } from "../redux/actions/rolesAction"
import { ClientRegistrationType, fetchClientRegistrationAction } from "../redux/actions/clientRegistration";
import { ActiveDropDown, ActiveTypes, PasswordType, UsersType, addUsersAction, deleteUsersAction, editUsersAction, fetchUsersAction, updatePassword } from "../redux/actions/usersActions"
import { AppDispatch, StoreState } from "../redux/store"

interface TableBody {
    sno: number
    name: string | undefined
    cellNo: number
    status: ActiveTypes | undefined
    branch: string | undefined
    role: string | undefined
    onOpenModal: MouseEventHandler<HTMLButtonElement>
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: UsersType) => void
type HandleDelete = (value: UsersType) => void
type HandleResetPassword = (value: UsersType) => void

const STATUS_DROPS = [
    {
        text: 'Active',
        value: 'active'
    },
    {
        text: 'InActive',
        value: 'inactive'
    },
]

const UserManagement = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const userList = useSelector<StoreState, UsersType[]>(state => state.user)
    const roleList = useSelector<StoreState, RolesType[]>(state => state.role)
    const BranchList = useSelector<StoreState, BranchType[]>(state => state.branch)
    const clients = useSelector<StoreState, ClientRegistrationType[]>(state => state.clientRegistration)
    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<UsersType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [id, setId] = useState<number>()
    const [userId, setUserId] = useState<string>('')
    const [userIdError, setUserIdError] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [cellNo, setCellNo] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [cellNoError, setCellNoError] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [passwordError, setPasswordError] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>('')
    const [status, setStatus] = useState<ActiveTypes | undefined>(ActiveDropDown.Active)
    const [statusError, setStatusError] = useState<string>('')
    const [branchId, setBranchId] = useState<string>('')
    const [branchIdError, setBranchIdError] = useState<string>('')
    const [emailId, setEmailId] = useState<string>('')
    const [emailIdError, setEmailIdError] = useState<string>('')
    const [role, setRole] = useState<string>('')
    const [roleError, setRoleError] = useState<string>('')

    const [openModal, setOpenModal] = useState<boolean>(false)
    const [resetPassword, setResetPassword] = useState<string>('')
    const [resetPasswordError, setResetPasswordError] = useState<string>('')
    // const [passwordEdit, setPasswordEdit] = useState<PasswordType | null>(null)

    // useEffect(() => {
    //     dispatch(fetchClientRegistrationAction())
    // }, [])

    // Focus Functionality

    const [focusedField, setFocusedField] = useState<string | null>(null)
    const userIdRef=useRef<HTMLInputElement>(null)
    const mobileNoRef=useRef<HTMLInputElement>(null)
    const emailRef=useRef<HTMLInputElement>(null)
    const roleRef=useRef<HTMLInputElement>(null)
    const nameRef=useRef<HTMLInputElement>(null)
    const passwordRef=useRef<HTMLInputElement>(null)
    const branchRef=useRef<HTMLInputElement>(null)
    const statusRef=useRef<HTMLInputElement>(null)


     const handleKeyDown = <T extends Element>(
            e: React.KeyboardEvent<T>,
            nextRef?: React.RefObject<HTMLElement>,
            nextField?: string,
            prevRef?: React.RefObject<HTMLElement>,
            prevField?: string
        ) => {
            const moveFocus = (ref?: React.RefObject<HTMLElement>, field?: string) => {
                if (!ref?.current) return;
    
                e.preventDefault();
    
                const activeElement = document.activeElement as HTMLElement;
                if (activeElement && 'blur' in activeElement) {
                    activeElement.blur();
                }
    
                const input = ref.current.querySelector('input, select, textarea') || ref.current;
                if (input instanceof HTMLElement) {
                    input.focus();
                    setFocusedField(field || '');
                }
            };
    
            if (e.key === 'ArrowDown') moveFocus(nextRef, nextField);
            if (e.key === 'ArrowUp') moveFocus(prevRef, prevField);
        };


    const handleUserId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUserId(e.target.value)
        setUserIdError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleCellNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        
        if(e.target.value.length > 10){
            setCellNoError("Mobile No must be 10 digits")
        }
        else{
            setCellNo(e.target.value)
            setCellNoError('')

        }
    }

    const handlePassword: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value)
        setPasswordError('')
    }

    const hasRequiredCharacters = (input: string): boolean => {
        const hasAlpha = /[A-Za-z]/.test(input);
        const hasNum = /\d/.test(input);
        const hasSpecialChar = /[@_]/.test(input);
        return hasAlpha && hasNum && hasSpecialChar;
    }

    const handleConfirmPassword: ChangeEventHandler<HTMLInputElement> = (e) => {
        setConfirmPassword(e.target.value)
        setConfirmPasswordError('')
    }
    const handleResetPassword: ChangeEventHandler<HTMLInputElement> = (e) => {
        setResetPassword(e.target.value)
    }
    const handleStatus: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStatus(e.target.value as ActiveTypes)
        setStatusError('')
    }

    const handleEmail: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmailId(e.target.value)
        setEmailIdError('')
    }
    const handleBranch: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBranchId(e.target.value)
        setBranchIdError('')
    }

    const handleRole: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRole(e.target.value)
        setRoleError('')
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
    }

    const handleDelete: HandleDelete = (value) => {
        setDeleteId(value.id)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const handleModalClose = () => {
        setOpenModal(false)
    }

    const openResetPasswordModal: HandleResetPassword = (value) => {
        setOpenModal(true)
        setId(value.id)
    }
    const resetStates = () => {
        setUserId('')
        setUserIdError('')
        setName('')
        setCellNo('')
        setNameError('')
        setCellNoError('')
        setPassword('')
        setPasswordError('')
        setConfirmPassword('')
        setConfirmPasswordError('')
        setStatus(ActiveDropDown.Active)
        setStatusError('')
        setEmailId('')
        setEmailIdError('')
        setBranchId('')
        setBranchIdError('')
        setRole('')
        setRoleError('')
        setEditData(null)
        setFormType(FormTypeList.ADD)
    }

    const handleSave = () => {
        let error = false
        const addData: UsersType = {
            userId: userId,
            id: id,
            name: name,
            cellNo:parseInt(cellNo),
            password: password,
            emailId: emailId,
            branchId: branchId,
            role: role,
            status: status,
        }

        const editData: UsersType = {
            userId: userId,
            id: id,
            name: name,
            cellNo:parseInt(cellNo),
            // password: resetPassword ? resetPassword:password,
            emailId: emailId,
            branchId: branchId,
            role: role,
            status: status,
        }

        if (addData.userId === '') {
            setUserIdError('Userid required')
            error = true
        } else if ((addData.userId!).length > 15) {
            setUserIdError('Userid should be within 15 characters')
            error = true
        }

        if (addData.name === '') {
            setNameError('Name required')
            error = true
        } else if ((addData.name || '').length > 30) {
            setNameError('Name should be within 30 characters')
            error = true
        }

        if (formType === FormTypeList.ADD) {
            if (addData.password === '') {
                setPasswordError('Password required')
                error = true
            } else if ((addData.password!).length < 8) {
                setPasswordError('Password should be atleast 8 characters')
                error = true
            } else if ((!hasRequiredCharacters(addData.password!))) {
                setPasswordError('Password allow only this characters @,_ , A-Z, a-z, 0-9')
                error = true
            }
        }

        // if (addData.emailId === '') {
        //     setEmailIdError('Email required')
        //     error = true
        // } else if ((addData.emailId || '').length > 100) {
        //     setEmailIdError('Email should be within 100 characters')
        //     error = true
        // } else if (!validateEmail(addData.emailId || '')) {
        //     setEmailIdError('Invalid Email')
        //     error = true

        // }

        if (addData.branchId === '') {
            setBranchIdError('Branch required')
            error = true
        }

        if (addData.role === '') {
            setRoleError('Role required')
            error = true
        }


        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addUsersAction(addData)).then(text => {
                    toast('Added', ToastTypes.SUCCESS)
                    resetStates()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== null) {
                dispatch(editUsersAction(editData, editData?.id)).then(text => {
                    toast('Updated', ToastTypes.SUCCESS)
                    resetStates()
                    setEditData(null)
                    setFormType(FormTypeList.ADD)
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }



    const onDelete = () => {
        if (deleteId) {
            dispatch(deleteUsersAction(deleteId)).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
            }).catch(text => {

            })

        }
    }

    const onResetPassword = () => {
        const editPassword: PasswordType = {
            resetPassword: resetPassword
        }
        if (formType === FormTypeList.ADD) {
            dispatch(updatePassword(editPassword, id)).then(text => {
                toast('Reset-Password Successfull', ToastTypes.SUCCESS)
                setResetPassword('')
                setEditData(null)
                setFormType(FormTypeList.ADD)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
        setOpenModal(false)
    }

    useEffect(() => {
        if (formType === FormTypeList.EDIT && editData !== null) {
            setId(editData.id)
            setUserId(editData.userId || '')
            setName(editData.name || '')
            setCellNo(editData.cellNo?.toString() || '')
            setPassword(editData.password !== undefined ? editData.password : '')
            setStatus(editData.status)
            setEmailId(editData.emailId || '')
            setBranchId(editData.branchId || '')
            setRole(editData.role || '')
        } else {
            resetStates()
        }
    }, [formType, editData])

    useEffect(() => {
        dispatch(fetchUsersAction())
        dispatch(fetchRolesAction())
        dispatch(fetchBranchAction())
    }, [dispatch])

    return (
      <>
        <Modal
          headerText={"Delete User"}
          visible={showDeleteForm}
          onClose={handleDeleteFormClose}
          centered
          size="lg"
        >
          <DeleteConfirmationForm
            onDelete={onDelete}
            onClose={handleDeleteFormClose}
          />
        </Modal>
        <Modal
          headerText={"Reset Password"}
          visible={openModal}
          onClose={handleModalClose}
          centered
          size="sm"
        >
          <FormInput
            name="resetPassword"
            value={resetPassword}
            onChange={handleResetPassword}
            className="mb-3"
          />
          <button
            className="btn btn-sm fw-bold btn-danger px-2"
            onClick={() => resetStates()}
          >
            Add New
          </button>
          <button
            type="button"
            className="btn btn-sm fw-bold btn-primary m-2"
            onClick={() => onResetPassword()}
          >
            Reset Password
          </button>
        </Modal>
        <UserLayout>
          <h4>User Management</h4>
          <div className="row">
            <div className="col-lg-6">
              <div className="card shadow-lg p-1">
                <div className="card-body">
                  <div className="row">
                    {formType === FormTypeList.ADD && (
                      <div className="col-sm-6 col-md-4 col-lg-6">
                        <FormInput
                          name="userId"
                          label="User Id"
                          labelClassName="required"
                          value={userId}
                          required={true}
                          onChange={handleUserId}
                          errorText={userIdError}
                          labelColor="#032566"
                          onClick={() => setFocusedField("userId")}
                          className={
                            focusedField === "userId"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                          ref={userIdRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              mobileNoRef,
                              "cellno",
                              undefined,
                              undefined
                            )
                          }
                        />
                      </div>
                    )}

                    <div className="col-sm-6 col-md-4 col-lg-6">
                      <FormInput
                        name="name"
                        label="Name"
                        labelClassName="required"
                        required={true}
                        value={name}
                        onChange={handleName}
                        errorText={nameError}
                        labelColor="#032566"
                        containerClass="mb-2"
                        onClick={() => setFocusedField("name")}
                        className={
                          focusedField === "name"
                            ? "border border-2 border-primary rounded"
                            : ""
                        }
                        ref={nameRef}
                        onKeyDown={(e) =>
                          handleKeyDown(
                            e,
                            passwordRef,
                            "password",
                            roleRef,
                            "role"
                          )
                        }
                      />
                    </div>
                  </div>
                  {/* <div className="col-2 mt-2">
                                    <button className="btn btn-sm fw-bold btn-secondary" style={{ float: "right" }} onClick={() => resetStates()}>Add New</button>
                                </div> */}

                  <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-6">
                      <FormInput
                        name="cellNo"
                        label="Mobile No"
                        labelClassName="required"
                        required={true}
                        value={cellNo}
                        onChange={handleCellNo}
                        errorText={cellNoError}
                        labelColor="#032566"
                        containerClass="mb-2"
                        onClick={() => setFocusedField("cellno")}
                        className={
                          focusedField === "cellno"
                            ? "border border-2 border-primary rounded"
                            : ""
                        }
                        ref={mobileNoRef}
                        onKeyDown={(e) =>
                          handleKeyDown(
                            e,
                            emailRef,
                            "email",
                            userIdRef,
                            "userId"
                          )
                        }
                      />
                    </div>
                    {formType === FormTypeList.ADD && (
                      <div className="col-sm-6 col-md-4 col-lg-6">
                        <FormInput
                          name="Password"
                          label="Password"
                          labelClassName="required"
                          required={true}
                          value={password}
                          onChange={handlePassword}
                          errorText={passwordError}
                          labelColor="#032566"
                          containerClass="mb-2"
                          onClick={() => setFocusedField("password")}
                          className={
                            focusedField === "password"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                          ref={passwordRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              branchRef,
                              "branch",
                              nameRef,
                              "name"
                            )
                          }
                        />
                      </div>
                    )}
                    {/* {formType === FormTypeList.ADD && <div className='col-sm-6 col-md-4 col-lg-6'>
                                    <FormInput
                                        name='confirmPassword'
                                        label='Confirm Password'
                                        value={confirmPassword}
                                        onChange={handleConfirmPassword}
                                        // placeholder='Confirm Password'
                                        errorText={confirmPasswordError}
                                    // containerClass="mb-2"
                                    />
                                </div>} */}

                    <div className="col-sm-6 col-md-4 col-lg-6">
                      <FormInput
                        name="emailId"
                        label="Email"
                        value={emailId}
                        onChange={handleEmail}
                        labelColor="#032566"
                        errorText={emailIdError}
                        onClick={() => setFocusedField("email")}
                        className={
                          focusedField === "email"
                            ? "border border-2 border-primary rounded"
                            : ""
                        }
                        ref={emailRef}
                        onKeyDown={(e) =>
                          handleKeyDown(
                            e,
                            roleRef,
                            "role",
                            mobileNoRef,
                            "cellno"
                          )
                        }
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-6">
                      <FormInput
                        name="branchId"
                        label="Branch"
                        labelClassName="required"
                        required={true}
                        value={branchId}
                        onChange={handleBranch}
                        errorText={branchIdError}
                        containerClass="mb-2"
                        labelColor="#032566"
                        type="select"
                        onClick={() => setFocusedField("branch")}
                        className={
                          focusedField === "branch"
                            ? "border border-2 border-primary rounded"
                            : ""
                        }
                        ref={branchRef}
                        onKeyDown={(e) =>
                          handleKeyDown(
                            e,
                            statusRef,
                            "status",
                            passwordRef,
                            "password"
                          )
                        }
                      >
                        <option value="">Select</option>
                        {clients.map((client) => (
                          <option key={client.id} value={client.id}>
                            {client.place}
                          </option>
                        ))}
                      </FormInput>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-6">
                      <FormInput
                        name="Role"
                        label="Role"
                        labelClassName="required"
                        required={true}
                        value={role}
                        onChange={handleRole}
                        errorText={roleError}
                        labelColor="#032566"
                        containerClass="mb-2"
                        type="select"
                        onClick={() => setFocusedField("role")}
                        className={
                          focusedField === "role"
                            ? "border border-2 border-primary rounded"
                            : ""
                        }
                        ref={roleRef}
                        onKeyDown={(e) =>
                          handleKeyDown(e, nameRef, "name", emailRef, "email")
                        }
                      >
                        <option value="">Select</option>
                        {roleList
                          .map((r) => r.role)
                          .filter(onlyUnique)
                          .map((ro) => {
                            return <option value={ro}>{ro}</option>;
                          })}
                      </FormInput>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-6">
                      <FormInput
                        name="Status"
                        label="Status"
                        labelClassName="required"
                        value={status}
                        onChange={handleStatus}
                        errorText={statusError}
                        labelColor="#032566"
                        containerClass="mb-2"
                        type="select"
                        onClick={() => setFocusedField("status")}
                        className={
                          focusedField === "status"
                            ? "border border-2 border-primary rounded"
                            : ""
                        }
                        ref={statusRef}
                        onKeyDown={(e) =>
                          handleKeyDown(
                            e,
                            undefined,
                            undefined,
                            branchRef,
                            "branch"
                          )
                        }
                      >
                        <option value="">Select</option>
                        {STATUS_DROPS.map((a, i) => {
                          return (
                            <Option value={a.value.toString()} key={i}>
                              {a.text}
                            </Option>
                          );
                        })}
                      </FormInput>
                    </div>
                  </div>
                  {(formType === FormTypeList.ADD ||
                    formType === FormTypeList.EDIT) && (
                    <div className="d-flex justify-content-end hstack gap-1 mt-2">
                      <button
                        className="btn btn-sm fw-bold btn-secondary"
                        onClick={() => resetStates()}
                      >
                        Add New
                      </button>
                      <button
                        className="btn btn-sm fw-bold btn-success"
                        onClick={handleSave}
                      >
                        {editData ? "Update" : "Save"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="card shadow-lg p-1"
                style={{ height: "43vh", overflowY: "auto" }}
              >
                {/* <div className="card-body"> */}
                <div className="table-wrapper">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-truncate align-middle">S.No</th>
                        <th className="text-truncate align-middle">Name</th>
                        <th className="text-truncate align-middle">
                          Mobile No
                        </th>
                        <th className="text-truncate align-middle">Branch</th>
                        <th className="text-truncate align-middle">Role</th>
                        <th className="text-truncate align-middle">Status</th>
                        <th className="text-truncate align-middle">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.map((ul, i) => {
                        return (
                          <UserBody
                            sno={i + 1}
                            name={ul.name}
                            cellNo={ul?.cellNo!}
                            role={ul.role}
                            status={ul.status}
                            branch={ul.branchId}
                            key={ul.id}
                            onOpenModal={() => openResetPasswordModal(ul)}
                            onEdit={() => handleEdit(ul)}
                            onDelete={() => handleDelete(ul)}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </UserLayout>
      </>
    );
}

const UserBody = ({ sno, name, cellNo, branch, role, status, onOpenModal, onEdit, onDelete }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{name}</td>
        <td className='text-capitalize align-middle'>{cellNo}</td>
        <td className='align-middle'>{branch}</td>
        <td className='text-capitalize align-middle'>{role}</td>
        <td className='text-capitalize align-middle'>{status}</td>
        <td className="align-middle">
            <div className='hstack gap-1'>
                <span className='badge bg-warning cursor-pointer' onClick={onOpenModal}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="#ffffff" className="text-light"><path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z" /></svg></span>
                <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>
                <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>
            </div>
        </td>
    </tr>
}
export default UserManagement