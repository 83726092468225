import { useEffect, useState } from "react";
import { Card, Table, Button } from "react-bootstrap";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ReportFilter from "./ReportFilter";
import api from "../../config/api";
import { getDate, rupeeFormat, LocalFormatDate } from "../../config/functions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../redux/store";
import { ClientRegistrationType, fetchClientRegistrationAction } from "../../redux/actions/clientRegistration";
import bgMaterial from "../../components/custom/assets/images/bg-material.png";
import { apiUrl } from "../../config";

interface SummaryData {
    financialYear: string;
    supplier: string;
    grandTotal: number;
}


const PurchaseReturnSummary = () => {
    const dispatch = useDispatch<AppDispatch>()

    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [branchId, setBranchId] = useState<string>("");
    const [invoiceTitle, setInvoiceTitle] = useState<string>("All");
    const [invoiceType, setInvoiceType] = useState<string>("Retail");
    const [tableData, setTableData] = useState<SummaryData[]>([]);
    const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

    const clients = useSelector<StoreState, ClientRegistrationType[]>(
        (state) => state.clientRegistration
    );

    useEffect(() => {
        dispatch(fetchClientRegistrationAction())
    }, [])

    useEffect(() => {
        if (triggerSearch) {
            api()
                .get(
                    `reports/purchaseSummaryReturn/?fromDate=${getDate(fromDate)}&toDate=${getDate(toDate)}&branchId=${branchId}&invoiceTitle=${invoiceTitle}&invoiceType=${invoiceType}`
                )
                .then((res) => {
                    if (res.status === 200 && res.data.data) {
                        setTableData(res.data.data);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching purchase data:", error);
                });

            setTriggerSearch(false);
        }
    }, [triggerSearch, fromDate, toDate, branchId, invoiceTitle, invoiceType]);

    const [filters, setFilters] = useState<any>({});

    const handleSearch = (newFilters: any) => {
        setFilters(newFilters);
        setFromDate(newFilters.fromDate);
        setToDate(newFilters.toDate);
        setBranchId(newFilters.branchId);
        setInvoiceTitle(newFilters.invoiceTitle);
        setInvoiceType(newFilters.invoiceType);
        setTriggerSearch(true);
    };

    const downloadCSV = () => {
        const header = [
            "SI.No", "Financial Year", "Supplier Name", "Grand Total"
        ].map(col => `="${col}"`);

        const grandTotal = tableData.reduce(
            (acc, item) => {
                   acc.grandTotal += Number(item.grandTotal) || 0;
                return acc;
            },
            {
                grandTotal: 0,
            }
        );

        const rows = tableData?.map((item, index) => [
          index + 1,
          item.financialYear,
          item.supplier,
          Number(item.grandTotal || 0).toFixed(2),
        ]);

        const grandTotalRow = [
          "",
          "",
          "Grand Total",
          Number(grandTotal?.grandTotal || 0).toFixed(2),
        ];
        rows.push(grandTotalRow);

        const csvContent = [
            header.join(","),
            ...rows.map(row => row.join(","))
        ].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Purchase_summary_return.csv";
        link.click();
    };

    const downloadPDF = () => {
        const logoUrl = clients[0]?.shopPhoto;

        const doc = new jsPDF("landscape");
        const marginX = 10;
        const boxWidth = doc.internal.pageSize.width - 2 * marginX;
        const boxHeight = 25;
        let startY = 15;

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");

        doc.rect(marginX, startY - 5, boxWidth, boxHeight);

        if (logoUrl) {
            doc.addImage(logoUrl, "JPEG", marginX + 5, startY - 2, 25, 20);
        }

        const paddingBetweenHeaderAndTable = 5;
        const textX = marginX + 35;

        doc.text(clients[0]?.shopShortName || "", textX, startY);
        doc.setFont("helvetica", "normal");
        doc.text(
            `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${clients[0]?.district || ""}`,
            textX,
            startY + 5
        );
        doc.text(
            `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${clients[0]?.contactNo1 || ""}`,
            textX,
            startY + 10
        );
        doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 15);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        const heading = "Purchase return summary report";
        const headingWidth = doc.getTextWidth(heading);
        const pageWidth = doc.internal.pageSize.width;
        doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 8);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const financialYearText = `Financial Year: ${tableData[0]?.financialYear || ""}`;
        const headingStartX = marginX + boxWidth - headingWidth - 10;
        doc.text(financialYearText, headingStartX, startY + 13);
        
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        const fromDate = "From : " + (LocalFormatDate(getDate(filters?.fromDate)) || "");
        const toDate = "To : " + (LocalFormatDate(getDate(filters?.toDate)) || "");

        const tableStartY = startY + boxHeight + paddingBetweenHeaderAndTable;
        doc.text(fromDate, marginX, tableStartY-3);
        doc.text(toDate, marginX + 50, tableStartY-3);

        let tableBody = tableData.map((item, index) => [
            (index + 1).toString(),
            
            item.supplier || "",
            rupeeFormat(item.grandTotal) || "",
        ]);

        const grandTotal = tableData.reduce(
            (acc, item) => {
                acc.grandTotal += item.grandTotal || 0;
                return acc;
            },
            {
                grandTotal: 0
            }
        );

        autoTable(doc, {
            startY: 47,
            head: [
                [
                    "SI.No", "Supplier Name", "Grand Total"
                ],
            ],
            body: tableBody,
            foot: [[
                {
                    content: 'Grand Total', 
                    colSpan: 2,
                },
                {
                    content: rupeeFormat(grandTotal.grandTotal)
                }

            ]],
            theme: "grid",
            styles: { fontSize: 8, textColor: 0, lineColor: 0, lineWidth: 0.1 },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: 0,
                fontStyle: "bold",
                lineColor: 0,
                lineWidth: 0.1,
                halign:'center'
            },
            footStyles : {
                fillColor: [255, 255, 255],
                halign: "right" 

            },
            margin: { top: 25, left: 10, right: 10 },
            columnStyles: {
                0: { halign: "center" }, // SI.No (Center aligned)
                1: { halign: "center" }, // Date (Center aligned)
                2: { halign: "right" },
            }
        });

        // Footer Section (Page Number)
        const pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            let pageHeight = doc.internal.pageSize.height;
            let footerY = pageHeight - 15;

            doc.setFontSize(10);
            doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, footerY);
        }

        doc.save("purchase_return_summary_report.pdf");
    };


    return (
        <>
            <ReportFilter onSearch={handleSearch} />
            {tableData.length > 0 ? (
                <Card className="mt-2 p-2 shadow-lg border-0 rounded-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5 className="fw-bold">Purchase return summary</h5>
                        <div>
                            <Button className="me-2 btn-sm" variant="success" onClick={downloadCSV}>
                                Download CSV
                            </Button>
                            <Button className="me-2 btn-sm" variant="success" onClick={downloadPDF}>
                                Download PDF
                            </Button>
                        </div>
                    </div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>SI.No</th>
                                <th>Financial Year</th>
                                <th>Supplier Name</th>
                                <th>Grand Total</th>
                            </tr>
                        </thead>

                        <tbody>
                            {tableData?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.financialYear}</td>
                                    <td>{item.supplier}</td>
                                    <td>{rupeeFormat(item.grandTotal)}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </Table>
                </Card>
            ) : (
                <h4 className="text-center fw-bold text-secondary">No such data found!</h4>
            )}
        </>
    );
};

export default PurchaseReturnSummary;
