import { useState } from "react";
import { Card, Container, Nav } from "react-bootstrap";
import UserLayout from "../../components/custom/layouts/UserLayout";
import SalesBillersWise from "../../components/report/SalesBillersWise";
import SalesDateWise from "../../components/report/SalesDateWise";
import SalesGstRateWise from "../../components/report/SalesGstRateWise";
import SalesInvoiceWise from "../../components/report/SalesInvoiceWise";
import SalesProductDateWise from "../../components/report/SalesProductDateWise";
import SalesRepresenterBillWise from "../../components/report/SalesRepresenterWise";
import "../../styles/styles.css";
import SalesTenderWise from "../../components/report/SalesTenderWise";

const SalesReport = () => {
    const [activeTab, setActiveTab] = useState("invoice");

    return (
        <UserLayout>
            <h3 className="mb-2 font-bold">Sales Report</h3>

            <Container fluid className="p-4 shadow-lg bg-white rounded">

                <Nav variant="pills" className="custom-tabs mb-2 justify-content-start" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey || "invoice")}>

                    <Nav.Item>
                        <Nav.Link eventKey="invoice" className="custom-tab">Sales Invoice Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="date" className="custom-tab">Sales Date Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="gst" className="custom-tab">Sales GST Rate Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="product" className="custom-tab">Sales Product Date Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="customer" className="custom-tab">Sales Billed by Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="representer" className="custom-tab">Representer Wise</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="tender" className="custom-tab">Sales Tender Wise </Nav.Link>
                    </Nav.Item>
                </Nav>

                {/* Tab Content */}
                <Card className="tab-content-card">
                    {activeTab === "invoice" && <TabContent title="Sales Invoice Wise" />}
                    {activeTab === "date" && <TabContent title="Sales Date Wise" />}
                    {activeTab === "gst" && <TabContent title="Sales GST Rate Wise" />}
                    {activeTab === "product" && <TabContent title="Sales Product Date Wise" />}
                    {activeTab === "customer" && <TabContent title="Sales Billed by Wise" />}
                    {activeTab === "representer" && <TabContent title="Representer Wise" />}
                    {activeTab === "tender" && <TabContent title="Sales Tender Wise" />}
                </Card>
            </Container>
        </UserLayout>
    );
};

// Tab Content Component
interface TabContentProps {
    title: string;
}

// Tab Content Component with explicit type
const TabContent: React.FC<TabContentProps> = ({ title }) => (
    <>
        {title === "Sales Invoice Wise" && <div>
            <SalesInvoiceWise />
        </div>}
        {title === "Sales Date Wise" && <div>
            <SalesDateWise />
        </div>}
        {title === "Sales GST Rate Wise" && <div>
            <SalesGstRateWise />
        </div>}
        {title === "Sales Product Date Wise" && <div>
            <SalesProductDateWise />
        </div>}
        {title === "Sales Billed by Wise" && <div>
            <SalesBillersWise />
        </div>}
        {title === "Representer Wise" && <div>
            <SalesRepresenterBillWise />
        </div>}
        {title === "Sales Tender Wise" && <div>
            <SalesTenderWise />
        </div>}
    </>
);


export default SalesReport;
