import { jsPDF } from "jspdf"
import autoTable from "jspdf-autotable"
import React, { ChangeEventHandler, MouseEventHandler, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { SingleValue } from 'react-select'
import CreatableDropdown from "../components/CreatableDropdown"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput, FormInput2 } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import SearchableSelect from "../components/searchableSelect"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { AccountHolderType, addAccountHolderAction, deleteAccountHolderAction, editAccountHolderAction, fetchAccountHolderAction } from "../redux/actions/accountHolder"
import { AuthUserType, RoleType } from "../redux/actions/authUserActions"
import { fetchBranchAction } from "../redux/actions/branchAction"
import { fetchCategoryAction } from "../redux/actions/categoryAction"
import { DropDownType, addDropDownAction, fetchDropDownAction } from "../redux/actions/dropdownAction"
import { AppDispatch, StoreState } from "../redux/store"

import {
    ClientRegistrationType,
    fetchClientRegistrationAction,
} from "../redux/actions/clientRegistration"
import "../styles/styles.css"

interface TableBody {
    sno: number,
    groupName: string | undefined,
    accountName: string | undefined,
    place: string | undefined,
    contactNumber1: number | undefined | string,
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    rolePermissions: RoleType | undefined
}

type HandleEdit = (value: AccountHolderType) => void
type HandleDelete = (value: AccountHolderType) => void

const AccountHolder = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const AccountList = useSelector<StoreState, AccountHolderType[]>(state => state.account.filter(account => account.groupName !== 'main'))
    const AuthUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const DropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropdown)
    // const BranchList = useSelector<StoreState, BranchType[]>(state => state.branch)
    // const [showForm, setShowForm] = useState<boolean>(false)

    const clients = useSelector<StoreState, ClientRegistrationType[]>(state => state.clientRegistration)

    useEffect(() => {
        dispatch(fetchClientRegistrationAction())
    }, [])

    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<AccountHolderType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [deleteName, setDeleteName] = useState<string | undefined>('')
    const [accountNumber, setAccountNumber] = useState<number>()
    const [id, setId] = useState<number>()
    const url = window.location.pathname
    const rolePermissions = AuthUser?.roles?.find(r => r.pageUrl === url)

    useEffect(() => {
        dispatch(fetchAccountHolderAction())
        dispatch(fetchBranchAction())
        dispatch(fetchDropDownAction())
        dispatch(fetchCategoryAction())
    }, [dispatch])

    const [branchId, setBranchId] = useState<string>('')
    const [branchIdError, setBranchIdError] = useState<string>('')
    const [groupName, setGroupName] = useState<string>('')
    const [groupNameError, setGroupNameError] = useState<string>('')
    const [nameTag, setNameTag] = useState<string>('')
    const [nameTagError, setNameTagError] = useState<string>('')
    const [accountName, setAccountName] = useState<string>('')
    const [accountNameError, setAccountNameError] = useState<string>('')
    // const [accountNumber, setAccountNumber] = useState<string>('')
    // const [accountNumberError, setAccountNumberError] = useState<string>('')
    const [address1, setAddress1] = useState<string>('')
    const [address1Error, setAddress1Error] = useState<string>('')
    const [address2, setAddress2] = useState<string>('')
    const [address2Error, setAddress2Error] = useState<string>('')
    const [doorNumber, setdoorNumber] = useState<string>('')
    const [doorNumberError, setDoorNumberError] = useState<string>('')
    const [accountCategory, setAccountCategory] = useState<string>("NON-ACCOUNT")
    const [accountCategoryError, setAccountCategoryError] = useState<string>('')
    const [allowSms, setAllowSms] = useState<boolean>(false)
    const [allowSmsError, setAllowSmsError] = useState<string>('')
    const [area, setArea] = useState<string>('')
    const [areaError, setAreaError] = useState<string>('')
    const [place, setPlace] = useState<string>('')
    const [placeError, setPlaceError] = useState<string>('')
    const [supplierPlace, setSupplierPlace] = useState<string>('')
    const [supplierPlaceError, setSupplierPlaceError] = useState<string>('')
    const [district, setDistrict] = useState<string>('')
    const [districtError, setDistrictError] = useState<string>('')
    const [pincode, setPincode] = useState<string | undefined>('')
    const [pincodeError, setPincodeError] = useState<string>('')
    const [state, setState] = useState<string>('')
    const [stateError, setStateError] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [countryError, setCountryError] = useState<string>('')
    const [route, setRoute] = useState<string>('')
    const [routeError, setRouteError] = useState<string>('')
    const [contactNumber1, setContactNumber1] = useState<string | undefined>('')
    const [contactNumber1Error, setContactNumber1Error] = useState<string>('')
    const [contactNumber2, setContactNumber2] = useState<string | undefined>('')
    const [contactNumber2Error, setContactNumber2Error] = useState<string>('')
    const [oldBalance, setOldBalance] = useState<string>('')
    const [oldBalanceError, setOldBalanceError] = useState<string>('')
    const [asOnDate, setAsOnDate] = useState<string>('')
    const [asOnDateError, setAsOnDateError] = useState<string>('')
    const [gstNumber, setGstNumber] = useState<string | undefined>('')
    const [gstNumberError, setGstNumberError] = useState<string>('')
    const [aadharNumber, setAadharNumber] = useState<string | undefined>('')
    const [aadharNumberError, setAadharNumberError] = useState<string>('')
    const [contactPerson, setContactPerson] = useState<string>('')
    const [contactPersonError, setContactPersonError] = useState<string>('')
    const [contactPersonNumber, setContactPersonNumber] = useState<string | undefined>('')
    const [contactPersonNumberError, setContactPersonNumberError] = useState<string>('')
    const [bankAccountNumber, setBankAccountNumber] = useState<string | undefined>('')
    const [bankAccountNumberError, setBankAccountNumberError] = useState<string>('')
    const [bankName, setBankName] = useState<string>('')
    const [bankNameError, setBankNameError] = useState<string>('')
    const [bankBranchName, setBankBranchName] = useState<string>('')
    const [bankBranchNameError, setBankBranchNameError] = useState<string>('')
    const [ifscCode, setIfscCode] = useState<string>('')
    const [ifscCodeError, setIfscCodeError] = useState<string>('')
    const [areaName, setAreaName] = useState<string>('')
    const [areaNameError, setAreaNameError] = useState<string>('')
    const [openModal, setOpenModal] = useState(false)
    const [placeName, setPlaceName] = useState<string>('')
    const [placeNameError, setPlaceNameError] = useState<string>('')
    const [placeModal, setPlaceModal] = useState(false)
    const [supplierPlaceName, setSupplierPlaceName] = useState<string>('')
    const [supplierPlaceNameError, setSupplierPlaceNameError] = useState<string>('')
    const [showSupplierPlaceModal, setShowSupplierPlaceModal] = useState(false)
    const [routeName, setRouteName] = useState<string>('')
    const [routeNameError, setRouteNameError] = useState<string>('')
    const [routeModal, setRouteModal] = useState(false)
    const [districtName, setDistrictName] = useState<string>('')
    const [districtNameError, setDistrictNameError] = useState<string>('')
    const [districtModal, setDistrictModal] = useState(false)
    const [stateName, setStateName] = useState<string>('')
    const [stateNameError, setStateNameError] = useState<string>('')
    const [stateModal, setStateModal] = useState(false)
    const [countryName, setCountryName] = useState<string>('')
    const [countryNameError, setCountryNameError] = useState<string>('')
    const [countryModal, setCountryModal] = useState(false)
    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()
    const searchTerm = searchQuery.get('search') || ''
    const mainContentRef = React.useRef<HTMLDivElement>(null)
    const [showTable, setShowTable] = useState<boolean>(false);
    //For focus
    const [focusedField, setFocusedField] = useState<string | null>(null);
    const groupRef = useRef<HTMLDivElement>(null);
    const categoryRef = useRef<HTMLInputElement>(null);
    const tagRef = useRef<HTMLDivElement>(null);
    const supplierRef = useRef<HTMLDivElement>(null);
    const accountNameRef = useRef<HTMLInputElement>(null);
    const contactRef = useRef<HTMLInputElement>(null);
    const contactNumber2Ref = useRef<HTMLInputElement>(null);
    const address1Ref = useRef<HTMLInputElement>(null);
    const address2Ref = useRef<HTMLInputElement>(null);
    const pincodeRef = useRef<HTMLInputElement>(null);
    const areaRef = useRef<HTMLDivElement>(null);
    const placeRef = useRef<HTMLDivElement>(null);
    const stateRef = useRef<HTMLDivElement>(null);
    const districtRef = useRef<HTMLDivElement>(null);
    const countryRef = useRef<HTMLDivElement>(null);
    const routeRef = useRef<HTMLDivElement>(null);
    const gstRef = useRef<HTMLInputElement>(null);
    const aadharRef = useRef<HTMLInputElement>(null);
    const contactPersonRef = useRef<HTMLInputElement>(null);
    const contactPersonNumberRef = useRef<HTMLInputElement>(null);
    const oldBalanceRef = useRef<HTMLInputElement>(null);
    const asOnDateRef = useRef<HTMLInputElement>(null);
    const allowSmsRef = useRef<HTMLInputElement>(null);
    const ifscCodeRef = useRef<HTMLInputElement>(null);
    const bankAccountNumberRef = useRef<HTMLInputElement>(null);
    const bankNameRef = useRef<HTMLInputElement>(null);
    const branchNameRef = useRef<HTMLInputElement>(null);
    // const allowSmsRef = useRef<HTMLInputElement>(null);
    // const allowSmsRef = useRef<HTMLInputElement>(null);
    // const [focusedField, setFocusedField] = useState<string>('');

    const handleKeyDown = <T extends Element>(
        e: React.KeyboardEvent<T>,
        nextRef?: React.RefObject<HTMLElement>,
        nextField?: string,
        prevRef?: React.RefObject<HTMLElement>,
        prevField?: string
    ) => {
        const moveFocus = (ref?: React.RefObject<HTMLElement>, field?: string) => {
            if (!ref?.current) return;

            e.preventDefault();

            const activeElement = document.activeElement as HTMLElement;
            if (activeElement && 'blur' in activeElement) {
                activeElement.blur();
            }

            const input = ref.current.querySelector('input, select, textarea') || ref.current;
            if (input instanceof HTMLElement) {
                input.focus();
                setFocusedField(field || '');
            }
        };

        if (e.key === 'ArrowDown') moveFocus(nextRef, nextField);
        if (e.key === 'ArrowUp') moveFocus(prevRef, prevField);
    };

    // pdf functionality

    const downloadPDF = (groupName: String) => {
        const logoUrl = clients[0]?.shopPhoto;

        const doc = new jsPDF("landscape");
        const marginX = 10;
        const boxWidth = doc.internal.pageSize.width - 2 * marginX;
        const boxHeight = 25;
        let startY = 15;

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");

        doc.rect(marginX, startY - 5, boxWidth, boxHeight);

        if (logoUrl) {
            doc.addImage(logoUrl, "JPEG", marginX + 5, startY - 2, 25, 20);
        }

        const textX = marginX + 35;

        doc.text(clients[0]?.shopFullName || "", textX, startY);
        doc.setFont("helvetica", "normal");
        doc.text(
            `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${clients[0]?.district || ""
            } `,
            textX,
            startY + 5
        );
        doc.text(
            `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${clients[0]?.contactNo1 || ""
            }`,
            textX,
            startY + 10
        );
        doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 15);

        const currentDate = new Date().toLocaleDateString("en-GB");
        const pageWidth = doc.internal.pageSize.width;
        doc.text(`Date: ${currentDate}`, pageWidth - marginX - 40, startY + 15);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        const heading = "Account Holder List";
        const headingWidth = doc.getTextWidth(heading);

        doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 8);

        // const AccountHolderCount = AccountList.length || 0;

        let filteredData = [];
        if (groupName) {
            filteredData = AccountList.filter((item) => item.groupName?.toLowerCase() === groupName.toLowerCase())

        } else {
            const order = ["supplier", "customer", "representer"];
            order.forEach((groupType) => {
                const filteredItems = AccountList.filter(
                    (item) => item.groupName?.toLowerCase() === groupType
                );
                filteredData.push(...filteredItems);

            })
            const remainingItems = AccountList.filter(
                (item) => !order.includes(item.groupName?.toLowerCase() ?? "")
            );
            filteredData.push(...remainingItems);
        }
        // console.log("Filtered Data:", filteredData);

        const AccountHolderCount = filteredData.length || 0;


        doc.text(`Total Account Holders:${AccountHolderCount}`,
            pageWidth - marginX - 60, startY + boxHeight + 3);


        const tableStartY = startY + boxHeight + 10;

        if (!filteredData || filteredData.length === 0) {
            doc.text("No data available", marginX, tableStartY);
            doc.save("AccountHolder.pdf");
            return;
        }


        ;




        let tableBody = filteredData.map((item, index) => [
            (index + 1).toString(),
            item.groupName || "",
            item.accountName || "",
            item.place || "",
            item.contactNumber1 || "0",
        ]);

        autoTable(doc, {
            startY: 47,
            head: [
                ["SI.No", "Group Name", "Account Name", "Place", "Contact Number"],
            ],
            body: tableBody,
            theme: "grid",
            styles: { fontSize: 8, textColor: 0, lineColor: 0, lineWidth: 0.1 },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: 0,
                fontStyle: "bold",
                lineColor: 0,
                lineWidth: 0.1,
            },
            margin: { top: 25, left: 10, right: 10 },
            columnStyles: {
                0: { halign: "center" },
                1: { halign: "left" },
                2: { halign: "left" },
                3: { halign: "left" },
                4: { halign: "left" },
            },

        });

        const pageCount = doc.getNumberOfPages();
        console.log(pageCount);

        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            let pageHeight = doc.internal.pageSize.height;
            let footerY = pageHeight - 15;

            doc.setFontSize(10);
            doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, footerY);
        }

        doc.save("AccountHolder.pdf");
    };







    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery({ search: e.target.value })
    }
    const filterAccountHandler = (account: any, searchTerm: any) => {
        return searchTerm !== '' ? (new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(account.accountName)
            // || new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(account.place)
            || new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(account.contactNumber1)) : true || []
    }

    React.useEffect(() => {
        if (searchTerm !== '') {
            searchRef.current?.focus()
        }
    }, [])

    // const handleBranch = (selectedOption: { value: string; label: string } | null) => {
    //     if (selectedOption) {
    //         setBranchId(selectedOption.value);
    //         console.log(selectedOption.value)
    //         console.log(branchId)
    //     } else {
    //         setBranchId('');
    //     }
    //     setBranchIdError('');
    // };

    const handleGroup = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            setGroupName(selectedOption.value);
        } else {
            setGroupName('');
        }
        setGroupNameError('');
    };

    const handleTag = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            setNameTag(selectedOption.value);
        } else {
            setNameTag('');
        }
        setNameTagError('');
    };

    const handleSmsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllowSms(event.target.checked);
    };

    const handleAccountName: ChangeEventHandler<HTMLInputElement> = (e) => {
        const newAccountName = e.target.value.toLowerCase();

        const isDuplicate = AccountList.some(account => account.accountName && account.accountName.toLowerCase() === newAccountName.toLowerCase());

        if (isDuplicate) {
            setAccountNameError('Account Name must be unique');
        } else {
            setAccountName(e.target.value);
            setAccountNameError('');
        }
    };

    const handleAddress1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress1(e.target.value)
        setAddress1Error('')
    }

    const handleAddress2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress2(e.target.value)
        setAddress2Error('')
    }

    // const handleAccountCategory = (newValue: SingleValue<{ label: string; value: string }>) => {
    //     if (newValue) {
    //         setAccountCategory(newValue.value);
    //         setAccountCategoryError('');
    //     } else {
    //         setAccountCategory("");
    //         setAccountCategoryError('');
    //     }
    // };


    const handleAccountCategory: ChangeEventHandler<HTMLInputElement> = (e) => {


        setAccountCategory(e.target.value)
        setAccountCategoryError('')
    }


    const handleArea = (newValue: SingleValue<{ label: string; value: string }>) => {
        if (newValue) {
            setArea(newValue.value);
            setAreaError('');
        } else {
            setArea('');
            setAreaError('');
        }
    };

    const handlePlace = (newValue: SingleValue<{ label: string; value: string }>) => {
        if (newValue) {
            setPlace(newValue.value);
            setPlaceError('');
        } else {
            setPlace('');
            setPlaceError('');
        }
    };

    const handleSupplierPlace = (newValue: SingleValue<{ label: string; value: string }>) => {
        if (newValue) {
            setSupplierPlace(newValue.value);
            setSupplierPlaceError('');
        } else {
            setSupplierPlace('');
            setSupplierPlaceError('');
        }
    };

    const handleDistrict = (newValue: SingleValue<{ label: string; value: string }>) => {
        if (newValue) {
            setDistrict(newValue.value);
            setDistrictError('');
        } else {
            setDistrict('');
            setDistrictError('');
        }
    };

    const handlePincode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPincode(e.target.value)
        setPincodeError('')
    }

    const handleState = (newValue: SingleValue<{ label: string; value: string }>) => {
        if (newValue) {
            setState(newValue.value);
            setStateError('');
        } else {
            setState('');
            setStateError('');
        }
    };

    const handleCountry = (newValue: SingleValue<{ label: string; value: string }>) => {
        if (newValue) {
            setCountry(newValue.value);
            setCountryError('');
        } else {
            setCountry('');
            setCountryError('');
        }
    };

    const handleRoute = (newValue: SingleValue<{ label: string; value: string }>) => {
        if (newValue) {
            setRoute(newValue.value);
            setRouteError('');
        } else {
            setRoute('');
            setRouteError('');
        }
    };

    const handleContactNumber1: ChangeEventHandler<HTMLInputElement> = (e) => {
        const newAccountNumber = e.target.value.toLowerCase();

        const isDuplicate = AccountList.some(account => account.accountName && account.accountName.toLowerCase() === newAccountNumber.toLowerCase());

        if (isDuplicate) {
            setContactNumber1Error('Account Number must be unique');
        } else {
            setContactNumber1(e.target.value);
            setContactNumber1Error('');
        }
    }

    const handleContactNumber2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactNumber2(e.target.value)
        setContactNumber2Error('')
    }

    const handleOldBalance: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOldBalance(e.target.value)
        setOldBalanceError('')
        if (e.target.value === '') setAsOnDate('')
    }

    const handleAsOnDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAsOnDate(e.target.value)
        setAsOnDateError('')
    }

    const handleGstNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGstNumber(e.target.value)
        setGstNumberError('')
    }

    const handleAadharNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAadharNumber(e.target.value)
        setAadharNumberError('')
    }

    const handleContactPerson: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactPerson(e.target.value)
        setContactPersonError('')
    }

    const handleContactPersonNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactPersonNumber(e.target.value)
        setContactPersonNumberError('')
    }

    const handleBankAccountNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBankAccountNumber(e.target.value)
        setBankAccountNumberError('')
    }

    const handleBankName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBankName(e.target.value)
        setBankNameError('')
    }

    const handleBranchBankName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBankBranchName(e.target.value)
        setBankBranchNameError('')
    }

    const handleIfscCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setIfscCode(e.target.value)
        setIfscCodeError('')
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)

    }

    const handleDelete: HandleDelete = async (value) => {
        setDeleteId(value.accountNumber)

        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const handleModalClose = () => {
        setOpenModal(false)
        setPlaceModal(false)
        setShowSupplierPlaceModal(false)
        setRouteModal(false)
        setDistrictModal(false)
        setStateModal(false)
        setCountryModal(false)
    }

    const handleAreaName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAreaName(e.target.value)
    }
    const handlePlaceName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPlaceName(e.target.value)
    }
    const handleSupplierPlaceName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSupplierPlaceName(e.target.value)
    }
    const handleRouteName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteName(e.target.value)
    }
    const handleDistrictName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDistrictName(e.target.value)
    }
    const handleStateName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStateName(e.target.value)
    }
    const handleCountryName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCountryName(e.target.value)
    }
    const resetStates = () => {
        setBranchId('')
        setGroupName('')
        setNameTag('')
        setAccountName('')
        setBankName('')
        setBankAccountNumber('')
        setBankBranchName('')
        setIfscCode('')
        setAddress1('')
        setAddress2('')
        setArea('')
        setPlace('')
        setSupplierPlace('')
        setDistrict('')
        setPincode('')
        setState('')
        setCountry('')
        setRoute('')
        setContactNumber1('')
        setContactNumber2('')
        setContactNumber2Error('')
        setOldBalance('')
        setAsOnDate('')
        setAsOnDateError('')
        setGstNumber('')
        setGstNumberError('')
        setAadharNumber('')
        setAadharNumberError('')
        setContactPerson('')
        setContactPersonNumber('')
        setAreaName('')
        setPlaceName('')
        setRouteName('')
        setDistrictName('')
        setStateName('')
        setCountryName('')
        setEditData(null)
        setAccountCategory("NON-ACCOUNT")
        setAccountCategoryError('')
        setAllowSms(false)
        setAllowSmsError('')
        setBranchIdError('')
        setGroupNameError("")
        setNameTagError('')
        setAccountNameError('')
        setAddress1Error('')
        setAddress2Error('')
        setAreaError('')
        setPlaceError('')
        setSupplierPlaceError('')
        setDistrictError('')
        setPincodeError('')
        setStateError('')
        setCountryError('')
        setRouteError('')
        setContactNumber1Error('')
        setContactNumber2Error('')
        setOldBalanceError('')
        setAsOnDateError('')
        setGstNumberError('')
        setAadharNumberError('')
        setContactPersonError('')
        setContactPersonNumberError('')
        setBankAccountNumberError('')
        setBankNameError('')
        setBankBranchNameError('')
        setIfscCodeError('')
        setAreaNameError('')
        setSupplierPlaceNameError('')
    }

    const viewTable = () => {
        setShowTable((data) => (!data))
    }

    const handleSave = () => {
        let error = false

        const addData: AccountHolderType = {
            // accountNumber: id,
            branchId: branchId,
            groupName: groupName,
            nameTag: nameTag,
            accountName: accountName,
            contactNumber1: parseInt(contactNumber1 !== undefined ? contactNumber1 : ''),
            contactNumber2: parseInt(contactNumber2 !== undefined ? contactNumber2 : ''),
            address1: address1,
            address2: address2,
            area: area,
            place: place,
            supplierPlace: supplierPlace,
            district: district,
            pincode: parseInt(pincode !== undefined ? pincode : ''),
            state: state,
            country: country,
            route: route,
            oldBalance: parseInt(oldBalance),
            asOnDate: asOnDate,
            gstNumber: gstNumber,
            aadharNumber: parseInt(aadharNumber !== undefined ? aadharNumber : ''),
            contactPerson: contactPerson,
            contactPersonNumber: parseInt(contactPersonNumber !== undefined ? contactPersonNumber : ''),
            bankAccountNumber: parseInt(bankAccountNumber !== undefined ? bankAccountNumber : ''),
            bankName: bankName,
            bankBranchName: bankBranchName,
            accountCategory: accountCategory,
            allowSms: allowSms,
            ifscCode: ifscCode,
            // branch: `${AuthUser?.branchId}`
        }

        const editdata: AccountHolderType = {
            accountNumber: accountNumber,
            branchId: branchId,
            groupName: groupName,
            nameTag: nameTag,
            accountName: accountName,
            contactNumber1: parseInt(contactNumber1 !== undefined ? contactNumber1 : ''),
            contactNumber2: parseInt(contactNumber2 !== undefined ? contactNumber2 : ''),
            address1: address1,
            address2: address2,
            area: area,
            place: place,
            supplierPlace: supplierPlace,
            district: district,
            pincode: parseInt(pincode !== undefined ? pincode : ''),
            state: state,
            country: country,
            route: route,
            oldBalance: parseInt(oldBalance),
            asOnDate: asOnDate,
            gstNumber: gstNumber,
            aadharNumber: parseInt(aadharNumber !== undefined ? aadharNumber : ''),
            contactPerson: contactPerson,
            contactPersonNumber: parseInt(contactPersonNumber !== undefined ? contactPersonNumber : ''),
            bankAccountNumber: parseInt(bankAccountNumber !== undefined ? bankAccountNumber : ''),
            bankName: bankName,
            bankBranchName: bankBranchName,
            accountCategory: accountCategory,
            allowSms: allowSms,
            ifscCode: ifscCode,
            branch: `${AuthUser?.branchId}`

        }

        // if (branchId?.toString() === '') {
        //     setBranchIdError('Branch name required')
        //     error = true
        // }

        if (groupName === '') {
            setGroupNameError('Group name required')
            error = true
        }

        if (!accountCategory || accountCategory.trim() === "") {
            setAccountCategoryError('Account category required')
            error = true
        }

        if (nameTag === '') {
            setNameTagError('Name tag required')
            error = true
        }

        if (accountName === '') {
            setAccountNameError('Account name required')
            error = true
        }

        if (!contactNumber1?.toString().trim()) {
            setContactNumber1Error('Primary contact number required');
            error = true;
        } else if (![10, 12].includes(contactNumber1.toString().trim().length)) {
            setContactNumber1Error('Enter a valid 10 or 12-digit primary contact number');
            error = true;
        }

        if (contactNumber2 && contactNumber2.toString().trim().length > 0) {
            if (![10, 12].includes(contactNumber2.toString().trim().length)) {
                setContactNumber2Error('Enter a valid 10 or 12-digit secondary contact number');
                error = true;
            }
        }

        if (groupName.toLocaleLowerCase() === 'supplier') {
            if (supplierPlace === '') {
                setSupplierPlaceError('Supplier place required')
                error = true
            }
        }

        if (pincode && pincode.toString().trim().length > 0) {
            if ((pincode?.toString() || '').length > 6 || (pincode?.toString() || '').length < 6) {
                setPincodeError('Enter your 6 digits pincode')
                error = true
            }
        }

        if (gstNumber && gstNumber.toString().trim().length > 0) {
            console.log(gstNumber)
            if ((gstNumber || '').length > 15 || (gstNumber || '').length < 15) {
                setGstNumberError('Enter your valid GST number')
                error = true
            }
        }

        if (aadharNumber && aadharNumber.toString().trim().length > 0) {
            if ((aadharNumber?.toString() || '').length > 12 || (aadharNumber?.toString() || '').length < 12) {
                setAadharNumberError('Enter your valid 12 digits aadhar number')
                error = true
            }
        }

        if (contactPersonNumber && contactPersonNumber.toString().trim().length > 0) {
            if (![10, 12].includes(contactPersonNumber.toString().trim().length)) {
                setContactPersonNumberError('Enter a valid 10 or 12-digit contact number');
                error = true;
            }
        }

        if (oldBalance !== '' && parseFloat(oldBalance) > 0) {
            if (asOnDate === '') {
                setAsOnDateError('Till Date Required')
                error = true
            }
        }

        if (!error) {
            console.log('in');

            if (formType === FormTypeList.ADD) {
                dispatch(addAccountHolderAction(addData)).then(text => {
                    toast(text, ToastTypes.SUCCESS)
                    resetStates()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== null) {
                dispatch(editAccountHolderAction(editdata, editData?.accountNumber)).then(text => {
                    toast(text, ToastTypes.SUCCESS)
                    resetStates()
                    setEditData(null)
                    setFormType(FormTypeList.ADD)
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {
            const dateObj = new Date(editData.asOnDate || '')

            const d = dateObj.getDate().toString().padStart(2, '0')
            const m = (dateObj.getMonth() + 1).toString().padStart(2, '0')
            const y = dateObj.getFullYear().toString()

            setAccountNumber(editData.accountNumber)
            setBranchId(editData.branchId?.toString() || '')
            setGroupName(editData.groupName || '')
            setNameTag(editData.nameTag || '')
            setAccountName(editData.accountName || '')
            setAddress1(editData.address1 || '')
            setAddress2(editData.address2 || '')
            setArea(editData.area || '')
            setPlace(editData.place || '')
            setSupplierPlace(editData.supplierPlace || '')
            setDistrict(editData.district || '')
            setPincode(editData.pincode?.toString() !== "0" ? editData.pincode?.toString() : '')
            setState(editData.state || '')
            setCountry(editData.country || '')
            setRoute(editData.route || '')
            setContactNumber1(editData.contactNumber1?.toString() !== '0' ? editData.contactNumber1?.toString() : '')
            setContactNumber2(editData.contactNumber2?.toString() !== '0' ? editData.contactNumber2?.toString() : '')
            setOldBalance(editData.oldBalance?.toString() || '')
            setAsOnDate(y + '-' + m + '-' + d)
            setGstNumber(editData.gstNumber || '')
            setAadharNumber(editData.aadharNumber?.toString() !== '0' ? editData.aadharNumber?.toString() : '')
            setContactPerson(editData.contactPerson || '')
            setContactPersonNumber(editData.contactPersonNumber?.toString() !== '0' ? editData.contactPersonNumber?.toString() : '')
            setBankAccountNumber(editData.bankAccountNumber?.toString() !== '0' ? editData.bankAccountNumber?.toString() : '')
            setBankName(editData.bankName || '')
            setBankBranchName(editData.bankBranchName?.toString() || '')
            setIfscCode(editData.ifscCode || '')
            setAccountCategory(editData.accountCategory || 'NON-ACCOUNT')
            setAllowSms(editData.allowSms || false)
        } else {
            resetStates()
        }
    }, [formType, editData])

    // const onAccountCategory = (newValue: any) => {
    //     let error = false;

    //     if (newValue === '') {
    //         setAccountCategoryError('Account Category Required');
    //         error = true;
    //     }

    //     const addDynAccountCategory = {
    //         id: id,
    //         category: "account_category",
    //         title: newValue,
    //     };

    //     if (!error) {
    //         dispatch(addDropDownAction(addDynAccountCategory))
    //             .then(() => {
    //                 toast('Added Account Category', ToastTypes.SUCCESS);
    //                 setAccountCategory(newValue);
    //             })
    //             .catch((text) => {
    //                 toast(text, ToastTypes.ERROR);
    //             });
    //     }
    // };

    const onArea = (newValue: any) => {
        let error = false;

        if (newValue === '') {
            setAreaError('Division required');
            error = true;
        }

        const addDynArea = {
            id: id,
            category: "area",
            title: newValue,
        };

        if (!error) {
            dispatch(addDropDownAction(addDynArea))
                .then(() => {
                    toast('Added Division', ToastTypes.SUCCESS);
                    setArea(newValue);
                })
                .catch((text) => {
                    toast(text, ToastTypes.ERROR);
                });
            setOpenModal(false);
        }
    };

    const onPlace = (newValue: any) => {

        let error = false

        if (newValue === '') {
            setPlaceError('Place required');
            error = true;
        }

        const addDynPlace: DropDownType = {
            id: id,
            category: "place",
            title: newValue
        }

        if (!error) {
            dispatch(addDropDownAction(addDynPlace)).then(text => {
                toast('Added Place', ToastTypes.SUCCESS)
                setPlace(newValue);
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
            setPlaceModal(false)
        }

    }

    const onSupplierPlace = (newValue: any) => {
        let error = false
        if (newValue === '') {
            setSupplierPlaceError('Supplier Place required')
            error = true
        }

        const addDynSupplierPlace: DropDownType = {
            id: id,
            category: "supplier place",
            title: newValue
        }
        if (!error) {
            dispatch(addDropDownAction(addDynSupplierPlace)).then(text => {
                toast('Supplier Place Added', ToastTypes.SUCCESS)
                setSupplierPlace(newValue);
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
            setShowSupplierPlaceModal(false)
        }

    }
    const onRoute = (newValue: any) => {
        let error = false

        if (newValue === '') {
            setRouteError('Route required');
            error = true;
        }
        const addDynRoute: DropDownType = {
            id: id,
            category: 'route',
            title: newValue
        }
        if (!error) {
            dispatch(addDropDownAction(addDynRoute)).then(text => {
                toast('Added Route', ToastTypes.SUCCESS)
                setRoute(newValue);
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
            setRouteModal(false)
        }
    }

    const onDistrict = (newValue: any) => {
        let error = false

        if (newValue === '') {
            setDistrictError('District required');
            error = true;
        }
        const addDynDistrict: DropDownType = {
            id: id,
            category: 'district',
            title: newValue
        }
        if (!error) {
            dispatch(addDropDownAction(addDynDistrict)).then(text => {
                toast('Added District', ToastTypes.SUCCESS)
                setDistrict(newValue);
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
            setDistrictModal(false)
        }
    }

    const onState = (newValue: any) => {
        let error = false
        if (newValue === '') {
            setStateError('State required');
            error = true;
        }

        const addDynState: DropDownType = {
            id: id,
            category: 'state',
            title: newValue
        }
        if (!error) {
            dispatch(addDropDownAction(addDynState)).then(text => {
                toast('Added State', ToastTypes.SUCCESS)
                setState(newValue);
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
            setStateModal(false)
        }

    }
    const onCountry = (newValue: any) => {
        let error = false

        if (newValue === '') {
            setCountryError('Country required');
            error = true;
        }

        const addDynCountry: DropDownType = {
            id: id,
            category: 'country',
            title: newValue
        }
        if (!error) {
            dispatch(addDropDownAction(addDynCountry)).then(text => {
                toast('Added Country', ToastTypes.SUCCESS)
                setCountry(newValue);
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
            setCountryModal(false)
        }
    }
    const onDelete = () => {

        if (deleteId) {
            dispatch(deleteAccountHolderAction(deleteId)).then(text => {
                toast(text, ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
                resetStates()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }
    return (
        <>
            <Modal
                headerText={"Delete AccountHolder"}
                visible={showDeleteForm}
                onClose={handleDeleteFormClose}
                centered
                size="lg"
            >
                <DeleteConfirmationForm
                    onDelete={onDelete}
                    value={deleteName}
                    onClose={handleDeleteFormClose}
                />
            </Modal>
            <Modal
                headerText={"Add Division"}
                visible={openModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="areaName"
                    value={areaName}
                    errorText={areaNameError}
                    required={true}
                    onChange={handleAreaName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => setAreaName("")}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onArea}
                >
                    save
                </button>
            </Modal>
            <Modal
                headerText={"Add Place"}
                visible={placeModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="placeName"
                    value={placeName}
                    errorText={placeNameError}
                    required={true}
                    onChange={handlePlaceName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => setPlaceName("")}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onPlace}
                >
                    save
                </button>
            </Modal>
            <Modal
                headerText={"Add Supplier Place"}
                visible={showSupplierPlaceModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="supplierPlaceName"
                    value={supplierPlaceName}
                    errorText={supplierPlaceNameError}
                    required={true}
                    onChange={handleSupplierPlaceName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => setSupplierPlaceName("")}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onSupplierPlace}
                >
                    save
                </button>
            </Modal>
            <Modal
                headerText={"Add Route"}
                visible={routeModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="routeName"
                    value={routeName}
                    errorText={routeNameError}
                    required={true}
                    onChange={handleRouteName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => setRouteName("")}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onRoute}
                >
                    save
                </button>
            </Modal>

            <Modal
                headerText={"Add District"}
                visible={districtModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="districtName"
                    value={districtName}
                    errorText={districtNameError}
                    required={true}
                    onChange={handleDistrictName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => setDistrictName("")}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onDistrict}
                >
                    save
                </button>
            </Modal>
            <Modal
                headerText={"Add State"}
                visible={stateModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="stateName"
                    value={stateName}
                    errorText={stateNameError}
                    required={true}
                    onChange={handleStateName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => setStateName("")}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onState}
                >
                    save
                </button>
            </Modal>
            <Modal
                headerText={"Add Country"}
                visible={countryModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="countryName"
                    value={countryName}
                    errorText={countryNameError}
                    required={true}
                    onChange={handleCountryName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => setCountryName("")}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onCountry}
                >
                    save
                </button>
            </Modal>

            <UserLayout>
                <h4>Account Holder</h4>
                <div className="row">
                    <div className={showTable ? "col-lg-7" : "col-lg-12"}>
                        <div className="card shadow-lg p-1">
                            <div className="text-end mt-1">
                                <button
                                    className="btn btn-sm fw-bold btn-success"
                                    style={{ float: "right" }}
                                    onClick={() => viewTable()}
                                >
                                    {showTable ? "Hide" : "View"}
                                </button>
                                <button
                                    className="btn btn-sm fw-bold btn-secondary me-1"
                                    style={{ float: "right" }}
                                    onClick={() => resetStates()}
                                >
                                    Add New
                                </button>
                            </div>
                            <div className="card-body p-0">
                                <div className="row mt-1 mb-2 ps-1">
                                    <div
                                        className={`col-lg-${groupName.toLocaleLowerCase() === "supplier" ? "3" : "4"
                                            } col-md-6 col-sm-12 hstack`}
                                        style={{
                                            height: "40px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">
                                                Group Name<span className="text-danger"> *</span>
                                            </label>
                                        </div>
                                        <div
                                            className="col-7"
                                            onClick={() => setFocusedField("group")}
                                        >
                                            <SearchableSelect
                                                id="groupName"
                                                ref={groupRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        categoryRef,
                                                        "category",
                                                        undefined,
                                                        undefined
                                                    )
                                                }
                                                label=""
                                                labelClassName="required"
                                                required={true}
                                                value={groupName}
                                                onChange={handleGroup}
                                                errorText={groupNameError}
                                                className={
                                                    focusedField === "group"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                options={DropDownList.filter(
                                                    (ddl) => ddl.category === "groupmaster"
                                                ).map((ddl) => ({
                                                    label: ddl.title,
                                                    value: ddl.title,
                                                }))}
                                            />
                                        </div>
                                    </div>

                                    {groupName.toLocaleLowerCase() === "supplier" && (
                                        <div
                                            className="col-lg-3 col-md-6 col-sm-12 hstack"
                                            style={{
                                                height: "40px",
                                                lineHeight: "normal",
                                                padding: "0px 12px",
                                            }}
                                        >
                                            <div className="col-4">
                                                <label className="required" htmlFor="supplierPlace">
                                                    Supplier Place
                                                </label>
                                            </div>
                                            <div
                                                className="col-7"
                                                onClick={() => setFocusedField("supplier")}
                                            >
                                                <CreatableDropdown
                                                    id="supplierPlace"
                                                    ref={supplierRef}
                                                    onKeyDown={(e) =>
                                                        handleKeyDown(
                                                            e,
                                                            categoryRef,
                                                            "category",
                                                            groupRef,
                                                            "group"
                                                        )
                                                    }
                                                    label=""
                                                    value={supplierPlace}
                                                    labelClassName="required"
                                                    required={true}
                                                    onChange={handleSupplierPlace}
                                                    onCreateOption={onSupplierPlace}
                                                    className={
                                                        focusedField === "supplier"
                                                            ? "border border-2 border-primary rounded"
                                                            : ""
                                                    }
                                                    options={DropDownList.filter(
                                                        (ddl) =>
                                                            ddl.category.toLocaleLowerCase() ===
                                                            "supplier place"
                                                    ).map((ddl) => ({
                                                        label: ddl.title,
                                                        value: ddl.title,
                                                    }))}
                                                    errorText={supplierPlaceError}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div
                                        className={`col-lg-${groupName.toLocaleLowerCase() === "supplier" ? "3" : "4"
                                            } col-md-6 col-sm-12 hstack`}
                                        style={{
                                            height: "60px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">Address 1</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                name="address1"
                                                onClick={() => setFocusedField("address1")}
                                                label=""
                                                labelClassName="required"
                                                height="35px"
                                                value={address1}
                                                onChange={handleAddress1}
                                                errorText={address1Error}
                                                className={
                                                    focusedField === "address1"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                ref={address1Ref}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        address2Ref,
                                                        "address2",
                                                        routeRef,
                                                        "route"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className={`col-lg-${groupName.toLocaleLowerCase() === "supplier" ? "3" : "4"
                                            } col-md-6 col-sm-12 hstack`}
                                        style={{
                                            height: "40px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">Country</label>
                                        </div>
                                        <div
                                            className="col-7"
                                            onClick={() => setFocusedField("country")}
                                        >
                                            <CreatableDropdown
                                                id="country"
                                                label=""
                                                value={country}
                                                onChange={handleCountry}
                                                required={true}
                                                onCreateOption={onCountry}
                                                options={DropDownList.filter(
                                                    (ddl) => ddl.category === "country"
                                                ).map((ddl) => ({
                                                    label: ddl.title,
                                                    value: ddl.title,
                                                }))}
                                                className={`${focusedField === "country"
                                                    ? "border border-2 border-primary rounded"
                                                    : ""
                                                    }`}
                                                errorText={countryError}
                                                ref={countryRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        contactRef,
                                                        "contact1",
                                                        stateRef,
                                                        "state"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-1 ps-1">
                                    <div
                                        className="col-sm-6 col-md-6 col-lg-4 hstack "
                                        style={{
                                            height: "10px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">
                                                Account Category
                                                <span className="text-danger"> *</span>
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                name="accountCategory"
                                                onClick={() => setFocusedField("category")}
                                                label=""
                                                labelClassName="required"
                                                height="35px"
                                                value={accountCategory || "NON-ACCOUNT"}
                                                containerClass="read-only-field"
                                                onChange={handleAccountCategory}
                                                ref={categoryRef}
                                                readOnly={true}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        accountNameRef,
                                                        "accountName",
                                                        groupRef,
                                                        "group"
                                                    )
                                                }
                                                className={
                                                    focusedField === "category"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                errorText={accountCategoryError}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-6 col-md-4 col-lg-4 hstack "
                                        style={{
                                            height: "20px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">Address 2</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                name="address2"
                                                onClick={() => setFocusedField("address2")}
                                                label=""
                                                labelClassName="required"
                                                height="35px"
                                                value={address2}
                                                onChange={handleAddress2}
                                                errorText={address2Error}
                                                className={
                                                    focusedField === "address2"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                ref={address2Ref}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        placeRef,
                                                        "place",
                                                        address1Ref,
                                                        "address1"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="col-sm-6 col-md-6 col-lg-4 hstack "
                                        style={{
                                            height: "10px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">
                                                Contact No. 1<span className="text-danger"> *</span>
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                name="contactNumber1"
                                                type="number"
                                                onClick={() => setFocusedField("contact1")}
                                                ref={contactRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        contactNumber2Ref,
                                                        "contact2",
                                                        countryRef,
                                                        "country"
                                                    )
                                                }
                                                label=""
                                                labelClassName="required"
                                                required={true}
                                                height="35px"
                                                value={contactNumber1}
                                                className={
                                                    focusedField === "contact1"
                                                        ? "border border-2 border-primary"
                                                        : ""
                                                }
                                                onChange={handleContactNumber1}
                                                errorText={contactNumber1Error}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1 ps-1">
                                    <div
                                        className="col-sm-6 col-md-8 col-lg-4 hstack"
                                        style={{
                                            height: "50px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">
                                                Account Name<span className="text-danger"> *</span>
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                name="accountName"
                                                onClick={() => setFocusedField("accountName")}
                                                ref={accountNameRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        tagRef,
                                                        "tag",
                                                        categoryRef,
                                                        "category"
                                                    )
                                                }
                                                className={
                                                    focusedField === "accountName"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                label=""
                                                labelClassName="required"
                                                height="35px"
                                                required={true}
                                                value={accountName}
                                                onChange={handleAccountName}
                                                errorText={accountNameError}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="col-sm-6 col-md-6 col-lg-4 hstack "
                                        style={{
                                            height: "40px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">Place</label>
                                        </div>
                                        <div
                                            className="col-7"
                                            onClick={() => setFocusedField("place")}
                                        >
                                            <CreatableDropdown
                                                id="place"
                                                label=""
                                                value={place}
                                                onChange={handlePlace}
                                                required={true}
                                                onCreateOption={onPlace}
                                                options={DropDownList.filter(
                                                    (ddl) => ddl.category === "place"
                                                ).map((ddl) => ({
                                                    label: ddl.title,
                                                    value: ddl.title,
                                                }))}
                                                className={`${focusedField === "place"
                                                    ? "border border-2 border-primary rounded"
                                                    : ""
                                                    }`}
                                                errorText={placeError}
                                                ref={placeRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        areaRef,
                                                        "area",
                                                        address2Ref,
                                                        "address2"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-6 col-md-6 col-lg-4 hstack "
                                        style={{
                                            height: "50px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">Contact No. 2</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                name="contactNumber2"
                                                type="number"
                                                label=""
                                                labelClassName="required"
                                                height="35px"
                                                value={contactNumber2}
                                                onChange={handleContactNumber2}
                                                errorText={contactNumber2Error}
                                                className={
                                                    focusedField === "contact2"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                ref={contactNumber2Ref}
                                                onClick={() => setFocusedField("contact2")}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        contactPersonRef,
                                                        "contactPerson",
                                                        contactRef,
                                                        "contact1"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1 ps-1">
                                    <div
                                        className="col-sm-6 col-md-6 col-lg-4 hstack "
                                        style={{
                                            height: "10px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">
                                                Name Tag<span className="text-danger"> *</span>
                                            </label>
                                        </div>
                                        <div
                                            className="col-7"
                                            onClick={() => setFocusedField("tag")}
                                        >
                                            <SearchableSelect
                                                id="nameTag"
                                                ref={tagRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        gstRef,
                                                        "gstNumber",
                                                        accountNameRef,
                                                        "accountName"
                                                    )
                                                }
                                                label=""
                                                labelClassName="required"
                                                required={true}
                                                value={nameTag}
                                                onChange={handleTag}
                                                errorText={nameTagError}
                                                className={
                                                    focusedField === "tag"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                options={[
                                                    "Mr.",
                                                    "Mrs.",
                                                    "Miss.",
                                                    "Master.",
                                                    "Baby.",
                                                    "Dr.",
                                                    "Er.",
                                                    "N/A",
                                                ].map((tag) => ({
                                                    label: tag,
                                                    value: tag,
                                                }))}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="col-sm-6 col-md-6 col-lg-4 hstack "
                                        style={{
                                            height: "20px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">Division</label>
                                        </div>
                                        <div
                                            className="col-7"
                                            onClick={() => setFocusedField("area")}
                                        >
                                            <CreatableDropdown
                                                id="area"
                                                label=""
                                                value={area}
                                                onChange={handleArea}
                                                required={true}
                                                onCreateOption={onArea}
                                                options={DropDownList.filter(
                                                    (ddl) => ddl.category === "area"
                                                ).map((ddl) => ({
                                                    label: ddl.title,
                                                    value: ddl.title,
                                                }))}
                                                errorText={areaError}
                                                className={`${focusedField === "area"
                                                    ? "border border-2 border-primary rounded"
                                                    : ""
                                                    }`}
                                                ref={areaRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        districtRef,
                                                        "district",
                                                        placeRef,
                                                        "place"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-6 col-md-4 col-lg-4 hstack "
                                        style={{
                                            height: "30px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">Contact Person</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                name="contactPerson"
                                                onClick={() => setFocusedField("contactPerson")}
                                                label=""
                                                labelClassName="required"
                                                height="35px"
                                                value={contactPerson}
                                                onChange={handleContactPerson}
                                                errorText={contactPersonError}
                                                className={
                                                    focusedField === "contactPerson"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                ref={contactPersonRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        contactPersonNumberRef,
                                                        "contactPersonNumber",
                                                        contactNumber2Ref,
                                                        "contact2"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>

                                    {/* add another input */}
                                </div>
                                <div className="row mt-1 ps-1">
                                    <div
                                        className="col-sm-6 col-md-4 col-lg-4 hstack "
                                        style={{
                                            height: "50px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">GST Number</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                name="gstNumber"
                                                onClick={() => setFocusedField("gstNumber")}
                                                label=""
                                                labelClassName="required"
                                                height="35px"
                                                value={gstNumber}
                                                onChange={handleGstNumber}
                                                errorText={gstNumberError}
                                                ref={gstRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        aadharRef,
                                                        "aadharNumber",
                                                        tagRef,
                                                        "tag"
                                                    )
                                                }
                                                className={
                                                    focusedField === "gstNumber"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-6 col-md-4 col-lg-4 hstack "
                                        style={{
                                            height: "40px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">District</label>
                                        </div>
                                        <div
                                            className="col-7"
                                            onClick={() => setFocusedField("district")}
                                        >
                                            <CreatableDropdown
                                                id="district"
                                                label=""
                                                value={district}
                                                onChange={handleDistrict}
                                                required={true}
                                                onCreateOption={onDistrict}
                                                options={DropDownList.filter(
                                                    (ddl) => ddl.category === "district"
                                                ).map((ddl) => ({
                                                    label: ddl.title,
                                                    value: ddl.title,
                                                }))}
                                                className={`${focusedField === "district"
                                                    ? "border border-2 border-primary rounded"
                                                    : ""
                                                    }`}
                                                errorText={districtError}
                                                ref={districtRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        pincodeRef,
                                                        "pincode",
                                                        areaRef,
                                                        "area"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-6 col-md-4 col-lg-4 hstack "
                                        style={{
                                            height: "50px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">Contact Person No.</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                type="number"
                                                name="contactPersonNumber"
                                                onClick={() => setFocusedField("contactPersonNumber")}
                                                label=""
                                                labelClassName="required"
                                                height="35px"
                                                value={contactPersonNumber}
                                                onChange={handleContactPersonNumber}
                                                errorText={contactPersonNumberError}
                                                className={
                                                    focusedField === "contactPersonNumber"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                ref={contactPersonNumberRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        oldBalanceRef,
                                                        "oldBalance",
                                                        contactPersonRef,
                                                        "contactPerson"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1 ps-1">
                                    <div
                                        className="col-sm-6 col-md-4 col-lg-4 hstack "
                                        style={{
                                            height: "30px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">Aadhar Number</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                name="aadharNumber"
                                                type="number"
                                                onClick={() => setFocusedField("aadharNumber")}
                                                label=""
                                                labelClassName="required"
                                                height="35px"
                                                value={aadharNumber}
                                                onChange={handleAadharNumber}
                                                errorText={aadharNumberError}
                                                className={
                                                    focusedField === "aadharNumber"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                ref={aadharRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        routeRef,
                                                        "route",
                                                        gstRef,
                                                        "gstNumber"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-6 col-md-6 col-lg-4 hstack "
                                        style={{
                                            height: "40px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">Pincode</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                name="pincode"
                                                onClick={() => setFocusedField("pincode")}
                                                label=""
                                                type="number"
                                                labelClassName="required"
                                                height="35px"
                                                value={pincode}
                                                onChange={handlePincode}
                                                errorText={pincodeError}
                                                className={
                                                    focusedField === "pincode"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                ref={pincodeRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        stateRef,
                                                        "state",
                                                        districtRef,
                                                        "district"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="col-sm-6 col-md-2 col-lg-4 hstack "
                                        style={{
                                            height: "30px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">Old Balance</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                name="oldBalance"
                                                type="number"
                                                onClick={() => setFocusedField("oldBalance")}
                                                label=""
                                                labelClassName="required"
                                                height="35px"
                                                value={oldBalance}
                                                onChange={handleOldBalance}
                                                errorText={oldBalanceError}
                                                className={
                                                    focusedField === "oldBalance"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                ref={oldBalanceRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        asOnDateRef,
                                                        "asOnDate",
                                                        contactPersonNumberRef,
                                                        "contactPersonNumber"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1 ps-1">
                                    <div
                                        className="col-sm-6 col-md-4 col-lg-4 hstack "
                                        style={{
                                            height: "15px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label className="required" htmlFor="route">
                                                Route
                                            </label>
                                        </div>

                                        <div
                                            className="col-7"
                                            onClick={() => setFocusedField("route")}
                                        >
                                            <CreatableDropdown
                                                id="route"
                                                label=""
                                                value={route}
                                                onChange={handleRoute}
                                                required={true}
                                                onCreateOption={onRoute}
                                                options={DropDownList.filter(
                                                    (ddl) => ddl.category === "route"
                                                ).map((ddl) => ({
                                                    label: ddl.title,
                                                    value: ddl.title,
                                                }))}
                                                className={`${focusedField === "route"
                                                    ? "border border-2 border-primary rounded"
                                                    : ""
                                                    }`}
                                                errorText={routeError}
                                                ref={routeRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        address1Ref,
                                                        "address1",
                                                        aadharRef,
                                                        "aadharNumber"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-sm-6 col-md-4 col-lg-4 hstack "
                                        style={{
                                            height: "20px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">State</label>
                                        </div>
                                        <div
                                            className="col-7"
                                            onClick={() => setFocusedField("state")}
                                        >
                                            <CreatableDropdown
                                                id="state"
                                                ref={stateRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        countryRef,
                                                        "country",
                                                        pincodeRef,
                                                        "pincode"
                                                    )
                                                }
                                                label=""
                                                // height="-24px"
                                                value={state}
                                                required={true}
                                                onChange={handleState}
                                                onCreateOption={onState}
                                                options={DropDownList.filter(
                                                    (ddl) => ddl.category === "state"
                                                ).map((ddl) => ({
                                                    label: ddl.title,
                                                    value: ddl.title,
                                                }))}
                                                className={`${focusedField === "state"
                                                    ? "border border-2 border-primary rounded"
                                                    : ""
                                                    }`}
                                                errorText={stateError}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="col-sm-6 col-md-2 col-lg-4 hstack "
                                        style={{
                                            height: "30px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="">Till Date</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput2
                                                name="asOnDate"
                                                onClick={() => setFocusedField("asOnDate")}
                                                label=""
                                                labelClassName="required"
                                                height="35px"
                                                value={asOnDate}
                                                onChange={handleAsOnDate}
                                                errorText={asOnDateError}
                                                type="date"
                                                disabled={oldBalance ? false : true}
                                                ref={asOnDateRef}
                                                className={
                                                    focusedField === "asOnDate"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2 ps-1">
                                    <div
                                        className="col-sm-6 col-md-2 col-lg-4 hstack"
                                        style={{
                                            height: "25px",
                                            lineHeight: "normal",
                                            padding: "0px 12px",
                                        }}
                                    >
                                        <div className="col-1">
                                            <FormInput2
                                                name="allowSms"
                                                label=""
                                                height="24px"
                                                labelClassName="required"
                                                checked={allowSms}
                                                onChange={handleSmsChange}
                                                type="checkbox"
                                                ref={allowSmsRef}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="">Allow SMS notifications</label>
                                        </div>
                                        {/* <label className="" htmlFor="allowSms">
                                        <input
                                            id="allowSms"
                                            type="checkbox"
                                            checked={allowSms} // This binds the checkbox to the state
                                            onChange={handleSmsChange} // This triggers the state change
                                        />
                                        Allow SMS notifications
                                    </label> */}
                                    </div>
                                    <div className="col-4"></div>
                                </div>

                                <label htmlFor="" className="fs-5 mt-2">
                                    Bank Details :
                                </label>
                                <div className="row">
                                    <div className="col-1"></div>
                                    <div className="col-sm-6 col-md-6 col-lg-5 hstack mt-1">
                                        <div className="col-4">
                                            <label htmlFor="">Bank Account No</label>
                                        </div>
                                        <div className="col-8">
                                            <FormInput2
                                                name="bankAccountNumber"
                                                label=""
                                                labelClassName="required"
                                                height="24px"
                                                value={bankAccountNumber}
                                                onChange={handleBankAccountNumber}
                                                errorText={bankAccountNumberError}
                                                type="number"
                                                className={
                                                    focusedField === "bankAccountNumber"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                onClick={() => setFocusedField("bankAccountNumber")}
                                                ref={bankAccountNumberRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        branchNameRef,
                                                        "bankBranchName",
                                                        undefined,
                                                        undefined
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-6 col-lg-5 hstack mt-1">
                                        <div className="col-3">
                                            <label htmlFor="">Bank Name</label>
                                        </div>
                                        <div className="col-9">
                                            <FormInput2
                                                name="bankName"
                                                label=""
                                                labelClassName="required"
                                                height="24px"
                                                value={bankName}
                                                onChange={handleBankName}
                                                errorText={bankNameError}
                                                className={
                                                    focusedField === "bankName"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                onClick={() => setFocusedField("bankName")}
                                                ref={bankNameRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        ifscCodeRef,
                                                        "ifscCode",
                                                        branchNameRef,
                                                        "bankBranchName"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-1"></div>
                                    <div className="col-sm-4 col-md-6 col-lg-5 hstack mt-1">
                                        <div className="col-4">
                                            <label htmlFor="">Branch Name</label>
                                        </div>
                                        <div className="col-8">
                                            <FormInput2
                                                name="bankBranchName"
                                                label=""
                                                labelClassName="required"
                                                height="24px"
                                                value={bankBranchName}
                                                onChange={handleBranchBankName}
                                                errorText={bankBranchNameError}
                                                className={
                                                    focusedField === "bankBranchName"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                onClick={() => setFocusedField("bankBranchName")}
                                                ref={branchNameRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        bankNameRef,
                                                        "bankName",
                                                        bankAccountNumberRef,
                                                        "bankAccountNumber"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-6 col-lg-5 hstack mt-1 ">
                                        <div className="col-3">
                                            <label htmlFor="">IFSC Code</label>
                                        </div>
                                        <div className="col-9">
                                            <FormInput2
                                                name="ifscCode"
                                                label=""
                                                labelClassName="required"
                                                height="24px"
                                                value={ifscCode}
                                                onChange={handleIfscCode}
                                                errorText={ifscCodeError}
                                                className={
                                                    focusedField === "ifscCode"
                                                        ? "border border-2 border-primary rounded"
                                                        : ""
                                                }
                                                onClick={() => setFocusedField("ifscCode")}
                                                ref={ifscCodeRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDown(
                                                        e,
                                                        undefined,
                                                        undefined,
                                                        bankNameRef,
                                                        "bankName"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                </div>

                                {(formType === FormTypeList.ADD ||
                                    formType === FormTypeList.EDIT ||
                                    formType === FormTypeList.DELETE) && (
                                        <div className="d-flex justify-content-end hstack gap-1 mt-2">
                                            {/* <button className="btn btn-sm fw-bold btn-secondary" onClick={() => resetStates()}>Clear</button> */}
                                            {(editData
                                                ? rolePermissions?.edit
                                                : rolePermissions?.add) && (
                                                    <button
                                                        className="btn btn-sm fw-bold btn-success"
                                                        onClick={handleSave}
                                                    >
                                                        {editData ? "Update" : "Save"}
                                                    </button>
                                                )}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>

                    {rolePermissions?.view && showTable ? (
                        <div className="col-lg-5">
                            <div className="card shadow-lg p-1" style={{ height: "559px" }}>
                                {/* <div className="card-body"> */}
                                <div className="d-flex justify-content-end p-2 ">
                                    <button
                                        className="btn btn-primary w-40 "
                                        onClick={() => downloadPDF(groupName)}
                                    >
                                        <i className="fe-download me-1"></i>
                                        Download PDF
                                    </button>
                                </div>

                                <div className="mb-2">
                                    <FormInput2
                                        onChange={searchTermChangeHandler}
                                        name=""
                                        placeholder="Search..."
                                        height="24px"
                                        value={searchTerm}
                                        onClick={() => {
                                            setFocusedField("search");

                                            mainContentRef?.current?.scrollTo({
                                                behavior: "smooth",
                                                top: 270,
                                            });
                                        }}
                                        onBlur={() => setFocusedField(null)}
                                        ref={searchRef}
                                        className={
                                            focusedField === "search"
                                                ? "border border-2 border-primary rounded"
                                                : ""
                                        }
                                    />
                                </div>
                                <div className="table-wrapper">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="text-truncate align-middle">S.No</th>
                                                <th className="text-truncate align-middle">
                                                    Group Name
                                                </th>
                                                <th className="text-truncate align-middle">
                                                    Account Name
                                                </th>
                                                <th className="text-truncate align-middle">Place</th>
                                                <th className="text-truncate align-middle">
                                                    Contact Number
                                                </th>
                                                {(rolePermissions?.edit ||
                                                    rolePermissions?.delete) && (
                                                        <th className="text-truncate align-middle">
                                                            Actions
                                                        </th>
                                                    )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {AccountList?.filter((account) =>
                                                filterAccountHandler(account, searchTerm)
                                            )?.map((al, i) => {
                                                return (
                                                    <AccountBody
                                                        sno={i + 1}
                                                        groupName={al.groupName}
                                                        accountName={al.accountName}
                                                        place={al.place}
                                                        contactNumber1={al.contactNumber1}
                                                        onEdit={() => handleEdit(al)}
                                                        onDelete={() => handleDelete(al)}
                                                        rolePermissions={rolePermissions}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </UserLayout>
        </>
    );
}

const AccountBody = ({ sno, groupName, accountName, place, contactNumber1, onEdit, onDelete, rolePermissions }: TableBody) => {

    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{groupName}</td>
        <td className='text-capitalize align-middle'>{accountName}</td>
        <td className='align-middle'>{place}</td>
        <td className='text-capitalize align-middle'>{contactNumber1}</td>
        {(rolePermissions?.edit || rolePermissions?.delete) && <td className="align-middle">
            <div className='hstack gap-1'>
                {rolePermissions?.edit && <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>}
                {rolePermissions?.delete && <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>}
            </div>
        </td>}
    </tr>
}
export default AccountHolder