import React, { forwardRef } from 'react';
import Select from 'react-select';
import { StylesConfig } from 'react-select';

interface SearchableSelectProps {
    id: string;
    label: string;
    labelClassName?: string;
    height?: string;
    required?: boolean;
    value: string;
    options: { value: string; label: string }[];
    onChange: (selectedOption: { value: string; label: string } | null) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;  // Added onKeyDown prop
    errorText?: string;
    className?: string;
    customStyles?: StylesConfig<any, false>;
}

const SearchableSelect = forwardRef<HTMLDivElement, SearchableSelectProps>(
    (
        {
            id,
            label,
            labelClassName = '',
            required = false,
            height,
            value,
            options,
            onChange,
            onKeyDown,  // Destructured onKeyDown
            errorText,
            className,
            customStyles = {},
        },
        ref
    ) => {
        const defaultStyles: StylesConfig<any, false> = {
            control: (base) => ({
                ...base,
                border: '1px solid rgba(2, 2, 2, 0.12)', 
                borderRadius: '4px',
                boxShadow: 'none',
                height: height,
            }),
        };
        return (
            <div className="form-group" ref={ref} style={{ lineHeight: height }}>
                <label htmlFor={id} className={labelClassName}>
                    {label} {!required && <span className="text-danger">*</span>}
                </label>
                <Select
                    id={id}
                    value={options.find((option) => option.value === value) || null}
                    onChange={onChange}
                    onKeyDown={onKeyDown}  // Added onKeyDown to Select
                    options={options}
                    isSearchable
                    tabIndex={0}
                    className={className}
                    classNamePrefix="select"
                    styles={{ ...defaultStyles, ...customStyles }}
                />
                {errorText && <small className="text-danger mb-5">{errorText}</small>}
            </div>
        );
    }
);


export default SearchableSelect;
