import { combineReducers } from "redux";
import { accountReducer } from "./accountHolder";
import authUserReducer from "./authUserReducer";
import { branchReducer } from "./branchReducer";
import { categoryReducer } from "./categoryReducer";
import { groupReducer } from "./groupMaster";
import { productReducer } from "./productReducer";
import purchaseReducer from "./purchaseReducer";
import { roleReducer } from "./rolesReducer";
import supplierReducer from "./supplierReducer";
/*import { transactionReducer } from "./transaction";*/
import { brandReducer } from "./brandReducer";
import resetPasswordReducer from "./changePasswordReducer";
import { clientRegistrationReducer } from "./clientRegistration";
import { customerReducer } from "./customerReducer";
import { departmentReducer } from "./departmentReducer";
import { dropdownReducer } from "./dropdown";
import { purchaseCategoryReducer } from "./purchaseCategoryReducer";
import { purchaseItemReducer } from "./purchaseItemReducer";
import { purchaseReturnItemReducer } from "./purchaseReturnItemReducer";
import purchaseReturnReducer from "./purchaseReturnReducer";
import { salesCategoryReducer } from "./salesCategoryReducer";
import { salesItemReducer } from "./salesItemReducer";
import salesReducer from "./salesReducer";
import { salesReturnItemReducer } from "./salesReturnItemReducer";
import salesReturnReducer from "./salesReturnReducer";
import { shopReducer } from "./shopReducer";
import { stockMasterReducer } from "./stockMasterReducer";
import { transactionBalanceReducer } from "./transactionBalance";
import { transactionReducer } from "./transactionReducer";
import { usersReducer } from "./usersReducer";


const reducers = combineReducers({
    account: accountReducer,
    authUser: authUserReducer,
    branch: branchReducer,
    category: categoryReducer,
    group: groupReducer,
    product: productReducer,
    purchase: purchaseReducer,
    purchaseItem: purchaseItemReducer,
    sales: salesReducer,
    salesItem: salesItemReducer,
    role: roleReducer,
    supplier: supplierReducer,
    transaction: transactionReducer,
    user: usersReducer,
    dropdown: dropdownReducer,
    transactionBalance: transactionBalanceReducer,
    stockMaster: stockMasterReducer,
    customer: customerReducer,
    department: departmentReducer,
    brand: brandReducer,
    purchaseReturn: purchaseReturnReducer,
    purchaseReturnItem: purchaseReturnItemReducer,
    salesReturn: salesReturnReducer,
    salesReturnItem: salesReturnItemReducer,
    purchaseCategory: purchaseCategoryReducer,
    salesCategory: salesCategoryReducer,
    ResetPassword: resetPasswordReducer,
    clientRegistration: clientRegistrationReducer,
    shop: shopReducer
})

export default reducers