import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import api from "../../config/api";
import { getDate, LocalFormatDate, rupeeFormat } from "../../config/functions";
import {
    ClientRegistrationType,
    fetchClientRegistrationAction,
} from "../../redux/actions/clientRegistration";
import { AppDispatch, StoreState } from "../../redux/store";
import SalesReportFilter from "./SalesReportFilter";

interface InvoiceData {
    financialYear: string;
    date: string;
    day: string;
    invoiceNo: string;
    customerName: string;
    quantity: number;
    // billValue: number;
    // supplierGSTIN: string;
    taxable: number;
    igst: number;
    cgst: number;
    sgst: number;
    total: number;
    paid: number;
    balance: number;
    splitMode: string;
    // addAmount: number;
    // lessAmount: number;
    // netTotal: number;
}

const SalesInvoiceWise = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [branchId, setBranchId] = useState<string>("");
    const [invoiceTitle, setInvoiceTitle] = useState<string>("All");
    const [invoiceType, setInvoiceType] = useState<string>("Retail");
    const [tableData, setTableData] = useState<InvoiceData[]>([]);
    const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

    const clients = useSelector<StoreState, ClientRegistrationType[]>(
        (state) => state.clientRegistration
    );

    useEffect(() => {
        dispatch(fetchClientRegistrationAction());
    }, []);

    useEffect(() => {
        if (triggerSearch) {
            api()
                .get(
                    `reports/sales/?fromDate=${getDate(fromDate)}&toDate=${getDate(
                        toDate
                    )}&branchId=${branchId}&invoiceTitle=${invoiceTitle}&invoiceType=${invoiceType}`
                )
                .then((res) => {
                    if (res.status === 200 && res.data.data) {
                        setTableData(res.data.data);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching sales data:", error);
                });

            setTriggerSearch(false);
        }
    }, [triggerSearch, fromDate, toDate, branchId, invoiceTitle, invoiceType]);

    const [filters, setFilters] = useState<any>({});

    const handleSearch = (newFilters: any) => {
        setFilters(newFilters);
        setFromDate(newFilters.fromDate);
        setToDate(newFilters.toDate);
        setBranchId(newFilters.branchId);
        setInvoiceTitle(newFilters.invoiceTitle);
        setInvoiceType(newFilters.invoiceType);
        setTriggerSearch(true);
    };

    const downloadCSV = () => {
        const header = [
            "SI.No",
            "Financial Year",
            "Date",
            "Day",
            "Invoice Number",
            "Name",
            "Qty",
            "Bill Value",
            "Taxable",
            "IGST",
            "CGST",
            "SGST",
            "Total",
            "Paid",
            "Balance",
            "Split Mode",
        ].map((col) => `="${col}"`);

        const grandTotal = tableData.reduce(
            (acc, item) => {
                // acc.billValue += item.billValue || 0;
                acc.taxable += item.taxable || 0;
                acc.igst += item.igst || 0;
                acc.cgst += item.cgst || 0;
                acc.sgst += item.sgst || 0;
                acc.total += item.total || 0;
                acc.paid += item.paid || 0;
                acc.balance += item.balance || 0;
                // acc.addAmount += item.addAmount || 0;
                // acc.lessAmount += item.lessAmount || 0;
                // acc.netTotal += item.netTotal || 0;
                return acc;
            },
            {
                billValue: 0,
                taxable: 0,
                igst: 0,
                cgst: 0,
                sgst: 0,
                total: 0,
                paid: 0,
                balance: 0,
                // addAmount: 0,
                // lessAmount: 0,
                // netTotal: 0
            }
        );

        const rows = tableData?.map((item, index) => [
            index + 1,
            item.financialYear,
            LocalFormatDate(item.date),
            item.day,
            item.invoiceNo,
            item.customerName,
            item.quantity,
            // item.billValue,
            item.total,
            item.taxable,
            item.igst,
            item.cgst,
            item.sgst,
            item.total,
            item.paid,
            item.balance,
            item.splitMode,
            // item.addAmount,
            // item.lessAmount,
            // item.netTotal,
        ]);

        const grandTotalRow = [
            "",
            "",
            "",
            "",
            "",
            "",
            "Grand Total",
            grandTotal?.total?.toFixed(2),
            grandTotal?.taxable?.toFixed(2),
            grandTotal?.igst?.toFixed(2),
            grandTotal?.cgst?.toFixed(2),
            grandTotal?.sgst?.toFixed(2),
            grandTotal?.total?.toFixed(2),
            grandTotal?.paid?.toFixed(2),
            grandTotal?.balance?.toFixed(2),
            // grandTotal.addAmount, grandTotal.lessAmount, grandTotal.netTotal
        ];
        rows.push(grandTotalRow);

        const csvContent = [
            header.join(","),
            ...rows.map((row) => row.join(",")),
        ].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "sales _invoice_details.csv";
        link.click();
    };

    const downloadPDF = () => {
        const logoUrl = clients[0]?.shopPhoto;

        const doc = new jsPDF("landscape");
        const marginX = 10;
        const boxWidth = doc.internal.pageSize.width - 2 * marginX;
        const boxHeight = 25;
        let startY = 15;

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");

        doc.rect(marginX, startY - 5, boxWidth, boxHeight);

        if (logoUrl) {
            doc.addImage(logoUrl, "JPEG", marginX + 5, startY - 2, 25, 20);
        }

        const paddingBetweenHeaderAndTable = 3;
        const textX = marginX + 35;

        doc.text(clients[0]?.shopShortName || "", textX, startY);
        doc.setFont("helvetica", "normal");
        doc.text(
            `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${clients[0]?.district || ""
            }`,
            textX,
            startY + 5
        );
        doc.text(
            `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${clients[0]?.contactNo1 || ""
            }`,
            textX,
            startY + 10
        );
        doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 15);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        const heading = "Sales invoice wise report";
        const headingWidth = doc.getTextWidth(heading);
        const pageWidth = doc.internal.pageSize.width;
        doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 8);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const financialYearText = `Financial Year: ${tableData[0]?.financialYear || ""
            }`;
        const headingStartX = marginX + boxWidth - headingWidth - 10;
        doc.text(financialYearText, headingStartX, startY + 13);

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        const fromDate =
            "From : " + (LocalFormatDate(getDate(filters?.fromDate)) || "");
        const toDate = "To : " + (LocalFormatDate(getDate(filters?.toDate)) || "");

        const tableStartY = startY + boxHeight + paddingBetweenHeaderAndTable;
        doc.text(fromDate, marginX, tableStartY);
        doc.text(toDate, marginX + 50, tableStartY);

        let tableBody = tableData?.map((item, index) => [
            (index + 1).toString(),
            LocalFormatDate(item.date) || "",
            item.day || "",
            item.invoiceNo || "",
            item.customerName || "",
            item.quantity || 0,
            rupeeFormat(item.total) || "0",
            rupeeFormat(item.taxable) || "0",
            rupeeFormat(item.igst) || "0",
            rupeeFormat(item.cgst) || "0",
            rupeeFormat(item.sgst) || "0",
            rupeeFormat(item.total) || "0",
            rupeeFormat(item.paid) || "0",
            rupeeFormat(item.balance) || "0",
            item.splitMode || "",
            // rupeeFormat(item.addAmount) || "0",
            // rupeeFormat(item.lessAmount) || "0",
            // rupeeFormat(item.netTotal) || "0",
        ]);
        // console.log(tableBody);

        const grandTotal = tableData.reduce(
            (acc, item) => ({
                // billValue: acc.billValue + (item.billValue || 0),

                taxable: acc.taxable + (item.taxable || 0),
                igst: acc.igst + (item.igst || 0),
                cgst: acc.cgst + (item.cgst || 0),
                sgst: acc.sgst + (item.sgst || 0),
                total: acc.total + (item.total || 0),
                paid: acc.paid + (item.paid || 0),
                balance: acc.balance + (item.balance || 0),

                // addAmount: acc.addAmount + (item.addAmount || 0),
                // lessAmount: acc.lessAmount + (item.lessAmount || 0),
                // netTotal: acc.netTotal + (item.netTotal || 0),
            }),
            { taxable: 0, igst: 0, cgst: 0, sgst: 0, total: 0, paid: 0, balance: 0 }
        );

        autoTable(doc, {
            startY: 47,
            head: [
                [
                    "SI.No",
                    "Date",
                    "Day",
                    "Invoice Number",
                    " Name",
                    "Qty",
                    "billValue",
                    "taxable",
                    "IGST",
                    "CGST",
                    "SGST",
                    "Total",
                    "Paid",
                    "Balance",
                    "Split Mode ",
                ],
            ],
            body: tableBody,
            foot: [
                [
                    {
                        content: "Grand Total",
                        colSpan: 6,
                        styles: { fontStyle: "bold", halign: "right" },
                    },
                    {
                        content: rupeeFormat(grandTotal?.total?.toFixed(2)),
                        styles: { fontStyle: "bold" },
                    },
                    {
                        content: rupeeFormat(grandTotal?.taxable?.toFixed(2)),
                        styles: { fontStyle: "bold" },
                    },
                    {
                        content: rupeeFormat(grandTotal?.igst?.toFixed(2)),
                        styles: { fontStyle: "bold" },
                    },
                    {
                        content: rupeeFormat(grandTotal?.cgst?.toFixed(2)),
                        styles: { fontStyle: "bold" },
                    },
                    {
                        content: rupeeFormat(grandTotal?.sgst?.toFixed(2)),
                        styles: { fontStyle: "bold" },
                    },
                    {
                        content: rupeeFormat(grandTotal?.total?.toFixed(2)),
                        styles: { fontStyle: "bold" },
                    },
                    {
                        content: rupeeFormat(grandTotal?.paid?.toFixed(2)),
                        styles: { fontStyle: "bold" },
                    },
                    {
                        content: rupeeFormat(grandTotal?.balance?.toFixed(2)),
                        styles: { fontStyle: "bold" },
                    },

                    // {content: rupeeFormat(grandTotal.addAmount.toFixed(2)), styles: { fontStyle: "bold"}},
                    // {content: rupeeFormat(grandTotal.lessAmount.toFixed(2)), styles: { fontStyle: "bold" }},
                    // {content: rupeeFormat(grandTotal.netTotal.toFixed(2)), styles: { fontStyle: "bold" }},
                ],
            ],
            theme: "grid",
            styles: { fontSize: 8, textColor: 0, lineColor: 0, lineWidth: 0.1 },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: 0,
                fontStyle: "bold",
                lineColor: 0,
                lineWidth: 0.1,
            },
            footStyles: {
                fillColor: [255, 255, 255],
                halign: "right",
            },
            margin: { top: 25, left: 10, right: 10 },
            columnStyles: {
                0: { halign: "center" }, // SI.No (Center aligned)
                1: { halign: "center" }, // Date (Center aligned)
                2: { halign: "center" }, // Invoice Number (Center aligned)
                3: { halign: "left" }, // Supplier Name (Left aligned)
                4: { halign: "left" }, // Supplier GSTIN (Left aligned)
                5: { halign: "center" }, // Taxable (Right aligned)
                6: { halign: "right" }, // IGST (Right aligned)
                7: { halign: "right" }, // CGST (Right aligned)
                8: { halign: "right" }, // SGST (Right aligned)
                9: { halign: "right" }, // Total (Right aligned)
                10: { halign: "right" }, // Add Amount (Right aligned)
                11: { halign: "right" }, // Less Amount (Right aligned)
                12: { halign: "right" },
                13: { halign: "right" },

                // Net Total (Right aligned)
            },
        });

        // Footer Section (Page Number)
        const pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            let pageHeight = doc.internal.pageSize.height;
            let footerY = pageHeight - 15;

            doc.setFontSize(10);
            doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, footerY);
        }

        doc.save("sales_invoice_details.pdf");
    };
    // tableData.map((item, index) => {
    //     console.log(item.quantity);
    // });

    return (
        <>
            <SalesReportFilter onSearch={handleSearch} from="Sales Invoice Wise" />
            {tableData.length > 0 ? (
                <Card className="mt-2 p-2 shadow-lg border-0 rounded-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5 className="fw-bold">Sales invoice details</h5>
                        <div>
                            <Button
                                className="me-2 btn-sm"
                                variant="success"
                                onClick={downloadCSV}
                            >
                                Download CSV
                            </Button>
                            <Button
                                className="me-2 btn-sm"
                                variant="success"
                                onClick={downloadPDF}
                            >
                                Download PDF
                            </Button>
                        </div>
                    </div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>SI.No</th>
                                <th>Financial year</th>
                                <th>Date</th>
                                <th>Day</th>
                                <th>Invoice number</th>
                                <th>Name</th>
                                <th>Quantity </th>
                                <th>Bill value</th>
                                <th>Taxable</th>
                                <th>IGST</th>
                                <th>CGST</th>
                                <th>SGST</th>
                                <th>Total</th>
                                <th>Paid </th>
                                <th>Balance </th>
                                <th>Split mode</th>
                            </tr>
                        </thead>

                        <tbody>
                            {tableData?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.financialYear}</td>
                                    <td>{LocalFormatDate(item.date)}</td>
                                    <td>{item.day}</td>
                                    <td>{item.invoiceNo}</td>
                                    <td>{item.customerName}</td>
                                    <td>{item.quantity}</td>
                                    <td>{rupeeFormat(item.total)}</td>
                                    <td>{rupeeFormat(item.taxable)}</td>
                                    <td>{rupeeFormat(item.igst)}</td>
                                    <td>{rupeeFormat(item.cgst)}</td>
                                    <td>{rupeeFormat(item.sgst)}</td>
                                    <td>{rupeeFormat(item.total)}</td>
                                    <td>{rupeeFormat(item.paid)}</td>
                                    <td>{rupeeFormat(item.balance)}</td>
                                    <td>{item.splitMode}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card>
            ) : (
                <h4 className="text-center fw-bold text-secondary">
                    No such data found!
                </h4>
            )}
        </>
    );
};

export default SalesInvoiceWise;