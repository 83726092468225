import api from "../config/api"
import { PurchaseType } from "../redux/actions/purchaseActions"

export const addPurchase = async (data: PurchaseType) => {
    return await api().post('/purchase/', data)
}

export const editPurchase = async (data: PurchaseType, id: number | undefined) => {
    return await api().put(`/purchase/?id=${id}`, data)
}

export const addOpeningStock = async (data: PurchaseType) => {
    return await api().post('/openingStock/', data)
}

export const editOpeningStock = async (data: PurchaseType, id: number | undefined) => {
    return await api().put(`/openingStock/?id=${id}`, data)
}

export const getPurchase = async () => {
    return await api().get('/purchase/')
}

export const getPurchaseById = (id: number) => {
    return api().get(`/purchase/?id=${id}`)
}

export const getPurchaseItemsByPurchaseDate = (date: string) => {
    return api().get(`/purchaseItems/?date=${date}`)
}

export const deletePurchase = (id: number | undefined) => {
    return api().delete(`/purchase/?id=${id}`)

}

export const getPurchaseByDate = (startDate: string, endDate: string) => {
    return api().get(`/purchase/?startDate=${startDate}&endDate=${endDate}`)
}