import { BsTelephone } from "react-icons/bs";
import { useSelector } from 'react-redux';
import UserLayout from '../components/custom/layouts/UserLayout';
import { apiUrl } from '../config';
import { RootState } from '../redux/store';

const MainHome = () => {

    const clientsData = useSelector((state: RootState) => state.clientRegistration);
    const clients = clientsData.slice(0, 1).map((item) => item);



    return (
        <UserLayout>
            {clients.map((client) => (

                <>
                    <div className="row">
                        <div className="col-4"></div>
                        <div className="col-4">
                        </div>
                        <div className="col-4">
                            <p className='fs-5 fw-semibold me-5 d-inline-block text-end mb-0'>GST NO : {client.gstNo}</p>
                            <p className='fs-5 fw-semibold text-end text-primary mb-0 d-inline-block ms-5 me-4'><BsTelephone size={16} color='#0d6efd' /> +91 {client.contactNo1}</p>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-around my-0 mx-auto p-0">
                        <div className="col-8 m-0">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <h2 className='fw-semibold d-flex gap-3'>
                                        <div>
                                            <img src={`${apiUrl}/${client.shopLogo}`} alt="logo" style={{ height: '75px', width: '75px' }} />
                                        </div>
                                        <div>
                                            {client.shopFullName}
                                            <p className="fst-italic fs-4 py-2">{client.tagLine}</p>
                                        </div>
                                    </h2>



                                </div>
                                <div className="col-4 fs-5 fw-semibold m-0">
                                </div>
                            </div>
                        </div>
                        <div className="col-1 m-0"></div>
                        <div className="col-3">
                            <p className='me-4 mb-0 fs-5 text-end fw-semibold'>{client.address1},{client.address2},</p>
                            <p className='me-4 mb-0 fs-5 text-end fw-semibold'>{client.place}, {client.taluk}</p>
                            <p className='me-4 mb-0 fs-5 text-end fw-semibold'>{client.district}</p>
                        </div>
                    </div>
                    <div className="marquee-container overflow-hidden bg-light shadow-sm p-1">
                        <div className="marquee-content d-flex align-items-center">
                            <div className="marquee-item px-3 py-1 bg-primary text-white rounded mx-1">New Offers</div>
                            <div className="marquee-item px-3 py-1 bg-success text-white rounded mx-1">Exchange Offers</div>
                            <div className="marquee-item px-3 py-1 bg-info text-white rounded mx-1">50% off</div>
                            <div className="marquee-item px-3 py-1 bg-warning text-dark rounded mx-1">Exciting stuffs</div>
                            <div className="marquee-item px-3 py-1 bg-danger text-white rounded mx-1">Buy 1 Get 1</div>
                        </div>
                    </div>
                    <div className='row my-0 mx-auto'>
                        <div className="col-12">
                            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                                
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={`${apiUrl}/${client.bannerImage}`} className="w-100 h-6" alt="super-market" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ))}

        </UserLayout>


    )
}

export default MainHome