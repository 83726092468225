import { MouseEventHandler, useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from "react-redux";
import { FormInput } from "../components/custom/components";
import DeleteConfirmSales from "../components/DeleteConfirmSales";
import Modal from "../components/Modal";
import { useToast } from "../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../config";
import { LocalFormatDate, rupeeFormat } from "../config/functions";
import { CustomerType } from "../redux/actions/customerAction";
import { fetchSalesAction, SalesType } from "../redux/actions/salesActions";
import { AppDispatch, StoreState } from "../redux/store";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { ClientRegistrationType } from "../redux/actions/clientRegistration";
import { AuthUserType } from "../redux/actions/authUserActions";
import { Value } from "sass";
import { getSalesById } from "../services/sales.service ";
import { match } from "assert";
import { SalesEditAttributes } from "./SalesMaster";
import { ProductType } from "../redux/actions/productAction";




interface TableBody {
    sno: number;
    invoiceDate: string;
    invoiceNo: string;
    salesEntryNo: string;
    customer: string;
    finalAmount: string;
    onEdit: MouseEventHandler<HTMLButtonElement>;
    onDelete: MouseEventHandler<HTMLButtonElement>;
    onPrint: MouseEventHandler<HTMLButtonElement>;
}

type HandleEdit = (value: SalesType) => void;
type HandleDelete = (value: SalesType) => void;
type HandlePrint = (value: SalesType) => void;

interface Props {
    setFormType: React.Dispatch<React.SetStateAction<FormType>>;
    setId: React.Dispatch<React.SetStateAction<number | undefined>>;
    handleEdit?: () => void;
    onclose: () => void;
}

const Sales = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const salesList = useSelector<StoreState, SalesType[]>(
    (state) => state.sales
  );
  const CustomerList = useSelector<StoreState, CustomerType[]>(
    (state) => state.customer
  );

  const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false);
  const [searchDate, setSearchDate] = useState<Date | null>(new Date());
  const [searchCustomer, setSearchCustomer] = useState<string>("");
  const [searchInvoiceNo, setSearchInvoiceNo] = useState<string>("");
  const [editData, setEditData] = useState<SalesType>();

  const clients = useSelector<StoreState, ClientRegistrationType[]>(
    (state) => state.clientRegistration
  );

  const AuthUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state.authUser
  );
    const ProductList = useSelector<StoreState, ProductType[]>(state => state.product)
    
  useEffect(() => {
    dispatch(fetchSalesAction());
  }, []);

  const handleDateFilter = (date: Date | null) => setSearchDate(date);
  const handleCustomerFilter = (value: string) =>
    setSearchCustomer(value.toLowerCase());
  const handleInvoiceNoFilter = (value: string) =>
    setSearchInvoiceNo(value.toLowerCase());

  const handleEdit: HandleEdit = (value) => {
    console.log(value);

    props.setFormType(FormTypeList.EDIT);
    props.setId(value.id);
    setEditData(value);
    props.onclose();
  };

  const handleDelete: HandleDelete = (value) => {
    props.setFormType(FormTypeList.DELETE);
    setEditData(value);
    setShowDeleteForm(true);
  };

  const handlePrintPdf: HandlePrint = async (value) => {
    console.log("Value", value);

    if (!value || !value.invoiceNumber) {
      console.error("Invalid sales data for PDF generation");
      return;
    }
    //  props.setId(value.id);
    if (value?.id !== undefined) {
      const response = await getSalesById(parseInt(value.id.toString()));
      const data: SalesEditAttributes = response.data.data;
      console.log("DATA", data);

      const productMap=new Map(ProductList.map((product)=>[product.productCode?.toString(),product]))

      const printObj = {
        kotnumber: 1,
        invoiceType: data.invoice_type,
        cgst: data.net_cgst,
        sgst: data.net_sgst,
        billnumber: data.invoice_number,
        tablename: "table1",
        customer: CustomerList?.find(
          (c) => c.accountNumber?.toString() === data.customer_id?.toString()
        ),
        cashierid: AuthUser?.userId,
        systemid: data.system_no,
        date: LocalFormatDate(data.invoice_date),
        printername: "",
        time: new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Kolkata",
          hour12: true,
        }),
        // 'time': new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata', hour12: true }).replace(',', ''),
        productitmes: data.salesItems
          ?.filter((salesItem: any) => salesItem.productName !== null)
          ?.map((salesItem: any) => {
            console.log("salesItem", salesItem);

            const product=productMap.get(salesItem.product_group_code?.toString())

            return {
              // name: ProductList?.find(
              //   (p) =>
              //     p.productCode?.toString() ===
              //     salesItem.product_group_code?.toString()
              // )?.productName,
              name:product?.productName,
              pcode: salesItem.product_code,
              hsn: product?.sac,              
              qty: salesItem.quantity,
              rate: parseInt(salesItem?.price_gst!)?.toFixed(2),
              gst: product?.gst,
              //  mrp: parseInt(salesItem?.mrp!)?.toFixed(2),
              total: parseInt(salesItem?.total_amount!)?.toFixed(2),
            };
          }),

        empname: AuthUser?.name,
        grandtotal: data.grand_total,
        discount: [{ discountname: "offer 10%", amount: 20.0 }],
        gst: [{ taxname: "gst 8%", amount: 16.0 }],
        oc: [{ ocname: "ocname 5%", amount: 10.0 }],
        finaltotal: parseFloat(data?.grand_total!)?.toFixed(2),
        totaldisc: parseFloat(data?.dis_amount!)?.toFixed(2),
        totalqty: data.salesItems?.reduce(
          (sum: any, si: any) => sum + Number(si.total_quantity),
          0
        ),
        paymentamount: parseFloat(data.amt_paid)?.toFixed(2),
        tender: data.tender_amount
          ? (data.tender_amount)?.toFixed(2)
          : 0,
        balance: data.tender_return ? data.tender_return?.toFixed(2) : 0,
        //    pointsearned: data.pointsEarned > 0 ? data.pointsEarned : 0,
        //    totalpoints: data.totalpoints > 0 ? data.totalpoints : 0,
      };

      console.log("productitems", printObj.productitmes);

      if (printObj.productitmes && printObj.productitmes.length > 0) {
        console.log(printObj);

        generateBillPdf(printObj);
      }
    } else {
      console.error("Invalid value.id: undefined");
    }

    // Optionally set the ID in the parent component (if needed)

    // Generate the PDF
    //  generateBillPdf(value);
  };

  const handleDeleteFormClose = () => {
    setShowDeleteForm(false);
    props.setFormType(FormTypeList.ADD);
  };

  const generateBillPdf = (printObj: any) => {
    const doc = new jsPDF();
    const logoUrl = clients[0]?.shopPhoto;
    const marginX = 10;
    const startY = 15;

    if (logoUrl) {
      doc.addImage(logoUrl, "JPEG", marginX, startY - 2, 25, 20);
    }
    // *Business Details (Top Left)*
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text(clients[0]?.shopFullName || "", 35, 10);
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(clients[0]?.address1 || "", 35, 15);
    doc.text(clients[0]?.address2 || "", 35, 20);
    doc.text(
      (clients[0]?.state || "") + "-" + (clients[0]?.pincode || ""),
      35,
      25
    );
    doc.text("Cell: " + (clients[0]?.contactNo1 || ""), 35, 30);
    doc.text("GST NO: " + clients[0]?.gstNo || "", 35, 35);
    doc.setFontSize(12);
    doc.text(printObj?.invoiceType, 150, 10);
    // *Draw Horizontal Line After GST No*
    doc.line(14, 40, 195, 40);

    // *Customer Details (Left Side)*
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("Customer Details:", 14, 47);
    doc.setFont("helvetica", "normal");
    doc.text("Name: " + (printObj.customer.accountName || ""), 14, 53);
    doc.text("Address: MYD", 14, 58);
    doc.text("Cell: " + (printObj.customer.contactNumber1 || ""), 14, 63);
    doc.text("GSTIN: NIL", 14, 68);

    // *Invoice Details (Right Side)*
    doc.setFont("helvetica", "bold");
    doc.text("Invoice Details:", 150, 47);
    doc.setFont("helvetica", "normal");
    doc.text("Invoice No: " + printObj.billnumber, 150, 53);
    doc.text("Date: " + printObj.date + " " + printObj.time, 150, 58);
    // doc.text("Ref No: ", 150, 63);
    doc.text("Payment: CASH SALES", 150, 63);

    doc.line(14, 72, 195, 72);

    // *Table Headers & Data*
    const tableColumn = [
      "S.No",
      "Particulars",
      "HSN Code",
      "Qty",
      "Rate",
      "GST%",
      "Total",
    ];
    const tableRows = printObj?.productitmes?.map(
      (item: any, index: number) => [
        index + 1,
        item.name,
        item.hsn || "-",
        item.qty,
        item.rate,
        item.gst,
        item.total,
      ]
    );

    autoTable(doc, {
      startY: 75, // Below the second line
      head: [tableColumn],
      body: tableRows,
      theme: "grid",
      styles: {
        fontSize: 10,
        cellPadding: 2,
        valign: "middle",
        lineColor: 0,
        lineWidth: 0.1,
        textColor: 0,
      },
      // styles: { fontSize: 8, },
      headStyles: { fillColor: [255, 255, 255] }, // Black header
      bodyStyles: { lineWidth: 0 },
    });

    doc.line(
      14,
      (doc as any).lastAutoTable.finalY + 5,
      195,
      (doc as any).lastAutoTable.finalY + 5
    );

    let finalY = (doc as any).lastAutoTable.finalY + 10;

    // Define X positions
    let leftTableX = 14; // Left side for GST table
    let rightTableX = 110; // Right side for Amount details table

    autoTable(doc, {
      startY: finalY,
      margin: { left: leftTableX }, // Align to the left
      head: [["CGST", "SGST"]],
      body: [[printObj.cgst, printObj.sgst]],
      theme: "plain",
      styles: { fontSize: 10, halign: "center" },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 30 },
      },
    });

    let nextY = finalY;

    autoTable(doc, {
      startY: nextY,
      margin: { left: rightTableX }, // Align to the right
      body: [
        ["Bill Amount", ":", printObj.finaltotal],
        ["Amount Paid", ":", printObj.tender],
        ["Balance", ":", printObj.balance],
      ],
      theme: "plain",
      styles: { fontSize: 10 },
      columnStyles: {
        0: { fontStyle: "bold", halign: "right" },
        1: { halign: "left" },
        2: { halign: "right" },
      },
    });
    doc.line(
      14,
      (doc as any).lastAutoTable.finalY + 5,
      195,
      (doc as any).lastAutoTable.finalY + 5
    );

    // *Footer Text*
    doc.text(
      "Goods Once Sold Cannot be taken back. Thanks Come Again.",
      53,
      finalY + 35
    );

    // // *QR Code Generation*
    // const qrCode = new QRCodeStyling({
    //   width: 70,
    //   height: 70,
    //   data: "https://your-business-website.com",
    //   dotsOptions: { color: "#000", type: "square" },
    //   backgroundOptions: { color: "#ffffff" },
    // });

    // qrCode.getRawData().then((image) => {
    //   if (image) {
    //     doc.addImage(image, "PNG", 150, finalY + 10, 40, 40);
    //   }
    doc.save("Sales Invoice-" + printObj.date + " " + printObj.time + ".pdf");
    // });
  };

  // **Filtered Sales List**
  const filteredSales = salesList.filter((tr) => {
    const invoiceDateMatch = searchDate
      ? LocalFormatDate(tr.invoiceDate) ===
        LocalFormatDate(searchDate.toLocaleDateString())
      : true;
    const invoiceNoMatch = searchInvoiceNo
      ? tr.invoiceNumber.toLowerCase().includes(searchInvoiceNo)
      : true;
    const customerObj = CustomerList.find(
      (s) => s?.accountNumber === tr?.customerId
    );
    const customerName = customerObj?.accountName?.toLowerCase() || "";
    const customerMatch = searchCustomer
      ? customerName.includes(searchCustomer)
      : true;

    return invoiceDateMatch && invoiceNoMatch && customerMatch;
  });

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="hstack gap-4 mb-3">
            <div>
              <label htmlFor="searchDate">Date :</label>
              <DatePicker
                id="searchDate"
                selected={searchDate}
                onChange={handleDateFilter}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <FormInput
              name="searchInvoiceNo"
              label="Invoice No :"
              value={searchInvoiceNo}
              onChange={(e) => handleInvoiceNoFilter(e.target.value)}
            />
            <FormInput
              name="searchCustomer"
              label="Customer :"
              value={searchCustomer}
              onChange={(e) => handleCustomerFilter(e.target.value)}
            />
          </div>
        </div>
      </div>

      <Modal
        headerText={"Delete Sales"}
        visible={showDeleteForm}
        onClose={handleDeleteFormClose}
        centered
        size="sm"
      >
        <DeleteConfirmSales
          editData={editData}
          onClose={handleDeleteFormClose}
        />
      </Modal>

      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow-lg">
            <div className="table-wrapper">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-truncate align-middle">S.No</th>
                    <th className="text-truncate align-middle">
                      Sales Entry No
                    </th>
                    <th className="text-truncate align-middle">Bill Date</th>
                    <th className="text-truncate align-middle">Bill No</th>
                    <th className="text-truncate align-middle">Customer</th>
                    <th className="text-truncate align-middle">Grand Total</th>
                    <th className="text-truncate align-middle">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSales?.map((tr, i) => {
                    const customerObj = CustomerList.find(
                      (s) => s?.accountNumber === tr?.customerId
                    );
                    const customerName = customerObj?.accountName || "";
                    return (
                      <UserBody
                        sno={i + 1}
                        invoiceNo={tr.invoiceNumber?.toString()}
                        invoiceDate={tr.invoiceDate?.toString()}
                        salesEntryNo={tr.salesEntryNumber?.toString()}
                        finalAmount={
                          tr.grandTotal ? tr.grandTotal?.toString() : "0"
                        }
                        customer={customerName}
                        onEdit={() => handleEdit(tr)}
                        onDelete={() => handleDelete(tr)}
                        onPrint={() => handlePrintPdf(tr)}
                        key={i}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const UserBody = ({ sno, invoiceDate, finalAmount, invoiceNo, salesEntryNo, customer, onEdit, onDelete,onPrint }: TableBody) => {
    return (
      <tr key={sno}>
        <td className="align-middle">{sno}</td>
        <td className="text-capitalize align-middle">{salesEntryNo}</td>
        <td className="text-capitalize align-middle">
          {LocalFormatDate(invoiceDate)}
        </td>
        <td className="text-capitalize align-middle">{invoiceNo}</td>
        <td className="text-capitalize align-middle">{customer}</td>
        <td className="text-capitalize align-middle">
          {rupeeFormat(finalAmount)}
        </td>
        <td className="align-middle">
          <div className="hstack gap-1">
            <button
              className="btn btn-sm fw-bold btn-info p-1"
              onClick={onEdit}
            >
              <i className="fe-edit noti-icon"></i>
            </button>
            <button
              className="btn btn-sm fw-bold btn-danger p-1"
              onClick={onDelete}
            >
              <i className="fe-trash-2 noti-icon"></i>
            </button>
            <button
              className="btn btn-sm fw-bold btn-success p-1"
              onClick={onPrint}
            >
              <i className="fe-printer noti-icon"></i>
            </button>
          </div>
        </td>
      </tr>
    );
};

export default Sales;
