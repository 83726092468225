import { Dispatch } from "redux";
import { camelize } from "../../config/functions";
import { addClientRegistration, deleteClientRegistration, editClientRegistration, getClientRegistration } from "../../services/clientRegistraion.service";

export interface ClientRegistrationType {
    id?: string;
    shopId?: number;
    shopFullName?: string;
    shopShortName?: string;
    clientType?: string;
    headOffice?: string;
    proprietorName?: string;
    gstNo?: string;
    panCardNo?: string;
    licenceNo1?: string;
    licenceNo2?: string;
    licenceNo3?: string;
    address1?: string;
    address2?: string;
    place?: string;
    taluk?: string;
    district?: string;
    state?: string;
    pincode?: string;
    contactNo1?: string;
    contactNo2?: string;
    shopLogo?: string;
    shopPhoto?: string;
    bannerPhoto?: string;
    tagLine?: string;
    bannerImage?: string;
}

export enum ClientRegistrationActionList {
    ADD_CLIENT_REG = 'ADD_CLIENT_REG',
    EDIT_CLIENT_REG = 'EDIT_CLIENT_REG',
    FETCH_CLIENT_REG = 'FETCH_CLIENT_REG',
    DELETE_CLIENT_REG = 'DELETE_CLIENT_REG'
}

export interface AddClientRegistrationAction {
    type: ClientRegistrationActionList.ADD_CLIENT_REG;
    data: ClientRegistrationType;
}

export interface EditClientRegistrationAction {
    type: ClientRegistrationActionList.EDIT_CLIENT_REG;
    data: ClientRegistrationType;
    id: string | number;
}

export interface FetchClientRegistrationAction {
    type: ClientRegistrationActionList.FETCH_CLIENT_REG;
    data: ClientRegistrationType[];
}

export interface DeleteClientRegistrationAction {
    type: ClientRegistrationActionList.DELETE_CLIENT_REG;
    data: string | undefined;
}

export type ClientRegistrationActions = AddClientRegistrationAction | EditClientRegistrationAction | FetchClientRegistrationAction | DeleteClientRegistrationAction;

export const addClientRegistrationAction = (data: FormData) => {
    return async (dispatch: Dispatch) => {
        return addClientRegistration(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {};
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddClientRegistrationAction>({
                    type: ClientRegistrationActionList.ADD_CLIENT_REG,
                    data: addData
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Client Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add');
        });
    };
};

export const editClientRegistrationAction = (data: FormData, id: string) => {
    return async (dispatch: Dispatch) => {
        return editClientRegistration(data, id).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {};
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i]);
                    const value = Object.values(response.data.data)[i];
                    editData = { ...editData, [key]: value };
                }
                dispatch<EditClientRegistrationAction>({
                    type: ClientRegistrationActionList.EDIT_CLIENT_REG,
                    data: editData,
                    id: id
                });
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Client Updated');
            } else {
                throw { response };
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update');
        });
    };
};

export const fetchClientRegistrationAction = () => {
    return async (dispatch: Dispatch) => {
        const response = await getClientRegistration();
        const data = response.data && response.data.data ? response.data.data.map((res: any) => {
            let camelCase = {};
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i]);
                const value = Object.values(res)[i];
                camelCase = { ...camelCase, [key]: value };
            }
            return camelCase;
        }) : [];
        dispatch<FetchClientRegistrationAction>({
            type: ClientRegistrationActionList.FETCH_CLIENT_REG,
            data: data
        });
    };
};

export const deleteClientRegistrationAction = (id: string) => {
    return async (dispatch: Dispatch) => {
        return deleteClientRegistration(id).then(response => {
            if (response.status === 200) {
                dispatch<DeleteClientRegistrationAction>({
                    type: ClientRegistrationActionList.DELETE_CLIENT_REG,
                    data: id
                });
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Client Deleted');
            } else {
                throw { response };
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete');
        });
    };
};