import { useEffect, useState } from "react";
import { Card, Table, Button } from "react-bootstrap";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import ReportFilter from "./ReportFilter";
import api from '../../config/api';
import { getDate, LocalFormatDate, rupeeFormat } from "../../config/functions";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { ClientRegistrationType } from "../../redux/actions/clientRegistration";

interface PurchaseDateWiseData {
    financialYear: string;
    purchaseDate: string;
    day: string;
    taxable: number;
    igst: number;
    sgst: number;
    cgst: number;
    total: number;
    addAmount: number;
    lessAmount: number;
    netTotal: number;
    grandTotal: number;
}

const PurchaseReturnDateWise = () => {
    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");

    const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

    const [tableData, setTableData] = useState<PurchaseDateWiseData[]>([]);

    const clients = useSelector<StoreState, ClientRegistrationType[]>(state => state.clientRegistration)

    useEffect(() => {
        if (triggerSearch) {
            const response = api().get(
                `reports/purchaseReturnDateWise/?fromDate=${getDate(fromDate)}&toDate=${getDate(toDate)}`
            );

            response.then((res) => {
                if (res.status === 200 && res.data.data) {
                    console.log("API Response:", res.data);
                    setTableData(res.data.data);
                }
            }).catch(error => {
                console.error("Error fetching purchase data:", error);
            });

            setTriggerSearch(false);
        }
    }, [triggerSearch, fromDate, toDate]);

    const handleSearch = (newFilters: any) => {
        setFromDate(newFilters.fromDate);
        setToDate(newFilters.toDate);

        setTriggerSearch(true);
    };

    const downloadCSV = () => {
        const header = ["SI.No", "Finanacial Year", "Pur.Date", "Pur.Day", "Taxable", "IGST", "CGST", "SGST", "Total", "Add Amount", "Less Amount", "Net Total", "Grand Total"];

        const colTotal = tableData.reduce(
            (acc, item) => {
                 acc.taxable += parseFloat(item.taxable?.toFixed(2) || "0");
                 acc.igst += parseFloat(item.igst?.toFixed(2) || "0");
                 acc.cgst += parseFloat(item.cgst?.toFixed(2) || "0");
                 acc.sgst += parseFloat(item.sgst?.toFixed(2) || "0");
                 acc.total += parseFloat(item.total?.toFixed(2) || "0");
                 acc.addAmount += parseFloat(item.addAmount?.toFixed(2) || "0");
                 acc.lessAmount += parseFloat(
                   item.lessAmount?.toFixed(2) || "0"
                 );
                 acc.netTotal += parseFloat(item.netTotal?.toFixed(2) || "0");
                 acc.grandTotal += parseFloat(
                   item.grandTotal?.toFixed(2) || "0"
                 );
                 return acc;
            },
            {
                taxable: 0,
                igst: 0,
                cgst: 0,
                sgst: 0,
                total: 0,
                addAmount: 0,
                lessAmount: 0,
                netTotal: 0,
                grandTotal: 0
            }
        );

        const rows = tableData?.map((item, index) => [
          index + 1,
          item.financialYear || "",
          LocalFormatDate(item.purchaseDate) || "",
          item.day || "",
          rupeeFormat(parseFloat(item.taxable?.toFixed(2) || "0")) || "0",
          rupeeFormat(parseFloat(item.igst?.toFixed(2) || "0")) || "0",
          rupeeFormat(parseFloat(item.cgst?.toFixed(2) || "0")) || "0",
          rupeeFormat(parseFloat(item.sgst?.toFixed(2) || "0")) || "0",
          rupeeFormat(parseFloat(item.total?.toFixed(2) || "0")) || "0",
          rupeeFormat(parseFloat(item.addAmount?.toFixed(2) || "0")) || "0",
          rupeeFormat(parseFloat(item.lessAmount?.toFixed(2) || "0")) || "0",
          rupeeFormat(parseFloat(item.netTotal?.toFixed(2) || "0")) || "0",
          rupeeFormat(parseFloat(item.grandTotal?.toFixed(2) || "0")) || "0",
        ]);

        const grandTotalRow = [
          "", // SI.No
          "", // Financial Year
          "", // Pur.Date
          "Grand Total", // Pur.Day
          rupeeFormat(colTotal.taxable.toFixed(2)) || "0", // Taxable
          rupeeFormat(colTotal.igst.toFixed(2)) || "0", // IGST
          rupeeFormat(colTotal.cgst.toFixed(2)) || "0", // CGST
          rupeeFormat(colTotal.sgst.toFixed(2)) || "0", // SGST
          rupeeFormat(colTotal.total.toFixed(2)) || "0", // Total
          rupeeFormat(colTotal.addAmount.toFixed(2)) || "0", // Add Amount
          rupeeFormat(colTotal.lessAmount.toFixed(2)) || "0", // Less Amount
          rupeeFormat(colTotal.netTotal.toFixed(2)) || "0", // Net Total
          rupeeFormat(colTotal.grandTotal.toFixed(2)) || "0", // Grand Total
        ];

        const csvContent = [
            header.join(","),
            ...rows.map(row => row.join(",")),
            grandTotalRow.join(",")
        ].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "purchase_return_date_wise_report.csv";
        link.click();
    };


    const downloadPDF = () => {
        const logoUrl = clients[0]?.shopPhoto;

        const doc = new jsPDF("landscape");
        const marginX = 10;
        const boxWidth = doc.internal.pageSize.width - 2 * marginX;
        const boxHeight = 25;
        let startY = 15;

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");

        doc.rect(marginX, startY - 5, boxWidth, boxHeight);

        if (logoUrl) {
            doc.addImage(logoUrl, "JPEG", marginX + 5, startY - 2, 25, 20);
        }

        const paddingBetweenHeaderAndTable = 5;
        const textX = marginX + 35;

        doc.text(clients[0]?.shopShortName || "", textX, startY);
        doc.setFont("helvetica", "normal");
        doc.text(
            `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${clients[0]?.district || ""}`,
            textX,
            startY + 5
        );
        doc.text(
            `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${clients[0]?.contactNo1 || ""}`,
            textX,
            startY + 10
        );
        doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 15);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        const heading = "Purchase return date wise report";
        const headingWidth = doc.getTextWidth(heading);
        const pageWidth = doc.internal.pageSize.width;
        doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 8);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const financialYearText = `Financial Year: ${tableData[0]?.financialYear || ""}`;
        const headingStartX = marginX + boxWidth - headingWidth - 10;
        doc.text(financialYearText, headingStartX, startY + 13);
        
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        const formattedFromDate = "From : " + (LocalFormatDate(getDate(fromDate)) || "");
        const formattedToDate = "To : " + (LocalFormatDate(getDate(toDate)) || "");

        const tableStartY = startY + boxHeight + paddingBetweenHeaderAndTable;
        doc.text(formattedFromDate, marginX, tableStartY-3);
        doc.text(formattedToDate, marginX + 50, tableStartY-3);

        const colTotal = tableData.reduce(
            (acc, item) => {
   acc.taxable += parseFloat(item.taxable?.toFixed(2) || "0");
   acc.igst += parseFloat(item.igst?.toFixed(2) || "0");
   acc.cgst += parseFloat(item.cgst?.toFixed(2) || "0");
   acc.sgst += parseFloat(item.sgst?.toFixed(2) || "0");
   acc.total += parseFloat(item.total?.toFixed(2) || "0");
   acc.addAmount += parseFloat(item.addAmount?.toFixed(2) || "0");
   acc.lessAmount += parseFloat(item.lessAmount?.toFixed(2) || "0");
   acc.netTotal += parseFloat(item.netTotal?.toFixed(2) || "0");
   acc.grandTotal += parseFloat(item.grandTotal?.toFixed(2) || "0");
   return acc;
             
            },
            {
                taxable: 0,
                igst: 0,
                cgst: 0,
                sgst: 0,
                total: 0,
                addAmount: 0,
                lessAmount: 0,
                netTotal: 0,
                grandTotal: 0
            }
        );

        autoTable(doc, {
          startY: tableStartY + 3,
          head: [
            [
              "SI.No",
              "Pur.Date",
              "Pur.Day",
              "Taxable",
              "IGST",
              "CGST",
              "SGST",
              "Total",
              "Add Amount",
              "Less Amount",
              "Net Total",
              "Grand Total",
            ],
          ],
          body: [
            ...tableData?.map((item, index) => [
              (index + 1).toString(),
              LocalFormatDate(item.purchaseDate) || "",
              item.day || "",
              rupeeFormat(parseFloat(item.taxable?.toFixed(2) || "0")) || "0",
              rupeeFormat(parseFloat(item.igst?.toFixed(2) || "0")) || "0",
              rupeeFormat(parseFloat(item.cgst?.toFixed(2) || "0")) || "0",
              rupeeFormat(parseFloat(item.sgst?.toFixed(2) || "0")) || "0",
              rupeeFormat(parseFloat(item.total?.toFixed(2) || "0")) || "0",
              rupeeFormat(parseFloat(item.addAmount?.toFixed(2) || "0")) || "0",
              rupeeFormat(parseFloat(item.lessAmount?.toFixed(2) || "0")) ||
                "0",
              rupeeFormat(parseFloat(item.netTotal?.toFixed(2) || "0")) || "0",
              rupeeFormat(parseFloat(item.grandTotal?.toFixed(2) || "0")) ||
                "0",
            ]),
            [
              {
                content: "Total",
                colSpan: 3,
                styles: { fontStyle: "bold", halign: "right" },
              },
              {
                content: rupeeFormat(colTotal?.taxable?.toFixed(2)),
                styles: { fontStyle: "bold" },
              },
              {
                content: rupeeFormat(colTotal?.igst?.toFixed(2)),
                styles: { fontStyle: "bold" },
              },
              {
                content: rupeeFormat(colTotal?.cgst?.toFixed(2)),
                styles: { fontStyle: "bold" },
              },
              {
                content: rupeeFormat(colTotal?.sgst?.toFixed(2)),
                styles: { fontStyle: "bold" },
              },
              {
                content: rupeeFormat(colTotal?.total?.toFixed(2)),
                styles: { fontStyle: "bold" },
              },
              {
                content: rupeeFormat(colTotal?.addAmount?.toFixed(2)),
                styles: { fontStyle: "bold" },
              },
              {
                content: rupeeFormat(colTotal?.lessAmount?.toFixed(2)),
                styles: { fontStyle: "bold" },
              },
              {
                content: rupeeFormat(colTotal?.netTotal?.toFixed(2)),
                styles: { fontStyle: "bold" },
              },
              {
                content: rupeeFormat(colTotal?.grandTotal?.toFixed(2)),
                styles: { fontStyle: "bold" },
              },
            ],
          ],
          theme: "grid",
          styles: { fontSize: 8, textColor: 0, lineColor: 0, lineWidth: 0.1 },
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: 0,
            fontStyle: "bold",
            lineColor: 0,
            lineWidth: 0.1,
          },
          margin: { top: 25, left: 10, right: 10 },
          columnStyles: {
            0: { halign: "center" },
            1: { halign: "center" },
            2: { halign: "center" },
            3: { halign: "right" },
            4: { halign: "right" },
            5: { halign: "right" },
            6: { halign: "right" },
            7: { halign: "right" },
            8: { halign: "right" },
            9: { halign: "right" },
            10: { halign: "right" },
            11: { halign: "right" },
          },
        });

         // Footer Section (Page Number)
         const pageCount = doc.getNumberOfPages();
         for (let i = 1; i <= pageCount; i++) {
             doc.setPage(i);
             let pageHeight = doc.internal.pageSize.height;
             let footerY = pageHeight - 15;
 
             doc.setFontSize(10);
             doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, footerY);
         }

        doc.save("purchase_return_date_wise_report.pdf");
    };


    return (
        <>
            <ReportFilter onSearch={handleSearch} />
            {tableData.length > 0 ? <Card className="mt-2 p-2 shadow-lg border-0 rounded-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5 className="fw-bold">Purchase return date details</h5>
                    <div>
                        <Button className="me-2 btn-sm" variant="success" onClick={downloadCSV}>
                            Download CSV
                        </Button>
                        <Button className="me-2 btn-sm" variant="success" onClick={downloadPDF}>
                            Download PDF
                        </Button>
                    </div>
                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>SI.No</th>
                            <th>Financial Year</th>
                            <th>Pur.Date</th>
                            <th>Pur.Day</th>
                            <th>Taxable</th>
                            <th>IGST</th>
                            <th>CGST</th>
                            <th>SGST</th>
                            <th>Total</th>
                            <th>Add Amount</th>
                            <th>Less Amount</th>
                            <th>Net Total</th>
                            <th>Grand Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        {tableData?.length > 0 ? (
                            tableData?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.financialYear}</td>
                                    <td>{item.purchaseDate}</td>
                                    <td>{item.day}</td>
                                    <td>{item.taxable}</td>
                                    <td>{item.igst}</td>
                                    <td>{item.cgst}</td>
                                    <td>{item.sgst}</td>
                                    <td>{item.total}</td>
                                    <td>{item.addAmount}</td>
                                    <td>{item.lessAmount}</td>
                                    <td>{item.netTotal}</td>
                                    <td>{item.grandTotal}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={13} className="text-center fw-bold">
                                    No such data found !!!
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card> : <h4 className="text-center fw-bold text-secondary">
                No such data found !
            </h4>
            }
        </>
    );
};

export default PurchaseReturnDateWise;
