import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import React, { useEffect } from "react"
import { Badge } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { rupeeFormat } from "../config/functions"
import { AuthUserType } from "../redux/actions/authUserActions"
import { ClientRegistrationType, fetchClientRegistrationAction } from "../redux/actions/clientRegistration"
import { StockMasterType, fetchStockMasterAction } from "../redux/actions/stockMasterAction"
import { AppDispatch, StoreState } from "../redux/store"


interface TableBody {
    sno: number,
    productName: string | undefined,
    productCode: string,
    expiryDate: string,
    departmentName: string,
    categoryName: string,
    brandName: string,
    productDetails: (string | undefined)[],
    quantity: number,
    reOrder: number,
    priceWithOutGst: number,
    priceWithGst: number,
    wholeSaleRate: number,
    retailRate: number,
    loseRate: number,
    mrp: number,
    hsn: string,
    gst: number
}

const StockMaster = () => {
    const dispatch = useDispatch<AppDispatch>()
    const StockList = useSelector<StoreState, StockMasterType[]>(state => state.stockMaster)
    // const ProductList = useSelector<StoreState, ProductType[]>(state => state.product)
    const clients = useSelector<StoreState, ClientRegistrationType[]>(state => state.clientRegistration)
    const AuthUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)

    const url = window.location.pathname
    const rolePermissions = AuthUser?.roles?.find(r => r.pageUrl === url)
    // const [showForm, setShowForm] = useState<boolean>(false)
    // const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    // const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    // const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    // const [id, setId] = useState<number>()

    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()
    const searchTerm = searchQuery.get('search') || ''
    // const [stockData, setStockData] = useState([])

    const mainContentRef = React.useRef<HTMLDivElement>(null)

    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery({ search: e.target.value })
    }

    const filterProductHandler = (stockMaster: any, searchTerm: any) => {
        const regex = new RegExp(`^${searchTerm}|${searchTerm}`, 'gi')
        return searchTerm !== '' ? (
            regex.test(stockMaster.productName) ||
            regex.test(stockMaster.productCode) ||
            regex.test(stockMaster.sac?.toString()) ||
            regex.test(stockMaster.totalQuantity.toString())
        ) : true
    }

    //     let cate = StockList.map((item)=>item.categoryName)
    // const uniqueArr = cate.filter((item, index) => cate.indexOf(item) === index);

    //     const value = uniqueArr.forEach((item) => {
    //         return StockList.filter((data) => {
    //             if(data.categoryName === item){
    //                 return [data.brandName, data.productName,data.productCode, data.sac, data.gst, data.quantity,data.priceWogst, data.pwg]
    //             }
    //         })
    //     })

    const downloadPDF = () => {
        const logoUrl = clients[0]?.shopPhoto;
        const doc = new jsPDF("landscape");
        const marginX = 10;
        const boxWidth = doc.internal.pageSize.width - 2 * marginX;
        const boxHeight = 35;
        let startY = 15;

        // Draw a rectangle for the header section
        doc.rect(marginX, startY - 5, boxWidth, boxHeight);

        // Add logo if available
        if (logoUrl) {
            doc.addImage(logoUrl, "JPEG", marginX + 5, startY, 30, 25); // Adjusted dimensions for better fit
        }

        // Set font size and style for shop details
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");

        const textX = marginX + 40; // X position for text after the logo
        doc.text(clients[0]?.shopFullName || "", textX, startY + 5); // Shop full name
        doc.setFont("helvetica", "normal");
        doc.text(
            `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${clients[0]?.district || ""
            }`,
            textX,
            startY + 10
        ); // Address line 1
        doc.text(
            `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${clients[0]?.contactNo1 || ""
            }`,
            textX,
            startY + 15
        ); // Address line 2
        doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 20); // GSTIN

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        const heading = "Current Stock Report";
        const dateTime = new Date().toLocaleDateString("en-GB");
        const time = new Date().toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        });
        const headingWidth = doc.getTextWidth(heading);
        const pageWidth = doc.internal.pageSize.width;
        doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 15);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);

        // Align date and time in a row
        const dateTimeText = `${dateTime} | ${time}`;
        const dateTimeWidth = doc.getTextWidth(dateTimeText);
        doc.text(dateTimeText, marginX + boxWidth - dateTimeWidth - 10, startY + 20);

        startY += boxHeight + 10;

        const groupedData = StockList.reduce((acc, item) => {
            const category = item.categoryName || "Uncategorized"; // Handle null or undefined categories
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(item);
            return acc;
        }, {} as Record<string, StockMasterType[]>);

        Object.keys(groupedData).forEach((category) => {
            const categoryData = groupedData[category];

            doc.setFontSize(12);
            doc.setFont("helvetica", "bold");
            doc.text(`${category} :`, marginX, startY);
            startY += 5;

            const tableBody = categoryData.map((item, index) => [
                index + 1,
                item.brandName || "N/A",
                item.productName || "N/A",
                item.productCode || "N/A",
                item.sac,
                item.gst,
                rupeeFormat(item.priceGst),
                rupeeFormat(item.rRate),
                rupeeFormat(item.mrp),
                item.totalQuantity,

            ]);

            autoTable(doc, {
                startY,
                head: [
                    [
                        "S.No",
                        "Brand",
                        "Product Name",
                        "Product Code",
                        "HSN Code",
                        "GST %",
                        "PW GST",
                        "Retail",
                        "MRP",
                        "Stock"
                    ],
                ],
                body: tableBody,
                theme: "grid",
                styles: { fontSize: 8, textColor: 0, lineColor: 0, lineWidth: 0.1 },
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: 0,
                    fontStyle: "bold",
                    lineColor: 0,
                    lineWidth: 0.1,
                },
                columnStyles: {
                    0: { halign: "center" },
                    1: { halign: "left" },
                    2: { halign: "left" },
                    3: { halign: "left" },
                    4: { halign: "left" },
                    5: { halign: "left" },
                    6: { halign: "right" },
                    7: { halign: "right" },
                    8: { halign: "right" },
                    9: { halign: "center" },
                },
                margin: { top: 10, left: marginX, right: marginX },
            });

            startY = (doc as any).autoTable.previous.finalY + 10;

            if (startY + 20 > doc.internal.pageSize.height) {
                doc.addPage();
                startY = 15;
            }
        });
        const pageCount = doc.getNumberOfPages();
        console.log(pageCount);

        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            let pageHeight = doc.internal.pageSize.height;
            let footerY = pageHeight - 10;

            doc.setFontSize(10);
            doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, footerY);
        }

        doc.save("stock_master_report.pdf");
    };

    React.useEffect(() => {
        if (searchTerm !== '') {
            searchRef.current?.focus()
        }
    }, [])
    useEffect(() => {
        dispatch(fetchClientRegistrationAction())
    }, [])
    useEffect(() => {
        dispatch(fetchStockMasterAction())
    }, [])

    return <>
        <UserLayout>
            <h4>Stock Master</h4>
            <div className="row">
                <div className="col">
                    {rolePermissions?.view ? <div className="card p-2 w-100">
                        <div className="row">
                            <div className="col-8 ps-3">
                                <FormInput
                                    onChange={searchTermChangeHandler}
                                    name=""
                                    placeholder='Search by product name, product code, no. of stock and HSN code...'
                                    value={searchTerm}
                                    onFocus={() => {
                                        mainContentRef?.current?.scrollTo({
                                            behavior: 'smooth',
                                            top: 270
                                        })
                                    }}
                                    innerRef={searchRef}
                                    className="container my-2 py-1 fs-4"
                                    style={{ position: "sticky", top: '3px' }}
                                />
                            </div>
                            <div className="col-4 text-end">
                                <button type="button" className="btn btn-primary p-2 border-rounded fw-bold" onClick={downloadPDF}><i className='fe-download'></i> Download PDF</button>
                            </div>
                        </div>

                        <div className='table-wrapper' style={{ position: 'relative' }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        {/* <th className='text-truncate align-middle'>Department</th> */}
                                        <th className='text-truncate align-middle'>Category</th>
                                        <th className='text-truncate align-middle'>Brand</th>
                                        <th className='text-truncate align-middle'>Product Name</th>
                                        <th className='text-truncate align-middle'>Product Code</th>
                                        {/* <th className='text-truncate align-middle'>Product Details</th> */}
                                        <th className='text-truncate align-middle'>HSN Code</th>
                                        <th className='text-truncate align-middle'>GST %</th>
                                        <th className='text-truncate align-middle'>PWO GST</th>
                                        <th className='text-truncate align-middle'>PW GST</th>
                                        <th className='text-truncate align-middle'>W.Sale Rate</th>
                                        <th className='text-truncate align-middle'>Retail Rate</th>
                                        <th className='text-truncate align-middle'>MRP</th>
                                        <th className='text-truncate align-middle'>Stock</th>
                                        <th className='text-truncate align-middle'>Vendor</th>
                                        <th className='text-truncate align-middle'>Updated By/Updated On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        StockList.filter((sl) => filterProductHandler(sl, searchTerm)).map((sl, i) => {
                                            return <StockBody
                                                key={i}
                                                sno={i + 1}
                                                productName={sl.productName}
                                                productCode={sl.productCode}
                                                departmentName={sl.departmentName === undefined ? '' : sl.departmentName}
                                                categoryName={sl.categoryName || ''}
                                                brandName={sl.brandName || ''}
                                                expiryDate={sl.expiryDate}
                                                productDetails={[sl.attribute1, sl.attribute2, sl.attribute3, sl.attribute4, sl.attribute5]}
                                                reOrder={sl.reOrder}
                                                priceWithOutGst={sl.priceWogst}
                                                priceWithGst={sl.priceGst}
                                                wholeSaleRate={sl.wRate}
                                                retailRate={sl.rRate}
                                                loseRate={sl.lRate}
                                                mrp={sl.mrp}
                                                quantity={sl.totalQuantity}
                                                hsn={sl.sac?.toString()}
                                                gst={sl.gst}
                                            />
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div> : <div className="card p-2 w-100"><h3 className="text-secondary text-center">Oops! No Records.</h3></div>}
                </div>
            </div>
        </UserLayout>
    </>
}

const StockBody = ({ sno, productName, productCode, departmentName, gst, brandName, categoryName, expiryDate, productDetails, quantity, reOrder, priceWithOutGst, priceWithGst, wholeSaleRate, retailRate, loseRate, mrp, hsn }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        {/* <td className='text-capitalize align-middle'>{departmentName}</td> */}
        <td className='text-capitalize align-middle'>{categoryName}</td>
        <td className='text-capitalize align-middle'>{brandName}</td>
        <td className='text-capitalize align-middle text-truncate'>{productName} {productDetails.filter(item => item !== '').join('-')}</td>
        <td className='text-capitalize align-middle text-truncate'>{productCode}</td>
        {/* <td className='text-capitalize align-middle text-truncate'>{productDetails.filter(item => item !== '').join(',')}</td> */}
        <td className='text-capitalize align-middle'>{hsn}</td>
        <td className='text-capitalize align-middle'>{gst}%</td>
        <td className='text-capitalize align-middle'>{rupeeFormat(priceWithOutGst)}</td>
        <td className='text-capitalize align-middle'>{rupeeFormat(priceWithGst)}</td>
        <td className='text-capitalize align-middle'>{wholeSaleRate}</td>
        <td className='text-capitalize align-middle'>{retailRate}</td>
        <td className='text-capitalize align-middle'>{rupeeFormat(mrp)}</td>
        <td className='text-capitalize align-middle'> {quantity < 50 ? <Badge bg="danger" className="badge">{quantity}{" / "}{50}</Badge> : <Badge bg="success" className="badge">{quantity}</Badge>}</td>
        <td className='text-capitalize align-middle'></td>
        <td className='text-capitalize align-middle'></td>
    </tr>
}

export default StockMaster