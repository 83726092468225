import { Dispatch } from "redux"
import { GetState } from "../store"
import { addCategory, deleteCategory, editCategory, getCategory } from "../../services/category.service";
import { camelize } from "../../config/functions";
import { addDepartment, deleteDepartment, editDepartment, getDepartment } from "../../services/department.services";


export interface DepartmentType {
    id?: number
    companyId?:number;
    shopId?:number;
    branchId?: number,
    name?: string
    imageUpload?:File
    imageUrl?:any
}



export enum DepartmentActionList {
    ADD_DEPARTMENT = ' ADD_DEPARTMENT',
    EDIT_DEPARTMENT = 'EDIT_DEPARTMENT',
    FETCH_DEPARTMENT = ' FETCH_DEPARTMENT',
    DELETE_DEPARTMENT = 'DELETE_DEPARTMENT'
}

export interface AddDepartmentAction {
    type: DepartmentActionList.ADD_DEPARTMENT;
    data: DepartmentType
}
export interface EditDepartmentAction {
    type: DepartmentActionList.EDIT_DEPARTMENT;
    data: DepartmentType
    id: number | undefined
}

export interface FetchDepartmentAction {
    type: DepartmentActionList.FETCH_DEPARTMENT;
    data: DepartmentType[]
}
export interface DeleteDepartmentAction {
    type: DepartmentActionList.DELETE_DEPARTMENT;
    data: number|undefined
}

export type DepartmentAction = AddDepartmentAction | EditDepartmentAction | FetchDepartmentAction | DeleteDepartmentAction;


export const addDepartmentAction = (data: FormData) => {
    return async (dispatch: Dispatch) => {
        return addDepartment(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddDepartmentAction>({
                    type: DepartmentActionList.ADD_DEPARTMENT,
                    data: addData
                })
                return Promise.resolve({
                    id: response.data.data.id, 
                    message: response.data.message || 'Department Added'
                });
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })    
    }
}
export const editDepartmentAction = (data: FormData, id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return editDepartment(data,id).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
                dispatch<EditDepartmentAction>({
                    type: DepartmentActionList.EDIT_DEPARTMENT,
                    data: editData,
                    id: id
                })
        
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Department Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}
export const fetchDepartmentAction = () => {
    return async (dispatch: Dispatch) => {
        const response=await getDepartment()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchDepartmentAction>({
            type: DepartmentActionList.FETCH_DEPARTMENT,
            data: data
        })
    }
}
export const deleteDepartmentAction = (id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return deleteDepartment(id).then(response => {
            if (response.status === 200) {
                dispatch<DeleteDepartmentAction>({
                    type: DepartmentActionList.DELETE_DEPARTMENT,
                    data:id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Department Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}