import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import AdminHome from '../pages/admin/Home'
import BranchMaster from '../pages/Branch'
import Category from '../pages/Category'
import Department from '../pages/Department'
import GroupMaster from '../pages/Group'
import Login from '../pages/Login'
import Logout from '../pages/Logout'
import ProductMaster from '../pages/Product'
import RoleManagement from '../pages/RoleManagement'
import Unit from '../pages/Unit'
import UserManagement from '../pages/UserManagement'
import { AuthUserType } from '../redux/actions/authUserActions'
// import { fetchUsers } from '../redux/actions/usersActions'
import AccountHolder from '../pages/AccountHolder'
import AppSettings from '../pages/AppSettings'
import Attribute from '../pages/Attribute'
import BarcodeSticker from '../pages/Barcodesticker'
import Brand from '../pages/Brand'
import ResetPassword from '../pages/ChangePassword'
import ClientRegistration from '../pages/ClientRegistration'
import Country from '../pages/Country'
import District from '../pages/District'
import Division from '../pages/Division'
import MainHome from '../pages/MainHome'
import Place from '../pages/Place'
import PurchaseUpdateAttribute from '../pages/PurchaseAttribute'
import PurchaseDashboard from '../pages/PurchaseDashboard'
import PurchaseMaster from '../pages/PurchaseMaster'
import PurchaseReturnDashboard from '../pages/PurchaseReturnDashboard'
import PurchaseReturnMaster from '../pages/PurchaseReturnMaster'
import PurchaseReport from '../pages/report/PurchaseReport'
// import PurchaseReturnReport from '../pages/PurchaseReturnReport'
import SalesReport from '../pages/report/SalesReport'
import RouteMaster from '../pages/Route'
import SalesMaster from '../pages/SalesMaster'
import SalesReturnDashboard from '../pages/SalesReturnDashboard'
import SalesReturnMaster from '../pages/SalesReturnMaster'
// import SalesReturnReport from '../pages/SalesReturnReport'
import GstReport from '../pages/report/GstReport'
import PurchaseReturnReports from '../pages/report/PurchaseReturnReports'
import SalesReturnReports from '../pages/report/SalesReturnReports'
import Shop from '../pages/Shop'
import State from '../pages/State'
import StockMaster from '../pages/StockMaster'
import SupplierPlace from '../pages/SupplierPlace'
import Transaction from '../pages/Transaction'
import { fetchAccountHolderAction } from '../redux/actions/accountHolder'
import { fetchBranchAction } from '../redux/actions/branchAction'
import { fetchCategoryAction } from '../redux/actions/categoryAction'
import { fetchClientRegistrationAction } from '../redux/actions/clientRegistration'
import { fetchProductAction } from '../redux/actions/productAction'
import { fetchRolesAction } from '../redux/actions/rolesAction'
import { fetchShopAction } from '../redux/actions/shopAction'
import { fetchStockMasterAction } from '../redux/actions/stockMasterAction'
import { fetchUsersAction } from '../redux/actions/usersActions'
import { AppDispatch, StoreState } from '../redux/store'
import { USER_MENU_ITEMS } from './custom/constants/menu'


const Router = () => {
    // const location = useLocation();
    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const authUserRole = authUser?.roles || [];
       
    const authendicated = typeof authUser?.['auth-token'] === 'string' && authUser?.['auth-token'] !== undefined && authUser?.['auth-token'] !== ''

    //  const currentMenuItems = USER_MENU_ITEMS.find(
    //    (item) =>
    //      item.url === location.pathname ||
    //      (item.children &&
    //        item.children.some((child) => child.url === location.pathname))
    // );
    
      // const stringToBoolean = (value: string | boolean): boolean => {
      //   if (typeof value === "boolean") return value; // If already a boolean, return it
      //   return value === "true"; // Convert string "true" to boolean true
      // };

    // const rolePermission = Boolean(
    //     currentMenuItems && authUserRole.find(role =>
    //         role.pageUrl === currentMenuItems.url &&
    //         role.pageTitle === currentMenuItems.label &&
    //         // role.view === 'true'
    //         stringToBoolean(role.view)
    //     )
    // )
    // console.log("rolePermission",rolePermission);
    
    

    useEffect(() => {
        if (authendicated) {
            dispatch(fetchUsersAction())
            dispatch(fetchProductAction())
            dispatch(fetchBranchAction())
            dispatch(fetchRolesAction())
            dispatch(fetchStockMasterAction())
            dispatch(fetchAccountHolderAction())
            dispatch(fetchCategoryAction)
            dispatch(fetchClientRegistrationAction())
            dispatch(fetchShopAction())
            dispatch(fetchClientRegistrationAction())
        }
    }, [authendicated, dispatch])


    return <BrowserRouter>
        <Routes>
            <Route path='/' element={authendicated ? <MainHome /> : <Navigate to='/login' />} />

            <Route path='/mainHome' element={authendicated ? <MainHome /> : <Navigate to='/' />} />
            <Route path='/shop' element={authendicated ? <Shop /> : <Navigate to='/' />} />
            <Route path='/clientRegistration' element={authendicated ? <ClientRegistration /> : <Navigate to='/' />} />
            <Route path='/salesReturn/dashboard' element={authendicated ? <SalesReturnDashboard /> : <Navigate to='/' />} />
            <Route path='/purchase/dashboard' element={authendicated ? <PurchaseDashboard /> : <Navigate to='/' />} />
            <Route path='/purchaseReturn/dashboard' element={authendicated ? <PurchaseReturnDashboard /> : <Navigate to='/' />} />
            <Route path='/accountHolder' element={authendicated ? <AccountHolder /> : <Navigate to='/' />} />
            <Route path='/barcodeSticker' element={authendicated ? <BarcodeSticker /> : <Navigate to='/' />} />
            <Route path='/branchMaster' element={authendicated ? <BranchMaster /> : <Navigate to='/' />} />
            <Route path='/category' element={authendicated ? <Category /> : <Navigate to='/' />} />
            <Route path='/country' element={authendicated ? <Country /> : <Navigate to='/' />} />
            <Route path='/department' element={authendicated && authUserRole? <Department /> : <Navigate to='/' />} />
            <Route path='/district' element={authendicated ? <District /> : <Navigate to='/' />} />
            <Route path='/division' element={authendicated ? <Division /> : <Navigate to='/' />} />
            <Route path='/groupMaster' element={authendicated ? <GroupMaster /> : <Navigate to='/' />} />
            <Route path='/login' element={!authendicated ? <Login /> : <Navigate to='/' />} />
            <Route path='/logout' element={authendicated ? <Logout /> : <Navigate to='/' />} />
            <Route path='/brand' element={authendicated ? <Brand /> : <Navigate to='/' />} />
            <Route path='/place' element={authendicated ? <Place /> : <Navigate to='/' />} />
            <Route path='/productMaster' element={authendicated ? <ProductMaster /> : <Navigate to='/' />} />
            <Route path='/purchaseMaster' element={authendicated ? <PurchaseMaster From='Purchase' /> : <Navigate to='/' />} />
            <Route path='/openingStock' element={authendicated ? <PurchaseMaster From='OpeningStock' /> : <Navigate to='/' />} />
            <Route path='/purchaseReturn' element={authendicated ? <PurchaseReturnMaster /> : <Navigate to='/' />} />

            <Route path='/roles' element={authendicated ? <RoleManagement /> : <Navigate to='/' />} />
            <Route path='/route' element={authendicated ? <RouteMaster /> : <Navigate to='/' />} />
            <Route path='/state' element={authendicated ? <State /> : <Navigate to='/' />} />
            <Route path='/stockMaster' element={authendicated ? <StockMaster /> : <Navigate to='/' />} />
            <Route path='/supplierPlace' element={authendicated ? <SupplierPlace /> : <Navigate to='/' />} />
            <Route path='/transaction' element={authendicated ? < Transaction /> : <Navigate to='/' />} />
            <Route path='/userManagement' element={authendicated ? <UserManagement /> : <Navigate to='/' />} />
            <Route path='/unit' element={authendicated ? <Unit /> : <Navigate to='/' />} />
            <Route path='/salesMaster' element={authendicated ? <SalesMaster /> : <Navigate to='/' />} />
            <Route path='/salesReturnMaster' element={authendicated ? <SalesReturnMaster /> : <Navigate to='/' />} />
            <Route path='/report/purchaseReport' element={authendicated ? <PurchaseReport /> : <Navigate to='/' />} />
            <Route path='/report/salesReport' element={authendicated ? <SalesReport /> : <Navigate to='/' />} />
            <Route path='/report/salesReturnReport' element={authendicated ? <SalesReturnReports /> : <Navigate to='/' />} />
            <Route path='/report/gstReport' element={authendicated ? <GstReport /> : <Navigate to='/' />} />
            <Route path='/return/purchaseReturnReport' element={authendicated ? <PurchaseReturnReports /> : <Navigate to='/' />} />
            {/* <Route path='/purchaseReturnReport' element={authendicated ? <PurchaseReturnReport /> : <Navigate to='/' />} /> */}
            {/* <Route path='/salesReturnReport' element={authendicated ? <SalesReturnReport /> : <Navigate to='/' />} /> */}

            {/* <Route path='/purchaseCategoryReport' element={authendicated ? <PurchaseCategoryReport /> : <Navigate to='/' />} /> */}
            {/* <Route path='/salesCategoryReport' element={authendicated ? <SalesCategoryReport /> : <Navigate to='/' />} /> */}


            <Route path='/attributeMaster' element={authendicated ? <Attribute /> : <Navigate to='/' />} />
            <Route path='/appSettings' element={authendicated ? <AppSettings /> : <Navigate to='/' />} />
            <Route path='/admin' element={authendicated ? <AdminHome /> : <Navigate to='/admin/login' />} />
            <Route path='/purchaseAttribute' element={authendicated ? <PurchaseUpdateAttribute /> : <Navigate to='/' />} />
            <Route path='/resetPassword' element={authendicated ? <ResetPassword /> : <Navigate to='/' />} />
        </Routes>
    </BrowserRouter >
}

export default Router