import { useEffect, useState } from "react";
import { Card, Table, Button } from "react-bootstrap";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import ReportFilter from "./ReportFilter";
import api from '../../config/api';
import { getDate, LocalFormatDate, rupeeFormat } from "../../config/functions";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { ClientRegistrationType } from "../../redux/actions/clientRegistration";

interface PurchaseData {
    financialYear:string;
    productName: string;
    productCode: string;
    qty:number;
    netAmount:number;
}

interface PurchaseProductDateWise {
    financialYear:string;
    purchaseDate: string;
    purchaseData: PurchaseData[];
}

const PurchaseProductDateWise = () => {
    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [triggerSearch, setTriggerSearch] = useState<boolean>(false);
    const [tableData, setTableData] = useState<PurchaseProductDateWise[]>([]);
    const clients = useSelector<StoreState, ClientRegistrationType[]>(state => state.clientRegistration)
    
    useEffect(() => {
        if (triggerSearch) {
            const response = api().get(
                `reports/purchaseProductDateWise/?fromDate=${getDate(fromDate)}&toDate=${getDate(toDate)}`
            );

            response.then((res) => {
                if (res.status === 200 && res.data.data) {
                    console.log("API Response:", res.data);
                    setTableData(res.data.data);
                }
            }).catch(error => {
                console.error("Error fetching purchase data:", error);
            });

            setTriggerSearch(false);
        }
    }, [triggerSearch, fromDate, toDate]);
const [filters, setFilters] = useState<any>({});
    const handleSearch = (newFilters: any) => {
        setFromDate(newFilters.fromDate);
        setToDate(newFilters.toDate);
        setTriggerSearch(true);
    };
   
    const downloadCSV = () => {
        const header = [
            "SI.No", "Product Name",
            "Product Code", "Qty", "Net Amount"
        ];

        let csvContent = header.join(",") + "\n";

        tableData.forEach((item, index) => {
            csvContent += `\nFinancial Year: ${item.financialYear}\n`;
            csvContent += `\nPurchase Date: ${item.purchaseDate}\n`;
            // csvContent += header.join(",") + "\n";

            item.purchaseData.forEach((purchase, subIndex) => {
                csvContent += [
                    subIndex + 1,
                    purchase.productName,
                    purchase.productCode,
                    purchase.qty,
                    purchase.netAmount
                ].join(",") + "\n";
            });

        });

        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "purchase_product_date_report.csv";
        link.click();
    };

    const downloadPDF = () => {
        const logoUrl = clients[0]?.shopPhoto;
        const doc = new jsPDF("landscape");

        const marginX = 10;
        const boxWidth = doc.internal.pageSize.width - 2 * marginX;
        const boxHeight = 25;
        let startY = 15;
        
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        
        doc.rect(marginX, startY - 5, boxWidth, boxHeight);

        if (logoUrl) {
            doc.addImage(logoUrl, "JPEG", marginX + 5, startY - 2, 25, 20);
        }

        const paddingBetweenHeaderAndTable = 5;
        const textX = marginX + 35;

        doc.text(clients[0]?.shopShortName || "", textX, startY);
        doc.setFont("helvetica", "normal");
        doc.text(
            `${clients[0]?.address1 || ""}, ${clients[0]?.address2 || ""}, ${clients[0]?.district || ""}`,
            textX,
            startY + 5
        );
        doc.text(
            `${clients[0]?.state || ""} - ${clients[0]?.pincode || ""}, ${clients[0]?.contactNo1 || ""}`,
            textX,
            startY + 10
        );
        doc.text(`GSTIN: ${clients[0]?.gstNo || ""}`, textX, startY + 15);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        const heading = "Purchase product date wise report";
        const headingWidth = doc.getTextWidth(heading);
        const pageWidth = doc.internal.pageSize.width;
        doc.text(heading, marginX + boxWidth - headingWidth - 10, startY + 8);
            
         doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const financialYearText = `Financial Year: ${tableData[0]?.financialYear || ""}`;
        const headingStartX = marginX + boxWidth - headingWidth - 10;
        doc.text(financialYearText, headingStartX, startY + 13);
                
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        const formattedFromDate = "From : " + (LocalFormatDate(getDate(fromDate)) || "");
        const formattedToDate = "To : " + (LocalFormatDate(getDate(toDate)) || "");
        const tableStartY = 12 + boxHeight + paddingBetweenHeaderAndTable;
        doc.text(formattedFromDate, marginX, tableStartY);
        doc.text(formattedToDate, marginX + 50, tableStartY);
        
        
        let bodyData:any = []; 
        let serialNumber = 1;
    
        tableData.forEach((item) => {
            bodyData.push([
                {
                    content: `Purchase Date: ${LocalFormatDate(item.purchaseDate)}`,
                    colSpan: 14,
                    styles: { fontStyle: "bold", halign: "center" }
                }
            ]);
    
            bodyData.push(
                ...item.purchaseData?.map((purchase) => [
                    serialNumber++, 
                    purchase.productName,
                    purchase.productCode,
                    purchase.qty,
                    rupeeFormat(purchase.netAmount)
                ])
            );

        });
    
        autoTable(doc, {
            startY: startY + 32,
            head: [
                [
                    "SI.No","Product Name",
                    "Product Code", "Qty", "Net Amount"
                ]
            ],
            body: bodyData,
            theme: "grid",
            styles: { fontSize: 8, textColor: 0, lineColor: 0, lineWidth: 0.1 },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: 0,
                fontStyle: "bold",
                lineColor: 0,
                lineWidth: 0.1,
            },
            columnStyles: {
                0: { halign: "center" }, // SI.No (Center aligned)
                1: { halign: "center" }, // Date (Center aligned)
                2: { halign: "center" }, // Invoice Number (Center aligned)
                3: { halign: "center" },   // Supplier Name (Left aligned)
                4: { halign: "right" },// Net Total (Right aligned)
            },
            margin: { top: 25, left: 10, right: 10 }
        });
        
        // Footer Section (Page Number)
        const pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            let pageHeight = doc.internal.pageSize.height;
            let footerY = pageHeight - 15;

            doc.setFontSize(10);
            doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, footerY);
        }

        doc.save("purchase_product_date_wise_report.pdf");
    };
    
    return (
        <>
            <ReportFilter onSearch={handleSearch} />
            {tableData.length > 0 ? <Card className="mt-2 p-2 shadow-lg border-0 rounded-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5 className="fw-bold">Purchase product date wise details</h5>
                    <div>
                        <Button className="me-2 btn-sm" variant="success" onClick={downloadCSV}>
                            Download CSV
                        </Button>
                        <Button className="me-2 btn-sm" variant="success" onClick={downloadPDF}>
                            Download PDF
                        </Button>
                    </div>
                </div>
                {tableData.map((item, index) => (
                    <div key={index}>
                        <h6 className="mt-3">Purchase Date: {LocalFormatDate(item.purchaseDate)}</h6>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>SI.No</th>
                                    <th>Financial Year</th>
                                    <th>Product Name</th>
                                    <th>Product Code</th>
                                    <th>Quantity</th>
                                    <th>Net Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.purchaseData?.map((purchase, subIndex) => (
                                    <tr key={`${index}-${subIndex}`}>
                                        <td>{subIndex + 1}</td>
                                        <td>{item.financialYear}</td>
                                        <td>{purchase.productName}</td>
                                        <td>{purchase.productCode}</td>
                                        <td>{purchase.qty}</td>
                                        <td>{rupeeFormat(purchase.netAmount)}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                ))}
            </Card> : <h4 className="text-center fw-bold text-secondary">
                No such data found !
            </h4>
            }
        </>
    );
};

export default PurchaseProductDateWise;