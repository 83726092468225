import { Dispatch } from "redux"
import { camelize } from "../../config/functions";
import { addShop, deleteShop, editShop, getShop } from "../../services/shop.services";


export interface ShopType {
    id?: number;
    name?: string
    // imageUpload?:File
    // imageUrl?:any
}

export enum ShopActionList {
    ADD_SHOP = ' ADD_SHOP',
    EDIT_SHOP = 'EDIT_SHOP',
    FETCH_SHOP = ' FETCH_SHOP',
    DELETE_SHOP = 'DELETE_SHOP'
}

export interface AddShopAction {
    type: ShopActionList.ADD_SHOP;
    data: ShopType
}
export interface EditShopAction {
    type: ShopActionList.EDIT_SHOP;
    data: ShopType
    id: number | undefined
}

export interface FetchShopAction {
    type: ShopActionList.FETCH_SHOP;
    data: ShopType[]
}
export interface DeleteShopAction {
    type: ShopActionList.DELETE_SHOP;
    data: number|undefined
}

export type ShopAction = AddShopAction | EditShopAction | FetchShopAction | DeleteShopAction;


export const addShopAction = (data: FormData) => {
    return async (dispatch: Dispatch) => {
        return addShop(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddShopAction>({
                    type: ShopActionList.ADD_SHOP,
                    data: addData
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Shop Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })    
    }
}
export const editShopAction = (data: FormData, id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return editShop(data,id).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
                dispatch<EditShopAction>({
                    type: ShopActionList.EDIT_SHOP,
                    data: editData,
                    id: id
                })
        
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Shop Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}
export const fetchShopAction = () => {
    return async (dispatch: Dispatch) => {
        const response=await getShop()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchShopAction>({
            type: ShopActionList.FETCH_SHOP,
            data: data
        })
    }
}
export const deleteShopAction = (id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return deleteShop(id).then(response => {
            if (response.status === 200) {
                dispatch<DeleteShopAction>({
                    type: ShopActionList.DELETE_SHOP,
                    data:id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Shop Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}