import { Dispatch } from "redux";
import { camelize } from "../../config/functions";
import { addBrand, deleteBrand, editBrand, getBrand } from "../../services/brand.service";


export interface BrandType {
    id?: number
    companyId?: number
    shopId?: number
    branchId?: number
    name?: string
    imageUpload?: File
    imageUrl?: any

}

export enum BrandActionList {
    ADD_BRAND = 'ADD_BRAND',
    EDIT_BRAND = 'EDIT_BRAND',
    FETCH_BRAND = 'FETCH_BRAND',
    DELETE_BRAND = 'DELETE_BRAND'
}

export interface AddBrandAction {
    type: BrandActionList.ADD_BRAND;
    data: BrandType
}

export interface EditBrandAction {
    type: BrandActionList.EDIT_BRAND
    data: BrandType
    id: number | undefined
}

export interface FetchBrandAction {
    type: BrandActionList.FETCH_BRAND;
    data: BrandType[]

}
export interface DeleteBrandAction {
    type: BrandActionList.DELETE_BRAND
    data: number | undefined
}

export type BrandAction = AddBrandAction | EditBrandAction | FetchBrandAction | DeleteBrandAction;


export const addBrandAction = (data: FormData) => {
    return async (dispatch: Dispatch) => {
        return addBrand(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddBrandAction>({
                    type: BrandActionList.ADD_BRAND,
                    data: addData
                })
                return Promise.resolve({
                    id: response.data.data.id, 
                    message: response.data.message || 'Department Added'
                });
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const editBrandAction = (data: FormData, id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return editBrand(data, id).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
                dispatch<EditBrandAction>({
                    type: BrandActionList.EDIT_BRAND,
                    data: editData,
                    id: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Brand Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}


export const fetchBrandAction = () => {
    return async (dispatch: Dispatch) => {
        const response = await getBrand()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchBrandAction>({
            type: BrandActionList.FETCH_BRAND,
            data: data
        })
    }
}

export const deleteBrandAction = (id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return deleteBrand(id).then(response => {
            if (response.status === 200) {
                dispatch<DeleteBrandAction>({
                    type: BrandActionList.DELETE_BRAND,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Manufacturer Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}