import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
    ChangeEventHandler,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Button, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { UserPlus } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Clock from "../components/Clock";
import Modal from "../components/Modal";
import Option from "../components/Option";
import useOverlayLoader from "../components/OverlayLoader/useOverlayLoader";
import ProductSearch from "../components/ProductSearch";
import { Dropdown } from "../components/PurchaseForm";
import { FormInput } from "../components/custom/components";
import UserLayout from "../components/custom/layouts/UserLayout";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../config";
import api from "../config/api";
import {
    LocalFormatDate,
    convertAmtToWords,
    getDate,
    rupeeFormat,
} from "../config/functions";
import { AccountHolderType } from "../redux/actions/accountHolder";
import { AuthUserType } from "../redux/actions/authUserActions";
import { ClientRegistrationType } from "../redux/actions/clientRegistration";
import {
    CustomerType,
    addCustomerAction,
    fetchCustomerAction,
} from "../redux/actions/customerAction";
import {
    DropDownType,
    addDropDownAction,
    fetchDropDownAction,
} from "../redux/actions/dropdownAction";
import {
    SalesPaymentModeTypes,
    SalesPaymentModesDropDown,
    SalesType,
    addSalesAction,
    fetchSalesAction,
    updateSalesAction,
} from "../redux/actions/salesActions";
import {
    StockMasterType,
    fetchStockMasterAction,
} from "../redux/actions/stockMasterAction";
import { UsersType } from "../redux/actions/usersActions";
import { AppDispatch, StoreState } from "../redux/store";
import { getSalesById } from "../services/sales.service ";
import { getTransactionCurrentBalanceByAcNo } from "../services/transaction.service";
import Sales from "./Sales";

export interface SalesItem {
    id: number;
    productGroupCode: string;
    productCode: string;
    productName: Dropdown | null;
    gstPercent?: string;
    qty: string;
    mrp?: string;
    rate?: string;
    disAmt?: string;
    pdPercent?: number;
    pdAmount?: number;
    gstValue?: string;
    taxable?: string;
    totalAmt?: string;
    hsn?: string;
    batchNo?: string;
    expiryDt?: Date | null;
    pwog?: string;
    pwg?: string;
    // sRate1?: string
    // sRate2?: string
    // sRate3?: string
    // pdRs?: string
    // cdPercent?: string
    // cdRs?: string
    igst?: string;
    cgst?: string;
    sgst?: string;
    // netAmt?: string
    // unitProfit?: string
    // totalProfit?: string
    profitPercent?: string;
}

interface Product {
    id: number;
    productName: string;
    productCode: string;
    sac: string;
    gst: string;
}

interface Payments {
    cash?: number;
    card1?: number;
    card2?: number;
    online1?: number;
    online2?: number;
}

const SALSE_PAYMENT_MODES = [
    {
        text: "CASH",
        value: "Cash",
    },
    {
        text: "CREDIT",
        value: "Credit",
    },
    {
        text: "BANK",
        value: "Bank",
    },
];
const SALES_TYPE_DROPS = [
    {
        text: "SALES TAX INVOICE",
        value: "SALES TAX INVOICE",
    },
    {
        text: "SALES ESTIMATE",
        value: "SALES ESTIMATE",
    },
];

export const getDefaultSalesItems = () => {
    const salesItems: SalesItem[] = [];

    for (let i = 0; i < 100; i++) {
        salesItems.push({
            id: i + 1,
            productName: null,
            productGroupCode: "",
            productCode: "",
            batchNo: "",
            expiryDt: null,
            gstPercent: "",
            qty: "",
            mrp: "0",
            rate: "0",
            disAmt: "0",
            pdPercent: 0,
            pdAmount: 0,
            gstValue: "0",
            taxable: "0",
            totalAmt: "0",
            hsn: "",
            igst: "",
            cgst: "",
            sgst: "",
            pwg: "",
            pwog: "",
            profitPercent: "0",
        });
    }
    return salesItems;
};

export interface SalesItemEditAttributes {
    id: number;
    sales_id: number;
    entry_number: number;
    product_id: number;
    product_code: string;
    batch_number: number;
    expiry_date: string;
    quantity: number;
    free_quantity: number;
    total_quantity: number;
    price_wogst: number;
    price_gst: number;
    hsn: string;
    w_rate: number;
    r_rate: number;
    l_rate: number;
    mrp: number;
    gst_value: number;
    s_discount: number;
    p_discount_percent: number;
    p_discount_amount: number;
    c_discount_percent: number;
    c_discount_amount: number;
    taxable_amount: number;
    igst_amount: number;
    cgst_amount: number;
    sgst_amount: number;
    total_amount: number;
    unit_cost: number;
    profit_percent: number;
    profit_amount: number;
}

export interface SalesEditAttributes {
    id: number;
    finance_year: string;
    branch_id: number;
    invoice_type: string;
    invoice_title: string;
    invoice_date: string;
    invoice_day: number;
    invoice_week_number: number;
    invoice_week_day: number;
    invoice_month: number;
    invoice_year: number;
    invoice_number: number;
    sales_entry_number: number;
    system_no: string;
    rep_id: number;
    customer_id: number;
    bill_amount: string;
    dis_percent: string;
    dis_amount: string;
    total_amount: string;
    total_discount: string;
    prev_balance: string;
    credit: string;
    bank_received: string;
    add: string;
    less: string;
    grand_total: string;
    amt_in_words: string;
    particulars: string;
    amt_paid: string;
    payment_mode: string;
    delivery_mode: string;
    delivery_details: string;
    remarks: string;
    tender_return: number;
    tender_amount: number;
    net_taxable: string;
    net_igst: string;
    net_sgst: string;
    net_cgst: string;
    net_profit: string;
    net_profit_percent: string;
    A_value: number;
    A_taxable: number;
    B_value: number;
    B_taxable: number;
    C_value: number;
    C_taxable: number;
    D_value: number;
    D_taxable: number;
    E_value: number;
    E_taxable: number;
    F_value: number;
    F_taxable: number;
    G_value: number;
    G_taxable: number;
    createdAt?: string;
    created_by: number;
    split: string;
    salesItems: SalesItemEditAttributes[];
    Transactions?: any[];
}

export interface CDropdown {
    label: string;
    name: string;
    value: number;
    score: string;
    conNo: string;
}

const SalesMaster = () => {
    const toast = useToast();
    const [setShowOverlayLoader, setHideOverlayLoader] = useOverlayLoader();
    const dispatch = useDispatch<AppDispatch>();
    const AuthUser = useSelector<StoreState, AuthUserType | null>(
        (state) => state.authUser
    );
    const StockList = useSelector<StoreState, StockMasterType[]>(
        (state) => state.stockMaster
    );
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(
        (state) => state.account
    );
    const userList = useSelector<StoreState, UsersType[]>((state) => state.user);
    const DropDownList = useSelector<StoreState, DropDownType[]>(
        (state) => state.dropdown
    );
    const CustomerList = useSelector<StoreState, CustomerType[]>(
        (state) => state.customer
    );
    // const ProductList = useSelector<StoreState, ProductType[]>(state => state.product)
    const clients = useSelector<StoreState, ClientRegistrationType[]>(
        (state) => state.clientRegistration
    );
    const [discountValue, setDiscountValue] = useState<string>("0");

    useEffect(() => {
        dispatch(fetchDropDownAction());
        dispatch(fetchCustomerAction());
        dispatch(fetchStockMasterAction());
    }, []);

    const [financialYear, setFinancialYear] = useState<string>("");
    // const [financialYearError, setFinancialYearError] = useState<string>('')
    const [billNo, setBillNo] = useState<string>();
    // const [billNoError, setBillNoError] = useState<string>('')
    const [billTime, setBillTime] = useState<string>("");
    // const [billTimeError, setBillTimeError] = useState<string>('')
    const [gudsRecdDate, setGudsRecdDate] = useState<string>("");
    const [billDate, setBillDate] = useState<Date>(new Date());
    // const [billDateError, setBillDateError] = useState<string>('')
    // const [customerNo, setCustomerNo] = useState<string>('2')
    const [invoiceType, setInvoiceType] = useState<string>(
        SALES_TYPE_DROPS[0].value
    );
    const [invoiceTypeError, setInvoiceTypeError] = useState<string>("");
    const [invoiceTitle, setInvoiceTitle] = useState<string>("RETAIL");
    // const [invoiceTitleError, setInvoiceTitleError] = useState<string>('')

    const [invoiceAmount, setInvoiceAmount] = useState<string>("");
    const [salesEntryNo, setSalesEntryNo] = useState<string>(
        (Math.floor(Math.random() * 60000) + 10000).toString()
    );
    const [customer, setCustomer] = useState<CDropdown | null>(null);

    const [customerName, setCustomerName] = useState<string>("");
    const [customerNameError, setCustomerNameError] = useState<string>("");
    const [customerAddress, setCustomerAddress] = useState<string>("");
    // const [customerAddressError, setCustomerAddressError] = useState<string>('')
    const [customerContact, setCustomerContact] = useState<string>("");
    // const [customerContactError, setCustomerContactError] = useState<string>('')
    const [gst, setGST] = useState<string>("");
    const [gstError, setGSTError] = useState<string>("");

    const [refBy, setRefBy] = useState<string>("0");
    const [refByError, setRefByError] = useState<string>("");
    const [customerPlace, setCustomerPlace] = useState<string>("");
    // const [customerPlaceError, setCustomerPlaceError] = useState<string>('')
    const [branch, setBranch] = useState<string>("");
    // const [branchError, setBranchError] = useState<string>('')
    const [billedBy, setBilledBy] = useState<string>(
        AuthUser?.name ? AuthUser.name : ""
    );
    // const [billedByError, setBilledByError] = useState<string>('')
    const [sysNo, setSysNo] = useState<string>(window.location.hostname);
    // const [sysNoError, setSysNoError] = useState<string>('')

    // const [productName, setProductName] = useState<string>('')
    const [productNameError, setProductNameError] = useState<string>("");

    const [salesItems, setSalesItems] = useState<SalesItem[]>(
        getDefaultSalesItems()
    );
    // const authUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)

    // const [showSalesForm, setShowSalesForm] = useState<boolean>(false)
    const [showSalesView, setShowSalesView] = useState<boolean>(false);

    const [billAmount, setBillAmount] = useState<string>("");
    const [billAmountError, setBillAmountError] = useState<string>("");
    const [disPercent, setDisPercent] = useState<string>("");
    const [disPercentError, setDisPercentError] = useState<string>("");
    const [disAmt, setDisAmt] = useState<string>("0");
    const [disAmtError, setDisAmtError] = useState<string>("");
    const [totAmt, setTotAmt] = useState<string>("");
    const [totAmtError, setTotAmtError] = useState<string>("");
    const [billNetAmt, setBillNetAmt] = useState<string>("");
    const [billNetAmtError, setBillNetAmtError] = useState<string>("");
    const [add, setAdd] = useState<string>("0");
    const [addError, setAddError] = useState<string>("");
    const [less, setLess] = useState<string>("0");
    const [lessError, setLessError] = useState<string>("");
    const [netAmt, setNetAmt] = useState<string>("0");
    const [netAmtError, setNetAmtError] = useState<string>("");
    const [roundOff, setRoundOff] = useState<string>("");
    const [roundOffError, setRoundOffError] = useState<string>("");
    const [gtotal, setGTotal] = useState<string>("0");
    const [gtotalError, setGTotalError] = useState<string>("");
    const [amtInWords, setAmtInWords] = useState<string>("");
    const [amtInWordsError, setAmtInWordsError] = useState<string>("");
    const [paymentMode, setPaymentMode] = useState<SalesPaymentModeTypes>(
        SalesPaymentModesDropDown.CASH
    );
    const [paymentModeError, setPaymentModeError] = useState<string>("");
    const [paymentNo, setPaymentNo] = useState<string>("");
    const [paymentNoError, setPaymentNoError] = useState<string>("");
    const [amtPaid, setAmtPaid] = useState<string>("0");
    const [amtPaidError, setAmtPaidError] = useState<string>("");
    const [balance, setBalance] = useState<string>("");
    const [balanceError, setBalanceError] = useState<string>("");
    const [deliveryMode, setDeliveryMode] = useState<string>("");
    // const [deliveryModeError, setDeliveryModeError] = useState<string>('')
    const [deliveryDetails, setDeliveryDetails] = useState<string>("");
    // const [deliveryDetailsError, setDeliveryDetailsError] = useState<string>('')
    const [currentBal, setCurrentBal] = useState<string>("0");
    const [currentBalError, setCurrentBalError] = useState<string>("");
    const [previousBal, setPreviousBal] = useState<string>("0");
    const [previousBalError, setPreviousBalError] = useState<string>("");
    const [overAllBal, setOverAllBal] = useState<string>("0");
    const [overAllBalError, setOverAllBalError] = useState<string>("");
    const [bankName, setBankName] = useState<string>("");
    const [bankNumber, setBankNumber] = useState<string>("");

    const [totalItems, setTotalItems] = useState<string>("");
    const [totalItemsError, setTotalItemsError] = useState<string>("");
    const [totalQty, setTotalQty] = useState<string>("");
    const [totalQtyError, setTotalQtyError] = useState<string>("");
    const [packNo, setPackNo] = useState<string>("");
    const [packNoError, setPackNoError] = useState<string>("");
    const [yourSavings, setYourSavings] = useState<string>("");
    const [yourSavingsError, setYourSavingsError] = useState<string>("");
    const [netIgst, setNetIgst] = useState<string>("0");
    const [netIgstError, setNetIgstError] = useState<string>("");
    const [netSgst, setNetSgst] = useState<string>("");
    const [netSgstError, setNetSgstError] = useState<string>("");
    const [netCgst, setNetCgst] = useState<string>("");
    const [netCgstError, setNetCgstError] = useState<string>("");
    const [netTaxable, setNetTaxable] = useState<string>("");
    const [netTaxableError, setNetTaxableError] = useState<string>("");
    const [netGSTValue, setNetGSTValue] = useState<string>("");
    const [netGSTValueError, setNetGSTValueError] = useState<string>("");

    const [tenderedAmt, setTenderedAmt] = useState<string>("");
    const [tenderedAmtError, setTenderedAmtError] = useState<string>("");
    const [tenderedReturn, setTenderedReturn] = useState<string>("");
    const [tenderedReturnError, setTenderedReturnError] = useState<string>("");
    const [tenderedBal, setTenderedBal] = useState<string>("");
    const [tenderedBalError, setTenderedBalError] = useState<string>("");

    const [finalAmount, setFinalAmount] = useState<string>("0");
    const [totalProfit, setTotalProfit] = useState<string>("0");

    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
    const [id, setId] = useState<number>();

    const [modal, setModal] = useState(false);

    const [branchId, setBranchId] = useState<string>("");
    const [branchIdError, setBranchIdError] = useState<string>("");
    const [nameTag, setNameTag] = useState<string>("");
    const [nameTagError, setNameTagError] = useState<string>("");
    const [accountName, setAccountName] = useState<string>("");
    const [accountNameError, setAccountNameError] = useState<string>("");
    const [address1, setAddress1] = useState<string>("");
    const [address1Error, setAddress1Error] = useState<string>("");
    const [address2, setAddress2] = useState<string>("");
    const [address2Error, setAddress2Error] = useState<string>("");
    const [area, setArea] = useState<string>("");
    const [areaError, setAreaError] = useState<string>("");
    const [place, setPlace] = useState<string>("");
    const [placeError, setPlaceError] = useState<string>("");
    const [district, setDistrict] = useState<string>("");
    const [districtError, setDistrictError] = useState<string>("");
    const [pincode, setPincode] = useState<string | undefined>("");
    const [pincodeError, setPincodeError] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [stateError, setStateError] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [countryError, setCountryError] = useState<string>("");
    const [route, setRoute] = useState<string>("");
    const [routeError, setRouteError] = useState<string>("");
    const [contactNumber1, setContactNumber1] = useState<string | undefined>("");
    const [contactNumber1Error, setContactNumber1Error] = useState<string>("");
    const [contactNumber2, setContactNumber2] = useState<string | undefined>("");
    const [contactNumber2Error, setContactNumber2Error] = useState<string>("");
    const [aadharNumber, setAadharNumber] = useState<string | undefined>("");
    const [aadharNumberError, setAadharNumberError] = useState<string>("");
    const [gstNumber, setGstNumber] = useState<string | undefined>("");
    const [gstNumberError, setGstNumberError] = useState<string>("");

    const [hiddenGst, setHiddenGst] = useState<boolean>(true);

    const [areaName, setAreaName] = useState<string>("");
    const [areaModal, setAreaModal] = useState(false);

    const [placeName, setPlaceName] = useState<string>("");
    const [placeModal, setPlaceModal] = useState(false);

    const [routeName, setRouteName] = useState<string>("");
    const [routeModal, setRouteModal] = useState(false);

    const [districtName, setDistrictName] = useState<string>("");
    const [districtModal, setDistrictModal] = useState(false);

    const [stateName, setStateName] = useState<string>("");
    const [stateModal, setStateModal] = useState(false);

    const [countryName, setCountryName] = useState<string>("");
    const [countryModal, setCountryModal] = useState(false);

    const [previousPoints, setPreviousPoints] = useState<number>(0);
    const [pointsEarned, setPointsEarned] = useState<number>(
        Math.ceil(parseInt(finalAmount) / 100)
    );
    const [totalpoints, setTotalPoints] = useState<number>(0);
    const [isCustPointsRedeemed, setIsCustPointsRedeemed] =
        useState<boolean>(false);

    const [showTenderModal, setShowTenderModal] = useState(false);
    const [cash, setCash] = useState<string>("");
    const [card1, setCard1] = useState<string>("");
    const [card2, setCard2] = useState<string>("");
    const [onlinePayment1, setOnlinePayment1] = useState<string>("");
    const [onlinePayment2, setOnlinePayment2] = useState<string>("");
    const [split, setSplit] = useState<string>("");
    const [remark, setRemark] = useState<string>("");
    const [bankRecevied, setBankRecevied] = useState<string>("");
    const [ledgerBal, setLedgerBal] = useState<string>("");
    const [paymentMethod, setPaymentMethod] = useState<string>("");
    const [cashEditable, setCashEditable] = useState<boolean>(false);
    // const handleTimeChange = (newTime: string) => {
    //     setBillTime(newTime);
    // };
    const [TotalBillAmt, setTotalBillAmt] = useState<string>("0");
    const [TotalDisAmt, setTotalDisAmt] = useState<string>("0");

    //reset in ProductSearch
    const [resetSearch, setResetSearch] = useState<boolean>(false);

    //focus
    const [focusedField, setFocusedField] = useState<string | null>(null);

    const addRef = useRef<HTMLInputElement>(null);
    const lessRef = useRef<HTMLInputElement>(null);

    const tenderRef = useRef<any>(null);
    const cashRef = useRef<any>(null);
    const card1Ref = useRef<any>(null);
    const card2Ref = useRef<any>(null);
    const online1Ref = useRef<any>(null);
    const online2Ref = useRef<any>(null);

    const handleKeyDownForRight = <T extends Element>(
        e: React.KeyboardEvent<T>,
        nextRef: React.RefObject<
            | HTMLDivElement
            | HTMLInputElement
            | HTMLSelectElement
            | HTMLTextAreaElement
        >,
        fieldName: string
    ) => {
        if (e.key === "Enter" && nextRef.current) {
            // console.log('nextRef.current', nextRef.current);

            e.preventDefault();
            const input = nextRef.current.querySelector(
                "input, select, .react-select__input"
            );
            if (input) {
                (input as HTMLElement).focus();
            }
            setFocusedField(fieldName);
        }
    };

    const refs = useRef<{
        [key: string]: any;
    }>({});

    const handleKeyDown = (
        e: React.KeyboardEvent | React.MouseEvent,
        nextField: string,
        rowId: string,
        moveToNextRow: boolean = false
    ) => {
        const isKeyboardEvent = e.type === "keydown";
        const isMouseEvent = e.type === "click";

        // console.log("Is Keyboard Event:", isKeyboardEvent);
        // console.log("Is Mouse Event:", isMouseEvent);

        if (isKeyboardEvent && (e as React.KeyboardEvent).key === "Enter") {
            e.preventDefault();

            const currentRow = parseInt(rowId, 10);
            const targetRowId = moveToNextRow ? currentRow + 1 : currentRow;
            const nextRef = refs.current[`${nextField}-${targetRowId}`];
            console.log(targetRowId);
            console.log("nextRef", nextRef);
            console.log("focusedField", focusedField);

            if (nextRef) {
                if (nextRef.querySelector) {
                    const input = nextRef.querySelector(
                        "input, select, .react-select__input"
                    );
                    console.log("1");
                    if (input) {
                        console.log("12");
                        (input as HTMLElement).focus();
                    }
                } else if (nextRef.focus) {
                    console.log("2");
                    nextRef.focus();
                } else if (nextRef.select?.focus) {
                    console.log("3");
                    nextRef.select.focus();
                }

                setFocusedField(`${nextField}-${targetRowId}`);
            }
        } else if (isMouseEvent) {
            e.preventDefault();

            const currentRow = parseInt(rowId, 10);
            const targetRowId = moveToNextRow ? currentRow + 1 : currentRow;
            const nextRef = refs.current[`${nextField}-${targetRowId}`];

            if (nextRef) {
                if (nextRef.querySelector) {
                    const input = nextRef.querySelector(
                        "input, select, .react-select__input"
                    );
                    if (input) {
                        (input as HTMLElement).focus();
                    }
                } else if (nextRef.focus) {
                    nextRef.focus();
                } else if (nextRef.select?.focus) {
                    nextRef.select.focus();
                }

                setFocusedField(`${nextField}-${targetRowId}`);
            }
        }
    };

    useEffect(() => {
        const firstItemId = salesItems[0]?.id;

        if (firstItemId && refs.current[`productName-${firstItemId}`]) {
            const currentRef = refs.current[`productName-${firstItemId}`];

            if (currentRef && currentRef.focus) {
                // currentRef.focus();
                setFocusedField(`productName-${firstItemId}`);
            }
        }
    }, [salesItems]);

    useEffect(() => {
        let total = 0;
        let disTotal = 0;
        salesItems
            ?.filter((f) => {
                return (
                    f.productName?.label !== "" &&
                    f.productName?.label !== undefined &&
                    f.productName?.value !== "" &&
                    f.productName?.value !== undefined
                );
            })
            .map((si) => {
                total += parseFloat(si.totalAmt ? si.totalAmt : "0");
                disTotal += si.pdAmount ? si.pdAmount : 0;
            });
        setTotalBillAmt(total?.toFixed(2));
        setTotalDisAmt(disTotal?.toFixed(2));
    }, [salesItems]);

    useEffect(() => {
        let defaultCustomer = CustomerList?.find(
            (a) => a.accountName?.toLowerCase() === "cash sales"
        );
        if (defaultCustomer) {
            let CusObj = {
                label:
                    defaultCustomer?.accountName! +
                    "-" +
                    defaultCustomer?.contactNumber1 || "",
                name: defaultCustomer?.accountName ? defaultCustomer?.accountName : "",
                value: defaultCustomer?.accountNumber || 0,
                score: defaultCustomer?.score
                    ? defaultCustomer?.score?.toString()
                    : "0",
                conNo: defaultCustomer?.contactNumber1
                    ? defaultCustomer?.contactNumber1?.toString()
                    : "",
            };
            setCustomer(CusObj);
            setCustomerName(
                defaultCustomer?.accountName ? defaultCustomer.accountName : ""
            );
            setCustomerContact(defaultCustomer?.contactNumber1?.toString()!);
            setPreviousPoints(
                defaultCustomer?.score ? parseInt(defaultCustomer?.score!) : 0
            );
        }
        let defaultRepresenter = AccountLists?.find(
            (al) =>
                al.groupName?.toLowerCase() === "representer" &&
                al.accountName?.toLowerCase() === "self"
        );
        setRefBy(defaultRepresenter?.accountNumber?.toString()!);

        let defaultBranch = AuthUser?.branchId;
        setBranchId(defaultBranch ? defaultBranch?.toString()! : "");
        let defAddress = clients?.find(
            (cl) => cl.id?.toString() === AuthUser?.branchId?.toString()
        );
        let defPlace = DropDownList?.filter(
            (ddl: DropDownType) => ddl.category === "place"
        )
            ?.find(
                (ddl: DropDownType) =>
                    ddl.title?.toLowerCase() === defAddress?.place?.toLowerCase()
            )
            ?.id?.toString();
        let defDistrict = DropDownList?.filter(
            (ddl: DropDownType) => ddl.category === "district"
        )
            ?.find(
                (ddl: DropDownType) =>
                    ddl.title?.toLowerCase() === defAddress?.district?.toLowerCase()
            )
            ?.id?.toString();
        let defState = DropDownList?.filter(
            (ddl: DropDownType) => ddl.category === "state"
        )
            ?.find(
                (ddl: DropDownType) =>
                    ddl.title?.toLowerCase() === defAddress?.state?.toLowerCase()
            )
            ?.id?.toString();
        // setArea(defAddress?.place)
        console.log(defaultBranch);
        console.log(defPlace);
        console.log(defDistrict);
        console.log(defState);

        setPlace(defPlace ? defPlace : "");
        setDistrict(defDistrict ? defDistrict : "");
        setState(defState ? defState : "");
        setCountry("India");
    }, [CustomerList, AccountLists, AuthUser, clients, DropDownList]);

    const resetStates = () => {
        fetchRecentSalesId();
        setFormType(FormTypeList.ADD);
        setSalesItems(getDefaultSalesItems());
        // setPreviousPoints(0)
        setPointsEarned(0);
        setTotalPoints(0);
        setDiscountValue("");
        // setPackNo()
        setInvoiceType(SALES_TYPE_DROPS[0].value);
        setTenderedAmt("");
        setTenderedReturn("");
        setTenderedBal("");
        //top
        setGudsRecdDate("");
        setBillNo("");
        setBillTime("");
        setBillDate(new Date());
        // setCustomerNo('')
        setInvoiceAmount("");
        setSalesEntryNo("");
        // setCustomer(null)
        // setCustomerName('')
        // setCustomerAddress('')
        // setCustomerContact('')
        setGST("");
        // setRefBy('')
        setCustomerPlace("");
        setBranch("");
        setBilledBy("");
        setSysNo(window.location.hostname);
        //
        setBillAmount("");
        setBillAmountError("");
        setDisPercent("");
        setDisPercentError("");
        setDisAmt("0");
        setDisAmtError("");
        setTotAmt("");
        setTotAmtError("");
        setRoundOff("0");
        setRoundOffError("");
        setBillNetAmt("");
        setBillNetAmtError("");
        setAdd("0");
        setAddError("");
        setLess("0");
        setLessError("");
        setNetAmt("0");
        setNetAmtError("");
        setGTotal("0");
        setGTotalError("");
        setAmtInWords("");
        setAmtInWordsError("");
        setPaymentMode(SalesPaymentModesDropDown.CASH);
        setPaymentModeError("");
        setPaymentNo("");
        setPaymentNoError("");
        setAmtPaid("0");
        setAmtPaidError("");
        setCurrentBal("0");
        setCurrentBalError("");
        setPreviousBal("0");
        setPreviousBalError("");
        setOverAllBal("0");
        setOverAllBalError("");
        setBankName("");
        setBankNumber("");
        setTenderedAmt("0");
        setTenderedAmtError("");
        setTenderedBal("0");
        setTenderedBalError("");
        setTotalItems("");
        setTotalItemsError("");
        setTotalQty("");
        setTotalQtyError("");
        setFinalAmount("0");
        // setBranchId('')
        setNameTag("");
        setAccountName("");
        setContactNumber1("");
        setContactNumber2("");
        setAddress1("");
        setAddress2("");
        setArea("");
        // setPlace('')
        // setDistrict('')
        // setState('')
        // setCountry('')
        setRoute("");
        setAadharNumber("");
        setGstNumber("");
        setAreaName("");
        setPlaceName("");
        setRouteName("");
        setDistrictName("");
        setStateName("");
        setCountryName("");

        setCash("");
        setCard1("");
        setCard2("");
        setOnlinePayment1("");
        setOnlinePayment2("");
        setSplit("");
        setRemark("");
        setShowTenderModal(false);

        // reset the product search
        setResetSearch(true);
    };

    const resetBill = () => {
        setCashEditable(false);
        setTenderedAmt("");
        setCash("");
        setCard1("");
        setCard2("");
        setOnlinePayment1("");
        setOnlinePayment2("");
        setSplit("No");
        setRemark("");
    };

    const handleRefBy: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRefBy(e.target.value);
        setRefByError("");
    };

    const handleInvoiceType: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInvoiceType(e.target.value);
        setInvoiceTypeError("");
    };

    // const handleProductName = (val: Dropdown | null, id: string) => {
    //     // console.log(val);
    //     // console.log(id);
    //     // const prodObj = ProductList.find(pd => pd.id?.toString() === val?.value?.toString()
    //     const stockObj = StockList.find(pd => pd.id?.toString() === val?.value?.toString()
    //     )

    //     const values: string[] | undefined = val?.label.split("-") || [''];

    //     let updated = [...salesItems]
    //     let ind = updated.findIndex(pi => pi.id.toString() === id)
    //     const existSalesItem = updated.findIndex(pi => pi.productName?.value === val?.value)
    //     if (existSalesItem > -1 && val !== null) {
    //         let qty = parseInt(updated[existSalesItem]?.qty) + 1;
    //         updated[existSalesItem].qty = qty.toString();
    //         let gval = (((qty ? parseFloat(qty.toString()) : 0) * (updated[existSalesItem]?.rate ? parseFloat(updated[existSalesItem]?.rate!) : 0)) - (updated[existSalesItem].disAmt ? parseFloat(updated[existSalesItem]?.disAmt!) : 0)) * ((updated[existSalesItem].gstPercent ? parseFloat(updated[existSalesItem]?.gstPercent!) : 0) / (100 + (updated[existSalesItem].gstPercent ? parseFloat(updated[existSalesItem]?.gstPercent!) : 0)))
    //         updated[existSalesItem].gstValue = gval?.toFixed(2)

    //         //TAXABLE((qty*rate) - disc) - GST value
    //         let tax = (((qty ? parseFloat(qty.toString()) : 0) * (updated[existSalesItem].rate ? parseFloat(updated[existSalesItem].rate!) : 0)) - (updated[existSalesItem].disAmt ? parseFloat(updated[existSalesItem].disAmt!) : 0)) - gval
    //         updated[existSalesItem].taxable = tax?.toFixed(2)

    //         ///TOTAL gst val+tax
    //         let tot = gval + tax
    //         updated[existSalesItem].totalAmt = tot?.toFixed(2)
    //         setSalesItems(updated)
    //         return
    //     }

    //     if (ind > -1) {
    //         if (val === null) {
    //             updated[ind] = {
    //                 ...updated[ind],
    //                 productName: null,
    //                 productCode: '',
    //                 mrp: '0',
    //                 rate: '0',
    //                 qty: '',
    //                 disAmt: '0',
    //                 pdPercent: 0,
    //                 pdAmount: 0,
    //                 totalAmt: '0.00'
    //             };
    //             setSalesItems(updated);
    //             setProductNameError('');
    //             return;
    //         }
    //         updated[ind].productName = val
    //         updated[ind].productCode = stockObj?.productCode ? stockObj?.productCode.toString() : ''
    //         updated[ind].productGroupCode = stockObj?.productGroupCode ? stockObj?.productGroupCode.toString() : ''
    //         // updated[ind].hsn = stockObj?.sac ? stockObj?.sac.toString() : ''
    //         updated[ind].gstPercent = stockObj?.gst ? stockObj?.gst.toString() : ''
    //         updated[ind].qty = '1'
    //         updated[ind].mrp = stockObj?.mrp ? stockObj?.mrp.toString() : ''
    //         updated[ind].rate = stockObj?.lRate ? stockObj?.lRate.toString() : ''
    //         updated[ind].hsn = stockObj?.sac ? stockObj?.sac.toString() : ''

    //         updated[ind].batchNo = stockObj?.batchNumber ? stockObj?.batchNumber.toString() : ''
    //         updated[ind].expiryDt = stockObj?.expiryDate ? new Date(stockObj?.expiryDate) : null
    //         updated[ind].igst = stockObj?.igstAmount ? stockObj?.igstAmount.toString() : ''
    //         updated[ind].cgst = stockObj?.cgstAmount ? stockObj?.cgstAmount.toString() : ''
    //         updated[ind].sgst = stockObj?.sgstAmount ? stockObj?.sgstAmount.toString() : ''
    //         updated[ind].pwog = stockObj?.priceWogst ? stockObj?.priceWogst.toString() : ''
    //         updated[ind].pwg = stockObj?.priceGst ? stockObj?.priceGst.toString() : ''

    //         //for unitprofit
    //         let uprofit = (stockObj?.wRate ? (stockObj?.wRate) : 0) - (stockObj?.priceWogst ? (stockObj?.priceWogst) : 0)
    //         // updated[ind].unitProfit = uprofit.toString()

    //         //for profit %
    //         let profitPercent = ((uprofit ? uprofit : 0) / (stockObj?.priceWogst ? (stockObj?.priceWogst) : 0)) * 100
    //         updated[ind].profitPercent = profitPercent.toFixed(2)

    //         //GST VALUE ((qty  rate) - disc )   GST/(100 + GST)
    //         let gval = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0)) - (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0)) * ((stockObj?.gst ? parseFloat(stockObj?.gst.toString()) : 0) / (100 + (stockObj?.gst ? parseFloat(stockObj?.gst.toString()) : 0)))
    //         updated[ind].gstValue = gval?.toFixed(2)

    //         //TAXABLE((qty*rate) - disc) - GST value
    //         let tax = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0)) - (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0)) - gval
    //         updated[ind].taxable = tax?.toFixed(2)

    //         ///TOTAL gstval +tax
    //         let tot = gval + tax
    //         updated[ind].totalAmt = tot?.toFixed(2)
    //         setSalesItems(updated)

    //         //Disount =totalAmount-rateAmount
    //         let dis = ((stockObj?.mrp ? stockObj?.mrp : 0) - (stockObj?.lRate ? stockObj?.lRate : 0))
    //         updated[ind].disAmt = dis?.toFixed(2)
    //     }
    //     if (val === null) {
    //         // updated[ind].expiryDt = null
    //         updated[ind].qty = ''
    //     }
    //     setSalesItems(updated)
    //     setProductNameError('')
    // }

    const handleProductName = (
        val: Product | null,
        id: number, // Change `id` to `number` to match the expected signature
        field: string,
        value: string
    ) => {
        // Find the stock object using the product ID
        const stockObj = StockList.find(
            (pd) => pd.productCode === val?.productCode
        );

        // Extract product details from the `Product` object
        let productName = val
            ? `${val.productName}-${val.productCode}-${val.sac}-${val.gst}`
            : "";


        let updated = [...salesItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id.toString());

        // Check if the product already exists in the sales items
        const existSalesItem = updated.findIndex(
            (pi) => pi.productName?.value === val?.id?.toString()
        );

        if (existSalesItem > -1 && val !== null) {
            updated[ind].productName = { label: "", value: "" };
            let qty = parseInt(updated[existSalesItem]?.qty) + 1;

            updated[existSalesItem].qty = qty.toString();

            // Calculate GST value
            let gval =
                ((qty ? parseFloat(qty.toString()) : 0) *
                    (updated[existSalesItem]?.rate
                        ? parseFloat(updated[existSalesItem]?.rate!)
                        : 0) -
                    (updated[existSalesItem].disAmt
                        ? parseFloat(updated[existSalesItem]?.disAmt!)
                        : 0)) *
                ((updated[existSalesItem].gstPercent
                    ? parseFloat(updated[existSalesItem]?.gstPercent!)
                    : 0) /
                    (100 +
                        (updated[existSalesItem].gstPercent
                            ? parseFloat(updated[existSalesItem]?.gstPercent!)
                            : 0)));
            updated[existSalesItem].gstValue = gval?.toFixed(2);

            // Calculate taxable amount
            let tax =
                (qty ? parseFloat(qty.toString()) : 0) *
                (updated[existSalesItem].rate
                    ? parseFloat(updated[existSalesItem].rate!)
                    : 0) -
                (updated[existSalesItem].disAmt
                    ? parseFloat(updated[existSalesItem].disAmt!)
                    : 0) -
                gval;
            updated[existSalesItem].taxable = tax?.toFixed(2);

            // Calculate total amount
            let tot = gval + tax;
            updated[existSalesItem].totalAmt = tot?.toFixed(2);

            setSalesItems(updated);
            return;
        }

        if (ind > -1) {
            if (val === null) {
                // Clear the product details if `val` is null
                updated[ind] = {
                    ...updated[ind],
                    productName: null,
                    productCode: "",
                    mrp: "0",
                    rate: "0",
                    qty: "",
                    disAmt: "0",
                    pdPercent: 0,
                    pdAmount: 0,
                    totalAmt: "0.00",
                };
                setSalesItems(updated);
                setProductNameError("");
                return;
            }

            // Convert the `Product` object to a `Dropdown` object
            updated[ind].productName = {
                label: productName,
                value: val.id.toString(),
            };

            // Update other fields
            updated[ind].productCode = stockObj?.productCode
                ? stockObj.productCode.toString()
                : "";
            updated[ind].productGroupCode = stockObj?.productGroupCode
                ? stockObj.productGroupCode.toString()
                : "";
            updated[ind].gstPercent = stockObj?.gst ? stockObj.gst.toString() : "";
            updated[ind].qty = "1";
            updated[ind].mrp = stockObj?.mrp ? stockObj.mrp.toString() : "";
            updated[ind].rate = stockObj?.lRate ? stockObj.lRate.toString() : "";
            updated[ind].hsn = stockObj?.sac ? stockObj.sac.toString() : "";
            updated[ind].batchNo = stockObj?.batchNumber
                ? stockObj.batchNumber.toString()
                : "";
            updated[ind].expiryDt = stockObj?.expiryDate
                ? new Date(stockObj.expiryDate)
                : null;
            updated[ind].igst = stockObj?.igstAmount
                ? stockObj.igstAmount.toString()
                : "";
            updated[ind].cgst = stockObj?.cgstAmount
                ? stockObj.cgstAmount.toString()
                : "";
            updated[ind].sgst = stockObj?.sgstAmount
                ? stockObj.sgstAmount.toString()
                : "";
            updated[ind].pwog = stockObj?.priceWogst
                ? stockObj.priceWogst.toString()
                : "";
            updated[ind].pwg = stockObj?.priceGst ? stockObj.priceGst.toString() : "";

            // Calculate unit profit
            let uprofit =
                (stockObj?.wRate ? stockObj.wRate : 0) -
                (stockObj?.priceWogst ? stockObj.priceWogst : 0);
            updated[ind].profitPercent = (
                ((uprofit ? uprofit : 0) /
                    (stockObj?.priceWogst ? stockObj.priceWogst : 1)) *
                100
            ).toFixed(2);

            // Calculate GST value
            let gval =
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) *
                    (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0) -
                    (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0)) *
                ((stockObj?.gst ? parseFloat(stockObj.gst.toString()) : 0) /
                    (100 + (stockObj?.gst ? parseFloat(stockObj.gst.toString()) : 0)));
            updated[ind].gstValue = gval?.toFixed(2);

            // Calculate taxable amount
            let tax =
                (updated[ind].qty ? parseFloat(updated[ind].qty) : 0) *
                (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0) -
                (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0) -
                gval;
            updated[ind].taxable = tax?.toFixed(2);

            // Calculate total amount
            let tot = gval + tax;
            updated[ind].totalAmt = tot?.toFixed(2);

            // Calculate discount
            let dis =
                (stockObj?.mrp ? stockObj.mrp : 0) -
                (stockObj?.lRate ? stockObj.lRate : 0);
            updated[ind].disAmt = dis?.toFixed(2);
        }

        if (val === null) {
            updated[ind].qty = "";
        }

        setSalesItems(updated);
        setProductNameError("");
    };

    const handleProductCode = (value: string, id: string) => {
        // console.log(value);
        // console.log(id);
        let updated = [...salesItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].productCode = value;
        }
        setSalesItems(updated);
    };
    const handleGst = (value: string, id: string) => {
        let updated = [...salesItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].gstPercent = value;
        }
        setSalesItems(updated);
    };

    const handleQty = (value: string, id: string) => {
        // To reset redeem points
        if (isCustPointsRedeemed) {
            let cObj = CustomerList?.find(
                (c) => c.accountNumber?.toString() === customer?.value?.toString()
            );
            let cpoints = cObj?.score ? parseInt(cObj?.score) : 0;
            let amt = parseFloat(finalAmount) + cpoints;
            setFinalAmount(amt?.toString());
            setPreviousPoints(cpoints);
            setIsCustPointsRedeemed(false);
        }

        let updated = [...salesItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);

        if (ind > -1) {
            // const stockItem = StockList.find(stock => stock.productCode === updated[ind].productCode);
            // if (!stockItem) {
            //     toast("Product code not found in stock masters", ToastTypes.ERROR);
            //     return;
            // }
            const enteredQty = value ? parseFloat(value) : 0;
            // const stockQty = stockItem.quantity;
            // if (enteredQty > stockQty) {
            //     toast("Entered quantity is greater than available stock quantity", ToastTypes.ERROR);
            //     return;
            // }

            updated[ind].qty = value;

            // GST VALUE ((qty * rate) - disc ) GST / (100 + GST)
            let gval =
                ((enteredQty ? enteredQty : 0) *
                    (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0) -
                    (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0)) *
                ((updated[ind].gstPercent ? parseFloat(updated[ind].gstPercent!) : 0) /
                    (100 +
                        (updated[ind].gstPercent
                            ? parseFloat(updated[ind].gstPercent!)
                            : 0)));
            updated[ind].gstValue = gval?.toFixed(2);

            // TAXABLE ((qty * rate) - disc) - GST value
            let tax =
                (enteredQty ? enteredQty : 0) *
                (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0) -
                (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0) -
                gval;
            updated[ind].taxable = tax?.toFixed(2);

            // TOTAL gst val + tax
            let tot = gval + tax;
            updated[ind].totalAmt = tot?.toFixed(2);

            setSalesItems(updated);
        }
    };

    const handleDiscountAmt = (value: string, id: string) => {
        let updated = [...salesItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].disAmt = value;
            //GST VALUE ((qty  rate) - disc )   GST/(100 + GST)
            let gval =
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) *
                    (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0) -
                    (value ? parseFloat(value) : 0)) *
                ((updated[ind].gstPercent ? parseFloat(updated[ind].gstPercent!) : 0) /
                    (100 +
                        (updated[ind].gstPercent
                            ? parseFloat(updated[ind].gstPercent!)
                            : 0)));
            updated[ind].gstValue = gval?.toFixed(2);

            //TAXABLE((qty*rate) - disc) - GST value
            let tax =
                (updated[ind].qty ? parseFloat(updated[ind].qty) : 0) *
                (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0) -
                (value ? parseFloat(value) : 0) -
                gval;
            updated[ind].taxable = tax?.toFixed(2);

            ///TOTAL gst val+tax
            let tot = gval + tax;
            updated[ind].totalAmt = tot?.toFixed(2);
        }
        setSalesItems(updated);
    };

    const recalculateTotals = (items: SalesItem[]) => {
        const newGrandTotal = items.reduce((total, item) => {
            const itemTotal =
                parseFloat(item.rate || "0") * parseFloat(item.qty || "0");
            const discountAmount = item.pdAmount || 0; // Use the individual discount amount
            return total + (itemTotal - discountAmount);
        }, 0);

        // const newAmountPaid = newGrandTotal;

        // Update the state
        setNetAmt(newGrandTotal.toFixed(2));
        setGTotal(Math.round(newGrandTotal)?.toString());
        // setAmtPaid(newAmountPaid.toFixed(2));
    };

    const handlePDPercentChange = (value: string, id: string) => {
        const updated = [...salesItems];
        const index = updated.findIndex((item) => item.id.toString() === id);
        if (index > -1) {
            updated[index].pdPercent = parseFloat(parseFloat(value).toFixed(4));
            updated[index].pdAmount =
                (parseFloat(value) / 100) * parseFloat(updated[index].rate || "1");
        }
        setSalesItems(updated);
        recalculateTotals(updated);
    };

    const handlePDAmountChange = (value: string, id: string) => {
        const updated = [...salesItems];
        const index = updated.findIndex((item) => item.id.toString() === id);
        if (index > -1) {
            updated[index].pdAmount = parseFloat(parseFloat(value).toFixed(4));
            updated[index].pdPercent =
                (parseFloat(value) / parseFloat(updated[index].rate || "1")) * 100;
        }
        setSalesItems(updated);
        recalculateTotals(updated);
    };

    const applyGeneralDiscount = () => {
        const discountPercent = parseFloat(discountValue);
        const updated = salesItems.map((item) => {
            if (item.productName) {
                const pDiscountAmount =
                    (discountPercent / 100) * parseFloat(item.rate || "0");
                return {
                    ...item,
                    pdPercent: discountPercent,
                    pdAmount: parseFloat(pDiscountAmount.toFixed(4)), // Ensure type consistency
                };
            }
            return item;
        });

        setSalesItems(updated);
        recalculateTotals(updated);
    };

    useEffect(() => {
        applyGeneralDiscount();
    }, [discountValue]);

    const handleRate = (value: string, id: string) => {
        let updated = [...salesItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].rate = value;
            //GST VALUE ((qty  rate) - disc )   GST/(100 + GST)
            let gval =
                ((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) *
                    (value ? parseFloat(value) : 0) -
                    (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0)) *
                ((updated[ind].gstPercent ? parseFloat(updated[ind].gstPercent!) : 0) /
                    (100 +
                        (updated[ind].gstPercent
                            ? parseFloat(updated[ind].gstPercent!)
                            : 0)));
            updated[ind].gstValue = gval?.toFixed(2);

            //TAXABLE((qty*rate) - disc) - GST value
            let tax =
                (updated[ind].qty ? parseFloat(updated[ind].qty) : 0) *
                (value ? parseFloat(value) : 0) -
                (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0) -
                gval;
            updated[ind].taxable = tax?.toFixed(2);

            //Disount =totalAmount-rateAmount
            let dis =
                (updated[ind].mrp ? parseFloat(updated[ind].mrp!) : 0) -
                (value ? parseFloat(value) : 0);
            updated[ind].disAmt = dis?.toFixed(2);

            ///TOTAL gst val+tax
            let tot = gval + tax;
            updated[ind].totalAmt = tot?.toFixed(2);
        }
        setSalesItems(updated);
    };

    const handleGSTValue = (value: string, id: string) => {
        let updated = [...salesItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].gstValue = value;
        }
        setSalesItems(updated);
    };

    const handleTaxable = (value: string, id: string) => {
        let updated = [...salesItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].taxable = value;
        }
        setSalesItems(updated);
    };

    const handleTotAmt = (value: string, id: string) => {
        let updated = [...salesItems];
        let ind = updated.findIndex((pi) => pi.id.toString() === id);
        if (ind > -1) {
            updated[ind].totalAmt = value;
        }
        setSalesItems(updated);
    };

    //Bill side
    const handlePaymentMode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentMode(e.target.value as SalesPaymentModeTypes);
        setPaymentModeError("");
    };

    const handleBank: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBankNumber(e.target.value);
        if (e.target.value) {
            let obj = AccountLists.find(
                (a) => a.accountNumber?.toString() === e.target.value.toString()
            );
            setBankName(obj?.accountName ? obj?.accountName : "");
        }
    };
    const handleAmtPaid: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAmtPaid(e.target.value);
        setAmtPaidError("");
    };
    const handleBalance: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBalance(e.target.value);
        setBalanceError("");
    };
    const handleDisAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDisAmt(e.target.value);
        setDisAmtError("");
    };

    // const handleCustomerContact: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setCustomer(e.target.value)
    //     // setCustomerError('')
    // }
    const handleTotalAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotAmt(e.target.value);
        setTotAmtError("");
    };
    const handleBillAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBillAmount(e.target.value);
        setBillAmountError("");
    };
    const handleTotalItems: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotalItems(e.target.value);
        setTotalItemsError("");
    };
    const handlePackNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPackNo(e.target.value);
        setPackNoError("");
    };
    const handleNetTaxable: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNetTaxable(e.target.value);
        setNetTaxableError("");
    };
    const handleNetGST: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNetGSTValue(e.target.value);
        setNetGSTValueError("");
    };

    const handleYourSavings: ChangeEventHandler<HTMLInputElement> = (e) => {
        setYourSavings(e.target.value);
        setYourSavingsError("");
    };
    const handleNetIgst: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNetIgst(e.target.value);
        setNetIgstError("");
    };
    const handleNetCgst: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNetCgst(e.target.value);
        setNetCgstError("");
    };
    const handleNetSgst: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNetSgst(e.target.value);
        setNetSgstError("");
    };
    const handleTotalQty: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotalQty(e.target.value);
        setTotalQtyError("");
    };
    const handleBillNetAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBillNetAmt(e.target.value);
        setBillNetAmtError("");
    };
    const handleNetAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNetAmt(e.target.value);
        setNetAmtError("");
    };
    const handleRoundOff: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRoundOff(e.target.value);
        setRoundOffError("");
    };

    const handleAdd: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAdd(e.target.value);
        setAddError("");
        setNetAmt((prev) =>
            e.target.value
                ? (parseFloat(prev) + parseFloat(e.target.value)).toString()
                : prev
        );
        // setFinalAmount(prev => e.target.value ? (parseFloat(prev) + parseFloat(e.target.value)).toString() : prev)
    };
    const handleLess: ChangeEventHandler<HTMLInputElement> = (e) => {
        setLess(e.target.value);
        setLessError("");
        setNetAmt((prev) =>
            e.target.value
                ? (parseFloat(prev) + parseFloat(e.target.value)).toString()
                : prev
        );
        // setFinalAmount(prev => e.target.value ? (parseFloat(prev) - parseFloat(e.target.value)).toString() : prev)
    };
    const handleGrandTotal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGTotal(e.target.value);
        setGTotalError("");
    };
    const handleTenderedAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTenderedAmt(e.target.value);
        setTenderedAmtError("");
    };
    const handleTenderedBal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTenderedBal(e.target.value);
        setTenderedBalError("");
    };

    const handleTenderedReturn: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTenderedReturn(e.target.value);
        setTenderedReturnError("");
    };
    // const handleCurrentBal: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setCurrentBal(e.target.value)
    //     setCurrentBalError('')
    // }
    // const handlePreviousBal: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setPreviousBal(e.target.value)
    //     setPreviousBalError('')
    // }
    // const handleOverAllBal: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setOverAllBal(e.target.value)
    //     setOverAllBalError('')
    // }
    //
    // const handleInvoiceTypeClick = () => {
    //     setShowSalesForm(true)
    // }
    const handleView = () => {
        setShowSalesView(true);
    };

    const handleModalClose = () => {
        setAreaModal(false);
        setPlaceModal(false);
        setDistrictModal(false);
        setStateModal(false);
        setCountryModal(false);
        setRouteModal(false);
    };

    const handleBranch: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBranchId(e.target.value);
        setBranchIdError("");
    };

    const handleTag: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNameTag(e.target.value);
        setNameTagError("");
    };

    const handleAccountName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAccountName(e.target.value);
        setAccountNameError("");
    };

    const handleAddress1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress1(e.target.value);
        setAddress1Error("");
    };

    const handleAddress2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress2(e.target.value);
        setAddress2Error("");
    };

    const handleArea: ChangeEventHandler<HTMLInputElement> = (e) => {
        setArea(e.target.value);
        setAreaError("");
    };

    const handlePlace: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPlace(e.target.value);
        setPlaceError("");
    };

    const handleDistrict: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDistrict(e.target.value);
        setDistrictError("");
    };

    const handlePincode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPincode(e.target.value);
        setPincodeError("");
    };

    const handleState: ChangeEventHandler<HTMLInputElement> = (e) => {
        setState(e.target.value);
        setStateError("");
    };

    const handleCountry: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCountry(e.target.value);
        setCountryError("");
    };

    const handleRoute: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRoute(e.target.value);
        setRouteError("");
    };

    const handleContactNumber1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactNumber1(e.target.value);
        setContactNumber1Error("");
    };

    const handleContactNumber2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactNumber2(e.target.value);
        setContactNumber2Error("");
    };

    const handleAadharNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAadharNumber(e.target.value);
        setAadharNumberError("");
    };
    const handlegstNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGstNumber(e.target.value);
        setGstNumberError("");
    };

    const handleHiddenGst: ChangeEventHandler<HTMLInputElement> = (e) => {
        // if (e.target.checked) {
        //     SetViewFrom(false)
        // } else {
        //     SetViewFrom(true)
        // }
        setHiddenGst(!hiddenGst);
    };

    const handleFormClose = () => {
        // setShowSalesForm(false)
        setShowSalesView(false);
        setModal(false);
        setShowTenderModal(false);
    };

    const handleAreaName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAreaName(e.target.value);
    };
    const handlePlaceName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPlaceName(e.target.value);
    };

    const handleRouteName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteName(e.target.value);
    };
    const handleDistrictName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDistrictName(e.target.value);
    };
    const handleStateName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStateName(e.target.value);
    };
    const handleCountryName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCountryName(e.target.value);
    };

    const generateBillPdf = (printObj: any) => {
        const doc = new jsPDF();
        const logoUrl = clients[0]?.shopPhoto;
        const marginX = 10;
        const startY = 15;

        if (logoUrl) {
            doc.addImage(logoUrl, "JPEG", marginX, startY - 2, 25, 20);
        }
        // *Business Details (Top Left)*
        doc.setFont("helvetica", "bold");
        doc.setFontSize(10);
        doc.text(clients[0]?.shopFullName || "", 35, 10);
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.text(clients[0]?.address1 || "", 35, 15);
        doc.text(clients[0]?.address2 || "", 35, 20);
        doc.text(
            (clients[0]?.state || "") + "-" + (clients[0]?.pincode || ""),
            35,
            25
        );
        doc.text("Cell: " + (clients[0]?.contactNo1 || ""), 35, 30);
        doc.text("GST NO: " + clients[0]?.gstNo || "", 35, 35);
        doc.setFontSize(12);
        doc.text(printObj.invoiceType, 150, 10);
        // *Draw Horizontal Line After GST No*
        doc.line(14, 40, 195, 40);

        // *Customer Details (Left Side)*
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text("Customer Details:", 14, 47);
        doc.setFont("helvetica", "normal");
        doc.text("Name: " + (printObj.customer.name || ""), 14, 53);
        doc.text("Address: MYD", 14, 58);
        doc.text("Cell: " + (printObj.customer.conNo || ""), 14, 63);
        doc.text("GSTIN: NIL", 14, 68);

        // *Invoice Details (Right Side)*
        doc.setFont("helvetica", "bold");
        doc.text("Invoice Details:", 150, 47);
        doc.setFont("helvetica", "normal");
        doc.text("Invoice No: " + printObj.billnumber, 150, 53);
        doc.text("Date: " + printObj.date + " " + printObj.time, 150, 58);
        // doc.text("Ref No: ", 150, 63);
        doc.text("Payment: CASH SALES", 150, 63);

        doc.line(14, 72, 195, 72);

        // *Table Headers & Data*
        const tableColumn = [
            "S.No",
            "Particulars",
            "HSN Code",
            "Qty",
            "Rate",
            "GST%",
            "Total",
        ];
        const tableRows = printObj?.productitmes?.map(
            (item: any, index: number) => [
                index + 1,
                item.name,
                item.hsn || "-",
                item.qty,
                item.rate,
                item.gst,
                item.total,
            ]
        );

        autoTable(doc, {
            startY: 75, // Below the second line
            head: [tableColumn],
            body: tableRows,
            theme: "grid",
            styles: {
                fontSize: 10,
                cellPadding: 2,
                valign: "middle",
                lineColor: 0,
                lineWidth: 0.1,
                textColor: 0,
            },
            // styles: { fontSize: 8, },
            headStyles: { fillColor: [255, 255, 255] }, // Black header
            bodyStyles: { lineWidth: 0 },
        });

        doc.line(
            14,
            (doc as any).lastAutoTable.finalY + 5,
            195,
            (doc as any).lastAutoTable.finalY + 5
        );

        let finalY = (doc as any).lastAutoTable.finalY + 10;

        // Define X positions
        let leftTableX = 14; // Left side for GST table
        let rightTableX = 110; // Right side for Amount details table

        autoTable(doc, {
            startY: finalY,
            margin: { left: leftTableX }, // Align to the left
            head: [["CGST", "SGST"]],
            body: [[printObj.cgst, printObj.sgst]],
            theme: "plain",
            styles: { fontSize: 10, halign: "center" },
            columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
            },
        });

        let nextY = finalY;

        autoTable(doc, {
            startY: nextY,
            margin: { left: rightTableX }, // Align to the right
            body: [
                ["Bill Amount", ":", printObj.finaltotal],
                ["Amount Paid", ":", printObj.tender],
                ["Balance", ":", printObj.balance],
            ],
            theme: "plain",
            styles: { fontSize: 10 },
            columnStyles: {
                0: { fontStyle: "bold", halign: "right" },
                1: { halign: "left" },
                2: { halign: "right" },
            },
        });
        doc.line(
            14,
            (doc as any).lastAutoTable.finalY + 5,
            195,
            (doc as any).lastAutoTable.finalY + 5
        );

        // *Footer Text*
        doc.text(
            "Goods Once Sold Cannot be taken back. Thanks Come Again.",
            53,
            finalY + 35
        );

        // // *QR Code Generation*
        // const qrCode = new QRCodeStyling({
        //   width: 70,
        //   height: 70,
        //   data: "https://your-business-website.com",
        //   dotsOptions: { color: "#000", type: "square" },
        //   backgroundOptions: { color: "#ffffff" },
        // });

        // qrCode.getRawData().then((image) => {
        //   if (image) {
        //     doc.addImage(image, "PNG", 150, finalY + 10, 40, 40);
        //   }
        doc.save("Sales Invoice-" + printObj.date + " " + printObj.time + ".pdf");
        // });
    };

    const handleSave = () => {
        let error = false;

        const data = salesItems?.filter((f) => {
            return (
                f.productName?.label !== "" &&
                f.productName?.label !== undefined &&
                f.productName?.value !== "" &&
                f.productName?.value !== undefined
            );
        });

        if (data?.length > 0) {
            data?.forEach((d, i) => {
                if (d.productName?.value === "") {
                    toast(`In Row ${d.id}, Product required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.productCode === "") {
                    toast(`In Row ${d.id},Product Code required`, ToastTypes.ERROR);
                    error = true;
                }
                // if (d.hsn === '') {
                //     toast(`In Row ${d.id},HSN required`, ToastTypes.ERROR)
                //     error = true
                // }
                if (d.gstPercent === "") {
                    toast(`In Row ${d.id},gst percent required`, ToastTypes.ERROR);
                    error = true;
                }
                // if (d.batchNo === '') {
                //     toast(`In Row ${d.id},Batch required`, ToastTypes.ERROR)
                //     error = true
                // }
                // if (d.expiryDt?.toDateString() === '') {
                //     toast(`In Row ${d.id},Exp date required`, ToastTypes.ERROR)
                //     error = true
                // }
                if (d.qty === "") {
                    toast(`In Row ${d.id},Quantity required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.gstValue === "") {
                    toast(`In Row ${d.id},gstValue required`, ToastTypes.ERROR);
                    error = true;
                }
                // if (d.pwg === '') {
                //     toast(`In Row ${d.id},PWG required`, ToastTypes.ERROR)
                //     error = true
                // }
                // if (d.pwog === '') {
                //     toast(`In Row ${d.id},PWOG required`, ToastTypes.ERROR)
                //     error = true
                // }
                // if (d.sRate1 === '') {
                //     toast(`In Row ${d.id},Wholesale Price required`, ToastTypes.ERROR)
                //     error = true
                // }
                // if (d.sRate2 === '') {
                //     toast(`In Row ${d.id},Retail Price required`, ToastTypes.ERROR)
                //     error = true
                // }
                // if (d.sRate3 === '') {
                //     toast(`In Row ${d.id},Sales Price required`, ToastTypes.ERROR)
                //     error = true
                // }
                if (d.rate === "") {
                    toast(`In Row ${d.id},Rate required`, ToastTypes.ERROR);
                    error = true;
                }

                // if (parseFloat(d.pwg) < parseFloat(d.pwog)) {
                //     toast(`In Row ${d.id},PWG must be greater than PWOG`, ToastTypes.ERROR)
                //     error = true
                // } else if (parseFloat(d.sRate1) < parseFloat(d.pwg)) {
                //     toast(`In Row ${d.id},Rate 1 must be greater than PWG`, ToastTypes.ERROR)
                //     error = true
                // } else if (parseFloat(d.sRate2) < parseFloat(d.sRate1)) {
                //     toast(`In Row ${d.id},Rate 2 must be greater than Rate 1`, ToastTypes.ERROR)
                //     error = true
                // } else if (parseFloat(d.sRate3) < parseFloat(d.sRate2)) {
                //     toast(`In Row ${d.id},Rate 3 must be greater than Rate 2`, ToastTypes.ERROR)
                //     error = true
                // } else if (parseFloat(d.mrp) < parseFloat(d.sRate3)) {
                //     toast(`In Row ${d.id},MRP must be greater than Rate 3`, ToastTypes.ERROR)
                //     error = true
                // }

                if (d.taxable === "") {
                    toast(`In Row ${d.id},Tax required`, ToastTypes.ERROR);
                    error = true;
                }
                if (d.totalAmt === "") {
                    toast(`In Row ${d.id},Total Amt required`, ToastTypes.ERROR);
                    error = true;
                }
                // if (d.unitProfit === '') {
                //     toast(`In Row ${d.id},Unit Profit required`, ToastTypes.ERROR)
                //     error = true
                // }
                // if (d.totalProfit === '') {
                //     toast(`In Row ${d.id},Total Profit required`, ToastTypes.ERROR)
                //     error = true
                // }
            });
        } else {
            toast("Data Required", ToastTypes.ERROR);
            error = true;
        }
        ///top
        if (financialYear === "") {
            toast(`Financial Year required`, ToastTypes.ERROR);
            error = true;
        }
        if (invoiceType === "") {
            toast(`Invoice type required`, ToastTypes.ERROR);
            error = true;
        }
        if (invoiceTitle === "") {
            toast(`Invoice title required`, ToastTypes.ERROR);
            error = true;
        }
        if (billNo === "") {
            toast(`bill No required`, ToastTypes.ERROR);
            error = true;
        }
        // if (billTime === '') {
        //     toast(`bill Time required`, ToastTypes.ERROR)
        //     error = true
        // }
        if (customer?.value?.toString() === "") {
            toast(`Customer required`, ToastTypes.ERROR);
            error = true;
        }
        if (customer?.label?.toString() === "") {
            toast(`Customer required`, ToastTypes.ERROR);
            error = true;
        }
        if (customerName === "") {
            toast(`Customer required`, ToastTypes.ERROR);
            error = true;
        }
        if (billDate.toDateString() === "") {
            toast(`bill date required`, ToastTypes.ERROR);
            error = true;
        }
        // if (salesEntryNo === '') {
        //     toast(`Sales Entry No required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (supplier === '') {
        //     toast(`Supplier required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (branch === '') {
        //     toast(`Branch required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (customerPlace === '') {
        //     toast(`Branch required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (branch === '') {
        //     toast(`Branch required`, ToastTypes.ERROR)
        //     error = true
        // }

        ///right
        // if (billAmount === '') {
        //     toast(`Bill Amt required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (billNetAmt === '') {
        //     toast(`Net Amt required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (totAmt === '') {
        //     toast(`Total Amt required`, ToastTypes.ERROR)
        //     error = true
        // }
        if (gtotal === "") {
            toast(`Grand total required`, ToastTypes.ERROR);
            error = true;
        }
        if (
            [
                SalesPaymentModesDropDown.BANK,
                SalesPaymentModesDropDown.CASH,
                SalesPaymentModesDropDown.CREDIT,
            ].indexOf(paymentMode as SalesPaymentModeTypes) === -1
        ) {
            toast("Payment Mode must be BANK/CASH/CREDIT");
            error = true;
        }
        // if (amtInWords === '') {
        //     toast(`Amt In Words required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (currentBal === '') {
        //     toast(`Current balance required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (previousBal === '') {
        //     toast(`Previous balance required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (overAllBal === '') {
        //     toast(`OverAll balance required`, ToastTypes.ERROR)
        //     error = true
        // }
        if (totalQty === "") {
            toast(`total Qty required`, ToastTypes.ERROR);
            error = true;
        }
        if (totalItems === "") {
            toast(`total Items required`, ToastTypes.ERROR);
            error = true;
        }
        //
        // if (data.length > 0 && parseFloat(tenderedBal) !== parseFloat(finalAmount)) {
        //     toast('Mismatch in Tender Balance and bill Amt, Kindly Check ', ToastTypes.ERROR)
        //     error = true
        // }

        // if (data.length > 0 && parseFloat(tenderedBal).toFixed(2) !== parseFloat(finalAmount).toFixed(2)) {
        //     toast('Mismatch in Tender Balance and bill Amt, Kindly Check ', ToastTypes.ERROR)
        //     error = true
        // }

        if (error === false) {
            const netTaxable = data.reduce((previous, current) => {
                return previous + parseFloat(current?.taxable!);
            }, 0);
            // const netIgst = data.reduce((previous, current) => {
            //     return previous + parseFloat(current.igst)
            // }, 0)
            // const netCgst = data.reduce((previous, current) => {
            //     return previous + parseFloat(current.cgst)
            // }, 0)
            // const netSgst = data.reduce((previous, current) => {
            //     return previous + parseFloat(current.sgst)
            // }, 0)
            const netPercent = data.reduce((previous, current) => {
                return previous + parseFloat(current.profitPercent!);
            }, 0);
            //A
            const AData = data.filter((a) => a?.gstPercent?.toString() === "0");
            const AValue = AData.reduce((previous, current) => {
                return (
                    previous +
                    parseFloat(current.igst!) +
                    parseFloat(current.cgst!) +
                    parseFloat(current.sgst!)
                );
            }, 0);
            const ATaxable = AData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable!);
            }, 0);
            //B
            const BData = data.filter((a) => a?.gstPercent?.toString() === "5");
            const BValue = BData.reduce((previous, current) => {
                return (
                    previous +
                    parseFloat(current.igst!) +
                    parseFloat(current.cgst!) +
                    parseFloat(current.sgst!)
                );
            }, 0);
            const BTaxable = BData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable!);
            }, 0);
            //C
            const CData = data.filter((a) => a?.gstPercent?.toString() === "12");
            const CValue = CData.reduce((previous, current) => {
                return (
                    previous +
                    parseFloat(current.igst!) +
                    parseFloat(current.cgst!) +
                    parseFloat(current.sgst!)
                );
            }, 0);
            const CTaxable = CData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable!);
            }, 0);
            //D
            const DData = data.filter((a) => a?.gstPercent?.toString() === "18");
            const DValue = DData.reduce((previous, current) => {
                return (
                    previous +
                    parseFloat(current.igst!) +
                    parseFloat(current.cgst!) +
                    parseFloat(current.sgst!)
                );
            }, 0);
            const DTaxable = DData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable!);
            }, 0);
            //E
            const EData = data.filter((a) => a?.gstPercent?.toString() === "28");
            const EValue = EData.reduce((previous, current) => {
                return (
                    previous +
                    parseFloat(current.igst!) +
                    parseFloat(current.cgst!) +
                    parseFloat(current.sgst!)
                );
            }, 0);
            const ETaxable = EData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable!);
            }, 0);

            let idate = new Date();
            var days = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
            ];
            var dayName = days[idate.getDay()];

            const transactions = [];
            // let credit =  0
            // if (parseInt(previousBal) == overallBalance){
            //       credit = 0;
            // }
            // else if (parseInt(previousBal) < overallBalance){
            //         let creditIncrement = overallBalance - parseInt(previousBal);
            //         credit = creditIncrement;
            // }
            // else if (parseInt(previousBal) > overallBalance){
            //     let creditDecrement = overallBalance - parseInt(previousBal);
            //     credit = -(creditDecrement);
            // }
            // if (credit > 0) {
            //     transactions.push({ paymentMode: 'Credit', particulars: 'credit', amtPaid: credit });
            // }
            if (parseFloat(tenderedReturn) > 0) {
                transactions.push({
                    paymentMode: "Cash",
                    particulars: "Cash",
                    amtPaid: parseFloat(tenderedAmt) - parseFloat(tenderedReturn),
                });
            } else if (parseFloat(tenderedReturn) === 0 && parseFloat(cash) > 0) {
                transactions.push({
                    paymentMode: "Cash",
                    particulars: "Cash",
                    amtPaid: parseFloat(cash),
                });
            }
            if (parseFloat(card1) > 0) {
                transactions.push({
                    paymentMode: "Bank",
                    particulars: "Card1",
                    amtPaid: parseFloat(card1),
                });
            }
            if (parseFloat(card2) > 0) {
                transactions.push({
                    paymentMode: "Bank",
                    particulars: "Card2",
                    amtPaid: parseFloat(card2),
                });
            }
            if (parseFloat(onlinePayment1) > 0) {
                transactions.push({
                    paymentMode: "Bank",
                    particulars: "Online Payment 1",
                    amtPaid: parseFloat(onlinePayment1),
                });
            }
            if (parseFloat(onlinePayment2) > 0) {
                transactions.push({
                    paymentMode: "Bank",
                    particulars: "Online Payment 2",
                    amtPaid: parseFloat(onlinePayment2),
                });
            }
            if (paymentMethod === "credit") {
                transactions.push({
                    paymentMode: "Credit",
                    particulars: "Credit",
                    amtPaid: 0,
                });
            }

            let addData: SalesType = {
                financeYear: financialYear,
                branchId: AuthUser?.branchId ? AuthUser.branchId : 0,
                // billNo: billNo,
                // billTime: billTime,
                // billDate: billDate,
                // goodsReceivedDate: gudsRecdDate,
                invoiceDate: getDate(idate),
                invoiceType: invoiceType,
                invoiceTitle: invoiceTitle,
                invoiceDay: dayName,
                invoiceWeekNumber: 1,
                invoiceWeekDay: idate.getDay(),
                invoiceMonth: idate.getMonth() + 1,
                invoiceYear: idate.getFullYear(),
                invoiceNumber: billNo ? billNo : "0",
                packNo: packNo,
                salesEntryNumber: parseInt(salesEntryNo),
                repId: parseInt(refBy),
                customerId: customer?.value!,
                //new
                pointsEarned: pointsEarned.toString(),
                systemNo: sysNo,
                paymentMode: paymentMode,
                customerName: customerName,
                bankName: bankName,
                bankNumber: parseInt(bankNumber),
                billAmount: netAmt, //sending netAmt to be stored as  billAmt
                disPercent: disPercent,
                disAmount: disAmt,
                totalAmount: TotalBillAmt,
                totalDiscount: TotalDisAmt,
                roundOff: roundOff,
                prevBal: previousBal,
                totalBal: totalBal?.toString(),
                cash: cash,
                card1: card1,
                card2: card2,
                onlinePayment1: onlinePayment1,
                onlinePayment2: onlinePayment2,
                credit: balance,
                ledgerBal: ledgerBal,
                bankReceived: bankRecevied,
                add: add,
                less: less,
                grandTotal: gtotal,
                amtInWords: amtInWords,
                particulars: "sales-" + packNo,
                amtPaid: amtPaid,
                deliveryMode: deliveryMode,
                deliveryDetails: deliveryDetails,
                remarks: remark,
                tenderAmt: tenderedAmt,
                tenderReturn: tenderedReturn,
                // totalItems: totalItems,
                // totalQty: totalQty,
                netTaxable: netTaxable.toString(),
                netIgst: netIgst.toString(),
                netSgst: netSgst.toString(),
                netCgst: netCgst.toString(),
                netProfit: totalProfit,
                netProfitPercent: netPercent.toString(),
                AValue: AValue.toString(),
                ATaxable: ATaxable.toString(),
                BValue: BValue.toString(),
                BTaxable: BTaxable.toString(),
                CValue: CValue.toString(),
                CTaxable: CTaxable.toString(),
                DValue: DValue.toString(),
                DTaxable: DTaxable.toString(),
                EValue: EValue.toString(),
                ETaxable: ETaxable.toString(),
                isPointsRedeemed: isCustPointsRedeemed,
                split: split,
                transactions: transactions,
                //
                salesItems: data?.map((dd) => {
                    //tot profit=(q+f*s1)-(q+f*pwog)
                    let tprofit =
                        (parseFloat(dd.qty) + 0) * parseFloat(dd.rate!) -
                        (parseFloat(dd.qty) + 0) * parseFloat(dd.pwog!);
                    //for unitprofit
                    let unitProfit =
                        (dd.rate ? parseFloat(dd.rate) : 0) -
                        (dd.pwog ? parseFloat(dd.pwog) : 0);
                    //for profit %
                    let profitPercent =
                        ((unitProfit ? unitProfit : 0) /
                            (dd.pwog ? parseFloat(dd.pwog) : 0)) *
                        100;

                    // console.log(parseFloat(dd.igst!))
                    const obj = {
                        entryNumber: parseInt(salesEntryNo),
                        productId: dd.productName?.value
                            ? parseInt(dd.productName?.value)
                            : 0,
                        productCode: dd.productCode,
                        productGroupCode: dd.productGroupCode,
                        batchNumber: parseInt(dd.batchNo!),
                        expiryDate: dd.expiryDt ? getDate(dd.expiryDt.toString()) : "",
                        quantity: dd.qty,
                        freeQuantity: "0",
                        totalQuantity: dd.qty,
                        priceWogst: parseFloat(dd.pwog!),
                        priceGst: parseFloat(dd.pwg!),
                        wRate: parseFloat(dd.rate!),
                        // rRate: parseFloat(dd.sRate2),
                        // lRate: parseFloat(dd.sRate3),
                        mrp: parseFloat(dd.mrp!),
                        gstValue: parseFloat(dd.gstValue!),
                        sDiscount: parseFloat(dd.disAmt!),
                        pDiscountpercent: dd.pdPercent !== undefined ? dd.pdPercent : 0,
                        pDiscountamount: dd.pdAmount !== undefined ? dd.pdAmount : 0,
                        cDiscountpercent: 0,
                        cDiscountamount: 0,
                        taxableAmount: parseFloat(dd.taxable!),
                        igstAmount: dd.igst ? parseFloat(dd.igst!) : 0,
                        cgstAmount: dd.cgst ? parseFloat(dd.cgst!) : 0,
                        sgstAmount: dd.sgst ? parseFloat(dd.sgst!) : 0,
                        totalAmount: dd.totalAmt ? parseFloat(dd.totalAmt!) : 0,
                        unitCost: unitProfit,
                        profitAmount: tprofit,
                        profitPercent: parseFloat(profitPercent?.toFixed(2)),
                    };
                    return obj;
                }),
            };

            const printObj = {
                kotnumber: 1,
                invoiceType: addData.invoiceType,
                cgst: addData.netCgst,
                sgst: addData.netSgst,
                billnumber: addData.invoiceNumber,
                tablename: "table1",
                customer: customer,
                cashierid: AuthUser?.userId,
                systemid: addData.systemNo,
                date: LocalFormatDate(addData.invoiceDate),
                printername: "",
                time: new Date().toLocaleTimeString("en-US", {
                    timeZone: "Asia/Kolkata",
                    hour12: true,
                }),
                // 'time': new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata', hour12: true }).replace(',', ''),
                productitmes: salesItems
                    ?.filter((salesItem) => salesItem.productName !== null)
                    ?.map((salesItem) => {
                        console.log("s-m-salesItems", salesItem);

                        return {
                            name: salesItem.productName?.label,
                            pcode: salesItem.productCode,
                            hsn: salesItem.hsn,
                            qty: salesItem.qty,
                            disc: parseInt(salesItem?.disAmt!)?.toFixed(2),
                            rate: parseInt(salesItem?.rate!)?.toFixed(2),
                            mrp: parseInt(salesItem?.mrp!)?.toFixed(2),
                            total: parseInt(salesItem?.totalAmt!)?.toFixed(2),
                            gst: parseInt(salesItem?.gstPercent!)?.toFixed(2),
                        };
                    }),
                empname: AuthUser?.name,
                grandtotal: finalAmount,
                discount: [{ discountname: "offer 10%", amount: 20.0 }],
                gst: [{ taxname: "gst 8%", amount: 16.0 }],
                oc: [{ ocname: "ocname 5%", amount: 10.0 }],
                finaltotal: parseFloat(addData?.grandTotal!)?.toFixed(2),
                totaldisc: parseFloat(addData?.disAmount!)?.toFixed(2),
                totalqty: parseFloat(totalQty),
                paymentamount: parseFloat(amtPaid)?.toFixed(2),
                tender: tenderedAmt ? parseFloat(tenderedAmt)?.toFixed(2) : 0,
                balance: tenderedReturn ? parseFloat(tenderedReturn)?.toFixed(2) : 0,
                pointsearned: pointsEarned > 0 ? pointsEarned : 0,
                totalpoints: totalpoints > 0 ? totalpoints : 0,
            };

            if (formType === FormTypeList.ADD) {
                setShowOverlayLoader();
                dispatch(addSalesAction(addData))
                    .then((text) => {
                        toast(text, ToastTypes.SUCCESS);
                        generateBillPdf(printObj);
                        fetch("http://localhost:8080/Print/billprint.jsp", {
                            method: "POST",
                            body: JSON.stringify(printObj),
                            mode: "no-cors",
                            headers: { "Content-Type": "application/json" },
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                console.log("Print Successfully");
                            })
                            .catch((err) => {
                                console.log(err.message);
                            });
                        setHideOverlayLoader();
                        resetStates();
                        dispatch(fetchSalesAction());
                        fetchRecentSalesId();
                    })
                    .catch((text) => {
                        toast(text, ToastTypes.ERROR);
                    })
                    .finally(() => {
                        setHideOverlayLoader();
                    });
            } else if (formType === FormTypeList.EDIT && id) {
                setShowOverlayLoader();
                dispatch(updateSalesAction(addData, id))
                    .then((text) => {
                        toast(text, ToastTypes.SUCCESS);
                        generateBillPdf(printObj);
                        fetch("http://localhost:8080/Print/billprint.jsp", {
                            method: "POST",
                            body: JSON.stringify(printObj),
                            mode: "no-cors",
                            headers: { "Content-Type": "application/json" },
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                console.log("Print Successfully");
                            })
                            .catch((err) => {
                                console.log(err.message);
                            });
                        resetStates();
                        dispatch(fetchSalesAction());
                        fetchRecentSalesId();
                    })
                    .catch((text) => {
                        toast(text, ToastTypes.ERROR);
                    })
                    .finally(() => {
                        setHideOverlayLoader();
                    });
            }
        }
    };

    // const fetchRecentSalesId = async () => {
    //     api().get(`/sales/recent/`).then((res) => {
    //         const result = (res.data.data !== null ? parseInt(res.data.data) : 0) + 10000 + 1
    //         if (formType === FormTypeList.ADD) setBillNo(result.toString())
    //     });
    // }

    const fetchRecentSalesId = async () => {
        try {
            const response = await api().get(`/sales/recent/`);

            if (response.status === 200 && response.data.data) {
                const { recentId1, recentId2 } = response.data.data;

                // Determine the prefix based on invoiceType
                const prefix = `TAP${invoiceType === SALES_TYPE_DROPS[0].value ? "TI" : "ES"
                    }`;
                const lastInvoiceNumber =
                    invoiceType === SALES_TYPE_DROPS[0].value ? recentId1 : recentId2;

                if (lastInvoiceNumber) {
                    const numericPart = lastInvoiceNumber.replace(prefix, ""); // Extract numeric part
                    const parsedNumber = parseInt(numericPart, 10); // Convert to integer

                    if (isNaN(parsedNumber)) {
                        console.error("Failed to parse invoice number:", lastInvoiceNumber);
                        setBillNo(`${prefix}10001`);
                    } else {
                        const newNumericPart = parsedNumber + 1; // Increment numeric part
                        const formattedNumber = `${prefix}${newNumericPart
                            .toString()
                            .padStart(5, "0")}`; // Format to 5 digits
                        if (formType === FormTypeList.ADD) setBillNo(formattedNumber);
                    }
                } else {
                    setBillNo(`${prefix}10001`); // Default invoice number if none exists
                }
            } else {
                setBillNo(
                    `TAP${invoiceType === SALES_TYPE_DROPS[0].value ? "TI" : "ES"}10001`
                );
            }
        } catch (error) {
            console.error("Error fetching recent sales ID:", error);
        }
    };

    const redeemCustomerPoints = () => {
        if (previousPoints > 0) {
            let amt = parseFloat(finalAmount) - previousPoints;
            setFinalAmount(amt?.toString());
            setPreviousPoints(0);
            setIsCustPointsRedeemed(true);
        }
    };

    useEffect(() => {
        fetchRecentSalesId();
    }, [formType, invoiceType]);

    useEffect(() => {
        // if (paymentMode === SalesPaymentModesDropDown.CREDIT) {
        //     setAmtPaid("0");
        // }
        if (paymentMethod === "credit") {
            setAmtPaid("0");
        } else if (cash || card1 || card2 || onlinePayment1 || onlinePayment2) {
            const AmtPaid =
                parseFloat(cash || "0") +
                parseFloat(card1 || "0") +
                parseFloat(card2 || "0") +
                parseFloat(onlinePayment1 || "0") +
                parseFloat(onlinePayment2 || "0");
            setAmtPaid(AmtPaid.toString());
        }
    }, [
        paymentMode,
        cash,
        card1,
        card2,
        onlinePayment1,
        onlinePayment2,
        paymentMethod,
    ]);

    useEffect(() => {
        if (card1 || card2 || onlinePayment1 || onlinePayment2) {
            const backRecevied =
                parseFloat(card1 || "0") +
                parseFloat(card2 || "0") +
                parseFloat(onlinePayment1 || "0") +
                parseFloat(onlinePayment2 || "0");
            setBankRecevied(backRecevied.toString());
        } else {
            setBankRecevied("0");
        }
    }, [card1, card2, onlinePayment1, onlinePayment2]);

    useEffect(() => {
        if (paymentMethod === "credit") {
            const tenderedAmt = 0;
            setTenderedAmt(tenderedAmt.toString());
        }
    }, [paymentMethod]);

    const totalBal = useMemo(() => {
        return parseFloat(gtotal || "0") + parseFloat(previousBal || "0");
    }, [gtotal, previousBal]);

    const generateRemark = (payments: string[]) => {
        const remarkLabels = ["C", "C1", "C2", "C3", "C4"];
        const remarks = payments
            .map((payment, index) =>
                parseFloat(payment || "0") > 0 ? remarkLabels[index] : ""
            )
            .filter(Boolean);

        setRemark(remarks.join("+"));
        setSplit(remarks.length > 1 ? "Yes" : "No");
    };

    useEffect(() => {
        generateRemark([cash, card1, card2, onlinePayment1, onlinePayment2]);
    }, [totalBal, cash, card1, card2, onlinePayment1, onlinePayment2]);

    useEffect(() => {
        setTotalPoints(previousPoints + pointsEarned);
    }, [previousPoints, pointsEarned]);

    useEffect(() => {
        let d = new Date();
        const date = d.getDate().toString().padStart(2, "0");
        const month = (d.getMonth() + 1).toString().padStart(2, "0");
        setPackNo(date + month);
    }, []);

    const onArea = () => {
        const addDynArea: DropDownType = {
            id: id,
            category: "area",
            title: areaName,
        };

        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addDynArea))
                .then((text) => {
                    toast("Added Division", ToastTypes.SUCCESS);
                    setAreaName("");
                })
                .catch((text) => {
                    toast(text, ToastTypes.ERROR);
                });
        }
        setAreaModal(false);
    };
    const onPlace = () => {
        const addDynPlace: DropDownType = {
            id: id,
            category: "place",
            title: placeName,
        };
        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addDynPlace))
                .then((text) => {
                    toast("Added Place", ToastTypes.SUCCESS);
                    setPlaceName("");
                })
                .catch((text) => {
                    toast(text, ToastTypes.ERROR);
                });
        }
        setPlaceModal(false);
    };
    const onDistrict = () => {
        const addDynDistrict: DropDownType = {
            id: id,
            category: "district",
            title: districtName,
        };

        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addDynDistrict))
                .then((text) => {
                    toast("Added District", ToastTypes.SUCCESS);
                    setDistrictName("");
                })
                .catch((text) => {
                    toast(text, ToastTypes.ERROR);
                });
        }
        setDistrictModal(false);
    };
    const onState = () => {
        const addDynState: DropDownType = {
            id: id,
            category: "state",
            title: stateName,
        };

        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addDynState))
                .then((text) => {
                    toast("Added State", ToastTypes.SUCCESS);
                    setStateName("");
                })
                .catch((text) => {
                    toast(text, ToastTypes.ERROR);
                });
        }
        setStateModal(false);
    };
    const onCountry = () => {
        const addDynCountry: DropDownType = {
            id: id,
            category: "country",
            title: countryName,
        };
        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addDynCountry))
                .then((text) => {
                    toast("Added Country", ToastTypes.SUCCESS);
                    setCountryName("");
                })
                .catch((text) => {
                    toast(text, ToastTypes.ERROR);
                });
        }
        setCountryModal(false);
    };
    const onRoute = () => {
        const addDynRoute: DropDownType = {
            id: id,
            category: "route",
            title: routeName,
        };
        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addDynRoute))
                .then((text) => {
                    toast("Added Route", ToastTypes.SUCCESS);
                    setRouteName("");
                })
                .catch((text) => {
                    toast(text, ToastTypes.ERROR);
                });
        }
        setRouteModal(false);
    };

    useEffect(() => {
        // if (tenderedReturn !== '0') {
        //     setBalance('0')
        // } else {
        //     let bal = (totalBal ? totalBal : 0) - (amtPaid ? parseFloat(amtPaid) : 0) //totalBalance - amtpaid
        //     setBalance(bal?.toString())
        // }
        setBalance((totalBal - (amtPaid ? parseFloat(amtPaid) : 0))?.toString());
    }, [amtPaid, totalBal]);

    useEffect(() => {
        // if (parseFloat(tenderedAmt) > totalBal) {
        //     let val = (tenderedAmt ? parseFloat(tenderedAmt) : 0) - (totalBal ? totalBal : 0)
        //     setTenderedReturn(val?.toString())
        // } else if (parseFloat(tenderedAmt) === totalBal) {
        //     setTenderedReturn(0?.toString())
        // } else {
        //     setTenderedReturn(0?.toString())
        // }
        setTenderedReturn(
            (
                (tenderedAmt ? parseFloat(tenderedAmt) : 0) -
                (cash ? parseFloat(cash) : 0)
            )?.toString()
        );
    }, [tenderedAmt, cash]);

    useEffect(() => {
        let val =
            (tenderedAmt ? parseFloat(tenderedAmt) : 0) -
            (tenderedReturn ? parseFloat(tenderedReturn) : 0);
        setTenderedBal(val?.toString());
    }, [tenderedReturn, tenderedAmt]);

    useEffect(() => {
        // console.log(salesItems);
        const data = salesItems.filter((f) => {
            return (
                f.productName?.label !== "" &&
                f.productName?.label !== undefined &&
                f.productName?.value !== "" &&
                f.productName?.value !== undefined
            );
        });
        // console.log(data)
        const igst = data.reduce((previous, current) => {
            return previous + parseFloat(current?.gstValue!);
        }, 0);
        // console.log(igst)
        const nqty = data.reduce((previous, current) => {
            return previous + parseFloat(current?.qty!);
        }, 0);
        const ntax = data.reduce((previous, current) => {
            return previous + parseFloat(current?.taxable!);
        }, 0);
        const ngst = data.reduce((previous, current) => {
            return previous + parseFloat(current?.gstValue!);
        }, 0);
        const nsavings = data.reduce((previous, current) => {
            return previous + parseFloat(current?.disAmt!);
        }, 0);
        let val = (igst / 2).toFixed(2);
        setNetIgst(0?.toString());
        setNetCgst(val.toString());
        setNetSgst(val.toString());
        setTotalItems(data?.length?.toString());
        setTotalQty(nqty?.toString());
        setNetTaxable(ntax?.toString());
        setNetGSTValue(ngst?.toFixed(2).toString());
        setYourSavings(nsavings?.toString());
    }, [salesItems]);

    useEffect(() => {
        const data = salesItems.filter((f) => {
            return (
                f.productName?.label !== "" &&
                f.productName?.label !== undefined &&
                f.productName?.value !== "" &&
                f.productName?.value !== undefined
            );
        });
        const tProfit = data
            .reduce((previous, current) => {
                return previous + parseFloat(current?.totalAmt!);
            }, 0)
            .toFixed(2);
        setBillNetAmt(tProfit.toString());
    }, [salesItems]);

    // useEffect(() => {
    //     let gtot = (netTaxable ? parseFloat(netTaxable) : 0) + (netGSTValue ? parseFloat(netGSTValue) : 0) + (add ? parseFloat(add) : 0) - (less ? parseFloat(less) : 0)
    //     setGTotal(gtot.toString())
    // }, [add, less, netGSTValue, netTaxable])

    useEffect(() => {
        if (netAmt) {
            let Total = Math.round(parseFloat(netAmt)) - parseFloat(netAmt);
            setRoundOff(Total.toFixed(4));
        }
    }, [netAmt]);

    useEffect(() => {
        let gtot =
            (netTaxable ? parseFloat(netTaxable) : 0) +
            (netGSTValue ? parseFloat(netGSTValue) : 0) +
            (add ? parseFloat(add) : 0) -
            (less ? parseFloat(less) : 0);

        const roundedValue = gtot.toFixed(2);

        setNetAmt(roundedValue);
        setGTotal(Math.round(gtot)?.toString());
    }, [add, less, netGSTValue, netTaxable]);

    useEffect(() => {
        let fAmt = parseFloat(gtotal);
        // console.log(fAmt)
        setFinalAmount(fAmt.toString());
        setPointsEarned(Math.ceil(fAmt / 100));
    }, [gtotal]);

    // useEffect(() => {
    //     let billnetAmt = Math.round(totAmt ? parseFloat(totAmt) : 0)
    //     setBillNetAmt(billnetAmt.toString())
    // }, [totAmt])
    useEffect(() => {
        // console.log('cbal');
        if (parseFloat(previousBal) < 0) {
            let cbal = -(
                (previousBal ? Math.abs(parseFloat(previousBal)) : 0) -
                parseFloat(amtPaid)
            );
            setCurrentBal(cbal.toString());
        } else {
            let cbal =
                (previousBal ? parseFloat(previousBal) : 0) - parseFloat(amtPaid);
            setCurrentBal(cbal.toString());
        }
    }, [amtPaid, previousBal]);
    useEffect(() => {
        // console.log('obal');
        let obal =
            (currentBal ? parseFloat(currentBal) : 0) +
            (previousBal ? parseFloat(previousBal) : 0);
        setOverAllBal(obal.toString());
    }, [currentBal, previousBal]);

    useEffect(() => {
        const newOverAllBalance =
            totalBal -
            ((parseFloat(cash) || 0) +
                (parseFloat(card1) || 0) +
                (parseFloat(card2) || 0) +
                (parseFloat(onlinePayment1) || 0) +
                (parseFloat(onlinePayment2) || 0));

        setLedgerBal(newOverAllBalance < 0 ? "0" : newOverAllBalance.toString());
    }, [totalBal, cash, card1, card2, onlinePayment1, onlinePayment2]);

    // useEffect(() => {
    //     let tamt = (billAmount ? parseFloat(billAmount) : 0) - (disAmt ? parseFloat(disAmt) : 0)
    //     setTotAmt(tamt.toString())
    // }, [disAmt, billAmount])

    // useEffect(() => {
    //     if (disPercent !== '') {
    //         let damt = (billAmount ? parseFloat(billAmount) : 0) * (disPercent ? parseFloat(disPercent) : 0) / 100
    //         setDisAmt(damt.toString())
    //     }
    // }, [billAmount, disPercent])

    useEffect(() => {
        const d = new Date();
        const todayDate = d.getDate(); //27
        const currentMonth = d.getMonth() + 1; //6
        const currentYear = d.getFullYear(); //2022

        if (currentMonth >= 4 && todayDate >= 1) {
            let year = currentYear + "-" + (currentYear + 1);
            setFinancialYear(year);
        } else if (currentMonth < 4) {
            let year = currentYear - 1 + "-" + currentYear;
            setFinancialYear(year);
        }
    }, []);

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            // background: '#fff',
            // borderColor: '#9e9e9e',
            minHeight: "23px",
            height: "23px",
            boxShadow: state.isFocused ? null : null,
            width: "150px",
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: "25px",
            padding: "0 5px",
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: "0px",
        }),
        dropdownIndicator: (prevStyle: any, state: any) => ({
            // ...prevStyle,
            display: "none",
        }),
        indicatorSeparator: (state: any) => ({
            display: "none",
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: "28px",
        }),
        menu: (css: any) => ({
            ...css,
            // 'z-index': 600,
            width: "250px",
        }),
    };
    const customStyles2 = {
        control: (provided: any, state: any) => ({
            ...provided,
            minHeight: "23px",
            height: "23px",
            boxShadow: state.isFocused ? null : null,
            // zIndex: 500,
            width: "250px",
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: "25px",
            padding: "0 6px",
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            margin: "0px",
        }),
        dropdownIndicator: (prevStyle: any, state: any) => ({
            // ...prevStyle,
            display: "none",
        }),
        indicatorSeparator: (state: any) => ({
            display: "none",
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: "28px",
        }),
        menu: (css: any) => ({
            ...css,
            zIndex: 555,
            width: "250px",
        }),
    };

    useEffect(() => {
        setAmtInWords(convertAmtToWords(parseFloat(gtotal), "India"));
    }, [gtotal]);

    useEffect(() => {
        const fetchBalance = async () => {
            if (customer) {
                try {
                    const response = await getTransactionCurrentBalanceByAcNo(
                        customer.value?.toString()
                    );
                    const data = response.data.data;

                    const balance = Array.isArray(data)
                        ? data[0]?.currentBalance
                        : data.currentBalance;

                    const formattedBalance =
                        balance && parseFloat(balance) >= 0 ? balance.toString() : "0";

                    setPreviousBal(formattedBalance);
                } catch (error) {
                    console.error("Error fetching balance:", error);
                    setPreviousBal("0");
                }
            } else {
                setPreviousBal("0");
            }
        };

        fetchBalance();
    }, [customer]);

    useEffect(() => {
        if (formType === FormTypeList.EDIT && id) {
            // console.log("Hello World Edit");
            (async () => {
                try {
                    setShowOverlayLoader();
                    // console.log('hi2');
                    const response = await getSalesById(id);
                    const data: SalesEditAttributes = response.data.data;
                    // console.log(data);

                    const custobj = CustomerList?.find(
                        (a) => a.accountNumber?.toString() === data.customer_id?.toString()
                    );
                    let custname = custobj?.accountName;
                    let custadd = custobj?.address1! + custobj?.address2;
                    let custcont = custobj?.contactNumber1;
                    let cScore = custobj?.score;
                    // let sgst = custobj?.gstNumber
                    let custplace = custobj?.supplierPlace;

                    const userObj = userList.find(
                        (a) => a.id?.toString() === data.created_by?.toString()
                    );
                    let uname = userObj?.name ? userObj?.name : "";

                    //****top****
                    setInvoiceType(data.invoice_type);
                    setInvoiceTitle(data.invoice_title);
                    setBillNo(data.invoice_number.toString());
                    // setBillTime(data.invoice_title)
                    setBillDate(new Date(data.invoice_date));
                    setInvoiceAmount(data.grand_total);
                    setSalesEntryNo(data.sales_entry_number?.toString());
                    setCustomer({
                        value: data.customer_id,
                        label: custname ? custname : "",
                        name: custname ? custname : "",
                        conNo: custcont ? custcont.toString() : "",
                        score: cScore ? cScore : "",
                    });
                    // setCustomerNo('2')
                    setCustomerName(custname!);
                    setCustomerAddress(custadd);
                    setCustomerContact(custcont?.toString()!);
                    setCustomerPlace(custplace ? custplace : "");
                    setPreviousPoints(cScore ? parseInt(cScore) : 0);
                    // setGST(sgst ? sgst : '')
                    // setSupAadhar(saadhar ? saadhar?.toString() : '')
                    setRefBy(data.rep_id?.toString());
                    setBranch(data.branch_id?.toString());
                    setBilledBy(uname);
                    setSysNo(data.system_no);

                    // Process Transactions
                    let cashAmount = "0",
                        card1Amount = "0",
                        card2Amount = "0",
                        onlinePayment1Amount = "0",
                        onlinePayment2Amount = "0";
                    let paymentMethodByPaymentMode = "";

                    data?.Transactions?.forEach((transaction) => {
                        switch (transaction.particulars) {
                            case "Cash":
                                cashAmount = transaction.credit_amount || "0";
                                break;
                            case "Card1":
                                card1Amount = transaction.credit_amount || "0";
                                break;
                            case "Card2":
                                card2Amount = transaction.credit_amount || "0";
                                break;
                            case "Online Payment 1":
                                onlinePayment1Amount = transaction.credit_amount || "0";
                                break;
                            case "Online Payment 2":
                                onlinePayment2Amount = transaction.credit_amount || "0";
                                break;
                        }

                        if (transaction.payment_mode === "Credit") {
                            paymentMethodByPaymentMode = "credit";
                        } else if (["Cash", "Bank"].includes(transaction.payment_mode)) {
                            paymentMethodByPaymentMode = "cashAndBank";
                        }
                    });

                    // Set Transaction States
                    setCash(cashAmount);
                    setCard1(card1Amount);
                    setCard2(card2Amount);
                    setOnlinePayment1(onlinePayment1Amount);
                    setOnlinePayment2(onlinePayment2Amount);
                    setPaymentMethod(paymentMethodByPaymentMode);

                    // Process Sales Items
                    const salesItemEditData = getDefaultSalesItems();

                    data.salesItems.forEach((pItem, pindex) => {
                        const stockObj = StockList.find(
                            (pd) =>
                                pd.productCode?.toString() === pItem.product_code.toString()
                        );
                        // console.log(stockObj)
                        let discount = (stockObj?.mrp || 0) - (stockObj?.lRate || 0);
                        let gstValue =
                            ((pItem.quantity || 0) * (stockObj?.lRate || 0) - discount) *
                            (parseFloat(stockObj?.gst?.toString() || "0") /
                                (100 + parseFloat(stockObj?.gst?.toString() || "0")));

                        salesItemEditData[pindex] = {
                            id: pindex + 1,
                            productName: {
                                label: stockObj?.productName || "",
                                value: stockObj?.id.toString() || "",
                            },
                            productCode: pItem.product_code?.toString() || "",
                            productGroupCode: stockObj?.productGroupCode?.toString() || "",
                            gstPercent: stockObj?.gst?.toString() || "",
                            batchNo: stockObj?.batchNumber?.toString() || "",
                            pdPercent: parseFloat(
                                pItem.p_discount_percent?.toString() || "0"
                            ),
                            pdAmount: parseFloat(pItem.p_discount_amount?.toString() || "0"),
                            expiryDt: stockObj?.expiryDate
                                ? new Date(stockObj?.expiryDate)
                                : null,
                            hsn: stockObj?.sac?.toString() || "",
                            qty: pItem.quantity?.toString() || "",
                            pwog: pItem.price_wogst?.toString() || "",
                            pwg: pItem.price_gst?.toString() || "",
                            rate: pItem.w_rate?.toString() || "",
                            mrp: pItem.mrp?.toString() || "",
                            igst: pItem.igst_amount?.toString() || "0",
                            cgst: pItem.cgst_amount?.toString() || "0",
                            sgst: pItem.sgst_amount?.toString() || "0",
                            disAmt: pItem.s_discount?.toString() || "0",
                            gstValue: pItem.gst_value?.toString() || "0",
                            taxable: pItem.taxable_amount?.toString() || "0",
                            totalAmt: pItem.total_amount?.toString() || "0",
                            profitPercent: pItem.profit_percent?.toString() || "0",
                        };
                    });

                    // console.log(salesItemEditData);
                    setSalesItems(salesItemEditData);

                    //****Right****
                    setNetTaxable(data.net_taxable);
                    setNetIgst(data.net_igst);
                    setNetSgst(data.net_sgst);
                    setNetCgst(data.net_cgst);
                    setBillAmount(data.bill_amount);
                    setNetAmt(data.bill_amount);
                    setDisPercent(data.dis_percent?.toString());
                    setDisAmt(data.dis_amount?.toString());
                    setTenderedAmt(data.tender_amount?.toString());
                    setTenderedReturn(data.tender_return?.toString());
                    // setBillNetAmt(data.)
                    setAdd(data.add);
                    setLess(data.less);
                    setGTotal(data.grand_total);
                    setAmtInWords(data.amt_in_words);
                    if (data.payment_mode === SalesPaymentModesDropDown.CASH) {
                        setPaymentMode(SalesPaymentModesDropDown.CASH);
                    } else if (data.payment_mode === SalesPaymentModesDropDown.BANK) {
                        setPaymentMode(SalesPaymentModesDropDown.BANK);
                        setBankName(
                            data?.Transactions ? data?.Transactions[2]?.account_name : ""
                        );
                        setBankNumber(
                            data?.Transactions
                                ? data?.Transactions[2]?.account_number?.toString()
                                : ""
                        );
                    } else if (data.payment_mode === SalesPaymentModesDropDown.CREDIT) {
                        setPaymentMode(SalesPaymentModesDropDown.CREDIT);
                    }
                    setPaymentNo(data.particulars);
                    setAmtPaid(data.amt_paid);
                    // setCurrentBal('')
                    // setPreviousBal('-3800')
                    // setOverAllBal('')
                    // setTotalItems(data.delivery_mode)
                    // setTotalQty(data.delivery_details)
                    setFinalAmount(data.grand_total);
                    setGTotal(data.grand_total);
                    setTotalItems(data.salesItems?.length?.toString());
                    const totalQuantity = data.salesItems.reduce(
                        (total, item) => total + (item.quantity || 0),
                        0
                    );
                    setTotalQty(totalQuantity.toString());
                    setPaymentNo(data.particulars);
                    setTotalBillAmt(data.total_amount);
                    setTotalDisAmt(data.total_discount);
                    setPreviousBal(data.prev_balance);
                    // setTotal(data.total_balance);
                    setBalance(data.credit);
                    setBankRecevied(data.bank_received);
                } catch (error) {
                    console.error("Error fetching sales data:", error);
                } finally {
                    setHideOverlayLoader();
                }
            })();
        }
    }, [formType, id]);

    // String(date.getMinutes()).padStart(2, '0')
    // const printTime = () => {
    //     const date = new Date();
    //     const h = date.getHours();
    //     const m = date.getMinutes();
    //     const s = date.getSeconds();  // Get seconds

    //     const ampm = h >= 12 ? "PM" : "AM";

    //     // Format hours, minutes, and seconds
    //     const formattedHours = (h % 12 || 12).toString().padStart(2, "0");
    //     const formattedMinutes = m.toString().padStart(2, "0");
    //     const formattedSeconds = s.toString().padStart(2, "0");

    //     const formattedTimeString = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

    //     setBillTime(formattedTimeString);
    // }

    // useEffect(() => {
    //     const printTime = () => {
    //         const date = new Date();
    //         const h = date.getHours();
    //         const m = date.getMinutes();
    //         const s = date.getSeconds();

    //         const ampm = h >= 12 ? "PM" : "AM";
    //         const formattedHours = (h % 12 || 12).toString().padStart(2, "0");
    //         const formattedMinutes = m.toString().padStart(2, "0");
    //         const formattedSeconds = s.toString().padStart(2, "0");

    //         const newTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

    //         // Only update state if the time has changed
    //         setBillTime((prevTime) => (prevTime !== newTime ? newTime : prevTime));
    //     };

    //     printTime(); // Call immediately to set the initial time
    //     const intervalId = setInterval(printTime, 1000);

    //     return () => clearInterval(intervalId);
    // }, []);

    // setInterval(printTime, 1000);

    const onCustomer = () => {
        const addData: CustomerType = {
            branchId: parseInt(branchId),
            nameTag: nameTag,
            accountName: accountName,
            contactNumber1: parseInt(
                contactNumber1 !== undefined ? contactNumber1 : ""
            ),
            contactNumber2: parseInt(
                contactNumber2 !== undefined ? contactNumber2 : ""
            ),
            address1: address1,
            address2: address2,
            area: area,
            place: place,
            district: district,
            pincode: parseInt(pincode !== undefined ? pincode : ""),
            state: state,
            country: country,
            route: route,
            aadharNumber: parseInt(aadharNumber !== undefined ? aadharNumber : ""),
            gstNo: gstNumber !== undefined ? gstNumber : "",
            // branch: `${AuthUser?.branchId}`
        };

        if (formType === FormTypeList.ADD) {
            dispatch(addCustomerAction(addData))
                .then((text) => {
                    toast(text, ToastTypes.SUCCESS);
                    api()
                        .get(`/customer/recent/`)
                        .then((res) => {
                            const result: CustomerType = res.data.data;
                            setCustomer({
                                value: result?.accountNumber!,
                                label: result?.accountName!.toString(),
                                name: result?.accountName!.toString(),
                                conNo: result?.contactNumber1!.toString(),
                                score: result?.score!.toString(),
                            });
                            setCustomerName(result?.accountName!.toString());
                            setCustomerContact(result?.contactNumber1!.toString());
                            setPreviousPoints(parseInt(result?.score!));
                        });
                    setBranch("");
                    setNameTag("");
                    setAccountName("");
                    setContactNumber1("");
                    setContactNumber2("");
                    setAddress1("");
                    setAddress2("");
                    setArea("");
                    setPlace("");
                    setDistrict("");
                    setPincode("");
                    setState("");
                    setCountry("");
                    setRoute("");
                    setModal(false);
                })
                .catch((text) => {
                    toast(text, ToastTypes.ERROR);
                });
        }
    };

    useEffect(() => {
        if (CustomerList) {
            let DefaultCustomer = CustomerList?.find(
                (c) => c.accountName?.toLocaleLowerCase() === "cash sales"
            );
            if (DefaultCustomer) {
                setCustomer(
                    DefaultCustomer
                        ? {
                            label:
                                DefaultCustomer?.accountName!.toString() +
                                "-" +
                                DefaultCustomer?.contactNumber1,
                            name: DefaultCustomer?.accountName!.toString(),
                            value: DefaultCustomer?.accountNumber
                                ? DefaultCustomer?.accountNumber
                                : 0,
                            conNo: DefaultCustomer
                                ? DefaultCustomer?.contactNumber1!.toString()
                                : "",
                            score: DefaultCustomer?.score ? DefaultCustomer?.score : "0",
                        }
                        : null
                );
                setCustomerName(
                    DefaultCustomer ? DefaultCustomer?.accountName!.toString() : ""
                );
                setCustomerContact(
                    DefaultCustomer ? DefaultCustomer?.contactNumber1!.toString() : ""
                );
                setPreviousPoints(
                    DefaultCustomer?.score ? parseInt(DefaultCustomer?.score) : 0
                );
            }
        }
    }, [CustomerList]);

    return (
        <>
            <Modal
                headerText="Sales"
                onClose={handleFormClose}
                centered
                size="xl"
                visible={showSalesView}
            >
                <Sales
                    setFormType={setFormType}
                    setId={setId}
                    onclose={handleFormClose}
                />
            </Modal>
            <Modal
                headerText={"Add Area"}
                visible={areaModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="areaName"
                    value={areaName}
                    onChange={handleAreaName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => resetStates()}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onArea}
                >
                    save
                </button>
            </Modal>
            <Modal
                headerText={"Add Place"}
                visible={placeModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="placeName"
                    value={placeName}
                    onChange={handlePlaceName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => resetStates()}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onPlace}
                >
                    save
                </button>
            </Modal>
            <Modal
                headerText={"Add District"}
                visible={districtModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="districtName"
                    value={districtName}
                    onChange={handleDistrictName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => resetStates()}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onDistrict}
                >
                    save
                </button>
            </Modal>
            <Modal
                headerText={"Add State"}
                visible={stateModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="stateName"
                    value={stateName}
                    onChange={handleStateName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => resetStates()}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onState}
                >
                    save
                </button>
            </Modal>
            <Modal
                headerText={"Add Country"}
                visible={countryModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="countryName"
                    value={countryName}
                    onChange={handleCountryName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => resetStates()}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onCountry}
                >
                    save
                </button>
            </Modal>
            <Modal
                headerText={"Add Route"}
                visible={routeModal}
                onClose={handleModalClose}
                centered
                size="sm"
            >
                <FormInput
                    name="routeName"
                    value={routeName}
                    onChange={handleRouteName}
                    className="mb-3"
                />
                <button
                    className="btn btn-sm fw-bold btn-danger px-2"
                    onClick={() => resetStates()}
                >
                    Add New
                </button>
                <button
                    type="button"
                    className="btn btn-sm fw-bold btn-primary m-2"
                    onClick={onRoute}
                >
                    save
                </button>
            </Modal>
            <Modal
                headerText={"Customer"}
                visible={modal}
                onClose={handleFormClose}
                centered
                size="lg"
            >
                <div className="row">
                    <div className="col-4">
                        <FormInput
                            name="branchId"
                            label="Branch Name"
                            labelClassName="required"
                            required={true}
                            value={branchId}
                            onChange={handleBranch}
                            errorText={branchIdError}
                            type="select"
                        >
                            <option value="">Select Branch</option>
                            {/* {BranchList.map((br: BranchType, i) => {
                                return <option value={br.id} key={i}>{br.name}</option>
                            })} */}
                            {clients?.map((client) => (
                                <option key={client.id} value={client.id}>
                                    {client.place}
                                </option>
                            ))}
                        </FormInput>
                    </div>
                    <div className="col-2">
                        <FormInput
                            name="nameTag"
                            label="Name Tag"
                            labelClassName="required"
                            required={true}
                            value={nameTag}
                            onChange={handleTag}
                            errorText={nameTagError}
                            type="select"
                        >
                            <option value="">Select Tag</option>
                            {[
                                "Mr.",
                                "Mrs.",
                                "Miss.",
                                "Master.",
                                "Baby.",
                                "Dr.",
                                "Er.",
                                "N/A",
                            ].map((tag, i) => {
                                return (
                                    <option value={tag} key={i}>
                                        {tag}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </div>

                    <div className="col-6">
                        <FormInput
                            name="accountName"
                            label="Account Name"
                            labelClassName="required"
                            required={true}
                            value={accountName}
                            onChange={handleAccountName}
                            errorText={accountNameError}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FormInput
                            name="contactNumber1"
                            label="Primary Contact Number"
                            labelClassName="required"
                            required={true}
                            value={contactNumber1}
                            onChange={handleContactNumber1}
                            errorText={contactNumber1Error}
                        />
                    </div>
                    <div className="col-6">
                        <FormInput
                            name="contactNumber2"
                            label="Secondary Contact Number"
                            labelClassName="required"
                            value={contactNumber2}
                            onChange={handleContactNumber2}
                            errorText={contactNumber2Error}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FormInput
                            name="address1"
                            label="Address 1"
                            labelClassName="required"
                            value={address1}
                            onChange={handleAddress1}
                            errorText={address1Error}
                        />
                    </div>
                    <div className="col-6">
                        <FormInput
                            name="address2"
                            label="Address 2"
                            labelClassName="required"
                            value={address2}
                            onChange={handleAddress2}
                            errorText={address2Error}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <FormInput
                            name="area"
                            label="Area"
                            labelClassName="required"
                            value={area}
                            onChange={handleArea}
                            errorText={areaError}
                            type="select"
                            icon={
                                <i
                                    data-toggle="modal"
                                    className={`fe-plus-circle me-1 p-1`}
                                    onClick={() => setAreaModal(true)}
                                >
                                    <div className="modal fade"></div>
                                </i>
                            }
                        >
                            <option value="">Select</option>
                            {DropDownList?.filter((ddl) => ddl.category === "area")?.map(
                                (ddl, i) => {
                                    return (
                                        <option value={ddl.title} key={i}>
                                            {ddl.title}
                                        </option>
                                    );
                                }
                            )}
                        </FormInput>
                    </div>
                    <div className="col-4">
                        <FormInput
                            name="place"
                            label="Place"
                            labelClassName="required"
                            value={place}
                            onChange={handlePlace}
                            errorText={placeError}
                            type="select"
                            icon={
                                <i
                                    data-toggle="modal"
                                    className={`fe-plus-circle me-1 p-1 cursor-pointer`}
                                    onClick={() => setPlaceModal(true)}
                                >
                                    <div className="modal fade"></div>
                                </i>
                            }
                        >
                            <option value="">Select</option>
                            {DropDownList?.filter(
                                (ddl: DropDownType) => ddl.category === "place"
                            )?.map((ddl, i) => {
                                return (
                                    <option value={ddl.title} key={i}>
                                        {ddl.title}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </div>
                    <div className="col-4">
                        <FormInput
                            name="district"
                            label="District"
                            labelClassName="required"
                            value={district}
                            onChange={handleDistrict}
                            errorText={districtError}
                            type="select"
                            icon={
                                <i
                                    data-toggle="modal"
                                    className={`fe-plus-circle me-1 p-1`}
                                    onClick={() => setDistrictModal(true)}
                                >
                                    <div className="modal fade"></div>
                                </i>
                            }
                        >
                            <option value="">Select</option>
                            {DropDownList?.filter(
                                (ddl: DropDownType) => ddl.category === "district"
                            )?.map((ddl, i) => {
                                return (
                                    <option value={ddl.title} key={i}>
                                        {ddl.title}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </div>
                    <div className="col-4">
                        <FormInput
                            name="state"
                            label="State"
                            labelClassName="required"
                            value={state}
                            onChange={handleState}
                            errorText={stateError}
                            type="select"
                            icon={
                                <i
                                    data-toggle="modal"
                                    className={`fe-plus-circle me-1 p-1`}
                                    onClick={() => setStateModal(true)}
                                ></i>
                            }
                        >
                            <option value="">Select</option>
                            {DropDownList?.filter(
                                (ddl: DropDownType) => ddl.category === "state"
                            )?.map((ddl, i) => {
                                return (
                                    <option value={ddl.title} key={i}>
                                        {ddl.title}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </div>
                    <div className="col-4">
                        <FormInput
                            name="country"
                            label="Country"
                            labelClassName="required"
                            value={country}
                            onChange={handleCountry}
                            errorText={countryError}
                            type="select"
                            icon={
                                <i
                                    data-toggle="modal"
                                    className={`fe-plus-circle me-1 p-1`}
                                    onClick={() => setCountryModal(true)}
                                >
                                    <div className="modal fade"></div>
                                </i>
                            }
                        >
                            <option value="">Select</option>
                            {DropDownList?.filter(
                                (ddl: DropDownType) => ddl.category === "country"
                            )?.map((ddl, i) => {
                                return (
                                    <option value={ddl.title} key={i}>
                                        {ddl.title}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </div>

                    <div className="col-4">
                        <FormInput
                            name="route"
                            label="Route"
                            labelClassName="required"
                            value={route}
                            onChange={handleRoute}
                            errorText={routeError}
                            type="select"
                            icon={
                                <i
                                    data-toggle="modal"
                                    className={`fe-plus-circle me-1 p-1`}
                                    onClick={() => setRouteModal(true)}
                                >
                                    <div className="modal fade"></div>
                                </i>
                            }
                        >
                            <option value="">Select</option>
                            {DropDownList?.filter(
                                (ddl: DropDownType) => ddl.category === "route"
                            )?.map((ddl, i) => {
                                return (
                                    <option value={ddl.title} key={i}>
                                        {ddl.title}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <FormInput
                            name="aadharNumber"
                            label="Aadhar Number"
                            labelClassName="required"
                            value={aadharNumber}
                            onChange={handleAadharNumber}
                            errorText={aadharNumberError}
                            type="text"
                        />
                    </div>
                    <div className="col-4">
                        <FormInput
                            name="gstNumber"
                            label="GST Number"
                            labelClassName="required"
                            value={gstNumber}
                            onChange={handlegstNumber}
                            errorText={gstNumberError}
                            type="text"
                        />
                    </div>
                </div>

                <div className="text-end">
                    <button
                        className="btn btn-sm fw-bold btn-danger"
                        onClick={() => resetStates()}
                    >
                        Reset
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm fw-bold btn-primary m-2"
                        onClick={onCustomer}
                    >
                        Save
                    </button>
                </div>
            </Modal>

            <UserLayout>
                {/* <h4>Sales Master</h4> */}
                <div className="row">
                    <div className="col-lg-9">
                        {/* <div className="col-lg-12"> */}
                        <div className="card shadow-lg">
                            <div className="card-body p-1">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="col-lg-12 hstack">
                                            <div className="col-3">
                                                <label className="">Contact Number</label>
                                            </div>
                                            <div className="col-8 mt-1 mb-1">
                                                <Select
                                                    options={
                                                        CustomerList?.map((f) => {
                                                            return {
                                                                label:
                                                                    f?.accountName! + "-" + f?.contactNumber1 ||
                                                                    "",
                                                                name: f?.accountName ? f?.accountName : "",
                                                                value: f?.accountNumber || 0,
                                                                score: f?.score ? f?.score?.toString() : "0",
                                                                conNo: f?.contactNumber1
                                                                    ? f?.contactNumber1?.toString()
                                                                    : "",
                                                            };
                                                        }) || []
                                                    }
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    onChange={(v) => {
                                                        setCustomer(v);
                                                        setCustomerName(v?.name ? v.name : "");
                                                        setCustomerContact(v?.conNo?.toString()!);
                                                        setPreviousPoints(
                                                            v?.score ? parseInt(v?.score!) : 0
                                                        );
                                                    }}
                                                    value={customer}
                                                    styles={customStyles2}
                                                />
                                            </div>
                                            <div className="col-1">
                                                {/* {<i data-toggle="modal" className={`fe-plus-circle me-1 p-1 cursor-pointer`} onClick={() => setModal(true)}>
                                                    <div className="modal fade"></div>
                                                </i>} */}
                                                <button
                                                    title="Add New Customer"
                                                    className="btn btn-sm btn-warning "
                                                    onClick={() => setModal(true)}
                                                >
                                                    <UserPlus size={15} width={18} />
                                                </button>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 hstack">
                                            <div className="col-3">
                                                <label htmlFor="">Customer Name</label>
                                            </div>
                                            <div className="col-9 mt-1">
                                                <FormInput
                                                    name="customerName"
                                                    label=""
                                                    labelClassName="required"
                                                    value={customerName}
                                                    // onChange={handleSupName}
                                                    errorText={customerNameError}
                                                    containerClass="mb-1 text-end"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 hstack">
                                            <div className="col-3">
                                                <label htmlFor="">Referred By</label>
                                            </div>
                                            <div className="col-9 mt-1">
                                                <FormInput
                                                    name="Referred By"
                                                    label=""
                                                    labelClassName="required "
                                                    value={refBy}
                                                    onChange={handleRefBy}
                                                    placeholder="Referred By"
                                                    errorText={refByError}
                                                    containerClass="mb-1"
                                                    // ref={refByRef}
                                                    // onKeyDown={(e) => handleKeyDown(e, invoiceDateRef, 'invoiceDate')}
                                                    className={"form-control"}
                                                    // className={focusedField === 'refBy' ? "border border-4 border-primary rounded" : ""}
                                                    // disabled={showViewForm}
                                                    type="select"
                                                >
                                                    <option value="">Select</option>
                                                    {AccountLists?.filter(
                                                        (al) =>
                                                            al.groupName === "representer" ||
                                                            al.groupName === "Representer"
                                                    ).map((al, i) => {
                                                        return (
                                                            <option value={al.accountNumber} key={i}>
                                                                {al.accountName}
                                                            </option>
                                                        );
                                                    })}
                                                </FormInput>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            {/* <div className="col-10"> */}
                                            <h4 className=" fs-4 badge bg-info">
                                                Customer Points : {previousPoints}
                                            </h4>
                                            <h4
                                                className=" fs-4 badge bg-info"
                                                style={{ marginLeft: "5px" }}
                                            >
                                                Current Balance : {currentBal}
                                            </h4>
                                            {/* </div> */}
                                            {previousPoints > 0 && (
                                                <button
                                                    className="ms-3 btn btn-sm fw-bold btn-success"
                                                    onClick={redeemCustomerPoints}
                                                >
                                                    Redeem
                                                </button>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        {/* <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Customer No </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='customerNo'
                                                label=''
                                                labelClassName="required"
                                                value={customerNo}
                                                // onChange={handleInvoiceNo}
                                                errorText={customerNoError}
                                                containerClass="mb-1 text-end"
                                                width={50}

                                            />
                                        </div>
                                    </div> */}
                                        {/* <div className="col-3">
                                            <label htmlFor="">Contact Number</label>
                                        </div>
                                        <div className="col-8 mt-1">
                                            <FormInput
                                                name='customerContact'
                                                label=''
                                                labelClassName="required"
                                                value={customerContact}
                                                onChange={handleCustomerContact}
                                                errorText={customerContactError}
                                                containerClass="mb-1"
                                                width={50}

                                            />
                                        </div> */}
                                        <div className="col-lg-12 hstack">
                                            <div className="col-3">
                                                <label htmlFor="">Financial Year </label>
                                            </div>
                                            <div className="col-9">
                                                <FormInput
                                                    name="year"
                                                    label=""
                                                    labelClassName="required"
                                                    value={financialYear}
                                                    // onChange={handleProductName}
                                                    // errorText={financialYearError}
                                                    containerClass="mb-1 text-end"
                                                    width={50}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 hstack">
                                            <div className="col-3">
                                                <label htmlFor="">Sales Type</label>
                                            </div>
                                            <div className="col-9 mt-1">
                                                <FormInput
                                                    name="Sales Type"
                                                    label=""
                                                    labelClassName="required"
                                                    value={invoiceType}
                                                    onChange={handleInvoiceType}
                                                    placeholder="Sales Type"
                                                    errorText={invoiceTypeError}
                                                    containerClass="mb-1"
                                                    // disabled={showViewForm}
                                                    type="select"
                                                >
                                                    <option value="">Select</option>
                                                    {SALES_TYPE_DROPS.map((a, i) => {
                                                        return (
                                                            <Option value={a.value.toString()} key={i}>
                                                                {a.text}
                                                            </Option>
                                                        );
                                                    })}
                                                </FormInput>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 hstack">
                                            <div className="col-3">
                                                <label htmlFor="discountInput">Discount</label>
                                            </div>
                                            <div className="col-9 mt-1">
                                                <input
                                                    id="discountInput"
                                                    className="form-control"
                                                    type="number"
                                                    list="discountOptions"
                                                    value={discountValue}
                                                    onChange={(e) => setDiscountValue(e.target.value)}
                                                    min="0"
                                                    max="100"
                                                    step="0.01"
                                                />
                                                <datalist id="discountOptions">
                                                    <option value="0" label="0%"></option>
                                                    <option value="5" label="5%"></option>
                                                    <option value="12" label="12%"></option>
                                                    <option value="18" label="18%"></option>
                                                    <option value="28" label="28%"></option>
                                                </datalist>
                                            </div>
                                        </div>

                                        {/* <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Cust.Address</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='customerAddress'
                                                label=''
                                                labelClassName="required"
                                                value={customerAddress}
                                                // onChange={handleSupAddress}
                                                errorText={customerAddressError}
                                                containerClass="mb-1"
                                                width={50}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Place</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='customerPlace'
                                                label=''
                                                labelClassName="required"
                                                value={customerPlace}
                                                // onChange={handleSupPlace}
                                                errorText={customerPlaceError}
                                                containerClass="p-0 mb-1"
                                                width={50}

                                            />
                                        </div>
                                    </div> */}
                                    </div>

                                    {/* <div className="col-lg-4">
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Referred By</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='refBy'
                                                label=''
                                                labelClassName="required"
                                                value={refBy}
                                                // onChange={handlerefBy}
                                                errorText={refByError}
                                                containerClass="p-0 mb-1"
                                                width={50}
                                                readOnly={true}
                                                disabled={true}
                                                type="select"
                                            >
                                                <option value=""></option>
                                                {AccountLists.filter((al) => al.groupName === 'representer' || al.groupName === 'Representer').map((al) => {
                                                    return <option value={al.accountNumber}>{al.accountName}</option>
                                                })
                                                }
                                            </FormInput>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Branch</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='branch'
                                                label=''
                                                labelClassName="required"
                                                value={branch}
                                                // onChange={handlebranch}
                                                errorText={branchError}
                                                containerClass="p-0 mb-1"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>

                                   
                                </div> */}
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                        {/* <span className="mt-2 d-n">
                        <FormInput
                            name="hiddenGst"
                            label="Hidden GST"
                            value={hiddenGst.toString()}
                            type="checkbox"
                            onChange={handleHiddenGst}
                            onClick={() => setHiddenGst(false)}
                        />
                    </span> */}
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card shadow-lg">
                                    <div className="card-body">
                                        <div className="table-wrapper">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-truncate align-middle">S.No</th>
                                                        <th className="text-truncate align-middle">
                                                            Product Name
                                                        </th>
                                                        <th className="text-truncate align-middle">
                                                            Product Code
                                                        </th>
                                                        {!hiddenGst && (
                                                            <th className="text-truncate align-middle">
                                                                GST%
                                                            </th>
                                                        )}
                                                        <th className="text-truncate align-middle">
                                                            M.R.P
                                                        </th>
                                                        <th className="text-truncate align-middle">RATE</th>
                                                        <th className="text-truncate align-middle">QTY</th>
                                                        <th className="text-truncate align-middle">
                                                            DISCOUNT
                                                        </th>
                                                        <th className="text-truncate align-middle">PD %</th>
                                                        <th className="text-truncate align-middle">
                                                            PD.Rs
                                                        </th>
                                                        {!hiddenGst && (
                                                            <>
                                                                <th className="text-truncate align-middle">
                                                                    GST VALUE
                                                                </th>

                                                                <th className="text-truncate align-middle">
                                                                    Taxable
                                                                </th>
                                                            </>
                                                        )}
                                                        <th className="text-truncate align-middle">
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {salesItems.map((pi, i) => {
                                                        // if (i === 0) {
                                                        //     console.log('Row Data:', pi, 'Index:', i);
                                                        // }
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                {/* <td className="p-0">
                                                                    <Select
                                                                        // options={ProductList?.map(p => {
                                                                        //     return {
                                                                        //         label: (p?.productName + '-' + p?.productCode + '-' + p.sac + '-' + p.gst) || '',
                                                                        //         value: p.id?.toString() || ''
                                                                        //     }
                                                                        // })}
                                                                        options={StockList?.map((p) => {
                                                                            return {
                                                                                label:
                                                                                    p?.productName +
                                                                                    "-" +
                                                                                    p?.productCode +
                                                                                    "-" +
                                                                                    p?.sac +
                                                                                    "-" +
                                                                                    p?.gst || "",
                                                                                value: p.id?.toString() || "",
                                                                                name: p?.productName || '',
                                                                            };
                                                                        })}
                                                                        placeholder={false}
                                                                        isSearchable={true}
                                                                        isClearable={true}
                                                                        onChange={(val) =>
                                                                            handleProductName(val, pi.id.toString())
                                                                        }
                                                                        value={pi.productName}
                                                                        styles={customStyles}
                                                                    // menuIsOpen={true}
                                                                    // maxMenuHeight={180}
                                                                    // classNames={{
                                                                    //     container: (state) =>
                                                                    //         state.className ? 'form-select react-select' : 'form-select react-select',
                                                                    //     control: (state) =>
                                                                    //         state.className ? 'form-select react-select' : 'form-select react-select',
                                                                    //     indicatorsContainer: (state) =>
                                                                    //         state.className ? 'react-select mt-1' : 'react-select mt-1',
                                                                    // }}
                                                                    />
                                                                </td> */}
                                                                <td className="p-0 d-flex">
                                                                    <button
                                                                        className="btn btn-sm py-0 px-1"
                                                                        onClick={() => {
                                                                            const updatedItems = salesItems.filter(
                                                                                (_, index) => index !== i
                                                                            );
                                                                            setSalesItems(updatedItems);
                                                                        }}
                                                                    >
                                                                        X
                                                                    </button>
                                                                    <ProductSearch
                                                                        key={pi.id}
                                                                        editProductName={
                                                                            FormTypeList.EDIT ? pi.productName : null
                                                                        }
                                                                        handleProductName={handleProductName}
                                                                        pi={pi}
                                                                        resetSearch={resetSearch}
                                                                        setResetSearch={setResetSearch} // Pass the setResetSearch function
                                                                        handleKeyDown={handleKeyDown}
                                                                        refs={refs}

                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="productCode"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.productCode}
                                                                        onChange={(e) =>
                                                                            handleProductCode(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={productNameError}
                                                                        readOnly={true}
                                                                        containerClass="w-100 read-only-field"
                                                                    />
                                                                </td>
                                                                {!hiddenGst && (
                                                                    <td className="p-0">
                                                                        <FormInput
                                                                            name="gst"
                                                                            label=""
                                                                            labelClassName="required"
                                                                            value={pi.gstPercent}
                                                                            onChange={(e) =>
                                                                                handleGst(
                                                                                    e.target.value,
                                                                                    pi.id.toString()
                                                                                )
                                                                            }
                                                                            errorText={gstError}
                                                                            readOnly={true}
                                                                            containerClass="read-only-field"
                                                                        />
                                                                    </td>
                                                                )}
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="mrp"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.mrp}
                                                                        onChange={(e) =>
                                                                            handleRate(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        containerClass="read-only-field"
                                                                        type="number"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="Rate"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.rate}
                                                                        onChange={(e) =>
                                                                            handleRate(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        containerClass="read-only-field"
                                                                        type="number"
                                                                        readOnly={true}
                                                                    />
                                                                </td>

                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name={`qty-${pi.id}`}
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.qty}
                                                                        onChange={(e) =>
                                                                            handleQty(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass=""
                                                                        // type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`qty-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(e, "pdPercent", pi.id.toString())
                                                                        }
                                                                        className={
                                                                            focusedField === `qty-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>

                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="Discount"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.disAmt}
                                                                        onChange={(e) =>
                                                                            handleDiscountAmt(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        containerClass="read-only-field"
                                                                        type="number"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="pdPercent"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.pdPercent}
                                                                        onChange={(e) =>
                                                                            handlePDPercentChange(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`pdPercent-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(
                                                                                e,
                                                                                "pdRupees",
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        className={
                                                                            focusedField === `pdPercent-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="pdRupees"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={pi.pdAmount}
                                                                        onChange={(e) =>
                                                                            handlePDAmountChange(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        type="number"
                                                                        ref={(el) =>
                                                                            (refs.current[`pdRupees-${pi.id}`] = el)
                                                                        }
                                                                        onKeyDown={(e) =>
                                                                            handleKeyDown(
                                                                                e,
                                                                                "productName",
                                                                                pi.id.toString(),
                                                                                true
                                                                            )
                                                                        }
                                                                        className={
                                                                            focusedField === `pdRupees-${pi.id}`
                                                                                ? "border border-2 border-primary rounded"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                                {!hiddenGst && (
                                                                    <>
                                                                        <td className="p-0">
                                                                            <FormInput
                                                                                name="GST VALUE"
                                                                                label=""
                                                                                labelClassName="required"
                                                                                value={pi.gstValue}
                                                                                onChange={(e) =>
                                                                                    handleGSTValue(
                                                                                        e.target.value,
                                                                                        pi.id.toString()
                                                                                    )
                                                                                }
                                                                                containerClass=" read-only-field"
                                                                                readOnly={true}
                                                                            />
                                                                        </td>
                                                                        <td className="p-0">
                                                                            <FormInput
                                                                                name="taxable"
                                                                                label=""
                                                                                labelClassName="required"
                                                                                value={parseFloat(pi.taxable!).toFixed(
                                                                                    2
                                                                                )}
                                                                                onChange={(e) =>
                                                                                    handleTaxable(
                                                                                        e.target.value,
                                                                                        pi.id.toString()
                                                                                    )
                                                                                }
                                                                                errorText={gstError}
                                                                                containerClass=" read-only-field"
                                                                                readOnly={true}
                                                                            />
                                                                        </td>
                                                                    </>
                                                                )}
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name="TotalAmt"
                                                                        label=""
                                                                        labelClassName="required"
                                                                        value={parseFloat(pi.totalAmt!).toFixed(2)}
                                                                        onChange={(e) =>
                                                                            handleTotAmt(
                                                                                e.target.value,
                                                                                pi.id.toString()
                                                                            )
                                                                        }
                                                                        errorText={gstError}
                                                                        containerClass="w-100 read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div
                                            className="d-flex justify-content-between hstack gap-1 mt-2"
                                            style={{ padding: "0 45px" }}
                                        >
                                            <div
                                                className="d-flex justify-content-between"
                                                style={{ width: "58%" }}
                                            >
                                                {/* <h5 style={{ color: "red" }}>Total Items: {salesItems.filter(f =>
                                                f.productName?.label && f.productName?.value
                                            ).length}</h5> */}
                                                <h5 style={{ color: "red" }}>
                                                    Total Items: {totalItems}
                                                </h5>
                                                <h5 style={{ color: "green" }}>
                                                    Total Quantity:{" "}
                                                    {salesItems.reduce(
                                                        (total, item) =>
                                                            total + parseFloat(item.qty || "0"),
                                                        0
                                                    )}
                                                </h5>
                                            </div>

                                            {/* <button className="btn btn-secondary" onClick={() => resetStates()}>Clear</button> */}
                                            <div>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleView}
                                                >
                                                    View
                                                </button>
                                                {/* <button className="btn btn-primary" onClick={handleSave}>{formType === FormTypeList.ADD ? 'Save' : 'Update'}</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        {/* <div className="card shadow-lg ">
                        <div className="card-body"> */}
                        <p>
                            <span className="fs-4 badge bg-primary">
                                {LocalFormatDate(billDate?.toDateString())}
                            </span>
                            {/* <span className="fs-4 badge bg-primary ms-1">{billTime}</span> */}
                            <Clock />
                        </p>
                        <p>
                            <span className="fs-3 badge bg-danger">Bill No: {billNo}</span>
                        </p>

                        <div className="card shadow-lg ">
                            <div className="card-body">
                                <div>
                                    <h4>Total Amt: {TotalBillAmt}</h4>
                                </div>
                                <div>
                                    <h4>Total Dis: {TotalDisAmt}</h4>
                                </div>
                                <div className="row">
                                    <h4 className="text-center">Grand Total</h4>
                                    <h2
                                        className="text-center text-white "
                                        style={{ backgroundColor: "#032566" }}
                                    >
                                        {rupeeFormat(finalAmount)}
                                    </h2>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <label>Total Items</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="totalItems"
                                                        label=""
                                                        labelClassName="required"
                                                        value={totalItems}
                                                        onChange={handleTotalItems}
                                                        errorText={totalItemsError}
                                                        containerClass="w-100 read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Total Quantity</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="totalQty"
                                                        label=""
                                                        labelClassName="required"
                                                        value={totalQty}
                                                        onChange={handleTotalQty}
                                                        errorText={totalQtyError}
                                                        containerClass="w-100 read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Pack No</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="packNo"
                                                        label=""
                                                        labelClassName="required"
                                                        value={packNo}
                                                        onChange={handlePackNo}
                                                        errorText={packNoError}
                                                        containerClass="w-100 read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Your Savings</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="yourSavings"
                                                        label=""
                                                        labelClassName="required"
                                                        value={yourSavings}
                                                        onChange={handleYourSavings}
                                                        errorText={yourSavingsError}
                                                        containerClass="w-100 read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            {!hiddenGst && (
                                                <>
                                                    <tr>
                                                        <td>
                                                            <label>NET IGST</label>
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name="netIgst"
                                                                label=""
                                                                labelClassName="required"
                                                                value={netIgst}
                                                                onChange={handleNetIgst}
                                                                errorText={netIgstError}
                                                                containerClass="w-100 read-only-field"
                                                                type="number"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label>NET CGST</label>
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name="netCgst"
                                                                label=""
                                                                labelClassName="required"
                                                                value={netCgst}
                                                                onChange={handleNetCgst}
                                                                errorText={netCgstError}
                                                                containerClass="w-100 read-only-field"
                                                                type="number"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label>NET SGST</label>
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name="netSgst"
                                                                label=""
                                                                labelClassName="required"
                                                                value={netSgst}
                                                                onChange={handleNetSgst}
                                                                errorText={netSgstError}
                                                                containerClass="w-100 read-only-field"
                                                                type="number"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <label>Net Taxable</label>
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name="netTaxable"
                                                                label=""
                                                                labelClassName="required"
                                                                value={netTaxable}
                                                                onChange={handleNetTaxable}
                                                                errorText={netTaxableError}
                                                                containerClass="read-only-field"
                                                                type="number"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                            {!hiddenGst && (
                                                <tr>
                                                    <td>
                                                        <label>Net GST Value</label>
                                                    </td>
                                                    <td className="p-0">
                                                        <FormInput
                                                            name="netGSTValue"
                                                            label=""
                                                            labelClassName="required"
                                                            value={netGSTValue}
                                                            onChange={handleNetGST}
                                                            errorText={netGSTValueError}
                                                            containerClass="read-only-field"
                                                            type="number"
                                                            readOnly={true}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>
                                                    <label>Add</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="Add"
                                                        label=""
                                                        labelClassName="required"
                                                        value={add}
                                                        onChange={handleAdd}
                                                        errorText={addError}
                                                        containerClass="w-100"
                                                        type="number"
                                                        ref={addRef}
                                                        onKeyDown={(e) =>
                                                            handleKeyDownForRight(e, lessRef, "less")
                                                        }
                                                        className={
                                                            focusedField === `add`
                                                                ? "border border-2 border-primary rounded"
                                                                : ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Less</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="Less"
                                                        label=""
                                                        labelClassName="required"
                                                        value={less}
                                                        onChange={handleLess}
                                                        errorText={lessError}
                                                        containerClass="w-100"
                                                        type="number"
                                                        ref={lessRef}
                                                        onKeyDown={(e) =>
                                                            handleKeyDownForRight(e, lessRef, "less")
                                                        }
                                                        className={
                                                            focusedField === `less`
                                                                ? "border border-2 border-primary rounded"
                                                                : ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Net Amt</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="Net Amt"
                                                        label=""
                                                        labelClassName="required"
                                                        value={netAmt}
                                                        onChange={handleNetAmt}
                                                        containerClass="read-only-field"
                                                        errorText={lessError}
                                                        // containerClass="w-100"
                                                        readOnly={true}
                                                        type="number"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Round Off</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="RoundOff"
                                                        label=""
                                                        labelClassName="required"
                                                        value={roundOff}
                                                        onChange={handleRoundOff}
                                                        errorText={roundOffError}
                                                        containerClass="read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Grand Total</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="grandTotal"
                                                        label=""
                                                        labelClassName="required"
                                                        value={gtotal}
                                                        onChange={handleGrandTotal}
                                                        errorText={gtotalError}
                                                        containerClass="w-100 read-only-field"
                                                        readOnly={true}
                                                        type="number"
                                                    />
                                                </td>
                                            </tr>

                                            {/* <tr>
                                            <td><label>Amt In Words</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='AmtInWords'
                                                    label=''
                                                    labelClassName="required"
                                                    value={amtInWords}
                                                    onChange={handleAmtInWords}
                                                    errorText={amtInWordsError}
                                                    containerClass="w-100"
                                                // type="number"
                                                />
                                            </td>
                                        </tr> */}
                                            {/* <tr>
                                            <td><label>Payment Mode</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='paymentMode'
                                                    label=''
                                                    labelClassName="required"
                                                    value={paymentMode}
                                                    onChange={handlePaymentMode}
                                                    errorText={paymentModeError}
                                                    containerClass="w-100"
                                                    type="select"
                                                >
                                                    <option value="">Select</option>
                                                    {SALSE_PAYMENT_MODES.map((a, i) => {
                                                        return <Option value={a.value.toString()} key={i}>{a.text}</Option>
                                                    })}
                                                </FormInput>
                                            </td>
                                        </tr> */}
                                            {/* {paymentMode === SalesPaymentModesDropDown.BANK && <tr>
                                            <td><label>Bank</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='bankNumber'
                                                    label=''
                                                    labelClassName="required"
                                                    value={bankNumber}
                                                    onChange={handleBank}
                                                    containerClass="w-100"
                                                    type="select"
                                                >
                                                    <option value="">Select</option>
                                                    {AccountLists.filter((al) => al.groupName === 'main' && al.accountName?.toLowerCase().includes('bank')).map((al) => {
                                                        return <option value={al.accountNumber}>{al.accountName}</option>
                                                    })
                                                    }
                                                </FormInput>
                                            </td>
                                        </tr>} */}
                                            <tr>
                                                <td>
                                                    <label>Amt Paid</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="AmtPaid"
                                                        label=""
                                                        labelClassName="required"
                                                        value={amtPaid}
                                                        onChange={handleAmtPaid}
                                                        errorText={amtPaidError}
                                                        containerClass="w-100 read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            {/* <tr>
                                            <td><label>Balance</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='Balance'
                                                    label=''
                                                    labelClassName="required"
                                                    value={balance}
                                                    onChange={handleBalance}
                                                    errorText={balanceError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Ten.Amt</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='tenderedAmt'
                                                    label=''
                                                    labelClassName="required"
                                                    value={tenderedAmt}
                                                    onChange={handleTenderedAmt}
                                                    errorText={tenderedAmtError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Ten.Return</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='tenderedReturn'
                                                    label=''
                                                    labelClassName="required"
                                                    value={tenderedReturn}
                                                    onChange={handleTenderedReturn}
                                                    errorText={tenderedReturnError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Tendered Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='tenderedBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={tenderedBal}
                                                    onChange={handleTenderedBal}
                                                    errorText={tenderedBalError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Current Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='currentBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={currentBal}
                                                    onChange={() => { }}
                                                    errorText={currentBalError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Previous Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='previousBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={previousBal}
                                                    onChange={() => { }}
                                                    errorText={previousBalError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>OverAll Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='overAllBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={overAllBal}
                                                    onChange={() => { }}
                                                    errorText={overAllBalError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr> */}
                                            <tr>
                                                <td>
                                                    <label>Previous Points</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="previouspoints"
                                                        label=""
                                                        labelClassName="required"
                                                        value={previousPoints}
                                                        onChange={handleTenderedBal}
                                                        // errorText={previouspointsError}
                                                        containerClass="w-100 read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Points Earned</label>
                                                </td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name="pointsEarned"
                                                        label=""
                                                        labelClassName="required"
                                                        value={pointsEarned}
                                                        onChange={handleTenderedBal}
                                                        // errorText={pointsEarnedError}
                                                        containerClass="w-100 read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex justify-content-end mt-2">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            if (parseFloat(gtotal) > 0) {
                                                setShowTenderModal(true);
                                            } else {
                                                toast("Please fill the items first", ToastTypes.ERROR);
                                            }
                                        }}
                                    >
                                        Pay Bill
                                    </button>
                                </div>
                            </div>
                        </div>
                        <p>
                            <span className="fs-4 badge bg-primary">User: {billedBy}</span>
                            <span className="fs-4 badge bg-primary ms-1">Sys: {sysNo}</span>
                        </p>
                    </div>
                </div>
            </UserLayout>

            <Modal
                headerText="Payment Particulars"
                onClose={handleFormClose}
                centered
                size="lg"
                visible={showTenderModal}
            >
                <>
                    <Form className="row">
                        <Form.Check
                            type="radio"
                            label="Cash/Bank"
                            value="cashAndBank"
                            checked={paymentMethod === "cashAndBank"}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                            style={{ fontWeight: "bold", fontSize: "18px" }}
                            className="col-4 mb-1  ms-2"
                        />
                        <Form.Check
                            type="radio"
                            label="Credit"
                            value="credit"
                            checked={paymentMethod === "credit"}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                            style={{ fontWeight: "bold", fontSize: "18px" }}
                            className="col-4 mb-1  ms-2"
                        />
                        {/* <div className="d-flex justify-content-end"> */}
                        {paymentMethod === "cashAndBank" && (
                            <h4 className="col-3 mb-1 ms-1 badge bg-danger fs-4">
                                Grand Total: {gtotal}
                            </h4>
                        )}
                        {/* </div> */}
                    </Form>
                    {paymentMethod === "cashAndBank" && (
                        <div className="">
                            <table className="table " style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Grand Total</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                className="fw-bold"
                                                type="number"
                                                name="grandTotal"
                                                value={gtotal}
                                                readOnly
                                                style={{ backgroundColor: "#DBF3DF", width: "100%" }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Previous Balance</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                className="fw-bold"
                                                type="number"
                                                name="previousBalance"
                                                value={parseFloat(previousBal).toFixed(2)}
                                                readOnly
                                                style={{ backgroundColor: "#DBF3DF" }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Total Balance</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                className="fw-bold"
                                                type="number"
                                                name="totalBalance"
                                                value={totalBal}
                                                readOnly
                                                style={{ backgroundColor: "#DBF3DF" }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Tender Amount</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                // className="fw-bold"
                                                type="number"
                                                name="tenderedAmt"
                                                value={tenderedAmt}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setTenderedAmt(value);

                                                    if (value !== "0") {
                                                        setCash(gtotal.toString());
                                                        // setCash(value?.toString());
                                                    }
                                                }}
                                                ref={tenderRef}
                                                onKeyDown={(e) =>
                                                    handleKeyDownForRight(e, card1Ref, "card1")
                                                }
                                                className={
                                                    focusedField === "tenderAmt"
                                                        ? "border border-2 border-primary rounded"
                                                        : "fw-bold"
                                                }
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <strong>Cash (C)</strong>
                                        </td>
                                        <td
                                            style={{
                                                padding: "0",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Form.Control
                                                type="number"
                                                className="fw-bold"
                                                name="cash"
                                                value={cash}
                                                readOnly={!cashEditable}
                                                style={{
                                                    backgroundColor: cashEditable ? "" : "#DBF3DF",
                                                    flex: 1,
                                                }}
                                                onChange={(e) => setCash(e.target.value)}
                                            />
                                            <button
                                                type="button"
                                                className=" btn btn-sm fw-bold btn-info p-1"
                                                onClick={() => setCashEditable((prev) => !prev)}
                                            >
                                                <i className="fe-edit noti-icon"></i>
                                            </button>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <strong>Card 1 (C1)</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                // className="fw-bold"
                                                type="number"
                                                name="card1"
                                                value={card1}
                                                onChange={(e) => setCard1(e.target.value || "0")}
                                                ref={card1Ref}
                                                onKeyDown={(e) =>
                                                    handleKeyDownForRight(e, card2Ref, "card2")
                                                }
                                                className={
                                                    focusedField === "card1"
                                                        ? "border border-2 border-primary rounded"
                                                        : "fw-bold"
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Card 2 (C2)</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                // className="fw-bold"
                                                type="number"
                                                name="card2"
                                                value={card2}
                                                onChange={(e) => setCard2(e.target.value || "0")}
                                                ref={card2Ref}
                                                onKeyDown={(e) =>
                                                    handleKeyDownForRight(e, online1Ref, "onlinePayment1")
                                                }
                                                className={
                                                    focusedField === "card2"
                                                        ? "border border-2 border-primary rounded"
                                                        : "fw-bold"
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Online Payment 1 (C3)</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                // className="fw-bold"
                                                type="number"
                                                name="onlinePayment1"
                                                value={onlinePayment1}
                                                onChange={(e) =>
                                                    setOnlinePayment1(e.target.value || "0")
                                                }
                                                ref={online1Ref}
                                                onKeyDown={(e) =>
                                                    handleKeyDownForRight(e, online1Ref, "onlinePayment2")
                                                }
                                                className={
                                                    focusedField === "onlinePayment1"
                                                        ? "border border-2 border-primary rounded"
                                                        : "fw-bold"
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Online Payment 2 (C4)</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                // className="fw-bold"
                                                type="number"
                                                name="onlinePayment2"
                                                value={onlinePayment2}
                                                onChange={(e) =>
                                                    setOnlinePayment2(e.target.value || "0")
                                                }
                                                ref={online2Ref}
                                                onKeyDown={(e) =>
                                                    handleKeyDownForRight(e, tenderRef, "tenderAmt")
                                                }
                                                className={
                                                    focusedField === "onlinePayment2"
                                                        ? "border border-2 border-primary rounded"
                                                        : "fw-bold"
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Credit</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                className="fw-bold"
                                                type="number"
                                                name="balance"
                                                value={parseFloat(balance).toFixed(2)}
                                                readOnly
                                                style={{ backgroundColor: "#DBF3DF" }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Split Payment (Y/N)</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                className="fw-bold"
                                                name="splitPayment"
                                                value={split}
                                                onChange={(e) => setSplit(e.target.value)}
                                                readOnly
                                                style={{ backgroundColor: "#DBF3DF" }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Amount Paid</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                className="fw-bold"
                                                type="number"
                                                name="amtPaid"
                                                value={amtPaid}
                                                readOnly
                                                style={{ backgroundColor: "#DBF3DF" }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Tender Return</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                className="fw-bold"
                                                type="number"
                                                name="tenderedReturn"
                                                value={parseFloat(tenderedReturn).toFixed(2)}
                                                readOnly
                                                style={{ backgroundColor: "#DBF3DF" }}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="readonly-row">
                                        <td>
                                            <strong>Remark</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                className="fw-bold"
                                                name="remark"
                                                value={remark}
                                                readOnly
                                                onChange={(e) => setRemark(e.target.value)}
                                                style={{ backgroundColor: "#DBF3DF" }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Ledger Balance</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                className="fw-bold"
                                                type="number"
                                                name="ledgerBal"
                                                value={parseFloat(ledgerBal).toFixed(2)}
                                                readOnly
                                                style={{ backgroundColor: "#DBF3DF" }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Bank Recevied</strong>
                                        </td>
                                        <td style={{ padding: "0" }}>
                                            <FormInput
                                                className="fw-bold"
                                                type="number"
                                                name="backRecevied"
                                                value={bankRecevied}
                                                readOnly
                                                style={{ backgroundColor: "#DBF3DF" }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                    <div className="d-flex justify-content-end">
                        <Button
                            className="btn btn-sm fw-bold btn-secondary fw-bold"
                            onClick={() => resetBill()}
                            style={{ marginRight: "10px" }}
                        >
                            Clear
                        </Button>
                        {/* <Button variant="primary" onClick={handleSave}>Save Changes</Button> */}
                        <button className="btn btn-primary" onClick={handleSave}>
                            {formType === FormTypeList.ADD ? "Save Bill" : "Update Bill"}
                        </button>
                    </div>
                </>
            </Modal>
        </>
    );
};
export default SalesMaster;
