import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState,useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput, FormInput2 } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { apiUrl, FormType, FormTypeList } from "../config"
import { onlyUnique } from "../config/functions"
import { AuthUserType, RoleType } from "../redux/actions/authUserActions"
import { addClientRegistrationAction, ClientRegistrationType, deleteClientRegistrationAction, editClientRegistrationAction } from "../redux/actions/clientRegistration"
import { ShopType } from "../redux/actions/shopAction"
import { AppDispatch, StoreState } from "../redux/store"

interface TableBody {
    sno: number,
    shopShort: string | undefined,
    name: string | undefined,
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    rolePermissions: RoleType | undefined
}

type HandleEdit = (value: ClientRegistrationType) => void
type HandleDelete = (value: ClientRegistrationType) => void


const ClientRegistration = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()

    const date = new Date()
    const unixTimestamp = Math.floor(date.getTime() / 1000);

    const AuthUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const clients = useSelector<StoreState, ClientRegistrationType[]>(state => state.clientRegistration)
    const ShopList = useSelector<StoreState, ShopType[]>(state => state.shop)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<ClientRegistrationType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [deleteName, setDeleteName] = useState<string | undefined>('')
    // const [id, setId] = useState<string>('')

    const url = window.location.pathname
    const rolePermissions = AuthUser?.roles?.find(r => r.pageUrl === url)

    const [id, setId] = useState<string>('');
    const [idError, setIdError] = useState<string>('');

    const [shopId, setShopId] = useState<string>('');
    const [shopIdError, setShopIdError] = useState<string>('');

    const [shopFullName, setShopFullName] = useState<string>('');
    const [shopFullNameError, setShopFullNameError] = useState<string>('');

    const [shopShortName, setShopShortName] = useState<string>('');
    const [shopShortNameError, setShopShortNameError] = useState<string>('');

    const [clientType, setClientType] = useState<string>('');
    const [clientTypeError, setClientTypeError] = useState<string>('');

    const [headOffice, setHeadOffice] = useState<string>('');
    const [headOfficeError, setHeadOfficeError] = useState<string>('');

    const [proprietorName, setproprietorName] = useState<string>('');
    const [proprietorNameError, setproprietorNameError] = useState<string>('');

    const [gstNo, setGstNo] = useState<string>('');
    const [gstNoError, setGstNoError] = useState<string>('');

    const [panCardNo, setPanCardNo] = useState<string>('');
    const [panCardNoError, setPanCardNoError] = useState<string>('');

    const [licenceNo1, setLicenceNo1] = useState<string>('');
    const [licenceNo1Error, setLicenceNo1Error] = useState<string>('');

    const [licenceNo2, setLicenceNo2] = useState<string>('');
    const [licenceNo2Error, setLicenceNo2Error] = useState<string>('');

    const [licenceNo3, setLicenceNo3] = useState<string>('');
    const [licenceNo3Error, setLicenceNo3Error] = useState<string>('');

    const [address1, setAddress1] = useState<string>('');
    const [address1Error, setAddress1Error] = useState<string>('');

    const [address2, setAddress2] = useState<string>('');
    const [address2Error, setAddress2Error] = useState<string>('');

    const [place, setPlace] = useState<string>('');
    const [placeError, setPlaceError] = useState<string>('');

    const [taluk, setTaluk] = useState<string>('');
    const [talukError, setTalukError] = useState<string>('');

    const [district, setDistrict] = useState<string>('');
    const [districtError, setDistrictError] = useState<string>('');

    const [state, setState] = useState<string>('');
    const [stateError, setStateError] = useState<string>('');

    const [pincode, setPincode] = useState<string>('');
    const [pincodeError, setPincodeError] = useState<string>('');

    const [contactNo1, setContactNo1] = useState<string>('');
    const [contactNo1Error, setContactNo1Error] = useState<string>('');

    const [contactNo2, setContactNo2] = useState<string>('');
    const [contactNo2Error, setContactNo2Error] = useState<string>('');

    const [tagLine, setTagLine] = useState<string>('');
    const [tagLineError, setTagLineError] = useState<string>('');

    const [shopLogo, setShopLogo] = useState<{ file: File | null; preview: string }>({
        file: null,
        preview: "",
    });
    const [shopLogoError, setShopLogoError] = useState<string>('');

    const [bannerImage, setBannerImage] = useState<{ file: File | null; preview: string }>({
        file: null,
        preview: "",
    });
    const [bannerImageError, setBannerImageError] = useState<string>('');

    const shopFileInputRef = React.useRef<HTMLInputElement>(null)
    const bannerFileInputRef = React.useRef<HTMLInputElement>(null)
    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()
    const searchTerm = searchQuery.get('search') || ''

    // Focus Functionality
    const [focusedField, setFocusedField] = useState<string | null>(null);
    const shopFullNamRef = useRef<HTMLInputElement>(null);
    const shopShortNamRef = useRef<HTMLInputElement>(null);
    const clientRef = useRef<HTMLInputElement>(null);
    const headOffRef = useRef<HTMLSelectElement>(null);
    const properietorNamRef = useRef<HTMLInputElement>(null);
    const gstRef = useRef<HTMLInputElement>(null);
    const panRef = useRef<HTMLInputElement>(null);
    const licence1Ref = useRef<HTMLInputElement>(null);
    const licence2Ref = useRef<HTMLInputElement>(null);
    const licence3Ref = useRef<HTMLInputElement>(null);
    const address1Ref = useRef<HTMLInputElement>(null);
    const address2Ref = useRef<HTMLInputElement>(null);
    const placeRef = useRef<HTMLInputElement>(null);
    const talukRef = useRef<HTMLInputElement>(null);
    const districtRef = useRef<HTMLInputElement>(null);
    const stateRef = useRef<HTMLInputElement>(null);
    const pinCodeRef = useRef<HTMLInputElement>(null);
    const contactNo1Ref = useRef<HTMLInputElement>(null);
    const contactNo2Ref = useRef<HTMLInputElement>(null);
    const tagRef = useRef<HTMLInputElement>(null);

     const handleKeyDown = <T extends Element>(
            e: React.KeyboardEvent<T>,
            nextRef?: React.RefObject<HTMLElement>,
            nextField?: string,
            prevRef?: React.RefObject<HTMLElement>,
            prevField?: string
        ) => {
            const moveFocus = (ref?: React.RefObject<HTMLElement>, field?: string) => {
                if (!ref?.current) return;
    
                e.preventDefault();
    
                const activeElement = document.activeElement as HTMLElement;
                if (activeElement && 'blur' in activeElement) {
                    activeElement.blur();
                }
    
                const input = ref.current.querySelector('input, select, textarea') || ref.current;
                if (input instanceof HTMLElement) {
                    input.focus();
                    setFocusedField(field || '');
                }
            };
    
            if (e.key === 'ArrowRight') moveFocus(nextRef, nextField);
            if (e.key === 'ArrowLeft') moveFocus(prevRef, prevField);
        };



    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    React.useEffect(() => {
        if (searchTerm !== '') {
            searchRef.current?.focus()
        }
    }, [])

    const handleShopFullNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        const newShopId = e.target.value;
        // let val = e.target.id;
        setShopId(newShopId);
        setShopFullNameError('');

        const selectedShop = ShopList.find((data) => data.id?.toString() === newShopId);
        if (selectedShop) {
            setShopFullName(selectedShop.name || '');

        }
    };
    console.log(shopId);

    const handleShopShortNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        if (/[^a-zA-Z0-9 ]/g.test(value)) {
            setShopShortNameError('Special characters are not allowed!');
        } else {
            setShopShortName(value);
            setShopShortNameError('');
        }
        // setShopShortName(value);
        // setShopShortNameError('');
    };

    const handleClientTypeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        if (/[^a-zA-Z0-9 ]/g.test(value)) {
            setClientTypeError('Special characters are not allowed!');
        } else {
            setClientType(value);
            setClientTypeError('');
        }
        setClientType(value);
        setClientTypeError('');
    };

    const handleHeadOfficeChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
        const value = e.target.value;
        if (/[^a-zA-z0-9]/g.test(value)) {
            setHeadOfficeError('Special characters are not allowed!');
        }
        else {
            setHeadOffice(value)
            setHeadOfficeError('');
        }
    };

    const handleproprietorNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-z0-9]/g.test(e.target.value)) {
            setproprietorNameError('Special characters are not allowed!');
        }
        else {
            setproprietorName(e.target.value);
            setproprietorNameError('');
        }
    };

    const handleGstNoChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9]/g.test(e.target.value)) {
            setGstNoError('Special characters are not allowed!');
        } else {
            setGstNo(e.target.value);
            setGstNoError('');
        }
    };

    const handlePanCardNoChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9]/g.test(e.target.value)) {
            setPanCardNoError('Special characters are not allowed!');
        } else {
            setPanCardNo(e.target.value);
            setPanCardNoError('');
        }
    };

    const handleLicenceNo1Change: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9]/g.test(e.target.value)) {
            setLicenceNo1Error('Special characters are not allowed!');
        } else {
            setLicenceNo1(e.target.value);
            setLicenceNo1Error('');
        }
    };

    const handleLicenceNo2Change: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9]/g.test(e.target.value)) {
            setLicenceNo2Error('Special characters are not allowed!');
        } else {
            setLicenceNo2(e.target.value);
            setLicenceNo2Error('');
        }
    };

    const handleLicenceNo3Change: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9]/g.test(e.target.value)) {
            setLicenceNo3Error('Special characters are not allowed!');
        } else {
            setLicenceNo3(e.target.value);
            setLicenceNo3Error('');
        }
    };

    const handleAddress1Change: ChangeEventHandler<HTMLInputElement> = (e) => {
        // if (/[^a-zA-Z0-9\s]/g.test(e.target.value)) {
        //     setAddress1Error('Special characters are not allowed!');
        // } else {
        //     setAddress1(e.target.value);
        //     setAddress1Error('');
        // }
        setAddress1(e.target.value);
        setAddress1Error('');
    };

    const handleAddress2Change: ChangeEventHandler<HTMLInputElement> = (e) => {
        // if (/[^a-zA-Z0-9\s]/g.test(e.target.value)) {
        //     setAddress2Error('Special characters are not allowed!');
        // } else {
        //     setAddress2(e.target.value);
        //     setAddress2Error('');
        // }
        setAddress2(e.target.value);
        setAddress2Error('');
    };

    const handlePlaceChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9\s]/g.test(e.target.value)) {
            setPlaceError('Special characters are not allowed!');
        } else {
            setPlace(e.target.value);
            setPlaceError('');
        }
    };

    const handleTalukChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9\s]/g.test(e.target.value)) {
            setTalukError('Special characters are not allowed!');
        } else {
            setTaluk(e.target.value);
            setTalukError('');
        }
    };

    const handleDistrictChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9\s]/g.test(e.target.value)) {
            setDistrictError('Special characters are not allowed!');
        } else {
            setDistrict(e.target.value);
            setDistrictError('');
        }
    };

    const handleStateChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9\s]/g.test(e.target.value)) {
            setStateError('Special characters are not allowed!');
        } else {
            setState(e.target.value);
            setStateError('');
        }
    };

    const handlePincodeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9]/g.test(e.target.value)) {
            setPincodeError('Special characters are not allowed!');
        } else {
            setPincode(e.target.value);
            setPincodeError('');
        }
    };

    const handleContactNo1Change: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9]/g.test(e.target.value)) {
            setContactNo1Error('Special characters are not allowed!');
        } else {
            setContactNo1(e.target.value);
            setContactNo1Error('');
        }
    };

    const handleContactNo2Change: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9]/g.test(e.target.value)) {
            setContactNo2Error('Special characters are not allowed!');
        } else {
            setContactNo2(e.target.value);
            setContactNo2Error('');
        }
    };

    const handleTagLineChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (/[^a-zA-Z0-9\s]/g.test(e.target.value)) {
            setTagLineError('Special characters are not allowed!');
        } else {
            setTagLine(e.target.value);
            setTagLineError('');
        }
    };

    const handleShopLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setShopLogo({ file, preview: fileUrl });
        }
    };

    const handleBannerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setBannerImage({ file, preview: fileUrl });
        }

    };

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
    }
    //change
    const handleDelete: HandleDelete = async (value) => {
        const id = value.id ? parseInt(value.id.toString(), 10) : undefined;
        setDeleteId(id);
        setShowDeleteForm(true);
    };
    const resetStates = () => {
        setId('');
        setShopId('');
        setShopFullName('');
        setShopShortName('');
        setClientType('');
        setHeadOffice('');
        setproprietorName('');
        setGstNo('');
        setPanCardNo('');
        setLicenceNo1('');
        setLicenceNo2('');
        setLicenceNo3('');
        setAddress1('');
        setAddress2('');
        setPlace('');
        setTaluk('');
        setDistrict('');
        setState('');
        setPincode('');
        setContactNo1('');
        setContactNo2('');
        setTagLine('');
        if (bannerFileInputRef.current) {
            bannerFileInputRef.current.value = ""; // Reset the file input
        }
        if (shopFileInputRef.current) {
            shopFileInputRef.current.value = ""; // Reset the file input
        }
        setShopLogo({ file: null, preview: "" });
        setBannerImage({ file: null, preview: "" });

        // setIdError('');
        setShopIdError('');
        setShopFullNameError('');
        setShopShortNameError('');
        setClientTypeError('');
        setHeadOfficeError('');
        setproprietorNameError('');
        setGstNoError('');
        setPanCardNoError('');
        setLicenceNo1Error('');
        setLicenceNo2Error('');
        setLicenceNo3Error('');
        setAddress1Error('');
        setAddress2Error('');
        setPlaceError('');
        setTalukError('');
        setDistrictError('');
        setStateError('');
        setPincodeError('');
        setContactNo1Error('');
        setContactNo2Error('');
        setShopLogoError('');
        setTagLineError('');
        setBannerImageError('');

        setEditData(null);
    };

    // const viewTable = () => {
    //     setShowTable((data) => (!data))
    // }

    const formData = new FormData();

    formData.append("shopId", shopId);
    formData.append("shopFullName", shopFullName);
    formData.append("shopShortName", shopShortName);
    formData.append("clientType", clientType);
    formData.append("headOffice", headOffice);
    formData.append("proprietorName", proprietorName);
    formData.append("gstNo", gstNo);
    formData.append("panCardNo", panCardNo);
    formData.append("licenceNo1", licenceNo1);
    formData.append("licenceNo2", licenceNo2);
    formData.append("licenceNo3", licenceNo3);
    formData.append("address1", address1);
    formData.append("address2", address2);
    formData.append("place", place);
    formData.append("taluk", taluk);
    formData.append("district", district);
    formData.append("state", state);
    formData.append("pincode", pincode !== undefined ? pincode : '');
    formData.append("contactNo1", contactNo1 !== undefined ? contactNo1 : '');
    formData.append("contactNo2", contactNo2 !== undefined ? contactNo2 : '');
    formData.append("shopLogo", shopLogo?.file!); // Ensure this is a File object if needed
    formData.append("tagLine", tagLine);
    formData.append("bannerImage", bannerImage?.file!); // Ensure this is a File object if needed

    const handleSave = () => {
        let error = false;

        if (shopFullName === '') {
            setShopFullNameError('Shop Full Name is required');
            error = true;
        }
        if (shopFullName.toString().length > 50) {
            setShopFullNameError('Shop full name should be within 50 digits');
            error = true;
        }

        if (shopShortName === '') {
            setShopShortNameError('Shop Short Name is required');
            error = true;
        }
        if (shopShortName.toString().length > 30) {
            setShopShortNameError('Shop Short name should be within 30 digits');
            error = true;
        }

        if (clientType == null) {
            setClientTypeError('Client Type is required');
            error = true;
        }

        if (!headOffice) {
            setHeadOfficeError('Head Office is required');
            error = true;
        }

        if (proprietorName === '') {
            setproprietorNameError('propertier Name is required');
            error = true;
        }
        if (proprietorName.toString().length > 15) {
            setproprietorNameError('Shop Short name should be within 15 digits');
            error = true;
        }

        if (gstNo === '') {
            setGstNoError('GST No is required');
            error = true;
        }
        if (gstNo && gstNo.toString().trim().length > 0) {
            if ((gstNo?.toString() || '').length > 15 || (gstNo?.toString() || '').length < 15) {
                setGstNoError('GST No should be 15 digits');
                error = true;
            }
        }

        if (panCardNo === '') {
            setPanCardNoError('PAN Card No is required');
            error = true;
        }
        if (panCardNo && panCardNo.toString().trim().length > 0) {
            if ((panCardNo?.toString() || '').length > 10 || (panCardNo?.toString() || '').length < 10) {
                setPanCardNoError('PAN Card No should be 10 digits');
                error = true;
            }
        }

        if (licenceNo1 === '') {
            setLicenceNo1Error('Licence No 1 is required');
            error = true;
        }
        if (licenceNo1 && licenceNo1.toString().trim().length > 0) {
            if ((licenceNo1?.toString() || '').length > 16 || (licenceNo1?.toString() || '').length < 16) {
                setLicenceNo1Error('licence No should be 16 digits');
                error = true;
            }
        }


        if (licenceNo2 && licenceNo2.toString().trim().length > 0) {
            if ((licenceNo2?.toString() || '').length > 16 || (licenceNo2?.toString() || '').length < 16) {
                setLicenceNo2Error('licence No should be 16 digits');
                error = true;
            }
        }



        if (licenceNo3 && licenceNo3.toString().trim().length > 0) {
            if ((licenceNo3?.toString() || '').length > 16 || (licenceNo3?.toString() || '').length < 16) {
                setLicenceNo3Error('licence No should be 16 digits');
                error = true;
            }
        }

        if (address1 === '') {
            setAddress1Error('Address 1 is required');
            error = true;
        }

        if (address2 === '') {
            setAddress2Error('Address 2 is required');
            error = true;
        }

        if (place === '') {
            setPlaceError('Place is required');
            error = true;
        }

        if (taluk === '') {
            setTalukError('Taluk is required');
            error = true;
        }

        if (district === '') {
            setDistrictError('District is required');
            error = true;
        }

        if (state === '') {
            setStateError('State is required');
            error = true;
        }
        if (pincode === '') {
            setPincodeError('Pincode is required');
            error = true;
        }
        if (pincode && pincode.toString().trim().length > 0) {
            if ((pincode?.toString() || '').length > 6 || (pincode?.toString() || '').length < 6) {
                setPincodeError('Enter your 6 digits pincode');
                error = true;
            }
        }

        if (contactNo1?.toString() === '') {
            setContactNo1Error('contact number 1 required');
            error = true;
        }
        if (contactNo1 && contactNo1.toString().trim().length > 0) {
            if ((contactNo1?.toString() || '').length > 10 || (contactNo1?.toString() || '').length < 10) {
                setContactNo1Error('Contact number should be 10 digits');
                error = true;
            }
        }


        if (contactNo2 && contactNo2.toString().trim().length > 0) {
            if ((contactNo2?.toString() || '').length > 10 || (contactNo2?.toString() || '').length < 10) {
                setContactNo2Error('Contact number should be 10 digits');
                error = true;
            }
        }
        if (formType === FormTypeList.ADD) {
            if (!shopLogo.file) {
                setShopLogoError('Shop Logo is required');
                error = true;
            }
        }


        if (tagLine === '') {
            setTagLineError('Tag Line is required');
            error = true;
        }

        if (formType === FormTypeList.ADD) {
            if (!bannerImage.file) {
                console.log('img1');
                setBannerImageError('Banner Image is required');
                error = true;
            }
        }

        if (bannerImage.file) {
            if (bannerImage.file.size / 1024 / 1024 > 2) {
            setBannerImageError("Size is too large");
            error = true;
            } else if (!["image/png", "image/jpg", "image/jpeg"].includes(bannerImage.file.type)) {
            setBannerImageError("Banner Image type must be png/jpg/jpeg");
            error = true;
            }
        }
        // console.log(error);

        if (!error) {
            // console.log('ok');
            if (formType === FormTypeList.ADD) {
                // console.log('add');

                dispatch(addClientRegistrationAction(formData))
                    .then(text => {
                        toast(text, ToastTypes.SUCCESS);
                        resetStates();
                    }).catch(text => {
                        toast(text, ToastTypes.ERROR);
                    });
            } else if (formType === FormTypeList.EDIT && editData !== null && editData.id) {
                dispatch(editClientRegistrationAction(formData, editData.id))
                    .then(text => {
                        toast(text, ToastTypes.SUCCESS);
                        resetStates();
                        setEditData(null);
                        setFormType(FormTypeList.ADD);
                    }).catch(text => {
                        toast(text, ToastTypes.ERROR);
                    });
            }
        }

    };



    useEffect(() => {
        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {
            // const dateObj = new Date(editData.asOnDate || '')

            // const d = dateObj.getDate().toString().padStart(2, '0')
            // const m = (dateObj.getMonth() + 1).toString().padStart(2, '0')
            // const y = dateObj.getFullYear().toString()
            console.log(editData);

            setId(editData.id?.toString() || '');
            setShopId(editData.shopId?.toString()!);
            setShopFullName(editData.shopFullName || '');
            setShopShortName(editData.shopShortName || '');
            setClientType(editData.clientType || '');
            setHeadOffice(editData.headOffice || '');
            setproprietorName(editData.proprietorName || '');
            setGstNo(editData.gstNo || '');
            setPanCardNo(editData.panCardNo || '');
            setLicenceNo1(editData.licenceNo1 || '');
            setLicenceNo2(editData.licenceNo2 || '');
            setLicenceNo3(editData.licenceNo3 || '');
            setAddress1(editData.address1 || '');
            setAddress2(editData.address2 || '');
            setPlace(editData.place || '');
            setTaluk(editData.taluk || '');
            setDistrict(editData.district || '');
            setState(editData.state || '');
            setPincode(editData.pincode?.toString() || '');
            setContactNo1(editData.contactNo1?.toString() || '');
            setContactNo2(editData.contactNo2?.toString() || '');
            setShopLogo({ file: null, preview: apiUrl + '/' + editData.shopLogo! });
            setTagLine(editData.tagLine || '');
            setBannerImage({ file: null, preview: apiUrl + '/' + editData.bannerImage! });

        } else {
            resetStates()
        }
    }, [formType, editData])


    const onDelete = () => {

        if (deleteId) {
            // change
            dispatch(deleteClientRegistrationAction(deleteId.toString())).then(text => {
                toast(text, ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
                resetStates()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }
    return (
      <>
        <Modal
          headerText={"Delete Client"}
          visible={showDeleteForm}
          onClose={handleDeleteFormClose}
          centered
          size="lg"
        >
          <DeleteConfirmationForm
            onDelete={onDelete}
            value={deleteName}
            onClose={handleDeleteFormClose}
          />
        </Modal>

        <UserLayout>
          <h4>Client Registration</h4>
          <div className="row">
            {/* <div className={showTable ? "col-lg-8" : 'col-lg-12'}> */}
            <div className="col-lg-8">
              <div className="card shadow-lg p-1">
                <div className="text-end mt-1">
                  {/* <button className="btn btn-sm fw-bold btn-success" style={{ float: "right" }} onClick={() => viewTable()}>{showTable ? 'Hide' : 'View'}</button> */}
                  {/* <button className="btn btn-sm fw-bold btn-secondary me-1" style={{ float: "right" }} onClick={() => resetStates()}>Add New</button> */}
                </div>
                <div className="card-body p-0">
                  <div className="row mt-1 mb-2">
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2">
                      <div className="col-4">
                        <label htmlFor="">
                          Shop full name<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput
                          name="shopFullName"
                          label=""
                          labelClassName="required"
                          required={true}
                          value={shopId}
                          onChange={handleShopFullNameChange}
                          errorText={shopFullNameError}
                          containerClass="mb-2"
                          type="select"
                          ref={shopFullNamRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              shopShortNamRef,
                              "shopshortname",
                              undefined,
                              undefined
                            )
                          }
                          className={
                            focusedField === "shopfullname"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        >
                          <option value="">Select</option>
                          {ShopList?.map((r) => r)
                            .filter(onlyUnique)
                            .map((ro, i) => {
                              return (
                                <option key={i} value={ro.id}>
                                  {ro.name}
                                </option>
                              );
                            })}
                        </FormInput>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2">
                      <div className="col-4">
                        <label htmlFor="">
                          Shop short name<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="shopShortName"
                          label=""
                          labelClassName="required"
                          height="24px"
                          required={true}
                          value={shopShortName}
                          onChange={handleShopShortNameChange}
                          errorText={shopShortNameError}
                          ref={shopShortNamRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              clientRef,
                              "client",
                              shopFullNamRef,
                              "shopfullname"
                            )
                          }
                          className={
                            focusedField === "shopshortname"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Client Type<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput
                          name="clientType"
                          label=""
                          labelClassName="required"
                          required={true}
                          value={clientType}
                          onChange={handleClientTypeChange}
                          errorText={clientTypeError}
                          containerClass="mb-2"
                          type="select"
                          ref={clientRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              headOffRef,
                              "headoffice",
                              shopShortNamRef,
                              "shopshortname"
                            )
                          }
                          className={
                            focusedField === "client"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        >
                          <option value="">Select</option>
                          <option value={"wholesale"}>Wholesale</option>
                          <option value={"retail"}>Retail</option>
                        </FormInput>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Head office<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <select
                          name="headOffice"
                          //   className="form-select"
                          value={headOffice}
                          onChange={handleHeadOfficeChange}
                          required
                          ref={headOffRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              properietorNamRef,
                              "proprietorname",
                              clientRef,
                              "client"
                            )
                          }
                          className={
                            focusedField === "headoffice"
                              ? "border border-2 border-primary rounded form-select"
                              : "form-select"
                          }
                        >
                          <option value="">Select</option>
                          <option value="True">True</option>
                          <option value="False">False</option>
                        </select>
                        <div className="invalid-feedback">
                          {headOfficeError ? headOfficeError : ""}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2">
                      <div className="col-4">
                        <label htmlFor="">
                          Proprietor Name<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="ProprietorName"
                          label=""
                          labelClassName="required"
                          height="24px"
                          required={true}
                          value={proprietorName}
                          onChange={handleproprietorNameChange}
                          errorText={proprietorNameError}
                          ref={properietorNamRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              gstRef,
                              "gst",
                              headOffRef,
                              "headoffice"
                            )
                          }
                          className={
                            focusedField === "proprietorname"
                              ? "border border-2 border-primary rounded "
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          GST No<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="gstNo"
                          label=""
                          labelClassName="required"
                          height="24px"
                          value={gstNo}
                          onChange={handleGstNoChange}
                          errorText={gstNoError}
                          ref={gstRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              panRef,
                              "pan",
                              properietorNamRef,
                              "proprietorname"
                            )
                          }
                          className={
                            focusedField === "gst"
                              ? "border border-2 border-primary rounded "
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Pan card no<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="panCardNo"
                          label=""
                          labelClassName="required"
                          height="24px"
                          value={panCardNo}
                          onChange={handlePanCardNoChange}
                          errorText={panCardNoError}
                          ref={panRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              licence1Ref,
                              "licence1",
                              gstRef,
                              "gst"
                            )
                          }
                          className={
                            focusedField === "pan"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Licence No 1<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="licenceNo1"
                          label=""
                          labelClassName="required"
                          height="24px"
                          value={licenceNo1}
                          onChange={handleLicenceNo1Change}
                          errorText={licenceNo1Error}
                          ref={licence1Ref}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              licence2Ref,
                              "licence2",
                              panRef,
                              "pan"
                            )
                          }
                          className={
                            focusedField === "licence1"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">Licence No 2</label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="licenceNo2"
                          label=""
                          labelClassName="required"
                          height="24px"
                          value={licenceNo2}
                          onChange={handleLicenceNo2Change}
                          errorText={licenceNo2Error}
                          ref={licence2Ref}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              licence3Ref,
                              "licence3",
                              licence1Ref,
                              "licence1"
                            )
                          }
                          className={
                            focusedField === "licence2"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">Licence No 3</label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="licenceNo3"
                          label=""
                          labelClassName="required"
                          height="24px"
                          value={licenceNo3}
                          onChange={handleLicenceNo3Change}
                          errorText={licenceNo3Error}
                          ref={licence3Ref}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              address1Ref,
                              "address1",
                              licence2Ref,
                              "licence2"
                            )
                          }
                          className={
                            focusedField === "licence3"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Address 1<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="address1"
                          label=""
                          labelClassName="required"
                          height="24px"
                          value={address1}
                          onChange={handleAddress1Change}
                          errorText={address1Error}
                          ref={address1Ref}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              address2Ref,
                              "address2",
                              licence3Ref,
                              "licence3"
                            )
                          }
                          className={
                            focusedField === "address1"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Address 2<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="address2"
                          label=""
                          labelClassName="required"
                          height="24px"
                          value={address2}
                          onChange={handleAddress2Change}
                          errorText={address2Error}
                          ref={address2Ref}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              placeRef,
                              "place",
                              address1Ref,
                              "address1"
                            )
                          }
                          className={
                            focusedField === "address2"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Place<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="place"
                          label=""
                          labelClassName="required"
                          height="24px"
                          value={place}
                          onChange={handlePlaceChange}
                          errorText={placeError}
                          ref={placeRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              talukRef,
                              "taluk",
                              address2Ref,
                              "address2"
                            )
                          }
                          className={
                            focusedField === "place"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Taluk<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="taluk"
                          label=""
                          labelClassName="required"
                          height="24px"
                          value={taluk}
                          onChange={handleTalukChange}
                          errorText={talukError}
                          ref={talukRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              districtRef,
                              "district",
                              placeRef,
                              "place"
                            )
                          }
                          className={
                            focusedField === "taluk"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          District<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="district"
                          label=""
                          labelClassName="required"
                          height="24px"
                          value={district}
                          onChange={handleDistrictChange}
                          errorText={districtError}
                          ref={districtRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              stateRef,
                              "state",
                              talukRef,
                              "taluk"
                            )
                          }
                          className={
                            focusedField === "district"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          State<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="state"
                          label=""
                          labelClassName="required"
                          height="24px"
                          value={state}
                          onChange={handleStateChange}
                          errorText={stateError}
                          ref={stateRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              pinCodeRef,
                              "pincode",
                              districtRef,
                              "district"
                            )
                          }
                          className={
                            focusedField === "state"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Pincode<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="pincode"
                          label=""
                          type="number"
                          labelClassName="required"
                          height="24px"
                          value={pincode}
                          onChange={handlePincodeChange}
                          errorText={pincodeError}
                          ref={pinCodeRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              contactNo1Ref,
                              "contact1",
                              stateRef,
                              "state"
                            )
                          }
                          className={
                            focusedField === "pincode"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Contact no 1<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="contactNo1"
                          label=""
                          type="number"
                          labelClassName="required"
                          height="24px"
                          value={contactNo1}
                          onChange={handleContactNo1Change}
                          errorText={contactNo1Error}
                          ref={contactNo1Ref}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              contactNo2Ref,
                              "contact2",
                              pinCodeRef,
                              "pincode"
                            )
                          }
                          className={
                            focusedField === "contact1"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">Contact no 2</label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="contactNo2"
                          label=""
                          type="number"
                          labelClassName="required"
                          height="24px"
                          value={contactNo2}
                          onChange={handleContactNo2Change}
                          errorText={contactNo2Error}
                          ref={contactNo2Ref}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              tagRef,
                              "tag",
                              contactNo1Ref,
                              "contact1"
                            )
                          }
                          className={
                            focusedField === "contact2"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Tag line<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <FormInput2
                          name="tagLine"
                          label=""
                          labelClassName="required"
                          height="24px"
                          value={tagLine}
                          onChange={handleTagLineChange}
                          errorText={tagLineError}
                          ref={tagRef}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              shopFileInputRef,
                              "shoplogo",
                              contactNo2Ref,
                              "contact2"
                            )
                          }
                          className={
                            focusedField === "tag"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Shop Logo<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <input
                          type="file"
                          id="shopLogo"
                          accept="image/*"
                          ref={shopFileInputRef}
                          onChange={(e) => handleShopLogoChange(e)}
                          //   ref={address2Ref}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              bannerFileInputRef,
                              "bannerimage",
                              tagRef,
                              "tag"
                            )
                          }
                          className={
                            focusedField === "shoplogo"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                        {shopLogo.preview && (
                          <img
                            src={shopLogo.preview}
                            alt="Preview 1"
                            style={{
                              display: "block",
                              width: "75px",
                              height: "75px",
                              marginTop: "8px",
                            }}
                          />
                        )}
                        <small className="text-danger">
                          {shopLogoError || shopLogo === null
                            ? shopLogoError
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 hstack mt-2 ">
                      <div className="col-4">
                        <label htmlFor="">
                          Banner image<span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="col-7">
                        <input
                          type="file"
                          id="bannerImage"
                          accept="image/*"
                          ref={bannerFileInputRef}
                          onChange={(e) => handleBannerChange(e)}
                          
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              undefined,
                              undefined,
                              shopFileInputRef,
                              "shoplogo"
                            )
                          }
                          className={
                            focusedField === "bannerimage"
                              ? "border border-2 border-primary rounded"
                              : ""
                          }
                        />
                        {bannerImage.preview && (
                          <img
                            src={bannerImage.preview}
                            alt="Preview 2"
                            style={{
                              display: "block",
                              width: "75px",
                              height: "75px",
                              marginTop: "8px",
                            }}
                          />
                        )}
                        <small className="text-danger">
                          {bannerImageError || bannerImage === null
                            ? bannerImageError
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>

                  {(formType === FormTypeList.ADD ||
                    formType === FormTypeList.EDIT ||
                    formType === FormTypeList.DELETE) && (
                    <div className="d-flex justify-content-end hstack mt-2 gap-1 mt-2">
                      <button
                        className="btn btn-sm fw-bold btn-secondary"
                        onClick={() => resetStates()}
                      >
                        Clear
                      </button>
                      {(editData
                        ? rolePermissions?.edit
                        : rolePermissions?.add) && (
                        <button
                          className="btn btn-sm fw-bold btn-success"
                          onClick={handleSave}
                        >
                          {editData ? "Update" : "Save"}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {rolePermissions?.view && (
              <div className="col-lg-4">
                <div
                  className="card shadow-lg p-1"
                  style={{ height: "503px", overflow: "auto" }}
                >
                  <div className="table-wrapper">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-truncate align-middle">S.No</th>
                          <th className="text-truncate align-middle">
                            Shop Name
                          </th>
                          <th className="text-truncate align-middle">
                            Shop Short Name
                          </th>
                          {(rolePermissions?.edit ||
                            rolePermissions?.delete) && (
                            <th className="text-truncate align-middle">
                              Actions
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {clients.map((cl, i) => {
                          return (
                            <ClientsBody
                              key={i}
                              sno={i + 1}
                              // shopId={cl.shopId}
                              name={cl.shopFullName}
                              shopShort={cl.shopShortName}
                              onEdit={() => handleEdit(cl)}
                              onDelete={() => handleDelete(cl)}
                              rolePermissions={rolePermissions}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </UserLayout>
      </>
    );
}

const ClientsBody = ({ sno, name, shopShort, onEdit, onDelete }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='align-middle'>{name}</td>
        <td className='align-middle'>{shopShort}</td>
        <td className="align-middle">
            <div className='hstack p-2 gap-1'>
                <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>
                <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>
            </div>
        </td>
    </tr>
}
export default ClientRegistration