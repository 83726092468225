import api from "../config/api";
import { ClientRegistrationType } from "../redux/actions/clientRegistration";

export const addClientRegistration = async (data: FormData) => {
    return await api().post('/clientRegistration/', data);
};

export const editClientRegistration = async (data: FormData, id: string) => {
    return await api().post(`/clientRegistration/?id=${id}`, data);
};

export const getClientRegistration = async () => {
    return await api().get('/clientRegistration/');
};

export const deleteClientRegistration = async (id: string) => {
    return await api().delete(`/clientRegistration/?id=${id}`);
     
};